import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import React, { useState, useCallback } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  createEmployeeRequest,
  editEmployeeRequest,
  updateEmployeeRequest,
  employeeDetailRequest,
  deleteEmployeeRequest,
} from "../../../api/company/employeesRequest"
import {
  FULL_WIDTH_KATAKANA,
  FULL_WIDTH_AND_HALFWIDTH,
  EMAIL_REGEXP,
  PHONE_NUMBER,
} from "../../../utils/regular"
import { useAuth } from "../../public/useAuth"
import { useIcon } from "../../public/useIcon"
import {
  EmployeeFormType,
  EmployeeResponseType,
  EmployeeListResponseType,
} from "./type"

const employeeResponseData: EmployeeResponseType = {
  id: 0,
  last_name: "",
  first_name: "",
  last_name_kana: "",
  first_name_kana: "",
  display_name: "",
  account: {
    id: 0,
    email: "",
    company_id: 0,
    available_status: "",
    object_id: "",
  },
  employee_code: "",
  phone_number: "",
  remote_flag: false,
  note: "",
  is_available: true,
  floor: {
    id: 0,
    floor_number: "",
    floor_name: "",
  },
  seat_id: 0,
  seat_name: 0,
  role: 4,
  branch: {
    id: 0,
    branch_name: "",
  },
  team: {
    id: 0,
    team_name: "",
    team_leader_name: "",
    team_leader_email: "",
  },
  icon: "",
}

// TODO
// 正規表現を定数かする
// 拠点や所属の設定は別ブランチで実装

export const useEmployee = () => {
  const navigate = useNavigate()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [successMessageOpen, setSuccessMessageOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const [iconBlob, setIconBlob] = useState<Blob | undefined>(undefined) // S3にデータとして送信する画像
  const { imageUrl, setImageUrl, fetchFile, handleCreateIcon } = useIcon()
  const { createUsers } = useAuth()
  const [employeeList, setEmployeeList] = useState<EmployeeListResponseType[]>(
    []
  )

  const basicSchema = Yup.object().shape({
    last_name: Yup.string()
      .required("必須になります")
      .matches(
        FULL_WIDTH_AND_HALFWIDTH,
        "全角ひらがな、カタカナ、漢字、半角アルファベットになります"
      ),
    first_name: Yup.string()
      .required("必須になります")
      .matches(
        FULL_WIDTH_AND_HALFWIDTH,
        "全角ひらがな、カタカナ、漢字、半角アルファベットになります"
      ),
    last_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    first_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    email: Yup.string()
      .required("必須になります")
      .matches(EMAIL_REGEXP, "メールアドレスの形式で入力してください"),
    employee_code: Yup.string()
      .nullable()
      .test("maxlength", "50文字以下で入力してください", (freeword) => {
        if (freeword == undefined) {
          return true
        }
        return freeword.length <= 50
      }),

    phone_number: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(
        PHONE_NUMBER,
        "ハイフンなしの10桁もしくは11桁の半角数字で入力してください"
      ),

    branch_id: Yup.number()
      .required("必須になります")
      .min(1, "拠点名を選択してください"),
    role: Yup.number()
      .required("必須になります")
      .max(3, "権限を選択してください"),
    floor_id: Yup.number()
      .required("必須になります")
      .min(1, "フロア名を選択してください"),
    team_id: Yup.number()
      .required("必須になります")
      .min(1, "所属名を選択してください"),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      last_name: employeeResponseData.last_name,
      first_name: employeeResponseData.first_name,
      last_name_kana: employeeResponseData.last_name_kana,
      first_name_kana: employeeResponseData.first_name_kana,
      display_name: employeeResponseData.display_name,
      email: employeeResponseData.account.email,
      employee_code: employeeResponseData.employee_code,
      phone_number: employeeResponseData.phone_number,
      branch_id: employeeResponseData.branch.id,
      floor_id: employeeResponseData.floor.id,
      floor_number: employeeResponseData.floor.floor_number,
      team_id: employeeResponseData.team.id,
      is_seat: 0,
      seat_name: employeeResponseData.seat_name,
      seat_id: employeeResponseData.seat_id,
      employee_id: employeeResponseData.id,
      note: employeeResponseData.note,
      remote_flag: employeeResponseData.remote_flag,
      is_available: employeeResponseData.is_available,
      team_leader_name: employeeResponseData.team.team_leader_name,
      team_leader_email: employeeResponseData.team.team_leader_email,
      role: employeeResponseData.role,
      account_id: employeeResponseData.account.id,
      company_id: employeeResponseData.account.company_id,
      object_id: employeeResponseData.account.object_id,
    },
    resolver: yupResolver(basicSchema),
  })

  // TODO
  // 仮置で外部キーを設定
  // 別ブランチにて修正
  // 座席シートの登録機能ができたらseat_idを削除する
  const onCreateSubmit: SubmitHandler<EmployeeFormType> = async (params) => {
    setLoading(true)
    try {
      const { data, error } = await createEmployeeRequest({
        ...params,
        account_id: undefined,
        is_available: true,
      })
      if (data) {
        if (iconBlob) {
          await handleCreateIcon(iconBlob, data.account.company_id, data.id) // アイコンが設定されている場合はアイコンを登録する
        }
        localStorage.setItem("alertContent", "従業員の登録が完了しました")
        navigate("/company/employees")
      } else if (error) {
        setErrorMessages(error)
        setErrorMessageOpen(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const [employeeDetail, setEmployeeDetail] =
    useState<EmployeeResponseType>(employeeResponseData)

  const fetchEmployeeDetail = useCallback(async (targetEmployeeId: number) => {
    setLoading(true)
    try {
      const response = await employeeDetailRequest({
        employee_id: targetEmployeeId,
      })
      if (response.status === 200 && response.data) {
        setEmployeeDetail(response.data)
        setForbidden(false)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 403) {
        setForbidden(true)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const onUpdateSubmit: SubmitHandler<EmployeeFormType> = async (params) => {
    setLoading(true)
    try {
      const { data, error } = await updateEmployeeRequest({
        ...params,
        seat_id: params.seat_id === 0 ? null : params.seat_id,
        account_id: params.account_id,
        is_available: true,
      })
      if (data) {
        if (iconBlob) {
          await handleCreateIcon(iconBlob, data.account.company_id, data.id) // アイコンが変更されている場合はアイコンを登録する
        }
        localStorage.setItem("alertContent", "従業員の更新が完了しました")
        navigate(`/company/employees/${data.id}`, {
          state: {
            updatedEmployeeId: data.id,
          },
        })
      } else if (error) {
        setErrorMessages(error)
        setErrorMessageOpen(true)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const editEmployee = useCallback(async (targetEmployeeId: number) => {
    setLoading(true)
    try {
      const response = await editEmployeeRequest({
        employee_id: targetEmployeeId,
      })
      if (response.status === 200 && response.data) {
        reset({
          account_id: response.data.account.id,
          last_name: response.data.last_name,
          first_name: response.data.first_name,
          last_name_kana: response.data.last_name_kana,
          first_name_kana: response.data.first_name_kana,
          display_name: response.data.display_name,
          email: response.data.account.email,
          employee_code: response.data.employee_code,
          phone_number: response.data.phone_number,
          branch_id: response.data.branch.id,
          floor_id: response.data.floor.id,
          team_id: response.data.team.id,
          seat_id: response.data.seat_id,
          employee_id: targetEmployeeId,
          seat_name: response.data.seat_name,
          is_seat: response.data.seat_id ? 1 : 0,
          note: response.data.note,
          remote_flag: response.data.remote_flag,
          company_id: response.data.account.company_id,
          role: response.data.role,
        })
        setForbidden(false)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 403) {
        setForbidden(true)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  // 個別に従業員を選択する
  const handleEmployeeChecked = (employeeId: number) => {
    const newEmployeeList = employeeList.map((employee) => {
      if (employee.id === employeeId) {
        return { ...employee, checked: !employee.checked }
      } else {
        return employee
      }
    })

    setEmployeeList(newEmployeeList)
  }

  // 全従業員を選択済みにする
  const handleAllEmployeeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmployeeList = employeeList.map((employee) => {
      return { ...employee, checked: e.target.checked }
    })

    setEmployeeList(newEmployeeList)
  }

  // TODO ↓とCSVダウンロード機能等のコードが重複しているため共通化したい
  // /src/models/employee/useEmployeeSearch/employeeList/index.ts

  // チェックを入れたユーザーについて、Cognito上にアカウントを作成する
  const handleCreateUserOnCognito = async () => {
    setLoading(true)
    try {
      // チェック済みの従業員のリスト
      const checkedEmployeeList = employeeList.filter((employee) => {
        return employee.checked
      })

      if (checkedEmployeeList.length === 0) return

      // チェック済みの従業員のメールアドレスのリスト
      const emailList = checkedEmployeeList.map((employee) => {
        return employee.account.email
      })

      const { error } = await createUsers(emailList)

      if (!error) {
        setSuccessMessageOpen(true)
        setSuccessMessage("認証メールの送信に成功しました。")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 従業員の削除（論理削除）
  const handleDeleteEmployee = async () => {
    setLoading(true)
    try {
      const { error } = await deleteEmployeeRequest(employeeDetail.account.id)

      if (!error) {
        localStorage.setItem("alertContent", "従業員アカウントを削除しました")
        navigate("/company/employees")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    control,
    errors,
    isValid,
    errorMessageOpen,
    errorMessages,
    employeeList,
    employeeDetail,
    handleSubmit,
    onCreateSubmit,
    onUpdateSubmit,
    getValues,
    setValue,
    fetchEmployeeDetail,
    editEmployee,
    loading,
    forbidden,
    setIconBlob,
    imageUrl,
    setImageUrl,
    fetchFile,
    handleEmployeeChecked,
    handleAllEmployeeChecked,
    handleCreateUserOnCognito,
    handleDeleteEmployee,
    successMessage,
    successMessageOpen,
    setErrorMessageOpen,
    setSuccessMessageOpen,
  }
}
