export const reservedAreaStyle = {
  position: "absolute",
  boxSizing: "border-box",
  borderRadius: "15px",
  border: "4px solid",
  cursor: "pointer",
}

export const areaStyle = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  colorCode: string,
  isHighLight = false
) => {
  return {
    width: endX - startX,
    height: endY - startY,
    borderRadius: "15px",
    border: "10px solid",
    borderColor: isHighLight ? "rgba(34, 186, 157, 0.7)" : colorCode,
    position: "absolute",
    boxSizing: "border-box",
    top: startY,
    left: startX,
    cursor: "pointer",
  }
}

export const areaIconStyle = (colorCode: string, isHighLight = false) => {
  return {
    fontSize: "30px",
    top: "0px",
    position: "absolute",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    border: "solid 5px #fff",
    boxShadow: "0 0 2px #1d0204",
    cursor: "pointer",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: isHighLight ? "rgba(34, 186, 157, 0.7)" : colorCode,
    color: isHighLight ? "#000" : "#FFFFFF",
  }
}
