import { useCallback } from "react"

import { searchPlaceRequest } from "../../../../api/employee/scheduleRequest"
import { SearchScheduleRequestType } from "./type"

export const useSearchSchedules = () => {
  // const [schedule, setSchedule] = useState<Schedule | undefined>(undefined)

  // 会議室の予定の一覧を取得する関数
  const searchSchedule = useCallback(
    async (data: SearchScheduleRequestType) => {
      try {
        const response = await searchPlaceRequest({
          employee_id: data.employee_id,
          schedule_time: data.schedule_time,
          whole_day_flag: data.whole_day_flag,
        })
        if (response.status === 200 && response.data) {
          return response.data
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("error")
      }
    },
    []
  )

  return {
    searchSchedule,
  }
}
