import React, { useContext, useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { DesktopDatePicker } from "@mui/x-date-pickers"

import { ValidEndDateProps } from ".."
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeNewSchedule/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeNewSchedule/ja"
import { CORRECTDATEREGEX } from "../../../../../utils/regular"

export const EndDateComponent: React.FC<ValidEndDateProps> = ({
  setValidEndDateTime,
  validEndDate,
  setValidEndDate,
  setDateError,
  defaultDate,
  setErrorMessages,
  checkMeetingOrSeat,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const { control, watch, setValue, getValues } = useFormContext<ScheduleForm>()
  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)
  const handleStartDateChange = (newEndDate: Date | null) => {
    const endTime = getValues("schedule.end_time")
    const startTime = getValues("schedule.start_time")
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.EndDateReset])
    }
    if (newEndDate && endTime && startTime) {
      const newEndTime = new Date(
        newEndDate.getFullYear(),
        newEndDate.getMonth(),
        newEndDate.getDate(),
        endTime.getHours(),
        endTime.getMinutes()
      )
      setValue("schedule.end_time", newEndTime)
    }
    compareTostartTime(newEndDate)
    compareToStartDate(newEndDate)
    setValue("schedule.end_date", newEndDate)
  }

  const handleStartDateBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.EndDateReset])
    }

    if (!event.target.value.match(CORRECTDATEREGEX)) {
      setValue("schedule.end_date", new Date())
      return
    }

    const dateStringArray = event.target.value.split("/")
    const newEndDate = new Date(
      Number(dateStringArray[0]),
      Number(dateStringArray[1]) - 1,
      Number(dateStringArray[2])
    )
    compareToStartDate(newEndDate)

    setValue("schedule.end_date", newEndDate)
  }

  const compareTostartTime = (newEndDate: Date | null) => {
    const startDate = getValues("schedule.start_date")
    const startTime = getValues("schedule.start_time")
    const endTime = getValues("schedule.end_time")

    if (
      !!newEndDate &&
      !!startDate &&
      !!endTime &&
      !!startTime &&
      startDate.getFullYear() <= newEndDate.getFullYear() &&
      startDate.getMonth() <= newEndDate.getMonth() &&
      startDate.getDate() <= newEndDate.getDate() &&
      startTime.getTime() >= endTime.getTime()
    ) {
      setValidEndDateTime("#d32f2f")
      setDateError(false)
    } else {
      setValidEndDateTime("rgba(0, 0, 0, 0.23)")
      setDateError(true)
    }
  }

  const compareToStartDate = (newEndDate: Date | null) => {
    const startDate = getValues("schedule.start_date")

    if (
      !!newEndDate &&
      !!startDate &&
      new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      ) >
        new Date(
          newEndDate.getFullYear(),
          newEndDate.getMonth(),
          newEndDate.getDate()
        )
    ) {
      setValidEndDate("#d32f2f")
      setDateError(false)
    } else {
      compareTostartTime(newEndDate)
      setValidEndDate("rgba(0, 0, 0, 0.23)")
      setDateError(true)
    }
  }

  //初回レンダリング時
  useEffect(() => {
    if (defaultDate) {
      setValue("schedule.end_date", new Date(defaultDate))
    }
  }, [])

  return (
    <>
      <Controller
        control={control}
        name="schedule.end_date"
        render={({ field }) => (
          <DesktopDatePicker
            {...field}
            label={translations.EndDate}
            format="yyyy/MM/dd"
            value={watch("schedule.end_date")}
            onChange={handleStartDateChange}
            maxDate={schedulableMaxDate}
            slotProps={{
              textField: {
                onBlur: handleStartDateBlur,
                sx: {
                  "& label": {
                    color:
                      validEndDate === "rgba(0, 0, 0, 0.23)"
                        ? "rgba(0, 0, 0, 0.6)"
                        : "#d32f2f",
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                  },
                  "& .MuiIconButton-root": {
                    padding: "0px",
                  },
                  "& .MuiInputAdornment-root": {
                    margin: "0px",
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    padding: "5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: validEndDate,
                    },
                  },
                },
                required: true,
                name: "end_date",
                id: "end_date",
              },
            }}
          />
        )}
      />
    </>
  )
}
