import React, { memo } from "react"

import { Box } from "@mui/material"

import { MeetingRoomType } from "../../../../models/company/useQrCode/type"
import {
  meetingRoomStyle,
  meetingRoomIconStyle,
} from "../../../../styles/meetingStyle"

interface Props {
  meetingRoom: MeetingRoomType
}

// QRコード画面：座席選択モーダル > 会議室
export const CompanyQrCodeModalMeetingRoom: React.FC<Props> = memo(
  ({ meetingRoom }: Props) => {
    const meetingRoomId = meetingRoom.id
    const meetingRoomCode = meetingRoom.meeting_room_code

    return (
      <Box
        id={`M${String(meetingRoomId)}`}
        className={"meetingRoom"}
        sx={meetingRoomStyle(
          meetingRoom.start_x,
          meetingRoom.start_y,
          meetingRoom.end_x,
          meetingRoom.end_y,
          meetingRoom.color_code
        )}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <span
          style={
            meetingRoomIconStyle(meetingRoom.color_code) as React.CSSProperties
          }
        >
          M
        </span>
        <span
          style={{
            top: "60px",
            left: "60px",
            color: meetingRoom.color_code,
            position: "absolute",
            fontSize: "30px",
          }}
        >
          {meetingRoomCode}
        </span>
      </Box>
    )
  }
)
