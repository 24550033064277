// スケジュール予約/席予約
const en = {
  SpaceBooking: "Select a Location",
  Location: "Location",
  Floor: "Floor",
  Spaces: "Spaces",
  Desks: "Desks",
  SelectedSpace: "Selected",
  Cancel: "Cancel",
  ConfirmReservation: "Confirm Reservation",
  SpaceNo: "Space No.",
  spaceName: "Space Name",
  CapacityNumber: "Capacity",
  Enter: "Enter",
  MeetingSpace: "Meeting Space",
  SpaceName: "Space Name",
  Capacity: "Capacity",
  Equipment: "Equipment",
  Description: "Description",
  Selected: "Selected",
}
export default en
