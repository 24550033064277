import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, Button, styled, Typography } from "@mui/material"

import { theme } from "../../../../config/theme"
import { FloorType } from "../../../../models/company/useBranch/type"
import { generateButtonStyle } from "../../../../utils/generateButtonStyle"

interface Props {
  floors: FloorType[]
  branchId: number
}

const FloorListWrapper = styled(Box)(({ theme }) => ({
  borderTop: "1px solid rgba(227, 227, 227)",
  paddingTop: theme.spacing(3),
}))

const defaultFloorButtonStyle = {
  width: "100%",
  borderRadius: "20px",
  margin: theme.spacing(1, "auto"),
  padding: theme.spacing(1, 2),
  fontWeight: "bold",
  fontSize: "12px",
  justifyContent: "flex-start",
  display: "flex",
}

// QRコード画面：左下、フロア一覧
export const CompanyQrCodeFloorList = ({ floors, branchId }: Props) => {
  const navigate = useNavigate()
  const urlParams = useParams<{ floor_id: string }>()

  // フロアが選択されているかどうか
  const isFloorSelected = (floorId: number) => {
    return Number(urlParams.floor_id) === floorId
  }

  return (
    <FloorListWrapper>
      <Typography
        color="primary"
        fontWeight="bold"
        fontSize="14px"
        gutterBottom
      >
        フロア情報一覧
      </Typography>
      {floors.map((floor) => {
        return (
          <Button
            key={floor.id}
            sx={generateButtonStyle(
              isFloorSelected(floor.id),
              defaultFloorButtonStyle
            )}
            onClick={() => {
              if (
                window.confirm(
                  "表示されているQRは全てリセットされます。よろしいですか？"
                )
              ) {
                navigate(
                  `/company/branches/${branchId}/floors/${floor.id}/qrcode`
                )
              }
            }}
          >
            {floor.floor_name}
            <span
              style={{
                marginLeft: "2rem",
              }}
            >
              {floor.underground_flag && "地下"}
              {floor.floor_number}F
            </span>
          </Button>
        )
      })}
    </FloorListWrapper>
  )
}
