import {
  BranchType,
  BranchFormType,
  BranchDetailType,
} from "../../models/company/useBranch/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const branchesRequest = async (): Promise<ApiResponse<BranchType[]>> => {
  try {
    const data = await api.get("/company/branches")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const createBranchRequest = async (
  params: BranchFormType
): Promise<ApiResponse<void>> => {
  try {
    const { data } = await api.post("/company/branches", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateBranchRequest = async (
  params: BranchFormType
): Promise<ApiResponse<void>> => {
  try {
    await api.patch(`/company/branches/${params?.branch_id}`, params)
    return {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const branchDetailRequest = async (params: {
  branch_id: string | undefined
}): Promise<ApiResponse<BranchDetailType>> => {
  try {
    const data = await api.get(`/company/branches/${params?.branch_id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

export const branchDeleteRequest = async (params: {
  branch_id: number
}): Promise<ApiResponse<void>> => {
  try {
    await api.delete(`/company/branches/${params?.branch_id}`)
    return {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message, status: error.response.status }
  }
}
