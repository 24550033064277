import React, { useEffect } from "react"

import { Box } from "@mui/material"

import { theme } from "../../../../../config/theme"
import {
  dragEmployeeState,
  fetchLayout,
  layoutResponseState,
  whoIsWheresState,
} from "../../../../../models/employee/useSchedule/useLayout"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { useCompanyRelations } from "../../../../../models/public/useCompanyRelations"
import { Loading } from "../../../../public/Loading"
import { EmployeeCalendarLayoutHeader } from "../EmployeeCalendarLayoutHeader"
import { EmployeeCalendarLayoutMain } from "../EmployeeCalendarLayoutMain"
import { EmployeeCalendarLayoutSidebar } from "../EmployeeCalendarLayoutSidebar"

interface Props {
  branchId: number
  floorId: number
  scheduleForm: ScheduleForm
  pathname: string
  monthlySetting?: string
}

export const EmployeeLayoutMainComponent: React.FC<Props> = (props) => {
  const { companyRelations } = useCompanyRelations()
  const { layoutResponse, setLayoutResponse } = layoutResponseState()
  const { whoIsWheres, setWhoIsWheres } = whoIsWheresState(
    props.scheduleForm.schedule.share_schedule.who_is_wheres
  )
  const { dragEmployee, setDragEmployee } = dragEmployeeState()
  const promiseLayout = async () => {
    await fetchLayout(
      {
        branch_id: props.branchId,
        floor_id: props.floorId,
        start_date: props.scheduleForm.schedule.start_date,
        end_date: props.scheduleForm.schedule.end_date,
        start_time: props.scheduleForm.schedule.whole_day_flag
          ? new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              6,
              0
            )
          : props.scheduleForm.schedule.start_time,
        end_time: props.scheduleForm.schedule.whole_day_flag
          ? new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              23,
              59
            )
          : props.scheduleForm.schedule.end_time,
      },
      setLayoutResponse,
      companyRelations.id
    )
  }
  useEffect(() => {
    if (companyRelations.id > 0) {
      promiseLayout()
    }
  }, [companyRelations])

  return (
    <>
      {layoutResponse.loading || layoutResponse.value.layout_image === "" ? (
        <Loading type="content" loading={layoutResponse.loading} />
      ) : (
        <Box display="flex" width="100%" height="100%">
          <Box flexGrow="1" sx={{ paddingRight: theme.spacing(1.875) }}>
            <Box display="flex" alignItems="center">
              <Box width="100%">
                <EmployeeCalendarLayoutHeader
                  branchId={props.branchId}
                  branchName={layoutResponse.value.branch.branch_name}
                  floorId={props.floorId}
                  floorName={layoutResponse.value.floor_name}
                  wholeDayFlagProps={props.scheduleForm.schedule.whole_day_flag}
                  startDate={props.scheduleForm.schedule.start_date}
                  startTime={props.scheduleForm.schedule.start_time}
                  endTime={props.scheduleForm.schedule.end_time}
                  scheduleForm={props.scheduleForm}
                  pathname={props.pathname}
                  layoutResponse={layoutResponse.value}
                  setLayoutResponse={setLayoutResponse}
                  whoIsWheres={whoIsWheres}
                />
              </Box>
            </Box>
            <EmployeeCalendarLayoutMain
              layoutImg={layoutResponse.value.layout_image}
              layoutX={layoutResponse.value.x}
              layoutY={layoutResponse.value.y}
              layoutScale={layoutResponse.value.scale}
              seats={layoutResponse.value.seats}
              meetingRooms={layoutResponse.value.meeting_rooms}
              areas={layoutResponse.value.areas}
              whoIsWheres={whoIsWheres}
              setWhoIsWheres={setWhoIsWheres}
              dragEmployee={dragEmployee}
              startTime={props.scheduleForm.schedule.start_time || new Date()}
              endTime={props.scheduleForm.schedule.end_time || new Date()}
            />
          </Box>
          <Box
            sx={{
              width: "270px",
              paddingLeft: theme.spacing(1.875),
              margin: "0",
              display: "flex",
              justifyContent: "center",
              borderLeft: "1px solid #E5E5E5",
            }}
          >
            <EmployeeCalendarLayoutSidebar
              scheduleForm={props.scheduleForm}
              whoIsWheres={whoIsWheres}
              setWhoIsWheres={setWhoIsWheres}
              setDragEmployee={setDragEmployee}
              pathname={props.pathname}
              branchName={layoutResponse.value.branch.branch_name}
              floorName={layoutResponse.value.floor_name}
              monthlySetting={props.monthlySetting}
            />
          </Box>
        </Box>
      )}
    </>
  )
}
