import { BrowserQRCodeReader, IScannerControls } from "@zxing/browser"
import { Result } from "@zxing/library"
import jsQR from "jsqr"
import { Point } from "jsqr/dist/locator"

import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useSignageReception } from "../../../../models/signage/useReception"
import { Loading } from "../../../public/Loading"
import { SignageMainOutlineButton } from "../../../public/SignageMainOutlineButton"
import "./style.css"

const CustomBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}))

const CancelTypography = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  "&:hover": {
    cursor: "pointer",
    color: "#454545",
  },
}))

interface LocationType {
  topRightCorner: Point
  topLeftCorner: Point
  bottomRightCorner: Point
  bottomLeftCorner: Point
  topRightFinderPattern: Point
  topLeftFinderPattern: Point
  bottomLeftFinderPattern: Point
  bottomRightAlignmentPattern?: Point | undefined
}

export const SignageReceptionQrReader = () => {
  const controlsRef = useRef<IScannerControls | null>()
  const videoRef = useRef<HTMLVideoElement>(null)
  const { qrCodeData, setQrCodeData, handleReadQrCode } = useSignageReception()
  const loadingRef = useRef<boolean>(false)
  const urlParams = useParams<{ branch_id: string }>()
  const SignageInformationId = Number(urlParams.branch_id)
  const [QrText, setQrText] = useState<string>("")
  const { resetTimer } = useSignageReception()
  const navigate = useNavigate()
  const branchId = Number(urlParams.branch_id)

  const onReadQRCode = async (result: any) => {
    if (!loadingRef.current) {
      setQrCodeData({
        ...JSON.parse(result.text),
        signage_information_id: SignageInformationId,
      })
    }
  }

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    const constraints = {
      audio: false,
      video: {
        width: { ideal: 1080 }, // QR読み取りフレーム変更
        height: { ideal: 980 }, // QR読み取りフレーム変更
        facingMode: "user", // 背面カメラ使う
        aspectRatio: 4 / 3,
      },
    }
    const codeReader = new BrowserQRCodeReader()
    codeReader.decodeFromConstraints(
      constraints,
      videoRef.current,
      (result, error, controls) => {
        if (error) {
          return
        }
        if (result) {
          onReadQRCode(result)
        }
        controlsRef.current = controls
      }
    )
    return () => {
      if (!controlsRef.current) {
        return
      }

      controlsRef.current.stop()
      controlsRef.current = null
    }
  }, [])

  useEffect(() => {
    const readQrCodeEffect = async () => {
      if (qrCodeData.schedule_information_id > 0) {
        loadingRef.current = true
        await handleReadQrCode(qrCodeData)
      }
    }
    readQrCodeEffect()
  }, [qrCodeData])

  return (
    <>
      {loadingRef.current ? (
        <Loading type="fullScreen" />
      ) : (
        <CustomBox
          style={{ width: "100％", minWidth: "100%", height: "100vh" }}
        >
          <Typography>{QrText}</Typography>
          <div className="wrapper qr_frame">
            <video
              style={{ minWidth: "100%", height: "100vh" }}
              ref={videoRef}
            />
            <div className="overlay" />
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <SignageMainOutlineButton
              label="TOPに戻る"
              subLabel="Back to TOP"
              handleClick={() => {
                resetTimer()
                navigate(`/signage/branches/${branchId}/reception/home`)
              }}
            />
          </div>
        </CustomBox>
      )}
    </>
  )
}
