import React from "react"

import { styled } from "@mui/material"

const CustomText = styled("span")(({ theme }) => ({
  fontWeight: "normal",
  fontFamily: "Noto Sans",
  marginLeft: theme.spacing(1),
}))

export const RequiredFlag = () => {
  return <CustomText>(必須)</CustomText>
}
