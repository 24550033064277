import { useLanguage } from "../contexts/LanguageContext"
import enTranslations from "../translations/employeeHome/employeeScheduleModal/en"
import jaTranslations from "../translations/employeeHome/employeeScheduleModal/ja"

const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]

// 日時のフォーマット設定
export const dateTimeFormat = (now: Date) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const year = date.getFullYear()
  const month = ("0" + (now.getMonth() + 1)).slice(-2)
  const day = ("0" + now.getDate()).slice(-2)
  const hour = ("0" + now.getHours()).slice(-2)
  const min = ("0" + now.getMinutes()).slice(-2)

  return `${year}-${month}-${day}T${hour}:${min}`
}

// 本日の日付
export const todayDateFormat = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = ("0" + (today.getMonth() + 1)).slice(-2)
  const day = ("0" + today.getDate()).slice(-2)

  return `${year}/${month}/${day}`
}

export const billingDateFormat = (now: Date) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${month}/${day}/${year}`
}

export const jaDateFormat = (now: Date) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const year = date.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)

  return `${year}${translations.year}${month}${translations.month}${day}${translations.day}`
}

export const calendarDateFormat = (now: Date) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const year = now.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)

  return `${year}/${month}/${day}`
}

export const dateTimeJaFormat = (now: Date) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)
  const hour = ("0" + date.getHours()).slice(-2)
  const min = ("0" + date.getMinutes()).slice(-2)
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return `${month}${translations.month}${day}${translations.day} ${hour}:${min}`
}

export const timeFormat = (now: Date) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const hour = ("0" + date.getHours()).slice(-2)
  const min = ("0" + date.getMinutes()).slice(-2)

  return `${hour}:${min}`
}

export const dateTimeOnlyFormat = (date: Date) => {
  const dateString = date.toLocaleString()
  const dateFormat = new Date(dateString.replace(/-/g, "/"))
  const hour = ("0" + new Date(dateFormat).getHours()).slice(-2)
  const min = ("0" + new Date(dateFormat).getMinutes()).slice(-2)
  return `${hour}:${min}`
}

export const dateOnlyFormat = (date: Date) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const dateString = date.toLocaleString()
  const dateFormat = new Date(dateString.replace(/-/g, "/"))
  const month = ("00" + (new Date(dateFormat).getMonth() + 1)).slice(-2)
  const day = ("00" + new Date(dateFormat).getDate()).slice(-2)
  return `${month}${translations.month}${day}${translations.day}`
}

export const dateAndDayOfWeekFormat = (now: Date) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const month = date.getMonth() + 1
  const day = date.getDate()
  const weekNum = date.getDay()
  const targetWeek = dayOfWeek[weekNum]

  return `${month}/${day}(${targetWeek})`
}
// stringからstringのパターン
export const formatDateForSafari = (dateStr: string) => {
  return dateStr.replace(/ /, "T").replace(/ \+0900/, "")
}
// Dateオブジェクトを文字列に変換
export const formatDateForLocale = (date: Date, locale = "ja-JP") => {
  return date.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  })
}
// Dateからstringのパターン
export const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}`
}

// start_timeとend_timeを指定のフォーマットに変換するヘルパー関数
export const formatScheduleTime = (start: string, end: string) => {
  const startDateTime = new Date(start)
  const endDateTime = new Date(end)
  const startDate = `${startDateTime.getMonth() + 1}/${startDateTime.getDate()}`
  const startTime = `${startDateTime.getHours()}:${String(
    startDateTime.getMinutes()
  ).padStart(2, "0")}`
  const endDate = `${endDateTime.getMonth() + 1}/${endDateTime.getDate()}`
  const endTime = `${endDateTime.getHours()}:${String(
    endDateTime.getMinutes()
  ).padStart(2, "0")}`

  return `${startDate} ${startTime} 〜 ${endDate} ${endTime}`
}

//日時データを【月/日 時間:分】の形式に変換
const formatDateTimeFromString = (dateTimeStr: string) => {
  const dateTime = new Date(dateTimeStr)
  const formattedDate = `${dateTime.getMonth() + 1}/${dateTime.getDate()}`
  const formattedTime = `${dateTime.getHours()}:${String(
    dateTime.getMinutes()
  ).padStart(2, "0")}`
  return `${formattedDate} ${formattedTime}`
}

// scheduleの開始日時〜終了日時の表示
export const formatScheduleTimeRangeForDisplay = (
  start: string,
  end: string
) => {
  const formattedStart = formatDateTimeFromString(start)
  const formattedEnd = formatDateTimeFromString(end)

  return `${formattedStart}〜${formattedEnd}`
}
