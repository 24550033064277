import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
  styled,
  InputLabel,
  SelectChangeEvent,
  Switch,
} from "@mui/material"

import { usePasswordExpireMonthes } from "../../../../models/company/usePasswordExpireMonthes"
import { useSignage } from "../../../../models/company/useSignage"
import { useAccounts } from "../../../../models/employee/useAccounts"
import {
  AkerunDoorType,
  FloorType,
  SignageSettingType,
} from "../../../../models/signage/useSetting/type"
import { pageType } from "../../../../utils/location"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { Loading } from "../../../public/Loading"
import { MobileErrorText } from "../../../public/MobileErrorText"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

const CustomGridItem = styled(Grid)(() => ({
  width: "80%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

interface Props {
  loading: boolean
  branchId: number
  floorId: number
  meetingRoomId: number
  selectedBranch: SignageSettingType
  selectedFloor: FloorType
  signageDisplayData: SignageSettingType[]
  changeSelectValue: (e: SelectChangeEvent<number>) => void
  akerunDoorRelation: AkerunDoorType[]
}

export const SignageSetting = ({
  loading,
  branchId,
  floorId,
  meetingRoomId,
  selectedBranch,
  selectedFloor,
  signageDisplayData,
  changeSelectValue,
  akerunDoorRelation,
}: Props) => {
  const navigate = useNavigate()

  const [floorOrMeetingRoom, setFloorOrMeetingRoom] = useState<number>(0)
  const [isMeetingRoomSelectOpen, setIsMeetingRoomSelectOpen] =
    useState<boolean>(false)
  const [isSelectDoorOpen, setSelectDoorOpen] = useState<boolean>(false)
  // メール送信の表示更新
  const [isSelectMailOpen, setSelectMailOpen] = useState<boolean>(false)

  const [isReceptionSelectOpen, setIsReceptionSelectOpen] =
    useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)

  // 設置場所が会議室だった場合、会議室選択のプルダウンを表示させる
  // 設置場所が受付だった場合、扉選択のプルダウンを表示させる
  const handlePlaceSelect = (event: SelectChangeEvent<number>) => {
    if (event.target.value === 1) {
      setFloorOrMeetingRoom(1) //会議室
      setIsMeetingRoomSelectOpen(true)
      setIsReceptionSelectOpen(false)
      setSelectMailOpen(false)
      // 紐づく扉トグルを非表示
      setSelectDoorOpen(false)
    } else if (event.target.value === 2) {
      setFloorOrMeetingRoom(2) //フロア
      setIsMeetingRoomSelectOpen(false)
      setIsReceptionSelectOpen(false)
      setSelectMailOpen(false)
      // 紐づく扉トグルを非表示
      setSelectDoorOpen(false)
    } else {
      setFloorOrMeetingRoom(3) //受付
      setIsMeetingRoomSelectOpen(false)
      // 紐づく扉トグルを表示
      setSelectDoorOpen(true)
      setSelectMailOpen(true)
    }
  }

  const { fetchPasswordChangeTimes } = useAccounts()
  const { fetchPasswordExpireMonthes } = usePasswordExpireMonthes()
  const { control, handleSubmit, onCreateSignageSubmit } = useSignage()

  useEffect(() => {
    fetchPasswordChangeTimes().then((data) => {
      if (data) {
        if (new Date() > new Date(data.password_change_times)) {
          localStorage.setItem("expiredChangePassword", "true")
          navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
        }
      }
    })
    fetchPasswordExpireMonthes()
  }, [])

  // バリデーションチェック
  const checkValidation = () => {
    if (branchId === 0 || floorId === 0 || floorOrMeetingRoom === 0) {
      setErrorMessages(["拠点・フロア・設置場所を全て選択してください"])
      setErrorMessageOpen(true)
      return false
    } else if (floorOrMeetingRoom === 1 && meetingRoomId !== 0) {
      setErrorMessages([])
      setErrorMessageOpen(false)
      return true
    } else if (floorOrMeetingRoom === 2 || floorOrMeetingRoom === 3) {
      setErrorMessages([])
      setErrorMessageOpen(false)
      return true
    } else {
      setErrorMessages(["会議室を選択してください"])
      setErrorMessageOpen(true)
      return false
    }
  }
  // バリデーションに問題がなければ該当のページへ遷移させる
  const handleApply = () => {
    if (checkValidation() && floorOrMeetingRoom === 1) {
      // サイネージ登録処理
      handleSubmit(onCreateSignageSubmit)().then(() => {
        navigate(`/signage/meeting-rooms/${meetingRoomId}`)
      })
    } else if (checkValidation() && floorOrMeetingRoom === 2) {
      navigate(`/signage/floors/${floorId}/map`)
    } else if (checkValidation() && floorOrMeetingRoom === 3) {
      // サイネージ登録処理
      handleSubmit(onCreateSignageSubmit)()
      // navigate(`/signage/branches/${branchId}/reception/home`)
    }
  }

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <CustomGridContainer
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        spacing={3}
      >
        <CustomGridItem item mb={3}>
          <Typography variant="h6" color="#707070" fontWeight="bold">
            設置場所を選択してください
          </Typography>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            >
              拠点
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormControl fullWidth>
              <InputLabel htmlFor="branch_id">拠点</InputLabel>
              <Controller
                control={control}
                name="branch_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="branch_id"
                    name="branch_id"
                    label="拠点"
                    fullWidth
                    value={branchId}
                    variant="outlined"
                    onChange={(e) => {
                      changeSelectValue(e)
                      field.onChange(e)
                    }}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem disabled={true} key={0} value={0}>
                      拠点
                    </MenuItem>
                    {signageDisplayData.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.branch_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            >
              フロア
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormControl fullWidth>
              <InputLabel htmlFor="floor_id">フロア</InputLabel>
              <Controller
                control={control}
                name="floor_id"
                render={({ field }) => (
                  <Select
                    id="floor_id"
                    name="floor_id"
                    label="フロア"
                    fullWidth
                    value={floorId}
                    variant="outlined"
                    onChange={(e) => {
                      changeSelectValue(e)
                      field.onChange(e)
                    }}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem disabled={true} key={0} value={0}>
                      フロア
                    </MenuItem>
                    {branchId !== 0 &&
                      selectedBranch?.floors.map((floor) => (
                        <MenuItem key={floor.id} value={floor.id}>
                          {floor.floor_name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            >
              設置場所
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormControl fullWidth>
              <InputLabel htmlFor="place">設置場所</InputLabel>
              <Select
                name="place"
                label="設置場所"
                value={floorOrMeetingRoom}
                fullWidth
                variant="outlined"
                onChange={(event) => {
                  handlePlaceSelect(event)
                }}
                sx={{
                  background: "rgba(112,112,112,0.05)",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem disabled={true} key={0} value={0}>
                  設置場所
                </MenuItem>
                <MenuItem key={1} value={1}>
                  会議室
                </MenuItem>
                <MenuItem key={2} value={2}>
                  フロア
                </MenuItem>
                <MenuItem key={3} value={3}>
                  受付
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </CustomGridItem>
        {isSelectDoorOpen && akerunDoorRelation[0].id != "" && (
          <CustomGridItem item container spacing={1}>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="caption"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                紐づく扉を登録する
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="door_id"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => {
                        setIsReceptionSelectOpen(!isReceptionSelectOpen)
                        field.onChange("")
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </CustomGridItem>
        )}
        {isReceptionSelectOpen && (
          <CustomGridItem item container spacing={1}>
            <Grid item xs={12} sm={3}>
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                扉
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormControl fullWidth>
                <InputLabel htmlFor="door_id">扉</InputLabel>
                <Controller
                  control={control}
                  name="door_id"
                  render={({ field }) => (
                    <Select
                      name="door_id"
                      label="扉"
                      // value={floorOrMeetingRoom}
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        扉
                      </MenuItem>
                      {akerunDoorRelation.map((door) => (
                        <MenuItem key={door.id} value={door.id}>
                          {door.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </CustomGridItem>
        )}

        {isMeetingRoomSelectOpen && (
          <>
            <CustomGridItem item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  会議室
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="meeting_room_id">会議室</InputLabel>
                  <Select
                    id="meeting_room_id"
                    name="meeting_room_id"
                    label="会議室"
                    fullWidth
                    value={meetingRoomId}
                    variant="outlined"
                    onChange={changeSelectValue}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem disabled={true} key={0} value={0}>
                      選択してください
                    </MenuItem>
                    {selectedFloor &&
                      selectedFloor.layouts.meeting_rooms.map((meetingRoom) => (
                        <MenuItem key={meetingRoom.id} value={meetingRoom.id}>
                          {meetingRoom.meeting_room_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </CustomGridItem>
          </>
        )}
        {isSelectMailOpen && (
          <>
            <CustomGridItem item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  チェックイン時に{"　"}メールで通知する
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="notification_flag"
                    defaultValue={true}
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => {
                          field.onChange(e)
                        }}
                        checked={field.value}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </CustomGridItem>
          </>
        )}
        <CustomGridItem item mt={1}>
          {errorMessageOpen && MobileErrorText(errorMessages)}
        </CustomGridItem>
        <CustomGridItem item mt={2}>
          <ReflectButton
            label={"適用"}
            handleClick={() => {
              handleApply()
              // handleSubmit(onCreateSignageSubmit)()
            }}
          />
        </CustomGridItem>
      </CustomGridContainer>
    </>
  )
}
