import React, { ReactNode, useEffect, useState } from "react"
import { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"

import { Box, styled } from "@mui/material"

import { Header } from "../components/public/Header"
import { Loading } from "../components/public/Loading"
import { CompanySidebar, EmployeeSidebar } from "../components/public/Sidebar"
import { useCompany } from "../models/company/useCompany"
import { useIcon } from "../models/public/useIcon"
import { RoleContext } from "../providers/RoleProvider"

// TODO
const CustomHeader = styled(Header)(() => ({
  zIndex: 100,
  marginBottom: "-10px",
}))

const CustomSidebarContent = styled(Box)({
  zIndex: 200,
  display: "flex",
  height: "calc(100vh - 50px)",
})

const CustomMainContent = styled(Box)({
  width: "100%",
  backgroundColor: "#F7F7F7",
  borderTopLeftRadius: "10px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})

interface LocationStateProps {
  updatedEmployeeId: number
}

export const CommonTemplate = ({ children }: { children: ReactNode }) => {
  const companyObject = useCompany()
  const iconObject = useIcon()
  const headerObject = companyObject.headerCompanyInfo
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)
  const { isTeamLeader } = useContext(RoleContext)

  useEffect(() => {
    companyObject.fetchCompany().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (headerObject && !headerObject.is_temporary) {
      iconObject.fetchFile(headerObject.company.id, headerObject.employee.id)
    }
  }, [headerObject])

  // 従業員編集でログイン中ユーザーの情報を編集した際に
  // location.stateから変更された従業員のidを受け取り最新のデータと同期する→ヘッダーに反映
  useEffect(() => {
    const locationState = location.state as LocationStateProps
    if (locationState?.updatedEmployeeId) {
      if (locationState?.updatedEmployeeId === headerObject?.employee.id) {
        const refetchData = async () => {
          await Promise.all([
            companyObject.fetchCompany(),
            iconObject.fetchFile(
              headerObject.company.id,
              headerObject.employee.id
            ),
          ])
        }
        refetchData()
      }
    }
  }, [location.state])

  return (
    <>
      {loading ? (
        <Loading type="fullScreen" />
      ) : (
        <>
          {headerObject ? (
            <>
              <Box
                sx={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CustomHeader
                  {...companyObject}
                  {...iconObject}
                  loading={iconObject.loading}
                />
                <CustomSidebarContent>
                  {location.pathname.indexOf("company/") === 1 ? (
                    <CompanySidebar />
                  ) : (
                    <EmployeeSidebar />
                  )}
                  <CustomMainContent>{children}</CustomMainContent>
                </CustomSidebarContent>
              </Box>
            </>
          ) : (
            <Navigate to="/company/register-info" />
          )}
        </>
      )}
    </>
  )
}
