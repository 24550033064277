import React from "react"

import { Box, styled, Typography } from "@mui/material"

import { ScheduleDisplayMonthType } from "../../../../../models/employee/useSchedule/months/type"
import { MonthMultipleDaySchedules } from "./MonthMultipleDaySchedules"
import { MonthOtherDaySchedules } from "./MonthOtherDaySchedules"
import { MonthSingleDaySchedules } from "./MonthSingleDaySchedules"
import { MonthStatusSchedules } from "./MonthStatusSchedules"

const CustomBox = styled(Box)(() => ({
  borderColor: "#C1C1C1",
  orderBottomWidth: "1px",
  height: "160px",
  width: "14%",
}))

interface Props {
  calendars: ScheduleDisplayMonthType
  getEventWidth: (startDay: Date, endDay: Date) => number
  displayReserved: boolean
  displayNonReserved: boolean
}

export const EmployeeScheduleMonthTable: React.FC<Props> = ({
  calendars,
  getEventWidth,
  displayReserved,
  displayNonReserved,
}: Props) => {
  return (
    <>
      <Box>
        {calendars.map((week, index) => {
          return (
            <Box key={index} display="flex">
              {week.map((day, index2) => (
                <CustomBox key={index2} border={1}>
                  <Typography
                    align="right"
                    margin="3px 5px 5px 0px"
                    fontSize="14px"
                    fontFamily="Noto Sans"
                  >
                    {new Date(day.date).getDate()}
                  </Typography>
                  {day.status_schedules.length > 0 ? (
                    <MonthStatusSchedules
                      day={day}
                      displayReserved={displayReserved}
                      displayNonReserved={displayNonReserved}
                    />
                  ) : (
                    <Box height="24px" mb="5px"></Box>
                  )}
                  {day.multipleDisplayCount < 3 &&
                  day.singleDisplayCount > 0 &&
                  !day.multiple_schedules.some((s) => s.index === 0) ? (
                    <>
                      <MonthSingleDaySchedules
                        day={day}
                        displayReserved={displayReserved}
                        displayNonReserved={displayNonReserved}
                      />
                      <MonthMultipleDaySchedules
                        day={day}
                        getEventWidth={getEventWidth}
                        displayReserved={displayReserved}
                        displayNonReserved={displayNonReserved}
                      />
                    </>
                  ) : (
                    <>
                      {day.multipleDisplayCount > 0 && (
                        <MonthMultipleDaySchedules
                          day={day}
                          getEventWidth={getEventWidth}
                          displayReserved={displayReserved}
                          displayNonReserved={displayNonReserved}
                        />
                      )}
                      {day.singleDisplayCount > 0 && (
                        <MonthSingleDaySchedules
                          day={day}
                          displayReserved={displayReserved}
                          displayNonReserved={displayNonReserved}
                        />
                      )}
                    </>
                  )}
                  {day.otherDisplayCount > 0 && (
                    <MonthOtherDaySchedules day={day} />
                  )}
                </CustomBox>
              ))}
            </Box>
          )
        })}
      </Box>
    </>
  )
}
