const en = {
  Disconnect: "Disconnect",
  Connect: "Connect",
  Cancel: "Cancel",
  ConnectOutLook: "Connection with Outlook",
  ConnectMicrosoftAccount:
    "Connect to your Microsoft account to sync with your Outlook calendar events.",
  ConnectGoogle: "Connect with Google",
  ConnectGoogleAccount:
    "Connect your Google account to sync with your Google Calendar events.",
  DisconnectGoogle: "Disconnect from Google Calendar",
  DisconnectGoogleCalendar: "Disconnect from Google Calendar schedules.",
  CancelOutlook: "Cancel with Outlook",
  UnlinkFromOutlook: "Unlink from Outlook Calendar schedules.",
}
export default en
