import React from "react"

import { Grid, Typography } from "@mui/material"

import { BranchDetailType } from "../../../../models/company/useBranch/type"
import { translatePrefecture } from "../../../../utils/prefecture"

interface Props {
  branch: BranchDetailType
}

// QRコード画面：左上、拠点情報詳細
export const CompanyQrCodeBranchDetail = ({ branch }: Props) => {
  return (
    <>
      <Typography
        color="primary"
        fontWeight="bold"
        fontSize="14px"
        gutterBottom
      >
        {branch.branch_name}
      </Typography>
      <Typography fontSize="12px" gutterBottom>
        {branch.branch_postal_code}
      </Typography>
      <Typography fontSize="12px" gutterBottom>
        {translatePrefecture(branch.branch_prefecture_id)}
        {branch.branch_city}
        {branch.branch_house_number}
        {branch.branch_building_name}
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item sm={5.5}>
          <Typography
            color="primary"
            fontWeight="bold"
            fontSize="12px"
            gutterBottom
          >
            席数
          </Typography>
        </Grid>
        <Grid item sm={5.5}>
          <Typography fontSize="12px" gutterBottom>
            {branch.seat_count}席
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item sm={5.5}>
          <Typography
            color="primary"
            fontWeight="bold"
            fontSize="12px"
            gutterBottom
          >
            従業員数
          </Typography>
        </Grid>
        <Grid item sm={5.5}>
          <Typography fontSize="12px" gutterBottom>
            {branch.employee_count}人
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item sm={5.5}>
          <Typography
            color="primary"
            fontWeight="bold"
            fontSize="12px"
            gutterBottom
          >
            制限席数
          </Typography>
        </Grid>
        <Grid item sm={5.5}>
          <Typography fontSize="12px" gutterBottom>
            {branch.restricted_seat_count}席
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item sm={5.5}>
          <Typography
            color="primary"
            fontWeight="bold"
            fontSize="12px"
            gutterBottom
          >
            拠点責任者名
          </Typography>
        </Grid>
        <Grid item sm={5.5}>
          <Typography fontSize="12px" gutterBottom>
            {branch.branch_leader_name}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
