import { EmployeeListResponseType } from "../../../models/company/useEmployee/type"
import { SearchEmployeeListRequestType } from "../../../models/company/useEmployeeListSearch/type"
import { api, ApiResponse } from "../../config/axiosConfig"

export const employeeListSearchRequest = async (
  params: SearchEmployeeListRequestType
): Promise<ApiResponse<EmployeeListResponseType[]>> => {
  try {
    const data = await api.get("/company/employees/search_employee_list", {
      params: params,
    })
    return data
    // eslint-disable-next-line
  } catch (error: any) {
    return { error: error.response.data }
  }
}
