export const median = (numbers: number[]) => {
  if (numbers.length === 0) {
    return 0
  }
  const halfPositionIndex = (numbers.length / 2) | 0
  const ascendedNumbers = numbers.sort((a, b) => {
    return a - b
  })

  if (ascendedNumbers.length % 2) {
    return ascendedNumbers[halfPositionIndex]
  }
  return (
    (ascendedNumbers[halfPositionIndex - 1] +
      ascendedNumbers[halfPositionIndex]) /
    2
  )
}

export const average = (numbers: number[]) => {
  if (numbers.length === 0) {
    return 0
  }
  const sum = numbers.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue
  )

  return sum / numbers.length
}

export const max = (numbers: number[]) => {
  if (numbers.length === 0) {
    return 0
  }
  const maxValue = numbers.reduce((previous, next) => Math.max(previous, next))

  return maxValue
}

export const min = (numbers: number[]) => {
  if (numbers.length === 0) {
    return 0
  }
  const minValue = numbers.reduce((previous, next) => Math.min(previous, next))

  return minValue
}

// 特定の桁で四捨五入する際に使う。
// 例
// roundBySpecificDigit(12.34, -1) => 12.3
// roundBySpecificDigit(12.34, 1) => 10
// baseDigit...四捨五入したい位(...-2, -1, 0, 1, 2,...)
export const roundBySpecificDigit = (value: number, baseDigit: number) => {
  const baseValue = Math.pow(10, -1 * (baseDigit + 1))

  if (baseValue <= 1) {
    // 1の位以上を四捨五入する時
    return Math.round(value * baseValue) * Math.pow(10, baseDigit) // 整数値での計算になるようにする
  } else {
    // 小数点以下を四捨五入する時
    return Math.round(value * baseValue) / baseValue
  }
}
