import { ConfirmRoleResponseType } from "../../models/public/useAuth/login/type"
import { api, ApiResponse } from "../config/signageAxiosConfig"

// サイネージログインで拠点管理者以上かを確認するAPI
export const confirmRoleRequest = async (params: {
  email: string
}): Promise<ApiResponse<ConfirmRoleResponseType>> => {
  try {
    const data = await api.get("/signage/auths/confirm_employee_role", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
