import { Team } from "../models/public/useCompanyRelations/type"

export const handleTeamNameChange = (teamIds: number[], teams: Team[]) => {
  const targetTeams = teams.filter((team) => {
    return teamIds.find((teamId) => teamId === team.id)
  })

  return targetTeams.map((targetTeam) => {
    return targetTeam.team_name
  })
}
