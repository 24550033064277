import React from "react"
import { useNavigate } from "react-router-dom"

import { Box, Typography, Paper, styled, CircularProgress } from "@mui/material"

import { CompanyDashboardHeader } from "../../components/company/dashboard/CompanyDashboardHeader"
import { CompanyDashboardMain } from "../../components/company/dashboard/CompanyDashboardMain"
import { AlertContent } from "../../components/public/AlertContent"
import { AlertError } from "../../components/public/AlertError"
import { LayoutPaper } from "../../components/public/LayoutPaper"
import { useLanguage } from "../../contexts/LanguageContext"
import { useDashboard } from "../../models/company/useDashboard"
import { useCompanyRelations } from "../../models/public/useCompanyRelations"
import enTranslations from "../../translations/companyHome/en"
import jaTranslations from "../../translations/companyHome/ja"

// TODO
// ページ遷移未実装分あり（ダッシュボード、URL決まったら実装）

const CustomMainBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.875, 2.5, 0, 1.875),
}))

const DashboardTitle = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: "#707070",
  lineHeight: "24px",
  letterSpacing: "0.5",
}))

const CustomTitleHeader = styled(Box)(() => ({
  width: "220px",
  borderBottomStyle: "solid",
  borderColor: "#808A9F",
  color: "#808A9F",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.5",
  textAlign: "center",
  cursor: "pointer",
}))

const DashboardHeader = styled(Box)(({ theme }) => ({
  width: "220px",
  marginLeft: theme.spacing(0.5),
  borderBottomStyle: "solid",
  borderColor: "#22BA9D",
  color: "#22BA9D",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.5",
  textAlign: "center",
  textDecoration: "none",
  cursor: "pointer",
}))

const HeaderLine = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  borderBottomStyle: "solid",
  borderColor: "#a9a9a9",
  borderWidth: "thin",
  flexGrow: 1,
}))

const CustomMessage = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1.875, 0),
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: "#707070",
  lineHeight: "24px",
  letterSpacing: "0.5",
}))

const CustomFooter = styled(Box)(({ theme }) => ({
  marginBottom: "20px",
  height: "20px",
  marginLeft: theme.spacing(1.875),
  marginRight: theme.spacing(2.5),
  textAlign: "right",
  color: "#707070",
  fontWeight: "bold",
}))

const CustomSubContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.875),
  borderRadius: "8px",
  border: "none",
  boxShadow: "0px 1px 4px #15223214",
  backgroundColor: "#f9f9f9",
  minHeight: "280px",
  fontSize: "12px",
  "& a": {
    display: "block",
    margin: "10px 0",
    color: "#707070",
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
  },
  "& a:hover": {
    textDecoration: "underline",
  },
}))

export const CompanyDashboardPage = () => {
  const navigate = useNavigate()
  const { companyRelations } = useCompanyRelations()
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const {
    branchId,
    floorId,
    changeSelectValue,
    layoutImg,
    layout,
    seats,
    meetingRooms,
    areas,
    loading,
    errorMessageObject,
    updateArticles,
    defectArticles,
    articlesLoading,
  } = useDashboard()

  return (
    <>
      <CustomMainBox>
        <DashboardTitle>ダッシュボード</DashboardTitle>
        <Box
          sx={{
            display: "flex",
            marginTop: "15x",
          }}
        >
          <CustomTitleHeader
            onClick={() => {
              navigate("/company/home")
            }}
          >
            利用準備
          </CustomTitleHeader>
          <DashboardHeader
            onClick={() => {
              navigate("/company/dashboard")
            }}
          >
            ダッシュボード
          </DashboardHeader>
          <HeaderLine />
        </Box>
        <CustomMessage>
          <span>オフィスの状況を見る</span>
        </CustomMessage>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Box style={{ width: "72%" }}>
            <CompanyDashboardHeader
              branchId={branchId}
              floorId={floorId}
              changeSelectValue={changeSelectValue}
              companyRelations={companyRelations}
            />
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "white",
                  minHeight: "585px",
                  marginTop: "15px",
                  padding: "15px",
                  borderRadius: "8px",
                  border: "none",
                  boxShadow: "0px 1px 4px #15223214",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <LayoutPaper>
                {layout && (
                  <CompanyDashboardMain
                    seats={seats}
                    meetingRooms={meetingRooms}
                    areas={areas}
                    layoutImg={layoutImg}
                    layout={layout}
                  />
                )}
              </LayoutPaper>
            )}
          </Box>
          <Box
            sx={{
              width: "25%",
            }}
          >
            <CustomMessage
              sx={{
                paddingTop: "0",
              }}
            >
              <span>アップデート情報</span>
            </CustomMessage>
            {articlesLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "350px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <CustomSubContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "280px",
                }}
              >
                <Box>
                  {updateArticles.slice(0, 7).map((article) => (
                    <a
                      key={article.url}
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      •
                      {article.title.length > 22
                        ? `${article.title.slice(0, 22)}...`
                        : article.title}
                    </a>
                  ))}
                </Box>
                <a
                  href="https://workagile.jp/index.php/info/#update"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  もっと見る
                </a>
              </CustomSubContent>
            )}
            <CustomMessage>
              <span>不具合・障害報告</span>
            </CustomMessage>
            {articlesLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "350px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <CustomSubContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "280px",
                }}
              >
                <Box>
                  {defectArticles.slice(0, 7).map((article) => (
                    <a
                      key={article.url}
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      •
                      {article.title.length > 22
                        ? `${article.title.slice(0, 22)}...`
                        : article.title}
                    </a>
                  ))}
                </Box>
                <a
                  href="https://workagile.jp/index.php/info/#defect"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  もっと見る
                </a>
              </CustomSubContent>
            )}
          </Box>
        </Box>
      </CustomMainBox>
      <CustomFooter>
        <Typography
          sx={{
            fontSize: "10px",
            fontFamily: "Noto Sans",
            lineHeight: "20px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          variant="overline"
        >
          <a
            href="https://workagile.jp/index.php/terms/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
              marginRight: "10px",
              fontSize: "15px",
            }}
          >
            {translations.TermsService}
          </a>
          <a
            href="https://workagile.jp/index.php/privacy/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
              fontSize: "15px",
            }}
          >
            {translations.PrivacyPolicy}
          </a>
        </Typography>
      </CustomFooter>
      <AlertContent />
      <AlertError
        isMessageOpen={errorMessageObject.isOpenMessage}
        closeMessage={errorMessageObject.closeMessage}
        alertMessage={errorMessageObject.alertMessage}
      />
    </>
  )
}
