import React, { memo, ReactNode } from "react"

import { Box, styled } from "@mui/material"

export const CustomPaper = styled(Box)({
  width: "100%",
  minHeight: "92%",
  maxHeight: "740px",
  overflow: "auto",
  boxSizing: "border-box",
  padding: "10px 5px",
})

export const CompanyLayoutSideberTemplate = memo(
  ({ children }: { children: ReactNode }) => {
    return (
      <>
        <CustomPaper>{children}</CustomPaper>
      </>
    )
  }
)
