import React from "react"

import { MonthCalendar } from "../../../../components/employee/calendar/months/Main"
import { AlertContent } from "../../../../components/public/AlertContent"
import { ContentEmployeePaper } from "../../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../../components/public/ContentTemplate"

export const CalendarOfMonthsPage = () => {
  return (
    <>
      <AlertContent />
      <ContentTemplate>
        <ContentEmployeePaper>
          <MonthCalendar />
        </ContentEmployeePaper>
      </ContentTemplate>
    </>
  )
}
