import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"

import React, { useEffect } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  Checkbox,
  CheckboxProps,
  SvgIcon,
  Box,
  IconButton,
  FormControlLabel,
  Slider,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { EmployeeLayoutModeSelect } from "../../../../components/employee/layouts/common/EmployeeLayoutModeSelect"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { useSchedulableMonthes } from "../../../../models/company/useSchedulableMonthes"
import { EmployeeLayoutRequestType } from "../../../../models/employee/useLayout/type"
import {
  Branch,
  CompanyRelationsType,
} from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../../../../translations/employeeStatus/employeeStatusHeader/ja"
import { fifteenMinutesIntervalHours } from "../../../../utils/hours"
import { useOpen } from "../../../../utils/isOpen"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"

const CustomTypography = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  color: "#22ba9d",
  lineHeight: "17px",
  letterSpacing: "0.6px",
})

const UncheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      border: "1px solid #22BA9D",
      backgroundColor: "white",
    }}
  />
)

const CheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      backgroundColor: "#22BA9D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SvgIcon
      sx={{
        color: "white",
        fontSize: "1.2rem",
      }}
    >
      <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
    </SvgIcon>
  </Box>
)

const CustomCheckbox: React.FC<CheckboxProps> = (props) => (
  <Checkbox icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...props} />
)

interface Props {
  companyRelations: CompanyRelationsType
  changeSelectValue: (e: SelectChangeEvent<number>) => void
  handleLayoutChange: (params: EmployeeLayoutRequestType) => void
  branchId: number
  floorId: number
  filterSeatSchedules: (scheduled_date: string, start_time: string) => void
  filterMeetingRoomSchedules: (
    scheduled_date: string,
    start_time: string
  ) => void
  handleFilterWholeDaySchedule: (
    scheduledDate: string,
    wholeDayFlag: boolean,
    startTime?: string
  ) => void
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  dayOfWeek: string[]
  startDate: Date
  endDate: Date
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  formatDate: (date: Date) => string
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  handleFilterSchedule: (wholeDayFlag: boolean) => void
  selectedBranch: Branch
}

// 拠点・フロアおよび日時の切り替えを行う
export const EmployeeLayoutHeader: React.FC<Props> = ({
  companyRelations,
  changeSelectValue,
  handleLayoutChange,
  branchId,
  floorId,
  filterSeatSchedules,
  filterMeetingRoomSchedules,
  handleFilterWholeDaySchedule,
  date,
  setDate,
  setWeekdayNumber,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  weekdayNumber,
  startDate,
  endDate,
  timeIndex,
  setTimeIndex,
  formatDate,
  setStartDate,
  setEndDate,
  wholeDayFlag,
  setWholeDayFlag,
  loading,
  handleFilterSchedule,
  selectedBranch,
}: Props) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const time = fifteenMinutesIntervalHours[timeIndex]
  const scheduleCalendar = useOpen()

  const { schedulableMonthes, fetchSchedulableMonthes } =
    useSchedulableMonthes()

  const schedulableMaxDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + schedulableMonthes,
    new Date().getDate()
  )

  // 時刻を切り替えた時の挙動
  const handleTimeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setTimeIndex(value)
      const time = fifteenMinutesIntervalHours[value]
      filterSeatSchedules(formatDate(date), time)
      filterMeetingRoomSchedules(formatDate(date), time)
    }
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // 初回レンダリング時、スライダーの初期値を現在時刻に合わせる
  useEffect(() => {
    // TODO いずれ共通化する
    const nowTime = new Date()
    let nowMinutes = nowTime.getMinutes()
    const nowHours = nowTime.getHours()
    // 現在時刻が6時から23時代の間の場合
    if (6 <= nowHours && nowHours <= 23) {
      switch ((nowMinutes / 15) | 0) {
        case 0:
          nowMinutes = 0
          break
        case 1:
          nowMinutes = 15
          break
        case 2:
          nowMinutes = 30
          break
        case 3:
          nowMinutes = 45
          break
      }
      //timeIndexをセットする
      setTimeIndex(
        fifteenMinutesIntervalHours.findIndex(
          (fifteenMinutesIntervalHour) =>
            fifteenMinutesIntervalHour ===
            nowHours + ":" + String(nowMinutes).padStart(2, "0")
        )
      )
    }
    fetchSchedulableMonthes()
  }, [])

  // 2日以上日付をずらした場合にAPIリクエストを再送信する。その後の挙動
  useEffect(() => {
    if (!loading) {
      handleFilterSchedule(wholeDayFlag)
    }
  }, [loading])

  // 終日のチェックボックスを外した時の挙動
  useEffect(() => {
    if (wholeDayFlag) {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag)
    } else {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag, time) // falseの場合は日付と時刻をベースに予定をfilterし直す
    }
  }, [wholeDayFlag])

  // フロアを切り替えるたびにレイアウト情報を取得し直す
  useEffect(() => {
    if (floorId > 0) {
      handleLayoutChange({
        branch_id: branchId,
        floor_id: floorId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      })
    }
  }, [floorId])

  // 日付を切り替えた場合の挙動
  useEffect(() => {
    // if (floorId > 0) {
    //   if (date < startDate || date > endDate) {
    //     const newStartDate = new Date(
    //       date.getFullYear(),
    //       date.getMonth(),
    //       date.getDate() - 1
    //     )
    //     const newEndDate = new Date(
    //       date.getFullYear(),
    //       date.getMonth(),
    //       date.getDate() + 1
    //     )
    //     const refreshSchedule = async () => {
    //       await Promise.all([
    //         handleLayoutChange({
    //           branch_id: branchId,
    //           floor_id: floorId,
    //           start_date: formatDate(newStartDate),
    //           end_date: formatDate(newEndDate),
    //         }),
    //       ])
    //     }
    //     refreshSchedule().then(() => {
    //       setStartDate(newStartDate)
    //       setEndDate(newEndDate)
    //     })
    //   } else {
    //     handleFilterSchedule(wholeDayFlag)
    //   }
    // }

    // 日付を変える度にレイアウトを取得するように修正
    if (floorId > 0) {
      const newStartDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const newEndDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const refreshSchedule = async () => {
        await Promise.all([
          handleLayoutChange({
            branch_id: branchId,
            floor_id: floorId,
            start_date: formatDate(newStartDate),
            end_date: formatDate(newEndDate),
          }),
        ])
      }
      refreshSchedule().then(() => {
        setStartDate(newStartDate)
        setEndDate(newEndDate)
      })
    }
  }, [date])

  // dateにカレンダーで選択された日付を設定する。
  const handleDateChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setDate(new Date(newValue))
    }
  }

  // weekdayにカレンダーで選択された日付の曜日を設定する。
  const handleWeekdayChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setWeekdayNumber(newValue.getDay())
    }
  }

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <CustomTypography
            sx={{
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            {translations.Location}
          </CustomTypography>
          <Box alignItems="center">
            <Box>
              <FormControl fullWidth>
                <Select
                  id="branch_id"
                  name="branch_id"
                  label="拠点"
                  fullWidth
                  value={branchId}
                  variant="outlined"
                  onChange={changeSelectValue}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    color: "#707070",
                    lineHeight: "17px",
                    letterSpacing: "0.6px",
                    background: "#F8F8F8",
                    height: "36px",
                    padding: "0",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    ".MuiSelect-icon": { fontSize: "20px" },
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    {translations.Location}
                  </MenuItem>
                  {companyRelations.branches.map((branch, index) => (
                    <MenuItem key={index + 1} value={branch.id}>
                      {branch.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <CustomTypography
            sx={{
              fontWeight: "bold",
              marginLeft: "20px",
              marginRight: "10px",
            }}
          >
            {translations.Floor}
          </CustomTypography>
          <Box alignItems="center" marginRight="25px">
            <Box>
              <FormControl fullWidth>
                <Select
                  id="floor_id"
                  name="floor_id"
                  label="フロア"
                  fullWidth
                  variant="outlined"
                  onChange={changeSelectValue}
                  value={floorId}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    color: "#707070",
                    lineHeight: "17px",
                    letterSpacing: "0.6px",
                    background: "#F8F8F8",
                    height: "36px",
                    padding: "0",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    ".MuiSelect-icon": { fontSize: "20px" },
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    {translations.Floor}
                  </MenuItem>
                  {selectedBranch &&
                    selectedBranch.floors
                      .filter((floor) =>
                        floor.layouts.some(
                          (layout) =>
                            layout.release_date !== null &&
                            new Date(layout.release_date) <= new Date()
                        )
                      )
                      .map((floor, index) => (
                        <MenuItem key={index + 1} value={floor.id}>
                          {floor.floor_name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" marginRight="20px">
            <CustomTypography
              sx={{
                fontWeight: "bold",
              }}
            >
              日付
            </CustomTypography>
            <IconButton
              onClick={
                floorId > 0
                  ? () => {
                      previousDate()
                      previousWeekday()
                    }
                  : () => null
              }
              disabled={floorId === 0}
            >
              <ChevronLeftIcon
                color={floorId > 0 ? "primary" : "disabled"}
                fontSize="large"
              />
            </IconButton>
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={language === "ja" ? ja : enUS}
              >
                <DesktopDatePicker
                  label="DatePicker"
                  value={date}
                  open={scheduleCalendar.isOpen}
                  onChange={(event) => {
                    handleDateChange(event)
                    handleWeekdayChange(event)
                    scheduleCalendar.close()
                  }}
                  maxDate={schedulableMaxDate}
                  onClose={scheduleCalendar.close}
                  slots={{
                    textField: () => (
                      <CustomTypography
                        onClick={scheduleCalendar.open}
                        sx={{ cursor: "pointer", color: "#454545" }}
                      >
                        {`${month}${translations.month} ${day}${translations.day} ${translations.weekdays[weekdayNumber]}`}
                      </CustomTypography>
                    ),
                    previousIconButton: IconButton,
                    nextIconButton: IconButton,
                  }}
                  slotProps={{
                    popper: {
                      sx: { marginTop: "280px", marginLeft: "400px" },
                    },
                    textField: {
                      onClick: scheduleCalendar.open,
                      style: { cursor: "pointer" },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <IconButton
              onClick={
                floorId > 0
                  ? () => {
                      nextDate()
                      nextWeekday()
                    }
                  : () => null
              }
              disabled={floorId === 0 || schedulableMaxDate <= date}
            >
              <ChevronRightIcon
                color={floorId > 0 ? "primary" : "disabled"}
                fontSize="large"
              />
            </IconButton>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <CustomCheckbox
                  disabled={floorId === 0}
                  checked={wholeDayFlag}
                  onChange={() => setWholeDayFlag(!wholeDayFlag)}
                  sx={{
                    color: "#22ba9d",
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                    },
                  }}
                />
              }
              label={translations.AllDay}
              componentsProps={{
                typography: {
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  color: "#454545",
                  lineHeight: "19px",
                  letterSpacing: "0.7px",
                  marginRight: "-1px",
                },
              }}
            />
          </Box>
        </Box>
        <Box flexGrow="1" marginRight="20px">
          <Slider
            aria-label="Always visible"
            disabled={wholeDayFlag}
            max={fifteenMinutesIntervalHours.length - 1}
            step={1}
            valueLabelDisplay="on"
            valueLabelFormat={fifteenMinutesIntervalHours[timeIndex]}
            value={timeIndex}
            onChange={handleTimeChange}
            track={false}
            sx={{
              color: "#f0f0f0",
              height: "10px",
              padding: "0",
              opacity: "1",
              "& .MuiSlider-thumb": {
                height: "10px",
                borderRadius: "19px",
                color: "#22ba9d",
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: "none",
                  color: "#22ba9d",
                },
              },
              "& .MuiSlider-thumb::before": {
                boxShadow: "none",
              },
              "& .MuiSlider-thumb::after": {
                width: "0",
                height: "0",
              },
              "& .MuiSlider-valueLabel": {
                fontSize: "12px",
                color: "#22ba9d",
                lineHeight: "14px",
                background: "none",
              },
              "& .MuiSlider-valueLabel::before": {
                width: "0",
              },
            }}
          />
        </Box>
        <Box>
          <EmployeeLayoutModeSelect
            type={"layout"}
            branchId={branchId}
            floorId={floorId}
            date={date}
            wholeDayFlag={wholeDayFlag}
            timeIndex={timeIndex}
            time={fifteenMinutesIntervalHours[timeIndex]}
          />
        </Box>
      </Box>
    </Box>
  )
}
