import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Create } from "@mui/icons-material"
import { Grid, Box, Typography } from "@mui/material"

import { BranchDetailType } from "../../../../models/company/useBranch/type"
import { translatePrefecture } from "../../../../utils/prefecture"

const line = {
  marginTop: "40px",
  height: "650px",
  border: "solid",
  color: "#E2E2E2",
  marginRight: "10px",
}

interface Props {
  branchDetail: BranchDetailType
  forbidden: boolean
}

export const CompanyBranchDetail: React.FC<Props> = ({
  branchDetail,
  forbidden,
}: Props) => {
  const urlParams = useParams<{ id: string }>()
  const navigate = useNavigate()

  return (
    <>
      <Grid container spacing={2}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={4}
            style={{ marginTop: "-15px", marginLeft: "-13px" }}
          >
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={18}>
                拠点詳細
              </Typography>
            </Grid>
            <Grid item xs={5}>
              {/* TODO 編集画面への導線を権限エラー時に封じる */}
              {!forbidden && (
                <Typography>
                  <button
                    style={{ height: "30px" }}
                    onClick={() =>
                      navigate(`/company/branches/${urlParams.id}/edit`)
                    }
                  >
                    <Create />
                  </button>
                </Typography>
              )}
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                拠点名称
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>{branchDetail.branch_name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                郵便番号
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>
                〒{branchDetail.branch_postal_code}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                住所
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>
                {translatePrefecture(branchDetail.branch_prefecture_id)}
                {branchDetail.branch_city}
                {branchDetail.branch_house_number}
                {branchDetail.branch_building_name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                席数
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>{branchDetail.seat_count}席</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                自由席数
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>
                {branchDetail.free_seat_count}席
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                会議室数
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>
                {branchDetail.meeting_rooms_count}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                拠点責任者
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>
                {branchDetail.branch_leader_name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                ワーカー数
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>
                {branchDetail.employee_count}名
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                フロア数
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12}>{branchDetail.floor_total}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold" fontSize={12}>
                備考
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize={12} sx={{ wordBreak: "break-all" }}>
                {branchDetail.note}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <hr style={line} />
    </>
  )
}
