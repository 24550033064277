import { Auth } from "aws-amplify"

import React, { useContext, useEffect } from "react"
import {
  Navigate,
  RouteProps,
  useLocation,
  useNavigate,
} from "react-router-dom"

import { AuthContext } from "../providers/AuthProvider"

// ログアウト状態のユーザーが使うページにログイン中のユーザーがアクセスした場合にhome画面にリダイレクトさせる
// 使い方
{
  /* <Route
  path="/signup"
  element={<PublicRoute path="/signup" element={<CompanySignUp />} />}
/> */
}

export const CompanyPublicRoute: React.FC<RouteProps> = ({ element }) => {
  const { isAuthenticated } = useContext(AuthContext)
  const navigate = useNavigate()
  console.log("test")

  useEffect(() => {
    if (localStorage.getItem("login") !== "homeLogin") {
      Auth.signOut()
    }
  }, [])

  return (
    <>
      {isAuthenticated && localStorage.getItem("login") === "homeLogin" ? (
        <Navigate to="/company/home" /> // TODO 従業員画面が実装されたらリダイレクト先をpropsで分岐する
      ) : (
        <>{element}</>
      )}
    </>
  )
}
