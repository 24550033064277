import { LayoutType } from "../models/company/useBranch/type"

// 公開済みと公開予定のレイアウトを絞り込む
export const filterReleaseLayouts = (layouts: LayoutType[]) => {
  const selectedReleaseLayouts = layouts
    .map((layout) => {
      if (layout.post_status === 1 || layout.release_date) {
        return layout
      }
    })
    .flatMap((e) => e ?? [])

  return selectedReleaseLayouts
}
