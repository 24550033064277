import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import { useForm } from "react-hook-form"

import {
  Box,
  styled,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material"

import { useCompany } from "../../../../src/models/company/useCompany"
import { CancelButton } from "../../public/CancelButton"
import { CustomHeadTag } from "../../public/CustomHeadTag"
import { ReflectButton } from "../../public/ReflectButton"
import { TwoConditionsReflectButton } from "../../public/TwoConditionsReflectButton"

interface Props {
  onClickCancelButton: () => void
}
const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

export const CompanyNameForm = ({ onClickCancelButton }: Props) => {
  const {
    control,
    companyName,
    setCompanyName,
    companyNameKana,
    setCompanyNameKana,
    form,
    isValid,
    fetchCompany,
    fetchCompanyPreparation,
    updateCompanyName,
  } = useCompany()

  const errors = form.formState.errors

  return (
    <>
      <CustomHeadTag />
      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="company_name">
            <Typography
              variant="h6"
              color="inherit"
              sx={{
                fontWeight: "bold",
              }}
            >
              新しい法人名
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={form.control}
            name="company_name"
            render={({ field }) => (
              <TextField
                {...field}
                {...form}
                onBlur={(event) => {
                  setCompanyName(event.target.value)
                }}
                error={!!errors.company_name}
                helperText={errors.company_name?.message}
                id="company_name"
                name="company_name"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>
      </CustomGridItem>

      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="company_name_kana">
            <Typography
              variant="h6"
              color="inherit"
              sx={{
                fontWeight: "bold",
              }}
            >
              新しい法人名カナ
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={form.control}
            name="company_name_kana"
            render={({ field }) => (
              <TextField
                {...field}
                {...form}
                onBlur={(event) => {
                  setCompanyNameKana(event.target.value)
                }}
                error={!!errors.company_name_kana}
                helperText={errors.company_name_kana?.message}
                id="company_name_kana"
                name="company_name_kana"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "450px",
          m: "30px auto",
        }}
      >
        <CancelButton label="閉じる" handleClick={onClickCancelButton} />
        <TwoConditionsReflectButton
          label="更新"
          conditionOne={companyName}
          conditionTwo={companyNameKana}
          handleClick={updateCompanyName}
        />
      </Box>
    </>
  )
}
