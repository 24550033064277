import React, { useState } from "react"

import {
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { SearchedEmployeeResult } from "../../../../../models/employee/useSchedule/scheduleSearchEmployeeForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleSideSearch/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleSideSearch/ja"
import { EmployeeIconWrapper } from "../../../../public/EmployeeIconWrapper"
import { Paging } from "../../../../public/Pagination"

interface Props {
  employees: SearchedEmployeeResult
  handleEmployeeChecked: (selectedEmployeeId: number) => void
}

interface TableHeader {
  id: number
  label: string
}

const CustomSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1),
  color: "#454545",
}))

const CustomTableHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
}))

// 従業員検索の検索結果
export const SearchedEmployeeList = ({
  employees,
  handleEmployeeChecked,
}: Props) => {
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 5, 10, 15...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const DATA_COUNT_PER_PAGE = 5

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const employeeTableColumn: TableHeader[] = [
    { id: 1, label: translations.Member },
    { id: 2, label: translations.BusinessUnit },
    { id: 3, label: translations.Manager },
    { id: 4, label: translations.Location },
  ]

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              {employeeTableColumn.map((headCell) => (
                <CustomTableHeader key={headCell.id} padding={"normal"}>
                  {headCell.label}
                </CustomTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees
              .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
              .map((employee, index) => {
                return (
                  <TableRow hover role="checkbox" key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={employee.checked}
                        onChange={() => handleEmployeeChecked(employee.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex">
                        <EmployeeIconWrapper>
                          {employee.icon === null ? (
                            <Typography variant="h6" color="inherit">
                              {employee.last_name[0]}
                              {employee.first_name[0]}
                            </Typography>
                          ) : (
                            <img
                              src={employee.icon}
                              style={{
                                borderRadius: "50%",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </EmployeeIconWrapper>
                        <Box ml="2rem">
                          {employee.last_name}
                          {employee.first_name}
                          <CustomSubtitle
                            variant="subtitle2"
                            sx={{
                              textDecoration: "underline",
                            }}
                          >
                            {employee.account.email}
                          </CustomSubtitle>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{employee.team.team_name}</TableCell>
                    <TableCell>
                      {employee.team_leader_name}
                      <CustomSubtitle
                        variant="subtitle2"
                        sx={{
                          textDecoration: "underline",
                        }}
                      >
                        {employee.team_leader_email}
                      </CustomSubtitle>
                    </TableCell>
                    <TableCell>
                      {employee.branch.branch_name}
                      <CustomSubtitle>
                        {employee.floor.floor_number}
                      </CustomSubtitle>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {employees.length > DATA_COUNT_PER_PAGE && (
        <Paging
          pageCount={Math.ceil(employees.length / DATA_COUNT_PER_PAGE)}
          page={pageNumber}
          handlePaginationChange={handlePaginationChange}
        />
      )}
    </>
  )
}
