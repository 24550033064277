import { Withdrawal } from "../../models/company/useWithdrawal/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchWithdrawalRequest = async (): Promise<
  ApiResponse<Withdrawal>
> => {
  try {
    const data = await api.get("/company/withdrawals")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const withdrawalRequest = async (): Promise<ApiResponse<void>> => {
  try {
    const { data } = await api.post("/company/withdrawals")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
