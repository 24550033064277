const en = {
  EmployeeName: "Employee name",
  EmployeeNameKana: "Employee name (in Katakana)",
  Email: "E-mail",
  EmployeeId: "Employee ID",
  CompanyPhoneNumber: "Company phone number",
  Location: "Location",
  Floor: "Floor",
  DivisionDepartment: "Division/Department",
  ManagerName: "Manager Name",
  ManagerEmail: "Manager E-mail",
  WorkArrangement: "Work arrangement",
  AssignedDesk: "Assigned Desk",
  AssignedDeskId: "Assigned Desk ID",
  Remarks: "Remarks",
  Return: "Return",
  No: "No assigned desk",
  Yes: "Assigned desk",
  FullTime: "Full-time remote work",
  OfficeWork: "In-office work",
}
export default en
