import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"

import { EmployeeProfileShowData } from "../../../components/employee/profile/show"
import { BackIcon } from "../../../components/public/BackIcon"
import { ContentEmployeePaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { ContentTitle } from "../../../components/public/ContentTitle"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useProfile } from "../../../models/employee/useProfile"
import enTranslations from "../../../translations/employeeIcon/employeeMenuIcon/en"
import jaTranslations from "../../../translations/employeeIcon/employeeMenuIcon/ja"

export const EmployeeProfileShowPage = () => {
  const { fetchEmployeeProfile, employeeProfile } = useProfile()

  const navigate = useNavigate()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  useEffect(() => {
    fetchEmployeeProfile()
  }, [])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <BackIcon handleClick={() => navigate("/employee/home")} />
        <ContentTitle title={translations.profile} />
      </Box>
      <ContentTemplate>
        <ContentEmployeePaper>
          <EmployeeProfileShowData employeeProfile={employeeProfile} />
        </ContentEmployeePaper>
      </ContentTemplate>
    </>
  )
}
