const ja = {
  eightCharacters: "8文字以上で入力してください",
  PleaseOneSymbol:
    "英大文字・英小文字・数字・記号をそれぞれ一つ以上含めてください",
  Required: "必須になります",
  fiftyPassword: "50文字以下で入力してください",
  PasswordNotMatch: "パスワードと確認用パスワードが一致しません",
  password: "パスワードの形式で入力してください",
  email: "メールアドレスの形式で入力してください",
  CheckIn: "チェックインする",
  Checkout: "チェックアウトする",
  scheduleAndCheckIn: "時間設定&チェックイン",
  endOfShiftCheckIn: "終業までチェックイン",
  endOfReservationCheckIn: "予約終了時刻までチェックイン",
  checkIn: "チェックインが完了しました",
  checkOut: "チェックアウトが完了しました",
  CheckInFailed: "チェックインに失敗しました",
  CheckOutFailed: "チェックアウトに失敗しました",
  update: "スケジュールの更新が完了しました",
  create: "スケジュールの登録が完了しました",
  TypingError: "入力に誤りがございます、もう一度お試しください",
  Katakana: "全角カタカナになります",
  UseHiragana: "全角ひらがな、カタカナ、漢字、半角アルファベットになります",
  favoriteAdd: "お気に入りグループの登録が完了しました",
  favoriteDelete: "お気に入りグループの削除が完了しました",
  pleaseEnterTwo: "2文字以上で入力してください",
  fiftyEmployeeSearch: "50文字以下で入力してください",
  pleaseFloor: "フロアを選択してください",
  EmployeeNameRequired:
    "従業員名（カナ）、拠点およびフロア、所属、お気に入りのいずれかが必須です",
  AlreadyDesk: "すでに同じ時間に席予約がありますが、本当に更新しますか？",
  NoData: "表示するデータがありません",
  UserNotFound: "ユーザーが見つかりませんでした",
  digitsCode: "6桁で入力してください",
  halfWidthNumbers: "半角数字で入力してください",
  WouldYouAgain: "チェックアウト後、再度利用しますか？",
  YouCheckIn: "チェックインしますか？",
  YouCheckOut: "チェックアウトしますか？",
  SuccessUpdate: "予約時間が変更されました",
  ScheduleDeleted: "予定の削除が完了しました",
  Incorrect: "日時の設定に誤りがあります。",
  YourScheduleAdded: "予定の登録が完了しました",
  ScheduleUpdated: "予定の更新が完了しました",
  changePassword: "パスワードの変更に失敗しました",
  selectMembers: "従業員を選択してください",
  SeatAlreadyReserved: "この座席は予約済みです。",
  ReservedSeat: "この座席は指定席です。",
  PleaseFindAnotherSeat: "他の座席をお探しください。",
  CheckInNow: "現在他の座席でチェックインしています",
  ReserveInNow: "現在他の座席を予約しています",

  // エラーメッセージ以外
  FindLocation: "場所を確認する",
  Search: "検索する",
  Desk: "座席番号",
  RoomName: "会議室名",
  Name: "予約者",
  CurrentDesk: "現在の座席は",
  CurrentMeetingRoom: "現在の会議室は",
  Hour: "時",
  Minutes: "分",
  month: "月",
  day: "日",
  ReservedUntil: "まで予約されています",
  to: "まで",
  Reserved: "予約されています",
  UseAgain: "再度利用する",
  currentTime: "予約時間を現在時刻へ変更する",
  Cancel: "キャンセル",
  DesksUsedBefore: "最近利用した席",
  Subject: "件名",
  allDay: "終日",
  EmployeeName: "従業員名",
  Email: "メールアドレス",
  DeskStatus: "席状況",
  DeleteRecurring: "定期的な予定の削除",
  ThisSchedule: "この予定",
  FromThisSchedule: "これ以降の予定",
  AllSchedule: "全ての予定",
  Delete: "削除する",
  Home: "ホーム画面に戻る",
  NoArea: "このエリアにチェックインする権限がありません",
  anotherReserved:
    "他の席に予約が入っていますが、この座席にチェックインしてもよろしいですか",
  currentlyCheckedAnotherDesk:
    "現在他の座席にチェックイン中ですがこちらの座席を予約してもよろしいですか？",
  wouldYouCheckIn: "この席にチェックインしますか？",
  reservationInAnotherSpace:
    "他の会議室に予約が入っていますが、同じメンバーでこの会議室にチェックインしてもよろしいですか？",
  wouldYouCheckInSpace: "この会議室にチェックインしますか？",
}
export default ja
