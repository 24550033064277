import {
  AkerunDoorType,
  SignageSettingType,
} from "../../models/signage/useSetting/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchSignageSettingRequest = async (): Promise<
  ApiResponse<SignageSettingType[]>
> => {
  try {
    const data = await api.get("/signage/settings")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const akerunDoorRequest = async (
  branch_id: number
): Promise<ApiResponse<AkerunDoorType[]>> => {
  try {
    const data = await api.get("/company/akerun/get_door_information", {
      params: { branch_id: branch_id },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
