import { api, ApiResponse } from "../config/axiosConfig"

// Sidekiq経由でCognitoにユーザーを作成するAPI
export const createAuthRequest = async (params: {
  emails: string[]
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/auth", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
