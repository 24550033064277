import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
// import Cookies from "js-cookie"
import * as Yup from "yup"

import { useCallback, useContext, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations//errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { PASSWORD_REGEX } from "../../../../utils/regular"
import { ChangePasswordType } from "./type"

const changePasswordData: ChangePasswordType = {
  password: "",
  passwordConfirmation: "",
}

Amplify.configure(awsmobile)
export const useCheckInPasswordChange = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { currentUser } = useContext(AuthContext)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)

  const basicSchema = Yup.object().shape({
    password: Yup.string()
      .required(translations.Required)
      .max(50, translations.fiftyPassword)
      .min(8, translations.eightCharacters)
      .matches(PASSWORD_REGEX, translations.PleaseOneSymbol),
    passwordConfirmation: Yup.string()
      .required(translations.Required)
      .oneOf([Yup.ref("password"), null], translations.PasswordNotMatch),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      password: changePasswordData.password,
      passwordConfirmation: changePasswordData.password,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<ChangePasswordType> = useCallback(
    async (data) => {
      setLoading(true)
      try {
        const response = await Auth.completeNewPassword(
          currentUser,
          data.password
        )
        if (response.signInUserSession) {
          // const savedPassword = Cookies.get("password")
          // // ログイン時に「メールアドレスとパスワードを保存する」にチェックを入れている場合、最新のパスワードに書き換える
          // if (savedPassword) {
          //   Cookies.set("password", data.password, {
          //     expires: 30,
          //   })
          // }
          navigate(
            `/mobile/check-in?floor_id=${queryParams.get(
              "floor_id"
            )}&id=${queryParams.get("id")}&type=${queryParams.get(
              "type"
            )}&name=${queryParams.get("name")}`
          )
        }
      } catch (error) {
        setOpen(true)
        setErrorMessages([translations.changePassword])
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    onSubmit,
    open,
    errorMessages,
    loading,
  }
}
