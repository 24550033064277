import React from "react"

import { Box, styled, Typography } from "@mui/material"

import { useLanguage } from "../../../../../../contexts/LanguageContext"
import { MonthScheduleType } from "../../../../../../models/employee/useSchedule/months/type"
import enTranslations from "../../../../../../translations/employeeSchedule/employeeScheduleMain/en"
import jaTranslations from "../../../../../../translations/employeeSchedule/employeeScheduleMain/ja"

const ScheduleBox = styled(Box)(() => ({
  height: "24px",
  borderRadius: "7px",
  marginBottom: "5px",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
}))

const LeftScheduleBox = styled(Box)(() => ({
  height: "24px",
  borderRadius: "7px",
  marginBottom: "5px",
  width: "50%",
  marginRight: "auto",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
}))

const RightScheduleBox = styled(Box)(() => ({
  height: "24px",
  borderRadius: "7px",
  marginBottom: "5px",
  width: "50%",
  marginLeft: "auto",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
}))

// 予定のステータスに応じて背景色を変更
const colorChoice = (schedule_status: string): string => {
  if (schedule_status === "remote") {
    return "#C1C1C1"
  } else if (schedule_status === "going_out") {
    return "#EFC34B"
  } else if (schedule_status === "trip") {
    return "#D972A5"
  } else if (schedule_status === "holiday") {
    return "#4F9BD0"
  } else {
    return "#4EC7B0"
  }
}

interface Props {
  day: {
    date: Date
    status_schedules: MonthScheduleType[]
  }
  displayReserved: boolean
  displayNonReserved: boolean
}

export const MonthStatusSchedules: React.FC<Props> = ({
  day,
  displayReserved,
  displayNonReserved,
}: Props) => {
  const color = colorChoice

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const setScheduleText = (
    schedule_status: string,
    schedule_title: string
  ): string => {
    if (schedule_status === "remote") {
      return translations.WorkFromHome
    } else if (schedule_status === "going_out") {
      return translations.OutOfOffice
    } else if (schedule_status === "trip") {
      return translations.BusinessTrip
    } else if (schedule_status === "holiday") {
      return translations.Vacation
    } else {
      return schedule_title
    }
  }

  return (
    <>
      {day.status_schedules.length === 1 &&
        day.status_schedules[0].schedule_judge === "all_day" &&
        day.status_schedules.map((status, indexAllDay) => {
          return (
            <>
              {!displayNonReserved && status.reservable_type && (
                <ScheduleBox
                  key={indexAllDay}
                  sx={{
                    color: "white",
                    width: `${status.width}%`,
                    backgroundColor: color(status.schedule_status),
                  }}
                >
                  <Typography
                    align="left"
                    ml="5px"
                    fontSize="12px"
                    fontFamily="Noto Sans"
                  >
                    {setScheduleText(
                      status.schedule_status,
                      status.schedule_title
                    )}
                  </Typography>
                </ScheduleBox>
              )}
              {!displayReserved && !status.reservable_type && (
                <ScheduleBox
                  key={indexAllDay}
                  sx={{
                    color: "white",
                    width: `${status.width}%`,
                    backgroundColor: color(status.schedule_status),
                  }}
                >
                  <Typography
                    align="left"
                    ml="5px"
                    fontSize="12px"
                    fontFamily="Noto Sans"
                  >
                    {setScheduleText(
                      status.schedule_status,
                      status.schedule_title
                    )}
                  </Typography>
                </ScheduleBox>
              )}
            </>
          )
        })}
      {day.status_schedules.length === 1 &&
        day.status_schedules[0].schedule_judge === "AM" &&
        day.status_schedules.map((status, indexAm) => {
          return (
            <>
              {!displayNonReserved && status.reservable_type && (
                <Box key={indexAm} display="flex">
                  <LeftScheduleBox
                    sx={{
                      color: "white",
                      backgroundColor: color(status.schedule_status),
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {setScheduleText(
                        status.schedule_status,
                        status.schedule_title
                      )}
                    </Typography>
                  </LeftScheduleBox>
                </Box>
              )}
              {!displayReserved && !status.reservable_type && (
                <Box key={indexAm} display="flex">
                  <LeftScheduleBox
                    sx={{
                      color: "white",
                      backgroundColor: color(status.schedule_status),
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {setScheduleText(
                        status.schedule_status,
                        status.schedule_title
                      )}
                    </Typography>
                  </LeftScheduleBox>
                </Box>
              )}
            </>
          )
        })}
      {day.status_schedules.length === 1 &&
        day.status_schedules[0].schedule_judge === "PM" &&
        day.status_schedules.map((status, indexPm) => {
          return (
            <>
              {!displayNonReserved && status.reservable_type && (
                <Box key={indexPm} display="flex">
                  <RightScheduleBox
                    sx={{
                      color: "white",
                      backgroundColor: color(status.schedule_status),
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {setScheduleText(
                        status.schedule_status,
                        status.schedule_title
                      )}
                    </Typography>
                  </RightScheduleBox>
                </Box>
              )}
              {!displayReserved && !status.reservable_type && (
                <Box key={indexPm} display="flex">
                  <RightScheduleBox
                    sx={{
                      color: "white",
                      backgroundColor: color(status.schedule_status),
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {setScheduleText(
                        status.schedule_status,
                        status.schedule_title
                      )}
                    </Typography>
                  </RightScheduleBox>
                </Box>
              )}
            </>
          )
        })}
      {day.status_schedules.length >= 2 && (
        <Box display="flex">
          {day.status_schedules.map((status, index4) => {
            return (
              <>
                {!displayNonReserved && status.reservable_type && (
                  <>
                    {status.schedule_judge === "AM" && (
                      <LeftScheduleBox
                        key={index4}
                        sx={{
                          color: "white",
                          backgroundColor: color(status.schedule_status),
                        }}
                      >
                        <Typography
                          align="left"
                          ml="5px"
                          fontFamily="Noto Sans"
                        >
                          {setScheduleText(
                            status.schedule_status,
                            status.schedule_title
                          )}
                        </Typography>
                      </LeftScheduleBox>
                    )}
                    {status.schedule_judge === "PM" && (
                      <RightScheduleBox
                        sx={{
                          color: "white",
                          backgroundColor: color(status.schedule_status),
                        }}
                      >
                        <Typography
                          align="left"
                          ml="5px"
                          fontSize="12px"
                          fontFamily="Noto Sans"
                        >
                          {setScheduleText(
                            status.schedule_status,
                            status.schedule_title
                          )}
                        </Typography>
                      </RightScheduleBox>
                    )}
                  </>
                )}
                {!displayReserved && !status.reservable_type && (
                  <>
                    {status.schedule_judge === "AM" && (
                      <LeftScheduleBox
                        key={index4}
                        sx={{
                          color: "white",
                          backgroundColor: color(status.schedule_status),
                        }}
                      >
                        <Typography
                          align="left"
                          ml="5px"
                          fontSize="12px"
                          fontFamily="Noto Sans"
                        >
                          {setScheduleText(
                            status.schedule_status,
                            status.schedule_title
                          )}
                        </Typography>
                      </LeftScheduleBox>
                    )}
                    {status.schedule_judge === "PM" && (
                      <RightScheduleBox
                        sx={{
                          color: "white",
                          backgroundColor: color(status.schedule_status),
                        }}
                      >
                        <Typography
                          align="left"
                          ml="5px"
                          fontSize="12px"
                          fontFamily="Noto Sans"
                        >
                          {setScheduleText(
                            status.schedule_status,
                            status.schedule_title
                          )}
                        </Typography>
                      </RightScheduleBox>
                    )}
                  </>
                )}
              </>
            )
          })}
        </Box>
      )}
    </>
  )
}
