import React, { useContext } from "react"
import { Navigate, RouteProps } from "react-router-dom"

import { AuthContext } from "../providers/AuthProvider"

// ログアウト状態のユーザーが使うページにログイン中のユーザーがアクセスした場合にhome画面にリダイレクトさせる
// 使い方
{
  /* <Route
  path="/signup"
  element={<PublicRoute path="/signup" element={<CompanySignUp />} />}
/> */
}

export const MobilePublicRoute: React.FC<RouteProps> = ({ element }) => {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <>
      {isAuthenticated ? (
        <Navigate to="/mobile/home" /> // TODO 従業員画面が実装されたらリダイレクト先をpropsで分岐する
      ) : (
        <>{element}</>
      )}
    </>
  )
}
