import React, { useState, useEffect, memo } from "react"
import { SketchPicker } from "react-color"
import { UseFormReturn, Controller, SubmitHandler } from "react-hook-form"

import FileUploadIcon from "@mui/icons-material/FileUpload"
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,
  TextField,
  Select,
  Grid,
  styled,
  SelectChangeEvent,
  Box,
  Chip,
  Button,
  FormControlLabel,
  Modal,
  Tab,
  Tabs,
} from "@mui/material"

import {
  SPECIFIC,
  ALL,
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import {
  MeetingRoomType,
  MeetingRoomFormType,
} from "../../../../models/company/useLayout/type"
import { useAutoCancelTimes } from "../../../../models/public/useAutoCancelTimes"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import {
  ApplianceType,
  TagCategoryType,
  TagType,
} from "../../../../models/public/useTag/type"
import { useImageUpload } from "../../../../utils/imageUpload"
import { useOpen } from "../../../../utils/isOpen"
import { handleTeamNameChange } from "../../../../utils/teamIDtoTeamName"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
})

export const CustomTypography = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 4px gray",
  },
})

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "70%",
}))

const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
})

interface Props {
  meetingRoom: MeetingRoomType
  meetingRoomForm: UseFormReturn<MeetingRoomFormType>
  companyRelations: CompanyRelationsType
  handleUpdateMeetingRoom: SubmitHandler<MeetingRoomFormType>
  tagCategories: TagCategoryType[]
  appliances: ApplianceType[]
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
}

const MenuSelectProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
}

/*
  レイアウトのサイドバーで会議室を追加
*/
// TODO
// ・所属選択のバリデーション
// ・タグ、機器リスト、画像の追加
export const CompanyLayoutMeetingRoomForm: React.FC<Props> = memo(
  ({
    companyRelations,
    meetingRoom,
    meetingRoomForm,
    handleUpdateMeetingRoom,
    tagCategories,
    appliances,
    setSidebarLayoutComponent,
    setSidebarTabType,
  }: Props) => {
    const [reservableTeamIds, setReservableTeamIds] = useState<number[]>(
      meetingRoom.reservable_team_ids
    )
    const [reservableTeamNames, setReservableTeamNames] = useState<string[]>([])
    const [availabilityTeamIds, setAvailabilityTeamIds] = useState<number[]>(
      meetingRoom.availability_team_ids
    )
    const [availabilityTeamNames, setAvailabilityTeamNames] = useState<
      string[]
    >([])
    const [colorCode, setColorCode] = useState<string>(meetingRoom.color_code)
    const [openColor, setOpenColor] = useState<boolean>(false)
    const [tags, setTags] = useState<TagType[]>([])
    const [tagCategoryId, setTagCategoryId] = React.useState<number>(0)
    const [selectdTags, setSelectdTags] = useState<TagType[]>([])
    const [selectdAppliances, setSelectdAppliances] = useState<ApplianceType[]>(
      []
    )
    const [autoCancelTimeMinutes, setAutoCancelTimeMinutes] = useState<number>(
      meetingRoom.auto_cancel_times_id
    )
    const { autoCancelTimes, fetchAutoCancelTimes } = useAutoCancelTimes()

    const tagsOpen = useOpen()
    const appliancesOpen = useOpen()
    const { imgBlob, imgFile, getRootProps, getInputProps, setImgFile } =
      useImageUpload()
    useEffect(() => {
      setValue("image_blob", imgBlob)
      setImgFile(imgFile)
    }, [imgBlob])

    const handleColorOpen = () => {
      setOpenColor(!openColor)
    }

    const {
      control,
      setValue,
      handleSubmit,
      reset,
      formState: { errors, isValid },
    } = meetingRoomForm

    /*
      所属選択に関連するイベント
    */
    // 所属選択をしたときに所属IDと所属名の値を設定する
    // useStateとsetValueを両方使っている理由： 表示部分と登録ボタンを押したときに値が保持されないため
    const handleTeamChange = (
      e: SelectChangeEvent<typeof reservableTeamIds>
    ) => {
      const teamIds = e.target.value
      const targetTeamIds =
        typeof teamIds === "string" ? teamIds.split(",").map(Number) : teamIds

      // 全選択された場合
      if (targetTeamIds.includes(0)) {
        switch (e.target.name) {
          case "reservable_team_ids":
            // すでに全選択がされている時に全選択をクリックした時
            if (reservableTeamIds.length === companyRelations.teams.length) {
              // 所属IDと所属名を空配列にする
              setReservableTeamNames([])
              setReservableTeamIds([])
              setValue("reservable_type", SPECIFIC)
              setValue("reservable_team_ids", [])
            } else {
              // 全ての所属IDと所属名を設定する
              setReservableTeamNames(
                companyRelations.teams.map((team) => team.team_name)
              )
              setReservableTeamIds(
                companyRelations.teams.map((team) => team.id)
              )
              setValue("reservable_type", ALL)
              setValue(
                "reservable_team_ids",
                companyRelations.teams.map((team) => team.id)
              )
            }
            break
          case "availability_team_ids":
            // すでに全選択がされている時に全選択をクリックした時
            if (availabilityTeamIds.length === companyRelations.teams.length) {
              // 所属IDと所属名を空配列にする
              setAvailabilityTeamNames([])
              setAvailabilityTeamIds([])
              setValue("availability_type", SPECIFIC)
              setValue("availability_team_ids", [])
            } else {
              // 全ての所属IDと所属名を設定する
              setAvailabilityTeamNames(
                companyRelations.teams.map((team) => team.team_name)
              )
              setAvailabilityTeamIds(
                companyRelations.teams.map((team) => team.id)
              )
              setValue("availability_type", SPECIFIC)
              setValue(
                "availability_team_ids",
                companyRelations.teams.map((team) => team.id)
              )
            }
            break
        }
      } else {
        // 所属IDに紐づく所属名を取得する
        const targetTeamNames = handleTeamNameChange(
          targetTeamIds,
          companyRelations.teams
        )

        switch (e.target.name) {
          case "reservable_team_ids":
            setValue("reservable_type", SPECIFIC)
            setValue("reservable_team_ids", targetTeamIds)
            setReservableTeamNames(targetTeamNames)

            return setReservableTeamIds(targetTeamIds)
          case "availability_team_ids":
            setValue("availability_type", SPECIFIC)
            setValue("availability_team_ids", targetTeamIds)
            setAvailabilityTeamNames(targetTeamNames)

            return setAvailabilityTeamIds(targetTeamIds)
        }
      }
    }

    /*
      タグに関連するイベント
    */
    const handleTagCategoryChange = (
      e: React.SyntheticEvent,
      newValue: number
    ) => {
      const tagCategory = tagCategories.find((tagCategory) => {
        return tagCategory.id === newValue
      })
      if (tagCategory) {
        setTags(tagCategory.tags)
      }
      setTagCategoryId(newValue)
    }

    const selectedTagCheck = (tagId: number) => {
      const resultTag = selectdTags.find((selectdTag) => {
        return selectdTag.id === tagId
      })

      return resultTag ? true : false
    }

    const handleTagCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
      // タグが3つ以上選ばれた場合、処理を中止する
      if (selectdTags.length >= 3 && e.target.checked) {
        return
      }

      const tagCategory = tagCategories.find((tagCategory) => {
        return tagCategory.id === tagCategoryId
      })

      if (tagCategory) {
        const targetTag = tagCategory.tags.find((tag) => {
          return tag.id === Number(e.target.value)
        })

        if (e.target.checked && targetTag) {
          const resultTagIds = selectdTags.map((resultTag) => {
            return resultTag.id
          })
          setValue("tag_ids", [...resultTagIds, Number(e.target.value)])
          setSelectdTags([...selectdTags, targetTag])
        } else {
          const resultTagIds = selectdTags.filter((selectdTag) => {
            return selectdTag.id !== Number(e.target.value)
          })
          setValue(
            "tag_ids",
            resultTagIds.map((resultTag) => {
              return resultTag.id
            })
          )
          setSelectdTags(resultTagIds)
        }
      }
    }

    /*
      機器に関連するイベント
    */
    const selectedApplianceCheck = (applianceId: number) => {
      const resultAppliance = selectdAppliances.find((selectdAppliance) => {
        return selectdAppliance.id === applianceId
      })

      return resultAppliance ? true : false
    }

    const handleApplianceCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
      // 機器が3つ以上選ばれた場合、処理を中止する
      if (selectdAppliances.length >= 3 && e.target.checked) {
        return
      }

      const targetAppliance = appliances.find((appliance) => {
        return appliance.id === Number(e.target.value)
      })

      if (e.target.checked && targetAppliance) {
        const resultApplianceIds = selectdAppliances.map((resultAppliance) => {
          return resultAppliance.id
        })
        setValue("appliance_ids", [
          ...resultApplianceIds,
          Number(e.target.value),
        ])
        setSelectdAppliances([...selectdAppliances, targetAppliance])
      } else {
        const resultApplianceIds = selectdAppliances.filter(
          (selectdAppliance) => {
            return selectdAppliance.id !== Number(e.target.value)
          }
        )
        setValue(
          "appliance_ids",
          resultApplianceIds.map((resultTag) => {
            return resultTag.id
          })
        )
        setSelectdAppliances(resultApplianceIds)
      }
    }

    /*
      登録している会議室をフォームに反映する
    */
    useEffect(() => {
      setReservableTeamNames(
        handleTeamNameChange(
          meetingRoom.reservable_team_ids,
          companyRelations.teams
        )
      )
      setAvailabilityTeamNames(
        handleTeamNameChange(
          meetingRoom.availability_team_ids,
          companyRelations.teams
        )
      )
      // meetingRoomのidが0以外の場合、既に追加している会議室情報を反映させる
      if (meetingRoom.id && meetingRoom.id !== 0) {
        reset({
          id: meetingRoom.id,
          meeting_room_name: meetingRoom.meeting_room_name,
          availability_type: meetingRoom.availability_type,
          reservable_type: meetingRoom.reservable_type,
          availability_team_ids: meetingRoom.availability_team_ids,
          reservable_team_ids: meetingRoom.reservable_team_ids,
          capacity: meetingRoom.capacity,
          available_time: meetingRoom.available_time,
          color_code: meetingRoom.color_code,
          meeting_room_description: meetingRoom.meeting_room_description,
          tag_ids: meetingRoom.tag_ids,
          appliance_ids: meetingRoom.appliance_ids,
          image_blob: meetingRoom.image_blob,
          auto_cancel_times_id: meetingRoom.auto_cancel_times_id,
          meeting_room_reservable_all: meetingRoom.meeting_room_reservable_all,
          meeting_room_availability_all:
            meetingRoom.meeting_room_availability_all,
        })

        if (meetingRoom.image_blob) {
          const createObjectURL = (window.URL || window.webkitURL)
            .createObjectURL
          setImgFile(
            createObjectURL(meetingRoom.image_blob as Blob | MediaSource)
          )
          setValue("image_blob", meetingRoom.image_blob)
        }

        // タグを選択された状態にする
        setSelectdTags(
          tagCategories
            .map((tagCategory) => {
              return tagCategory.tags
                .map((tag) => {
                  if (meetingRoom.tag_ids.includes(tag.id)) {
                    return tag
                  }
                })
                .flatMap((e) => e ?? [])
            })
            .flatMap((e) => e ?? [])
        )

        // 機器を選択された状態にする
        setSelectdAppliances(
          appliances
            .map((appliance) => {
              if (meetingRoom.appliance_ids.includes(appliance.id)) {
                return appliance
              }
            })
            .flatMap((e) => e ?? [])
        )
      }
    }, [])
    const handleSetAutoCancelTimeId = (e: SelectChangeEvent<number>) => {
      setValue("auto_cancel_times_id", Number(e.target.value))
      setAutoCancelTimeMinutes(Number(e.target.value))
    }

    const handleMeetingRoomReservableAllChange = (
      e: SelectChangeEvent<number[]>
    ) => {
      const value = e.target.value as number[]
      if (value.includes(0)) {
        setValue("meeting_room_reservable_all" as keyof MeetingRoomFormType, 0)
        setValue(
          "reservable_team_ids",
          companyRelations.teams.map((team) => team.id)
        )
      } else {
        setValue("meeting_room_reservable_all" as keyof MeetingRoomFormType, 1)
        setValue("reservable_team_ids", value)
      }
    }

    const handleMeetingRoomAvailabilityAllChange = (
      e: SelectChangeEvent<number[]>
    ) => {
      const value = e.target.value as number[]
      if (value.includes(0)) {
        setValue(
          "meeting_room_availability_all" as keyof MeetingRoomFormType,
          0
        )
        setValue(
          "availability_team_ids",
          companyRelations.teams.map((team) => team.id)
        )
      } else {
        setValue(
          "meeting_room_availability_all" as keyof MeetingRoomFormType,
          1
        )
        setValue("availability_team_ids", value)
      }
    }

    useEffect(() => {
      fetchAutoCancelTimes()
    }, [])

    return (
      <>
        <Box
          sx={{
            paddingBottom: "20px",
          }}
        >
          <CustomBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/conference.svg`}
              height="20px"
              width="20px"
            />
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              会議室の設定
            </Typography>
          </CustomBox>
        </Box>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "20px",
          }}
          container
          spacing={1}
        >
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              会議室名を入力
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="meeting_room_name"
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  error={!!errors.meeting_room_name}
                  helperText={errors.meeting_room_name?.message}
                  id="meeting_room_name"
                  name="meeting_room_name"
                  required
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              選択されている会議室
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
            }}
          >
            <Typography
              sx={{ color: "#707070", paddingRight: "10px" }}
              variant="caption"
            >
              M{meetingRoom.meeting_room_code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9.5}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              エリアの色
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Box
              sx={{
                width: "30px",
                height: "30px",
                marginRight: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid",
                borderColor: "#22BA9D",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: `${colorCode}`,
                }}
                onClick={handleColorOpen}
              ></Box>
            </Box>
            {openColor && (
              <Box
                sx={{
                  zIndex: 1000,
                  position: "absolute",
                  top: "100px",
                  right: "400px",
                }}
              >
                <SketchPicker
                  color={colorCode}
                  onChange={(color) => {
                    setColorCode(color.hex)
                    setValue("color_code", color.hex)
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              定員
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Controller
              control={control}
              name="capacity"
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  error={!!errors.capacity}
                  helperText={errors.capacity?.message}
                  id="capacity"
                  name="capacity"
                  required
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              名
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              会議室の#属性を選択してください（3つまで）
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
            <Button color="primary" variant="contained" onClick={tagsOpen.open}>
              属性を選択する
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="tag_ids">属性</InputLabel>
              <Controller
                control={control}
                name="tag_ids"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="tag_ids"
                    name="tag_ids"
                    multiple
                    value={selectdTags}
                    input={
                      <OutlinedInput
                        disabled={true}
                        id="select-multiple-chip"
                        label="Chip"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((tag) => (
                          <Chip key={tag.id} label={tag.tag_name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuSelectProps}
                    IconComponent={GreenArrowIcon}
                  ></Select>
                )}
              />
            </FormControl>
          </Grid>
          <Modal open={tagsOpen.isOpen} onClose={tagsOpen.close}>
            <CustomLayoutModal>
              <Grid container xs={12} sx={{ padding: "30px" }}>
                {/* タグ選択するのタイトル */}
                <Grid item xs={12} sm={12} spacing={1}>
                  <Typography sx={{ color: "#707070" }} variant="h6">
                    タグを選択する
                  </Typography>
                </Grid>
                {/* タグタブ */}
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Tabs
                      value={tagCategoryId}
                      onChange={handleTagCategoryChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example"
                    >
                      {tagCategories.map((tagCategory, i) => {
                        return (
                          <Tab
                            key={i}
                            value={tagCategory.id}
                            label={tagCategory.category_name}
                          />
                        )
                      })}
                    </Tabs>
                  </Box>
                </Grid>
                {/* タグ一覧 */}
                <Grid item xs={12} sm={12} sx={{ margin: "20px 0" }}>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      padding: "0 30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {tags.map((tag, i) => {
                      return (
                        <Grid
                          key={i}
                          item
                          xs={4}
                          sm={4}
                          sx={{ wordBreak: "break-word" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleTagCheck}
                                value={tag.id}
                                checked={selectedTagCheck(tag.id)}
                              />
                            }
                            label={tag.tag_name}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReflectButton label="戻る" handleClick={tagsOpen.close} />
                </Grid>
              </Grid>
            </CustomLayoutModal>
          </Modal>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              機器を選択してください（3つまで）
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={appliancesOpen.open}
            >
              機器を選択する
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="appliance_ids">機器</InputLabel>
              <Controller
                control={control}
                name="appliance_ids"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="appliance_ids"
                    name="appliance_ids"
                    multiple
                    value={selectdAppliances}
                    input={
                      <OutlinedInput
                        disabled={true}
                        id="select-multiple-chip"
                        label="Chip"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((appliance) => (
                          <Chip
                            key={appliance.id}
                            label={appliance.appliance_name}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuSelectProps}
                    IconComponent={GreenArrowIcon}
                  ></Select>
                )}
              />
            </FormControl>
          </Grid>
          <Modal open={appliancesOpen.isOpen} onClose={appliancesOpen.close}>
            <CustomLayoutModal>
              <Grid container xs={12} sx={{ padding: "30px" }}>
                {/* 機器を選択するのタイトル */}
                <Grid item xs={12} sm={12} spacing={1}>
                  <Typography sx={{ color: "#707070" }} variant="h6">
                    機器を選択する
                  </Typography>
                </Grid>
                {/* 機器一覧 */}
                <Grid item xs={12} sm={12} sx={{ margin: "20px 0" }}>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      padding: "0 30px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {appliances.map((appliance, i) => {
                      return (
                        <Grid
                          key={i}
                          item
                          xs={4}
                          sm={4}
                          sx={{ wordBreak: "break-word" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleApplianceCheck}
                                value={appliance.id}
                                checked={selectedApplianceCheck(appliance.id)}
                              />
                            }
                            label={appliance.appliance_name}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReflectButton
                    label="戻る"
                    handleClick={appliancesOpen.close}
                  />
                </Grid>
              </Grid>
            </CustomLayoutModal>
          </Modal>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              予約可能なすべての所属を選択してください。
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="reservable_team_ids">所属選択</InputLabel>
              <Controller
                control={control}
                name="reservable_team_ids"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="reservable_team_ids"
                    name="reservable_team_ids"
                    multiple
                    value={reservableTeamIds}
                    onChange={(e) => {
                      field.onChange(e)
                      handleTeamChange(e)
                      handleMeetingRoomReservableAllChange(e)
                    }}
                    input={<OutlinedInput label="所属選択" />}
                    renderValue={() => reservableTeamNames.join(", ")}
                    MenuProps={MenuProps}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem key={0} value={0}>
                      <Checkbox
                        checked={
                          reservableTeamIds.length ===
                          companyRelations.teams.length
                        }
                      />
                      <ListItemText primary={"ALL"} />
                    </MenuItem>
                    {companyRelations.teams.map((team) => (
                      <MenuItem key={team.id} value={Number(team.id)}>
                        <Checkbox
                          checked={
                            reservableTeamIds.indexOf(Number(team.id)) > -1
                          }
                        />
                        <ListItemText primary={team.team_name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              利用可能なすべての所属を選択してください。
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="availability_team_ids">所属選択</InputLabel>
              <Controller
                control={control}
                name="availability_team_ids"
                render={({ field }) => (
                  <Select
                    {...field}
                    error={!!errors.availability_team_ids}
                    id="availability_team_ids"
                    name="availability_team_ids"
                    multiple
                    value={availabilityTeamIds}
                    onChange={(e) => {
                      field.onChange(e)
                      handleTeamChange(e)
                      handleMeetingRoomAvailabilityAllChange(e)
                    }}
                    input={<OutlinedInput label="所属選択" />}
                    renderValue={() => availabilityTeamNames.join(", ")}
                    MenuProps={MenuProps}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem key={0} value={0}>
                      <Checkbox
                        checked={
                          availabilityTeamIds.length ===
                          companyRelations.teams.length
                        }
                      />
                      <ListItemText primary={"ALL"} />
                    </MenuItem>
                    {companyRelations.teams.map((team) => (
                      <MenuItem key={team.id} value={Number(team.id)}>
                        <Checkbox
                          checked={
                            availabilityTeamIds.indexOf(Number(team.id)) > -1
                          }
                        />
                        <ListItemText primary={team.team_name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              予約・利用時間の1回あたりの制限をもうける場合、時間制限を行ってください。行わない場合0を設定して下さい。(分単位)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Controller
              control={control}
              name="available_time"
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  error={!!errors.available_time}
                  helperText={errors.available_time?.message}
                  id="available_time"
                  name="available_time"
                  required
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              分
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              自動キャンセル時間を選択してください。
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="auto_cancel_times_id">
                自動キャンセル
              </InputLabel>
              <Controller
                control={control}
                name="auto_cancel_times_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    id="auto_cancel_times_id"
                    name="auto_cancel_times_id"
                    value={autoCancelTimeMinutes}
                    defaultValue={autoCancelTimeMinutes}
                    required
                    label="自動キャンセル"
                    onChange={(e) => {
                      handleSetAutoCancelTimeId(e)
                    }}
                    MenuProps={MenuProps}
                    IconComponent={GreenArrowIcon}
                  >
                    <MenuItem key={0} value={0}>
                      自動キャンセルなし
                    </MenuItem>
                    {autoCancelTimes.map((autoCancelTime) => (
                      <MenuItem
                        key={autoCancelTime.id}
                        value={Number(autoCancelTime.id)}
                      >
                        {autoCancelTime.auto_cancel_time}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              会議室のイメージを登録可能です。
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} {...getRootProps()}>
            <Box>
              <FileUploadIcon>
                <input {...getInputProps()} />
              </FileUploadIcon>
            </Box>
            <img src={imgFile} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              会議室の説明
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={meetingRoomForm.control}
              name="meeting_room_description"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.meeting_room_description}
                  helperText={errors.meeting_room_description?.message}
                  id="meeting_room_description"
                  label="最大文字数1000文字"
                  multiline
                  fullWidth
                  rows={4}
                />
              )}
            />
          </Grid>
        </Grid>
        <CustomBox sx={{ marginBottom: "40px" }}>
          <Button
            variant="outlined"
            fullWidth
            disabled={!isValid}
            onClick={handleSubmit(handleUpdateMeetingRoom)}
          >
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              {meetingRoom.id === 0 ? "保存" : "更新"}
            </Typography>
          </Button>
        </CustomBox>
        <CustomBox sx={{ justifyContent: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/back.svg`}
            height="20px"
            width="20px"
          />
          <Typography
            sx={{
              ml: 1,
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
                boxShadow: "0 0 4px gray",
              },
            }}
            variant="subtitle1"
            onClick={() => {
              setSidebarTabType("seat")
              setSidebarLayoutComponent("seatList")
            }}
          >
            一覧へ戻る
          </Typography>
        </CustomBox>
      </>
    )
  }
)
