import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"

import React, { useEffect } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  Checkbox,
  CheckboxProps,
  SvgIcon,
  Box,
  IconButton,
  FormControlLabel,
  Slider,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { EmployeeLayoutModeSelect } from "../../../../../components/employee/layouts/common/EmployeeLayoutModeSelect"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { useSchedulableMonthes } from "../../../../../models/company/useSchedulableMonthes"
import {
  ScheduleStatusType,
  ScheduleParDateListType,
} from "../../../../../models/employee/useLayout/status/type"
import { CompanyRelationsType } from "../../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../../../../../translations/employeeStatus/employeeStatusHeader/ja"
import { fifteenMinutesIntervalHours } from "../../../../../utils/hours"
import { useOpen } from "../../../../../utils/isOpen"
import { GreenArrowIcon } from "../../../../public/GreenArrowIcon"

const CustomTypography = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  color: "#22ba9d",
  lineHeight: "17px",
  letterSpacing: "0.6px",
})

const UncheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      border: "1px solid #22BA9D",
      backgroundColor: "white",
    }}
  />
)

const CheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      backgroundColor: "#22BA9D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SvgIcon
      sx={{
        color: "white",
        fontSize: "1.2rem",
      }}
    >
      <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
    </SvgIcon>
  </Box>
)

const CustomCheckbox: React.FC<CheckboxProps> = (props) => (
  <Checkbox icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...props} />
)

interface Props {
  companyRelations: CompanyRelationsType
  branchId: number
  setBranchId: React.Dispatch<React.SetStateAction<number>>
  scheduleStatus: ScheduleStatusType
  setScheduleStatus: React.Dispatch<React.SetStateAction<ScheduleStatusType>>
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  dayOfWeek: string[]
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  formatDate: (date: Date) => string
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  filterSchedules: (date: string, time: string) => void
  scheduleParDateList: ScheduleParDateListType[]
}

// 拠点・フロアおよび日時の切り替えを行う
export const EmployeeStatusHeader: React.FC<Props> = ({
  companyRelations,
  branchId,
  setBranchId,
  scheduleStatus,
  setScheduleStatus,
  date,
  setDate,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  weekdayNumber,
  setWeekdayNumber,
  timeIndex,
  setTimeIndex,
  formatDate,
  wholeDayFlag,
  setWholeDayFlag,
  filterSchedules,
  scheduleParDateList,
}: Props) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const scheduleCalendar = useOpen()

  const { schedulableMonthes, fetchSchedulableMonthes } =
    useSchedulableMonthes()

  const schedulableMaxDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + schedulableMonthes,
    new Date().getDate()
  )
  // 時刻を切り替えた時の挙動
  const handleTimeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setTimeIndex(value)
      const time = fifteenMinutesIntervalHours[value]
      filterSchedules(formatDate(date), time)
    }
  }

  const handleBranchIdChange = (e: SelectChangeEvent<number>) => {
    setBranchId(Number(e.target.value))
  }

  const handleScheduleStatusChange = (
    e: SelectChangeEvent<ScheduleStatusType>
  ) => {
    setScheduleStatus(e.target.value as ScheduleStatusType)
  }

  // dateにカレンダーで選択された日付を設定する。
  const handleDateChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setDate(new Date(newValue))
    }
  }

  // weekdayにカレンダーで選択された日付の曜日を設定する。
  const handleWeekdayChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setWeekdayNumber(newValue.getDay())
    }
  }
  useEffect(() => {
    fetchSchedulableMonthes()
  }, [])

  // データがない状態でfilterを実行するとエラーになるのを防ぐ
  const disabledFlag = scheduleParDateList.length === 0

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          <CustomTypography
            sx={{
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            {translations.Location}
          </CustomTypography>
          <Box display="flex" alignItems="center">
            <Box>
              <FormControl fullWidth>
                <Select
                  id="branch_id"
                  name="branch_id"
                  label="拠点"
                  fullWidth
                  value={branchId}
                  variant="outlined"
                  onChange={handleBranchIdChange}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    color: "#707070",
                    lineHeight: "17px",
                    letterSpacing: "0.6px",
                    background: "#F8F8F8",
                    height: "36px",
                    padding: "0",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    ".MuiSelect-icon": { fontSize: "20px" },
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    {translations.Location}
                  </MenuItem>
                  {companyRelations.branches.map((branch, index) => (
                    <MenuItem key={index + 1} value={branch.id}>
                      {branch.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <CustomTypography
            sx={{
              fontWeight: "bold",
              marginLeft: "20px",
              marginRight: "10px",
            }}
          >
            {translations.Floor}
          </CustomTypography>
          <Box alignItems="center" marginRight="25px">
            <Box>
              <FormControl fullWidth>
                <Select
                  id="floor_id"
                  name="floor_id"
                  label="状況"
                  fullWidth
                  variant="outlined"
                  onChange={handleScheduleStatusChange}
                  value={scheduleStatus}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    color: "#707070",
                    lineHeight: "17px",
                    letterSpacing: "0.6px",
                    background: "#F8F8F8",
                    height: "36px",
                    padding: "0",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    ".MuiSelect-icon": { fontSize: "20px" },
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled>{translations.Status}</MenuItem>
                  <MenuItem value={"remote"}>
                    {translations.WorkFromHome}
                  </MenuItem>
                  <MenuItem value={"going_out"}>
                    {translations.OutOfOffice}
                  </MenuItem>
                  <MenuItem value={"trip"}>
                    {translations.BusinessTrip}
                  </MenuItem>
                  <MenuItem value={"holiday"}>{translations.Vacation}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" marginRight="20px">
            <CustomTypography
              sx={{
                fontWeight: "bold",
              }}
            >
              日付
            </CustomTypography>
            <IconButton
              disabled={disabledFlag}
              onClick={() => {
                previousDate()
                previousWeekday()
              }}
            >
              <ChevronLeftIcon
                color={disabledFlag ? "disabled" : "primary"}
                fontSize="large"
              />
            </IconButton>
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={language === "ja" ? ja : enUS}
              >
                <DesktopDatePicker
                  label="DatePicker"
                  value={date}
                  open={scheduleCalendar.isOpen}
                  onChange={(event) => {
                    handleDateChange(event)
                    handleWeekdayChange(event)
                    scheduleCalendar.close()
                  }}
                  maxDate={schedulableMaxDate}
                  onClose={scheduleCalendar.close}
                  slots={{
                    textField: () => (
                      <CustomTypography
                        variant="h6"
                        onClick={scheduleCalendar.open}
                        sx={{ cursor: "pointer", color: "#454545" }}
                      >
                        {`${month}${translations.month}${day}${translations.day}${translations.weekdays[weekdayNumber]}`}
                      </CustomTypography>
                    ),
                    previousIconButton: IconButton,
                    nextIconButton: IconButton,
                  }}
                  slotProps={{
                    popper: {
                      sx: { marginTop: "280px", marginLeft: "400px" },
                    },
                    textField: {
                      onClick: scheduleCalendar.open,
                      style: { cursor: "pointer" },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <IconButton
              disabled={disabledFlag || schedulableMaxDate <= date}
              onClick={() => {
                nextDate()
                nextWeekday()
              }}
            >
              <ChevronRightIcon
                color={disabledFlag ? "disabled" : "primary"}
                fontSize="large"
              />
            </IconButton>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <CustomCheckbox
                  disabled={disabledFlag}
                  checked={wholeDayFlag}
                  onChange={() => setWholeDayFlag(!wholeDayFlag)}
                  sx={{
                    color: "#22ba9d",
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                    },
                  }}
                />
              }
              label={translations.AllDay}
              componentsProps={{
                typography: {
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  color: "#454545",
                  lineHeight: "19px",
                  letterSpacing: "0.7px",
                  marginRight: "-1px",
                },
              }}
            />
          </Box>
        </Box>
        <Box flexGrow="1" marginRight="20px">
          <Slider
            aria-label="Always visible"
            disabled={disabledFlag || wholeDayFlag}
            max={fifteenMinutesIntervalHours.length - 1}
            step={1}
            valueLabelDisplay="on"
            valueLabelFormat={fifteenMinutesIntervalHours[timeIndex]}
            value={timeIndex}
            onChange={handleTimeChange}
            track={false}
            sx={{
              color: "#f0f0f0",
              height: "10px",
              padding: "0",
              opacity: "1",
              "& .MuiSlider-thumb": {
                height: "10px",
                borderRadius: "19px",
                color: "#22ba9d",
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: "none",
                  color: "#22ba9d",
                },
              },
              "& .MuiSlider-thumb::before": {
                boxShadow: "none",
              },
              "& .MuiSlider-thumb::after": {
                width: "0",
                height: "0",
              },
              "& .MuiSlider-valueLabel": {
                fontSize: "12px",
                color: "#22ba9d",
                lineHeight: "14px",
                background: "none",
              },
              "& .MuiSlider-valueLabel::before": {
                width: "0",
              },
            }}
          />
        </Box>
        <Box>
          <EmployeeLayoutModeSelect
            type={"status"}
            branchId={
              companyRelations.default_branch_id
                ? companyRelations.default_branch_id
                : branchId
            }
            floorId={
              companyRelations.default_floor_id
                ? companyRelations.default_floor_id
                : 0
            }
            date={date}
            wholeDayFlag={wholeDayFlag}
            timeIndex={timeIndex}
            time={fifteenMinutesIntervalHours[timeIndex]}
          />
        </Box>
      </Box>
    </Box>
  )
}
