import React, { useState, useContext } from "react"
import { Controller, useFormContext } from "react-hook-form"

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import CircleCheckedFilled from "@mui/icons-material/CheckCircle"
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked"
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/mobileSchedule/en"
import jaTranslations from "../../../../../translations/mobileSchedule/ja"
import { GreenArrowIcon } from "../../../../public/GreenArrowIcon"

const CustomModalBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "95%",
  Height: "70%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}))

const RepeatAreaTextField = styled(TextField)({
  width: "60px",
  maxWidth: "60px",
  minWidth: "60px",
  marginRight: "1rem",
  backgroundColor: "#fff",
  "& .MuiInputBase-input": {
    padding: "0",
    height: "2.5rem",
    textAlign: "center",
    backgroundColor: "rgba(112,112,112,0.05)",
  },
})

const CustomSelect = styled(Select)({
  height: "2.5rem",
  width: "100px",
  backgroundColor: "rgba(112,112,112,0.05)",
  "& .MuiSelect-outlined": {
    padding: "0 0 0 8px",
  },
})
interface Props {
  repeatModalClose: () => void
  setRepeatDays: React.Dispatch<React.SetStateAction<string[]>>
  dayOfWeek: string[]
  monthlySetting: "day" | "dayOfWeek" | undefined
  setMonthlySetting: React.Dispatch<
    React.SetStateAction<"day" | "dayOfWeek" | undefined>
  >
}

export const RepeatScheduleModal: React.FC<Props> = ({
  repeatModalClose,
  setRepeatDays,
  dayOfWeek,
  monthlySetting,
  setMonthlySetting,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const days: string[] = [
    translations["Sunday"],
    translations["Monday"],
    translations["Tuesday"],
    translations["Wednesday"],
    translations["Thursday"],
    translations["Friday"],
    translations["Saturday"],
  ]
  const weeks: string[] = [
    translations["first"],
    translations["second"],
    translations["third"],
    translations["fourth"],
    translations["last"],
  ]
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ScheduleForm>()

  const { schedulableMonthes } = useContext(SchedulableMaxDateContext)
  const selectmonthes = () => {
    const selectMonth = []
    for (let i = 1; i < schedulableMonthes + 1; i++) {
      selectMonth.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      )
    }
    return selectMonth
  }

  const selectedDay = () => {
    return weeks[getValues("schedule.monthly.dayOfWeekly.weekly") - 1]
  }

  const selectedWeekDay = () => {
    return days[getValues("schedule.monthly.dayOfWeekly.dayOfWeek")]
  }

  return (
    <>
      <CustomModalBox>
        <Box display="flex" mb={2}>
          <ArrowBackIosNewIcon
            color="primary"
            fontSize="large"
            onClick={repeatModalClose}
          />
          <Typography variant="h6" ml={1}>
            {translations.RepeatSetting}
          </Typography>
        </Box>
        <Box display="flex">
          <Controller
            control={control}
            name="schedule.schedule_date_type"
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={Boolean(watch("schedule.schedule_date_type"))}
                onChange={(_, checked) => {
                  if (checked) {
                    const start_date = getValues("schedule.start_date")
                    if (start_date) {
                      const wDay = start_date.getDay()
                      getValues("schedule.weekly.days")[wDay] = true
                      setMonthlySetting("day")
                      setRepeatDays(
                        dayOfWeek.filter((day, i) => {
                          return getValues("schedule.weekly.days")[i] === true
                        })
                      )
                    }
                  } else {
                    setValue("schedule.weekly.days", new Array(7).fill(false))
                    setMonthlySetting(undefined)
                  }

                  setValue("schedule.schedule_date_type", Number(checked))
                }}
                name="schedule_date_type"
                id="schedule_date_type"
                sx={{ padding: "10px 0" }}
                icon={<CircleUnchecked style={{ color: "22BA9D" }} />}
                checkedIcon={<CircleCheckedFilled />}
              />
            )}
          />

          <Box
            marginLeft={1}
            display="flex"
            alignItems="center"
            sx={{
              color: "#22BA9D",
              fontWeight: "bolder",
            }}
          >
            {translations.RepeatSettings}
          </Box>
        </Box>

        <Box display="flex">
          <FormControl sx={{ width: "40%", marginRight: "1rem" }}>
            <Controller
              control={control}
              name="schedule.weekly_or_monthly"
              render={({ field }) => (
                <Select
                  {...field}
                  id="weekly_or_monthly"
                  name="weekly_or_monthly"
                  sx={{
                    height: "2.5rem",
                    backgroundColor: "rgba(112,112,112,0.05)",
                    "& .MuiSelect-outlined": {
                      padding: "0 0 0 8px",
                    },
                  }}
                  onChange={(e) => {
                    if (
                      e.target.value === "weekly" ||
                      e.target.value === "monthly"
                    ) {
                      field.onChange(e.target.value)
                      watch("schedule.weekly_or_monthly")
                      setValue("schedule.weekly_or_monthly", e.target.value)
                    }
                  }}
                  defaultValue={"weekly"}
                >
                  <MenuItem value={"weekly"}>{translations.Weekly}</MenuItem>
                  <MenuItem value={"monthly"}>{translations.Monthly}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          {getValues("schedule.weekly_or_monthly") === "weekly" && (
            <>
              <FormControl sx={{ width: "25%", marginRight: "1rem" }}>
                <Controller
                  control={control}
                  name="schedule.weekly.every_week"
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="schedule.weekly.every_week"
                      name="every_week"
                      error={!!errors.schedule?.weekly?.every_week}
                      // helperText={errors.schedule?.weekly?.every_week?.message}
                      fullWidth
                      sx={{
                        marginRight: "1rem",
                        backgroundColor: "#fff",
                        "& .MuiInputBase-input": {
                          padding: "0 0 0 8px",
                          height: "2.5rem",
                          lineHeight: "2.5rem",
                          textAlign: "center",
                          backgroundColor: "rgba(112,112,112,0.05)",
                        },
                      }}
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                      defaultValue={1}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <Box
                sx={{ width: "30%", height: "2.5rem", lineHeight: "2.5rem" }}
              >
                {translations.Weekly}
              </Box>
            </>
          )}

          {getValues("schedule.weekly_or_monthly") === "monthly" && (
            <>
              <FormControl sx={{ width: "25%", marginRight: "1rem" }}>
                <Controller
                  control={control}
                  name="schedule.monthly.dayOfWeekly.monthly"
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="schedule.monthly.dayOfWeekly.monthly"
                      name="monthly"
                      fullWidth
                      error={!!errors.schedule?.monthly?.dayly?.monthly}
                      sx={{
                        marginRight: "1rem",
                        backgroundColor: "#fff",
                        "& .MuiInputBase-input": {
                          padding: "0 0 0 8px",
                          height: "2.5rem",
                          lineHeight: "2.5rem",
                          textAlign: "center",
                          backgroundColor: "rgba(112,112,112,0.05)",
                        },
                      }}
                      onChange={(e) => {
                        field.onChange(e)
                        const number = e.target.value as number
                        if (1 <= number && number <= schedulableMonthes)
                          setValue(
                            "schedule.monthly.dayOfWeekly.monthly",
                            e.target.value as number
                          )
                      }}
                    >
                      {selectmonthes()}
                    </Select>
                  )}
                />
              </FormControl>
              <Box
                sx={{
                  width: "50%",
                  height: "2.5rem",
                  lineHeight: "2.5rem",
                }}
              >
                {translations.everyMonths}
              </Box>
            </>
          )}
        </Box>
        {getValues("schedule.weekly_or_monthly") === "weekly" && (
          <Grid container display="flex" spacing={2} sx={{ marginTop: "1rem" }}>
            {days.map((day, index) => (
              <Grid item sm={3} key={index} mb={2}>
                <Box display="flex">
                  <Checkbox
                    name="days"
                    checked={watch("schedule.weekly.days")[index]}
                    onChange={(_, checked) => {
                      if (!watch("schedule.schedule_date_type")) {
                        return
                      }
                      if (
                        !checked &&
                        getValues("schedule.weekly.days").filter((day) => day)
                          .length === 1
                      ) {
                        return
                      }
                      const days: boolean[] = getValues("schedule.weekly.days")
                      days.splice(index, 1, checked)
                      setValue("schedule.weekly.days", days)
                    }}
                    sx={{ padding: "0" }}
                    icon={<CircleUnchecked style={{ color: "#22BA9D" }} />}
                    checkedIcon={<CircleCheckedFilled />}
                  />
                  <Box display="flex" alignItems="center" fontSize="0.9rem">
                    {day}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        {getValues("schedule.weekly_or_monthly") === "monthly" && (
          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            <Grid display="flex" item sm={12} mb={2}>
              <Box display="flex">
                <Checkbox
                  name="monthly_day"
                  checked={monthlySetting === "day"}
                  onChange={() => {
                    if (!watch("schedule.schedule_date_type")) {
                      return
                    }
                    setMonthlySetting("day")
                  }}
                  sx={{ padding: "0" }}
                  icon={<CircleUnchecked style={{ color: "#22BA9D" }} />}
                  checkedIcon={<CircleCheckedFilled />}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ minWidth: "30px", marginRight: "8px" }}
                >
                  {translations.Day}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  height: "2.5rem",
                  lineHeight: "2.5rem",
                }}
              >
                {getValues("schedule.monthly.dayly.day")}
                {translations.day}
              </Box>
            </Grid>
            <Grid display="flex" item sm={12} mb={2}>
              <Box display="flex">
                <Checkbox
                  name="monthly_dayOfWeek"
                  checked={monthlySetting === "dayOfWeek"}
                  onChange={() => {
                    if (!watch("schedule.schedule_date_type")) {
                      return
                    }
                    setMonthlySetting("dayOfWeek")
                  }}
                  sx={{ padding: "0" }}
                  icon={<CircleUnchecked style={{ color: "#22BA9D" }} />}
                  checkedIcon={<CircleCheckedFilled />}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ minWidth: "50px", marginRight: "8px" }}
                >
                  {translations.DayOfWeek}
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                {selectedDay()} {selectedWeekDay()}
              </Box>
            </Grid>
          </Grid>
        )}
      </CustomModalBox>
    </>
  )
}
