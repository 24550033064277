import React, { useState, useEffect } from "react"

import { Box, Typography, styled } from "@mui/material"

import alertSound from "../../../../../src/audios/alarm_notification.wav"
import { theme } from "../../../../config/theme"

const TimeTypography = styled(Typography)(() => ({
  fontSize: theme.spacing(5),
  fontWeight: "bold",
  color: "#454545",
}))

interface Props {
  endTime: Date
  setIsExtendButtonVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const formatTime = (time: number) => {
  const hours = (`00` + Math.floor(time / (1000 * 60 * 60))).slice(-2)
  const minutes = (`00` + Math.floor((time / (1000 * 60)) % 60)).slice(-2)
  const seconds = (`00` + Math.floor((time / 1000) % 60)).slice(-2)

  return { hours, minutes, seconds }
}

export const CountDownTimer: React.FC<Props> = ({
  endTime,
  setIsExtendButtonVisible,
}: Props) => {
  const [restTime, setRestTime] = useState<number>(
    endTime.getTime() - new Date().getTime()
  )
  const [hasSoundPlayed, setHasSoundPlayed] = useState<boolean>(false)

  useEffect(() => {
    const audio = new Audio(alertSound)
    let frameId: number

    const countDown = () => {
      const currentTime = new Date().getTime()
      const rest = endTime.getTime() - currentTime
      setRestTime(rest)

      if (rest <= 0) {
        setIsExtendButtonVisible(false)
        cancelAnimationFrame(frameId)
      } else {
        frameId = requestAnimationFrame(countDown)
      }

      if (rest <= 300000 && !hasSoundPlayed) {
        audio.play()
        setHasSoundPlayed(true)
      }
    }

    frameId = requestAnimationFrame(countDown)

    return () => {
      cancelAnimationFrame(frameId)
    }
  }, [endTime, setIsExtendButtonVisible, hasSoundPlayed])

  const { hours, minutes, seconds } = formatTime(restTime)

  return (
    <Box my={2}>
      <Typography variant="body2" textAlign="center">
        会議終了まで
      </Typography>
      <TimeTypography>{`${hours}:${minutes}:${seconds}`}</TimeTypography>
    </Box>
  )
}
