import { useState } from "react"

import { fetchScheduleNewMessageRequest } from "../../../../api/employee/scheduleRequest"
import { ScheduleNewMessage } from "./type"

const initialScheduleNewMessage: ScheduleNewMessage[] = [
  {
    schedule_id: 0,
    schedule_title: "",
    schedule_creator: "",
    start_time: "",
    end_time: "",
    start_date: "",
    end_date: "",
    message_content: "",
    reservable: {
      id: 0,
      seat_name: "",
      branch: {
        id: 0,
        branch_name: "",
      },
    },
    weekly_recurring_type: {
      id: 0,
      every_week: 0,
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    },
    userable_id: 0,
    schedule_creator_id: 0,
    schedule_information_id: 0,
  },
]
export const useNewScheduleMessageState = (
  initialScheduleNewMessageObject: ScheduleNewMessage[] = initialScheduleNewMessage
) => {
  const [scheduleNewMessage, setScheduleNewMessage] = useState<
    ScheduleNewMessage[]
  >(initialScheduleNewMessageObject)

  return { scheduleNewMessage, setScheduleNewMessage }
}

export const useScheduleNewMessage = () => {
  const { scheduleNewMessage, setScheduleNewMessage } =
    useNewScheduleMessageState()

  const fetchNewScheduleMessage = async () => {
    try {
      const response = await fetchScheduleNewMessageRequest()
      if (response.status === 200 && response.data) {
        setScheduleNewMessage(response.data)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    }
  }

  return { scheduleNewMessage, setScheduleNewMessage, fetchNewScheduleMessage }
}
