import React, { memo, useState } from "react"

import AddIcon from "@mui/icons-material/Add"
import { Box, styled, Button, Typography, Checkbox } from "@mui/material"

import {
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import { SeatType } from "../../../../models/company/useLayout/type"

export const CustomTypography = styled(Typography)({
  color: "#22BA9D",
  fontWeight: "bold",
  fontSize: "12px",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 4px gray",
  },
})

interface Props {
  seats: SeatType[]
  layoutImg: string
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
  handleDeleteSeats: () => void
  handleAreaSeats: () => void
  handleDeleteMeetingRoomsOrAreas: () => void
  sidebarTabType: SidebarTabType
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
  seatAllCheck: (isSeatAllCheck: boolean) => void
  meetingRoomOrAreaAllCheck: (isMeetingRoomOrAreaAllCheck: boolean) => void
}

/*
  レイアウトのサイドバーのメニュー（席・会議室）
*/
export const CompanyLayoutSideberMenu: React.FC<Props> = memo(
  ({
    seats,
    layoutImg,
    setSidebarLayoutComponent,
    handleDeleteMeetingRoomsOrAreas,
    handleDeleteSeats,
    handleAreaSeats,
    sidebarTabType,
    setSidebarTabType,
    seatAllCheck,
    meetingRoomOrAreaAllCheck,
  }: Props) => {
    const [isSeatAllCheck, setIsSeatAllCheck] = useState<boolean>(false)
    const [isMeetingRoomOrAreaAllCheck, setIsMeetingRoomOrAreaAllCheck] =
      useState<boolean>(false)

    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          {sidebarTabType === "seat" && (
            <>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  if (layoutImg !== "") {
                    setSidebarTabType("seatForm")
                    setSidebarLayoutComponent("seatAdd")
                  }
                }}
                sx={{ width: "128px" }}
              >
                <AddIcon color="inherit" sx={{ fontSize: "12px" }} />
                <Typography variant="caption">席の追加</Typography>
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  const seatInfo = seats.filter((seat) => {
                    return seat.isCheck === true
                  })
                  if (layoutImg !== "" && seatInfo.length !== 0) {
                    handleAreaSeats()
                    setSidebarLayoutComponent("areaForm")
                    setSidebarTabType("areaForm")
                  }
                }}
                sx={{ width: "128px" }}
              >
                <AddIcon color="inherit" sx={{ fontSize: "12px" }} />
                <Typography variant="caption">エリアの追加</Typography>
              </Button>
            </>
          )}
          {sidebarTabType === "meetingRoom" && (
            <>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  if (layoutImg !== "") {
                    setSidebarLayoutComponent("meetingRoomAdd")
                    setSidebarTabType("meetingRoomForm")
                  }
                }}
                sx={{ width: "130px" }}
              >
                <AddIcon color="inherit" fontSize="small" />
                <Typography variant="caption">会議室の追加</Typography>
              </Button>
            </>
          )}
        </Box>
        {sidebarTabType === "seat" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Box>
              <Checkbox
                checked={isSeatAllCheck}
                color="primary"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIsSeatAllCheck(e.target.checked)
                  seatAllCheck(e.target.checked)
                }}
                size="small"
              />
              <Typography fontWeight="bold" variant="caption">
                全選択
              </Typography>
            </Box>
            <CustomTypography
              variant="caption"
              onClick={() => {
                handleDeleteSeats()
                layoutImg !== "" ? setSidebarLayoutComponent("seatList") : ""
              }}
            >
              削除
            </CustomTypography>
          </Box>
        )}
        {sidebarTabType === "meetingRoom" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Box>
              <Checkbox
                checked={isMeetingRoomOrAreaAllCheck}
                color="primary"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIsMeetingRoomOrAreaAllCheck(e.target.checked)
                  meetingRoomOrAreaAllCheck(e.target.checked)
                }}
                size="small"
              />
              <Typography
                variant="caption"
                fontWeight="bold"
                onClick={() => {
                  handleDeleteSeats()
                  layoutImg !== "" ? setSidebarLayoutComponent("seatList") : ""
                }}
              >
                全選択
              </Typography>
            </Box>
            <CustomTypography
              variant="caption"
              onClick={() => {
                handleDeleteMeetingRoomsOrAreas()
                layoutImg !== ""
                  ? setSidebarLayoutComponent("meetingRoomList")
                  : ""
              }}
            >
              削除
            </CustomTypography>
          </Box>
        )}
      </>
    )
  }
)
