import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  createCreditCardRequest,
  fetchCreditCardRequest,
} from "../../../api/company/creditCardRequest"
import { Multipayment } from "../../../components/public/CustomHeadTag"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { CreditCard } from "./type"

export const useGMOMultiPayment = () => {
  const getToken = async (
    creditCard: Parameters<typeof Multipayment.getToken>[0]
  ) => {
    return new Promise<ReturnType<typeof Multipayment.getToken>>((resolve) => {
      const Multipayment = window.Multipayment

      if (process.env.REACT_APP_GMO_PUBLIC_KEY) {
        Multipayment.init(process.env.REACT_APP_GMO_PUBLIC_KEY)
      }
      Multipayment.getToken(creditCard, resolve)
    })
  }

  return {
    getToken,
  }
}

export const useCreditCard = () => {
  const { getToken } = useGMOMultiPayment()
  const [defaultCreditCard, setDefaultCreditCard] = useState<
    CreditCard | undefined
  >(undefined)
  const navigate = useNavigate()

  const creditCardErrorMessage = useAlertMessage()

  const basicSchema = Yup.object().shape({
    cardno: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください"),
    expireYear: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください"),
    expireMonth: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください"),
    holdername: Yup.string().required("必須になります"),
    securitycode: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください"),
  })

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
  })

  const onCreateCreditCard = form.handleSubmit(
    async ({ cardno, expireMonth, expireYear, holdername, securitycode }) => {
      const paddingMonth = String(expireMonth).padStart(2, "0")

      try {
        const creditResponse = await getToken({
          cardno,
          expire: Number(`${expireYear}${paddingMonth}`),
          holdername,
          securitycode,
        })

        if (creditResponse.resultCode === "000") {
          const data = await createCreditCardRequest({
            token: creditResponse.tokenObject.token,
          })

          if (data.status == 204) {
            localStorage.setItem("alertContent", "お支払い方法を更新しました")
            navigate(`/company/settings/payment`)
            window.location.reload()
          } else {
            creditCardErrorMessage.handleSetMessage(
              "クレジットカードの登録に失敗しました。"
            )
            creditCardErrorMessage.openMessage()
          }
        } else {
          creditCardErrorMessage.handleSetMessage(
            "クレジットカードの入力に誤りがあります。"
          )
          creditCardErrorMessage.openMessage()
        }
      } catch (e) {
        console.log("error")
        creditCardErrorMessage.handleSetMessage(
          "クレジットカードの入力に誤りがあります。"
        )
        creditCardErrorMessage.openMessage()
      }
      form.reset({
        cardno: "",
        expireMonth: "",
        expireYear: "",
        holdername: "",
        securitycode: "",
      })
    }
  )

  const onFetchCreditCard = async () => {
    try {
      const response = await fetchCreditCardRequest()
      if (response.data && response.status === 200) {
        setDefaultCreditCard(response.data)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    form,
    onCreateCreditCard,
    creditCardErrorMessage,
    onFetchCreditCard,
    defaultCreditCard,
  }
}
