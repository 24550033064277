import React from "react"

import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { ForgotPasswordForm } from "../../../components/public/auth/forgotPasswordForm"
import { useForgotPassword } from "../../../models/public/useAuth/forgotPassword"

export const CompanyForgotPassword = () => {
  const authObject = useForgotPassword()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <ForgotPasswordForm {...authObject} />
    </AuthPageTemplate>
  )
}
