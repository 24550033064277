export type SeatType = {
  id: number | null
  layout_id: number
  area_id?: number | null
  seat_name: number
  available_time_type: number
  seat_type: number
  x: number
  y: number
  isCheck?: boolean
  isAreaSeatDeleteCheck?: boolean
  isNew?: boolean
}

export type MeetingRoomType = {
  id: number | null
  meeting_room_code: string | number
  meeting_room_name: string
  capacity: number
  availability_type: number
  reservable_type: number
  available_time: number
  meeting_room_description: string
  availability_team_ids: number[]
  reservable_team_ids: number[]
  tag_ids: number[]
  appliance_ids: number[]
  outside_team_usable: boolean
  start_x: number
  start_y: number
  end_x: number
  end_y: number
  color_code: string
  meeting_room_image: string
  image_blob?: Blob | undefined
  isCheck?: boolean
  isNew?: boolean
  auto_cancel_times_id: number
  meeting_room_reservable_all?: number
  meeting_room_availability_all?: number
}

export type MeetingRoomFormType = {
  id: number
  meeting_room_name: string
  availability_type: number
  reservable_type: number
  availability_team_ids: number[]
  reservable_team_ids: number[]
  capacity: number
  available_time: number
  color_code: string
  meeting_room_description: string
  tag_ids: number[]
  appliance_ids: number[]
  image_blob: Blob | undefined
  auto_cancel_times_id: number
  meeting_room_reservable_all: number | undefined
  meeting_room_availability_all: number | undefined
}

export type AreaFormType = {
  id: number
  area_name: string
  is_reservable: boolean
  is_area_to_meeting_room: boolean
  availability_type: number
  reservable_type: number
  availability_team_ids: number[]
  reservable_team_ids: number[]
  area_description: string
  available_time: number
  color_code: string
  outside_team_usable: boolean
  tag_ids: number[]
  image_blob: Blob | undefined
  auto_cancel_times_id: number
  area_reservable_all: number | undefined
  area_availability_all: number | undefined
}

export type AreaType = {
  id: number | null
  area_name: string
  is_reservable: boolean
  availability_type: number
  reservable_type: number
  area_description: string
  start_x: number
  start_y: number
  end_x: number
  end_y: number
  color_code: string
  is_area_to_meeting_room: boolean
  availability_team_ids: number[]
  reservable_team_ids: number[]
  area_to_meeting_room?: {
    available_time: number
    outside_team_usable: boolean
    meeting_room_code: string | number
  }
  tag_ids: number[]
  children: SeatType[]
  area_image: string
  image_blob?: Blob | undefined
  auto_cancel_times_id: number
  isCheck?: boolean
  isNew?: boolean
  isExpanded?: boolean
  area_reservable_all?: number
  area_availability_all?: number
}

export const PostStatus = {
  draft: 0,
  released: 1,
  paused: 2,
  deleted: 3,
  not_saved: 4,
} as const

export type PostStatus = typeof PostStatus[keyof typeof PostStatus]

export type LayoutType = {
  layout_image: string
  post_status: PostStatus
  release_date: string | Date
  paused_date: string | Date
  version: number
  width: number
  height: number
  isNewLayoutImage?: boolean
  x: number
  y: number
  scale: number
}

// TODO
// floor_idを席・会議室・エリアに紐付けているため、1つだけにする
export type LayoutRelationType = {
  layout: {
    company_id: number
    branch_id: number
    floor_id: number
    layout_image: string
    post_status: number
    release_date: string | Date
    paused_date: string | Date
    version: number
    width: number
    height: number
    x: number
    y: number
    scale: number
    seats: SeatType[]
    meeting_rooms: MeetingRoomType[]
    areas: AreaType[]
    layout_id: number
    delete: {
      seats: number[]
      meeting_rooms: number[]
      areas: number[]
    }
  }
}

export type ResavableSeatType = {
  id: number
  seat_name: string | number
  seat_type: number
  x: number
  y: number
  employee: {
    employee_id: number
    icon: string
    last_name: string
    first_name: string
  }
}

export type LayoutResavableSeatType = {
  floor: {
    layout: LayoutType
    seats: ResavableSeatType[]
  }
}
