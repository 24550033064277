const ja = {
  EmployeeName: "従業員名",
  EmployeeNameKana: "従業員名（カナ）",
  Email: "メールアドレス",
  EmployeeId: "従業員ID",
  CompanyPhoneNumber: "社用電話番号",
  Location: "拠点名",
  Floor: "フロア名",
  DivisionDepartment: "所属名",
  ManagerName: "所属長名",
  ManagerEmail: "所属長メールアドレス",
  WorkArrangement: "勤務形態",
  AssignedDesk: "指定席",
  AssignedDeskId: "指定席ID",
  Remarks: "備考",
  Return: "戻る",
  No: "なし",
  Yes: "あり",
  FullTime: "フルリモート勤務",
  OfficeWork: "出社勤務",
}
export default ja
