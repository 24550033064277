import React, { ReactNode, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

import { Box, styled } from "@mui/material"

import { Loading } from "../components/public/Loading"
import { SignageHeader } from "../components/public/SignageHeader"
import { theme } from "../config/theme"
import { useCompany } from "../models/company/useCompany"

const CustomMainContent = styled(Box)(() => ({
  padding: theme.spacing(1.5),
  width: "100%",
  backgroundColor: "#F7F7F7",
}))

export const SignageTemplate = ({ children }: { children: ReactNode }) => {
  const companyObject = useCompany()
  const headerObject = companyObject.headerCompanyInfo
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    companyObject.fetchCompany().then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>
      {loading ? (
        <Loading type="fullScreen" />
      ) : (
        <>
          {headerObject ? (
            <Box sx={{ boxSizing: "border-box" }}>
              <SignageHeader {...companyObject} />
              <CustomMainContent>{children}</CustomMainContent>
            </Box>
          ) : (
            <Navigate to="/company/register-info" />
          )}
        </>
      )}
    </>
  )
}
