import React, { useEffect } from "react"

import { MobileEmployeeProfileShowData } from "../../../components/mobile/profile"
import { useProfile } from "../../../models/employee/useProfile"

export const MobileEmployeeProfileShowPage = () => {
  const { fetchEmployeeProfile, employeeProfile } = useProfile()

  useEffect(() => {
    fetchEmployeeProfile()
  }, [])

  return (
    <>
      <MobileEmployeeProfileShowData employeeProfile={employeeProfile} />
    </>
  )
}
