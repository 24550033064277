import React from "react"

import { Box, styled, Typography } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { useScheduleShowData } from "../../../../../models/employee/useSchedule/show"
import { FetchScheduleShowFunction } from "../../../../../models/employee/useSchedule/show/type"
import {
  GoogleEvents,
  MicrosoftEvents,
  ScheduleType,
} from "../../../../../models/employee/useSchedule/weeks/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleMain/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleMain/ja"
import { useOpen } from "../../../../../utils/isOpen"
import { bgColorChoice } from "../../../../../utils/schedules"
import { EmployeeScheduleDayShowModal } from "../../days/EmployeeScheduleDayShowModal"

const LeftHalfScheduleBox = styled(Box)(() => ({
  height: theme.spacing(5),
  width: "50%",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  marginTop: theme.spacing(0.75),
  overflowX: "hidden",
  whiteSpace: "nowrap",
}))

const RightHalfScheduleBox = styled(Box)(() => ({
  height: theme.spacing(5),
  width: "50%",
  marginLeft: "auto",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  marginTop: theme.spacing(0.75),
  overflowX: "hidden",
  whiteSpace: "nowrap",
}))

const FullScheduleBox = styled(Box)(() => ({
  height: theme.spacing(5),
  width: "100%",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(0.75),
  overflowX: "hidden",
  whiteSpace: "nowrap",
}))

const CustomBox = styled(Box)(() => ({
  zIndex: 1000,
  overflowX: "hidden",
  whiteSpace: "nowrap",
  marginLeft: "5px",
  fontSize: "12px",
  fontFamily: "Noto Sans",
}))

const EventNumIcon = styled(Typography)(() => ({
  display: "inline-block",
  borderRadius: "50%",
  textAlign: "center",
  height: "25px",
  width: "25px",
  marginLeft: theme.spacing(0.75),
  color: "#22BA9D",
  fontWeight: "bolder",
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "25px",
  cursor: "pointer",
  backgroundColor: "rgba(255,255,255,0.3)",
}))

interface Props {
  employee_id: number
  scheduled_date: string
  schedules: ScheduleType[]
  event_schedules: ScheduleType[]
  microsoft_events: MicrosoftEvents
  google_events: GoogleEvents
  displayReserved: boolean
  displayNonReserved: boolean
}

// event_schedulesをamとpmに分ける（詳細表示用）
const ampmSchedules = (event_schedules: ScheduleType[]) => {
  const amSchedules: ScheduleType[] = []
  const pmSchedules: ScheduleType[] = []
  event_schedules.map((schedule) => {
    if (schedule.schedule_judge == "AM") {
      amSchedules.push(schedule)
    } else {
      pmSchedules.push(schedule)
    }
  })
  return { amSchedules, pmSchedules }
}

const msAMPMSchedules = (event_schedules: MicrosoftEvents) => {
  const msAMSchedules: MicrosoftEvents = []
  const msPMSchedules: MicrosoftEvents = []
  event_schedules.map((schedule) => {
    if (schedule.schedule_judge == "AM") {
      msAMSchedules.push(schedule)
    } else {
      msPMSchedules.push(schedule)
    }
  })
  return { msAMSchedules, msPMSchedules }
}

const googleAMPMSchedules = (event_schedules: GoogleEvents) => {
  const googleAMSchedules: GoogleEvents = []
  const googlePMSchedules: GoogleEvents = []
  event_schedules.map((schedule) => {
    if (schedule.schedule_judge == "AM") {
      googleAMSchedules.push(schedule)
    } else {
      googlePMSchedules.push(schedule)
    }
  })
  return { googleAMSchedules, googlePMSchedules }
}
export const EmployeeScheduleWeek: React.FC<Props> = ({
  employee_id,
  scheduled_date,
  schedules,
  displayReserved,
  microsoft_events,
  google_events,
  event_schedules,
  displayNonReserved,
}) => {
  // 予定のステータスに応じて表示する文言を変更
  const setScheduleText = (
    schedule_status: string,
    branch_name: string
  ): string => {
    if (schedule_status === "remote") {
      return translations.WorkFromHome
    } else if (schedule_status === "going_out") {
      return translations.OutOfOffice
    } else if (schedule_status === "trip") {
      return translations.BusinessTrip
    } else if (schedule_status === "holiday") {
      return translations.Vacation
    } else if (schedule_status === "in_office") {
      return branch_name
    } else {
      return ""
    }
  }

  const textChoice = setScheduleText
  const { isOpen, open, close } = useOpen()
  const { scheduleShowData, fetchScheduleShowData, onApproveSubmit } =
    useScheduleShowData()
  const { amSchedules, pmSchedules } = ampmSchedules(event_schedules)
  const { msAMSchedules, msPMSchedules } = msAMPMSchedules(microsoft_events)
  const { googleAMSchedules, googlePMSchedules } =
    googleAMPMSchedules(google_events)
  // 終日スケジュールのみ、時間が長い順に並び替え
  const allDayStatusSchedules = schedules.filter((schedule) => {
    return schedule.schedule_judge === "all_day"
  })
  allDayStatusSchedules.sort((a, b) => b.schedule_length - a.schedule_length)

  // AMスケジュールのみ、時間が長い順に並び替え
  const amStatusSchedules = schedules.filter((schedule) => {
    return schedule.schedule_judge === "AM"
  })
  amStatusSchedules.sort((a, b) => b.schedule_length - a.schedule_length)

  // PMスケジュールのみ、時間が長い順に並び替え
  const pmStatusSchedules = schedules.filter((schedule) => {
    return schedule.schedule_judge === "PM"
  })
  pmStatusSchedules.sort((a, b) => b.schedule_length - a.schedule_length)

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <>
      <Box display="flex">
        {/* 終日予定、席予約 */}
        {!displayNonReserved &&
          allDayStatusSchedules.length > 0 &&
          allDayStatusSchedules[0].reservable_type && (
            <FullScheduleBox
              sx={{
                color: "white",
                backgroundColor: `${bgColorChoice(
                  allDayStatusSchedules[0].schedule_status
                )}`,
              }}
            >
              <CustomBox>
                {textChoice(
                  allDayStatusSchedules[0].schedule_status,
                  allDayStatusSchedules[0].reservable?.floor?.branch
                    ?.branch_name
                )}
              </CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      event_schedules,
                      microsoft_events,
                      google_events,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {event_schedules.length +
                    microsoft_events.length +
                    google_events.length}
                </EventNumIcon>
              </Box>
            </FullScheduleBox>
          )}
        {/* 終日予定、席予約なし */}
        {!displayReserved &&
          allDayStatusSchedules.length > 0 &&
          !allDayStatusSchedules[0].reservable_type && (
            <FullScheduleBox
              sx={{
                color: "white",
                backgroundColor: `${bgColorChoice(
                  allDayStatusSchedules[0].schedule_status
                )}`,
              }}
            >
              <CustomBox>
                {textChoice(
                  allDayStatusSchedules[0].schedule_status,
                  allDayStatusSchedules[0].reservable?.floor?.branch
                    ?.branch_name
                )}
              </CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      event_schedules,
                      microsoft_events,
                      google_events,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {event_schedules.length +
                    microsoft_events.length +
                    google_events.length}
                </EventNumIcon>
              </Box>
            </FullScheduleBox>
          )}
        {/* AM予定、席予約あり */}
        {!displayNonReserved &&
          allDayStatusSchedules.length === 0 &&
          amStatusSchedules.length > 0 &&
          amStatusSchedules[0].reservable_type && (
            <LeftHalfScheduleBox
              sx={{
                color: "white",
                backgroundColor: `${bgColorChoice(
                  amStatusSchedules[0].schedule_status
                )}`,
              }}
            >
              <CustomBox>
                {textChoice(
                  amStatusSchedules[0].schedule_status,
                  amStatusSchedules[0].reservable?.floor?.branch?.branch_name
                )}
              </CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      amSchedules,
                      msAMSchedules,
                      googleAMSchedules,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {amSchedules.length +
                    msAMSchedules.length +
                    googleAMSchedules.length}
                </EventNumIcon>
              </Box>
            </LeftHalfScheduleBox>
          )}
        {/* AM予定、席予約なし */}
        {!displayReserved &&
          allDayStatusSchedules.length === 0 &&
          amStatusSchedules.length > 0 &&
          !amStatusSchedules[0].reservable_type && (
            <LeftHalfScheduleBox
              sx={{
                color: "white",
                backgroundColor: `${bgColorChoice(
                  amStatusSchedules[0].schedule_status
                )}`,
              }}
            >
              <CustomBox>
                {textChoice(
                  amStatusSchedules[0].schedule_status,
                  amStatusSchedules[0].reservable?.floor?.branch?.branch_name
                )}
              </CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      amSchedules,
                      msAMSchedules,
                      googleAMSchedules,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {amSchedules.length +
                    msAMSchedules.length +
                    googleAMSchedules.length}
                </EventNumIcon>
              </Box>
            </LeftHalfScheduleBox>
          )}
        {/* AM予定なし（PM予定はありの場合） */}
        {schedules.length > 0 &&
          allDayStatusSchedules.length === 0 &&
          amStatusSchedules.length === 0 && (
            <LeftHalfScheduleBox
              sx={{
                color: "#C1C1C1",
                backgroundColor: "none",
                border: 1,
                borderColor: "#C1C1C1",
              }}
            >
              <CustomBox>{translations.TBD}</CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      amSchedules,
                      msAMSchedules,
                      googleAMSchedules,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {amSchedules.length +
                    msAMSchedules.length +
                    googleAMSchedules.length}
                </EventNumIcon>
              </Box>
            </LeftHalfScheduleBox>
          )}
        {/* PM予定、席予約あり */}
        {!displayNonReserved &&
          allDayStatusSchedules.length === 0 &&
          pmStatusSchedules.length > 0 &&
          pmStatusSchedules[0].reservable_type && (
            <RightHalfScheduleBox
              sx={{
                color: "white",
                backgroundColor: `${bgColorChoice(
                  pmStatusSchedules[0].schedule_status
                )}`,
              }}
            >
              <CustomBox>
                {textChoice(
                  pmStatusSchedules[0].schedule_status,
                  pmStatusSchedules[0].reservable?.floor?.branch?.branch_name
                )}
              </CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      pmSchedules,
                      msPMSchedules,
                      googlePMSchedules,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {pmSchedules.length +
                    msPMSchedules.length +
                    googlePMSchedules.length}
                </EventNumIcon>
              </Box>
            </RightHalfScheduleBox>
          )}
        {/* PM予定、席予約なし */}
        {!displayReserved &&
          allDayStatusSchedules.length === 0 &&
          pmStatusSchedules.length > 0 &&
          !pmStatusSchedules[0].reservable_type && (
            <RightHalfScheduleBox
              sx={{
                color: "white",
                backgroundColor: `${bgColorChoice(
                  pmStatusSchedules[0].schedule_status
                )}`,
              }}
            >
              <CustomBox>
                {textChoice(
                  pmStatusSchedules[0].schedule_status,
                  pmStatusSchedules[0].reservable?.floor?.branch?.branch_name
                )}
              </CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      pmSchedules,
                      msPMSchedules,
                      googlePMSchedules,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {pmSchedules.length +
                    msPMSchedules.length +
                    googlePMSchedules.length}
                </EventNumIcon>
              </Box>
            </RightHalfScheduleBox>
          )}
        {/* PM予定なし（AM予定はありの場合） */}
        {schedules.length > 0 &&
          allDayStatusSchedules.length === 0 &&
          pmStatusSchedules.length === 0 && (
            <RightHalfScheduleBox
              sx={{
                color: "#C1C1C1",
                backgroundColor: "none",
                border: 1,
                borderColor: "#C1C1C1",
              }}
            >
              <CustomBox>{translations.TBD}</CustomBox>
              <Box sx={{ zIndex: 1200 }}>
                <EventNumIcon
                  onClick={() => {
                    handleScheduleClick(
                      employee_id,
                      scheduled_date,
                      pmSchedules,
                      msPMSchedules,
                      googlePMSchedules,
                      fetchScheduleShowData,
                      open
                    )
                  }}
                >
                  {pmSchedules.length +
                    msPMSchedules.length +
                    googlePMSchedules.length}
                </EventNumIcon>
              </Box>
            </RightHalfScheduleBox>
          )}
        {/* status予定が1つもない場合 */}
        {schedules.length === 0 && (
          <FullScheduleBox
            sx={{
              color: "#C1C1C1",
              backgroundColor: "none",
              border: 1,
              borderColor: "#C1C1C1",
            }}
          >
            <CustomBox>{translations.TBD}</CustomBox>
            <Box sx={{ zIndex: 1200 }}>
              <EventNumIcon
                onClick={() => {
                  handleScheduleClick(
                    employee_id,
                    scheduled_date,
                    event_schedules,
                    microsoft_events,
                    google_events,
                    fetchScheduleShowData,
                    open
                  )
                }}
              >
                {event_schedules.length +
                  microsoft_events.length +
                  google_events.length}
              </EventNumIcon>
            </Box>
          </FullScheduleBox>
        )}
        <EmployeeScheduleDayShowModal
          date={new Date(scheduled_date)}
          scheduleShowData={scheduleShowData}
          isOpen={isOpen}
          close={close}
          onApproveSubmit={onApproveSubmit}
        />
      </Box>
    </>
  )
}

const handleScheduleClick = (
  employee_id: number,
  scheduled_date: string,
  schedules: ScheduleType[],
  microsoft_events: MicrosoftEvents,
  google_events: GoogleEvents,
  fetchScheduleShowData: FetchScheduleShowFunction,
  open: () => void
) => {
  const scheduleIds = schedules.map((schedule) => {
    return schedule.id
  })
  let msEventIds: number[] = []
  let googleEventIds: number[] = []
  if (microsoft_events.length > 0) {
    msEventIds = microsoft_events.map((ms_event) => {
      return ms_event.id
    })
  } else if (google_events.length > 0) {
    googleEventIds = google_events.map((google_event) => {
      return google_event.id
    })
  }

  if (
    schedules.length > 0 ||
    microsoft_events.length > 0 ||
    google_events.length > 0
  ) {
    open()
    fetchScheduleShowData(
      schedules.map((schedule) => {
        return schedule.schedule_information_id
      }),
      new Date(scheduled_date),
      [...scheduleIds, ...msEventIds, ...googleEventIds],
      microsoft_events.length != 0 ? microsoft_events : google_events,
      employee_id
    )
  }
}
