import React from "react"

import { MobileHomeSchedule } from "../../../components/mobile/home/main"
import { AlertContent } from "../../../components/public/AlertContent"

export const MobileHomePage = () => {
  return (
    <>
      <MobileHomeSchedule />
      <AlertContent />
    </>
  )
}
