import React from "react"

import { MobileAuthPageTemplate } from "../../../components/mobile/common/AuthPageTemplate"
import { LoginForm } from "../../../components/mobile/login/mobileLoginForm"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useLogin } from "../../../models/public/useAuth/login"

export const MobileLogin = () => {
  const authObject = useLogin()
  const { setLanguage } = useLanguage()

  return (
    <MobileAuthPageTemplate loading={authObject.loading}>
      <LoginForm {...authObject} setLanguage={setLanguage} />
    </MobileAuthPageTemplate>
  )
}
