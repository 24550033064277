import React, { createContext, useState } from "react"

type CalendarLayoutData = {
  branchId: number
  setBranchId: React.Dispatch<React.SetStateAction<number>>
  floorId: number
  setFloorId: React.Dispatch<React.SetStateAction<number>>
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
}

interface Props {
  children?: React.ReactNode
}

export const CalendarLayoutContext = createContext<CalendarLayoutData>(
  {} as CalendarLayoutData
)

export const CalendarLayoutProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)
  const [date, setDate] = useState<Date>(new Date())
  const [wholeDayFlag, setWholeDayFlag] = useState<boolean>(false)
  const [timeIndex, setTimeIndex] = useState<number>(0)

  return (
    <CalendarLayoutContext.Provider
      value={{
        branchId,
        setBranchId,
        floorId,
        setFloorId,
        date,
        setDate,
        wholeDayFlag,
        setWholeDayFlag,
        timeIndex,
        setTimeIndex,
      }}
    >
      {children}
    </CalendarLayoutContext.Provider>
  )
}
