import { AxiosResponse } from "axios"

export const useDownloadCsv = () => {
  // APIレスポンスのエンコードされたファイル名をdecodeする
  const getFileName = (contentDisposition: string) => {
    return decodeURI(contentDisposition).substring(
      contentDisposition.indexOf("''") + 2,
      contentDisposition.length
    )
  }

  // CSVをダウンロードする
  const download = (response: AxiosResponse) => {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]) // 文字化け防止

    const url = URL.createObjectURL(
      new Blob([response.data, bom], { type: response.data["type"] })
    ) // ダウンロードURLの生成

    // 仮で生成したaタグにhref属性、download属性を付与(両方ないとダウンロードできなかった)
    const link = document.createElement("a")
    link.href = url
    link.download = getFileName(response.headers["content-disposition"]) // download属性を付与するとAPIで設定したファイル名をそのまま使える

    document.body.appendChild(link)
    link.click() // 仮で生成したaタグをクリックしてダウンロード実行

    // その後不要になった要素は削除する
    URL.revokeObjectURL(url)
    link.parentNode?.removeChild(link)
  }

  return {
    download,
  }
}
