import { useState } from "react"

import {
  fetchWithdrawalRequest,
  withdrawalRequest,
} from "../../../api/company/withdrawalRequest"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { Withdrawal } from "./type"

export const useWithdrawal = () => {
  const errorMessage = useAlertMessage()
  const [withdrawal, setWithdrawal] = useState<Withdrawal>({
    end_date: new Date(),
    withdrawal_application_date: null,
  })

  const handleWithdrawal = async () => {
    try {
      const { error } = await withdrawalRequest()

      if (!error) {
        localStorage.setItem("alertContent", "解約を申し込みました")
        window.location.reload()
      } else {
        errorMessage.handleSetMessage(error[0])
        errorMessage.openMessage()
      }
    } catch (e) {
      console.log("error")
      errorMessage.handleSetMessage("解約に失敗しました")
      errorMessage.openMessage()
    }
  }

  const fetchWithdrawal = async () => {
    try {
      const response = await fetchWithdrawalRequest()
      if (response.status === 200 && response.data?.end_date) {
        setWithdrawal(response.data)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    handleWithdrawal,
    errorMessage,
    fetchWithdrawal,
    withdrawal,
  }
}
