const ja = {
  home: "ホーム",
  locationManagement: "拠点管理",
  employeeManagement: "従業員管理",
  terms: "組織管理",
  reports: "レポート",
  schedule: "予定表",
  workplace: "席状況",
  settings: "設定",
}
export default ja
