import React from "react"

import { Box } from "@mui/material"

import { CheckOutCompleted } from "../../../components/mobile/checkOut/CheckOutCompleted"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CheckOutCompletedPage = () => {
  const { companyRelations } = useCompanyRelations()
  return (
    <Box sx={{ height: "100vh", my: "150px" }}>
      <CheckOutCompleted companyRelations={companyRelations} />
    </Box>
  )
}
