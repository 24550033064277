import { CompanyWeeklyOffDayType } from "../../models/company/useCompanyWeeklyOffDay/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchCompanyWeeklyOffDayRequest = async (): Promise<
  ApiResponse<CompanyWeeklyOffDayType>
> => {
  try {
    const data = await api.get("/company/company_weekly_off_days")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const createCompanyWeeklyOffDayRequest = async (
  params: CompanyWeeklyOffDayType
): Promise<ApiResponse<CompanyWeeklyOffDayType>> => {
  try {
    const { data } = await api.post(`/company/company_weekly_off_days`, params)
    return { data }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "An unknown error occurred"
    return { error: [errorMessage] }
  }
}

export const updateCompanyWeeklyOffDayRequest = async (
  params: CompanyWeeklyOffDayType
): Promise<ApiResponse<CompanyWeeklyOffDayType>> => {
  try {
    const { data } = await api.patch(
      `/company/company_weekly_off_days/${params.id}`,
      params
    )
    return { data }
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "An unknown error occurred"
    return { error: [errorMessage] }
  }
}
