import React, { useEffect, useState } from "react"
import {
  UseFormGetValues,
  UseFormSetValue,
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  SubmitHandler,
  UseFormClearErrors,
  UseFormTrigger,
} from "react-hook-form"

import DeleteIcon from "@mui/icons-material/Delete"
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import {
  Box,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  EmployeeGroupEmployeeType,
  EmployeeGroupRequestType,
  EmployeeGroupsType,
  EmployeeGroupType,
} from "../../../../../models/employee/useEmployeeGroup/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeGroupFormModal/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeGroupFormModal/ja"
import { AlertSuccess } from "../../../../public/AlertSuccess"
import { Paging } from "../../../../public/Pagination"
import { EmployeeGroupFormModal } from "../EmployeeGroupFormModal"

const EmployeeGroupModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(100),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4, 4, 1),
  borderRadius: theme.spacing(1),
}))

const EmployeeGroupFormModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(70),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  position: "sticky",
  top: "0",
}))

interface Props {
  employeeGroups: EmployeeGroupsType
  fetchEmployeeGroups: () => Promise<void>
  deleteEmployeeGroup: (employeeGroupId: number) => Promise<void>
  successMessage: string
  setShowSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
  showSuccessMessage: boolean
  getValues: UseFormGetValues<EmployeeGroupRequestType>
  setValue: UseFormSetValue<EmployeeGroupRequestType>
  errorMessages: string[]
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  control: Control<EmployeeGroupRequestType>
  errors: FieldErrors<EmployeeGroupRequestType>
  trigger: UseFormTrigger<EmployeeGroupRequestType>
  clearErrors: UseFormClearErrors<EmployeeGroupRequestType>
  isValid: boolean
  handleSubmit: UseFormHandleSubmit<EmployeeGroupRequestType>
  onUpdateSubmit: SubmitHandler<EmployeeGroupRequestType>
  editFormOpen: boolean
  setEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmployeeGroupModal = ({
  employeeGroups,
  fetchEmployeeGroups,
  deleteEmployeeGroup,
  successMessage,
  setShowSuccessMessage,
  showSuccessMessage,
  getValues,
  setValue,
  errorMessages,
  setErrorMessages,
  control,
  errors,
  trigger,
  clearErrors,
  isValid,
  handleSubmit,
  onUpdateSubmit,
  editFormOpen,
  setEditFormOpen,
}: Props) => {
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const [loading, setLoading] = useState<boolean>(true)
  const [employeeIds, setEmployeeIds] = useState<number[]>([])
  const [employees, setEmployees] = useState<EmployeeGroupEmployeeType[]>([])

  const DATA_COUNT_PER_PAGE = 5 // 1ページに表示するレコードの数

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  const handleEmployeeGroupEditOpen = (employeeGroup: EmployeeGroupType) => {
    const employeeGroupMembers = employeeGroup.employee_group_members.map(
      (member) => {
        return member.employee
      }
    )

    const employeeGroupMemberIds = employeeGroupMembers.map((member) => {
      return member.id
    })

    // 各種入力項目に初期値が設定された状態にする
    setValue("group_name", employeeGroup.group_name)
    setValue("group_name_kana", employeeGroup.group_name_kana)
    setValue("employee_ids", employeeGroupMemberIds)
    setValue("id", employeeGroup.id)
    setEmployees(employeeGroupMembers)

    // 上記でセットした値に対して手動でバリデーションをトリガーして即座にフォーム送信可能な状態にする
    trigger()

    setEditFormOpen(true)
  }

  const handleEmployeeGroupEditClose = () => {
    if (confirm("編集中の内容は失われますがよろしいですか？")) {
      setValue("group_name", "")
      setValue("group_name_kana", "")
      setValue("employee_ids", [])
      clearErrors() // 先に編集時に発生したエラーをなくしておく
      setErrorMessages([])
      setEditFormOpen(false)
    }
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  useEffect(() => {
    fetchEmployeeGroups().then(() => {
      setLoading(false)
    })
  }, [])

  // お気に入りを削除した時に件数が5の倍数に達したらページ番号を1戻す
  useEffect(() => {
    if (employeeGroups.length >= 5) {
      if (employeeGroups.length == DATA_COUNT_PER_PAGE * (pageNumber - 1)) {
        const newPageNumber = pageNumber - 1
        setPageNumber(newPageNumber)
        setPageStatus((newPageNumber - 1) * DATA_COUNT_PER_PAGE)
      }
    }
  }, [employeeGroups.length])

  return (
    <>
      {editFormOpen ? (
        <EmployeeGroupFormModalOverlay>
          <EmployeeGroupFormModal
            formType="update"
            employeeIds={employeeIds}
            handleClose={handleEmployeeGroupEditClose}
            setEmployeeIds={setEmployeeIds}
            employees={employees}
            control={control}
            errors={errors}
            isValid={isValid}
            handleSubmit={handleSubmit}
            onSubmit={onUpdateSubmit}
            getValues={getValues}
            setValue={setValue}
            errorMessages={errorMessages}
          />
        </EmployeeGroupFormModalOverlay>
      ) : (
        <EmployeeGroupModalOverlay>
          <Box
            sx={{
              position: "relative",
              height: "100%",
            }}
          >
            {loading ? (
              <>
                <CustomTypography variant="h5">お気に入り一覧</CustomTypography>
                <Box
                  sx={{
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </>
            ) : (
              <>
                <CustomTypography variant="h5">
                  {translations.FavoriteTeamList}
                </CustomTypography>
                <TableContainer
                  sx={{
                    height: "80%",
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{translations.TeamName}</TableCell>
                        <TableCell>{translations.TeamNameKana}</TableCell>
                        <TableCell>{translations.Members}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeeGroups
                        .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                        .map((employeeGroup) => {
                          return (
                            <TableRow key={employeeGroup.id}>
                              <TableCell>{employeeGroup.group_name}</TableCell>
                              <TableCell>
                                {employeeGroup.group_name_kana}
                              </TableCell>
                              <TableCell>
                                {employeeGroup.employee_group_members.map(
                                  (member) => {
                                    return (
                                      <Typography key={member.id}>
                                        {`${member.employee.last_name}
                              ${member.employee.first_name}`}
                                      </Typography>
                                    )
                                  }
                                )}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    handleEmployeeGroupEditOpen(employeeGroup)
                                  }
                                >
                                  <DriveFileRenameOutlineIcon />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() => {
                                    if (
                                      confirm(
                                        "お気に入りを削除してもよろしいですか？"
                                      )
                                    ) {
                                      deleteEmployeeGroup(employeeGroup.id)
                                    }
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {employeeGroups.length > DATA_COUNT_PER_PAGE && (
                  <Paging
                    pageCount={Math.ceil(
                      employeeGroups.length / DATA_COUNT_PER_PAGE
                    )}
                    page={pageNumber}
                    handlePaginationChange={handlePaginationChange}
                  />
                )}
              </>
            )}
          </Box>
        </EmployeeGroupModalOverlay>
      )}
      <AlertSuccess
        isMessageOpen={showSuccessMessage}
        alertMessage={successMessage}
        closeMessage={() => setShowSuccessMessage(false)}
      />
    </>
  )
}
