import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  InputLabel,
  Button,
  Tooltip,
  Modal,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material"

import { FloorType } from "../../../../models/company/useBranch/type"
import { useQrCode } from "../../../../models/company/useQrCode"
import { ReservableType } from "../../../../models/company/useQrCode/type"
import { filterReleaseLayouts } from "../../../../utils/filterReleaseLayouts"
import { CompanyQrCodeList } from "../CompanyQrCodeList"
import { CompanyQrCodeMeetingRoomModal } from "../CompanyQrCodeMeetingRoomModal"
import CompanyQrCodePrintComponent from "../CompanyQrCodePrintComponent"
import { CompanyQrCodeSeatModal } from "../CompanyQrCodeSeatModal"

const PrintComponentWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  height: "100%",
}))

const ModalOpenButton = styled(Button)(() => ({
  fontWeight: "bold",
  borderRadius: "6px",
}))

const SeatModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "80%",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(4),
  maxHeight: "90vh",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

const MeetingRoomModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "50%",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(4),
  maxHeight: "90vh",
  overflow: "scroll",
}))

interface Props {
  floors: FloorType[]
}

// QRコード画面：右上、印刷フォーム
export const CompanyQrCodePrint = ({ floors }: Props) => {
  const [reservableType, setReservableType] = useState<ReservableType>("Seat")
  const [seatModalOpen, setSeatModalOpen] = useState<boolean>(false)
  const [layoutId, setLayoutId] = useState<number>(0)
  const [meetingRoomModalOpen, setMeetingRoomModalOpen] =
    useState<boolean>(false)

  const printComponentRef = useRef(null)

  const urlParams = useParams<{ floor_id: string }>()

  const {
    handleSeatSelect,
    handleAllSeatSelected,
    isSeatSelected,
    handleMeetingRoomSelected,
    isMeetingRoomChecked,
    handleMeetingRoomFixed,
    setSelectedSeatList,
    setSelectedMeetingRoomList,
    setFloor,
    floor,
    sortedSeatList,
    sortedMeetingRoomList,
  } = useQrCode()

  const qrCodeList = sortedSeatList.concat(sortedMeetingRoomList)

  // ラジオボタンで座席 / 会議室を変更
  const handleReservableTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReservableType(e.target.value as ReservableType)
  }

  // ラジオボタンの選択状態に応じて開くモーダルを変える
  const handleModalOpen = () => {
    switch (reservableType) {
      case "Seat":
        setSeatModalOpen(true)
        break
      case "MeetingRoom":
        setMeetingRoomModalOpen(true)
    }
  }

  // ブラウザの印刷機能を立ち上げる
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current, // refで指定したコンポーネントが印刷範囲になる
  })

  // 選択中のフロアの選択された座席
  const selectedFloorSeatList = sortedSeatList.filter((seat) => {
    return seat.floorId === Number(urlParams.floor_id)
  })

  // フロア一覧のフロアを切り替えた時にstateを変更(QRコードの文字列に使う)
  useEffect(() => {
    if (Number(urlParams.floor_id) > 0 && floors[0].id > 0) {
      const selectedFloor = floors.filter((floor) => {
        return floor.id === Number(urlParams.floor_id)
      })[0]

      if (selectedFloor) {
        setFloor(selectedFloor)
        setLayoutId(0)
        setSelectedSeatList([])
        setSelectedMeetingRoomList([])
      }
    }
  }, [urlParams, floors])

  // 公開済みと公開予定のレイアウトを選択できるようにする
  const releaseLayouts = filterReleaseLayouts(floor.layouts)

  const handleVersionChange = (e: SelectChangeEvent<number>) => {
    const targetLayoutId = Number(e.target.value)

    // 最初のバージョン選択はconfirm挟まない
    if (layoutId === 0) {
      return setLayoutId(targetLayoutId)
    }

    // 選択したバージョンが選択中のバージョンではない場合
    if (targetLayoutId !== layoutId) {
      if (
        window.confirm(
          "表示されているQRは全てリセットされます。よろしいですか？"
        )
      ) {
        setLayoutId(targetLayoutId)
        setSelectedSeatList([])
        setSelectedMeetingRoomList([])
      }
    }
  }

  return (
    <PrintComponentWrapper>
      <Typography
        color="primary"
        fontWeight="bold"
        fontSize="14px"
        gutterBottom
      >
        印刷
      </Typography>
      <Grid container spacing={3}>
        <Grid item container sm={6} alignItems="center" spacing={1}>
          <Grid item sm={2}>
            <InputLabel htmlFor="last_name">
              <Typography color="primary" fontSize="12px" fontWeight="bold">
                印刷項目
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item sm={5}>
            <FormControl fullWidth>
              <RadioGroup
                name="post_status"
                row
                value={reservableType}
                onChange={handleReservableTypeChange}
              >
                <>
                  <FormControlLabel
                    value={"Seat"}
                    control={<Radio />}
                    label={
                      <Typography variant="body1" sx={{ fontSize: "0.75rem" }}>
                        座席(指定席を含む)
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"MeetingRoom"}
                    control={<Radio />}
                    label={
                      <Typography variant="body1" sx={{ fontSize: "0.75rem" }}>
                        会議室
                      </Typography>
                    }
                  />
                </>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl sx={{ width: 150 }}>
              <InputLabel htmlFor="layout_id">レイアウトver *</InputLabel>
              <Select
                id="layout_id"
                name="layout_id"
                variant="outlined"
                margin="dense"
                label="レイアウトver *"
                value={layoutId}
                fullWidth
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "12px",
                  borderRadius: "6px",
                }}
                onChange={(e) => handleVersionChange(e)}
              >
                <MenuItem disabled={true} key={0} value={0}>
                  ver選択
                </MenuItem>
                {releaseLayouts &&
                  releaseLayouts.map((layout, index) => (
                    <MenuItem key={layout.id} value={layout.id}>
                      {`ver${layout.version}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Modal
          open={seatModalOpen}
          onClose={() => setSeatModalOpen(false)}
          disableAutoFocus
        >
          <SeatModalBox>
            <CompanyQrCodeSeatModal
              setSeatModalOpen={setSeatModalOpen}
              selectedSeatList={selectedFloorSeatList}
              handleSeatSelect={handleSeatSelect}
              handleAllSeatSelected={handleAllSeatSelected}
              isSeatSelected={isSeatSelected}
              layoutId={layoutId}
            />
          </SeatModalBox>
        </Modal>
        <Modal
          open={meetingRoomModalOpen}
          onClose={() => setMeetingRoomModalOpen(false)}
          disableAutoFocus
        >
          <MeetingRoomModalBox>
            <CompanyQrCodeMeetingRoomModal
              layoutId={layoutId}
              setMeetingRoomModalOpen={setMeetingRoomModalOpen}
              handleMeetingRoomSelected={handleMeetingRoomSelected}
              isMeetingRoomChecked={isMeetingRoomChecked}
              handleMeetingRoomFixed={handleMeetingRoomFixed}
            />
          </MeetingRoomModalBox>
        </Modal>
        <Grid
          item
          sm={3.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="primary" fontSize="12px" fontWeight="bold">
            印刷範囲
          </Typography>
          <ModalOpenButton
            variant="outlined"
            color="primary"
            disabled={layoutId === 0}
            onClick={handleModalOpen}
          >
            範囲を選択する
          </ModalOpenButton>
        </Grid>
        <Grid item sm={1} display="flex" alignItems="center">
          <Tooltip title="印刷する">
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/print.svg`}
              alt="印刷マーク"
              style={{
                cursor: "pointer",
              }}
              onClick={handlePrint}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <CompanyQrCodeList qrCodeList={qrCodeList} />
      <Box display="none">
        <CompanyQrCodePrintComponent
          ref={printComponentRef}
          qrCodeList={qrCodeList}
        />
      </Box>
    </PrintComponentWrapper>
  )
}
