import { useCallback, useState } from "react"

import { fetchEmployeeRoleRequest } from "../../../api/public/employeeRoleReqeust"
import { EmployeeRoles } from "./type"

export const useEmployeeRoles = () => {
  const [role, setRole] = useState<EmployeeRoles | undefined>(undefined)

  // ユーザーのログイン状態をチェックする
  const fetchCurrentUserRole = useCallback(async () => {
    try {
      const response = await fetchEmployeeRoleRequest()
      if (response.status === 200 && response.data) {
        setRole(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  return {
    role,
    fetchCurrentUserRole,
  }
}
