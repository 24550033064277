export const FULL_WIDTH_KATAKANA = /^[ァ-ンヴー－]+$/
export const FULL_WIDTH_AND_HALFWIDTH = /^[a-zA-Zぁ-んァ-ンヴゔ一-龥々ー－]+$/
export const FULL_WIDTH_AND_HALFWIDTH_SPACE =
  /^[a-zA-Zぁ-んァ-ンヴゔ一-龥々ー－0-9\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?・･]+$/

export const FULL_WIDTH_AND_HALFWIDTH_AND_NUMBERS =
  /^[\da-zA-Zぁ-んァ-ン一-龥々ー－\s]+$/
export const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/
export const COMPANY_TEL = /^(0[0-9]{1,4}-[0-9]{1,4}-[0-9]{4})$/
export const PHONE_NUMBER = /^0[0-9]{9,10}$/
export const POSTAL_CODE = /^[0-9]{3}-[0-9]{4}$/
export const HALF_WIDTH_NUMBER = /^[0-9]*$/
export const NOTE_NUMBER = /^[\s\S]{0,1000}$/
export const SEAT_NUMBER = /^.{1,1000}$/
export const TEAM_NAME_REGEX =
  /^[\da-zA-Zぁ-んァ-ンヴゔ一-龥々ー－\s０-９Ａ-Ｚａ-ｚ（）・：；。、「」『』【】\-—/\\_@#$%&*+=^{}<>|〜:;’"!?！＠＃＄％＾＆＊＿＋＝＜＞？￥()'~．／｛｝｜～”“゛]+$/
// パスワードは以下が必須
// ・英大文字
// ・英小文字
// ・数字
// ・記号(!-/;:-@[]-`{}-~)
// TODO Cognitoで許可している記号は全て許可したい
export const PASSWORD_REGEX =
  /^(?=.*[!-/;:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,50}$/
export const PIN_CODE = /^[0-9]{6}$/
export const CORRECTDATEREGEX = /^\d{4}\/\d{1,2}\/\d{1,2}$/
export const NUMBERREGEX = /[^0-9]/g
