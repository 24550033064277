import React, { useState, useEffect } from "react"

import { Box } from "@mui/material"

import { MobileTeamSearch } from "../../../components/mobile/teamSearch/Main"
import { MobileTeamSearchHeader } from "../../../components/mobile/teamSearch/MobileTeamSearchHeader"
import { MobileTeamSearchSelectForm } from "../../../components/mobile/teamSearch/MobileTeamSearchSelectForm"
import { Loading } from "../../../components/public/Loading"
import { useTeamSearch } from "../../../models/mobile/useTeamSearch"

export const MobileTeamSearchPage = () => {
  const teamSearchObject = useTeamSearch()
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  useEffect(() => {
    teamSearchObject.fetchEmployeeTeam()
    teamSearchObject.fetchEmployeeRelations()
  }, [])

  return (
    <>
      <Box height="100vh">
        {teamSearchObject.loading && (
          <Loading type="content" loading={teamSearchObject.loading} />
        )}
        <Box sx={{ position: "sticky", top: 0 }}>
          <MobileTeamSearchHeader {...teamSearchObject} />
        </Box>
        <Box
          sx={{
            height: "75%",
            overflow: "auto",
            overscrollBehaviorY: "contain",
          }}
        >
          <MobileTeamSearchSelectForm
            {...teamSearchObject}
            setErrorMessages={setErrorMessages}
          />
          <MobileTeamSearch
            sortByStatusSchedules={teamSearchObject.sortByStatusSchedules}
            errorMessages={errorMessages}
          />
        </Box>
      </Box>
    </>
  )
}
