const ja = {
  InviteExternalGuests: "組織外のユーザーを追加",
  LastName: "姓",
  FirstName: "名",
  CompanyName: "会社名（任意）",
  Email: "メールアドレス",
  EmailRequired: "メールアドレスを入力",
  Cancel: "キャンセル",
  Invite: "追加",
}
export default ja
