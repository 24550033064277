import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useRef } from "react"

import { Box, Typography, Button, styled, Grid } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { SeatType } from "../../../../models/company/useReport/type"
import {
  AreaType,
  MeetingRoomType,
} from "../../../../models/company/useReport/type"
import { heatmapColorThresholds } from "../../../../styles/seatStyle"
import enTranslations from "../../../../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../../../../translations/employeeStatus/employeeStatusHeader/ja"
import { HeatmapLayoutArea } from "../HeatmapLayoutArea"
// import { HeatmapLayoutMeetingRoom } from "../HeatmapLayoutMeetingRoom"
import { HeatmapLayoutSeat } from "../HeatmapLayoutSeat"

const ZoomButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
}))

interface Props {
  layoutImg: string
  seats: SeatType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
}

// レイアウト表示部分
export const HeatmapLayoutMain: React.FC<Props> = ({
  layoutImg,
  seats,
  meetingRooms,
  areas,
}: Props) => {
  // アイコン凡例の表示
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const heatmapColorThresholdsArray = [
    { label: "0~20%", color: heatmapColorThresholds.veryLow },
    { label: "20~40%", color: heatmapColorThresholds.low },
    { label: "40~60%", color: heatmapColorThresholds.middle },
    { label: "60~80%", color: heatmapColorThresholds.high },
    { label: "80~100%", color: heatmapColorThresholds.veryHigh },
  ]

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          padding: theme.spacing(3, 4, 2, 0),
        }}
      >
        <Grid item>
          <Box display="flex" alignItems="center" sx={{ mr: "10px" }}>
            {heatmapColorThresholdsArray.map((threshold) => (
              <React.Fragment key={threshold.label}>
                <Box
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: threshold.color,
                    width: "12px",
                    height: "12px",
                    margin: "0 5px",
                  }}
                />
                <Typography sx={{ fontSize: "14px", mr: "5px" }}>
                  {threshold.label}
                </Typography>
              </React.Fragment>
            ))}
          </Box>
        </Grid>

        <Grid item>
          <ZoomButtonBox color="primary">
            <MinusButton
              onClick={async () => {
                // 最小10%を超えないようにする
                if (
                  layoutImageRef.current?.state?.scale &&
                  layoutImageRef.current?.state?.scale <= 0.1
                ) {
                  return
                } else {
                  layoutImageRef.current?.setTransform(
                    0,
                    0,
                    layoutImageRef.current?.state.scale - 0.1
                  )
                }
              }}
            >
              ー
            </MinusButton>
            <PlusButton
              onClick={async () => {
                // 最大100%を超えないようにする
                if (
                  layoutImageRef.current?.state?.scale &&
                  layoutImageRef.current?.state?.scale >= 1.0
                ) {
                  return
                } else {
                  layoutImageRef.current?.setTransform(
                    0,
                    0,
                    layoutImageRef.current?.state.scale + 0.1
                  )
                }
              }}
            >
              ＋
            </PlusButton>
          </ZoomButtonBox>
        </Grid>
      </Grid>
      <TransformWrapper
        initialScale={0.6}
        disabled={false}
        minScale={0.1}
        maxScale={1.0}
        wheel={{ disabled: false, step: 0.05 }}
        panning={{
          disabled: false,
        }}
        ref={layoutImageRef}
      >
        {!layoutImg && <p>{translations.LayoutNot}</p>}
        <TransformComponent
          wrapperStyle={{ maxWidth: "100%", maxHeight: "600px" }}
        >
          <img id="layout-image" src={layoutImg} />
          {seats.map((seat) => {
            return (
              <Box key={seat.id}>
                <HeatmapLayoutSeat seat={seat} />
              </Box>
            )
          })}
          {/* 会議室の枠を表示する場合はこちらを使用
          {meetingRooms.map((meetingRoom, index) => {
            return (
              <Box key={index}>
                <HeatmapLayoutMeetingRoom meetingRoom={meetingRoom} />
              </Box>
            )
          })} */}
          {areas.map((area) => (
            <Box key={area.id}>
              <HeatmapLayoutArea area={area} />
            </Box>
          ))}
        </TransformComponent>
      </TransformWrapper>
    </>
  )
}
