import { useTheme } from "@mui/material"

export const generateButtonStyle = (
  selectedFlag: boolean,
  defaultStyle?: React.CSSProperties
) => {
  // 選択状態に応じてスタイルを分岐
  const theme = useTheme()

  if (selectedFlag) {
    return {
      ...defaultStyle,
      color: theme.palette.primary.main,
      backgroundColor: "rgba(232, 248, 245, 0.5)", // 緑っぽい色
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "rgba(232, 248, 245, 0.9)",
        color: "#000",
      },
    }
  } else {
    return {
      ...defaultStyle,
      color: "#000",
      "&:hover": {
        backgroundColor: "rgba(227, 227, 227, 0.5)", // グレーっぽい色
        fontWeight: "bold",
      },
    }
  }
}
