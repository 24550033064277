import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { SignageMainOutlineButton } from "../../../components/public/SignageMainOutlineButton"
import { theme } from "../../../config/theme"
import { useSignageReception } from "../../../models/signage/useReception"

const CustomBox = styled(Box)(() => ({
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(10),
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  marginBottom: theme.spacing(3),
}))

const EnglishTextTypography = styled(Typography)(() => ({
  fontSize: "36px",
}))

export const SignageReceptionCompletedPage = () => {
  const navigate = useNavigate()
  const { returnTopPage, resetTimer } = useSignageReception()
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)

  useEffect(() => {
    returnTopPage()
  }, [])

  return (
    <CustomBox>
      <Box mb={12} textAlign="center">
        <CustomTypography variant="h3">
          社内担当者に連絡しました。
        </CustomTypography>
        <CustomTypography variant="h3">少々お待ちください。</CustomTypography>
        <EnglishTextTypography>
          Your arrival has been notified to our internal staff.
        </EnglishTextTypography>
        <EnglishTextTypography>Please wait for a moment.</EnglishTextTypography>
      </Box>
      <SignageMainOutlineButton
        label="TOPに戻る"
        subLabel="Back to TOP"
        handleClick={() => {
          resetTimer()
          navigate(`/signage/branches/${branchId}/reception/home`)
        }}
      />
    </CustomBox>
  )
}
