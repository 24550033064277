import { EmployeeCompanyInfoType } from "../../models/employee/useScheduleStartHour/type"
import { api } from "../config/axiosConfig"

export const fetchEmployeeCompanyInfoRequest =
  async (): Promise<EmployeeCompanyInfoType> => {
    try {
      const response = await api.get("/employee/company_info")
      return response.data
    } catch (error: any) {
      throw new Error(error.response)
    }
  }
