import React from "react"

import { Box, styled, useTheme } from "@mui/material"

import { EmployeeStatusHeader } from "../../../components/employee/layouts/status/EmployeeStatusHeader"
import { EmployeeStatusMain } from "../../../components/employee/layouts/status/EmployeeStatusMain"
import { EmployeeStatusSideBar } from "../../../components/employee/layouts/status/EmployeeStatusSideBar"
import { ContentEmployeePaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useEmployeeStatusList } from "../../../models/employee/useLayout/status"

export type Employees = {
  id: number
  last_name: string
  first_name: string
}[]

export const CustomSideberBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
})

export const CustomTabBox = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px 10px 0 0",
  padding: "5px 0",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 8px gray",
  },
})

export const EmployeeStatusPage = () => {
  const theme = useTheme()
  const {
    branchId,
    setBranchId,
    wholeDayFlag,
    setWholeDayFlag,
    scheduleStatus,
    setScheduleStatus,
    companyRelations,
    loading,
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    formatDate,
    timeIndex,
    setTimeIndex,
    scheduleParDateList,
    userNotExists,
    setUserNotExists,
    userNotExistsMessage,
    filterSchedule,
    filteredSchedules,
    handleEmployeeSearch,
  } = useEmployeeStatusList()

  return (
    <>
      <ContentTemplate>
        <ContentEmployeePaper>
          {loading && <Loading type="content" loading={loading} />}
          <Box display="flex" width="100%" height="calc(100vh - 85px)">
            <Box flexGrow="1" sx={{ paddingRight: theme.spacing(1.875) }}>
              <Box>
                <Box alignItems="center">
                  <Box width="100%">
                    <EmployeeStatusHeader
                      companyRelations={companyRelations}
                      branchId={branchId}
                      setBranchId={setBranchId}
                      scheduleStatus={scheduleStatus}
                      setScheduleStatus={setScheduleStatus}
                      date={date}
                      setDate={setDate}
                      previousDate={previousDate}
                      nextDate={nextDate}
                      previousWeekday={previousWeekday}
                      nextWeekday={nextWeekday}
                      weekdayNumber={weekdayNumber}
                      setWeekdayNumber={setWeekdayNumber}
                      dayOfWeek={dayOfWeek}
                      timeIndex={timeIndex}
                      setTimeIndex={setTimeIndex}
                      formatDate={formatDate}
                      wholeDayFlag={wholeDayFlag}
                      setWholeDayFlag={setWholeDayFlag}
                      loading={loading}
                      filterSchedules={filterSchedule}
                      scheduleParDateList={scheduleParDateList}
                    />
                  </Box>
                </Box>
                <EmployeeStatusMain schedules={filteredSchedules} />
              </Box>
            </Box>
            <Box
              sx={{
                padding: theme.spacing(1, 0, 2),
                width: "270px",
                paddingLeft: theme.spacing(1.875),
                margin: "0",
                display: "flex",
                justifyContent: "center",
                borderLeft: "1px solid #E5E5E5",
                overflow: "auto",
              }}
            >
              <EmployeeStatusSideBar
                handleEmployeeSearch={handleEmployeeSearch}
                userNotExists={userNotExists}
                userNotExistsMessage={userNotExistsMessage}
                setUserNotExists={setUserNotExists}
              />
            </Box>
          </Box>
        </ContentEmployeePaper>
      </ContentTemplate>
    </>
  )
}
