import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Grid, Link, styled, Typography } from "@mui/material"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

export const SignUpAppliedMessage = () => {
  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography align="center">
          ご入力いただいたメールアドレス宛に本登録のご案内をお送りしました。
          <Typography align="center">
            メールのリンクより本登録を完了させてください。
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center">
          <Link
            component={RouterLink}
            to={`/company/signup`}
            variant="caption"
            color="#454545"
          >
            <Typography variant="inherit" display="block">
              仮登録画面に戻る
            </Typography>
          </Link>
        </Typography>
      </Grid>
    </CustomGridContainer>
  )
}
