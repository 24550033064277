import { useState } from "react"

export const useAgreement = () => {
  const [paidChecked, setPaidChecked] = useState<boolean>(false)
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false)

  const handlePaidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaidChecked(event.target.checked)
  }

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyChecked(event.target.checked)
  }

  return {
    privacyChecked,
    paidChecked,
    handlePaidChange,
    handlePrivacyChange,
  }
}
