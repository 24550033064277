const en = {
  Location: "Location",
  Floor: "Floor",
  AllDay: "All day",
  Month: " / ",
  Day: "",
  Available: "Available",
  Reserved: "Reserved",
  CheckedIn: "Checked in",
  NotAvailable: "Not Available",
  Legend: "Map Legend",
  ConfirmReservation: "Confirm",
  Attendee: "Attendee",
  DragDrop: "Drag & Drop",
  SelectedDesk: "Selected Desk",
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  NoTitle: "No title",
  Reserve: "May I reserve a desk?",
  edit: "Back to edit",
  LayoutNot: "Layout does not exist.",
}
export default en
