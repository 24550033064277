import React, { useContext, useEffect, useRef, useState } from "react"
import {
  SubmitHandler,
  UseFormHandleSubmit,
  FieldErrors,
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Box,
  FormHelperText,
  styled,
  Checkbox,
  Button,
  Typography,
  Tooltip,
  Modal,
} from "@mui/material"

import { EmployeeFormType } from "../../../../models/company/useEmployee/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { useIcon } from "../../../../models/public/useIcon"
import { RoleContext } from "../../../../providers/RoleProvider"
import { useOpen } from "../../../../utils/isOpen"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"
import { RequiredFlag } from "../../../public/RequiredFlag"
import { CompanyLayoutTemplate } from "../../uiParts/CompanyLayoutTemplate"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "80%",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2, 0),
}))

const EmployeeIcon = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "50%",
  height: "50px",
  width: "50px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

interface Props {
  control: Control<EmployeeFormType>
  errors: FieldErrors<EmployeeFormType>
  isValid: boolean
  errorMessageOpen: boolean
  errorMessages: string[]
  handleSubmit: UseFormHandleSubmit<EmployeeFormType>
  onCreateSubmit: SubmitHandler<EmployeeFormType>
  onUpdateSubmit: SubmitHandler<EmployeeFormType>
  getValues: UseFormGetValues<EmployeeFormType>
  setValue: UseFormSetValue<EmployeeFormType>
  companyRelations: CompanyRelationsType
  forbidden: boolean
  setIconBlob: React.Dispatch<React.SetStateAction<Blob | undefined>>
  setImageUrl: React.Dispatch<React.SetStateAction<string>>
  imageUrl: string
}

/* TODO
  ・レイアウト機能ができ次第、指定席の設定をできるようにする
*/
export const CompanyEmployeeForm: React.FC<Props> = ({
  control,
  handleSubmit,
  onCreateSubmit,
  onUpdateSubmit,
  errors,
  isValid,
  getValues,
  setValue,
  companyRelations,
  errorMessageOpen,
  errorMessages,
  forbidden,
  setIconBlob,
  setImageUrl,
  imageUrl,
}: Props) => {
  const navigate = useNavigate()
  const urlParams = useParams<{ id: string }>()
  const { isOpen, open, close } = useOpen()
  const useModal = useOpen()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [reservedSeatId, setReservedSeatId] = useState<number>(
    getValues("seat_id")
  )
  const { isTeamLeader } = useContext(RoleContext)

  const [reservedSeatName, setReservedSeatName] = useState<number>(
    getValues("seat_name")
  )

  const { resizeFile } = useIcon()

  const handleSubmitEvent =
    getValues("employee_id") === 0 ? onCreateSubmit : onUpdateSubmit

  // 選択中の拠点情報を取得する
  const branchList = () => {
    return companyRelations.branches.find(
      (branch) => branch.id === getValues("branch_id")
    )
  }

  /* 拠点名を選択する
    ①フロア名を表示するフラグを更新する
    ②拠点名を選択する度に、フロア名を初期化して、選択した拠点に紐付くフロアを選択できるようにする
    */
  const [, setSelectedBranchId] = useState<number>(0)

  const handleBranchSelected = () => {
    setSelectedBranchId(getValues("branch_id"))
    setValue("floor_id", 0)
  }

  // リモートフラグの値が変わった時の処理
  const changeRemoteFlag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("remote_flag", e.target.checked)
    if (e.target.checked) {
      setValue("is_seat", 0)
      close()
    }
  }

  // カメラアイコンをクリックした時にfile fieldを起動する
  const handleCameraIconClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const createObjectURL = (window.URL || window.webkitURL).createObjectURL // 画像オブジェクトを作成
      const image = await resizeFile(e.target.files[0] as Blob) // 画像リサイズ
      if (e.target.files.length != 0) {
        setIconBlob(image as Blob)
        setImageUrl(createObjectURL(image as Blob | MediaSource))
      }
    }
  }

  // 指定席の選択
  const onReservedSeatClick = (seatId: number | null, seatName: number) => {
    if (!seatId) return

    if (reservedSeatId === seatId) {
      setReservedSeatId(0)
      setReservedSeatName(0)
    } else {
      setReservedSeatId(seatId)
      setReservedSeatName(seatName)
    }
  }

  useEffect(() => {
    if (getValues("is_seat") === 1) open()
  }, [])

  return (
    <>
      <Tooltip title="アイコンを登録する" disableInteractive placement="bottom">
        <EmployeeIcon>
          <img
            src={
              imageUrl === ""
                ? `${process.env.PUBLIC_URL}/images/icons/icon-register.svg`
                : imageUrl
            }
            alt="アイコン登録"
            style={{
              borderRadius: "50%",
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
            onClick={forbidden ? () => null : handleCameraIconClick}
          />
        </EmployeeIcon>
      </Tooltip>
      <input
        hidden
        ref={fileInputRef}
        type="file"
        onChange={handleFileUpload}
        accept="image/jpeg,image/png"
      />
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={5.5}>
          <CustomGridContainer container spacing={3} justifyContent="center">
            <CustomGridItem item xs={12} sm={2.5}>
              <InputLabel htmlFor="last_name">
                <Typography color="primary" fontWeight="bold">
                  姓
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={3.5}>
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                    required
                    id="last_name"
                    name="last_name"
                    label="ご氏名（姓）"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={12} sm={2.5}>
              <InputLabel htmlFor="first_name">
                <Typography color="primary" fontWeight="bold">
                  名
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={3.5}>
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    required
                    id="first_name"
                    name="first_name"
                    label="ご氏名（名）"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={2.5}>
              <InputLabel htmlFor="last_name_kana">
                <Typography color="primary" fontWeight="bold">
                  セイ
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={3.5}>
              <Controller
                control={control}
                name="last_name_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.last_name_kana}
                    helperText={errors.last_name_kana?.message}
                    required
                    id="last_name_kana"
                    name="last_name_kana"
                    label="ご氏名（セイ）"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
            <CustomGridItem item xs={12} sm={2.5}>
              <InputLabel htmlFor="first_name_kana">
                <Typography color="primary" fontWeight="bold">
                  メイ
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={4} sm={3.5}>
              <Controller
                control={control}
                name="first_name_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.first_name_kana}
                    helperText={errors.first_name_kana?.message}
                    required
                    id="first_name_kana"
                    name="first_name_kana"
                    label="ご氏名（メイ）"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={4}>
              <InputLabel htmlFor="display_name">
                <Typography color="primary" fontWeight="bold">
                  名前
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={8}>
              <Controller
                control={control}
                name="display_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.display_name}
                    helperText={errors.display_name?.message}
                    id="display_name"
                    name="display_name"
                    label="名前"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={4}>
              <InputLabel htmlFor="email">
                <Typography color="primary" fontWeight="bold">
                  メールアドレス
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={8}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    required
                    id="email"
                    name="email"
                    label="メールアドレス"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={4}>
              <InputLabel htmlFor="employee_code">
                <Typography color="primary" fontWeight="bold">
                  従業員ID
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={8}>
              <Controller
                control={control}
                name="employee_code"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.employee_code}
                    helperText={errors.employee_code?.message}
                    id="employee_code"
                    name="employee_code"
                    label="従業員ID"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={4}>
              <InputLabel htmlFor="phone_number">
                <Typography color="primary" fontWeight="bold">
                  社用電話番号
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={8}>
              <Controller
                control={control}
                name="phone_number"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message}
                    required
                    id="phone_number"
                    name="phone_number"
                    label="例: 080123456789"
                    fullWidth
                    variant="outlined"
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3} alignItems="center">
            <CustomGridItem item xs={1}>
              <Controller
                control={control}
                name="remote_flag"
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    id="remote_flag"
                    onChange={changeRemoteFlag}
                    disabled={forbidden}
                  />
                )}
              />
            </CustomGridItem>
            <Grid item xs={11}>
              <InputLabel htmlFor="remote_flag">
                フルリモート勤務の場合はチェックしてください
              </InputLabel>
            </Grid>
          </CustomGridContainer>
        </Grid>
        <Grid item xs={5.5}>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="branch_id">
                <Typography color="primary" fontWeight="bold">
                  拠点名
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <InputLabel htmlFor="branch_id">拠点名 *</InputLabel>
                <Controller
                  control={control}
                  name="branch_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.branch_id}
                      id="branch_id"
                      name="branch_id"
                      variant="outlined"
                      margin="dense"
                      label="拠点名 *"
                      labelId="拠点名 *"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e)
                        handleBranchSelected()
                      }}
                      disabled={forbidden}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        選択する
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.branch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.branch_id?.message}>
                  {errors.branch_id?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <InputLabel htmlFor="floor_id">
                <Typography color="primary" fontWeight="bold">
                  フロア名
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <InputLabel htmlFor="floor_id">フロア名 *</InputLabel>
                <Controller
                  control={control}
                  name="floor_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.floor_id}
                      id="floor_id"
                      name="floor_id"
                      variant="outlined"
                      margin="dense"
                      label="フロア名 *"
                      fullWidth
                      disabled={forbidden}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        選択する
                      </MenuItem>
                      {branchList()?.floors.map((floor) => (
                        <MenuItem key={floor.id} value={floor.id}>
                          {floor.floor_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.floor_id?.message}>
                  {errors.floor_id?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="is_seat">
                <Typography color="primary" fontWeight="bold">
                  指定席
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item container xs={12} sm={9} alignItems="center" spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="is_seat">指定席</InputLabel>
                  <Controller
                    control={control}
                    name="is_seat"
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="is_seat"
                        variant="outlined"
                        margin="dense"
                        label="指定席"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e)
                          getValues("is_seat") === 1 ? open() : close()
                          if (e.target.value === 1) {
                            setValue("remote_flag", false)
                          } else {
                            setValue("seat_id", 0)
                          }
                        }}
                        disabled={forbidden}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        IconComponent={GreenArrowIcon}
                      >
                        <MenuItem key={0} value={0}>
                          なし
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          あり
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {isOpen &&
                  getValues("branch_id") > 0 &&
                  getValues("floor_id") > 0 && (
                    <>
                      <Button
                        variant="outlined"
                        sx={{
                          fontWeight: "bold",
                        }}
                        onClick={useModal.open}
                      >
                        選択する
                      </Button>
                      {getValues("seat_name") > 0 && (
                        <span>座席番号: {getValues("seat_name")}</span>
                      )}
                    </>
                  )}
              </Grid>
            </Grid>
          </CustomGridContainer>
          <Modal open={useModal.isOpen} onClose={useModal.close}>
            <ModalBox>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    mb: 2,
                  }}
                >
                  <CompanyLayoutTemplate
                    companyId={companyRelations.id}
                    branchId={getValues("branch_id")}
                    floorId={getValues("floor_id")}
                    reservedSeatId={reservedSeatId}
                    onReservedSeatClick={onReservedSeatClick}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <CancelButton
                    label="キャンセル"
                    handleClick={useModal.close}
                  />
                  <ReflectButton
                    label="保存"
                    handleClick={() => {
                      setValue("seat_id", reservedSeatId)
                      setValue("seat_name", reservedSeatName)
                      useModal.close()
                    }}
                  />
                </Grid>
              </Grid>
            </ModalBox>
          </Modal>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="team_id">
                <Typography color="primary" fontWeight="bold">
                  所属名
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <InputLabel htmlFor="team_id">所属名 *</InputLabel>
                <Controller
                  control={control}
                  name="team_id"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.team_id}
                      id="team_id"
                      name="team_id"
                      variant="outlined"
                      margin="dense"
                      label="所属名 *"
                      fullWidth
                      disabled={isTeamLeader ? true : forbidden}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={isTeamLeader ? () => null : GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        選択する
                      </MenuItem>
                      {companyRelations.teams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                          {team.team_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.team_id?.message}>
                  {errors.team_id?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="role">
                <Typography color="primary" fontWeight="bold">
                  権限
                  <RequiredFlag />
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <InputLabel htmlFor="role">権限 *</InputLabel>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={!!errors.role}
                      id="role"
                      variant="outlined"
                      margin="dense"
                      label="権限名 *"
                      fullWidth
                      disabled={isTeamLeader ? true : false}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={isTeamLeader ? () => null : GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={4} value={4}>
                        権限を選択してください
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        法人管理者
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        拠点長
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        所属長
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        一般社員
                      </MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText error={!!errors.role?.message}>
                  {errors.role?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={12}>
              <InputLabel htmlFor="note">
                <Typography color="primary" fontWeight="bold">
                  備考
                </Typography>
              </InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="note"
                    label="最大文字数1000文字"
                    multiline
                    fullWidth
                    rows={10}
                    disabled={forbidden}
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
        </Grid>
      </Grid>
      {errorMessageOpen && ErrorText(errorMessages)}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "450px",
          m: "30px auto",
        }}
      >
        <CancelButton
          label="戻る"
          handleClick={() =>
            navigate(
              Number(urlParams.id) > 0
                ? `/company/employees/${urlParams.id}`
                : "/company/employees"
            )
          }
        />
        <ReflectButton
          label={getValues("employee_id") === 0 ? "登録" : "更新"}
          handleClick={handleSubmit(handleSubmitEvent)}
        />
      </Box>
    </>
  )
}
