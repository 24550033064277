const en = {
  workSchedule: "Work Schedule",
  allDay: "All day",
  AM: "AM",
  PM: "PM",
  SetTime: "Set time",
  Hour: "h",
  minutes: "m",
  year: " / ",
  month: " / ",
  day: "",
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  usedBefore: "Book a desk you've used before",
  used: "used",
  bookDesk: "Book Desk",
  Floor: "Floor",
  yourMood: "Book a desk that suits your mood",
  randomDesk: "Select up to 3 tags or get a random desk",
  spot: "Spots you want to avoid",
  chooseStatus: "Choose non-office status",
  enter: "ENTER",
  status: [
    "Work from home",
    "Out of office",
    "Business Trip",
    "DAY OFF / TIME OFF ",
  ],
  daysAgo: "days ago.",
  yesterday: "yesterday.",
  today: "today",
  Select: "Select",
  Cancel: "Cancel",
  ConfirmReservation: "Confirm Reservation",
  Reservation: "Reservation time",
  ReservationDetail: "Reservation details",
  CanWeProceed:
    "Can we proceed with the reservation using the following information?",
  Reserved: "Reserved desk/room",
  ReservationTime: "Reservation time",
  Edit: "Edit",
  SelectTag: "Select a tag(up to 3 tags)",
  ReservedDeskRoom: "Reserved desk/room",
  ReservationDay: "Reservation time",
  tag: "tag",
  next: "Next",
  Desk: "Desk",

  // エラーメッセージ
  schedulePast:
    "In WORK AGILE, it is not possible to create a schedule in the past.",
  NoSelectedSeats: "There are no available seats for the selected dates.",
  NoUsedSeat:
    "The seat you recently used is already reserved or you have never used the seat.",
  TypingError: "There is a typing error, please try again",
  DesksUsedBefore: "Desks you've used before",
}
export default en
