import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch"

import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { Controller } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import { useSignageAuthAdmin } from "../../../../models/signage/useAuthAdmin"
import { useSignageReception } from "../../../../models/signage/useReception"
import { AlertSuccess } from "../../../public/AlertSuccess"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { Loading } from "../../../public/Loading"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomUploadFileBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  position: "relative",
  height: "100%",
}))

const CancelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  textAlign: "center",
  "&:hover": {
    cursor: "pointer",
    color: "#454545",
  },
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "60%",
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2, 4),
  borderRadius: theme.spacing(1),
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  position: "sticky",
  top: "0",
}))

const CustomMapBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "#F7F7F7",
}))

const ZoomButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  paddingTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

const ZoomButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
}))

export const SignageReceptionMap = () => {
  const navigate = useNavigate()
  const fileFieldRef = useRef<HTMLInputElement>(null)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false)
  const [mapLoading, setMapLoading] = useState<boolean>(true)
  const {
    handleAuthorizeAdministrator,
    isAuthenticated,
    loading,
    errorMessages,
    form,
    setErrorMessages,
  } = useSignageAuthAdmin()
  const { control, formState, handleSubmit, clearErrors, setValue } = form
  const {
    registerReceptionMap,
    receptionMapImage,
    fetchReceptionMap,
    uploadFile,
    registerMapSuccessMessage,
    isRegisterMapSuccess,
    isRegisterMapSuccessMessageShown,
    setIsRegisterMapSuccessMessageShown,
    setRegisterMapSuccessMessage,
  } = useSignageReception()
  const urlParams = useParams<{ branch_id: string }>()

  const handleAuthModalOpen = () => {
    setIsAuthModalOpen(true)
  }

  // モーダルを閉じる時はエラーメッセージ等々が出てこない状態にしておく
  const handleAuthModalClose = () => {
    setErrorMessages([])
    clearErrors()
    setValue("email", "")
    setIsAuthModalOpen(false)
  }

  const handleFileFieldOpen = () => {
    fileFieldRef.current?.click()
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (
        e.target.files &&
        e.target.files.length != 0 &&
        (e.target.files[0].name.split(".").pop() == "jpeg" ||
          e.target.files[0].name.split(".").pop() == "png")
      ) {
        const uploadFileResult = await uploadFile(
          Number(urlParams.branch_id),
          e.target.files[0] as Blob
        )

        if (!uploadFileResult) throw "画像の投稿に失敗しました"

        registerReceptionMap({
          signage_information_id: Number(urlParams.branch_id),
          reception_map_image: uploadFileResult.key,
        })
      }
    } catch (err) {
      alert(
        "画像の投稿に失敗しました。拡張子はjpeg、もしくは、pngの画像を選択してください。"
      )
    }
  }

  // 認証に成功したらモーダルを閉じる
  useEffect(() => {
    if (isAuthenticated) {
      handleAuthModalClose()
      handleFileFieldOpen()
    }
  }, [isAuthenticated])

  // MAP登録に成功したときに動的に最新のMAPを読み込む
  useEffect(() => {
    if (isRegisterMapSuccess) {
      fetchReceptionMap({
        signage_information_id: Number(urlParams.branch_id),
      }).then(() => {
        setIsRegisterMapSuccessMessageShown(true)
        setRegisterMapSuccessMessage("MAP画像の登録に成功しました")
      })
    }
  }, [isRegisterMapSuccess])

  useEffect(() => {
    fetchReceptionMap({
      signage_information_id: Number(urlParams.branch_id),
    }).then(() => {
      setMapLoading(false)
    })
  }, [])

  return (
    <>
      {mapLoading ? (
        <Loading type="fullScreen" />
      ) : (
        <>
          {receptionMapImage === "" ? (
            <CustomUploadFileBox>
              <Box
                style={{
                  textAlign: "center",
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/upload.svg`}
                  onClick={
                    isAuthenticated ? handleFileFieldOpen : handleAuthModalOpen
                  }
                />
                <Typography gutterBottom>
                  MAP画像をアップロードしてください
                </Typography>
                <input
                  hidden
                  type="file"
                  style={{
                    display: "none",
                  }}
                  ref={fileFieldRef}
                  onChange={handleFileUpload}
                  accept="image/jpeg,image/png"
                />
                <CancelTypography
                  variant="h4"
                  onClick={() =>
                    navigate(
                      `/signage/branches/${Number(
                        urlParams.branch_id
                      )}/reception/home`
                    )
                  }
                  mt="2rem"
                >
                  TOPに戻る
                </CancelTypography>
              </Box>
            </CustomUploadFileBox>
          ) : (
            <CustomMapBox>
              <ZoomButtonContainer>
                <Button
                  sx={{ mr: 2, borderRadius: "16px" }}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={
                    isAuthenticated ? handleFileFieldOpen : handleAuthModalOpen
                  }
                >
                  MAP画像の変更
                </Button>
                <input
                  hidden
                  type="file"
                  style={{
                    display: "none",
                  }}
                  ref={fileFieldRef}
                  onChange={handleFileUpload}
                  accept="image/jpeg,image/png"
                />
                <ZoomButtonBox color="primary">
                  <MinusButton
                    onClick={() =>
                      layoutImageRef.current?.setTransform(
                        0,
                        0,
                        layoutImageRef.current?.state.scale - 0.3
                      )
                    }
                  >
                    ー
                  </MinusButton>
                  <PlusButton
                    onClick={() =>
                      layoutImageRef.current?.setTransform(
                        0,
                        0,
                        layoutImageRef.current?.state.scale + 0.3
                      )
                    }
                  >
                    ＋
                  </PlusButton>
                </ZoomButtonBox>
              </ZoomButtonContainer>
              <Box>
                <TransformWrapper
                  initialScale={1}
                  disabled={false}
                  minScale={0.2}
                  wheel={{ disabled: true, step: 0.05 }}
                  panning={{ disabled: false }}
                  ref={layoutImageRef}
                >
                  <TransformComponent
                    wrapperStyle={{
                      height: "80vh",
                    }}
                  >
                    <img src={receptionMapImage} />
                  </TransformComponent>
                </TransformWrapper>
                <CancelTypography
                  variant="h4"
                  onClick={() =>
                    navigate(
                      `/signage/branches/${Number(
                        urlParams.branch_id
                      )}/reception/home`
                    )
                  }
                  mt="2rem"
                >
                  TOPに戻る
                </CancelTypography>
              </Box>
            </CustomMapBox>
          )}
          {isRegisterMapSuccessMessageShown && (
            <AlertSuccess
              isMessageOpen={isRegisterMapSuccessMessageShown}
              alertMessage={registerMapSuccessMessage}
              closeMessage={() => setIsRegisterMapSuccessMessageShown(false)}
            />
          )}
          {isAuthModalOpen && (
            <Modal open={isAuthModalOpen} onClose={handleAuthModalClose}>
              <ModalBox>
                <Box
                  sx={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CustomTypography variant="h5" gutterBottom>
                    管理者の情報を入力
                  </CustomTypography>
                  <Typography variant="subtitle1">
                    管理者のみ行える操作です。管理者のメールアドレスを入力してください。
                  </Typography>
                  <Grid
                    container
                    direction="column"
                    spacing={3}
                    pt="1.5rem"
                    alignItems="space-around"
                    height="100%"
                  >
                    <Grid item display="flex" container sm={1}>
                      <Grid item xs={4} display="flex" alignItems="center">
                        <CustomTypography variant="h6" color="primary">
                          メールアドレス
                        </CustomTypography>
                      </Grid>
                      <Grid item xs={8}>
                        <Controller
                          control={control}
                          name="email"
                          render={({ field }) => (
                            <TextField
                              label="メールアドレス"
                              {...field}
                              error={!!formState.errors.email}
                              helperText={formState.errors.email?.message}
                              id="email"
                              name="email"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item pb="1rem" container direction="column">
                      <Grid item sm={0.25}>
                        <Box>
                          {errorMessages.length > 0 && ErrorText(errorMessages)}
                        </Box>
                      </Grid>
                      <Grid item container justifyContent="center" sm={4}>
                        {loading ? (
                          <CircularProgress size={32} />
                        ) : (
                          <>
                            <Grid
                              item
                              sm={4}
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <CancelButton
                                label="キャンセル"
                                handleClick={handleAuthModalClose}
                              />
                            </Grid>
                            <Grid
                              item
                              sm={4}
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <ReflectButton
                                label="送信"
                                disabled={!formState.isValid}
                                handleClick={handleSubmit(
                                  handleAuthorizeAdministrator
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </ModalBox>
            </Modal>
          )}
        </>
      )}
    </>
  )
}
