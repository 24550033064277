import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"

import React, { useEffect, useState, useContext } from "react"
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form"
import { useNavigate, useLocation } from "react-router-dom"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Box,
  Button,
  Typography,
  IconButton,
  styled,
  Modal,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  EmployeeGroupRequestType,
  EmployeeGroupsType,
} from "../../../../../models/employee/useEmployeeGroup/type"
import { SearchedEmployeeResult } from "../../../../../models/employee/useSchedule/days/type"
import { EmployeesType } from "../../../../../models/employee/useSchedule/type"
import { EmployeeScheduleRequestType } from "../../../../../models/employee/useSchedule/type"
import { RoleContext } from "../../../../../providers/RoleProvider"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleHeaderBar/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleHeaderBar/ja"
import {
  DAY_CALENDAR_PATHNAME,
  WEEK_CALENDAR_PATHNAME,
  MONTH_CALENDAR_PATHNAME,
} from "../../../../../utils/const"
import { generateButtonStyle } from "../../../../../utils/generateButtonStyle"
import { useOpen } from "../../../../../utils/isOpen"
import { AlertSuccess } from "../../../../public/AlertSuccess"
import { EmployeeGroupFormModal } from "../../common/EmployeeGroupFormModal"
import { EmployeeGroupModal } from "../../common/EmployeeGroupModal"
import { MeetingRoomCalendar } from "../../meetingRooms/Main"

const CustomTypography = styled(Typography)({
  letterSpacing: "0.6px",
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "17px",
  margin: "0 auto",
})

const CustomBoldTypography = styled(Typography)({
  letterSpacing: "0px",
  fontWeight: "bold",
  fontSize: "12px",
  fontFamily: "Noto Sans",
})

const MeetingRoomScheduleModalOverLay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  borderRadius: theme.spacing(0.5),
  boxShadow: theme.spacing(3),
  padding: theme.spacing(6, 4),
}))

const defaultButtonStyle = {
  borderRadius: theme.spacing(4),
}

const EmployeeGroupFormModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(70),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
}))

interface Props {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  dayOfWeek: string[]
  startDate: Date
  endDate: Date
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  formatDate: (date: Date) => string
  displayOnlyReserved: () => void
  displayOnlyNonReserved: () => void
  displayAll: () => void
  selectedEmployees: SearchedEmployeeResult
  handleDayScheduleFetch: (data: EmployeeScheduleRequestType) => Promise<void>
  employees: EmployeesType
  control: Control<EmployeeGroupRequestType>
  errors: FieldErrors<EmployeeGroupRequestType>
  trigger: UseFormTrigger<EmployeeGroupRequestType>
  clearErrors: UseFormClearErrors<EmployeeGroupRequestType>
  isValid: boolean
  handleSubmit: UseFormHandleSubmit<EmployeeGroupRequestType>
  onCreateSubmit: SubmitHandler<EmployeeGroupRequestType>
  getValues: UseFormGetValues<EmployeeGroupRequestType>
  setValue: UseFormSetValue<EmployeeGroupRequestType>
  errorMessages: string[]
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  showSuccessMessage: boolean
  setShowSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
  successMessage: string
  displayReserved: boolean
  displayNonReserved: boolean
  isCreateEmployeeGroupModalOpen: boolean
  setIsCreateEmployeeGroupModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >
  employeeGroups: EmployeeGroupsType
  fetchEmployeeGroups: () => Promise<void>
  deleteEmployeeGroup: (employeeGroupId: number) => Promise<void>
  onUpdateSubmit: SubmitHandler<EmployeeGroupRequestType>
  editFormOpen: boolean
  setEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmployeeScheduleDayHeader = ({
  date,
  setDate,
  startDate,
  endDate,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  weekdayNumber,
  setWeekdayNumber,
  formatDate,
  setStartDate,
  setEndDate,
  displayOnlyReserved,
  displayOnlyNonReserved,
  displayAll,
  selectedEmployees,
  handleDayScheduleFetch,
  employees,
  control,
  errors,
  trigger,
  clearErrors,
  isValid,
  handleSubmit,
  onCreateSubmit,
  getValues,
  setValue,
  errorMessages,
  setErrorMessages,
  showSuccessMessage,
  setShowSuccessMessage,
  successMessage,
  displayReserved,
  displayNonReserved,
  isCreateEmployeeGroupModalOpen,
  setIsCreateEmployeeGroupModalOpen,
  employeeGroups,
  fetchEmployeeGroups,
  deleteEmployeeGroup,
  onUpdateSubmit,
  editFormOpen,
  setEditFormOpen,
}: Props) => {
  const navigate = useNavigate()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)
  const location = useLocation()
  const { handleRoleChange } = useContext(RoleContext)

  useEffect(() => {
    const employeeIds = selectedEmployees.map((employee) => {
      return employee.id
    })
    if (date < startDate || date > endDate) {
      const newStartDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1
      )
      const newEndDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      )
      handleDayScheduleFetch({
        start_date: formatDate(newStartDate),
        end_date: formatDate(newEndDate),
        employee_ids: employeeIds,
      })
      setStartDate(newStartDate)
      setEndDate(newEndDate)
    }
  }, [date])

  const [meetingRoomModalOpen, setMeetingRoomModalOpen] =
    useState<boolean>(false)
  const [isEmployeeGroupModalOpen, setIsEmployeeGroupModalOpen] =
    useState<boolean>(false)
  const [employeeIds, setEmployeeIds] = useState<number[]>([])
  const scheduleCalendar = useOpen()

  // お気に入りグループ作成モーダルを開く
  const handleCreateEmployeeGroupModalOpen = () => {
    clearErrors() // closeした時にエラーメッセージが表示されているので打ち消す
    setIsCreateEmployeeGroupModalOpen(true)
  }

  // お気に入りグループ作成モーダルを閉じる
  const handleCreateEmployeeGroupModalClose = () => {
    if (confirm("編集中の内容は失われますがよろしいですか？")) {
      setValue("group_name", "")
      setValue("group_name_kana", "")
      setValue("employee_ids", [])
      trigger() // 先にバリデーションをトリガーしてボタンを押下不可にする
      setErrorMessages([])
      setIsCreateEmployeeGroupModalOpen(false)
    }
  }

  // お気に入りグループモーダルを開く
  const handleEmployeeGroupModalOpen = () => {
    setEditFormOpen(false)
    setIsEmployeeGroupModalOpen(true)
  }

  // お気に入りグループモーダルを閉じる
  const handleEmployeeGroupModalClose = () => {
    if (editFormOpen) {
      if (!confirm("編集中の内容は失われますがよろしいですか？")) {
        return // 編集中にモーダル外部をクリックした場合は確認のダイアログを出した上でモーダル自体を閉じる
      }
    }
    setIsEmployeeGroupModalOpen(false)
  }

  // 会議室一覧をクリックした時に新規ウィンドウで会議室の予定表を開く
  const handleMeetingRoomModalOpen = () => {
    localStorage.setItem("meetingRoomModalOpen", "true")
    window.open("meetingroom", "new-window", "width=1280,height=720,noopener")
  }

  // 会議室一覧モーダルを閉じる
  const handleMeetingRoomModalClose = () => {
    setMeetingRoomModalOpen(false)
  }

  // handleMeetingRoomModalOpenで設定したlocalStorageの値を読み取ってモーダルを開く
  useEffect(() => {
    const storageObject = localStorage.getItem("meetingRoomModalOpen")
    if (storageObject) {
      setMeetingRoomModalOpen(true)
      localStorage.removeItem("meetingRoomModalOpen")
    }
  }, [])

  // 予定が表示されている従業員のうち、自分を対象から外す
  const exceptMeEmployees = employees.slice(1)

  // dateにカレンダーで選択された日付を設定する。
  const handleDateChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setDate(new Date(newValue))
      window.localStorage.setItem(
        "targetDay",
        new Date(newValue).getTime().toString()
      )
    }
  }

  // weekdayにカレンダーで選択された日付の曜日を設定する。
  const handleWeekdayChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setWeekdayNumber(newValue.getDay())
    }
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // ページが遷移、権限変更があれば権限取得
  useEffect(() => {
    handleRoleChange()
  }, [location.pathname])

  return (
    <>
      <Box
        sx={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              previousDate()
              previousWeekday()
            }}
          >
            <ChevronLeftIcon color="primary" />
          </IconButton>
          <Box>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={language === "ja" ? ja : enUS}
            >
              <DesktopDatePicker
                open={scheduleCalendar.isOpen}
                onChange={(event) => {
                  handleDateChange(event)
                  handleWeekdayChange(event)
                  scheduleCalendar.close()
                }}
                maxDate={schedulableMaxDate}
                onClose={scheduleCalendar.close}
                slots={{
                  textField: () => (
                    <CustomTypography
                      variant="button"
                      onClick={scheduleCalendar.open}
                      sx={{ cursor: "pointer" }}
                    >
                      {`${month}${translations.month}${day}${translations.day}${translations.weekdays[weekdayNumber]}`}
                    </CustomTypography>
                  ),
                  previousIconButton: IconButton,
                  nextIconButton: IconButton,
                }}
                slotProps={{
                  popper: {
                    sx: { marginTop: "250px", marginLeft: "200px" },
                  },
                  textField: {
                    onClick: scheduleCalendar.open,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <IconButton
            disabled={schedulableMaxDate <= date}
            onClick={() => {
              nextDate()
              nextWeekday()
            }}
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </Box>
        <Box display="flex" marginLeft="auto">
          <Box display="flex" alignItems="center">
            <Box>
              <CustomBoldTypography color="primary">
                {translations.Schedule} :
              </CustomBoldTypography>
            </Box>
            <Button
              sx={{
                ...generateButtonStyle(
                  !displayReserved && !displayNonReserved,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayAll}
            >
              {translations.All}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(displayReserved, defaultButtonStyle),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayOnlyReserved}
            >
              {translations.Desks}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(displayNonReserved, defaultButtonStyle),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayOnlyNonReserved}
            >
              {translations.Events}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" marginLeft="1rem">
            <Box>
              <CustomBoldTypography color="primary">
                {translations.Calendar} :
              </CustomBoldTypography>
            </Box>
            <Button
              sx={{
                ...generateButtonStyle(
                  window.location.pathname === MONTH_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={() =>
                navigate(MONTH_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Month}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(
                  window.location.pathname === WEEK_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={() =>
                navigate(WEEK_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                    navigateFrom: window.location.pathname,
                  },
                })
              }
            >
              {translations.Week}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(
                  window.location.pathname === DAY_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={() =>
                navigate(DAY_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Day}
            </Button>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => {
                  handleCreateEmployeeGroupModalOpen()
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/favorite.svg`}
                  style={{
                    height: "15px",
                  }}
                />
              </IconButton>
              <Typography variant="h5" color="#dcdcdc">
                |
              </Typography>
              <Modal
                open={isCreateEmployeeGroupModalOpen}
                onClose={handleCreateEmployeeGroupModalClose}
                disableAutoFocus
              >
                <EmployeeGroupFormModalOverlay>
                  <EmployeeGroupFormModal
                    formType="create"
                    employeeIds={employeeIds}
                    handleClose={handleCreateEmployeeGroupModalClose}
                    setEmployeeIds={setEmployeeIds}
                    employees={exceptMeEmployees}
                    control={control}
                    errors={errors}
                    isValid={isValid}
                    handleSubmit={handleSubmit}
                    onSubmit={onCreateSubmit}
                    getValues={getValues}
                    setValue={setValue}
                    errorMessages={errorMessages}
                  />
                </EmployeeGroupFormModalOverlay>
              </Modal>
              {employeeGroups.length > 0 && (
                <Box display="flex">
                  <IconButton
                    onClick={() => {
                      handleEmployeeGroupModalOpen()
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/list.svg`}
                    />
                  </IconButton>
                  <Typography variant="h5" color="#dcdcdc">
                    |
                  </Typography>
                  <Modal
                    open={isEmployeeGroupModalOpen}
                    onClose={handleEmployeeGroupModalClose}
                  >
                    <Box>
                      <EmployeeGroupModal
                        employeeGroups={employeeGroups}
                        fetchEmployeeGroups={fetchEmployeeGroups}
                        deleteEmployeeGroup={deleteEmployeeGroup}
                        successMessage={successMessage}
                        setShowSuccessMessage={setShowSuccessMessage}
                        showSuccessMessage={showSuccessMessage}
                        getValues={getValues}
                        setValue={setValue}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        clearErrors={clearErrors}
                        isValid={isValid}
                        handleSubmit={handleSubmit}
                        onUpdateSubmit={onUpdateSubmit}
                        editFormOpen={editFormOpen}
                        setEditFormOpen={setEditFormOpen}
                      />
                    </Box>
                  </Modal>
                </Box>
              )}
              <IconButton onClick={handleMeetingRoomModalOpen}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/conference.svg`}
                  style={{
                    height: "15px",
                  }}
                />
              </IconButton>
              <Modal
                open={meetingRoomModalOpen}
                onClose={handleMeetingRoomModalClose}
                disableAutoFocus
              >
                <MeetingRoomScheduleModalOverLay>
                  <MeetingRoomCalendar />
                </MeetingRoomScheduleModalOverLay>
              </Modal>
              <AlertSuccess
                isMessageOpen={showSuccessMessage}
                closeMessage={() => setShowSuccessMessage(false)}
                alertMessage={successMessage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
