import React, { useEffect, useState } from "react"

import { Box, styled } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useScheduleShowData } from "../../../../models/employee/useSchedule/show"
import { useScheduleNewMessage } from "../../../../models/employee/useSchedule/useScheduleNewMessage"
import { ScheduleNewMessage } from "../../../../models/employee/useSchedule/useScheduleNewMessage/type"
import enTranslations from "../../../../translations/mobileHome/mobileHomeProfileIcon/en"
import jaTranslations from "../../../../translations/mobileHome/mobileHomeProfileIcon/ja"
import { dateStatus } from "../../../../utils/date"
import { useOpen } from "../../../../utils/isOpen"
import { MobileScheduleDayShowModal } from "../../schedule/MobileScheduleDayShowModal"

const MessageTableBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "400px",
}))

const MessagesTable = styled("table")({
  width: "100%",
  borderCollapse: "separate",
  borderSpacing: 0,
  backgroundColor: "white",
  position: "relative",
})

const Tablebody = styled("tbody")({
  "&:hover": {
    backgroundColor: "#F7F7F7",
    cursor: "pointer",
  },
})

const HeaderTr = styled("tr")({
  textAlign: "left",
  backgroundColor: "white",
  position: "sticky",
  top: "0",
  left: "0",
})

const HeaderTh = styled("th")({
  borderBottom: "1px solid lightgray",
  backgroundColor: "white",
  padding: "0 0.25rem",
})

const ContentTr = styled("tr")({
  width: "100%",
  verticalAlign: "top",
})

const ContentTd = styled("td")({
  padding: "0.5rem 0.25rem",
  fontSize: "14px",
  paddingBottom: "0",
})

const DateTimeTd = styled("td")({
  borderBottom: "1px solid lightgray",
  padding: "0.25rem",
  paddingTop: "0",
})

export const EmployeeNoticeList = () => {
  const { scheduleNewMessage, fetchNewScheduleMessage } =
    useScheduleNewMessage()
  const [newMessageDate, setNewMessageDate] = useState<Date>(new Date())

  useEffect(() => {
    fetchNewScheduleMessage()
  }, [])

  const dateObject = dateStatus()
  const { isOpen, open, close } = useOpen()
  const { scheduleShowData, fetchScheduleShowData, onApproveSubmit } =
    useScheduleShowData()

  const handleScheduleShowDataClick = (schedule: ScheduleNewMessage) => {
    open()
    fetchScheduleShowData(
      [schedule.schedule_information_id],
      new Date(schedule.start_time),
      [schedule.schedule_id],
      [schedule]
    )
  }

  // 他の人から招待された予定のみ表示
  const onlyInvitationMessage = scheduleNewMessage.filter((a) => {
    if (a.schedule_creator_id != a.userable_id) {
      return true
    }
  })
  // 言語切り替え
  const { language, setLanguage } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <Box
        sx={{
          gridArea: "Messages",
          height: "80%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            margin: "1rem",
            minHeight: "0",
            overflowY: "auto",
          }}
        >
          <MessageTableBox>
            <MessagesTable>
              <HeaderTr sx={{ width: "100%" }}>
                <HeaderTh sx={{ width: "60%" }}>
                  {translations.Subject}
                </HeaderTh>
                <HeaderTh>{translations.From}</HeaderTh>
              </HeaderTr>
              {onlyInvitationMessage.map((newMessage, i) => {
                return (
                  <Tablebody
                    key={"message_" + i}
                    onClick={() => handleScheduleShowDataClick(newMessage)}
                  >
                    <ContentTr sx={{ width: "100%" }}>
                      <ContentTd sx={{ width: "60%" }}>
                        <Box>
                          {newMessage.schedule_title}
                          <br />
                        </Box>
                      </ContentTd>
                      <ContentTd>
                        <Box>{newMessage.schedule_creator}</Box>
                      </ContentTd>
                    </ContentTr>
                    <tr>
                      <DateTimeTd colSpan={4}>
                        <Box sx={{ fontSize: "0.75rem", color: "gray" }}>
                          {newMessage.start_date} 〜 {newMessage.end_date}
                        </Box>
                      </DateTimeTd>
                    </tr>
                  </Tablebody>
                )
              })}
            </MessagesTable>
            <MobileScheduleDayShowModal
              date={newMessageDate}
              isOpen={isOpen}
              close={close}
              scheduleShowData={scheduleShowData}
              onApproveSubmit={onApproveSubmit}
            />
          </MessageTableBox>
        </Box>
      </Box>
    </>
  )
}
