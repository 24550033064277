import QRCode from "qrcode.react"

import React, { useState } from "react"

import { Box, Typography, Modal, styled } from "@mui/material"

import { theme } from "../../../../config/theme"
import { ReflectButton } from "../../../public/ReflectButton"
import { WideCancelButton } from "../../../public/WideCancelButton"
import { CountDownTimer } from "../CountDownTimer"
import { ExtendMeetingModal } from "../ExtendMeetingModal"

const CustomModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "500px",
  height: "300px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
}))

interface Props {
  endTime: Date
  meetingRoomCheckOutUrl: string
}

export const SignageMeetingRoomCheckOut: React.FC<Props> = ({
  endTime,
  meetingRoomCheckOutUrl,
}) => {
  const [isExtendModalOpen, setIsExtendModalOpen] = useState<boolean>(false)
  const [isExtendButtonVisible, setIsExtendButtonVisible] =
    useState<boolean>(true)

  const extendModalOpen = () => {
    setIsExtendModalOpen(true)
  }

  const extendModalClose = () => {
    setIsExtendModalOpen(false)
  }

  return (
    <>
      <CountDownTimer
        endTime={endTime}
        setIsExtendButtonVisible={setIsExtendButtonVisible}
      />
      {isExtendButtonVisible && (
        <>
          <WideCancelButton
            label="会議を延長する"
            handleClick={extendModalOpen}
          />
          <Modal open={isExtendModalOpen} onClose={extendModalClose}>
            <CustomModalOverlay>
              <ExtendMeetingModal extendModalClose={extendModalClose} />
            </CustomModalOverlay>
          </Modal>
        </>
      )}
      <Box mt={1.5} mb={1}>
        <Typography variant="h6">チェックアウトはこちら</Typography>
      </Box>
      <Box mt={1} mb={2}>
        <QRCode value={meetingRoomCheckOutUrl} />
      </Box>
      <Box my={1}>
        <Typography variant="h6">チェックアウト完了後はこちら</Typography>
      </Box>
      <Box mb={3}>
        <ReflectButton
          label="完了"
          handleClick={() => window.location.reload()}
        />
      </Box>
    </>
  )
}
