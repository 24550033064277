const ja = {
  createFavorite: "お気に入りを作成",
  TeamName: "グループ名",
  TeamNameKana: "グループ名カナ",
  Cancel: "キャンセル",
  Create: "登録",
  FavoriteTeamList: "お気に入り一覧",
  Edit: "お気に入りを編集",
  likeChange: "登録内容を変更しますか？",
  Change: "変更",
  AddMember: "従業員を追加する",
  Members: "メンバー",
  LikeAddTeam: "お気に入りを登録しますか?",
}
export default ja
