import { useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  fetchCompanyWeeklyOffDayRequest,
  createCompanyWeeklyOffDayRequest,
  updateCompanyWeeklyOffDayRequest,
} from "../../../api/company/companyWeeklyOffDayRequest"
import { CompanyWeeklyOffDayType } from "./type"

export const useCompanyWeeklyOffDay = () => {
  const navigate = useNavigate()
  const [companyWeeklyOffDay, setCompanyWeeklyOffDay] =
    useState<CompanyWeeklyOffDayType>({
      id: 0,
      sunday: true,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: true,
    })

  // 曜日の順番を定義（APIから取得した曜日の順番はランダムなため）
  const weekDayInOrder = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ] as const

  const fetchCompanyWeeklyOffDay = async () => {
    try {
      const response = await fetchCompanyWeeklyOffDayRequest()
      if (response.data && response.status === 200) {
        // レスポンスの曜日を順番に並べ替える
        const sortedWeekDay = {
          id: response.data.id,
        } as CompanyWeeklyOffDayType
        weekDayInOrder.forEach((day) => {
          response.data && (sortedWeekDay[day] = response.data[day])
        })

        setCompanyWeeklyOffDay(sortedWeekDay)
      }
    } catch (e) {
      console.log("error")
    }
  }

  const createCompanyWeeklyOffDay = async () => {
    try {
      const response = await createCompanyWeeklyOffDayRequest({
        id: 0,
        sunday: companyWeeklyOffDay.sunday,
        monday: companyWeeklyOffDay.monday,
        tuesday: companyWeeklyOffDay.tuesday,
        wednesday: companyWeeklyOffDay.wednesday,
        thursday: companyWeeklyOffDay.thursday,
        friday: companyWeeklyOffDay.friday,
        saturday: companyWeeklyOffDay.saturday,
      })
      if (response.data && response.status === 204) {
        localStorage.setItem("alertContent", "公休日を変更しました")
        navigate(`/company/settings/environment`)
        window.location.reload()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const updateCompanyWeeklyOffDay = async () => {
    try {
      const response = await updateCompanyWeeklyOffDayRequest({
        id: companyWeeklyOffDay.id,
        sunday: companyWeeklyOffDay.sunday,
        monday: companyWeeklyOffDay.monday,
        tuesday: companyWeeklyOffDay.tuesday,
        wednesday: companyWeeklyOffDay.wednesday,
        thursday: companyWeeklyOffDay.thursday,
        friday: companyWeeklyOffDay.friday,
        saturday: companyWeeklyOffDay.saturday,
      })
      if (response.data && response.status === 204) {
        localStorage.setItem("alertContent", "公休日を変更しました")
        navigate(`/company/settings/environment`)
        window.location.reload()
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    companyWeeklyOffDay,
    setCompanyWeeklyOffDay,
    fetchCompanyWeeklyOffDay,
    createCompanyWeeklyOffDay,
    updateCompanyWeeklyOffDay,
  }
}
