import React, { memo, ReactNode } from "react"

import { Box, Paper, styled, Typography } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/contentPaper/en"
import jaTranslations from "../../../translations/contentPaper/ja"
import { CONTENT_PAPER_MIN_HEIGHT } from "../../../utils/const"

const CustomCompanyMain = styled(Paper)(({ theme }) => ({
  minHeight: CONTENT_PAPER_MIN_HEIGHT,
  margin: theme.spacing(1.875, 2.5, 0, 1.875),
  padding: theme.spacing(1.875),
  borderRadius: theme.spacing(1.25),
  border: "none",
  boxShadow: "0px 1px 4px #15223214",
}))

const CustomEmployeeMain = styled(Paper)(({ theme }) => ({
  minHeight: CONTENT_PAPER_MIN_HEIGHT,
  margin: theme.spacing(1.875, 2.5, 0, 1.875),
  padding: theme.spacing(1.875),
  borderRadius: theme.spacing(1.25),
  border: "none",
  boxShadow: "0px 1px 4px #15223214",
}))

const CustomCompanyFooter = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1.875),
  marginRight: theme.spacing(2.5),
  color: "#707070",
  fontWeight: "bold",
  height: theme.spacing(2.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}))

const CustomEmployeeFooter = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
  marginRight: theme.spacing(2.5),
  color: "#707070",
  fontWeight: "bold",
  height: theme.spacing(2.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}))

export const ContentCompanyPaper = memo(
  ({ children }: { children: ReactNode }) => {
    const { language } = useLanguage()
    const translations = language === "en" ? enTranslations : jaTranslations

    return (
      <>
        <CustomCompanyMain variant="outlined">{children}</CustomCompanyMain>
        <CustomCompanyFooter>
          <Typography
            sx={{
              fontSize: "10px",
              fontFamily: "Noto Sans",
            }}
            variant="overline"
          >
            <a
              href="https://workagile.jp/index.php/terms/"
              style={{
                color: "#707070",
                textDecoration: "none",
                marginRight: "10px",
              }}
              target="_blank"
              rel="noreferrer"
            >
              {translations.TermsService}{" "}
            </a>
            <a
              href="https://workagile.jp/index.php/privacy/"
              style={{ color: "#707070", textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              {translations.PrivacyPolicy}
            </a>
          </Typography>
        </CustomCompanyFooter>
      </>
    )
  }
)

export const ContentEmployeePaper = memo(
  ({ children }: { children: ReactNode }) => {
    const { language } = useLanguage()
    const translations = language === "en" ? enTranslations : jaTranslations

    return (
      <>
        <CustomEmployeeMain variant="outlined">{children}</CustomEmployeeMain>
        <CustomEmployeeFooter>
          <Typography
            sx={{
              fontSize: "10px",
              fontFamily: "Noto Sans",
            }}
            variant="overline"
          >
            <a
              href="https://workagile.jp/index.php/terms/"
              style={{
                color: "#707070",
                textDecoration: "none",
                marginRight: "10px",
              }}
              target="_blank"
              rel="noreferrer"
            >
              {translations.TermsService}
            </a>
            <a
              href="https://workagile.jp/index.php/privacy/"
              style={{ color: "#707070", textDecoration: "none" }}
              target="_blank"
              rel="noreferrer"
            >
              {translations.PrivacyPolicy}
            </a>
          </Typography>
        </CustomEmployeeFooter>
      </>
    )
  }
)
