import React, { useEffect } from "react"

import { Grid, styled } from "@mui/material"

import { CompanyQrCodeBranchDetail } from "../../../components/company/qrcode/CompanyQrCodeBranchDetail"
import { CompanyQrCodeFloorList } from "../../../components/company/qrcode/CompanyQrCodeFloorList"
import { CompanyQrCodePrint } from "../../../components/company/qrcode/CompanyQrCodePrint"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useBranch } from "../../../models/company/useBranch"

// 区切り線
const Separator = styled("hr")(({ theme }) => ({
  height: "650px",
  opacity: "0.3",
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(3),
}))

export const CompanyQrCodeCreatePage = () => {
  const { fetchBranchDetail, branchDetail, loading } = useBranch()

  useEffect(() => {
    fetchBranchDetail()
  }, [])

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <ContentTemplate>
        <ContentCompanyPaper>
          <Grid container>
            <Grid item sm={3}>
              <Grid container direction="column">
                <Grid item sm={6}>
                  <CompanyQrCodeBranchDetail branch={branchDetail} />
                </Grid>
                <Grid item sm={6}>
                  <CompanyQrCodeFloorList
                    floors={branchDetail.floors}
                    branchId={branchDetail.id}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Separator />
            <Grid item sm={8.5}>
              <CompanyQrCodePrint floors={branchDetail.floors} />
            </Grid>
          </Grid>
        </ContentCompanyPaper>
      </ContentTemplate>
    </>
  )
}
