import QRCode from "qrcode.react"

import React, { useMemo } from "react"
import { Controller } from "react-hook-form"

import {
  Paper,
  Box,
  Button,
  Grid,
  InputLabel,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import { useMFA } from "../../../models/public/useAuth/MFA"
import enTranslations from "../../../translations/employeeSettings/employeeSettingsMain/en"
import jaTranslations from "../../../translations/employeeSettings/employeeSettingsMain/ja"
import { AlertContent } from "../AlertContent"
import { ContentTemplate } from "../ContentTemplate"
import { ErrorText } from "../ErrorText"

const CustomContentEmployeePaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(1.875),
  padding: theme.spacing(2.5),
  borderRadius: theme.spacing(1.25),
  border: "none",
  boxShadow: "0px 1px 4px #15223214",
}))

const CustomTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.6px",
})

const CustomBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
})

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#22BA9D",
  color: "#FFFFFF",
  fontFamily: "Noto Sans",
  fontSize: "14px",
  boxShadow: "0px 3px 6px #00000029",
  padding: theme.spacing(1, 6),
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  width: theme.spacing(40),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(40),
}))

export const SetupMFA = () => {
  const {
    control,
    errors,
    isValid,
    onMFASetup,
    handleSubmit,
    fetchQRCodeUrl,
    qrCodeUrl,
    currentMFASettings,
    fetchCurrentUserMFASettings,
    errorMessages,
    open,
  } = useMFA()

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // コンポーネント読み込み時にユーザーがMFA設定済みかどうかチェックする
  useMemo(() => {
    fetchCurrentUserMFASettings()
  }, [])

  return (
    <>
      <CustomTypography>{translations.twoFactorAuth}</CustomTypography>
      <ContentTemplate>
        <CustomContentEmployeePaper>
          {currentMFASettings === "NOMFA" ? (
            <>
              {qrCodeUrl === "" ? (
                <CustomBox sx={{ padding: "20% 0" }}>
                  <CustomGridItem item>
                    <CustomButton
                      onClick={fetchQRCodeUrl}
                      variant="contained"
                      sx={{ textTransform: "none", borderRadius: "6px" }}
                    >
                      {translations.ShowQRCode}
                    </CustomButton>
                  </CustomGridItem>
                </CustomBox>
              ) : (
                <>
                  <CustomBox sx={{ padding: "10% 0" }}>
                    <CustomGridItem item marginBottom="15px">
                      <QRCode value={qrCodeUrl} />
                    </CustomGridItem>
                    <CustomGridItem item marginBottom="15px">
                      <InputLabel htmlFor="pinCode">
                        <Typography>{translations.PINCode}</Typography>
                      </InputLabel>
                    </CustomGridItem>
                    <CustomGridItem item marginBottom="15px">
                      <Controller
                        control={control}
                        name="pinCode"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.pinCode}
                            helperText={errors.pinCode?.message}
                            id="pin_code"
                            name="pin_code"
                            label={translations.verificationCode}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </CustomGridItem>
                    {open && (
                      <Grid item marginBottom="15px">
                        <ErrorMessageContainer textAlign="center">
                          {ErrorText(errorMessages)}
                        </ErrorMessageContainer>
                      </Grid>
                    )}
                    <CustomGridItem item>
                      <CustomButton
                        onClick={handleSubmit(onMFASetup)}
                        variant="contained"
                        disabled={!isValid}
                        sx={{ textTransform: "none" }}
                      >
                        {translations.Create}
                      </CustomButton>
                    </CustomGridItem>
                  </CustomBox>
                </>
              )}
            </>
          ) : (
            <CustomBox sx={{ padding: "20% 0" }}>
              <Typography>{translations.verificationAlready}</Typography>
            </CustomBox>
          )}
          <AlertContent />
        </CustomContentEmployeePaper>
      </ContentTemplate>
    </>
  )
}
