const ja = {
  SeatLocation: "席・会議室",
  Location: "拠点",
  Floor: "フロア",
  DeskRoom: "対象席",
  Attendees: "参加者",
  Name: "名前",
  Accepted: "参加",
  Declined: "不参加",
  from: "から",
  people: "名",
  manyPeople: "名中",
  Description: "本文",
  NoTitle: "(タイトルなし)",
  month: "月",
  day: "日",
  week: "曜日",
  everyMonth: "毎月",
  everyWeek: "毎週",
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  Monthly: "ヶ月ごとの",
  weekly: "週ごとの",
  Private: "非公開",
  first: "第1",
  second: "第2",
  third: "第3",
  fourth: "第4",
  last: "最終",
  invitation: "参加しますか？",
  Decline: "拒否",
  Accept: "承認",
  ScheduleDeleted: "予定の削除が完了しました",
  autoCancel: "自動キャンセルされました。",
  gest: "ゲスト",
  schedule_statuses: {
    出社: "出社",
    在宅: "在宅",
    外出: "外出",
    出張: "出張",
    休暇: "休暇",
    その他: "その他",
  },
}
export default ja
