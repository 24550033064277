import React, { useState } from "react"

import { Box, Modal, styled, CircularProgress, Tabs, Tab } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AcceptStatusRequestType } from "../../../../models/employee/useSchedule/days/type"
import {
  Loading,
  ScheduleInformation,
  ScheduleInformations,
} from "../../../../models/employee/useSchedule/show/type"
import enTranslations from "../../../../translations/mobileHome/mobileHomeShowScheduleModal/en"
import jaTranslations from "../../../../translations/mobileHome/mobileHomeShowScheduleModal/ja"
import { MobileScheduleShowList } from "../MobileScheduleShowList"

const CustomCircularBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "95%",
  minHeight: "70%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.spacing(1),
}))

const CustomModalBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "95%",
  minHeight: "70%",
  maxHeight: "98%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}))

interface Props {
  date: Date
  isOpen: boolean
  close: () => void
  scheduleShowData: Loading<ScheduleInformations>
  onApproveSubmit: (data: AcceptStatusRequestType) => void
}

interface TabPanelProps {
  children?: ScheduleInformation
  index: number
  value: number
}

// ステータスタイトル言語切り替え
type ScheduleStatuses = {
  [key: string]: string
}

type Translations = {
  schedule_statuses: ScheduleStatuses
}

export const useLocalizedStatusText = (status: string) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations: Translations =
    language === "en" ? enTranslations : jaTranslations
  return translations.schedule_statuses[status] || status
}

export const MobileScheduleDayShowModal: React.FC<Props> = ({
  date,
  isOpen,
  close,
  scheduleShowData,
  onApproveSubmit,
}) => {
  const [value, setValue] = useState<number>(0)
  const titleArray = scheduleShowData.value.map((scheduleInformation) => {
    return useLocalizedStatusText(scheduleInformation.schedule_title)
  })

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const TabPanel = ({ children, index, value }: TabPanelProps) => {
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{
          marginTop: theme.spacing(1.5),
          height: "70vh",
        }}
      >
        {value === index && children && (
          <MobileScheduleShowList
            date={date}
            close={close}
            onApproveSubmit={onApproveSubmit}
            scheduleInformation={children}
          />
        )}
      </Box>
    )
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {scheduleShowData.loading ? (
          <CustomCircularBox>
            <CircularProgress />
          </CustomCircularBox>
        ) : (
          <CustomModalBox>
            <Box
              sx={{
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={value}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {titleArray.map((title, index) => {
                  return <Tab key={index} label={title} value={index} />
                })}
              </Tabs>
              {scheduleShowData.value.map((scheduleInformation, index) => {
                return (
                  <TabPanel key={index} value={value} index={index}>
                    {scheduleInformation}
                  </TabPanel>
                )
              })}
            </Box>
          </CustomModalBox>
        )}
      </Modal>
    </>
  )
}
