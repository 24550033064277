import { Amplify, Auth } from "aws-amplify"

import { useState, useEffect, useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"

import awsmobile from "../../../aws-exports"
import { RoleContext } from "../../../providers/RoleProvider"

export const useFederatedSignIn = () => {
  Amplify.configure(awsmobile)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate()
  const { isAdmin, isTeamLeader } = useContext(RoleContext)

  // Azure ADからトークンを取得し、ユーザーを認証する
  const fetchTokenFromAzureAD = useCallback(
    async (code: string, selectedTenantId: string) => {
      const tokenRequestBody = new URLSearchParams({
        grant_type: "authorization_code",
        client_id: awsmobile.aws_user_pools_web_client_id,
        code: code,
        redirect_uri: awsmobile.oauth.redirectSignIn,
      }).toString()

      try {
        const response = await fetch(
          `https://login.microsoftonline.com/${selectedTenantId}/oauth2/v2.0/token`,
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: tokenRequestBody,
          }
        )

        const data = await response.json()

        if (data.id_token && data.access_token) {
          await Auth.federatedSignIn(
            "azuread",
            { token: data.id_token, expires_at: Date.now() + 3600 * 1000 },
            { name: data.email || data.preferred_username || "unknown" }
          )
          setIsAuthenticated(true)
          setCurrentUser(data)
        } else {
          throw new Error("トークンが取得できませんでした")
        }
      } catch (error) {
        setIsAuthenticated(false)
        setCurrentUser(null)
      }
    },
    []
  )

  // 現在の認証状態をチェック
  const authenticate = useCallback(async () => {
    try {
      const response = await Auth.currentSession()
      setIsAuthenticated(!!response)

      if (response) {
        const user = await Auth.currentAuthenticatedUser()
        setCurrentUser(user)
      }
    } catch (error) {
      setIsAuthenticated(false)
      setCurrentUser(null)
    }
  }, [])

  // サインアウト
  const federatedSignOut = useCallback(async () => {
    try {
      const currentPath = window.location.pathname

      if (isAdmin || isTeamLeader) {
        if (currentPath.includes("/company")) {
          localStorage.setItem("postSignOutRedirect", "/company/login")
          await Auth.signOut()
        } else if (currentPath.includes("/employee")) {
          localStorage.setItem("postSignOutRedirect", "/employee/login")
          await Auth.signOut()
        } else if (currentPath.includes("/mobile")) {
          localStorage.setItem("postSignOutRedirect", "/mobile/login")
          await Auth.signOut()
        }
      } else {
        if (currentPath.includes("/employee")) {
          localStorage.setItem("postSignOutRedirect", "/employee/login")
        } else if (currentPath.includes("/mobile")) {
          localStorage.setItem("postSignOutRedirect", "/mobile/login")
        }
        await Auth.signOut()
      }

      // サインアウト成功後に状態を更新
      setIsAuthenticated(false)
      setCurrentUser(null)

      localStorage.removeItem("postSignOutRedirect")
    } catch (error) {
      console.log(error)
    }
  }, [isAdmin, isTeamLeader, navigate])

  useEffect(() => {
    authenticate()
  }, [authenticate])

  return {
    isAuthenticated,
    currentUser,
    fetchTokenFromAzureAD,
    federatedSignOut,
    authenticate,
  }
}
