import { useState } from "react"

import { SelectChangeEvent } from "@mui/material"

import {
  akerunDoorRequest,
  fetchSignageSettingRequest,
} from "../../../api/signage/settingRequest"
import { SignageSettingType, FloorType, AkerunDoorType } from "./type"

const initialFloorData: FloorType = {
  id: 0,
  branch_id: 0,
  floor_name: "",
  floor_number: "",
  layouts: {
    id: 0,
    meeting_rooms: [
      {
        id: 0,
        meeting_room_code: "",
        meeting_room_name: "",
      },
    ],
  },
}

const initialBranchData: SignageSettingType = {
  id: 0,
  branch_name: "",
  floors: [initialFloorData],
}

const initialAkerunDoorRelation: AkerunDoorType[] = [
  {
    id: "",
    name: "",
  },
]

export const useSignageSetting = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)
  const [meetingRoomId, setMeetingRoomId] = useState<number>(0)
  const [selectedBranch, setSelectedBranch] =
    useState<SignageSettingType>(initialBranchData)
  const [selectedFloor, setSelectedFloor] =
    useState<FloorType>(initialFloorData)

  const [signageDisplayData, setSignageDisplayData] = useState<
    SignageSettingType[]
  >([])
  const [akerunDoorRelation, setAkerunDoorRelations] = useState<
    AkerunDoorType[]
  >(initialAkerunDoorRelation)

  // 初期設定に必要なデータの取得
  const fetchSignageDisplayData = async () => {
    try {
      setLoading(true)
      const response = await fetchSignageSettingRequest()
      if (response.status === 200 && response.data) {
        setSignageDisplayData(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
  }

  // 拠点とフロアの切り替え
  const changeSelectValue = (e: SelectChangeEvent<number>) => {
    switch (e.target.name) {
      case "branch_id":
        setFloorId(0)
        handleSelectBranch(Number(e.target.value))
        fetchAkerunDoorRelations(Number(e.target.value))
        return setBranchId(Number(e.target.value))
      case "floor_id":
        handleSelectFloor(Number(e.target.value))
        return setFloorId(Number(e.target.value))
      case "meeting_room_id":
        return setMeetingRoomId(Number(e.target.value))
    }
  }

  const handleSelectBranch = (branchId: number) => {
    const selectedBranchData: SignageSettingType = signageDisplayData.filter(
      (branch) => {
        return branch.id === branchId
      }
    )[0]
    setSelectedBranch(selectedBranchData)
  }

  const handleSelectFloor = (floorId: number) => {
    if (selectedBranch.floors !== undefined) {
      const selectedFloorData: FloorType = selectedBranch.floors.filter(
        (floor) => {
          return floor.id === floorId
        }
      )[0]
      setSelectedFloor(selectedFloorData)
    }
  }

  // Akerunの扉情報を取得
  const fetchAkerunDoorRelations = async (branchId: number) => {
    try {
      const response = await akerunDoorRequest(branchId)
      if (response.status === 200 && response.data) {
        setAkerunDoorRelations(response.data)
      } else {
        setAkerunDoorRelations(initialAkerunDoorRelation)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    branchId,
    floorId,
    meetingRoomId,
    selectedBranch,
    selectedFloor,
    setSelectedFloor,
    signageDisplayData,
    fetchSignageDisplayData,
    changeSelectValue,
    setAkerunDoorRelations,
    akerunDoorRelation,
  }
}
