// レイアウト画像のダウンロード
import { Storage, Amplify } from "aws-amplify"

import awsmobile from "../aws-exports"

Amplify.configure(awsmobile)
export const downloadLayoutImage = async (
  companyId: number,
  branchId: number,
  floorId: number,
  img: string
) => {
  if (!img) return

  const result = await Storage.get(img, {
    level: "public",
    download: true,
    customPrefix: {
      public: `company${companyId}/layouts/branch${branchId}/floor${floorId}/`,
    },
  })

  return result
}
