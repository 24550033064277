import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"

import React, { useEffect, useContext } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Box,
  Typography,
  IconButton,
  styled,
  MenuItem,
  Grid,
  Select,
  InputLabel,
  SelectChangeEvent,
  FormControl,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { MeetingRoomScheduleRequestType } from "../../../../../models/employee/useSchedule/meetingRooms/type"
import { useCompanyRelations } from "../../../../../models/public/useCompanyRelations"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeMeetingRoom/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeMeetingRoom/ja"
import { useOpen } from "../../../../../utils/isOpen"
import { GreenArrowIcon } from "../../../../public/GreenArrowIcon"
import { NoRecordTextType } from "../Main"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  width: "60%",
}))

interface Props {
  handleScheduleFetch: (data: MeetingRoomScheduleRequestType) => Promise<void>
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  startDate: Date
  endDate: Date
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  formatDate: (date: Date) => string
  setNoRecordText: React.Dispatch<React.SetStateAction<NoRecordTextType>>
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
}

export const MeetingRoomScheduleHeader = ({
  handleScheduleFetch,
  date,
  setDate,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  formatDate,
  setNoRecordText,
  weekdayNumber,
  setWeekdayNumber,
}: Props) => {
  const {
    companyRelations,
    branchId,
    selectedBranch,
    floorId,
    setBranchId,
    setFloorId,
  } = useCompanyRelations()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)

  const CustomBoldTypography = styled(Typography)({
    letterSpacing: "0.8px",
    fontSize: "12px",
    fontFamily: "Noto Sans",
  })

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // プルダウンの変更
  const handlePullDownChange = (e: SelectChangeEvent<unknown>) => {
    switch (e.target.name) {
      case "branch_id":
        setBranchId(e.target.value as number)
        setFloorId(0)
        break
      case "floor_id":
        setFloorId(e.target.value as number)
        break
    }
  }

  // フロアを変更した時は一律でAPIに問い合わせる
  useEffect(() => {
    if (floorId > 0) {
      handleScheduleFetch({
        branch_id: branchId,
        floor_id: floorId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      })
      setNoRecordText("表示するデータがありません") // 拠点とフロアを選択後、データがない場合に表示されるテキスト
    }
  }, [floorId])

  // 日付を変更する場合、2日以上変更した場合のみAPIに問い合わせる
  useEffect(() => {
    // if (date < startDate || date > endDate) {
    //   if (floorId > 0) {
    //     const newStartDate = new Date(
    //       date.getFullYear(),
    //       date.getMonth(),
    //       date.getDate() - 1
    //     )
    //     const newEndDate = new Date(
    //       date.getFullYear(),
    //       date.getMonth(),
    //       date.getDate() + 1
    //     )
    //     handleScheduleFetch({
    //       branch_id: branchId,
    //       floor_id: floorId,
    //       start_date: formatDate(newStartDate),
    //       end_date: formatDate(newEndDate),
    //     })
    //     setStartDate(newStartDate)
    //     setEndDate(newEndDate)
    //   }
    // }

    if (floorId > 0) {
      const newStartDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const newEndDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      handleScheduleFetch({
        branch_id: branchId,
        floor_id: floorId,
        start_date: formatDate(newStartDate),
        end_date: formatDate(newEndDate),
      })
      setStartDate(newStartDate)
      setEndDate(newEndDate)
    }
  }, [date])

  const scheduleCalendar = useOpen()

  // dateにカレンダーで選択された日付を設定する。
  const handleDateChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setDate(new Date(newValue))
      window.localStorage.setItem(
        "targetDay",
        new Date(newValue).getTime().toString()
      )
    }
  }

  // weekdayにカレンダーで選択された日付の曜日を設定する。
  const handleWeekdayChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setWeekdayNumber(newValue.getDay())
    }
  }

  return (
    <>
      <Box
        sx={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
          width: "80vw",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              previousDate()
              previousWeekday()
            }}
          >
            <ChevronLeftIcon color="primary" />
          </IconButton>
          <Box>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={language === "ja" ? ja : enUS}
            >
              <DesktopDatePicker
                value={date}
                open={scheduleCalendar.isOpen}
                onChange={(event) => {
                  handleDateChange(event)
                  handleWeekdayChange(event)
                  scheduleCalendar.close()
                }}
                maxDate={schedulableMaxDate}
                onClose={scheduleCalendar.close}
                slots={{
                  textField: () => (
                    <CustomBoldTypography
                      onClick={scheduleCalendar.open}
                      sx={{ cursor: "pointer" }}
                    >
                      {`${month}${translations.month}${day}${translations.day}${translations.weekdays[weekdayNumber]}`}
                    </CustomBoldTypography>
                  ),
                  previousIconButton: IconButton,
                  nextIconButton: IconButton,
                }}
                slotProps={{
                  popper: {
                    sx: {
                      marginTop: "140px",
                      marginLeft: "75px",
                    },
                  },
                  textField: {
                    onClick: scheduleCalendar.open,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <IconButton
            disabled={schedulableMaxDate <= date}
            onClick={() => {
              nextDate()
              nextWeekday()
            }}
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </Box>
        <CustomGridContainer
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={5}
        >
          <Grid item container sm={6} alignItems="center">
            <Grid item xs={12} sm={2.5}>
              <InputLabel
                htmlFor="branch_id"
                color="primary"
                sx={{
                  color: "#22BA9D",
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Noto Sans",
                }}
              >
                {translations.Location}
              </InputLabel>
            </Grid>
            <Grid sm={9.5} xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="branch_id">
                  {translations.Location}
                </InputLabel>
                <Select
                  id="branch_id"
                  name="branch_id"
                  label="拠点"
                  fullWidth
                  value={branchId}
                  variant="outlined"
                  onChange={handlePullDownChange}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    background: "rgba(112,112,112,0.05)",
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 15px",
                    },
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    disabled={true}
                    key={0}
                    value={0}
                  >
                    {translations.Location}
                  </MenuItem>
                  {companyRelations.branches.map((branch) => (
                    <MenuItem
                      sx={{ fontSize: "12px" }}
                      key={branch.id}
                      value={branch.id}
                    >
                      {branch.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container sm={6} alignItems="center">
            <Grid item xs={12} sm={3}>
              <InputLabel
                htmlFor="floor_id"
                sx={{
                  color: "#22BA9D",
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Noto Sans",
                }}
              >
                {translations.Floor}
              </InputLabel>
            </Grid>
            <Grid sm={9} xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="floor_id">{translations.Floor}</InputLabel>
                <Select
                  id="floor_id"
                  name="floor_id"
                  label="フロア"
                  fullWidth
                  variant="outlined"
                  onChange={handlePullDownChange}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Noto Sans",
                    background: "rgba(112,112,112,0.05)",
                    "& .MuiOutlinedInput-input": {
                      padding: "12px 15px",
                    },
                  }}
                  IconComponent={GreenArrowIcon}
                  value={floorId}
                >
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    disabled={true}
                    key={0}
                    value={0}
                  >
                    {translations.Floor}
                  </MenuItem>
                  {selectedBranch &&
                    selectedBranch.floors.map((floor) => (
                      <MenuItem
                        sx={{ fontSize: "12px" }}
                        key={floor.id}
                        value={floor.id}
                      >
                        {floor.floor_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CustomGridContainer>
      </Box>
    </>
  )
}
