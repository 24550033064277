import {
  SearchEmployeeRequestType,
  SearchedEmployeeResult,
} from "../../models/company/useEmployeeLeaderSearch/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const employeeSearchRequest = async (
  params: SearchEmployeeRequestType
): Promise<ApiResponse<SearchedEmployeeResult>> => {
  try {
    const data = await api.get("/company/employees/search", { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
