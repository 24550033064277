import React, { Suspense, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { CircularProgress } from "@mui/material"

import { usePasswordExpireMonthes } from "../../../../models/company/usePasswordExpireMonthes"
import { useAccounts } from "../../../../models/employee/useAccounts"
import { pageType } from "../../../../utils/location"

const MobileCheckInOutContent = React.lazy(() =>
  import("./CheckInOutFromHome").then(({ CheckInOutFromHome }) => ({
    default: CheckInOutFromHome,
  }))
)

const MobileScheduleContent = React.lazy(() =>
  import("../../schedule/MobileCalendarWeek").then(
    ({ MobileScheduleWeek }) => ({
      default: MobileScheduleWeek,
    })
  )
)
export const MobileHomeSchedule = () => {
  const navigate = useNavigate()
  const { fetchPasswordChangeTimes } = useAccounts()
  const { fetchPasswordExpireMonthes } = usePasswordExpireMonthes()

  useEffect(() => {
    fetchPasswordChangeTimes().then((data) => {
      if (data) {
        if (new Date() > new Date(data.password_change_times)) {
          localStorage.setItem("expiredChangePassword", "true")
          navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
        }
      }
    })
    fetchPasswordExpireMonthes()
  }, [])

  return (
    <>
      <Suspense fallback={<CircularProgress />}>
        <MobileCheckInOutContent />
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        <MobileScheduleContent />
      </Suspense>
    </>
  )
}
