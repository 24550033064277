const ja = {
  DeleteSchedule: "予定を削除",
  Attendees: "参加者",
  month: "月",
  day: "日",
  week: "曜日",
  Accepted: "参加",
  Declined: "不参加",
  invitation: "参加しますか？",
  Decline: "拒否",
  Accept: "承認",
  everyMonth: "毎月",
  everyWeek: "毎週",
  weekly: "週ごとの",
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  private: "非公開",
  public: "公開",
  Message: "メッセージ",
  DesksRooms: "席・会議室",
  Edit: "編集",
  ScheduleDelete: "予定の削除が完了しました",
  gest: "(ゲスト)",
  schedule_statuses: {
    出社: "出社",
    在宅: "在宅",
    外出: "外出",
    出張: "出張",
    休暇: "休暇",
    その他: "その他",
  },
  StartDate: "開始日",
  StartTime: "開始時刻",
  EndTime: "終了時刻",
  EndDate: "終了日",
}
export default ja
