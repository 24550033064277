import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Popover,
  styled,
  Typography,
} from "@mui/material"

import { theme } from "../../../../config/theme"
import { useLayout } from "../../../../models/company/useLayout"
import {
  AreaType,
  MeetingRoomType,
  QrObjectType,
  SeatType,
} from "../../../../models/company/useQrCode/type"
import { useCompanyRelations } from "../../../../models/public/useCompanyRelations"
import { CancelButton } from "../../../public/CancelButton"
import { FreeSeat } from "../../../public/FreeSeat"
import { Loading } from "../../../public/Loading"
import { ReflectButton } from "../../../public/ReflectButton"
import { ReservedSeat } from "../../../public/ReservedSeat"
import { UnavailableSeat } from "../../../public/UnavailableSeat"
import { CompanyQrCodeModalArea } from "../CompanyQrCodeModalArea"
import { CompanyQrCodeModalMeetingRoom } from "../CompanyQrCodeModalMeetingRoom"
import { CompanyQrCodeModalSeat } from "../CompanyQrCodeModalSeat"

const ZoomButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
}))

const Separator = styled("hr")(({ theme }) => ({
  height: "650px",
  opacity: "0.3",
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(3),
}))

const ButtonContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  paddingBottom: theme.spacing(4),
}))

const SeatList = styled(Box)(({ theme }) => ({
  maxHeight: theme.spacing(60),
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

interface Props {
  setSeatModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedSeatList: QrObjectType[]
  handleSeatSelect: (seatId: number, seatName: number) => void
  handleAllSeatSelected: (
    e: React.ChangeEvent<HTMLInputElement>,
    seats: SeatType[]
  ) => void
  isSeatSelected: (seatId: number) => boolean
  layoutId: number
}

// QRコード画面：座席選択モーダル
export const CompanyQrCodeSeatModal = ({
  setSeatModalOpen,
  selectedSeatList,
  handleSeatSelect,
  handleAllSeatSelected,
  isSeatSelected,
  layoutId,
}: Props) => {
  const {
    setCompanyId,
    setFloorId,
    setBranchId,
    setLayoutId,
    seats,
    layoutImg,
    meetingRooms,
    areas,
  } = useLayout()

  const { fetchCompanyRelations } = useCompanyRelations()

  const urlParams = useParams<{ id: string; floor_id: string }>()

  // アイコン凡例の表示
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  // モーダルを開いた時にレイアウトを参照
  useEffect(() => {
    const fetchParams = async () => {
      const data = await fetchCompanyRelations()
      if (!data) return
      setCompanyId(data.id)
      setBranchId(Number(urlParams.id))
      setFloorId(Number(urlParams.floor_id))
      setLayoutId(Number(layoutId))
    }
    fetchParams()
  }, [])

  // レイアウトが読み込まれた時にローディングを終了
  useEffect(() => {
    if (layoutImg !== "") {
      setLoading(false)
      history.replaceState(
        null,
        "",
        `/company/branches/${Number(urlParams.id)}/floors/${Number(
          urlParams.floor_id
        )}/qrcode` // useLayout > layoutReflectionの影響でURLが変わってしまうので再度replace
      )
    }
  }, [layoutImg])

  // エリアに紐づいている利用可能な座席
  const availableAreaChildren = areas
    .map((area) => {
      return area.children.filter((seat) => {
        return seat.seat_type !== 2
      })
    })
    .flat()

  // 利用可能な席
  const availableSeats = seats.filter((seat) => {
    return seat.seat_type !== 2
  })

  // エリアに紐づいている座席とそうではない座席を両方合算して座席番号順に並べる
  const allSeat = availableSeats
    .concat(availableAreaChildren)
    .sort((first, second) => {
      return Number(first.seat_name) - Number(second.seat_name)
    })

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <>
          {loading ? (
            <>
              <Loading type="content" loading={loading} />
              <Box sx={{ height: "500px", width: "100%" }} />
            </>
          ) : (
            <>
              <Typography variant="h5" fontWeight="bold">
                座席を指定してください
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                      padding: theme.spacing(3, 4, 2, 0),
                    }}
                  >
                    <Button
                      sx={{ mr: 2, borderRadius: "16px" }}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleClick}
                    >
                      アイコン凡例
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: -20,
                        horizontal: -10,
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box
                        sx={{
                          height: "56px",
                          width: "400px",
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <ReservedSeat />
                        <Typography variant="body1">指定席</Typography>
                        <FreeSeat />
                        <Typography variant="body1">自由席</Typography>
                        <UnavailableSeat />
                        <Typography variant="body1">利用不可</Typography>
                      </Box>
                    </Popover>
                    <Grid item>
                      <ZoomButtonBox color="primary">
                        <MinusButton
                          onClick={async () => {
                            // 最小10%を超えないようにする
                            if (
                              layoutImageRef.current?.state?.scale &&
                              layoutImageRef.current?.state?.scale <= 0.1
                            ) {
                              return
                            } else {
                              layoutImageRef.current?.setTransform(
                                0,
                                0,
                                layoutImageRef.current?.state.scale - 0.1
                              )
                            }
                          }}
                        >
                          ー
                        </MinusButton>
                        <PlusButton
                          onClick={async () => {
                            // 最大100%を超えないようにする
                            if (
                              layoutImageRef.current?.state?.scale &&
                              layoutImageRef.current?.state?.scale >= 1.0
                            ) {
                              return
                            } else {
                              layoutImageRef.current?.setTransform(
                                0,
                                0,
                                layoutImageRef.current?.state.scale + 0.1
                              )
                            }
                          }}
                        >
                          ＋
                        </PlusButton>
                      </ZoomButtonBox>
                    </Grid>
                  </Grid>
                  <TransformWrapper
                    initialScale={0.5}
                    disabled={false}
                    minScale={0.1}
                    maxScale={1.0}
                    wheel={{ disabled: false, step: 0.05 }}
                    panning={{
                      disabled: false,
                    }}
                    ref={layoutImageRef}
                  >
                    <TransformComponent
                      wrapperStyle={{ maxWidth: "100%", maxHeight: "600px" }}
                    >
                      <img id="layout-image" src={layoutImg} />
                      {availableSeats.map((seat, index) => {
                        return (
                          <CompanyQrCodeModalSeat
                            key={index}
                            seat={seat as SeatType}
                            isSeatSelected={isSeatSelected(seat.id as number)}
                            handleSeatSelect={handleSeatSelect}
                          />
                        )
                      })}
                      {meetingRooms.map((meetingRoom, index) => {
                        return (
                          <CompanyQrCodeModalMeetingRoom
                            key={index}
                            meetingRoom={meetingRoom as MeetingRoomType}
                          />
                        )
                      })}
                      {areas.map((area, areaIndex) => {
                        return (
                          <>
                            <CompanyQrCodeModalArea
                              key={areaIndex}
                              area={area as AreaType}
                            />
                            {(area as AreaType).children
                              .filter((seat) => {
                                return seat.seat_type !== 2
                              })
                              .map((seat, seatIndex) => {
                                return (
                                  <CompanyQrCodeModalSeat
                                    key={seatIndex}
                                    seat={seat}
                                    isSeatSelected={isSeatSelected(
                                      seat.id as number
                                    )}
                                    handleSeatSelect={handleSeatSelect}
                                  />
                                )
                              })}
                          </>
                        )
                      })}
                    </TransformComponent>
                  </TransformWrapper>
                </Grid>
                <Separator />
                <Grid item xs={3}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Checkbox
                        checked={selectedSeatList.length === allSeat.length} // 全ての座席が選択されている場合はチェック済みにする
                        onChange={(e) => {
                          handleAllSeatSelected(e, allSeat as SeatType[])
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        fontWeight="bold"
                      >
                        全て選択
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box paddingLeft="0.7rem">
                    <Typography variant="h6" color="primary" fontWeight="bold">
                      選択中の座席
                    </Typography>
                    <SeatList>
                      {selectedSeatList.map((seat) => {
                        return (
                          <Typography variant="subtitle1" key={seat.id}>
                            座席{seat.name}
                          </Typography>
                        )
                      })}
                    </SeatList>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </Grid>
      <Grid item sm={12} mt={3}>
        <Typography align="center" gutterBottom>
          印刷範囲に表示しますか？
        </Typography>
      </Grid>
      <ButtonContainer item container xs={12} sm={12} justifyContent="center">
        <Grid item sm={8} display="flex" justifyContent="space-around">
          <CancelButton
            label="キャンセル"
            handleClick={() => setSeatModalOpen(false)}
          />
          <ReflectButton
            label="表示する"
            handleClick={() => setSeatModalOpen(false)}
          />
        </Grid>
      </ButtonContainer>
    </Grid>
  )
}
