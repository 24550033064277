import React, { useEffect } from "react"
import { UseFormGetValues } from "react-hook-form"

import AddIcon from "@mui/icons-material/Add"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import {
  Box,
  Grid,
  Checkbox,
  styled,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { useMeetingRooms } from "../../../../../models/employee/useMeetingRooms"
import { ProfileResponseType } from "../../../../../models/employee/useProfile/type"
import {
  fetchLayout,
  layoutResponseState,
} from "../../../../../models/employee/useSchedule/useLayout"
import { Seat } from "../../../../../models/employee/useSchedule/useLayout/type"
import {
  ReservableType,
  ScheduleForm,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { useSeats } from "../../../../../models/employee/useSeats"
import { CompanyRelationsType } from "../../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../../translations/mobileSchedule/en"
import jaTranslations from "../../../../../translations/mobileSchedule/ja"

const CustomLoadingBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  width: "95%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomModalBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  width: "95%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}))

const CustomTitleBox = styled(Box)(() => ({
  marginLeft: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  color: "#22BA9D",
}))

const CustomFooterBox = styled(Box)(() => ({
  marginLeft: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(1.5),
  position: "sticky",
  top: "100vh",
}))

const AllSeatsContentGrid = styled(Grid)(() => ({
  height: "20%",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

const AllRoomsContentGrid = styled(Grid)(() => ({
  maxHeight: "25%",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

interface MeetingRoom {
  meetingRoomId: number
  branchName: string
  floorNumber: string
  roomName: string
  capacity: number
}

interface CheckedSeat {
  seatId: number
  branchName: string
  floorName: string
  seatName: string
}

interface Props {
  addPlaceModalClose: () => void
  checkedSeatId: number
  setCheckedSeatId: React.Dispatch<React.SetStateAction<number>>
  checkedMeetingRoomId: number
  setCheckedMeetingRoomId: React.Dispatch<React.SetStateAction<number>>
  setIsSeatOrMeetingRoom: React.Dispatch<
    React.SetStateAction<ReservableType | undefined>
  >
  handleFixPlace: () => void
  handleCancelSelectPlace: () => void
  setCheckedSeat: React.Dispatch<React.SetStateAction<CheckedSeat | undefined>>
  setCheckedMeetingRoom: React.Dispatch<
    React.SetStateAction<MeetingRoom | undefined>
  >
  startDateTime: Date | null
  endDateTime: Date | null
  getValues: UseFormGetValues<ScheduleForm>
  employeeProfile: ProfileResponseType
  companyRelations: CompanyRelationsType
  isWholeDay: boolean
}

export const AddPlaceModal: React.FC<Props> = ({
  addPlaceModalClose,
  checkedSeatId,
  setCheckedSeatId,
  checkedMeetingRoomId,
  setCheckedMeetingRoomId,
  setIsSeatOrMeetingRoom,
  handleFixPlace,
  handleCancelSelectPlace,
  setCheckedSeat,
  setCheckedMeetingRoom,
  startDateTime,
  endDateTime,
  getValues,
  employeeProfile,
  companyRelations,
  isWholeDay,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { recentSeats, allSeats, fetchAllSeats } = useSeats(
    startDateTime,
    endDateTime
  )
  const startDate = getValues("schedule.start_date")

  const { meetingRooms, loading, fetchMeetingRooms } = useMeetingRooms()
  const { layoutResponse, setLayoutResponse } = layoutResponseState()

  const seats = layoutResponse.value.seats

  const recentSeatIds = recentSeats.map((seat) => {
    return seat.id
  })
  const recentSeatList = seats.filter((seat) => {
    return recentSeatIds.includes(seat.id)
  })
  const uniqueAllSeats = seats.filter((seat) => {
    return !recentSeatIds.includes(seat.id)
  })
  const sortedAllSeats = uniqueAllSeats.sort((seat) => {
    if (seat.id === checkedSeatId) return -1
    else return 1
  })

  const sortedMeetingRooms = meetingRooms
    .filter((meetingRoom) => !(isWholeDay && meetingRoom.available_time > 0))
    .sort((meetingRoom) => {
      if (meetingRoom.id === checkedMeetingRoomId) return -1
      else return 1
    })

  // 席が選択可能か判別
  const isSelectable = (seat: Seat): boolean => {
    if (seat.reservable_employee_ids.length > 0) {
      return (
        !seat.schedule &&
        !seat.unavailable_flag &&
        seat.reservable_employee_ids.includes(employeeProfile.id)
      )
    } else {
      return !seat.schedule && !seat.unavailable_flag
    }
  }

  const handleSelectRecentSeat = (
    e: React.ChangeEvent<HTMLInputElement>,
    seatId: number,
    branchName: string,
    floorName: string,
    seatName: string
  ) => {
    if (e.target.checked) {
      setCheckedSeatId(seatId)
      setCheckedMeetingRoomId(0)
      setIsSeatOrMeetingRoom("Seat")
      setCheckedSeat({
        seatId: seatId,
        branchName: branchName,
        floorName: floorName,
        seatName: seatName,
      })
      setCheckedMeetingRoom(undefined)
    } else {
      setCheckedSeatId(0)
      setCheckedSeat(undefined)
      setIsSeatOrMeetingRoom(undefined)
    }
  }

  const handleSelectRecentMeetingRoom = (
    e: React.ChangeEvent<HTMLInputElement>,
    meetingRoomId: number,
    branchName: string,
    floorNumber: string,
    roomName: string,
    capacity: number
  ) => {
    if (e.target.checked) {
      setCheckedMeetingRoomId(meetingRoomId)
      setCheckedSeatId(0)
      setIsSeatOrMeetingRoom("MeetingRoom")
      setCheckedMeetingRoom({
        meetingRoomId: meetingRoomId,
        branchName: branchName,
        floorNumber: floorNumber,
        roomName: roomName,
        capacity: capacity,
      })
      setCheckedSeat(undefined)
    } else {
      setCheckedMeetingRoomId(0)
      setCheckedMeetingRoom(undefined)
      setIsSeatOrMeetingRoom(undefined)
    }
  }

  const handleLayoutOpen = () => {
    window.open("layouts")
  }

  // 席の選択情報を取得
  const promiseLayout = async () => {
    await fetchLayout(
      {
        branch_id: employeeProfile.branch.id,
        floor_id: employeeProfile.floor.id,
        start_date: getValues("schedule.start_date"),
        end_date: getValues("schedule.end_date"),
        start_time: getValues("schedule.whole_day_flag")
          ? new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              6,
              0
            )
          : getValues("schedule.start_time"),
        end_time: getValues("schedule.whole_day_flag")
          ? new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              23,
              59
            )
          : getValues("schedule.end_time"),
      },
      setLayoutResponse,
      companyRelations.id
    )
  }

  useEffect(() => {
    fetchMeetingRooms(
      getValues("schedule.start_date"),
      getValues("schedule.end_date"),
      getValues("schedule.start_time"),
      getValues("schedule.end_time"),
      getValues("schedule.share_schedule.who_is_wheres").length
    )
    if (companyRelations.id > 0) {
      promiseLayout()
    }
  }, [])

  return (
    <>
      {loading ? (
        <CustomLoadingBox>
          <CircularProgress />
        </CustomLoadingBox>
      ) : (
        <CustomModalBox>
          <Box height="90%">
            <Box display="flex" mb={2}>
              <ArrowBackIosNewIcon
                color="primary"
                fontSize="large"
                onClick={handleCancelSelectPlace}
              />
              <Typography variant="h6" ml={1}>
                {translations.AddDeskMeetingRoom}
              </Typography>
            </Box>
            <CustomTitleBox>
              <Typography variant="body1" fontWeight="bold">
                {translations.UsedBefore}
              </Typography>
            </CustomTitleBox>
            <Grid container mt={1}>
              <Grid item xs={2.5} mt={1.5}>
                <Typography variant="body2">{translations.Desks}：</Typography>
              </Grid>
              <Grid item xs={9.5}>
                {recentSeatList.map((seat, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Grid item xs={2}>
                        <Checkbox
                          size="small"
                          checked={seat.id === checkedSeatId}
                          onChange={(e) =>
                            handleSelectRecentSeat(
                              e,
                              seat.id,
                              layoutResponse.value.branch.branch_name,
                              layoutResponse.value.floor_name,
                              seat.seat_name
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          mr="0.5rem"
                          display="inline"
                        >
                          {layoutResponse.value.branch.branch_name}
                        </Typography>
                        <Typography variant="body2" mr="1rem" display="inline">
                          {layoutResponse.value.floor_name}
                        </Typography>
                        <Typography variant="body2" display="inline">
                          {seat.seat_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={1}>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => {
                  if (
                    startDate != null &&
                    Object.prototype.toString.call(startDate)
                  ) {
                    fetchAllSeats(startDate)
                  }
                }}
              >
                <AddIcon color="inherit" sx={{ marginRight: "0.3rem" }} />
                {translations.Add}
              </Button>
            </Box>
            {allSeats.length > 0 && (
              <AllSeatsContentGrid container mt={1.5}>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={9.5}>
                  {sortedAllSeats.map((seat, index) => {
                    return (
                      <Grid
                        container
                        key={index}
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Grid item xs={2}>
                          <Checkbox
                            size="small"
                            checked={seat.id === checkedSeatId}
                            disabled={!isSelectable(seat)}
                            onChange={(e) =>
                              handleSelectRecentSeat(
                                e,
                                seat.id,
                                layoutResponse.value.branch.branch_name,
                                layoutResponse.value.floor_name,
                                seat.seat_name
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sx={{
                            color: !isSelectable(seat)
                              ? "rgba(0, 0, 0, 0.26)"
                              : "",
                          }}
                        >
                          <Typography
                            variant="body2"
                            mr="0.5rem"
                            display="inline"
                          >
                            {layoutResponse.value.branch.branch_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            mr="1rem"
                            display="inline"
                          >
                            {layoutResponse.value.floor_name}
                          </Typography>
                          <Typography variant="body2" display="inline">
                            {seat.seat_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </AllSeatsContentGrid>
            )}
            <AllRoomsContentGrid container mt={2.5}>
              <Grid item xs={2.5} mt={1.5}>
                <Typography variant="body2">
                  {translations.MeetingRooms}：
                </Typography>
              </Grid>
              <Grid item xs={9.5}>
                {sortedMeetingRooms.map((meetingRoom, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Grid item xs={2}>
                        <Checkbox
                          size="small"
                          checked={meetingRoom.id === checkedMeetingRoomId}
                          onChange={(e) =>
                            handleSelectRecentMeetingRoom(
                              e,
                              meetingRoom.id,
                              meetingRoom.floor.branch.branch_name,
                              meetingRoom.floor.floor_number,
                              meetingRoom.meeting_room_name,
                              meetingRoom.capacity
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body2"
                          mr="0.5rem"
                          display="inline"
                        >
                          {meetingRoom.floor.branch.branch_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          mr="0.5rem"
                          display="inline"
                        >
                          {meetingRoom.floor.floor_number}F
                        </Typography>
                        <Typography
                          variant="body2"
                          mr="0.5rem"
                          display="inline"
                        >
                          {meetingRoom.meeting_room_name}
                        </Typography>
                        <Typography variant="body2" display="inline">
                          {meetingRoom.capacity}
                          {translations.Capacity}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </AllRoomsContentGrid>
          </Box>
          <CustomFooterBox>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleLayoutOpen}
            >
              {translations.FloorMap}
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                handleFixPlace()
                addPlaceModalClose()
              }}
            >
              {translations.Confirm}
            </Button>
          </CustomFooterBox>
        </CustomModalBox>
      )}
    </>
  )
}
