import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  fetchPaidRequest,
  createPaidRequest,
} from "../../../api/company/paidRequest"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { FULL_WIDTH_KATAKANA } from "../../../utils/regular"
import { BuyerStatus, Paid } from "./type"

export enum BuyerStatusEnum {
  UNKNOWN, // 未登録
  UNDER_EXAMINARION, // 審査中
  TRADEABLE, // 取引可能
  NOT_TRADEABLE, // 取引不可
  NOT_AVAULABLE, // 利用不可
}

export const usePaid = () => {
  const [paid, setPaid] = useState<Paid>()

  const [buyerStatus, setBuyerStatus] = useState<BuyerStatusEnum>(
    BuyerStatusEnum.UNKNOWN
  )

  const navigate = useNavigate()

  const paidErrorMessage = useAlertMessage()

  const basicSchema = Yup.object().shape({
    company_name: Yup.string().required("必須になります"),
    company_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    representative_sei: Yup.string().required("必須になります"),
    representative_mei: Yup.string().required("必須になります"),
    representative_sei_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    representative_mei_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    zip_code_1: Yup.string().required("必須になります"),
    zip_code_2: Yup.string().required("必須になります"),
    prefecture: Yup.string().required("必須になります"),
    address1: Yup.string().required("必須になります"),
    address2: Yup.string().required("必須になります"),
    clerk_sei: Yup.string().required("必須になります"),
    clerk_mei: Yup.string().required("必須になります"),
    clerk_sei_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    clerk_mei_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    tel_1: Yup.string().required("必須になります"),
    tel_2: Yup.string().required("必須になります"),
    tel_3: Yup.string().required("必須になります"),
    email: Yup.string().required("必須になります"),
    payment_method: Yup.number().required("必須になります"),
  })

  const form = useForm<Paid>({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
  })

  const onCreatePaid = form.handleSubmit(async (data) => {
    try {
      const combineZipCode = `${data.zip_code_1}-${data.zip_code_2}`
      const combineTel = `${data.tel_1}-${data.tel_2}-${data.tel_3}`
      const combineFax =
        data.fax_1 && data.fax_2 && data.fax_3
          ? `${data.fax_1}-${data.fax_2}-${data.fax_3}`
          : ""
      const combineMobileTel =
        data.mobile_tel_1 && data.mobile_tel_2 && data.mobile_tel_3
          ? `${data.mobile_tel_1}-${data.mobile_tel_2}-${data.mobile_tel_3}`
          : ""

      const response = await createPaidRequest({
        ...data,
        zip_code: combineZipCode,
        tel: combineTel,
        fax: combineFax,
        mobile_tel: combineMobileTel,
      })

      if (response.status == 204) {
        localStorage.setItem("alertContent", "Paidの取引先登録をしました")
        navigate(`/company/settings/payment`)
        window.location.reload()
      } else {
        paidErrorMessage.handleSetMessage("取引先の登録に失敗しました")
        paidErrorMessage.openMessage()
      }
    } catch (e) {
      console.log("error")
      paidErrorMessage.handleSetMessage("取引先の登録に失敗しました")
      paidErrorMessage.openMessage()
    }
  })

  const onJudgingPaid = async () => {
    try {
      const response = await fetchPaidRequest()
      if (response.data && response.status === 200) {
        setBuyerStatus(response.data)
      } else {
        setBuyerStatus(BuyerStatusEnum.UNKNOWN)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    form,
    onCreatePaid,
    paidErrorMessage,
    onJudgingPaid,
    buyerStatus,
    paid,
  }
}
