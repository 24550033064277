import React, { useEffect } from "react"

import { EmployeeList } from "../../../components/company/employee/CompanyEmployeeList"
import { AlertContent } from "../../../components/public/AlertContent"
import { AlertError } from "../../../components/public/AlertError"
import { AlertSuccess } from "../../../components/public/AlertSuccess"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useEmployeeCsv } from "../../../models/company/useEmployeeCSV"
import { useSearchEmployeeList } from "../../../models/company/useEmployeeListSearch/index"

export const CompanyEmployeeListPage = () => {
  const csvObject = useEmployeeCsv()
  const searchObject = useSearchEmployeeList()

  useEffect(() => {
    searchObject.initializeEmployeeList()
  }, [])

  const loading = searchObject.loading || csvObject.loading

  return (
    <>
      <Loading type="content" loading={loading} />
      <ContentTemplate>
        <ContentCompanyPaper>
          <EmployeeList
            {...searchObject}
            downloadSampleCsv={csvObject.downloadSampleCsv}
            downloadCsv={csvObject.downloadCsv}
            uploadCsv={csvObject.uploadCsv}
          />
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertContent />
      <AlertSuccess
        isMessageOpen={searchObject.successMessageOpen}
        alertMessage={searchObject.successMessage}
        closeMessage={() => searchObject.setSuccessMessageOpen(false)}
      />
      <AlertSuccess
        isMessageOpen={csvObject.uploading}
        alertMessage={csvObject.uploadingMessage}
        closeMessage={() => csvObject.setUpLoading(false)}
      />
      <AlertError
        isMessageOpen={csvObject.failureMessageOpen}
        alertMessage={csvObject.failureMessage}
        closeMessage={() => csvObject.setFailureMessageOpen(false)}
      />
      <AlertError
        isMessageOpen={searchObject.pausedErrorMessage.isOpenMessage}
        closeMessage={searchObject.pausedErrorMessage.closeMessage}
        alertMessage={searchObject.pausedErrorMessage.alertMessage}
      />
    </>
  )
}
