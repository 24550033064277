// スケジュール予約/席予約
const ja = {
  SpaceBooking: "席予約",
  Location: "拠点",
  Floor: "フロア",
  Spaces: "会議室一覧",
  Desks: "レイアウト",
  SelectedSpace: "対象席",
  Cancel: "キャンセル",
  ConfirmReservation: "予約する",
  SpaceNo: "会議室コード",
  spaceName: "会議室名",
  Capacity: "定員",
  Enter: "決定",
  MeetingSpace: "会議スペース",
  SpaceName: "スペース名",
  CapacityNumber: "定員数",
  Equipment: "機器",
  Description: "説明",
  Selected: "選択済み",
}
export default ja
