import React, { useState, useEffect } from "react"

import { Box } from "@mui/material"

import { ResavableSeatType } from "../../../../models/company/useLayout/type"
import {
  freeSeatStyle,
  reservedSeatStyle,
  unavailableStyle,
} from "../../../../styles/seatStyle"

interface Props {
  seat: ResavableSeatType
  reservedSeatId: number
  onReservedSeatClick: (seatId: number | null, seatName: number) => void
}

// 座席オブジェクト
export const LayoutSeatObject: React.FC<Props> = ({
  seat,
  reservedSeatId,
  onReservedSeatClick,
}: Props) => {
  // eslint-disable-next-line
  const [seatTarget, setSeatTarget] = useState<HTMLElement | null>()
  const [seatStyle, setSeatStyle] = useState<React.CSSProperties | undefined>(
    undefined
  )
  const seatId = seat.id
  const seatType = seat.seat_type
  const seatName = seat.seat_name

  useEffect(() => {
    setSeatTarget(document.getElementById(String(seat.id)))
  }, [seatId])

  useEffect(() => {
    setSeatStyle(generateStyleObject as React.CSSProperties)
  }, [seatType, reservedSeatId])

  const generateStyleObject = () => {
    switch (seatType) {
      case 0:
        return {
          ...reservedSeatStyle,
          top: seat.y,
          left: seat.x,
          backgroundColor: seatId === reservedSeatId ? "#F4B461" : "#22BA9D",
        }
      case 1:
        return { ...freeSeatStyle, top: seat.y, left: seat.x }
      case 2:
        return { ...unavailableStyle, top: seat.y, left: seat.x }
      default:
        return { ...freeSeatStyle, top: seat.y, left: seat.x }
    }
  }

  return (
    <>
      <Box
        id={String(seatId)}
        className={"seat"}
        sx={seatStyle}
        onClick={() => {
          if (!seat.employee.icon) onReservedSeatClick(seatId, Number(seatName))
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {seat.employee.employee_id ? (
            <>
              {seat.employee.icon ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src={seat.employee.icon}
                />
              ) : (
                <span
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {seat.employee.last_name[0]}
                  {seat.employee.first_name[0]}
                </span>
              )}
            </>
          ) : (
            <p>{seatName}</p>
          )}
        </div>
      </Box>
    </>
  )
}
