import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useRef, useState, useEffect } from "react"

import { Box, styled } from "@mui/material"

import {
  AreaType,
  MeetingRoomType,
  LayoutType,
} from "../../../models/company/useDashboard/type"
import { SeatType } from "../../../models/company/useDashboard/type"
import { CompanyRelationsType } from "../../../models/public/useCompanyRelations/type"
import { EmployeeHomeLayoutArea } from "./EmployeeHomeLayoutArea"
import { EmployeeHomeLayoutMeetingRoom } from "./EmployeeHomeLayoutMeetingRoom"
import { EmployeeHomeLayoutSeat } from "./EmployeeHomeLayoutSeat"

const TransformContainer = styled(Box)(() => ({
  height: "calc(100vh - 370px)",
  overflow: "auto",
}))

interface Props {
  layoutImg: string
  layout: LayoutType
  seats: SeatType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
  companyRelations: CompanyRelationsType
  selectedBranch: string
  selectedFloor: string
}

// レイアウト表示部分
export const EmployeeHomeLayoutContentComponent: React.FC<Props> = ({
  layoutImg,
  layout,
  seats,
  meetingRooms,
  areas,
  companyRelations,
  selectedBranch,
  selectedFloor,
}: Props) => {
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [x, setX] = useState<number>(layout.x)
  const [y, setY] = useState<number>(layout.y)
  const [scale, setScale] = useState<number>(layout.scale)

  useEffect(() => {
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout])

  useEffect(() => {
    layoutImageRef.current?.setTransform(x, y, scale)
  }, [x, y, scale])

  return (
    <>
      <TransformContainer>
        <TransformWrapper
          disabled={false}
          minScale={0.1}
          maxScale={1.0}
          wheel={{ disabled: false, step: 0.05 }}
          panning={{
            disabled: false,
          }}
          initialPositionX={x}
          initialPositionY={y}
          initialScale={scale}
          ref={layoutImageRef}
        >
          <TransformComponent
            wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <img id="layout-image" src={layoutImg} />
            {seats.map((seat) => {
              return (
                <EmployeeHomeLayoutSeat
                  key={`seat${seat.id}`}
                  seat={seat}
                  companyRelations={companyRelations}
                  selectedBranch={selectedBranch}
                  selectedFloor={selectedFloor}
                />
              )
            })}
            {meetingRooms.map((meetingRoom) => {
              return (
                <EmployeeHomeLayoutMeetingRoom
                  key={`meetingRoom${meetingRoom.id}`}
                  meetingRoom={meetingRoom}
                />
              )
            })}
            {areas.map((area) => {
              return [
                <EmployeeHomeLayoutArea key={`area${area.id}`} area={area} />,
                ...area.children.map((seat) => {
                  return (
                    <EmployeeHomeLayoutSeat
                      key={`seat${seat.id}`}
                      seat={seat}
                      companyRelations={companyRelations}
                      selectedBranch={selectedBranch}
                      selectedFloor={selectedFloor}
                    />
                  )
                }),
              ]
            })}
          </TransformComponent>
        </TransformWrapper>
      </TransformContainer>
    </>
  )
}
