const en = {
  LookingFavoriteMembers: "Search by favorite team",
  NoFavoriteTeams: "You have no favorite teams",
  UnselecTeams: "Unselect all favorite teams",
  LookingSomeone: "Looking for someone?",
  UnselectAllMembers: "Unselect all members",
  fiftyMembers: "You can search up to 50 members at a time",
  WhereMember: "Search by business unit/location",
  UnselectBusinessUnit: "Unselect all business unit.",
  AreYouLooking: "Search by name",
  AddMembers: "Add members you are looking for",
  UnselectMembers: "Unselect all members",
  AddKanjiEmail: "Add members (enter Kanji, email)",
  status: [
    "Work from home",
    "Out of office",
    "Business Trip",
    "DAY OFF/TIME OFF",
  ],
  month: " / ",
  day: "",
  weekdays: ["(Sun)", "(Mon)", "(Tue)", "(Wed)", "(Thu)", "(Fri)", "(Sat)"],
}
export default en
