import React from "react"

import { AuthPageTemplate } from "../../components/public/AuthPageTemplate"
import { SignageForgotPasswordAppliedMessage } from "../../components/signage/login/ForgotPasswordAppliedMessage"

export const SignageForgotPasswordApplied = () => {
  return (
    <AuthPageTemplate loading={false}>
      <SignageForgotPasswordAppliedMessage />
    </AuthPageTemplate>
  )
}
