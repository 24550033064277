// 定数管理したいものはここへ

// 住所自動入力のAPIのエンドポイント
export const POSTAL_CODE_SEARCH_ENDPOINT =
  "https://zipcloud.ibsnet.co.jp/api/search"
export const DAY_CALENDAR_PATHNAME = "/employee/calendar/days"
export const WEEK_CALENDAR_PATHNAME = "/employee/calendar/weeks"
export const MONTH_CALENDAR_PATHNAME = "/employee/calendar/months"
export const MOBILE_DAY_CALENDAR_PATHNAME = "/mobile/day-calendar"
export const MOBILE_WEEK_CALENDAR_PATHNAME = "/mobile/home"
export const LAYOUT_PAGE_PATHNAME = [
  "/employee/layouts",
  "/employee/layouts/status",
  "/employee/calendar/layouts",
]
export const EARLY_CHECK_IN_INTERVAL = 15 // HOMEで何分前からチェックインできるか。バックエンドでも同じ名前の定数を使ってます。変更可能性あり
export const ENCRYPTION_KEY = "WORK AGILE ENCRYPTION KEY" // パスワードリセットの際に認証コードの暗号化に用いている鍵(取り扱い注意)
export const RETURN_TO_TOP_PAGE_SECONDS = 30 //サイネージ受付で何秒後にTOPページに戻るか。法人管理画面で設定できるようにする予定
export const SIGNAGE_FLOOR_RELOAD_MINUTES = 5 //サイネージフロアで一定時間経過後にリロードさせる
export const MAX_NUMBER_OF_SEARCH = 50 //スマホのチームサーチで一度に検索できる上限人数
export const CONTENT_PAPER_MIN_HEIGHT = 600
export const REPORT_FLOAT_DIGIT = -2 // レポート機能では少数第2位を四捨五入する
