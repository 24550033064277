import { useState } from "react"

import {
  fetchPasswordExpireMonthesRequest,
  updatePasswordExpireMonthesRequest,
} from "../../../api/company/passwordExpireMonthesRequest"

export const usePasswordExpireMonthes = () => {
  const [passwordExpireMonthes, setPasswordExpireMonthes] = useState<number>(3)
  const [passwordExpireMonthesId, setPasswordExpireMonthesId] =
    useState<number>(0)

  const handleSetPasswordExpireMonthes = (monthes: number) => {
    setPasswordExpireMonthes(monthes)
  }

  const handleSetPasswordExpireMonthesId = (account_id: number) => {
    setPasswordExpireMonthes(account_id)
  }

  const fetchPasswordExpireMonthes = async () => {
    try {
      const response = await fetchPasswordExpireMonthesRequest()
      if (response.data && response.status === 200) {
        handleSetPasswordExpireMonthesId(response.data.id)
        handleSetPasswordExpireMonthes(response.data.password_expire_monthes)
      }
    } catch (e) {
      console.log("error")
    }
  }

  const updatePasswordExpireMonthes = async (newExpireMonthes: number) => {
    try {
      const response = await updatePasswordExpireMonthesRequest({
        id: passwordExpireMonthesId,
        password_expire_monthes: newExpireMonthes,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "パスワード有効期限を変更しました")
        setPasswordExpireMonthes(newExpireMonthes)
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    passwordExpireMonthes,
    setPasswordExpireMonthes,
    fetchPasswordExpireMonthes,
    updatePasswordExpireMonthes,
  }
}
