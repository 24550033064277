const ja = {
  schedule: "予定表",
  Schedule: "予定",
  All: "全て",
  Desks: "席",
  Events: "スケジュール",
  Calendar: "カレンダー",
  Year: "年",
  month: "月",
  monthCalendar: "月",
  Month: "月",
  week: "週",
  Week: "週",
  day: "日",
  Day: "日",
  weekdays: ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"],
}
export default ja
