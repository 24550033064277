import React from "react"
import { Route, Routes } from "react-router-dom"

import { ErrorPage } from "../pages/ErrorPage"
import { FederatedSignInPage } from "../pages/employee/auth/FederatedSignInPage"
import { MobileCommonTemplate } from "../pages/mobile/MobileCommonTemplate"
import { MobileMFA } from "../pages/mobile/auth/MFA"
import { MobileConfirmForgotPassword } from "../pages/mobile/auth/confirmForgotPassword"
import { MobileForgotPassword } from "../pages/mobile/auth/forgotPassword"
import { MobileForgotPasswordApplied } from "../pages/mobile/auth/forgotPasswordApplied"
import { MobileLogin } from "../pages/mobile/auth/login"
import { MobilePasswordChange } from "../pages/mobile/auth/passwordChange"
import { CheckInPage } from "../pages/mobile/checkIn"
import { MobileCheckInMFA } from "../pages/mobile/checkIn/MFA"
import { CheckInReservationSeatPage } from "../pages/mobile/checkIn/ReservationSeat"
import { CheckInAlreadyCheckedInPage } from "../pages/mobile/checkIn/alreadyCheckedIn"
import { CheckInAlreadyReservedPage } from "../pages/mobile/checkIn/alreadyReserved"
import { CheckInCompletedPage } from "../pages/mobile/checkIn/completed"
import { MobileCheckInLogin } from "../pages/mobile/checkIn/login"
import { MobileCheckInPasswordChange } from "../pages/mobile/checkIn/passwordChange"
import { CheckOutPage } from "../pages/mobile/checkOut"
import { CheckOutCompletedPage } from "../pages/mobile/checkOut/completed"
import { MobileDayCalendarPage } from "../pages/mobile/dayCalendar"
import { MobileHomePage } from "../pages/mobile/home"
import { MobileLanguageChangePage } from "../pages/mobile/languageChange"
import { MobileLayoutPage } from "../pages/mobile/layouts"
import { MobileEmployeeProfileShowPage } from "../pages/mobile/profile"
import { MobileTeamSearchPage } from "../pages/mobile/team_search"
import { MobilePrivateRoute } from "./MobilePrivateRoute"
import { MobilePublicRoute } from "./MobilePublicRoute"

export const MobileRouter: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path="/home"
          element={
            <MobilePrivateRoute
              path="/home"
              element={
                <MobileCommonTemplate>
                  <MobileHomePage />
                </MobileCommonTemplate>
              }
            />
          }
        />
        <Route
          path="/layouts"
          element={
            <MobilePrivateRoute
              path="/layouts"
              element={
                <MobileCommonTemplate>
                  <MobileLayoutPage />
                </MobileCommonTemplate>
              }
            />
          }
        />
        <Route
          path="/profile"
          element={
            <MobilePrivateRoute
              path="/profile"
              element={
                <MobileCommonTemplate>
                  <MobileEmployeeProfileShowPage />
                </MobileCommonTemplate>
              }
            />
          }
        />
        <Route
          path="/languageChange"
          element={
            <MobilePrivateRoute
              path="/languageChange"
              element={
                <MobileCommonTemplate>
                  <MobileLanguageChangePage />
                </MobileCommonTemplate>
              }
            />
          }
        />
        <Route
          path="/team-search"
          element={
            <MobilePrivateRoute
              path="/team-search"
              element={
                <MobileCommonTemplate>
                  <MobileTeamSearchPage />
                </MobileCommonTemplate>
              }
            />
          }
        />
        <Route
          path="/day-calendar"
          element={
            <MobilePrivateRoute
              path="/day-calendar"
              element={
                <MobileCommonTemplate>
                  <MobileDayCalendarPage />
                </MobileCommonTemplate>
              }
            />
          }
        />
        <Route path="/check-in" element={<CheckInPage />} />
        <Route path="/check-in/completed" element={<CheckInCompletedPage />} />
        <Route path="/check-out" element={<CheckOutPage />} />
        <Route
          path="/check-out/completed"
          element={<CheckOutCompletedPage />}
        />
        <Route
          path="/check-in/already-reserved"
          element={<CheckInAlreadyReservedPage />}
        />
        <Route
          path="/check-in/reservation-seat"
          element={<CheckInReservationSeatPage />}
        />
        <Route
          path="/check-in/already-checked-in"
          element={<CheckInAlreadyCheckedInPage />}
        />
        <Route path="/federated-sign-in" element={<FederatedSignInPage />} />
        <Route
          path="/login"
          element={
            <MobilePublicRoute path="/login" element={<MobileLogin />} />
          }
        />
        <Route
          path="/mfa"
          element={<MobilePublicRoute path="/login" element={<MobileMFA />} />}
        />
        <Route
          path="/forgot-password"
          element={
            <MobilePublicRoute
              path="/forgot-password"
              element={<MobileForgotPassword />}
            />
          }
        />
        <Route
          path="/forgot-password/applied"
          element={
            <MobilePublicRoute
              path="/forgot-password/applied"
              element={<MobileForgotPasswordApplied />}
            />
          }
        />
        <Route
          path="/forgot-password/confirm"
          element={
            <MobilePublicRoute
              path="/forgot-password/confirm"
              element={<MobileConfirmForgotPassword />}
            />
          }
        />
        <Route path="/password-change" element={<MobilePasswordChange />} />
        <Route
          path="/check-in/login"
          element={
            <MobilePublicRoute path="/login" element={<MobileCheckInLogin />} />
          }
        />
        <Route
          path="/check-in/mfa"
          element={
            <MobilePublicRoute
              path="/check-in/mfa"
              element={<MobileCheckInMFA />}
            />
          }
        />
        <Route
          path="/check-in/password-change"
          element={
            <MobilePublicRoute
              path="/check-in/password-change"
              element={<MobileCheckInPasswordChange />}
            />
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}
