import {
  FetchAreaResponseType,
  FetchAreasRequestType,
} from "../../models/company/useAreas/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchAreasRequest = async (
  params: FetchAreasRequestType
): Promise<ApiResponse<FetchAreaResponseType>> => {
  try {
    const data = await api.get("/company/areas", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
