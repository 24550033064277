import React, { memo } from "react"

import { Button, styled } from "@mui/material"

export const CustomButton = styled(Button)({
  height: "40px",
  width: "216px",
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  borderRadius: "6px",
  textTransform: "none",
})

interface Props {
  label: string
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  style?: React.CSSProperties
}

export const ReflectButton: React.FC<Props> = memo(
  ({ label, handleClick, disabled = false, style }: Props) => {
    return (
      <CustomButton
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={disabled}
        style={style}
      >
        {label}
      </CustomButton>
    )
  }
)
