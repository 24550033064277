import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { CompanyTeamForm } from "../../../components/company/teams/CompanyTeamForm"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useTeam } from "../../../models/company/useTeam"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CompanyTeamUpdatePage = () => {
  const teamObject = useTeam()
  const { companyRelations } = useCompanyRelations()
  const urlParams = useParams<{ id: string }>()
  const selectedTeamId = Number(urlParams.id)

  useEffect(() => {
    teamObject.editTeam(selectedTeamId)
    teamObject.fetchTeamHierarchyList()
  }, [])

  return (
    <ContentTemplate>
      <ContentCompanyPaper>
        {teamObject.loading ? (
          <Loading type="content" loading={teamObject.loading} />
        ) : (
          <CompanyTeamForm
            {...teamObject}
            companyRelations={companyRelations}
          />
        )}
      </ContentCompanyPaper>
    </ContentTemplate>
  )
}
