// import { DateTimeScheduleRequestType } from "../../models/employee/useSchedule/dateTime/type"
import {
  ScheduleResponseDayType,
  AcceptStatusRequestType,
  ScheduleType,
} from "../../models/employee/useSchedule/days/type"
import {
  MeetingRoomScheduleRequestType,
  MeetingRoomScheduleResponseType,
} from "../../models/employee/useSchedule/meetingRooms/type"
import {
  ScheduleRequestMonthType,
  ScheduleResponseMonthType,
} from "../../models/employee/useSchedule/months/type"
import {
  Schedule,
  SearchScheduleRequestType,
} from "../../models/employee/useSchedule/searchPlace/type"
import {
  FetchMeetingRoomScheduleShowFunction,
  ScheduleInformations,
} from "../../models/employee/useSchedule/show/type"
import { EmployeeScheduleRequestType } from "../../models/employee/useSchedule/type"
import { ReserveResentSeatType } from "../../models/employee/useSchedule/useReserveSeat/type"
import {
  ScheduleForm,
  ScheduleFormResponseType,
} from "../../models/employee/useSchedule/useScheduleForm/type"
import {
  MyScheduleRequestType,
  ScheduleHomeResponseType,
  ScheduleHomeType,
} from "../../models/employee/useSchedule/useScheduleHome/type"
import { ScheduleNewMessage } from "../../models/employee/useSchedule/useScheduleNewMessage/type"
import {
  ScheduleShowDataType,
  ScheduleStatusRequestType,
} from "../../models/employee/useSchedule/useScheduleStatus/type"
import {
  UserableSearchGuestResponse,
  UserableSearchResponse,
} from "../../models/employee/useSchedule/useScheduleUserableSearch/type"
import {
  MicrosoftEvents,
  ScheduleResponseWeekType,
} from "../../models/employee/useSchedule/weeks/type"
import {
  CheckInRequestType,
  CheckInResponseType,
  CheckInStatusRequestType,
  CheckInStatusResponseType,
  InstantCheckInRequestType,
} from "../../models/mobile/useSchedule/checkIn/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchScheduleDayRequest = async (
  params: EmployeeScheduleRequestType
): Promise<ApiResponse<ScheduleResponseDayType[]>> => {
  try {
    const data = await api.get("/employee/schedules/day", {
      params: {
        start_date: params.start_date,
        end_date: params.end_date,
        employee_ids: JSON.stringify(params.employee_ids),
      },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchScheduleWeekRequest = async (
  params: EmployeeScheduleRequestType
): Promise<ApiResponse<ScheduleResponseWeekType[]>> => {
  try {
    const data = await api.get("/employee/schedules/week", {
      params: {
        start_date: params.start_date,
        end_date: params.end_date,
        employee_ids: JSON.stringify(params.employee_ids),
      },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchScheduleMonthRequest = async (
  params: ScheduleRequestMonthType
): Promise<ApiResponse<ScheduleResponseMonthType>> => {
  try {
    const data = await api.get("/employee/schedules/month", {
      params: {
        start_date: params.start_date,
        end_date: params.end_date,
      },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// 会議室の予定表を参照する
export const fetchMeetingRoomScheduleRequest = async (
  params: MeetingRoomScheduleRequestType
): Promise<ApiResponse<MeetingRoomScheduleResponseType>> => {
  try {
    const data = await api.get("/employee/schedules/meeting_rooms", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// 会議室予約から予定表を詳細を参照する
export const showMeetingRoomScheduleRequest = async (
  scheduleInformationId: number
): Promise<ApiResponse<FetchMeetingRoomScheduleShowFunction>> => {
  try {
    const data = await api.get("employee/schedules/meeting_room_show", {
      params: {
        id: scheduleInformationId,
      },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const newScheduleRequest = async (
  params: ScheduleForm
): Promise<ApiResponse<ScheduleFormResponseType>> => {
  try {
    const data = await api.post("/employee/schedules", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const fetchScheduleInformationShowDataRequest = async (
  scheduleInformationIds: number[],
  scheduleDate: Date,
  scheduleIds: number[],
  schedules:
    | ScheduleType[]
    | MicrosoftEvents
    | ScheduleHomeType[]
    | ScheduleNewMessage[],
  employeeId?: number
): Promise<ApiResponse<ScheduleInformations>> => {
  try {
    const data = await api.post(
      `/employee/schedules/${scheduleInformationIds[0]}/show`,
      {
        schedule_date: scheduleDate,
        schedule_ids: scheduleIds,
        schedule_information_ids: scheduleInformationIds,
        schedules: schedules,
        employee_id: employeeId,
      }
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const editScheduleRequest = async (
  params: ScheduleForm
): Promise<ApiResponse<ScheduleFormResponseType>> => {
  try {
    const data = await api.patch(
      `/employee/schedules/${params.schedule.id}`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

// // 日付と時刻と人をベースに予約された場所を検索する
export const searchPlaceRequest = async (
  params: SearchScheduleRequestType
): Promise<ApiResponse<Schedule>> => {
  try {
    const data = await api.get("/employee/schedules/search_place", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateApproveRequest = async (
  params: AcceptStatusRequestType
): Promise<ApiResponse<ScheduleFormResponseType>> => {
  try {
    const { data } = await api.patch(
      `/employee/schedules/${params?.id}/update_accept_status`,
      params
    )
    return { data: data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

// QRコードを読み取ってチェックインを行う
export const checkInRequest = async (
  params: CheckInRequestType
): Promise<ApiResponse<CheckInResponseType>> => {
  try {
    const data = await api.post("/employee/schedules/check_in", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// QRコードを読み取って終業までチェックインを行う
export const checkInEndOfShiftRequest = async (
  params: CheckInRequestType
): Promise<ApiResponse<CheckInResponseType>> => {
  try {
    const data = await api.post(
      "/employee/schedules/check_in_end_of_shift",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// QRコードを読み取って終業までチェックインを行う
export const checkInMobileRequest = async (
  params: CheckInRequestType
): Promise<ApiResponse<CheckInResponseType>> => {
  try {
    const data = await api.post("/employee/schedules/mobile_check_in", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// QRコードを読み取ってチェックアウトを行う
export const checkOutRequest = async (
  params: CheckInRequestType
): Promise<ApiResponse<CheckInResponseType>> => {
  try {
    const data = await api.post("/employee/schedules/check_out", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const fetchMyScheduleRequest = async (
  params: MyScheduleRequestType
): Promise<ApiResponse<ScheduleHomeResponseType>> => {
  try {
    const data = await api.get("/employee/schedules/me", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// 読み取ったQRコードに予約が入っていない場合にその場で予約・チェックインを行う
export const instantCheckInRequest = async (
  params: InstantCheckInRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/employee/schedules/instant_check_in", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const createReserveRecentSeatRequest = async (
  params: ReserveResentSeatType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/employee/schedules/resent_seat", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateReserveRecentSeatRequest = async (
  params: ReserveResentSeatType,
  scheduleId: number
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/employee/schedules/${scheduleId}/resent_seat`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const createScheduleStatusRequest = async (
  params: ScheduleStatusRequestType
): Promise<ApiResponse<ScheduleFormResponseType>> => {
  try {
    const data = await api.post("/employee/schedules/status", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateScheduleStatusRequest = async (
  params: ScheduleStatusRequestType,
  scheduleId: number
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/employee/schedules/${scheduleId}/status`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const fetchScheduleShowDataRequest = async (
  scheduleId: number
): Promise<ApiResponse<ScheduleShowDataType>> => {
  try {
    const data = await api.get(`/employee/schedules/${scheduleId}/status`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const scheduleUserableSearchRequest = async (
  params: string
): Promise<ApiResponse<UserableSearchResponse>> => {
  try {
    const data = await api.get(`/employee/schedules/search`, {
      params: { keyword: params },
    })

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const scheduleUserableGuestSearchRequest = async (
  params: string
): Promise<ApiResponse<UserableSearchGuestResponse>> => {
  try {
    const data = await api.get(`/employee/schedules/guest_search`, {
      params: { keyword: params },
    })

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const fetchScheduleNewMessageRequest = async (): Promise<
  ApiResponse<ScheduleNewMessage[]>
> => {
  try {
    const data = await api.get("/employee/schedules/new_messsage")

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const deleteScheduleRequest = async (
  schedule_information_id: number,
  delete_flag: "schedule" | "after_schedule" | "all_schedule",
  date: Date
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.delete(
      `/employee/schedules/${schedule_information_id}`,
      { params: { delete_flag: delete_flag, date: date } }
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

/** 当該座席/会議室を当日本人がCheckIn済か確認するためのリクエスト */
export const fetchCheckInStatusRequest = async (
  params: CheckInStatusRequestType
): Promise<ApiResponse<CheckInStatusResponseType>> => {
  try {
    const data = await api.get(`/employee/schedules/check_status`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
