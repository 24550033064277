import React, { useMemo, useState } from "react"

import { Box, Tooltip, Typography } from "@mui/material"

import { SeatType } from "../../../../models/company/useReport/type"
import {
  freeSeatStyle,
  heatmapColorThresholds,
} from "../../../../styles/seatStyle"

type SeatProps = {
  seat: SeatType
}

// 座席オブジェクト
const SeatObject = (props: SeatProps) => {
  const { seat } = props
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const generateStyleObject = (x: number, y: number, rate: number) => {
    const objectStyle = freeSeatStyle

    let borderColor
    if (rate >= 0 && rate <= 20) {
      borderColor = heatmapColorThresholds.veryLow
    } else if (rate > 20 && rate <= 40) {
      borderColor = heatmapColorThresholds.low
    } else if (rate > 40 && rate <= 60) {
      borderColor = heatmapColorThresholds.middle
    } else if (rate > 60 && rate <= 80) {
      borderColor = heatmapColorThresholds.high
    } else if (rate > 80) {
      borderColor = heatmapColorThresholds.veryHigh
    }

    const boxShadow = `0 0 5px 5px ${borderColor}aa`

    return {
      ...objectStyle,
      top: y,
      left: x,
      border: "solid 10px",
      borderColor: borderColor,
      boxShadow: boxShadow,
    }
  }

  const tooltipText = () => {
    const seatInfo = `座席番号: ${seat.seat_name}\n`
    const operatingRate = `稼働率: ${seat.operating_rate}%\n`

    return `${seatInfo}${operatingRate}`
  }

  return (
    <>
      <Box
        id={String(seat.id)}
        className={"seat"}
        sx={
          generateStyleObject(seat.x, seat.y, seat.operating_rate) as
            | React.CSSProperties
            | undefined
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={tooltipText()}
            placement="bottom"
            disableInteractive
            open={tooltipOpen}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: "pre-wrap",
                  position: "fixed",
                  width: "100px",
                },
              },
            }}
          >
            <Typography
              sx={{ fontSize: "20px" }}
              color="inherit"
              display="flex"
            >
              {seat.seat_name}
            </Typography>
          </Tooltip>
        </div>
      </Box>
    </>
  )
}

interface Props {
  seat: SeatType
}

/*
  レイアウト画像の登録フォームで席のアイコンが表示される
*/
export const HeatmapLayoutSeat: React.FC<Props> = ({ seat }: Props) => {
  return (
    <>
      <SeatObject seat={seat} />
    </>
  )
}
