import React from "react"

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
} from "@mui/material"

const tableFont = {
  color: "#22BA9D",
  fontSize: "14px",
  fontWeight: "bold",
  paddingTop: "30px",
  width: "4%",
}

interface Props {
  teamAttendanceList?: {
    id: number
    team_name: string
    team_name_kana: string
    team_leader_name: string
    attendance_rate: number
    reserved_rate: number
    canceled_rate: number
  }[]
}

export const TeamAttendanceRate: React.FC<Props> = ({
  teamAttendanceList,
}: Props) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={tableFont}
              sx={{ paddingLeft: "30px", width: "8%" }}
            >
              所属名
            </TableCell>
            <TableCell style={tableFont} sx={{ width: "8%" }}>
              所属名（カナ）
            </TableCell>
            <TableCell style={tableFont} sx={{ width: "8%" }}>
              所属長
            </TableCell>
            <TableCell style={tableFont}>出社率</TableCell>
            <TableCell style={tableFont}>予約率</TableCell>
            <TableCell style={tableFont}>予約キャンセル率</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamAttendanceList &&
            teamAttendanceList.length > 0 &&
            teamAttendanceList.map((report, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.team_name}
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.team_name_kana}
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.team_leader_name}
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.attendance_rate}%
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.reserved_rate}%
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.canceled_rate}%
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      {!teamAttendanceList ||
        (teamAttendanceList.length === 0 && (
          <Box mt="2rem" width="100%" marginBottom="570px">
            <Typography textAlign="center">
              表示するデータがありません
            </Typography>
          </Box>
        ))}
    </>
  )
}
