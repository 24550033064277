import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Box } from "@mui/material"

import { useEmployeeGroup } from "../../../../../models/employee/useEmployeeGroup"
import { useScheduleSearchEmployee } from "../../../../../models/employee/useSchedule/scheduleSearchEmployeeForm/index"
import { EmployeesType } from "../../../../../models/employee/useSchedule/type"
import { useWeekSchedule } from "../../../../../models/employee/useSchedule/weeks"
import { SchedulesTable } from "../../../../../models/employee/useSchedule/weeks/type"
import { SchedulableMaxDateProvider } from "../../../../../providers/SchedulableMaxDateProvider"
import { oneWeek } from "../../../../../utils/oneWeek"
import { weekStatus } from "../../../../../utils/weeks"
import { Loading } from "../../../../public/Loading"
import { EmployeeScheduleSideSearch } from "../../common/EmployeeScheduleSideSearch"
import { EmployeeScheduleDayLine } from "../EmployeeScheduleDayLine"
import { EmployeeScheduleWeekHeader } from "../EmployeeScheduleWeekHeader"
import { EmployeeScheduleWeekSideBar } from "../EmployeeScheduleWeekSideBar"
import { EmployeeScheduleWeekTable } from "../EmployeeScheduleWeekTable"

interface LocationState {
  displayReserved: boolean
  displayNonReserved: boolean
  navigateFrom?: string
}

export const WeekCalendar: React.FC = () => {
  const location = useLocation()
  const {
    scheduleMembers,
    setScheduleMembers,
    weekSchedules,
    setWeekSchedules,
    handleWeekScheduleFetch,
    weekScheduleLoading,
  } = useWeekSchedule()

  const weekObject = weekStatus()
  const searchObject = useScheduleSearchEmployee()
  const employeeGroupObject = useEmployeeGroup()
  const { setTargetWeeks } = oneWeek()

  const [displayReserved, setDisplayReserved] = useState<boolean>(
    (location.state as LocationState)?.displayReserved || false
  )
  const [displayNonReserved, setDisplayNonReserved] = useState<boolean>(
    (location.state as LocationState)?.displayNonReserved || false
  )

  const loading = weekScheduleLoading
  const navigateFrom = (location.state as LocationState).navigateFrom

  useEffect(() => {
    const employeeIds = searchObject.selectedEmployees.map((employee) => {
      return employee.id
    })
    // 予定の登録・削除および予定(日）画面からの遷移時は(localStorage内)"targetDay"の値を反映
    if (navigateFrom && /new|edit|days/.test(navigateFrom)) {
      const targetDate = new Date(
        parseInt(localStorage.getItem("targetDay") as string, 10)
      )
      const { newBeginningOfWeek, newEndOfThisWeek } =
        setTargetWeeks(targetDate)

      weekObject.setBeginningOfWeek(newBeginningOfWeek)
      weekObject.setEndOfWeek(newEndOfThisWeek)
      handleWeekScheduleFetch({
        start_date: weekObject.formatDate(newBeginningOfWeek),
        end_date: weekObject.formatDate(newEndOfThisWeek),
        employee_ids: employeeIds,
      })
    } else {
      handleWeekScheduleFetch({
        start_date: weekObject.formatDate(weekObject.beginningOfWeek),
        end_date: weekObject.formatDate(weekObject.endOfWeek),
        employee_ids: employeeIds,
      })
    }
  }, [])

  // バツアイコンを押した従業員の予定を配列から除外する
  const changeDisplay = (
    members: EmployeesType,
    schedules: SchedulesTable[],
    index: number
  ) => {
    const newMembers = members.filter((member, i) => {
      return i !== index
    })

    const newSchedules = schedules.filter((schedule, i) => {
      return i !== index
    })
    setScheduleMembers(newMembers)
    setWeekSchedules(newSchedules)

    const newSelectedEmployees = searchObject.selectedEmployees.filter(
      (employee, i) => {
        return i !== index - 1
      }
    )
    searchObject.setSelectedEmployees(newSelectedEmployees)

    localStorage.setItem(
      "selectedEmployees",
      JSON.stringify(newSelectedEmployees)
    )
  }

  // ヘッダーの「席」を押した時の関数（席予約と会議室予約のみの表示に切り替え）
  const displayOnlyReserved = () => {
    setDisplayReserved(true)
    setDisplayNonReserved(false)
  }

  // ヘッダーの「スケジュール」を押した時の関数（席予約と会議室予約以外の表示に切り替え）
  const displayOnlyNonReserved = () => {
    setDisplayNonReserved(true)
    setDisplayReserved(false)
  }

  // ヘッダーの「全て」を押した時の関数（「席」「スケジュール」選択時の表示解除）
  const displayAll = () => {
    setDisplayReserved(false)
    setDisplayNonReserved(false)
  }
  const navigate = useNavigate()
  const newScheduleNavigate = () => {
    navigate("/employee/calendar/new", {
      state: {
        employees: scheduleMembers,
        navigateFrom: window.location.pathname,
        defaultDate: weekObject.beginningOfWeek,
      },
    })
    window.localStorage.setItem(
      "targetDay",
      weekObject.beginningOfWeek.getTime().toString()
    )
  }

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <SchedulableMaxDateProvider>
        <Box display="flex" width="100%" minHeight="570px">
          <Box marginRight="15px" flexGrow="1">
            <EmployeeScheduleWeekHeader
              {...weekObject}
              {...employeeGroupObject}
              displayOnlyReserved={displayOnlyReserved}
              displayOnlyNonReserved={displayOnlyNonReserved}
              displayAll={displayAll}
              selectedEmployees={searchObject.selectedEmployees}
              handleWeekScheduleFetch={handleWeekScheduleFetch}
              employees={scheduleMembers}
              displayReserved={displayReserved}
              displayNonReserved={displayNonReserved}
            />
            <Box
              id="scroll-content"
              display="flex"
              sx={{
                overflow: "auto",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  position: "sticky",
                  left: 0,
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      height: "3rem",
                      position: "sticky",
                      top: 0,
                      zIndex: 10001,
                      backgroundColor: "white",
                    }}
                  ></Box>
                  <Box borderRight={1} borderColor="#C1C1C1">
                    {scheduleMembers.map((value, index) => (
                      <EmployeeScheduleWeekSideBar
                        key={index}
                        {...value}
                        index={index}
                        scheduleMembers={scheduleMembers}
                        weekSchedules={weekSchedules}
                        changeDisplay={changeDisplay}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "80%",
                  zIndex: 1,
                }}
              >
                <Box height="30px">
                  <EmployeeScheduleDayLine {...weekObject} />
                  {weekSchedules.map((value, index) => (
                    <EmployeeScheduleWeekTable
                      key={index}
                      index={index}
                      schedule={value}
                      displayReserved={displayReserved}
                      displayNonReserved={displayNonReserved}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box borderLeft="solid 1px #707070" width="275px" paddingLeft="15px">
            <EmployeeScheduleSideSearch
              startDate={weekObject.beginningOfWeek}
              endDate={weekObject.endOfWeek}
              formatDate={weekObject.formatDate}
              {...searchObject}
              handleScheduleFetch={handleWeekScheduleFetch}
              employees={scheduleMembers}
              employeeGroups={employeeGroupObject.employeeGroups}
              newScheduleNavigate={newScheduleNavigate}
            />
          </Box>
        </Box>
      </SchedulableMaxDateProvider>
    </>
  )
}
