import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Box, styled, Typography } from "@mui/material"

import { api } from "../../api/config/axiosConfig"
import { EmployeeHomeCheckInOut } from "../../components/employee/home/EmployeeHomeCheckInOut"
import { EmployeeHomeLayout } from "../../components/employee/home/EmployeeHomeLayout"
import { EmployeeHomeMessages } from "../../components/employee/home/EmployeeHomeMessages"
import { EmployeeHomeSchedule } from "../../components/employee/home/EmployeeHomeSchedule"
import { AlertContent } from "../../components/public/AlertContent"
import { useLanguage } from "../../contexts/LanguageContext"
import { usePasswordExpireMonthes } from "../../models/company/usePasswordExpireMonthes"
import { useAccounts } from "../../models/employee/useAccounts"
import enTranslations from "../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../translations/employeeHome/employeeHomeTitle/ja"
import { pageType } from "../../utils/location"

const CustomFooter = styled(Box)({
  height: "20px",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  paddingRight: "20px",
})

const CustomTypography = styled(Typography)({
  fontSize: "10px",
  fontFamily: "Noto Sans",
  lineHeight: "20px",
})

export type LocationParams = {
  Token: Token
  navigateFrom: string
  scheduleDate?: {
    scheduleStartDate: string
  }
}

type Token = {
  m_token: string
  m_refresh_token: string
  m_expires_at: number
  m_user_name: string
  m_user_email: string
  m_user_timezone: string
  g_token: string
  g_refresh_token: string
  g_expires_at: number
  g_user_name: string
  g_user_email: string
  g_user_timezone: string
}
export const EmployeeHomePage = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const location = useLocation()
  const token = (location.state as LocationParams)?.Token
  const navigate = useNavigate()
  const { fetchPasswordChangeTimes } = useAccounts()
  const { fetchPasswordExpireMonthes } = usePasswordExpireMonthes()

  useEffect(() => {
    localStorage.removeItem("login")
    if (
      localStorage.getItem("targetDay") == "NaN" ||
      localStorage.getItem("targetDay") == null
    ) {
      localStorage.setItem("targetDay", new Date().getTime().toString())
    }
    fetchPasswordChangeTimes().then((data) => {
      if (data) {
        if (new Date() > new Date(data.password_change_times)) {
          localStorage.setItem("expiredChangePassword", "true")
          navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
        }
      }
    })
    fetchPasswordExpireMonthes()
  }, [])

  useEffect(() => {
    if (token) {
      if (token.m_token) {
        api.post("/auth", {
          m_token: token.m_token,
          m_refresh_token: token.m_refresh_token,
          m_expires_at: token.m_expires_at,
          m_user_name: token.m_user_name,
          m_user_email: token.m_user_email,
          m_user_timezone: token.m_user_timezone
            ? token.m_user_timezone
            : "Tokyo Standard Time",
          pathname: window.location.pathname,
        })
      } else {
        api.post("/auth", {
          g_token: token.g_token,
          g_refresh_token: token.g_refresh_token,
          g_expires_at: token.g_expires_at,
          g_user_name: token.g_user_name,
          g_user_email: token.g_user_email,
          g_user_timezone: token.g_user_timezone,
          pathname: window.location.pathname,
        })
      }
    }
  }, [])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 50px)"
        width="100%"
      >
        <Box display="flex" padding="0 20px 0 15px" flexGrow="1">
          <Box display="flex" flexDirection="column" height="100%">
            <Box height="145px">
              <EmployeeHomeCheckInOut />
            </Box>
            <Box flexGrow="1" width="450px">
              <EmployeeHomeSchedule />
            </Box>
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            flexDirection="column"
            margin="0 0 0 15px"
            height="100%"
            width="calc(100% - 470px)"
          >
            <Box flexGrow="1">
              <EmployeeHomeLayout />
            </Box>
            <Box>
              <EmployeeHomeMessages />
            </Box>
          </Box>
        </Box>
        <CustomFooter>
          <CustomTypography variant="overline">
            <a
              href="https://workagile.jp/index.php/terms/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#707070",
              }}
            >
              {translations.TermsService}{" "}
            </a>
            <a
              href="https://workagile.jp/index.php/privacy/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#707070",
              }}
            >
              {translations.PrivacyPolicy}
            </a>
          </CustomTypography>
        </CustomFooter>
      </Box>

      <AlertContent />
    </>
  )
}
