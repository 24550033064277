import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { styled, Typography, Box, Button } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useSignageReception } from "../../../../models/signage/useReception"
import { GreenArrowRightIcon } from "../../../public/GreenArrowRightIcon"
import { SignageMainOutlineButton } from "../../../public/SignageMainOutlineButton"

const CustomBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  marginBottom: theme.spacing(2),
}))

const EnglishTextTypography = styled(Typography)(() => ({
  fontSize: "36px",
  color: "#454545",
  marginBottom: theme.spacing(2),
}))

const CustomButton = styled(Button)(() => ({
  width: "65%",
  fontSize: theme.spacing(4),
  fontWeight: "bold",
  border: "1.3px solid",
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const CustomBottomEnglishTypography = styled(Typography)(() => ({
  fontSize: "22.7px",
  textTransform: "none",
}))

const CancelTypography = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(4),
  "&:hover": {
    cursor: "pointer",
    color: "#454545",
    textDecoration: "underline",
  },
}))

export const SignageReceptionMenu = () => {
  const { resetTimer } = useSignageReception()
  const navigate = useNavigate()
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)
  return (
    <>
      <CustomBox>
        <CustomTypography variant="h3">
          受付方法を選んでください
        </CustomTypography>
        <EnglishTextTypography>Make a selection below</EnglishTextTypography>
        <Box mt={5} mb={6} width="100%">
          <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => {
              resetTimer()
              navigate(`/signage/branches/${branchId}/reception/qr-reader`)
            }}
          >
            <Typography variant="h4" fontWeight="bold" marginX="auto">
              QRコードをお持ちのお客様
              <CustomBottomEnglishTypography>
                Customers with QR code
              </CustomBottomEnglishTypography>
            </Typography>
            <GreenArrowRightIcon />
          </CustomButton>
          {/* <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/signage/reception/searchChoice`)}
          >
            <Typography variant="h4" fontWeight="bold" marginX="auto">
              予約情報から探す
            </Typography>
            <GreenArrowRightIcon />
          </CustomButton> */}
          <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => {
              resetTimer()
              navigate(`/signage/branches/${branchId}/reception/not-reserved`)
            }}
          >
            <Typography variant="h4" fontWeight="bold" marginX="auto">
              アポイントなしのお客様
              <CustomBottomEnglishTypography>
                Customers without an appointment
              </CustomBottomEnglishTypography>
            </Typography>
            <GreenArrowRightIcon />
          </CustomButton>
        </Box>
        <SignageMainOutlineButton
          label="TOPに戻る"
          subLabel="Back to TOP"
          handleClick={() => {
            resetTimer()
            navigate(`/signage/branches/${branchId}/reception/home`)
          }}
        />
      </CustomBox>
    </>
  )
}
