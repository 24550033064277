const ja = {
  workSchedule: "スケジュール予約",
  allDay: "終日",
  AM: "午前",
  PM: "午後",
  SetTime: "時間指定",
  Hour: "時",
  minutes: "分",
  year: "年",
  month: "月",
  day: "日",
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  usedBefore: "最近利用した席を選んで予約",
  used: "利用した",
  bookDesk: "を予約",
  Floor: "",
  yourMood: "気分雰囲気にあった席を予約（ランダム)",
  randomDesk: "タグを選択（３つまで、選ばない場合は全利用席からランダムで予約)",
  spot: "除外エリア",
  chooseStatus: "出社以外の状況を選ぶ",
  enter: "確定",
  status: ["在宅", "外出", "出張", "休暇"],
  daysAgo: "日前",
  yesterday: "昨日",
  today: "本日",
  Select: "選択する",
  Cancel: "キャンセル",
  ConfirmReservation: "予約する",
  Reservation: "予約内容の確認",
  ReservationDetail: "以下の内容で予約してもよろしいでしょうか？",
  CanWeProceed: "座席情報",
  Edit: "入力に戻る",
  SelectTag: "タグの選択(3つまで)",
  tag: "タグ",
  ReservationTime: "予約時間",
  ReservedDeskRoom: "座席情報",
  ReservationDay: "予約日時",
  next: "次へ",
  Desk: "座席",

  // エラーメッセージ
  schedulePast: "過去の予定を作成することはできません",
  NoSelectedSeats: "選択された日付に予約可能な座席が存在しません",
  NoUsedSeat:
    "最近利用した席が存在しない、もしくは、最近利用した席が現在時刻で利用できません。",
  TypingError: "入力に誤りがございます、もう一度お試しください",
  DesksUsedBefore: "最近利用した席",
}
export default ja
