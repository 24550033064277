import { SignageFloorLayoutType } from "../../models/signage/useFloor/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchSignageFloorRequest = async (params: {
  floor_id: number
}): Promise<ApiResponse<SignageFloorLayoutType>> => {
  try {
    const data = await api.get("/signage/floors", { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}
