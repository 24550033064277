import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Grid, Link, styled, Typography } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import enTranslations from "../../../../translations/employeeloginPage/forgotPassword/en"
import jaTranslations from "../../../../translations/employeeloginPage/forgotPassword/ja"
import { pageType } from "../../../../utils/location"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

export const ForgotPasswordAppliedMessage = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography align="center">
          {translations.resetEmail}
          <Typography align="center">{translations.accessURL}</Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center">
          <Link
            component={RouterLink}
            to={`/${pageType}/login`}
            variant="caption"
            color="#454545"
          >
            <Typography variant="inherit" display="block">
              {translations.loginPage}
            </Typography>
          </Link>
        </Typography>
      </Grid>
    </CustomGridContainer>
  )
}
