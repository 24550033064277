import React from "react"

import { Grid, Box, Typography, styled } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { WhoIsWheres } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"

interface Props {
  whoIsWheres: WhoIsWheres
  onUnselectSeat: (userableId: number) => void
}

const CustomTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: theme.palette.primary.main,
}))

const CustomNameTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "17px",
  color: "#454545",
  miniWidth: "48px",
  marginRight: "4px",
}))

const EmployeeAndSelectedSeatList = styled(Grid)(({ theme }) => ({
  width: "80%",
  marginTop: theme.spacing(0.5),
  overflowY: "scroll",
  maxHeight: theme.spacing(28),
  "&::-webkit-scrollbar": {
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

// ばつ印アイコン用
const CloseIcon = styled("img")({
  cursor: "pointer",
  width: "16px",
  height: "16px",
  marginLeft: "8px",
})

export const EmployeeCalendarLayoutSelectedSeat: React.FC<Props> = ({
  whoIsWheres,
  onUnselectSeat,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // ゲストには座席を割り当てないと合意済み。よってそもそもドラッグアンドドロップさせない
  const onlyEmployees = whoIsWheres.filter((whoIsWhere) => {
    return whoIsWhere.userable.userable_type === "Employee"
  })
  return (
    <>
      <Box display="flex" margin="15px 0 20px 0">
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/seat-without-circle.svg`}
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        <CustomTitleTypography>
          {translations.SelectedDesk}
        </CustomTitleTypography>
      </Box>
      <EmployeeAndSelectedSeatList container alignItems="center">
        {onlyEmployees.map((whoIsWhere, index) => (
          <Grid
            item
            sm={11}
            key={index}
            sx={{
              margin: "0 0 5px 25px",
              padding: theme.spacing(0.25, 1.5),
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomNameTypography marginRight="10px">
              {whoIsWhere.userable.last_name}
            </CustomNameTypography>
            <CustomNameTypography>
              {whoIsWhere.userable.first_name}
            </CustomNameTypography>
            <Typography
              fontSize="14px"
              fontFamily="Noto Sans"
              mr="1rem"
              display="inline"
            >
              {whoIsWhere.reservable.name}
            </Typography>
            {/* 座席が選択新ている時にばつ印を表示 */}
            {whoIsWhere.reservable.reservable_id &&
              whoIsWhere.userable.userable_id !== undefined && (
                <CloseIcon
                  src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                  onClick={() =>
                    onUnselectSeat(whoIsWhere.userable.userable_id as number)
                  }
                  alt="Unselect seat"
                />
              )}
          </Grid>
        ))}
      </EmployeeAndSelectedSeatList>
    </>
  )
}
