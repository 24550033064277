import React from "react"

import { Box, styled, Typography } from "@mui/material"

import { MonthScheduleType } from "../../../../../../models/employee/useSchedule/months/type"

const ScheduleBox = styled(Box)(() => ({
  height: "24px",
  borderRadius: "7px",
  marginBottom: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
}))

interface Props {
  day: {
    date: Date
    event_schedules: MonthScheduleType[]
    singleDisplayCount: number
  }
  displayReserved: boolean
  displayNonReserved: boolean
}

export const MonthSingleDaySchedules: React.FC<Props> = ({
  day,
  displayReserved,
  displayNonReserved,
}: Props) => {
  // 単日予定のうち、表示可能件数分に絞った配列に変換
  const singleDisplayList: MonthScheduleType[] = []
  for (let i = 0; i < day.event_schedules.length; i++) {
    if (!day.event_schedules[i].width) {
      singleDisplayList.push(day.event_schedules[i])
    }
    if (singleDisplayList.length === day.singleDisplayCount) {
      break
    }
  }
  return (
    <>
      {singleDisplayList.map((event, index) => {
        return (
          <>
            {!displayNonReserved && event.reservable_type && (
              <ScheduleBox
                key={index}
                sx={{
                  bgcolor: "#DBF4EF",
                  color: "#4EC7B0",
                  width: "100%",
                  border: "1px solid #4EC7B0",
                }}
              >
                <Typography
                  align="left"
                  ml="5px"
                  fontSize="12px"
                  fontFamily="Noto Sans"
                >
                  {event.schedule_title}
                </Typography>
              </ScheduleBox>
            )}
            {!displayReserved && !event.reservable_type && (
              <ScheduleBox
                key={index}
                sx={{
                  bgcolor: "#DBF4EF",
                  color: "#4EC7B0",
                  width: "100%",
                  border: "1px solid #4EC7B0",
                }}
              >
                <Typography
                  align="left"
                  ml="5px"
                  fontSize="12px"
                  fontFamily="Noto Sans"
                >
                  {event.schedule_title}
                </Typography>
              </ScheduleBox>
            )}
          </>
        )
      })}
    </>
  )
}
