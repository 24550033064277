import { translations } from "@aws-amplify/ui-react"
import { format } from "date-fns"

import React, { useEffect, useState } from "react"

import { Box, Typography, styled } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import { useScheduleShowData } from "../../../models/employee/useSchedule/show"
import "../../../models/employee/useSchedule/show"
import { useScheduleNewMessage } from "../../../models/employee/useSchedule/useScheduleNewMessage"
import { ScheduleNewMessage } from "../../../models/employee/useSchedule/useScheduleNewMessage/type"
import enTranslations from "../../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeTitle/ja"
import { dateStatus } from "../../../utils/date"
import { useOpen } from "../../../utils/isOpen"
import { EmployeeScheduleDayShowModal } from "../calendar/days/EmployeeScheduleDayShowModal"
import { EmployeeHomeContent } from "./EmployeeHomeContent"

const weekDays = ["日", "月", "火", "水", "木", "金", "土"]
const englishWeekDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

const weekDaysStr = (weekly_recurring_type: {
  id: number
  every_week: number
  sunday: boolean
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
}) => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  let weekDaysStr = ""
  englishWeekDays.forEach((weekDay, index) => {
    if (eval(`weekly_recurring_type.${weekDay}`)) {
      weekDaysStr += translations.weekdays[index] + "・"
    }
  })
  if (weekly_recurring_type.every_week === 1) {
    weekDaysStr = translations.everyWeek + weekDaysStr
  } else {
    weekDaysStr =
      `${weekly_recurring_type.every_week}${translations.everyWeeks}` +
      " " +
      weekDaysStr
  }
  return weekDaysStr.slice(0, -1)
}
////////// Title

const EmployeeHomeMessagesTitleStyle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  margin: "0 10px",
  lineHeight: "24px",
})

const EmployeeHomeMessagesTitle: React.FC = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <>
      <EmployeeHomeMessagesTitleStyle>
        {translations.NewMessage}
      </EmployeeHomeMessagesTitleStyle>
    </>
  )
}

////////// Content

const MessageTableBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "130px", // 高さ固定
  minWidth: "100%",
  overflow: "auto",
}))

const MessagesTable = styled("table")({
  width: "100%",
  borderCollapse: "separate",
  borderSpacing: 0,
  position: "relative",
})

const TableBody = styled("tbody")({
  "&:hover": {
    backgroundColor: "#F7F7F7",
    cursor: "pointer",
  },
})

const HeaderTr = styled("tr")({
  textAlign: "left",
  backgroundColor: "white",
  position: "sticky",
  top: "0",
  left: "0",
})

const HeaderTh = styled("th")({
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "13px",
  paddingBottom: "15px",
  paddingRight: "15px",
  borderBottom: "1px solid lightgray",
})

const ContentTr = styled("tr")({
  width: "100%",
  verticalAlign: "top",
})

const ContentTd = styled("td")({
  padding: "0",
})

const CustomMessagesTypography = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

const TableBox = styled(Box)({
  padding: "5px 15px 5px 0",
  borderBottom: "1px solid lightgray",
  height: "30px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

const DateTimeTd = styled("td")({
  padding: "0",
})

const EmployeeHomeMessagesContent: React.FC = () => {
  const { scheduleNewMessage, fetchNewScheduleMessage } =
    useScheduleNewMessage()
  const [newMessageDate, setNewMessageDate] = useState<Date>(new Date())

  useEffect(() => {
    fetchNewScheduleMessage()
  }, [])

  const dateObject = dateStatus()
  const { isOpen, open, close } = useOpen()
  const { scheduleShowData, fetchScheduleShowData, onApproveSubmit } =
    useScheduleShowData()

  const handleScheduleShowDataClick = (schedule: ScheduleNewMessage) => {
    open()
    setNewMessageDate(new Date(schedule.start_time))
    fetchScheduleShowData(
      [schedule.schedule_information_id],
      new Date(schedule.start_time),
      [schedule.schedule_id],
      [schedule]
    )
  }

  // 他の人から招待された予定のみ表示
  const onlyInvitationMessage = scheduleNewMessage.filter((a) => {
    if (a.schedule_creator_id != a.userable_id) {
      return true
    }
  })
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <MessageTableBox>
        <MessagesTable>
          <HeaderTr>
            <HeaderTh sx={{ width: "50%" }}>{translations.Subject}</HeaderTh>
            <HeaderTh>{translations.From}</HeaderTh>
            <HeaderTh>{translations.Location}</HeaderTh>
            <HeaderTh>{translations.dates}</HeaderTh>
          </HeaderTr>
          {onlyInvitationMessage.map((newMessage, i) => {
            return (
              <TableBody
                key={"message_" + i}
                onClick={() => handleScheduleShowDataClick(newMessage)}
              >
                <ContentTr>
                  <ContentTd>
                    <TableBox>
                      <CustomMessagesTypography>
                        {newMessage.schedule_title}
                      </CustomMessagesTypography>
                    </TableBox>
                  </ContentTd>
                  <ContentTd>
                    <TableBox>
                      <CustomMessagesTypography>
                        {newMessage.schedule_creator}
                      </CustomMessagesTypography>
                    </TableBox>
                  </ContentTd>
                  <ContentTd>
                    <TableBox>
                      <CustomMessagesTypography>
                        {newMessage.reservable
                          ? newMessage.reservable.branch.branch_name
                          : ""}
                      </CustomMessagesTypography>
                    </TableBox>
                  </ContentTd>
                  <DateTimeTd>
                    <TableBox>
                      <CustomMessagesTypography>
                        {format(
                          new Date(newMessage.start_date),
                          translations.date_format
                        )}
                      </CustomMessagesTypography>
                    </TableBox>
                  </DateTimeTd>
                </ContentTr>
              </TableBody>
            )
          })}
        </MessagesTable>
      </MessageTableBox>
      <EmployeeScheduleDayShowModal
        date={newMessageDate}
        isOpen={isOpen}
        close={close}
        scheduleShowData={scheduleShowData}
        onApproveSubmit={onApproveSubmit}
      />
    </>
  )
}

////////// Icon

const EmployeeLeftIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
})

const EmployeeLeftIcon = (
  <EmployeeLeftIconStyled>
    <img
      src={`${process.env.PUBLIC_URL}/images/icons/mail.svg`}
      style={{
        height: "20px",
        width: "20px",
      }}
    />
  </EmployeeLeftIconStyled>
)

export const EmployeeHomeMessages = () => {
  return (
    <>
      <EmployeeHomeContent
        title={<EmployeeHomeMessagesTitle />}
        content={<EmployeeHomeMessagesContent />}
        leftIcon={EmployeeLeftIcon}
        areaName="Messages"
      />
    </>
  )
}
