import { useState, useCallback, useEffect, useContext } from "react"

import { ApiResponse } from "../../../api/config/axiosConfig"
import {
  fetchCheckInOutMeetingRoomRequest,
  updateCheckInOutMeetingRoomRequest,
} from "../../../api/employee/meetingRoomRequest"
import { useFetchCheckInMeetingRoomName } from "../../../hooks/mobile/useFetchCheckInMeetingRoomName"
import { AuthContext } from "../../../providers/AuthProvider"
import {
  CheckInOutMeetingRoomResponseType,
  CheckInOutMeetingRoomRequestType,
} from "./type"

const initialCheckInOut: CheckInOutMeetingRoomResponseType = {
  check_in: {
    is_check_in: false,
    schedule: {
      id: 0,
      scheduled_date: new Date(),
      start_time: new Date(),
      end_time: new Date(),
      schedule_information_id: 0,
      reservable: {
        meeting_room_name: "",
        branch_name: "",
        floor_number: 0,
        floor_name: "",
      },
    },
  },
}

// チェックイン
export const useCheckInOutMeetingRoom = () => {
  const [checkInOutMeetingRoom, setCheckInOutMeetingRoom] =
    useState<CheckInOutMeetingRoomResponseType>(initialCheckInOut)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { currentUser } = useContext(AuthContext)
  const [availableTime, setAvailableTime] = useState(0)

  const { fetchCheckInMeetingRoomName, isLoading: isLoadingMeetingRoomName } =
    useFetchCheckInMeetingRoomName()

  const isLoadingOverall = isLoading || isLoadingMeetingRoomName

  const fetchCheckInOutMeetingRoom = useCallback(async () => {
    if (currentUser) {
      setIsLoading(true)
      try {
        const response: ApiResponse<CheckInOutMeetingRoomResponseType> =
          await fetchCheckInOutMeetingRoomRequest()
        if (response.status === 200 && response.data) {
          setCheckInOutMeetingRoom(response.data)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [currentUser])

  const updateCheckInOutMeetingRoom = async (
    data: CheckInOutMeetingRoomRequestType
  ) => {
    const { error } = await updateCheckInOutMeetingRoomRequest(data)
    if (!error) {
      localStorage.setItem(
        "alertContent",
        `${
          data.checked_in
            ? "チェックインが完了しました"
            : "チェックアウトが完了しました"
        }`
      )
      window.location.reload()
    } else {
      setErrorMessages(["チェックインに失敗しました"])
    }
  }

  useEffect(() => {
    fetchCheckInOutMeetingRoom()
  }, [fetchCheckInOutMeetingRoom])

  return {
    isLoading: isLoadingOverall,
    availableTime,
    setAvailableTime,
    checkInOutMeetingRoom,
    fetchCheckInOutMeetingRoom,
    fetchCheckInMeetingRoomName,
    updateCheckInOutMeetingRoom,
    errorMessages,
  }
}
