import React, { useState } from "react"

import { Box, Grid, Tooltip, Typography } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { MeetingRoomType } from "../../../../models/employee/useLayout/type"
import { Schedule } from "../../../../models/employee/useLayout/type"
import {
  meetingRoomStyle,
  meetingRoomIconStyle,
} from "../../../../styles/meetingStyle"
import {
  reservedEmployeesStyle,
  checkInReservedEmployeesStyle,
} from "../../../../styles/seatStyle"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import {
  formatDateForSafari,
  formatScheduleTimeRangeForDisplay,
} from "../../../../utils/dateTimeFormat"

type MeetingRoomProps = {
  meetingRoomId: number | null
  meetingRoomCode: string
  startX: number
  startY: number
  endX: number
  endY: number
  colorCode: string
  isHighLight: boolean
  schedules: Schedule[]
  meetingRoomName: string
}

// 会議室オブジェクト
const MeetingRoomObject = (props: MeetingRoomProps) => {
  const {
    meetingRoomId,
    meetingRoomCode,
    startX,
    startY,
    endX,
    endY,
    schedules,
    isHighLight,
    colorCode,
    meetingRoomName,
  } = props

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const timeFormat = (time: string) => {
    const isoTime = time.replace(" ", "T").replace(" ", "")
    const date = new Date(isoTime)
    const hour = ("0" + date.getHours()).slice(-2)
    const min = ("0" + date.getMinutes()).slice(-2)

    return `${hour}:${min}`
  }
  const tooltipText = (schedules: Schedule[]) => {
    const schedule = schedules[0]
    let employeesName = ""
    schedules.map((schedule) => {
      switch (schedule.userable_type) {
        case "Employee":
          employeesName += `${schedule?.userable.last_name} ${schedule?.userable.first_name}, `
          break
        case "Guest":
          employeesName += `${schedule?.userable.last_name} ${schedule?.userable.first_name}(ゲスト), `
      }
    })
    const scheduleTitle = schedule?.schedule_title
    const scheduleTime = schedule?.whole_day_flag
      ? translations.allDay
      : formatScheduleTimeRangeForDisplay(
          formatDateForSafari(schedule?.start_time as string),
          formatDateForSafari(schedule?.end_time as string)
        )

    const seatInfo = meetingRoomCode
    return `会議室番号: ${seatInfo}\n会議室名称: ${meetingRoomName}\n${translations.Name}: ${employeesName}\n${translations.Subject}: ${scheduleTitle}\n${scheduleTime}`
  }

  return (
    <>
      <Box
        id={`M${String(meetingRoomId)}`}
        className={"meetingRoom"}
        sx={meetingRoomStyle(
          startX,
          startY,
          endX,
          endY,
          colorCode,
          isHighLight
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <span
          style={
            meetingRoomIconStyle(colorCode, isHighLight) as React.CSSProperties
          }
        >
          M
        </span>
        <span
          style={{
            top: "60px",
            left: "60px",
            color: isHighLight ? "#000" : colorCode,
            position: "absolute",
            fontSize: "30px",
          }}
        >
          {meetingRoomCode}
        </span>
        {schedules && (
          <Tooltip
            title={schedules.length > 0 ? tooltipText(schedules) : ""}
            placement="bottom"
            disableInteractive
            open={schedules.length > 0 && tooltipOpen}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: "pre-wrap",
                  position: "fixed",
                  width: "200px",
                },
              },
            }}
          >
            <Grid
              container
              style={{
                bottom: "10px",
                left: "40px",
                position: "absolute",
                display: "flex",
                alignItems: "center",
              }}
            >
              {schedules.map((schedule, index) => {
                return (
                  <Grid item key={index} sm={4}>
                    <Box
                      sx={
                        schedule.checked_in
                          ? (checkInReservedEmployeesStyle as React.CSSProperties)
                          : (reservedEmployeesStyle as React.CSSProperties)
                      }
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {schedule.image_blob ? (
                        <img
                          src={schedule.image_blob}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Typography
                          sx={{ fontSize: "20px" }}
                          color="inherit"
                          display="flex"
                        >
                          <span>{schedule.userable.last_name[0]}</span>
                          <span>{schedule.userable.first_name?.[0]}</span>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Tooltip>
        )}
      </Box>
    </>
  )
}

interface Props {
  meetingRoom: MeetingRoomType
}

/*
  レイアウト画像にア会議室のアイコンが表示される
*/
export const MobileEmployeeLayoutMeetingRoom: React.FC<Props> = ({
  meetingRoom,
}: Props) => {
  return (
    <>
      <MeetingRoomObject
        meetingRoomId={meetingRoom.id}
        meetingRoomCode={meetingRoom.meeting_room_code}
        startX={meetingRoom.start_x}
        startY={meetingRoom.start_y}
        endX={meetingRoom.end_x}
        endY={meetingRoom.end_y}
        colorCode={meetingRoom.color_code}
        isHighLight={meetingRoom.is_highlight}
        schedules={meetingRoom.schedules as Schedule[]}
        meetingRoomName={meetingRoom.meeting_room_name}
      />
    </>
  )
}
