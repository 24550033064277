import {
  FloorRequestType,
  FloorOrderRequestType,
} from "../../models/company/useFloor/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const createFloorRequest = async (
  params: FloorRequestType
): Promise<ApiResponse<void>> => {
  try {
    const { data } = await api.post("/company/floors", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateFloorRequest = async (
  params: FloorRequestType
): Promise<ApiResponse<void>> => {
  try {
    await api.patch(`/company/floors/${params?.id}`, params)
    return {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateFloorOrderRequest = async (
  params: FloorOrderRequestType[]
): Promise<ApiResponse<void>> => {
  try {
    await api.patch("/company/floors/order", params)
    return {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
