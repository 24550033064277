import { api } from "../config/axiosConfig"

// ad_tokenの存在を確認
export const checkAzureAdIntegration = async () => {
  try {
    const tokenResponse = await api.get("/company/azuread/check_ad_token")
    if (tokenResponse.data && tokenResponse.data.has_token) {
      const response = await api.get("/company/azuread/groups")
      if (
        response.data &&
        Array.isArray(response.data.groups) &&
        response.data.groups.length > 0
      ) {
        return true
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return false
}

// グループ情報の取得
export const getAzureAdGroups = async (
  setGroups: React.Dispatch<React.SetStateAction<string[]>>,
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const response = await api.get("/company/azuread/groups")

    if (response.data && Array.isArray(response.data.groups)) {
      const groupNames = response.data.groups.map(
        (group: any) => group.displayName
      )
      setGroups(["all", ...groupNames])
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}

  setShowAlert(true)
}

// AzureADとの同期(台帳連携)
export const syncAzureAdUsers = async (
  syncGroup: string,
  handleCloseAlert: () => void
) => {
  const apiEndpoint =
    syncGroup === "all"
      ? "/company/azuread/sync_all_users_with_azure"
      : "/company/azuread/sync_group_users_with_azure"
  const requestBody = { group_id: syncGroup !== "all" ? syncGroup : null }

  try {
    await api.post(apiEndpoint, requestBody)
    localStorage.setItem(
      "alertContent",
      "AzureADと同期を開始しました。\n完了次第ログイン中のメールアドレスに通知しますので少々お待ちください。"
    )
  } catch (error) {
    localStorage.setItem(
      "alertErrorContent",
      "AzureADとの同期に失敗しました。再試行してください。"
    )
  } finally {
    handleCloseAlert()
    window.location.reload()
  }
}
