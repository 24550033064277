// axiosに関する設定
import Amplify, { Auth } from "aws-amplify"
import axios, { AxiosError, AxiosResponse } from "axios"

import { NavigateFunction } from "react-router-dom"

import { Config } from "../../config"

export const api = axios.create({
  baseURL: Config.api.endpoint,
  responseType: "json",
})

// Apiのレスポンスの共通の型
export interface ApiResponse<T> {
  data?: T
  error?: string[]
  status?: number
}

export const setUpSignageAxiosInterceptor = (
  navigate: NavigateFunction,
  setIsForbiddenError: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  // 各APIリクエストでレスポンスを受け取った時の共通処理
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (!error.response) {
        // 通信に失敗した場合など、そもそもレスポンスが返ってこなかった場合
        navigate("/500", { state: "500" })
      } else {
        // 失敗時の処理
        switch (error.response.status) {
          case 403:
            setIsForbiddenError(true) // AxiosInterceptor.tsxで設定したエラー情報を表示
            throw error
          case 404:
            navigate("/404", { state: "404" })
            break
          case 422:
            throw error
          default:
            // 指定していないステータスのエラーが起こった場合
            navigate("/500", { state: "500" })
            break
        }
      }
    }
  )
}
