import React, { createContext, useContext, useState } from "react"

// 現在の言語と更新関数の型を指定
type LanguageContextType = {
  language: string
  setLanguage: (lang: string) => void
}

const LanguageContext = createContext<LanguageContextType | null>(null)

// LanguageProviderコンポーネントが子コンポーネントに言語の状態と状態変更関数を提供
export const LanguageProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const storedLanguage = localStorage.getItem("language") || "ja"
  const [language, setLanguageState] = useState<string>(storedLanguage)

  const setLanguage = (lang: string) => {
    setLanguageState(lang)
    localStorage.setItem("language", lang)
  }

  //   子コンポーネントに言語更新関数を提供
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

// useLanguageで言語状態を共有できる
export const useLanguage = () => {
  const context = useContext(LanguageContext)
  if (context === null) {
    throw new Error("useLanguage must be used within a LanguageProvider")
  }
  return context
}
