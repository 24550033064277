import { CreditCard } from "../../models/company/useCreditCard/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const createCreditCardRequest = async (params: {
  token: string
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/credit_cards", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const fetchCreditCardRequest = async (): Promise<
  ApiResponse<CreditCard>
> => {
  try {
    const data = await api.get("/company/credit_cards")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
