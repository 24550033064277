import React from "react"

import { AuthPageTemplate } from "../../components/public/AuthPageTemplate"
import { PasswordChangeForm } from "../../components/public/auth/PasswordChangeForm"
import { SignagePasswordChangeForm } from "../../components/signage/login/PasswordChangeForm"
import { usePasswordChange } from "../../models/public/useAuth/passwordChange"

export const EmployeePasswordChange = () => {
  const authObject = usePasswordChange()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <PasswordChangeForm {...authObject} />
    </AuthPageTemplate>
  )
}
