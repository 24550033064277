const ja = {
  WelcomeBack: "おかえりなさい！",
  PleaseLoginAccount: "アカウントにログインしてください",
  EmailAddress: "メールアドレス（必須）",
  Password: "パスワード",
  Login: "ログイン",
  ForgotPassword: "パスワードをお忘れの方はこちら",
  LoginFailed: "ログインに失敗しました",
  Required: "必須になります",
  email: "メールアドレスの形式で入力してください",
  password: "パスワードの形式で入力してください",
}

export default ja
