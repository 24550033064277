const ja = {
  LookingFavoriteMembers: "お気に入りのメンバーをお探しですか？",
  NoFavoriteTeams: "お気に入りが未登録です",
  UnselecTeams: "お気に入りの選択を全て解除する",
  LookingSomeone: "誰かをお探しですか？",
  UnselectAllMembers: "メンバーの選択を全て解除する",
  fiftyMembers: "一度に検索できる人数は50人までです",
  WhereMember: "どこに所属するメンバーをお探しですか？",
  UnselectBusinessUnit: "所属の選択を全て解除する",
  AreYouLooking: "誰かをお探しですか？",
  AddMembers: "探したいメンバーの追加",
  UnselectMembers: "メンバーの選択を全て解除する",
  AddKanjiEmail: "メンバーを追加（漢字、メール入力）",
  status: ["在宅", "外出", "出張", "休暇"],
  month: "月",
  day: "日",
  weekdays: ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"],
}
export default ja
