import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
// import Cookies from "js-cookie"
import * as Yup from "yup"

import { useState, useCallback, useContext } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { confirmRoleRequest } from "../../../../api/signage/authRequest"
import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/employeeloginPage/loginpage/en"
import jaTranslations from "../../../../translations/employeeloginPage/loginpage/ja"
import { pageType } from "../../../../utils/location"
import { EMAIL_REGEXP, PASSWORD_REGEX } from "../../../../utils/regular"
import { LoginType } from "./type"

const authFormData: LoginType = {
  email: "",
  password: "",
  rememberLoginData: true,
}

export interface SignInParams {
  username: string
  password: string
  attributes: {
    email: string
  }
}

//TODO
// 従業員側のログイン機能実装時に共通化検討

export const useLogin = () => {
  Amplify.configure(awsmobile)
  const navigate = useNavigate()
  const [authData] = useState<LoginType>(authFormData)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { setCurrentUser } = useContext(AuthContext)

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // // コンポーネント読み込み時にcookieの情報を読み取ってemail, passwordが入っていれば自動でフォーム入力する
  // useEffect(() => {
  //   const email = Cookies.get("email")
  //   const password = Cookies.get("password")
  //   if (email) {
  //     setValue("email", email)
  //   }
  //   if (password) {
  //     setValue("password", password)
  //   }
  // }, [])

  const basicSchema = Yup.object().shape({
    email: Yup.string()
      .required(translations.Required)
      .matches(EMAIL_REGEXP, translations.email),
    password: Yup.string(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: authData.email,
      password: authData.password,
      rememberLoginData: true,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<LoginType> = useCallback(async (data) => {
    try {
      setLoading(true)

      const response = await Auth.signIn(data.email, data.password)
      if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
        navigate(`/${pageType}/mfa`)
      } else if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate(`/${pageType}/password-change`)
      } else if (response.signInUserSession) {
        localStorage.setItem("login", "homeLogin")
        window.location.reload()
      }
      setCurrentUser(response)
    } catch (error) {
      setOpen(true)
      setErrorMessages([translations.LoginFailed])
    } finally {
      setLoading(false)
    }
  }, [])

  // サイネージログイン用
  const onSignageSubmit: SubmitHandler<LoginType> = useCallback(
    async (params) => {
      setLoading(true)
      try {
        const response = await confirmRoleRequest({
          email: params.email,
        })
        if (response.status === 200) {
          onSubmit(params)
        } else if (response.error) {
          setOpen(true)
          setErrorMessages(response.error)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    control,
    authData,
    errors,
    isValid,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    open,
    errorMessages,
    loading,
    onSignageSubmit,
  }
}
