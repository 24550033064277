import { yupResolver } from "@hookform/resolvers/yup"
import { Storage } from "aws-amplify"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import Resizer from "react-image-file-resizer"
import { useNavigate } from "react-router-dom"

import {
  createSignageRequest,
  fetchSignageRequest,
  updateSignageRequest,
} from "../../../api/company/signageRequest"
import { SignageFormType } from "./type"

const SignageData: SignageFormType = {
  id: 0,
  branch_id: 0,
  floor_id: 0,
  door_id: "",
  notification_flag: true,
}

export const useSignage = () => {
  const navigate = useNavigate()
  const [imageUrl, setImageUrl] = useState<string>("")
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [s3key, setS3key] = useState<string>("")
  const [receptionMessage, setReceptionMessage] = useState<string>("")
  const [companyId, setCompanyId] = useState<number>(0)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [sinage] = useState<SignageFormType>(SignageData)
  const [displaySignage, setDisplaySignage] = useState(true)

  const basicSchema = Yup.object().shape({
    signage_reception_message: Yup.string().max(
      100,
      "100文字以下で入力してください"
    ),
  })

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      signage_reception_message: "",
    },
  })
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      id: sinage.id,
      branch_id: sinage.branch_id,
      floor_id: sinage.floor_id,
      door_id: sinage.door_id,
      notification_flag: sinage.notification_flag,
    },
    resolver: yupResolver(basicSchema),
  })

  // 画像登録
  const handleImageUpdate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const createObjectURL = (window.URL || window.webkitURL).createObjectURL
      const image = await resizeFile(e.target.files[0] as Blob)
      setImageBlob(image as Blob)
      setImageUrl(createObjectURL(image as Blob))
    }
  }

  // アイコンのリサイズ
  const resizeFile = async (file: Blob) => {
    return new Promise((resolve) => {
      try {
        Resizer.imageFileResizer(
          file, // 画像ファイルのパス
          600, // 最大幅
          700, // 最大高さ
          file.type, // ファイル形式
          100, // 圧縮の倍率。特に設定しない場合は、100になる。
          0, // 画像の回転角度
          (uri) => {
            resolve(uri)
          },
          "blob" // blobに変換
        )
      } catch (error) {
        alert(".jpeg.pngの画像を選択してください")
        throw error
      }
    })
  }

  // S3に画像の投稿
  const uploadFile = async (companyId: number, file: Blob) => {
    const customPrefix = `company${companyId}/signage/images/`

    if (!file) return

    try {
      const fileName = Date.now() + "." + file.type.replace(/(.*)\//g, "")
      const result = await Storage.put(fileName, file, {
        level: "public",
        contentType: file.type,
        customPrefix: {
          public: customPrefix,
        },
      })
      if (result) {
        return result
      }
    } catch (error) {
      console.log(error)
    }
  }

  // S3から画像の参照
  const fetchFile = async (companyId: number, image: string) => {
    if (image === "") return

    const customPrefix = `company${companyId}/signage/images/`
    try {
      const storageImageUrl = await Storage.get(image, {
        level: "public",
        customPrefix: {
          public: customPrefix,
        },
      })
      setImageUrl(storageImageUrl)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSignage = async () => {
    try {
      const response = await fetchSignageRequest()
      if (response.data && response.status === 200) {
        await fetchFile(response.data.company_id, response.data.signage_image)
        form.reset({
          signage_reception_message: response.data.signage_reception_message,
        })
        response.data.signage_image
        setCompanyId(response.data.company_id)
        setS3key(response.data.signage_image)
        setReceptionMessage(response.data.signage_reception_message)
        setDisplaySignage(response.data.is_display_signage)
      }
    } catch (e) {
      console.log("error")
    }
  }

  const updateSignage = form.handleSubmit(async (data) => {
    try {
      let image = s3key
      if (imageBlob) {
        const imageResult = await uploadFile(companyId, imageBlob)
        if (!imageResult) return

        image = imageResult.key
      }

      const response = await updateSignageRequest({
        is_display_signage: displaySignage,
        signage_reception_message: data.signage_reception_message,
        signage_image: image,
      })

      if (response.status === 204) {
        localStorage.setItem("alertContent", "サイネージの設定をしました")
        navigate(`/company/settings/environment`)
        window.location.reload()
      } else if (response.error) {
        setErrorMessages(response.error)
      }
    } catch (e) {
      console.log("error")
      setErrorMessages(["入力に誤りがあります。"])
    }
  })

  const onCreateSignageSubmit: SubmitHandler<SignageFormType> = useCallback(
    async (params) => {
      try {
        const { data, error } = await createSignageRequest({
          ...params,
          id: undefined,
        })
        if (!error) {
          if (data) {
            if (data.signage_information_id) {
              navigate(
                `/signage/branches/${data.signage_information_id}/reception/home`
              )
            }
          }
        } else {
          // setErrorMessageOpen(true)
          setErrorMessages(error)
        }
      } catch (error) {
        console.log(error)
      } finally {
        // setLoading(false)
      }
    },
    []
  )

  return {
    form,
    imageUrl,
    receptionMessage,
    fetchSignage,
    errorMessages,
    updateSignage,
    setErrorMessages,
    handleImageUpdate,
    control,
    handleSubmit,
    errors,
    isValid,
    getValues,
    setValue,
    reset,
    watch,
    onCreateSignageSubmit,
    displaySignage,
    setDisplaySignage,
  }
}
