import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { AccessTime } from "@mui/icons-material"
import { Box } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"

import { ValidEndDateProps } from ".."
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeNewSchedule/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeNewSchedule/ja"

export const EndDateTimeComponent: React.FC<ValidEndDateProps> = ({
  validEndDateTime,
  setValidEndDateTime,
  setDateError,
  defaultDate,
  scheduleDayDefaultEndTime,
  setErrorMessages,
  checkMeetingOrSeat,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const { control, watch, setValue, getValues } = useFormContext<ScheduleForm>()

  const handleStartDateTimeChange = (newEndDateTime: Date | null) => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.EndTimeReset])
    }
    compareToStartTime(newEndDateTime)
  }

  const compareToStartTime = (newEndDateTime: Date | null) => {
    const startDate = getValues("schedule.start_date")
    const endDate = watch("schedule.end_date")
    const startTime = getValues("schedule.start_time")

    if (
      !!newEndDateTime &&
      !!startDate &&
      !!endDate &&
      !!startTime &&
      startDate.getFullYear() == endDate.getFullYear() &&
      startDate.getMonth() == endDate.getMonth() &&
      startDate.getDate() == endDate.getDate() &&
      startTime.getTime() >= newEndDateTime.getTime()
    ) {
      setValue(
        "schedule.start_time",
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes()
        )
      )
      setValidEndDateTime("#d32f2f")
      setDateError(false)
    }
    if (endDate && newEndDateTime) {
      setValue(
        "schedule.end_time",
        new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          newEndDateTime.getHours(),
          newEndDateTime.getMinutes()
        )
      )
    } else {
      setValue("schedule.end_time", newEndDateTime)
    }
  }

  const handleStartDateTimeBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.EndTimeReset])
    }

    const inputTime = event.target.value
    const hour = ~inputTime.indexOf("午")
      ? inputTime.substring(3, inputTime.indexOf(":"))
      : inputTime.substring(0, inputTime.indexOf(":"))
    const minutes = inputTime.substring(inputTime.indexOf(":") + 1)
    const endDate = getValues("schedule.end_date")
    if (hour != null && minutes != null && endDate != null) {
      const newEndTime = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        Number(hour),
        Number(minutes)
      )
      compareToStartTime(newEndTime)
    }
  }

  //初回レンダリング時
  useEffect(() => {
    if (scheduleDayDefaultEndTime) {
      //終了時刻の初期表示にセット
      setValue(
        "schedule.end_time",
        new Date(
          defaultDate.getFullYear(),
          defaultDate.getMonth(),
          defaultDate.getDate(),
          scheduleDayDefaultEndTime
        )
      )
    }
  }, [])

  return (
    <>
      <Box
        display={watch("schedule.whole_day_flag") ? "none" : "block"}
        sx={{ marginRight: "1rem" }}
      >
        <Controller
          control={control}
          name="schedule.end_time"
          render={({ field }) => (
            <TimePicker
              {...field}
              label={translations.EndTime}
              views={["hours", "minutes"]}
              onChange={handleStartDateTimeChange}
              slots={{
                openPickerIcon: AccessTime,
              }}
              slotProps={{
                openPickerIcon: {
                  sx: {
                    padding: "0",
                  },
                },
                textField: {
                  onBlur: handleStartDateTimeBlur,
                  sx: {
                    "& label": {
                      color:
                        validEndDateTime === "rgba(0, 0, 0, 0.23)"
                          ? "rgba(0, 0, 0, 0.6)"
                          : "#d32f2f",
                      fontSize: "12px",
                      fontFamily: "Noto Sans",
                    },
                    "& .MuiOutlinedInput-input": {
                      fontSize: "12px",
                      fontFamily: "Noto Sans",
                      padding: "5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: validEndDateTime,
                      },
                    },
                  },
                  required: true,
                  id: "end_date_time",
                  name: "end_date_time",
                },
              }}
            />
          )}
        />
      </Box>
    </>
  )
}
