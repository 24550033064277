const ja = {
  scheduleTitle: "スケジュールタイトルを入力",
  Allday: "終日",
  day: "日",
  everyMonths: "ヶ月ごとの",
  everyWeeks: "週ごとの",
  first: "第1",
  second: "第2",
  third: "第3",
  fourth: "第4",
  last: "最終",
  DayOfWeek: "曜日",
  private: "非公開",
  public: "公開",
  Public: "公開する",
  Attendees: "参加者",
  Message: "メッセージ",
  AddDeskMeetingRoom: "席・会議室の追加",
  StartDate: "開始日",
  StartTime: "開始時刻",
  EndDate: "終了日",
  EndTime: "終了時刻",
  RepeatSettings: "繰り返しの設定",
  Weekly: "週ごと",
  Monthly: "月ごと",
  Day: "日",
  ConfirmReservation: "予約する",
  Add: "追加する",
  Reset: "リセット",
  RepeatSetting: "繰り返し頻度",
  Update: "更新する",
  Edit: "編集",
  Confirm: "確定する",
  Capacity: "人",

  // 曜日
  Sunday: "日曜日",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",

  // 会議室、席追加
  UsedBefore: "最近利用した席・会議室",
  FloorMap: "場所を確認する",
  Desks: "席",
  MeetingRooms: "会議室",
  // "確定する",

  // エラーメッセージ
  SchedulePast: "過去の予定を作成することはできません",
  SpanDays: "日をまたいでの予定を作成することはできません",
  OutsideBusinessHours: "営業時間外での予定を作成することはできません",
  StartDateReset:
    "開始日を変更する場合、選択している席・会議室は一度リセットしてください",
  StartTimeReset:
    "開始時刻を変更する場合、選択している席・会議室は一度リセットしてください",
  EndDateReset:
    "終了日を変更する場合、選択している席・会議室は一度リセットしてください",
  EndTimeReset:
    "終了時刻を変更する場合、選択している席・会議室は一度リセットしてください",
  AllDayReset:
    "終日を変更する場合、選択している席・会議室は一度リセットしてください",
  RepeatReset:
    "繰り返しの設定を変更する場合、選択している席・会議室は一度リセットしてください",
  RepeatFrequencyChange:
    "繰り返し頻度を変更する場合、選択している席・会議室は一度リセットしてください",
}
export default ja
