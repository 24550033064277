import React from "react"

import { MobileLayout } from "../../../components/mobile/layouts/MobileLayoutMain"
import { Loading } from "../../../components/public/Loading"
import { useLayout } from "../../../models/employee/useLayout"
import { EmployeeLayoutRequestType } from "../../../models/employee/useLayout/type"

export const MobileLayoutPage = () => {
  const layoutObject = useLayout()

  const handleLayoutChange = async (params: EmployeeLayoutRequestType) => {
    await layoutObject.fetchLayout(params, layoutObject.companyRelations.id)
  }
  return (
    <>
      {layoutObject.loading && (
        <Loading type="content" loading={layoutObject.loading} />
      )}

      <MobileLayout handleLayoutChange={handleLayoutChange} {...layoutObject} />
    </>
  )
}
