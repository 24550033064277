import React from "react"
import {
  SubmitHandler,
  UseFormHandleSubmit,
  FieldErrors,
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Box,
  FormHelperText,
  styled,
} from "@mui/material"

import { BranchFormType } from "../../../../models/company/useBranch/type"
import { prefectureList } from "../../../../support/prefecture"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
}))
const CustomBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(7),
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

interface Props {
  control: Control<BranchFormType>
  errors: FieldErrors<BranchFormType>
  isValid: boolean
  errorMessageOpen: boolean
  errorMessages: string[]
  handleSubmit: UseFormHandleSubmit<BranchFormType>
  onCreateSubmit: SubmitHandler<BranchFormType>
  onUpdateSubmit: SubmitHandler<BranchFormType>
  getValues: UseFormGetValues<BranchFormType>
  setValue: UseFormSetValue<BranchFormType>
  forbidden: boolean
}

export const CompanyBranchForm: React.FC<Props> = ({
  control,
  handleSubmit,
  onCreateSubmit,
  onUpdateSubmit,
  errors,
  isValid,
  getValues,
  errorMessageOpen,
  errorMessages,
  forbidden,
}: Props) => {
  const navigate = useNavigate()
  const urlParams = useParams<{ id: string }>()

  // TODO 右辺のonCreateSubmitは編集機能の時にonUpdateSubmitに置き換えるので仮置きです
  const handleSubmitEvent =
    getValues("branch_id") === 0 ? onCreateSubmit : onUpdateSubmit

  return (
    <>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="branch_name">拠点名称</InputLabel>
        </CustomGridItem>
        <Grid item xs={4} sm={4}>
          <Controller
            control={control}
            name="branch_name"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.branch_name}
                helperText={errors.branch_name?.message}
                required
                id="branch_name"
                name="branch_name"
                label="拠点名称"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="branch_postal_code">郵便番号</InputLabel>
        </CustomGridItem>
        <Grid item xs={4} sm={4}>
          <Controller
            control={control}
            name="branch_postal_code"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.branch_postal_code}
                helperText={errors.branch_postal_code?.message}
                required
                id="branch_postal_code"
                name="branch_postal_code"
                label="郵便番号"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="branch_prefecture_id">都道府県</InputLabel>
        </CustomGridItem>
        <Grid item xs={12} sm={5}>
          <FormControl sx={{ width: 400 }}>
            <InputLabel htmlFor="branch_prefecture_id">都道府県*</InputLabel>
            <Controller
              control={control}
              name="branch_prefecture_id"
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.branch_prefecture_id}
                  id="branch_prefecture_id"
                  name="branch_prefecture_id"
                  variant="outlined"
                  margin="dense"
                  label="都道府県 *"
                  labelId="都道府県 *"
                  fullWidth
                  disabled={forbidden}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    都道府県を選択してください
                  </MenuItem>
                  {prefectureList.map((prefecture) => (
                    <MenuItem key={prefecture.id} value={prefecture.id}>
                      {prefecture.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText error={!!errors.branch_prefecture_id?.message}>
              {errors.branch_prefecture_id?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="branch_city">市区町村</InputLabel>
        </CustomGridItem>
        <Grid item xs={12} sm={8}>
          <Controller
            control={control}
            name="branch_city"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.branch_city}
                helperText={errors.branch_city?.message}
                required
                id="branch_city"
                name="branch_city"
                label="市区町村"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="branch_house_number">番地以降</InputLabel>
        </CustomGridItem>
        <Grid item xs={12} sm={8}>
          <Controller
            control={control}
            name="branch_house_number"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.branch_house_number}
                helperText={errors.branch_house_number?.message}
                required
                id="branch_house_number"
                name="branch_house_number"
                label="番地以降"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="branch_building_name">建物名</InputLabel>
        </CustomGridItem>
        <Grid item xs={12} sm={8}>
          <Controller
            control={control}
            name="branch_building_name"
            render={({ field }) => (
              <TextField
                {...field}
                id="branch_building_name"
                name="branch_building_name"
                label="建物名"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="capacity">収容人数</InputLabel>
        </CustomGridItem>
        <Grid item xs={4} sm={4}>
          <Controller
            control={control}
            name="capacity"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.capacity}
                helperText={errors.capacity?.message}
                required
                id="capacity"
                name="capacity"
                label="収容人数"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="floor_space">床面積</InputLabel>
        </CustomGridItem>
        <Grid item xs={4} sm={4}>
          <Controller
            control={control}
            name="floor_space"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.floor_space}
                helperText={errors.floor_space?.message}
                value={field.value ? field.value : ""}
                id="floor_space"
                name="floor_space"
                fullWidth
                variant="outlined"
                disabled={forbidden}
              />
            )}
          />
        </Grid>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="note">㎡</InputLabel>
        </CustomGridItem>
      </CustomGridContainer>
      <CustomGridItem item xs={12} sm={2}>
        <InputLabel htmlFor="note">備考</InputLabel>
      </CustomGridItem>
      {/* TODO備考とフォームの距離修正 */}
      <Grid item xs={4} sm={4}>
        <Controller
          control={control}
          name="note"
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.note}
              helperText={errors.note?.message}
              id="note"
              name="note"
              label="最大文字数1000文字"
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              disabled={forbidden}
            />
          )}
        />
      </Grid>
      <CustomBox>{errorMessageOpen && ErrorText(errorMessages)}</CustomBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "450px",
          m: "30px auto",
        }}
      >
        <CancelButton
          label="戻る"
          handleClick={() =>
            navigate(
              Number(urlParams.id) > 0
                ? `/company/branches/${urlParams.id}`
                : "/company/branches"
            )
          }
        />
        <ReflectButton
          label={getValues("branch_id") === 0 ? "登録" : "更新"}
          disabled={!isValid || forbidden}
          handleClick={handleSubmit(handleSubmitEvent)}
        />
      </Box>
    </>
  )
}
