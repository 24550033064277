import React from "react"

import { Box } from "@mui/material"

interface Props {
  color: string
}

export const Down = ({ color }: Props) => {
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        marginLeft: "10px",
        marginRight: "10px",
        borderLeft: "30px solid transparent",
        borderRight: "30px solid transparent",
        borderTop: `20px solid ${color}`,
      }}
    />
  )
}

export const Right = () => {
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        marginLeft: "10px",
        marginRight: "10px",
        borderTop: "30px solid transparent",
        borderBottom: "30px solid transparent",
        borderLeft: "20px solid #22ba9d",
      }}
    />
  )
}
