import React from "react"

import { Box } from "@mui/material"

import {
  MeetingRoomType,
  SeatObjectType,
} from "../../../../models/employee/useLayout/type"
import { EmployeeLayoutSearch } from "../common/EmployeeLayoutSearch"

interface Props {
  floorId: number
  handleSeatHighLight: (seatId: number) => void
  handleEmployeePlaceSearch: (targetEmployeeId: number) => void
  selectedSeat: SeatObjectType | undefined
  selectedMeetingRoom: MeetingRoomType | undefined
  userNotExists: boolean
  userNotExistsMessage: string
  setUserNotExists: React.Dispatch<React.SetStateAction<boolean>>
}

// 席状況のサイドバー
export const EmployeeLayoutSidebar = ({
  handleEmployeePlaceSearch,
  userNotExists,
  userNotExistsMessage,
  setUserNotExists,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Box width="100%">
        <EmployeeLayoutSearch
          type="layout"
          handleEmployeeSearch={handleEmployeePlaceSearch}
          userNotExists={userNotExists}
          userNotExistsMessage={userNotExistsMessage}
          setUserNotExists={setUserNotExists}
        />
      </Box>
      {/*<Box borderTop="solid 1px #E5E5E5">
        <EmployeeLayoutRecentSeats
          recentSeats={recentSeats}
          floorId={floorId}
          handleSeatHighLight={handleSeatHighLight}
        />
      </Box>*/}
    </Box>
  )
}
