import { useState, useCallback, useEffect } from "react"

import {
  fetchCheckInOutSeatRequest,
  updateCheckInOutSeatRequest,
  updateCheckOutLeaveScheduleRequest,
} from "../../../api/employee/seatRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeHome/employeeHomeSchedule/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeSchedule/ja"
import { CheckInOutRequestType, CheckInOutResponseType } from "./type"

const initialCheckInOut: CheckInOutResponseType = {
  check_in: {
    is_check_in: false,
    schedule: {
      id: 0,
      scheduled_date: new Date(),
      start_time: new Date(),
      end_time: new Date(),
      schedule_information_id: 0,
      reservable: {
        seat_name: "",
        branch_name: "",
        floor_number: 0,
        floor_name: "",
      },
    },
  },
}

// チェックイン
export const useCheckInOutSeat = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [checkInOutSeat, setCheckInOutSeat] =
    useState<CheckInOutResponseType>(initialCheckInOut)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchCheckInOutSeat = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await fetchCheckInOutSeatRequest()
      if (response.status === 200 && response.data) {
        setCheckInOutSeat(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setIsLoading(false)
    }
  }, [])

  const updateCheckInOutSeat = async (data: CheckInOutRequestType) => {
    const { error } = await updateCheckInOutSeatRequest(data)
    if (!error) {
      localStorage.setItem(
        "alertContent",
        `${data.checked_in ? translations.checkedIn : translations.checkedOut}`
      )
      window.location.reload()
    } else {
      setErrorMessages([translations.CheckInFailed])
    }
  }

  useEffect(() => {
    fetchCheckInOutSeat()
  }, [])

  return {
    isLoading,
    checkInOutSeat,
    fetchCheckInOutSeat,
    updateCheckInOutSeat,
    errorMessages,
  }
}
