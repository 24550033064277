import React from "react"

import { Box, styled, Typography } from "@mui/material"

const PointWrapper = styled(Box)(() => ({
  marginBottom: "2rem",
}))

interface Props {
  number: number
  firstTitle: string
  secondTitle: string
  descriptions: string[]
}

export const Point = ({
  number,
  firstTitle,
  secondTitle,
  descriptions,
}: Props) => {
  const description = descriptions.map((text, i) => (
    <Typography sx={{ fontSize: "20px" }} key={i}>
      {text}
    </Typography>
  ))
  return (
    <PointWrapper>
      <Box
        textAlign="center"
        sx={{ display: "flex", height: "40px", marginBottom: "1.5rem" }}
      >
        <Typography
          sx={{
            backgroundColor: "#22ba9d",
            color: "#FFFFFF",
            fontSize: "30px",
            fontWeight: 700,
            border: "1px solid #22ba9d",
            borderRadius: "10px",
            width: "40px",
            lineHeight: "40px",
          }}
        >
          {number}
        </Typography>
        <Typography
          sx={{
            color: "#22ba9d",
            height: "100%",
            lineHeight: "42px",
            fontSize: "28px",
            fontWeight: 700,
            marginLeft: "10px",
          }}
        >
          {firstTitle}
        </Typography>
        <Typography
          sx={{
            height: "100%",
            lineHeight: "42px",
            fontSize: "28px",
            fontWeight: 700,
            marginLeft: "20px",
          }}
        >
          {secondTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f4f8f9",
          borderRadius: "10px",
          padding: "15px",
        }}
      >
        {description}
      </Box>
    </PointWrapper>
  )
}
