import React, { memo } from "react"

import { Button, Box, Typography, styled } from "@mui/material"

import { theme } from "../../../config/theme"

export const CustomButton = styled(Button)({
  width: "300px",
  fontSize: theme.spacing(3),
  fontWeight: "bold",
  border: "2px solid",
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(2),
  marginTop: "50px",
  textTransform: "none",
})

interface Props {
  label: string
  subLabel?: string
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const SignageMainOutlineErrorButton: React.FC<Props> = memo(
  ({ label, subLabel, handleClick }: Props) => {
    return (
      <CustomButton
        type="submit"
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            component="div"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            {label}
          </Typography>
          {subLabel && (
            <Typography component="div" sx={{ fontSize: "16px" }}>
              {subLabel}
            </Typography>
          )}
        </Box>
      </CustomButton>
    )
  }
)
