export const oneWeek = () => {
  // 前週の最初の日
  const previousStartOfWeek = (beginningOfWeek: Date) => {
    return new Date(
      beginningOfWeek.getFullYear(),
      beginningOfWeek.getMonth(),
      beginningOfWeek.getDate() - 7
    )
  }

  // 前週の最後の日
  const previousEndOfWeek = (beginningOfWeek: Date) => {
    return new Date(
      beginningOfWeek.getFullYear(),
      beginningOfWeek.getMonth(),
      beginningOfWeek.getDate() - 1
    )
  }

  // 翌週の最初の日
  const nextStartOfWeek = (endOfWeek: Date) => {
    return new Date(
      endOfWeek.getFullYear(),
      endOfWeek.getMonth(),
      endOfWeek.getDate() + 1
    )
  }

  // 翌週の最後の日
  const nextEndOfWeek = (endOfWeek: Date) => {
    return new Date(
      endOfWeek.getFullYear(),
      endOfWeek.getMonth(),
      endOfWeek.getDate() + 7
    )
  }

  // 対象日の週の最初の日を計算
  const setTargetWeeks = (day: Date) => {
    const dayNum = day.getDay()
    const newBeginningOfWeek = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate() - dayNum
    )

    const newEndOfThisWeek = new Date(
      newBeginningOfWeek.getFullYear(),
      newBeginningOfWeek.getMonth(),
      newBeginningOfWeek.getDate() + 6
    )
    return { newBeginningOfWeek, newEndOfThisWeek }
  }

  return {
    previousStartOfWeek,
    previousEndOfWeek,
    nextStartOfWeek,
    nextEndOfWeek,
    setTargetWeeks,
  }
}
