import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"

import React, { useEffect } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  Checkbox,
  Box,
  IconButton,
  FormControlLabel,
  Slider,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { useSyncLanguageAcrossTabs } from "../../../../../src/utils/useSyncLanguageAcrossTabs"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { EmployeeLayoutRequestType } from "../../../../models/employee/useLayout/type"
import {
  Branch,
  CompanyRelationsType,
} from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/mobileWorkPlace/en"
import jaTranslations from "../../../../translations/mobileWorkPlace/ja"
import { fifteenMinutesIntervalHours } from "../../../../utils/hours"
import { useOpen } from "../../../../utils/isOpen"

const CustomBoldTypography = styled(Typography)({
  letterSpacing: "0.8px",
  fontWeight: "bold",
})

const GreenArrowIcon = styled(ExpandMoreIcon)(() => ({
  color: "#22BA9D !important",
  right: "1px !important",
}))

interface Props {
  companyRelations: CompanyRelationsType
  changeSelectValue: (e: SelectChangeEvent<number>) => void
  handleLayoutChange: (params: EmployeeLayoutRequestType) => void
  branchId: number
  floorId: number
  filterSeatSchedules: (scheduled_date: string, start_time: string) => void
  filterMeetingRoomSchedules: (
    scheduled_date: string,
    start_time: string
  ) => void
  handleFilterWholeDaySchedule: (
    scheduledDate: string,
    wholeDayFlag: boolean,
    startTime?: string
  ) => void
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  dayOfWeek: string[]
  startDate: Date
  endDate: Date
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  formatDate: (date: Date) => string
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  handleFilterSchedule: (wholeDayFlag: boolean) => void
  selectedBranch: Branch
}

// 拠点・フロアおよび日時の切り替えを行う
export const MobileLayoutHeader: React.FC<Props> = ({
  companyRelations,
  changeSelectValue,
  handleLayoutChange,
  branchId,
  floorId,
  filterSeatSchedules,
  filterMeetingRoomSchedules,
  handleFilterWholeDaySchedule,
  date,
  setDate,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  weekdayNumber,
  setWeekdayNumber,
  dayOfWeek,
  startDate,
  endDate,
  timeIndex,
  setTimeIndex,
  formatDate,
  setStartDate,
  setEndDate,
  wholeDayFlag,
  setWholeDayFlag,
  loading,
  handleFilterSchedule,
  selectedBranch,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useSyncLanguageAcrossTabs()

  const month = date.getMonth() + 1
  const day = date.getDate()
  const time = fifteenMinutesIntervalHours[timeIndex]
  const weekdayText = "(" + dayOfWeek[weekdayNumber] + ")"
  const scheduleCalendar = useOpen()

  // 時刻を切り替えた時の挙動
  const handleTimeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setTimeIndex(value)
      const time = fifteenMinutesIntervalHours[value]
      filterSeatSchedules(formatDate(date), time)
      filterMeetingRoomSchedules(formatDate(date), time)
    }
  }

  // 初回レンダリング時、スライダーの初期値を現在時刻に合わせる
  useEffect(() => {
    // TODO いずれ共通化する
    const nowTime = new Date()
    let nowMinutes = nowTime.getMinutes()
    const nowHours = nowTime.getHours()
    // 現在時刻が6時から23時代の間の場合
    if (6 <= nowHours && nowHours <= 23) {
      switch ((nowMinutes / 15) | 0) {
        case 0:
          nowMinutes = 0
          break
        case 1:
          nowMinutes = 15
          break
        case 2:
          nowMinutes = 30
          break
        case 3:
          nowMinutes = 45
          break
      }
      //timeIndexをセットする
      setTimeIndex(
        fifteenMinutesIntervalHours.findIndex(
          (fifteenMinutesIntervalHour) =>
            fifteenMinutesIntervalHour ===
            nowHours + ":" + String(nowMinutes).padStart(2, "0")
        )
      )
    }
  }, [])

  // 2日以上日付をずらした場合にAPIリクエストを再送信する。その後の挙動
  useEffect(() => {
    if (!loading) {
      handleFilterSchedule(wholeDayFlag)
    }
  }, [loading])

  // 終日のチェックボックスを外した時の挙動
  useEffect(() => {
    if (wholeDayFlag) {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag)
    } else {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag, time) // falseの場合は日付と時刻をベースに予定をfilterし直す
    }
  }, [wholeDayFlag])

  // フロアを切り替えるたびにレイアウト情報を取得し直す
  useEffect(() => {
    if (floorId > 0) {
      handleLayoutChange({
        branch_id: branchId,
        floor_id: floorId,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      })
    }
  }, [floorId])

  // 日付を切り替えた場合の挙動
  useEffect(() => {
    if (floorId > 0) {
      // if (date < startDate || date > endDate) {
      //   const newStartDate = new Date(
      //     date.getFullYear(),
      //     date.getMonth(),
      //     date.getDate() - 1
      //   )
      //   const newEndDate = new Date(
      //     date.getFullYear(),
      //     date.getMonth(),
      //     date.getDate() + 1
      //   )
      //   const refreshSchedule = async () => {
      //     await Promise.all([
      //       handleLayoutChange({
      //         branch_id: branchId,
      //         floor_id: floorId,
      //         start_date: formatDate(newStartDate),
      //         end_date: formatDate(newEndDate),
      //       }),
      //     ])
      //   }
      //   refreshSchedule().then(() => {
      //     setStartDate(newStartDate)
      //     setEndDate(newEndDate)
      //   })
      // } else {
      //   handleFilterSchedule(wholeDayFlag)
      // }
      const newStartDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const newEndDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const refreshSchedule = async () => {
        await Promise.all([
          handleLayoutChange({
            branch_id: branchId,
            floor_id: floorId,
            start_date: formatDate(newStartDate),
            end_date: formatDate(newEndDate),
          }),
        ])
      }
      refreshSchedule().then(() => {
        setStartDate(newStartDate)
        setEndDate(newEndDate)
      })
    }
  }, [date])

  // dateにカレンダーで選択された日付を設定する。
  const handleDateChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setDate(new Date(newValue))
    }
  }

  // weekdayにカレンダーで選択された日付の曜日を設定する。
  const handleWeekdayChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setWeekdayNumber(newValue.getDay())
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item container alignItems="center" xs={12} spacing={1}>
          <Grid item container alignItems="center" xs={6}>
            <Grid item xs={3} textAlign="center">
              <InputLabel
                htmlFor="branch_id"
                color="primary"
                sx={{
                  height: "16px",
                  color: "#22BA9D",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {`${fifteenMinutesIntervalHours[22]}`}
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth>
                <InputLabel htmlFor="branch_id" sx={{ fontSize: "10px" }}>
                  {translations.Location}
                </InputLabel>
                <Select
                  id="branch_id"
                  name="branch_id"
                  label={translations.Location}
                  fullWidth
                  value={branchId}
                  variant="outlined"
                  onChange={changeSelectValue}
                  sx={{
                    height: "24px",
                    background: "rgba(112,112,112,0.05)",
                    fontSize: "10px",
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    {translations.Location}
                  </MenuItem>
                  {companyRelations.branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" xs={6}>
            <Grid item xs={3} textAlign="center">
              <InputLabel
                htmlFor="floor_id"
                sx={{
                  color: "#22BA9D",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {translations.Floor}
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl fullWidth>
                <InputLabel htmlFor="floor_id" sx={{ fontSize: "9px" }}>
                  {translations.Floor}
                </InputLabel>
                <Select
                  id="floor_id"
                  name="floor_id"
                  label={translations.Floor}
                  fullWidth
                  variant="outlined"
                  onChange={changeSelectValue}
                  value={floorId}
                  sx={{
                    height: "24px",
                    background: "rgba(112,112,112,0.05)",
                    fontSize: "10px",
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    {translations.Floor}
                  </MenuItem>
                  {selectedBranch &&
                    selectedBranch.floors
                      .filter((floor) =>
                        floor.layouts.some(
                          (layout) =>
                            layout.release_date !== null &&
                            new Date(layout.release_date) <= new Date()
                        )
                      )
                      .map((floor) => (
                        <MenuItem key={floor.id} value={floor.id}>
                          {floor.floor_name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12} spacing={0}>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={8}
          >
            <IconButton
              onClick={
                floorId > 0
                  ? () => {
                      previousDate()
                      previousWeekday()
                    }
                  : () => null
              }
              disabled={floorId === 0}
            >
              <ChevronLeftIcon
                color={floorId > 0 ? "primary" : "disabled"}
                fontSize="large"
              />
            </IconButton>
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={language === "ja" ? ja : enUS}
              >
                <DesktopDatePicker
                  label="DatePicker"
                  value={date}
                  open={scheduleCalendar.isOpen}
                  onChange={(event) => {
                    handleDateChange(event)
                    handleWeekdayChange(event)
                    scheduleCalendar.close()
                  }}
                  onClose={scheduleCalendar.close}
                  slots={{
                    textField: () => (
                      <CustomBoldTypography
                        variant="button"
                        onClick={scheduleCalendar.open}
                        sx={{ cursor: "pointer" }}
                      >
                        {`${month}${translations.month}${day}${translations.day}${translations.weekdays[weekdayNumber]}`}
                      </CustomBoldTypography>
                    ),
                    previousIconButton: IconButton,
                    nextIconButton: IconButton,
                  }}
                  slotProps={{
                    popper: {
                      sx: { marginTop: "200px", marginLeft: "30px" },
                    },
                    textField: {
                      onClick: scheduleCalendar.open,
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <IconButton
              onClick={
                floorId > 0
                  ? () => {
                      nextDate()
                      nextWeekday()
                    }
                  : () => null
              }
              disabled={floorId === 0}
            >
              <ChevronRightIcon
                color={floorId > 0 ? "primary" : "disabled"}
                fontSize="large"
              />
            </IconButton>
          </Grid>

          <Grid item container justifyContent="center" xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={wholeDayFlag}
                  onChange={() => setWholeDayFlag(!wholeDayFlag)}
                />
              }
              label={
                <Typography fontSize="14px">{translations.AllDay}</Typography>
              }
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid xs={10}>
            <Slider
              aria-label="Always visible"
              disabled={wholeDayFlag}
              max={fifteenMinutesIntervalHours.length - 1}
              step={1}
              valueLabelDisplay="on"
              valueLabelFormat={fifteenMinutesIntervalHours[timeIndex]}
              value={timeIndex}
              onChange={handleTimeChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
