const ja = {
  createSchedule: "新規スケジュール",
  findMember: "従業員検索",
  searchName: "従業員名を入力",
  location: "拠点",
  floor: "フロア",
  businessUnit: "所属",
  Favorite: "お気に入り",
  Team: "グループ",
  Search: "検索",
  Member: "従業員",
  BusinessUnit: "所属",
  Manager: "所属長",
  Location: "拠点",
  Cancel: "キャンセル",
  Enter: "確定",
}
export default ja
