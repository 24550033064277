import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { employeeSearchRequest } from "../../../../api/employee/employeeRequest"
import { useLanguage } from "../../../../contexts/LanguageContext"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { downloadIcon } from "../../../../utils/downLoadIcon"
import {
  Employee,
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "./type"

const initialSearchEmployeeParams: SearchEmployeeRequestType = {
  freeword: "",
  branch_id: 0,
  floor_id: 0,
  team_id: 0,
  employee_group_id: 0,
}

export const useScheduleSearchEmployee = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [loading, setLoading] = useState<boolean>(false)
  const storageSelectedEmployees = localStorage.getItem("selectedEmployees")
  const [searchEmployeeParams] = useState<SearchEmployeeRequestType>(
    initialSearchEmployeeParams
  )
  const [searchedEmployeeResult, setSearchedEmployeeResult] =
    useState<SearchedEmployeeResult>([])
  const [selectedEmployees, setSelectedEmployees] =
    useState<SearchedEmployeeResult>(
      storageSelectedEmployees
        ? JSON.parse(storageSelectedEmployees as string)
        : []
    )

  // 必須項目（従業員名、拠点、所属、お気に入りのいずれか）のバリデーション
  const validateRequireValues = function (this: any) {
    const { freeword, branch_id, team_id, employee_group_id } = this.parent
    return !(
      freeword.length === 0 &&
      branch_id === 0 &&
      team_id === 0 &&
      employee_group_id === 0
    )
  }

  const basicSchema = Yup.object().shape({
    freeword: Yup.string().test(
      "maxlength",
      translations.fiftyEmployeeSearch,
      (freeword) => {
        if (freeword == undefined) {
          return true
        }
        return freeword.length <= 50
      }
    ),

    floor_id: Yup.number().when("branch_id", {
      is: (branch_id: number) => branch_id !== 0,
      then: Yup.number().min(1, translations.pleaseFloor),
    }),
    require: Yup.boolean().test(
      "require_validation",
      translations.EmployeeNameRequired,
      validateRequireValues
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      freeword: searchEmployeeParams.freeword,
      branch_id: searchEmployeeParams.branch_id,
      floor_id: searchEmployeeParams.floor_id,
      team_id: searchEmployeeParams.team_id,
      employee_group_id: searchEmployeeParams.employee_group_id,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSearchEmployeeSubmit: SubmitHandler<SearchEmployeeRequestType> =
    useCallback(async (data) => {
      try {
        setLoading(true)
        const response = await employeeSearchRequest(data)
        if (response.status == 200 && response.data) {
          const defaultSearchEmployeeResult = response.data.map((employee) => {
            return { ...employee, checked: false }
          })
          setSearchedEmployeeResult(defaultSearchEmployeeResult)

          // iconをS3と同期
          const fetchIconsPromises = defaultSearchEmployeeResult.map(
            async (employee: Employee) => {
              if (employee.icon)
                employee.icon = await downloadIcon(
                  employee.account.company_id,
                  employee.id,
                  employee.icon
                )
              return await { ...employee, checked: false }
            }
          )
          Promise.all(fetchIconsPromises).then(setSearchedEmployeeResult)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }, [])

  // 従業員をcheckする
  const handleEmployeeChecked = (selectedEmployeeId: number) => {
    const newSearchEmployeeResult = searchedEmployeeResult.map((employee) => {
      if (employee.id === selectedEmployeeId) {
        return { ...employee, checked: !employee.checked }
      } else {
        return employee
      }
    })

    setSearchedEmployeeResult(newSearchEmployeeResult)
  }

  // モーダル上でcheckした従業員を確定して表示できる様にする
  const handleEmployeeFixed = () => {
    const checkedEmployees = searchedEmployeeResult.filter((employee) => {
      return employee.checked
    })
    const newSelectedEmployees = selectedEmployees.concat(checkedEmployees) // 既存の検索対象者と新しくcheckを入れた従業員を結合

    // 結合した配列をユニークにする
    const uniqueSelectedEmployees = Array.from(
      new Map(
        newSelectedEmployees.map((employee) => [employee.id, employee])
      ).values()
    )

    setSelectedEmployees(uniqueSelectedEmployees)

    localStorage.setItem(
      "selectedEmployees",
      JSON.stringify(uniqueSelectedEmployees)
    )

    return uniqueSelectedEmployees
  }

  // 検索対象者を削除
  const deletedSelectedEmployee = (selectedEmployeeId: number) => {
    const newSelectedEmployees = selectedEmployees.filter((employee) => {
      return employee.id !== selectedEmployeeId
    })

    setSelectedEmployees(newSelectedEmployees)
  }

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    onSearchEmployeeSubmit,
    getValues,
    setValue,
    clearErrors,
    searchedEmployeeResult,
    selectedEmployees,
    handleEmployeeChecked,
    handleEmployeeFixed,
    setSearchedEmployeeResult,
    deletedSelectedEmployee,
    setSelectedEmployees,
    loading,
  }
}
