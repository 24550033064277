import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import LocationOnIcon from "@mui/icons-material/LocationOn"
import { styled, Typography, Box } from "@mui/material"

import { theme } from "../../../../config/theme"
import { SignageMainOutlineButton } from "../../../public/SignageMainOutlineButton"

const CustomBox = styled(Box)(() => ({
  height: "75%",
  backgroundColor: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}))

const CustomBottomBox = styled(Box)(() => ({
  height: "25%",
  backgroundColor: "#F7F7F7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  marginBottom: theme.spacing(2),
}))

const MapTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
}))

interface Props {
  receptionMessage: string
}

export const SignageReceptionHome: React.FC<Props> = ({
  receptionMessage,
}: Props) => {
  const navigate = useNavigate()
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)

  return (
    <>
      <CustomBox>
        <Box>
          {receptionMessage === "" ? (
            <>
              <CustomTypography variant="h5">
                ようこそお越しくださいました
              </CustomTypography>
              <CustomTypography variant="h5">
                スタートボタンより
              </CustomTypography>
              <CustomTypography variant="h5">
                受付を開始してください
              </CustomTypography>
            </>
          ) : (
            <CustomTypography
              sx={{ width: "500px", whiteSpace: "pre-wrap" }}
              variant="h5"
            >
              {receptionMessage}
            </CustomTypography>
          )}
        </Box>
        <Box mt={5}>
          <SignageMainOutlineButton
            label="Start"
            handleClick={() =>
              navigate(`/signage/branches/${branchId}/reception/menu`)
            }
          />
        </Box>
      </CustomBox>
      <CustomBottomBox
        onClick={() => navigate(`/signage/branches/${branchId}/reception/map`)}
      >
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
          }}
        >
          <LocationOnIcon color="primary" sx={{ fontSize: "40px" }} />
          <MapTypography variant="h4">MAP</MapTypography>
        </Box>
      </CustomBottomBox>
    </>
  )
}
