import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState, useCallback, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import {
  createEmployeeGroupRequest,
  deleteEmployeeGroupRequest,
  fetchEmployeeGroupsRequest,
  updateEmployeeGroupRequest,
} from "../../../api/employee/employeeGroupRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/errorMessage/en"
import jaTranslations from "../../../translations/errorMessage/ja"
import {
  FULL_WIDTH_AND_HALFWIDTH,
  FULL_WIDTH_KATAKANA,
} from "../../../utils/regular"
import { EmployeeGroupsType, EmployeeGroupRequestType } from "./type"

const initialEmployeeGroupData: EmployeeGroupRequestType = {
  id: null,
  group_name: "",
  group_name_kana: "",
  group_type: 0,
  employee_ids: [],
}

export const useEmployeeGroup = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroupsType>([])
  const [editFormOpen, setEditFormOpen] = useState<boolean>(false)
  const [isCreateEmployeeGroupModalOpen, setIsCreateEmployeeGroupModalOpen] =
    useState<boolean>(false)

  const basicSchema = Yup.object().shape({
    group_name: Yup.string()
      .required(translations.Required)
      .matches(FULL_WIDTH_AND_HALFWIDTH, translations.UseHiragana),

    // グループ名カナが空の時はバリデーションパス
    group_name_kana: Yup.string().test(
      "Is full-width katakana or empty",
      translations.Katakana,
      function (value) {
        if (!value) {
          return true
        }
        return FULL_WIDTH_KATAKANA.test(value)
      }
    ),
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      id: initialEmployeeGroupData.id,
      group_name: initialEmployeeGroupData.group_name,
      group_name_kana: initialEmployeeGroupData.group_name_kana,
      group_type: initialEmployeeGroupData.group_type,
      employee_ids: initialEmployeeGroupData.employee_ids,
    },
    resolver: yupResolver(basicSchema),
  })

  const onCreateSubmit: SubmitHandler<EmployeeGroupRequestType> = useCallback(
    async (params) => {
      try {
        const { data, error } = await createEmployeeGroupRequest({
          ...params,
        })
        if (data) {
          setEmployeeGroups(data)

          // 成功メッセージの表示
          setShowSuccessMessage(true)
          setSuccessMessage(translations.favoriteAdd)

          // フォームの入力値のリセット
          setValue("group_name", "")
          setValue("group_name_kana", "")
          setValue("employee_ids", [])

          setErrorMessages([])
          setIsCreateEmployeeGroupModalOpen(false)
        } else if (error) {
          setErrorMessages(error)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error)
      }
    },
    []
  )

  const onUpdateSubmit: SubmitHandler<EmployeeGroupRequestType> = useCallback(
    async (params) => {
      try {
        const { data, error } = await updateEmployeeGroupRequest({
          ...params,
        })
        if (data) {
          setEmployeeGroups(data)

          // 成功メッセージの表示
          setShowSuccessMessage(true)
          setSuccessMessage(translations.favoriteDelete)

          // フォームの入力値のリセット
          setValue("group_name", "")
          setValue("group_name_kana", "")
          setValue("employee_ids", [])

          setErrorMessages([])
          setEditFormOpen(false)
        } else if (error) {
          setErrorMessages(error)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error)
      }
    },
    []
  )

  const fetchEmployeeGroups = useCallback(async () => {
    try {
      const response = await fetchEmployeeGroupsRequest()
      if (response.status === 200 && response.data) {
        setEmployeeGroups(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }, [])

  const deleteEmployeeGroup = useCallback(async (employeeGroupId: number) => {
    try {
      const response = await deleteEmployeeGroupRequest({
        id: employeeGroupId,
      })
      if (response.status === 200 && response.data) {
        // 成功メッセージの表示
        setShowSuccessMessage(true)
        setSuccessMessage(translations.favoriteDelete)
        setEmployeeGroups(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }, [])

  useEffect(() => {
    fetchEmployeeGroups()
  }, [])

  return {
    control,
    trigger,
    errors,
    isValid,
    clearErrors,
    errorMessages,
    setErrorMessages,
    showSuccessMessage,
    successMessage,
    setShowSuccessMessage,
    handleSubmit,
    onCreateSubmit,
    getValues,
    setValue,
    employeeGroups,
    fetchEmployeeGroups,
    deleteEmployeeGroup,
    onUpdateSubmit,
    editFormOpen,
    setEditFormOpen,
    isCreateEmployeeGroupModalOpen,
    setIsCreateEmployeeGroupModalOpen,
  }
}
