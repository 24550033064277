import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  styled,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import { useDashboard } from "../../../models/company/useDashboard"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import enTranslations from "../../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeTitle/ja"
import { EmployeeHomeContent } from "./EmployeeHomeContent"
import { EmployeeHomeLayoutContentComponent } from "./EmployeeHomeLayoutContentComponent"

////////// Title

const EmployeeHomeLayoutTitleStyle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  margin: "0 10px 0 0",
})

const EmployeeHomeLayoutTitle: React.FC = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <EmployeeHomeLayoutTitleStyle>
      {translations.seatStatus}
    </EmployeeHomeLayoutTitleStyle>
  )
}

////////// Content

const EmployeeHomeLayoutContent: React.FC = () => {
  const {
    layoutImg,
    layout,
    seats,
    meetingRooms,
    areas,
    setFloorId,
    setBranchId,
    setCompanyId,
  } = useDashboard()
  const { floorId, branchId, companyRelations } = useCompanyRelations()

  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // branchとfloorの選択状態を管理
  const [selectBranch, setSelectBranch] = useState<string>("")
  const [selectFloor, setSelectFloor] = useState<string>("")

  useEffect(() => {
    if (companyRelations.id > 0) {
      const currentBranch = companyRelations.branches.find(
        (b) => b.id === branchId
      )
      const currentFloor = currentBranch?.floors.find((f) => f.id === floorId)
      setSelectBranch(currentBranch?.branch_name || "")
      setSelectFloor(currentFloor?.floor_name || "")
      setFloorId(floorId)
      setBranchId(branchId)
      setCompanyId(companyRelations.id)
    }
  }, [companyRelations, branchId, floorId])

  // floorの選択状態を変更
  const handleFloorSelect = (selectedFloorId: number) => {
    setFloorId(selectedFloorId)
    setBranchId(
      companyRelations.branches.find((b) =>
        b.floors.find((f) => f.id === selectedFloorId)
      )?.id || 0
    )
    setCompanyId(companyRelations.id)
  }

  // 緑色のラベルのためのスタイル
  const GreenLabel = styled(Typography)({
    color: "#22BA9D",
    fontWeight: "bold",
    marginRight: "10px",
    minWidth: "50px",
  })

  // branchのFormControlのスタイル
  const StyledFormControlBranch = styled(FormControl)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "400px",
    marginRight: "20px",
    height: "30px",
    fontSize: "0.8rem",
    transform: "scale(0.8)",
    transformOrigin: "left",
    margin: "-6px 0",
  })

  // floorのFormControlのスタイル
  const StyledFormControlFloor = styled(FormControl)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: "200px",
    height: "30px",
    fontSize: "0.8rem",
    transform: "scale(0.8)",
    transformOrigin: "left",
  })

  return (
    <Box>
      <Box mb={2} display="flex" alignItems="center">
        <StyledFormControlBranch>
          <GreenLabel>拠点:</GreenLabel>
          <Select
            value={selectBranch}
            onChange={(event) => {
              setSelectBranch(event.target.value as string)
              // 拠点が変更されたら、フロアをリセット
              setSelectFloor("")
            }}
            displayEmpty
            sx={{ flexGrow: 1 }}
          >
            {companyRelations.branches.map((branch) => (
              <MenuItem key={branch.id} value={branch.branch_name}>
                {branch.branch_name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControlBranch>

        <StyledFormControlFloor>
          <GreenLabel>フロア:</GreenLabel>
          <Select
            value={selectFloor}
            onChange={(event) => {
              const selectedFloorName = event.target.value as string
              setSelectFloor(selectedFloorName)
              const selectedFloor = companyRelations.branches
                .find((b) => b.branch_name === selectBranch)
                ?.floors.find((f) => f.floor_name === selectedFloorName)
              if (selectedFloor) {
                handleFloorSelect(selectedFloor.id)
              }
            }}
            displayEmpty
            disabled={!selectBranch} // 拠点が選択されていない場合は無効化
            sx={{ flexGrow: 1 }}
          >
            {companyRelations.branches
              .find((b) => b.branch_name === selectBranch)
              ?.floors.map((floor) => (
                <MenuItem key={floor.id} value={floor.floor_name}>
                  {floor.floor_name}
                </MenuItem>
              ))}
          </Select>
        </StyledFormControlFloor>
      </Box>
      {layout && layoutImg && layoutImg.length > 0 ? (
        <EmployeeHomeLayoutContentComponent
          seats={seats}
          meetingRooms={meetingRooms}
          areas={areas}
          layoutImg={layoutImg}
          layout={layout}
          companyRelations={companyRelations}
          selectedBranch={selectBranch}
          selectedFloor={selectFloor}
        />
      ) : (
        <Typography align="center" fontSize="12px">
          {translations.LayoutNot}
        </Typography>
      )}
    </Box>
  )
}

////////// Icon

const EmployeeLeftIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
})

const EmployeeLeftIcon = (
  <EmployeeLeftIconStyled>
    <img
      src={`${process.env.PUBLIC_URL}/images/icons/seat-green.svg`}
      style={{
        height: "25px",
        width: "25px",
      }}
    />
  </EmployeeLeftIconStyled>
)

const EmployeeRightIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
})

const EmployeeRightIcon = () => {
  const navigate = useNavigate()

  const EmployeeRightIconOnClick = () => {
    navigate("/employee/layouts")
  }

  return (
    <EmployeeRightIconStyled>
      <img
        src={`${process.env.PUBLIC_URL}/images/icons/next-gray.svg`}
        style={{
          height: "15px",
          width: "15px",
          cursor: "pointer",
        }}
        onClick={EmployeeRightIconOnClick}
      />
    </EmployeeRightIconStyled>
  )
}

export const EmployeeHomeLayout = () => {
  return (
    <EmployeeHomeContent
      title={<EmployeeHomeLayoutTitle />}
      content={<EmployeeHomeLayoutContent />}
      leftIcon={EmployeeLeftIcon}
      rightIcon={EmployeeRightIcon()}
      areaName="Layout"
    />
  )
}
