import React from "react"
import { Helmet } from "react-helmet-async"

declare global {
  interface Window {
    Multipayment: {
      getToken: (
        creditCard: {
          cardno: number
          expire: number
          holdername: string
          securitycode: number
        },
        someCallbackFunction: any
      ) => {
        resultCode: `000` | number
        tokenObject: {
          isSecurityCodeSet: boolean | string
          maskedCardNo: string
          toBeExpiredAt: string
          token: string
        }
      }
      init: (apiKey: string) => void
    }
  }
}

export const Multipayment = window.Multipayment

export const CustomHeadTag: React.FC = () => {
  return (
    <Helmet>
      <script
        type="text/javascript"
        src={`${process.env.REACT_APP_GMO_TOKEN_URL}`}
      />
    </Helmet>
  )
}
