import { useState, useCallback, useEffect } from "react"

import { fetchMeetingRoomsRequest } from "../../../api/employee/meetingRoomRequest"
import { MeetingRoomResponseType } from "./type"

// ユーザーの所属するフロアの会議室情報の取得
export const useMeetingRooms = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomResponseType[]>(
    []
  )

  const fetchMeetingRooms = useCallback(
    async (
      start_date: Date | null,
      end_date: Date | null,
      start_time: Date | null,
      end_time: Date | null,
      count: number | null
    ) => {
      try {
        setLoading(true)
        const response = await fetchMeetingRoomsRequest({
          start_date,
          end_date,
          start_time,
          end_time,
          count,
        })
        if (response.status === 200 && response.data) {
          setMeetingRooms(response.data)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("error")
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    loading,
    meetingRooms,
    fetchMeetingRooms,
  }
}
