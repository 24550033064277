const en = {
  profile: "Profile",
  Profile: "Profile",
  Help: "Help",
  ChangePassword: "Change password",
  ManagementConsole: "Management Console",
  LogOut: "Log out",
  PleaseSetIcon: "Set an icon",
  Report: "Employee、Report detail",
  employee: "Go to Employee page",
}
export default en
