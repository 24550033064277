import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { setUpAxiosInterceptor } from "./api/config/axiosConfig"
import { setUpSignageAxiosInterceptor } from "./api/config/signageAxiosConfig"
import { AlertError } from "./components/public/AlertError"

export const AxiosInterceptors: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isForbiddenError, setIsForbiddenError] = useState<boolean>(false)
  const FORBIDDEN_ERROR_MESSAGE = "権限がありません"

  useEffect(() => {
    if (location.pathname === "/company/signage/login") {
      setUpSignageAxiosInterceptor(navigate, setIsForbiddenError)
    } else {
      setUpAxiosInterceptor(navigate, setIsForbiddenError)
    }
  }, [navigate])
  return isForbiddenError ? (
    <AlertError
      isMessageOpen={isForbiddenError}
      closeMessage={() => setIsForbiddenError(false)}
      alertMessage={FORBIDDEN_ERROR_MESSAGE}
    />
  ) : null
}
