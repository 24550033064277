import React, { useEffect } from "react"

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  styled,
  Typography,
} from "@mui/material"

import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { PropsTimeInputForm } from "./type"

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))
const MenuItemHour = [...Array(24)].map((_, i) => i)
const MenuItemMinutes = [...Array(4)].map((_, i) => i * 15)

export const TimeInputForm: React.FC<PropsTimeInputForm> = (props) => {
  useEffect(() => {
    props.setTimeFunction(
      new Date(2000, 1, 1, props.defaultTimeHour, props.defaultTimeMinutes)
    )
  }, [])

  return (
    <>
      <CustomGridItem item xs={2}>
        <InputLabel>
          <Typography fontWeight="bold" fontSize="16px">
            {props.inputLabel}
          </Typography>
        </InputLabel>
      </CustomGridItem>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>{props.inputLabel}(時)</InputLabel>
          <Select
            id="am_start_time"
            variant="outlined"
            margin="dense"
            label={props.inputLabel + "(時)"}
            fullWidth
            sx={{
              background: "rgba(112,112,112,0.05)",
            }}
            IconComponent={GreenArrowIcon}
            defaultValue={props.defaultTimeHour}
            onBlur={(event) => {
              props.setTimeFunction(
                new Date(
                  2000,
                  1,
                  1,
                  parseInt(event.target.value),
                  props.inputTime.getMinutes()
                )
              )
            }}
          >
            <MenuItem disabled={true}>
              {props.inputLabel}を選択してください
            </MenuItem>
            {MenuItemHour.map((hour, index) => {
              return (
                <MenuItem key={index} value={hour}>
                  {hour}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>{props.inputLabel}(分)</InputLabel>
          <Select
            id="auto_cancel_minutes"
            variant="outlined"
            margin="dense"
            label={props.inputLabel + "(分)"}
            fullWidth
            sx={{
              background: "rgba(112,112,112,0.05)",
            }}
            IconComponent={GreenArrowIcon}
            defaultValue={props.defaultTimeMinutes}
            onBlur={(event) => {
              props.setTimeFunction(
                new Date(
                  2000,
                  1,
                  1,
                  props.inputTime.getHours(),
                  parseInt(event.target.value)
                )
              )
            }}
          >
            <MenuItem disabled={true}>
              {props.inputLabel}を選択してください
            </MenuItem>
            {MenuItemMinutes.map((minutes, index) => {
              return (
                <MenuItem key={index} value={minutes}>
                  {minutes}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}
