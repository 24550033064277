import React from "react"

import { Grid } from "@mui/material"

import { CheckInPasswordChangeForm } from "../../../components/mobile/checkIn/CheckInPasswordChangeForm"
import { Loading } from "../../../components/public/Loading"
import { useCheckInPasswordChange } from "../../../models/mobile/useCheckInAuth/passwordChange"

export const MobileCheckInPasswordChange = () => {
  const authObject = useCheckInPasswordChange()

  return (
    <Grid container justifyContent="center">
      {authObject.loading && (
        <Loading type="content" loading={authObject.loading} />
      )}
      <Grid item xs={10}>
        <CheckInPasswordChangeForm {...authObject} />
      </Grid>
    </Grid>
  )
}
