import React, { useState, memo, useEffect } from "react"

import { Snackbar, Alert } from "@mui/material"

export const AlertContent: React.FC = memo(() => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>("")

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const alertContent = localStorage.getItem("alertContent")
    if (alertContent) {
      setMessage(alertContent)
      localStorage.removeItem("alertContent")
      setOpen(true)
    }
  }, [])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
})

export const AlertErrorContent: React.FC = memo(() => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>("")

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const alertContent = localStorage.getItem("alertErrorContent")
    if (alertContent) {
      setMessage(alertContent)
      localStorage.removeItem("alertErrorContent")
      setOpen(true)
    }
  }, [])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", whiteSpace: "pre-wrap" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  )
})
