import { useState, useCallback, useEffect } from "react"

import {
  fetchSeatsRequest,
  fetchAllSeatsRequest,
} from "../../../api/employee/seatRequest"
import { SeatResponseType } from "./type"

// 最近利用した座席情報の取得
export const useSeats = (startTime: Date | null, endTime: Date | null) => {
  const [recentSeats, setRecentSeats] = useState<SeatResponseType[]>([])
  const [allSeats, setAllSeats] = useState<SeatResponseType[]>([])
  const [seatLoading, setSeatLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchRecentSeats(startTime, endTime)
  }, [])

  const fetchRecentSeats = useCallback(
    async (startTime: Date | null, endTime: Date | null) => {
      setSeatLoading(true)
      try {
        const response = await fetchSeatsRequest(startTime, endTime)
        if (response.status === 200 && response.data) {
          setSeatLoading(false)
          setRecentSeats(response.data)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        setSeatLoading(false)
        console.log("error")
      }
    },
    []
  )

  // ユーザーの所属するフロアの座席情報の取得
  const fetchAllSeats = useCallback(async (startDate: Date) => {
    try {
      const response = await fetchAllSeatsRequest(startDate)
      if (response.status === 200 && response.data) {
        setAllSeats(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }, [])

  return {
    recentSeats,
    fetchRecentSeats,
    allSeats,
    fetchAllSeats,
    seatLoading,
  }
}
