const ja = {
  todayButton: "今日",
  create: "＋ 新規登録",
  status: "状況",
  allDay: "終日",
  week: ["日", "月", "火", "水", "木", "金", "土"],
  year: "年",
  month: "月",
  day: "日",
  TBD: "未",
  WorkFromHome: "宅",
  OutOfOffice: "外",
  BusinessTrip: "出",
  Vacation: "休",
  // アラート
  ScheduleUpdate: "スケジュールの更新が完了しました",
  ScheduleCreate: "スケジュールの登録が完了しました",
  tryAgain: "入力に誤りがございます、もう一度お試しください",
  checkedIn: "チェックインが完了しました",
  checkedOut: "チェックアウトが完了しました",
  CheckInFailed: "チェックインに失敗しました",
}
export default ja
