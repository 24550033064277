import { createTheme } from "@mui/material"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#22BA9D",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E3E3E3",
      contrastText: "#fff",
    },
    text: {
      disabled: "#000",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(112,112,112,0.05)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          margin: "0",
          paddingTop: "0.3rem",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "#454545",
          fontSize: "0.75rem",
          height: "1.25rem",
          width: "1.25rem",
          minWidth: "0rem",
          margin: "0 0.375rem",
        },
        text: {
          "&.Mui-selected": {
            backgroundColor: "#E8F8F5",
            color: "#22BA9D",
            fontSize: "0.75rem",
            fontWeight: "bold",
          },
          "&:hover": {
            backgroundColor: "#22BA9D",
          },
        },
        textPrimary: {
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
    },
  },
})
