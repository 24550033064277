import React from "react"

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded"
import { Button } from "@mui/material"

interface Props {
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const BackIcon: React.FC<Props> = ({ handleClick }: Props) => {
  return (
    <>
      <Button onClick={handleClick} sx={{ padding: "0", minWidth: "40px" }}>
        <ArrowBackIosRoundedIcon sx={{ fontSize: "20px", color: "#454545" }} />
      </Button>
    </>
  )
}
