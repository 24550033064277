import React from "react"

import { AuthPageTemplate } from "../../components/public/AuthPageTemplate"
import { SignageLoginForm } from "../../components/signage/login/SignageLoginForm"
import { useLogin } from "../../models/public/useAuth/login"

export const SignageLogin = () => {
  const authObject = useLogin()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <SignageLoginForm {...authObject} />
    </AuthPageTemplate>
  )
}
