import { useState } from "react"

import { fetchLayoutMeetingRoomRequest } from "../../../../api/company/layoutRequest"
import { MeetingRoomType } from "./type"

export const useMeetingRoom = () => {
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomType[]>([])

  // 会議室の取得
  const fetchMeetingRooms = async (layout_id: number) => {
    try {
      const response = await fetchLayoutMeetingRoomRequest(layout_id)
      if (response.data && response.status === 200) {
        setMeetingRooms(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    meetingRooms,
    fetchMeetingRooms,
  }
}
