import { useState } from "react"

import { updatePmStartTimeRequest } from "../../../api/company/companyRequest"

export const usePmStartTime = () => {
  const [pmStartTime, setPmStartTime] = useState<Date>(
    new Date("2000-01-01 13:00:00")
  )

  const updatePmStartTime = async () => {
    try {
      const response = await updatePmStartTimeRequest({
        pm_start_time: pmStartTime,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "就業時間を変更しました")
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    pmStartTime,
    setPmStartTime,
    updatePmStartTime,
  }
}
