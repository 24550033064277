import React, { useState, useRef, useEffect } from "react"

import { Box, Grid, Typography } from "@mui/material"

import { useMeetingRoomSchedules } from "../../../../../models/employee/useSchedule/meetingRooms"
import { MeetingRoomScheduleRequestType } from "../../../../../models/employee/useSchedule/meetingRooms/type"
import { useScheduleShowData } from "../../../../../models/employee/useSchedule/show"
import { useScheduleStartHour } from "../../../../../models/employee/useScheduleStartHour/index"
import { dateStatus } from "../../../../../utils/date"
import { useOpen } from "../../../../../utils/isOpen"
import { Paging } from "../../../../public/Pagination"
import { EmployeeScheduleDayShowModal } from "../../days/EmployeeScheduleDayShowModal"
import { MeetingRoomScheduleHeader } from "../MeetingRoomScheduleHeader"
import { MeetingRoomScheduleSideBar } from "../MeetingRoomScheduleSidebar"
import { MeetingRoomScheduleTable } from "../MeetingRoomScheduleTable"
import { MeetingRoomScheduleTimeLine } from "../MeetingRoomScheduleTimeline"

export type NoRecordTextType =
  | "拠点とフロアを選択してください"
  | "表示するデータがありません"

export const MeetingRoomCalendar: React.FC = () => {
  const { meetingRoomSchedules, fetchMeetingRoomSchedules } =
    useMeetingRoomSchedules()
  const dateObject = dateStatus()
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 5, 10, 15...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const [noRecordText, setNoRecordText] =
    useState<NoRecordTextType>("拠点とフロアを選択してください") // 表示するデータがない場合のテキスト
  const DATA_COUNT_PER_PAGE = 10
  const { isOpen, open, close } = useOpen()
  const { scheduleShowData, onApproveSubmit, fetchScheduleShowData } =
    useScheduleShowData()

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  // 各子供コンポーネントから予定の取得処理を呼び出すための関数
  const handleScheduleFetch = async (data: MeetingRoomScheduleRequestType) => {
    await fetchMeetingRoomSchedules(data)
  }

  // API側で、日付ごとに会議室の一覧を取得しているので選択されている日付に紐づくデータに絞り込む
  const selectedDateMeetingRoomSchedules = meetingRoomSchedules.filter(
    (list) => {
      return list.scheduled_date === dateObject.formatDate(dateObject.date)
    }
  )[0]

  // 予定表サイドバーに表示する会議室の一覧
  const meetingRooms = selectedDateMeetingRoomSchedules?.meeting_rooms // 初期化時にselectedDateMeetingRoomSchedulesがundefinedになるので?をつける

  // スケジュールの１番左側の時間軸は始業時間を表示する
  const startHour = useScheduleStartHour()
  const meetingRoomScrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (meetingRoomScrollRef.current && startHour !== null) {
      meetingRoomScrollRef.current.scrollLeft = 80 * startHour
    }
  }, [meetingRooms])

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={11} sm={11} marginTop="15px">
          <MeetingRoomScheduleHeader
            handleScheduleFetch={handleScheduleFetch}
            setNoRecordText={setNoRecordText}
            {...dateObject}
          />
          <Box display="flex">
            {meetingRooms && meetingRooms.length > 0 ? (
              <>
                <Box width="20%">
                  <Box
                    sx={{
                      height: "1.5rem",
                    }}
                  ></Box>
                  {meetingRooms
                    .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                    .map((meetingRoom) => {
                      return (
                        <MeetingRoomScheduleSideBar
                          key={meetingRoom.id}
                          {...meetingRoom}
                        />
                      )
                    })}
                </Box>
                <Box
                  sx={{
                    overflowX: "scroll",
                    mt: "auto",
                    width: "80%",
                  }}
                  ref={meetingRoomScrollRef}
                >
                  <MeetingRoomScheduleTimeLine />
                  {meetingRooms
                    .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                    .map((meetingRoom) => (
                      <MeetingRoomScheduleTable
                        key={meetingRoom.id}
                        schedules={meetingRoom.schedules}
                        scheduleDate={dateObject.formatDate(dateObject.date)}
                        open={open}
                        fetchScheduleShowData={fetchScheduleShowData}
                      />
                    ))}
                </Box>
              </>
            ) : (
              <>
                <Box mt="auto" width="40%"></Box>
                <Typography textAlign="center">{noRecordText}</Typography>
              </>
            )}
          </Box>
          <Grid item>
            {meetingRooms && meetingRooms.length > DATA_COUNT_PER_PAGE && (
              <Paging
                pageCount={Math.ceil(meetingRooms.length / DATA_COUNT_PER_PAGE)}
                page={pageNumber}
                handlePaginationChange={handlePaginationChange}
              />
            )}
          </Grid>
          <EmployeeScheduleDayShowModal
            date={dateObject.date}
            isOpen={isOpen}
            close={close}
            scheduleShowData={scheduleShowData}
            onApproveSubmit={onApproveSubmit}
          />
        </Grid>
      </Grid>
    </>
  )
}
