import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
import * as Yup from "yup"

import { useState, useCallback, useContext } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { PIN_CODE } from "../../../../utils/regular"
import { MFAType } from "./type"

const authFormData: MFAType = {
  pinCode: "",
}

export const useCheckInMFA = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const navigate = useNavigate()
  const [authData] = useState<MFAType>(authFormData)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  Amplify.configure(awsmobile)
  const { currentUser } = useContext(AuthContext)
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)

  const basicSchema = Yup.object().shape({
    pinCode: Yup.string()
      .required(translations.Required)
      .length(6, translations.digitsCode)
      .matches(PIN_CODE, translations.halfWidthNumbers),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      pinCode: authData.pinCode,
    },
    resolver: yupResolver(basicSchema),
  })

  // ログイン時の2要素認証
  const onConfirmSignIn: SubmitHandler<MFAType> = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Auth.confirmSignIn(
        currentUser,
        data.pinCode,
        "SOFTWARE_TOKEN_MFA"
      )
      if (response.signInUserSession) {
        navigate(
          `/mobile/check-in?floor_id=${queryParams.get(
            "floor_id"
          )}&id=${queryParams.get("id")}&type=${queryParams.get(
            "type"
          )}&name=${queryParams.get("name")}`
        )
      }
    } catch (error) {
      setOpen(true)
      setErrorMessages(["認証に失敗しました"])
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    control,
    authData,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    open,
    errorMessages,
    onConfirmSignIn,
    loading,
  }
}
