import { useState } from "react"

import { updatePmEndTimeRequest } from "../../../api/company/companyRequest"

export const usePmEndTime = () => {
  const [pmEndTime, setPmEndTime] = useState<Date>(
    new Date("2000-01-01 17:00:00")
  )

  const updatePmEndTime = async () => {
    try {
      const response = await updatePmEndTimeRequest({
        pm_end_time: pmEndTime,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "就業時間を変更しました")
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    pmEndTime,
    setPmEndTime,
    updatePmEndTime,
  }
}
