import React from "react"

import { AuthPageTemplate } from "../../components/public/AuthPageTemplate"
import { SignageForgotPasswordForm } from "../../components/signage/login/ForgotPasswordForm"
import { useForgotPassword } from "../../models/public/useAuth/forgotPassword"

export const SignageForgotPassword = () => {
  const authObject = useForgotPassword()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <SignageForgotPasswordForm {...authObject} />
    </AuthPageTemplate>
  )
}
