import { Storage } from "aws-amplify"

import { useState, useCallback } from "react"
import { useParams } from "react-router-dom"

import {
  signageCompanyNameSearchRequest,
  signageFetchMapRequest,
  signageGuestNameSearchRequest,
  signageReadQrCodeRequest,
  signageRegisterMapRequest,
} from "../../../api/signage/receptionRequest"
import { RETURN_TO_TOP_PAGE_SECONDS } from "../../../utils/const"
import {
  SignageReceptionReadQrCodeType,
  SignageReceptionSearchType,
  SignageReceptionFetchMapRequestType,
  SignageReceptionRegisterMapRequestType,
} from "./type"

const defaultQrCodeData = {
  token: "",
  schedule_information_id: 0,
  schedule_ids: [],
}

export const useSignageReception = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [searchedGuestResult, setSearchedGuestResult] = useState<
    SignageReceptionSearchType[]
  >([])
  const [qrCodeData, setQrCodeData] =
    useState<SignageReceptionReadQrCodeType>(defaultQrCodeData)
  const [receptionMapImage, setReceptionMapImage] = useState<string>("")
  const [companyId, setCompanyId] = useState<number>(0)
  const [isRegisterMapSuccess, setIsRegisterMapSuccess] =
    useState<boolean>(false)
  const [
    isRegisterMapSuccessMessageShown,
    setIsRegisterMapSuccessMessageShown,
  ] = useState<boolean>(false)
  const [registerMapSuccessMessage, setRegisterMapSuccessMessage] =
    useState<string>("")
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)

  const fetchReceptionMap = useCallback(
    async (params: SignageReceptionFetchMapRequestType) => {
      setLoading(true)
      try {
        const response = await signageFetchMapRequest(params)

        if (response.status === 200 && response.data) {
          setCompanyId(response.data.company_id)
          const downloadFileResult = await downloadLayoutImage(
            response.data.company_id,
            response.data.id,
            response.data.reception_map_image
          )

          if (downloadFileResult)
            setReceptionMapImage(
              URL.createObjectURL(downloadFileResult.Body as Blob)
            )
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const downloadLayoutImage = async (
    companyId: number,
    branchId: number,
    img: string
  ) => {
    if (!img) return

    const result = await Storage.get(img, {
      level: "public",
      download: true,
      customPrefix: {
        public: `company${companyId}/receptionMaps/branch${branchId}`,
      },
    })

    return result
  }

  const registerReceptionMap = useCallback(
    async (params: SignageReceptionRegisterMapRequestType) => {
      setLoading(true)
      try {
        const response = await signageRegisterMapRequest(params)

        if (response.status === 204) {
          setIsRegisterMapSuccess(true)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const uploadFile = async (branchId: number, file: Blob) => {
    if (!file) return

    const fileName = Date.now() + "." + file.type.replace(/(.*)\//g, "")
    const result = await Storage.put(fileName, file, {
      level: "public",
      contentType: file.type,
      customPrefix: {
        public: `company${companyId}/receptionMaps/branch${branchId}`,
      },
    })

    if (result) {
      return result
    }
  }

  // ゲストの会社名で検索
  const fetchSignageCompanyNameSearch = useCallback(async (params: string) => {
    try {
      setLoading(true)
      const response = await signageCompanyNameSearchRequest(params)
      if (response.status === 200 && response.data) {
        setSearchedGuestResult(response.data)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  // ゲストの氏名で検索
  const fetchSignageGuestNameSearch = useCallback(async (params: string) => {
    try {
      setLoading(true)
      const response = await signageGuestNameSearchRequest(params)
      if (response.status === 200 && response.data) {
        setSearchedGuestResult(response.data)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleReadQrCode = async (params: SignageReceptionReadQrCodeType) => {
    try {
      const { error } = await signageReadQrCodeRequest(params)
      if (!error) {
        window.location.href = `/signage/branches/${branchId}/reception/completed`
      } else if (error[0] === "too_early") {
        window.location.href = `/signage/branches/${branchId}/reception/too-early`
      } else {
        window.location.href = `/signage/branches/${branchId}/reception/not-reserved`
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 指定時間後にTOPページへ戻る
  const returnTopPage = () => {
    const timerId = window.setTimeout(() => {
      window.location.href = `/signage/branches/${branchId}/reception/home`
    }, RETURN_TO_TOP_PAGE_SECONDS * 1000)
    const timerIdsStorageObject = localStorage.getItem(
      "signageReceptionTimerIds"
    )
    if (timerIdsStorageObject) {
      const timerIds: number[] = JSON.parse(timerIdsStorageObject)
      timerIds.push(timerId)
      localStorage.setItem("signageReceptionTimerIds", JSON.stringify(timerIds))
    } else {
      const timerIds = []
      timerIds.push(timerId)
      localStorage.setItem("signageReceptionTimerIds", JSON.stringify(timerIds))
    }
  }
  // QR読み込み失敗後にエラー画面に遷移する
  const returnTopErrorPage = () => {
    const timerId = window.setTimeout(() => {
      window.location.href = `/signage/branches/${branchId}/reception/qr-error`
    }, RETURN_TO_TOP_PAGE_SECONDS * 1000)
    const timerIdsStorageObject = localStorage.getItem(
      "signageReceptionTimerIds"
    )
    if (timerIdsStorageObject) {
      const timerIds: number[] = JSON.parse(timerIdsStorageObject)
      timerIds.push(timerId)
      localStorage.setItem("signageReceptionTimerIds", JSON.stringify(timerIds))
    } else {
      const timerIds = []
      timerIds.push(timerId)
      localStorage.setItem("signageReceptionTimerIds", JSON.stringify(timerIds))
    }
  }
  // returnTopPageでセットしたタイマーをリセットする。
  // 現状は各コンポーネントのボタンクリック時に呼び出している(=操作があるのでTOPに戻さない、と判定)
  const resetTimer = () => {
    const timerIdsStorageObject = localStorage.getItem(
      "signageReceptionTimerIds"
    )
    if (timerIdsStorageObject) {
      const timerIds = JSON.parse(timerIdsStorageObject)
      timerIds.map((timerId: number) => {
        clearTimeout(timerId)
      })
      // localStorageの中身も削除して良さそうだが判断つかないので一旦このままにしておく
    }
  }

  return {
    loading,
    searchedGuestResult,
    fetchSignageCompanyNameSearch,
    fetchSignageGuestNameSearch,
    returnTopPage,
    returnTopErrorPage,
    handleReadQrCode,
    qrCodeData,
    setQrCodeData,
    receptionMapImage,
    fetchReceptionMap,
    registerReceptionMap,
    uploadFile,
    isRegisterMapSuccess,
    registerMapSuccessMessage,
    setIsRegisterMapSuccess,
    resetTimer,
    isRegisterMapSuccessMessageShown,
    setIsRegisterMapSuccessMessageShown,
    setRegisterMapSuccessMessage,
  }
}
