const ja = {
  // スケジュール編集
  EditSchedule: "スケジュールの編集",
  scheduleTitle: "スケジュールタイトルを入力",
  StartDate: "開始日",
  StartTime: "開始時刻",
  EndTime: "終了時刻",
  EndDate: "終了日",
  Allday: "終日",
  RepeatSettings: "繰り返しの設定",
  Weekly: "週ごと",
  Monthly: "月ごと",

  // 曜日
  Sunday: "日曜日",
  Monday: "月曜日",
  Tuesday: "火曜日",
  Wednesday: "水曜日",
  Thursday: "木曜日",
  Friday: "金曜日",
  Saturday: "土曜日",

  // 繰り返し予定
  Day: "日",
  DayOfWeek: "曜日",
  day: "日",
  everyMonths: "ヶ月ごとの",
  everyWeeks: "週ごとの",
  every: "",
  months: "",
  private: "非公開",
  first: "第1",
  second: "第2",
  third: "第3",
  fourth: "第4",
  last: "最終",
  of: "",
  The: "",

  // "Day  every N months  1st, 2nd, 3rd, [dd]th",
  // "Day of Week  every N months    1st, 2nd 3rd Nth   W[Sun Mon Tue Wed Thu Fri Sat] ",
  Public: "公開する",
  Private: "非公開にする",
  Description: "内容",
  Cancel: "キャンセル",
  ConfirmReservation: "予約する",
  Send: "送信",
  sendGuests: "カレンダーのゲストに招待メールを送信しますか？",
  edit: "編集に戻る",
  notSend: "送信しない",
  Confirm: "確定する",
  WouldYouConfirmReservation: "予約を確定しますか？",

  // エラーメッセージ
  SchedulePast: "過去の予定を作成することはできません",
  AllDayReset:
    "終日を変更する場合、選択している席・会議室は一度リセットしてください",
  RepeatReset:
    "繰り返しの設定を変更する場合、選択している席・会議室は一度リセットしてください",
  SpanDays: "日をまたいでの予定を作成することはできません",
  OutsideBusinessHours: "営業時間外での予定を作成することはできません",
  seat_already_reserved: "こちらの席は他のユーザーが予約しています",
  room_already_reserved: "こちらの会議室は他のユーザーが予約しています",
}
export default ja
