// 各コンポーネントでユーザーの情報を扱えるようにするためのprovider
import React, { createContext, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { fetchEmployeeRoleRequest } from "../api/public/employeeRoleReqeust"
import { Loading } from "../components/public/Loading"
import { useEmployeeRoles } from "../models/public/useEmployeeRoles"
import { EmployeeRoles } from "../models/public/useEmployeeRoles/type"
import { AuthContext } from "./AuthProvider"

type RoleContextType = {
  role: EmployeeRoles | undefined
  isAdmin: boolean
  isBranchLeader: boolean
  isTeamLeader: boolean
  isPaid: boolean
  isActive: boolean
  latestContract: boolean
  handleRoleChange: () => void
}

interface Props {
  children?: React.ReactNode
}

export const RoleContext = createContext<RoleContextType>({} as RoleContextType)

export const RoleProvider: React.FC<Props> = ({ children }: Props) => {
  const { isAuthenticated } = useContext(AuthContext)
  const { role, fetchCurrentUserRole } = useEmployeeRoles()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(isAuthenticated) // ログアウト状態ではローディングさせる必要がないので初期値false
  const [isRoleChanged, setIsRoleChanged] = useState(false)

  // リロード時、ログイン時に従業員のroleを取得する
  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        setLoading(true)
        await fetchCurrentUserRole().then(() => {
          setLoading(false)
        })
      }
    }
    fetchData()
  }, [])

  // 従業員の権限が変更されているか判定
  const handleRoleChange = async (): Promise<void> => {
    const response = await fetchEmployeeRoleRequest()
    const newRole = response.data
    if (JSON.stringify(role) !== JSON.stringify(newRole)) {
      setIsRoleChanged(true)
    }
  }

  // ページ遷移&従業員の権限変更があれば権限を取得する
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetchCurrentUserRole()
      setLoading(false)
      setIsRoleChanged(false)
    }
    if (isAuthenticated && isRoleChanged) {
      fetchData()
    }
  }, [location.key, isRoleChanged])

  const isAdmin = role?.role_type === "admin"
  const isBranchLeader = role?.role_type === "manager"
  const isTeamLeader = role?.role_type === "department"
  const isPaid = role?.is_paid === true
  const isActive = role?.is_active === true
  const latestContract = role?.latest_contract === 1

  return (
    <>
      {loading ? (
        <>
          <Loading type="fullScreen" />
        </>
      ) : (
        <RoleContext.Provider
          value={{
            role,
            isAdmin,
            isBranchLeader,
            isTeamLeader,
            isPaid,
            isActive,
            latestContract,
            handleRoleChange,
          }}
        >
          {children}
        </RoleContext.Provider>
      )}
    </>
  )
}
