import React, { useEffect } from "react"

import { Typography, Box, styled } from "@mui/material"

const CustomDayBox = styled(Box)(() => ({
  borderBottomWidth: "1px",
  height: "1.5rem",
  width: "14%",
  textAlign: "center",
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: "#707070",
}))

interface Props {
  dayOfWeek: Array<string>
  week: Array<string>
  beginningOfWeek: Date
  fetchWeek: (beginningOfWeek: Date) => void
}

export const EmployeeScheduleDayLine: React.FC<Props> = ({
  dayOfWeek,
  week,
  beginningOfWeek,
  fetchWeek,
}: Props) => {
  useEffect(() => {
    fetchWeek(beginningOfWeek)
  }, [beginningOfWeek])

  return (
    <>
      <Box
        sx={{
          height: "3rem",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 5000,
        }}
      >
        <Box display="flex">
          {dayOfWeek.map((value, index) => (
            <CustomDayBox key={index}>
              <CustomTypography>{value}</CustomTypography>
            </CustomDayBox>
          ))}
        </Box>
        <Box display="flex">
          {week.map((date, index) => (
            <CustomDayBox key={index}>
              <CustomTypography>{date}</CustomTypography>
            </CustomDayBox>
          ))}
        </Box>
      </Box>
    </>
  )
}
