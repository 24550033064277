import React from "react"
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form"

import { Box, Grid, styled } from "@mui/material"

import {
  Employee,
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "../../../../../models/company/useEmployeeLeaderSearch/type"
import { CompanyTeamFormType } from "../../../../../models/company/useTeam/type"
import { CompanyRelationsType } from "../../../../../models/public/useCompanyRelations/type"
import { EmployeeSearchForm } from "../../../../employee/common/EmployeeSearchForm"
import { SearchedEmployeeList } from "../../../../employee/common/SearchedEmployeeList"
import { CancelButton } from "../../../../public/CancelButton"
import { ErrorText } from "../../../../public/ErrorText"
import { ReflectButton } from "../../../../public/ReflectButton"

const CustomGridItem = styled(Grid)(() => ({
  minHeight: "70%",
  display: "flex",
  alignItems: "center",
  marginRight: "0.5rem",
}))

const CustomGridSearchItem = styled(Grid)(() => ({
  minHeight: "70%",
  borderLeft: "solid 1px #E5E5E5",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

interface Props {
  control: Control<SearchEmployeeRequestType>
  handleSubmit: UseFormHandleSubmit<SearchEmployeeRequestType>
  errors: FieldErrors<SearchEmployeeRequestType>
  onSubmit: SubmitHandler<SearchEmployeeRequestType>
  setValue: UseFormSetValue<SearchEmployeeRequestType>
  clearErrors: UseFormClearErrors<SearchEmployeeRequestType>
  teamFormSetValue: UseFormSetValue<CompanyTeamFormType>
  companyRelations: CompanyRelationsType
  searchedEmployeeResult: SearchedEmployeeResult
  setSearchedEmployeeResult: React.Dispatch<
    React.SetStateAction<SearchedEmployeeResult>
  >
  selectedEmployee: Employee
  setSelectedEmployee: React.Dispatch<React.SetStateAction<Employee>>
  handleEmployeeChecked: (selectedEmployeeId: number) => void
  errorMessages: string[]
  errorMessageOpen: boolean
  isManagerDecisionButton: boolean
  setIsSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  loading: boolean
}

export const TeamEmployeeSearchModal: React.FC<Props> = ({
  control,
  searchedEmployeeResult,
  setSearchedEmployeeResult,
  handleSubmit,
  errors,
  onSubmit,
  setValue,
  clearErrors,
  teamFormSetValue,
  handleEmployeeChecked,
  selectedEmployee,
  errorMessages,
  errorMessageOpen,
  isManagerDecisionButton,
  setIsSearchModalOpen,
  handleClose,

  loading,
}: Props) => {
  // 従業員を選択後確定ボタンを押した時の挙動
  const handleFixAndClose = () => {
    setIsSearchModalOpen(false)
    setValue("branch_id", 0)
    setValue("floor_id", 0)
    setValue("team_id", 0)
    setValue("employee_group_id", 0)
    clearErrors(undefined)
    setSearchedEmployeeResult([])
    teamFormSetValue(
      "team_leader_name",
      `${selectedEmployee.last_name}${selectedEmployee.first_name}`
    )
    teamFormSetValue("employee_id", selectedEmployee.id)
  }

  // キャンセルボタンを押した時の挙動
  const handleCancel = () => {
    handleClose()
    setIsSearchModalOpen(false)
    setValue("branch_id", 0)
    setValue("floor_id", 0)
    setValue("team_id", 0)
    setValue("employee_group_id", 0)
    clearErrors(undefined)
    setSearchedEmployeeResult([])
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        sx={{
          height: "85%",
        }}
      >
        <CustomGridItem item sm={8.25}>
          <SearchedEmployeeList
            employees={searchedEmployeeResult}
            handleEmployeeChecked={handleEmployeeChecked}
            loading={loading}
          />
        </CustomGridItem>
        <CustomGridSearchItem item sm={3.25}>
          <EmployeeSearchForm
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            setValue={setValue}
            setSearchedEmployeeResult={setSearchedEmployeeResult}
          />
        </CustomGridSearchItem>
      </Grid>
      <Box
        sx={{
          width: "50%",
          margin: "5px auto",
        }}
      >
        {errorMessageOpen && ErrorText(errorMessages)}
      </Box>
      <Grid item container justifyContent="center" spacing={3}>
        <Grid
          item
          sm={4}
          sx={{
            textAlign: "center",
          }}
        >
          <CancelButton label="キャンセル" handleClick={handleCancel} />
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            textAlign: "center",
          }}
        >
          <ReflectButton
            label="確定"
            disabled={isManagerDecisionButton}
            handleClick={handleFixAndClose}
          />
        </Grid>
      </Grid>
    </>
  )
}
