import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import enTranslations from "../../../../translations/employeeloginPage/forgotPassword/en"
import jaTranslations from "../../../../translations/employeeloginPage/forgotPassword/ja"
import { pageType } from "../../../../utils/location"
import { EMAIL_REGEXP } from "../../../../utils/regular"
import { ForgotPasswordType } from "./type"

const forgotPasswordData: ForgotPasswordType = {
  email: "",
}

Amplify.configure(awsmobile)
export const useForgotPassword = () => {
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const basicSchema = Yup.object().shape({
    email: Yup.string()
      .required(translations.email)
      .matches(EMAIL_REGEXP, translations.email),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: forgotPasswordData.email,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<ForgotPasswordType> = useCallback(
    async (data) => {
      setLoading(true)
      try {
        const response = await Auth.forgotPassword(data.email, {
          pageType: pageType,
        })
        if (response) {
          navigate(`/${pageType}/forgot-password/applied`)
        }
      } catch (error) {
        setOpen(true)
        setErrorMessages([translations.FailedEmail])
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    onSubmit,
    open,
    errorMessages,
    loading,
  }
}
