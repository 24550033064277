import React from "react"

import { Box, Pagination, styled } from "@mui/material"

interface Props {
  page?: number
  pageCount: number
  handlePaginationChange: (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => void
}

const CustomBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0, 2, 0),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

// ページネーションを実装するためのコンポーネント
export const Paging: React.FC<Props> = ({
  page,
  pageCount,
  handlePaginationChange,
}: Props) => {
  return (
    <CustomBox>
      <Pagination
        count={pageCount} // 全体のページ数
        onChange={handlePaginationChange} // ページを変更した時に作動する関数
        page={page} // 現在のページ
        showFirstButton // 先頭に移動するボタン
        showLastButton // 最後尾に移動するボタン
        color="primary"
      />
    </CustomBox>
  )
}

export const CompanyEmployeesPaging: React.FC<Props> = ({
  page,
  pageCount,
  handlePaginationChange,
}: Props) => {
  return (
    <CustomBox>
      <Pagination
        count={pageCount} // 全体のページ数
        onChange={handlePaginationChange} // ページを変更した時に作動する関数
        page={page} // 現在のページ
        color="primary"
      />
    </CustomBox>
  )
}
