import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { ArrowForwardIos, ArrowBackIosNew } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Grid,
  styled,
  Typography,
  Button,
  IconButton,
} from "@mui/material"

import { checkUserMobileTypeRequest } from "../../../../api/mobile/checkUserRequest"
import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { HeaderCompanyType } from "../../../../models/company/useCompany/type"
import { useAuth } from "../../../../models/public/useAuth"
import { useFederatedSignIn } from "../../../../models/public/useFederatedSignIn"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/mobileHome/mobileHomeProfileIcon/en"
import jaTranslations from "../../../../translations/mobileHome/mobileHomeProfileIcon/ja"
import { useOpen } from "../../../../utils/isOpen"
import { pageType } from "../../../../utils/location"
import { EmployeeNoticeList } from "../EmployeeNoticeList/EmployeeNoticeList"

const MobileMenuModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  height: "640px",
  width: "330px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  borderRadius: theme.spacing(1),
}))

const CustomArrowForwardIos = styled(ArrowForwardIos)(() => ({
  color: "#707070",
  marginLeft: "5%",
}))

const CustomArrowBackIosNew = styled(ArrowBackIosNew)(() => ({
  color: "#707070",
  marginLeft: "5%",
}))

const CustomCloseIcon = styled(CloseIcon)(() => ({
  color: "#E5E5E5",
}))

const CustomTypography = styled(Typography)(() => ({
  color: "#454545",
  letterSpacing: "0.7px",
  fontWeight: "bold",
}))

const CustomBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "10px auto",
}))

const BackModalButton = styled(Button)(() => ({
  color: "inherit",
  display: "flex",
  alignItems: "center",
}))

const QuitButton = styled(Button)(() => ({
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "right",
  marginLeft: "auto",
  marginRight: "5px",
}))

const LogoutButton = styled(Button)(() => ({
  width: "140px",
  color: "#454545",
  display: "flex",
  alignItems: "center",
  border: `1px solid black`,
  borderRadius: "10px",
  justifyContent: "center",
}))

const EmployeeIcon = styled(IconButton)(() => ({
  height: "20vmin",
  width: "20vmin",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "50%",
  display: "flex",
  alignItems: "top",
  justifyContent: "center",
  top: "auto",
  padding: 0,
}))

const EmployeeInformation = styled(Grid)(() => ({
  width: "100%",
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const EmployeeNotice = styled(Box)(() => ({
  width: "100%",
  padding: "15px",
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const EmployeeMenuContent = styled(Grid)(() => ({
  width: "100%",
  margin: "10px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const EmployeeLogout = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "25px",
}))

//

interface Props {
  handleCloseMobileMenu: () => void
  imageUrl: string
  headerCompanyInfo?: HeaderCompanyType
}

export const MobileMenuModal = ({
  handleCloseMobileMenu,
  imageUrl,
  headerCompanyInfo,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const navigate = useNavigate()
  const { signOut } = useAuth()
  const noticeListOpen = useOpen()
  const { federatedSignOut } = useFederatedSignIn()
  const [isSSOUser, setIsSSOUser] = useState(false)
  const { currentUser } = useContext(AuthContext)

  // SSOユーザーか通常ユーザーか判定
  useEffect(() => {
    const email = currentUser?.attributes.email
    if (email) {
      checkUserMobileTypeRequest(email).then((response) => {
        if (response.data?.user_type === "SSO") {
          setIsSSOUser(true)
        }
      })
    }
  }, [currentUser])

  const handleLogout = async () => {
    if (isSSOUser) {
      await federatedSignOut()
    } else {
      await signOut()
    }
  }

  return (
    <>
      <MobileMenuModalOverlay>
        <Box
          sx={{
            position: "relative",
            height: "100%",
          }}
        >
          <Box>
            <QuitButton onClick={handleCloseMobileMenu}>
              <CustomCloseIcon />
            </QuitButton>
          </Box>
          {noticeListOpen.isOpen ? (
            <>
              <Box display="flex">
                <BackModalButton onClick={noticeListOpen.close}>
                  <CustomArrowBackIosNew />
                </BackModalButton>
                <CustomTypography variant="h6">
                  {translations.NotificationList}
                </CustomTypography>
              </Box>
              <EmployeeNoticeList />
            </>
          ) : (
            <>
              <EmployeeInformation container>
                <CustomBox>
                  <EmployeeIcon id="mobile_employee_icon">
                    {imageUrl === "" ? (
                      <Box
                        sx={{
                          overflowX: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Typography sx={{ fontSize: "25px", color: "inherit" }}>
                          {headerCompanyInfo?.employee.last_name[0]}
                          {headerCompanyInfo?.employee.first_name[0]}
                        </Typography>
                      </Box>
                    ) : (
                      <img
                        src={imageUrl}
                        style={{
                          borderRadius: "50%",
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </EmployeeIcon>
                </CustomBox>
                <CustomBox>
                  <CustomTypography variant="h6">
                    {headerCompanyInfo?.employee.last_name +
                      " " +
                      headerCompanyInfo?.employee.first_name}
                  </CustomTypography>
                </CustomBox>
              </EmployeeInformation>
              <EmployeeNotice>
                <CustomTypography variant="h6" onClick={noticeListOpen.open}>
                  {translations.Notifications}
                </CustomTypography>
              </EmployeeNotice>
              <EmployeeMenuContent container>
                <EmployeeMenuContent
                  onClick={() => {
                    navigate("/mobile/profile")
                    handleCloseMobileMenu()
                  }}
                >
                  <CustomTypography variant="h6" marginLeft="10%">
                    {translations.AccountInformation}
                  </CustomTypography>
                  <CustomArrowForwardIos />
                </EmployeeMenuContent>
                <EmployeeMenuContent
                  onClick={() => {
                    navigate("/mobile/languageChange")
                    handleCloseMobileMenu()
                  }}
                >
                  <CustomTypography variant="h6" marginLeft="10%">
                    {translations.Language}
                  </CustomTypography>
                  <CustomArrowForwardIos />
                </EmployeeMenuContent>
                <EmployeeMenuContent
                  onClick={() => {
                    localStorage.setItem("expiredChangePassword", "true")
                    navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
                  }}
                >
                  <CustomTypography variant="h6" marginLeft="10%">
                    {translations.ChangePassword}
                  </CustomTypography>
                  <CustomArrowForwardIos />
                </EmployeeMenuContent>
                <EmployeeMenuContent
                  onClick={() => {
                    navigate("/mobile/home")
                    handleCloseMobileMenu()
                  }}
                >
                  <CustomTypography variant="h6" marginLeft="10%">
                    {translations.SCHEDULE}
                  </CustomTypography>
                  <CustomArrowForwardIos />
                </EmployeeMenuContent>
                <EmployeeMenuContent
                  onClick={() => {
                    navigate("/mobile/layouts")
                    handleCloseMobileMenu()
                  }}
                >
                  <CustomTypography variant="h6" marginLeft="10%">
                    {translations.WORKPLACE}
                  </CustomTypography>
                  <CustomArrowForwardIos />
                </EmployeeMenuContent>
                <EmployeeMenuContent
                  onClick={() => {
                    navigate("/mobile/team-search")
                    handleCloseMobileMenu()
                  }}
                >
                  <CustomTypography variant="h6" marginLeft="10%">
                    {translations.TeamSearch}
                  </CustomTypography>
                  <CustomArrowForwardIos />
                </EmployeeMenuContent>
              </EmployeeMenuContent>
              <EmployeeLogout>
                <LogoutButton onClick={handleLogout}>
                  {translations.Logout}
                </LogoutButton>
              </EmployeeLogout>
            </>
          )}
        </Box>
      </MobileMenuModalOverlay>
    </>
  )
}
