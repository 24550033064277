import { useState } from "react"

import { updateAmEndTimeRequest } from "../../../api/company/companyRequest"

export const useAmEndTime = () => {
  const [amEndTime, setAmEndTime] = useState<Date>(
    new Date("2000-01-01 12:00:00")
  )

  const updateAmEndTime = async () => {
    try {
      const response = await updateAmEndTimeRequest({
        am_end_time: amEndTime,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "就業時間を変更しました")
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    amEndTime,
    setAmEndTime,
    updateAmEndTime,
  }
}
