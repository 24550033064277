import {
  SignageReceptionReadQrCodeType,
  SignageReceptionFetchMapRequestType,
  SignageReceptionFetchMapResponseType,
  SignageReceptionRegisterMapRequestType,
  SignageReceptionSearchType,
} from "../../models/signage/useReception/type"
import { api, ApiResponse } from "../config/axiosConfig"

// ゲストの会社名で検索
export const signageCompanyNameSearchRequest = async (
  params: string
): Promise<ApiResponse<SignageReceptionSearchType[]>> => {
  try {
    const data = await api.get("/signage/receptions/company_name_search", {
      params: { keyword: params },
    })

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

// ゲストの氏名で検索
export const signageGuestNameSearchRequest = async (
  params: string
): Promise<ApiResponse<SignageReceptionSearchType[]>> => {
  try {
    const data = await api.get("/signage/receptions/guest_name_search", {
      params: { keyword: params },
    })

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const signageReadQrCodeRequest = async (
  params: SignageReceptionReadQrCodeType
): Promise<ApiResponse<SignageReceptionSearchType[]>> => {
  try {
    const data = await api.post("/signage/receptions/read_qrcode", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const signageFetchMapRequest = async (
  params: SignageReceptionFetchMapRequestType
): Promise<ApiResponse<SignageReceptionFetchMapResponseType>> => {
  try {
    const data = await api.get("/signage/receptions/maps", {
      params: params,
    })

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const signageRegisterMapRequest = async (
  params: SignageReceptionRegisterMapRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/signage/receptions/maps", params)

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
