import React, { memo } from "react"

import { Box, styled } from "@mui/material"

const CustomIcon = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#22BA9D",
  border: "solid 3px #fff",
  boxShadow: "0 0 2px #1d0204",
  transform: "rotate(90deg)",
  cursor: "pointer",
  boxSizing: "border-box",
}))

export const ReservedSeat = memo(() => {
  return (
    <>
      <CustomIcon />
    </>
  )
})
