import React, { useEffect, useState } from "react"

import { Box, Grid, Modal, styled, Typography } from "@mui/material"

import { useWithdrawal } from "../../../../models/company/useWithdrawal"
import { jaDateFormat } from "../../../../utils/dateTimeFormat"
import { useOpen } from "../../../../utils/isOpen"
import { AlertError } from "../../../public/AlertError"
import { CancelButton } from "../../../public/CancelButton"
import { Loading } from "../../../public/Loading"
import { ReflectButton } from "../../../public/ReflectButton"
import { WarningText } from "../../../public/WarningText"

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
}))

export const WithdrawalModal: React.FC = () => {
  const { isOpen, open, close } = useOpen()
  const { handleWithdrawal, errorMessage, fetchWithdrawal, withdrawal } =
    useWithdrawal()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await fetchWithdrawal()
      setLoading(false)
    })()
  }, [])

  if (loading) {
    return <Loading type="content" loading={loading} />
  }

  return (
    <>
      {!withdrawal.withdrawal_application_date && (
        <Typography color="#F3AC50" variant="overline" onClick={open}>
          サービスを解約する
        </Typography>
      )}
      <Modal open={isOpen} onClose={close}>
        <CustomLayoutModal>
          <Box sx={{ padding: "30px 30px 20px" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "20px",
              }}
              variant="h6"
            >
              解約をしますか？
            </Typography>
            <Grid item xs={12} sm={12}>
              {WarningText([
                "・未払いの請求がある場合、解約できません",
                "・解約を取り消しすることはできません",
                "・利用終了日までご利用いただけます",
              ])}
            </Grid>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                m: "20px 0",
              }}
              variant="body1"
            >
              利用終了日: {jaDateFormat(withdrawal.end_date)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "450px",
                m: "30px auto",
              }}
            >
              <CancelButton label="キャンセル" handleClick={close} />
              <ReflectButton
                label="解約する"
                handleClick={async () => {
                  await handleWithdrawal()
                }}
              />
            </Box>
          </Box>
        </CustomLayoutModal>
      </Modal>
      <AlertError
        isMessageOpen={errorMessage.isOpenMessage}
        closeMessage={errorMessage.closeMessage}
        alertMessage={errorMessage.alertMessage}
      />
    </>
  )
}
