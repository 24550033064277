import { Chart, registerables } from "chart.js"
import { subYears, compareAsc } from "date-fns"
import ja from "date-fns/locale/ja"

import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Bar } from "react-chartjs-2"

import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Typography,
  CircularProgress,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { GreenArrowIcon } from "../../../components/public/GreenArrowIcon"
import { theme } from "../../../config/theme"
import { useContractPlan } from "../../../models/company/useContractPlan"
import {
  useReportArea,
  useReportAreaState,
  useSearchReportArea,
} from "../../../models/company/useReport"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import {
  CONTENT_PAPER_MIN_HEIGHT,
  REPORT_FLOAT_DIGIT,
} from "../../../utils/const"
import { dateStatus } from "../../../utils/date"
import { roundBySpecificDigit } from "../../../utils/number"

Chart.register(...registerables)

const boxStyle = {
  margin: 3,
}

const areaBarGraphOption = {
  indexAxis: "y" as const,
  scales: {
    x1: {
      min: 0,
      max: 100,
      position: "top" as const,
    },
    x2: {
      min: 0,
      max: 100,
      position: "bottom" as const,
    },
    y: {
      ticks: {
        crossAlign: "far" as const,
        autoSkip: false,
      },
    },
  },
  maintainAspectRatio: false,
}

const ReportCustomSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(30),
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: "#EEEEEE",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: theme.spacing(5),
  fontSize: theme.spacing(2),
  marginTop: theme.spacing(1),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
}))

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 300,
    },
  },
}

// 色コード
const BLUE_CODE = "rgb(75, 192, 192)"

export const CompanyReportAreaPage = () => {
  const today = new Date()

  // 選択期間開始日の処理
  const { fetchContractPlan, contractPlan } = useContractPlan()
  // 利用開始日
  const registrationStartDate = new Date(
    contractPlan.active_contract.start_date
  )
  // 現時刻から一年前
  const oneYearBefore = subYears(new Date(), 1)
  // 比較して選択できる開始期間にどちらを当てるか決める
  const minDate =
    compareAsc(oneYearBefore, registrationStartDate) < 0
      ? registrationStartDate
      : oneYearBefore

  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )

  useEffect(() => {
    fetchContractPlan()
  }, [])

  const {
    heatMapReportAreaRelation,
    setHeatMapReportRelations,
    fetchReportRelations,
    setReportRelations,
    reportAreaRelation,
  } = useReportArea()
  const { searchReportRelations } = useSearchReportArea()
  const { formatDate } = dateStatus()
  const { companyRelations } = useCompanyRelations()
  const pullDownObject = useReportAreaState(companyRelations)
  const heatMapPullDownObject = useReportAreaState(companyRelations)
  const [loading, setLoading] = useState<boolean>(true)
  const pullDownBranchLoading =
    pullDownObject.pullDownLoading || heatMapPullDownObject.pullDownLoading

  const areaLabels: string[] = []
  const areaIds: number[] = []
  const areaAllSeatCount: { areaId: number; count: number }[] = []
  const areaOperationSeatCount: { areaId: number; count: number }[] = []
  const areaOperationSeatRateWithAreaId: { areaId: number; rate: number }[] = []
  const areaOperationRate: number[] = []
  // エリア別利用状況
  const areaBarGraphData = {
    // y 軸のラベル
    labels: areaLabels,
    datasets: [
      {
        label: "利用率(%)",
        // データの値
        data: areaOperationRate,
        // グラフの背景色
        backgroundColor: BLUE_CODE,
        // グラフの枠線の色
        borderColor: BLUE_CODE,
      },
    ],
  }

  // エリア別利用状況棒グラフ計算処理
  reportAreaRelation.report_areas.map((report) => {
    const areaIndex = areaIds.findIndex((item) => item == report.area_id)
    if (areaIndex == -1) {
      areaIds.push(report.area_id)
      areaLabels.push(
        `${report.floor.branch.branch_name}-${report.floor.floor_name}-${report.area.area_name}`
      )
      areaAllSeatCount.push({
        areaId: report.area_id,
        count: report.all_seat_count,
      })
      areaOperationSeatCount.push({
        areaId: report.area_id,
        count: report.operation_seat_count,
      })
    } else {
      areaAllSeatCount[areaIndex].count =
        areaAllSeatCount[areaIndex].count + report.all_seat_count
      areaOperationSeatCount[areaIndex].count =
        areaOperationSeatCount[areaIndex].count + report.operation_seat_count
    }

    areaOperationSeatRateWithAreaId.push({
      areaId: report.area_id,
      rate: roundBySpecificDigit(
        (report.operation_seat_count / report.all_seat_count) * 100,
        REPORT_FLOAT_DIGIT
      ),
    })
  })

  for (let i = 0; i < areaAllSeatCount.length; i++) {
    const areaData = areaOperationSeatRateWithAreaId.filter((data) => {
      return data.areaId === areaAllSeatCount[i].areaId
    })
    let areaOperationRateSum = 0
    areaData.map((data) => {
      areaOperationRateSum += data.rate
    })
    const operationSeatRate = roundBySpecificDigit(
      areaOperationRateSum / areaData.length,
      REPORT_FLOAT_DIGIT
    )
    areaOperationRate.push(operationSeatRate)
  }

  const handleAreaSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(pullDownObject.startDate),
        end_date: formatDate(pullDownObject.endDate),
        layout_ids: pullDownObject.layoutIds,
        tag_ids: pullDownObject.selectedTagIds,
      },
      setReportRelations,
      pullDownObject.setSearchLoading
    )
  }

  const heatMapAreaIds: number[] = []

  const formatData = (data: number[]) => {
    const newData = []
    const categories = [
      "0:00",
      "1:00",
      "2:00",
      "3:00",
      "4:00",
      "5:00",
      "6:00",
      "7:00",
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ]

    for (let i = 0; i < categories.length; i++) {
      newData.push({
        x: categories[i],
        y: data[i],
      })
    }
    return newData
  }

  // 各時間帯の利用座席数をもとに各時間帯の座席利用数を計算
  const calculateSeatOperationRate = (
    seatOperationStringArray: number[],
    allSeatCount: number
  ) => {
    return seatOperationStringArray.map((statusOfHour: number) => {
      if (allSeatCount === 0) {
        return 0
      }
      return roundBySpecificDigit(
        (statusOfHour / allSeatCount) * 100,
        REPORT_FLOAT_DIGIT
      )
    })
  }

  const heatMapSeatOperationStatusParArea: {
    areaId: number
    labelName: string
    seatOperationStatus: number[]
    allSeatCount: number
  }[] = []

  const convertSeatOperationStatusToNumbers = (
    seatOperationStatus: string[]
  ) => {
    return seatOperationStatus.map((status) => {
      return Number(status)
    })
  }

  // エリア別に各時間帯別の座席利用数と全座席数を合計する
  heatMapReportAreaRelation.report_areas.map((report) => {
    const areaIndex = heatMapAreaIds.findIndex(
      (item) => item === report.area_id
    )

    if (areaIndex === -1) {
      heatMapAreaIds.push(report.area_id)
      heatMapSeatOperationStatusParArea.push({
        areaId: report.area_id,
        labelName: `${report.floor.branch.branch_name}-${report.floor.floor_name}-${report.area.area_name}`,
        seatOperationStatus: convertSeatOperationStatusToNumbers(
          report.seat_operation_status
        ),
        allSeatCount: report.all_seat_count,
      })
    } else {
      heatMapSeatOperationStatusParArea[areaIndex].seatOperationStatus =
        heatMapSeatOperationStatusParArea[areaIndex].seatOperationStatus.map(
          (status, index) => {
            return (status += Number(report.seat_operation_status[index]))
          }
        )
      heatMapSeatOperationStatusParArea[areaIndex].allSeatCount +=
        report.all_seat_count
    }
  })

  const series = heatMapSeatOperationStatusParArea.map((areaData) => {
    return {
      name: areaData.labelName,
      data: formatData(
        calculateSeatOperationRate(
          areaData.seatOperationStatus,
          areaData.allSeatCount
        )
      ),
    }
  })

  const heatMapOptions = {
    chart: {
      height: theme.spacing(54.5),
      type: "heatmap" as const,
      toolbar: {
        show: false,
      },
    },
    series: series,
    plotOptions: {
      heatmap: {
        // radius: 10,
        // useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: -1,
              to: 19.9,
              color: "#C9E8E7",
              name: "0~20%",
            },
            {
              from: 20,
              to: 39.9,
              color: "#A5D9D7",
              name: "20~40%",
            },
            {
              from: 40,
              to: 59.9,
              color: "#78C5C2",
              name: "40~60%",
            },
            {
              from: 60,
              to: 79.9,
              color: "#4BB2AE",
              name: "60~80%",
            },
            {
              from: 80,
              to: 100,
              color: "#3C8E8B",
              name: "80~100%",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  }

  // データポイント1つあたりの高さ（ピクセル）
  const pixelPerPoint = 1.5
  // データポイントの数を計算（series内のすべてのdataの長さの合計）
  const dataPointCount = heatMapOptions.series.reduce(
    (total, current) => total + current.data.length,
    0
  )
  // チャートの高さを計算
  let chartHeight = pixelPerPoint * dataPointCount
  // 最小のチャートの高さを設定
  const minHeight = 400
  // チャートの高さが最小の高さより小さい場合は、最小の高さを使用
  if (chartHeight < minHeight) {
    chartHeight = minHeight
  }

  const handleHeatMapDataSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(heatMapPullDownObject.startDate),
        end_date: formatDate(heatMapPullDownObject.endDate),
        layout_ids: heatMapPullDownObject.layoutIds,
        tag_ids: heatMapPullDownObject.selectedTagIds,
      },
      setHeatMapReportRelations,
      heatMapPullDownObject.setSearchLoading
    )
  }

  useEffect(() => {
    fetchReportRelations({
      start_date: formatDate(defaultStartDate),
      end_date: formatDate(yesterDay),
    }).then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <ContentCompanyPaper>
      {loading || pullDownBranchLoading ? (
        <Box
          sx={{
            display: "flex",
            height: CONTENT_PAPER_MIN_HEIGHT, // 高さ指定しないと中央にローディングアイコンが配置できない
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={64} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", height: "100%" }}>
          <Grid container spacing={3}>
            <Grid container>
              {/* TODO: それぞれのGrid内部にグラフを実装する */}
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  エリア別利用状況
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={pullDownObject.startDate}
                          onChange={pullDownObject.handleStartDateChange}
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={pullDownObject.endDate}
                          onChange={pullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {pullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleAreaSearch}
                        disabled={pullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>

                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <Grid item sm={12}>
                    <ReportCustomSelect
                      name="branch_ids"
                      value={pullDownObject.selectedBranchIds}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) => pullDownObject.changeBranchId(e)}
                      renderValue={() =>
                        pullDownObject.selectedBranchNames.join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="拠点" disabled>
                        拠点
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            pullDownObject.selectedBranchIds.length ===
                            companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              pullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                  {pullDownObject.selectedBranchIds.length > 0 && (
                    <Grid item sm={12}>
                      <ReportCustomSelect
                        name="floor_ids"
                        value={pullDownObject.selectedFloorIds}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        multiple
                        IconComponent={GreenArrowIcon}
                        onChange={(e) => pullDownObject.changeFloorId(e)}
                        renderValue={() =>
                          pullDownObject.selectedFloorNames.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="フロア" disabled>
                          フロア
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              pullDownObject.selectedFloorIds.length ===
                              pullDownObject.selectedBranchesFloors.length
                            }
                          />
                          <ListItemText primary={"全てのフロア"} />
                        </MenuItem>
                        {pullDownObject.selectedBranchesFloors.map((floor) => (
                          <MenuItem key={floor.id} value={floor.id}>
                            <Checkbox
                              checked={
                                pullDownObject.selectedFloorIds.indexOf(
                                  Number(floor.id)
                                ) > -1
                              }
                            />
                            <ListItemText
                              primary={`${floor.branch_name}-${floor.floor_name}`}
                            />
                          </MenuItem>
                        ))}
                      </ReportCustomSelect>
                    </Grid>
                  )}
                  {pullDownObject.searchTagLoading ? (
                    <Box
                      width={theme.spacing(30)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={32} />
                    </Box>
                  ) : (
                    <>
                      {pullDownObject.selectedFloorIds.length > 0 &&
                        pullDownObject.tags.length > 0 && (
                          <>
                            <ReportCustomSelect
                              name="tag_ids"
                              value={pullDownObject.selectedTagIds}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginBottom: 0,
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) => pullDownObject.changeTagId(e)}
                              renderValue={() =>
                                pullDownObject.selectedTagNames.join(", ")
                              }
                              inputProps={{
                                style: {
                                  paddingBottom: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="タグ" disabled>
                                タグ
                              </MenuItem>

                              {pullDownObject.tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                  <Checkbox
                                    checked={
                                      pullDownObject.selectedTagIds.indexOf(
                                        Number(tag.id)
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={`#${tag.tag_name}`} />
                                </MenuItem>
                              ))}
                            </ReportCustomSelect>
                          </>
                        )}
                    </>
                  )}
                </Grid>
                <Box
                  sx={{
                    height: theme.spacing(54.5),
                    paddingTop: theme.spacing(5),
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      // Math.max を用いて、最小の高さを設定
                      height: `${Math.max(
                        areaBarGraphData.labels.length * 35,
                        200
                      )}px`,
                    }}
                  >
                    <Bar data={areaBarGraphData} options={areaBarGraphOption} />
                  </Box>
                </Box>
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  時間帯別エリア利用状況
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={heatMapPullDownObject.startDate}
                          onChange={heatMapPullDownObject.handleStartDateChange}
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={heatMapPullDownObject.endDate}
                          onChange={heatMapPullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={heatMapPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {heatMapPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleHeatMapDataSearch}
                        disabled={heatMapPullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <Grid item sm={12}>
                    <ReportCustomSelect
                      name="branch_ids"
                      value={heatMapPullDownObject.selectedBranchIds}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) => heatMapPullDownObject.changeBranchId(e)}
                      renderValue={() =>
                        heatMapPullDownObject.selectedBranchNames.join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="拠点" disabled>
                        拠点
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            heatMapPullDownObject.selectedBranchIds.length ===
                            companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              heatMapPullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                  {heatMapPullDownObject.selectedBranchIds.length > 0 && (
                    <Grid item sm={12}>
                      <ReportCustomSelect
                        name="floor_ids"
                        value={heatMapPullDownObject.selectedFloorIds}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        multiple
                        IconComponent={GreenArrowIcon}
                        onChange={(e) => heatMapPullDownObject.changeFloorId(e)}
                        renderValue={() =>
                          heatMapPullDownObject.selectedFloorNames.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="フロア" disabled>
                          フロア
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              heatMapPullDownObject.selectedFloorIds.length ===
                              heatMapPullDownObject.selectedBranchesFloors
                                .length
                            }
                          />
                          <ListItemText primary={"全てのフロア"} />
                        </MenuItem>
                        {heatMapPullDownObject.selectedBranchesFloors.map(
                          (floor) => (
                            <MenuItem key={floor.id} value={floor.id}>
                              <Checkbox
                                checked={
                                  heatMapPullDownObject.selectedFloorIds.indexOf(
                                    Number(floor.id)
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={`${floor.branch_name}-${floor.floor_name}`}
                              />
                            </MenuItem>
                          )
                        )}
                      </ReportCustomSelect>
                    </Grid>
                  )}
                  {heatMapPullDownObject.searchTagLoading ? (
                    <Box
                      width={theme.spacing(30)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={32} />
                    </Box>
                  ) : (
                    <>
                      {heatMapPullDownObject.selectedFloorIds.length > 0 &&
                        heatMapPullDownObject.tags.length > 0 && (
                          <>
                            <ReportCustomSelect
                              name="tag_ids"
                              value={heatMapPullDownObject.selectedTagIds}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginBottom: 0,
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                heatMapPullDownObject.changeTagId(e)
                              }
                              renderValue={() =>
                                heatMapPullDownObject.selectedTagNames.join(
                                  ", "
                                )
                              }
                              inputProps={{
                                style: {
                                  paddingBottom: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="タグ" disabled>
                                タグ
                              </MenuItem>
                              {heatMapPullDownObject.tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                  <Checkbox
                                    checked={
                                      heatMapPullDownObject.selectedTagIds.indexOf(
                                        Number(tag.id)
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={`#${tag.tag_name}`} />
                                </MenuItem>
                              ))}
                            </ReportCustomSelect>
                          </>
                        )}
                    </>
                  )}
                </Grid>
                <div style={{ overflow: "auto", maxHeight: "500px" }}>
                  <ReactApexChart
                    options={heatMapOptions}
                    series={series}
                    type="heatmap"
                    height={chartHeight}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ContentCompanyPaper>
  )
}
