import html2canvas from "html2canvas"
import jsPDF from "jspdf"

import React from "react"

import { Box, Grid, Modal, styled, Typography } from "@mui/material"

import { theme } from "../../../config/theme"
import { Billing, Company } from "../../../models/company/useBilling/type"
import { billingDateFormat } from "../../../utils/dateTimeFormat"
import { translatePrefecture } from "../../../utils/prefecture"
import { CancelButton } from "../../public/CancelButton"
import { ReflectButton } from "../../public/ReflectButton"
import { changePaymentMethodsText } from "./PaymentHistory"

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "794px",
  height: "80%",
  overflow: "auto",
}))

const CustomGridContainer = styled(Grid)(() => ({
  padding: theme.spacing(2, 0),
  display: "flex",
  alignItems: "center",
}))

const SubContainer = styled(Grid)(() => ({
  padding: theme.spacing(2, 0),
}))

const CustomGridItem = styled(Grid)(() => ({
  // display: "flex",
  // alignItems: "center",
}))

const CustomLabel = styled(Typography)(() => ({
  fontSize: "13px",
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}))

interface Props {
  isOpen: boolean
  close: () => void
  billing: Billing
  company: Company
}

export const BiilingModal: React.FC<Props> = ({
  isOpen,
  close,
  billing,
  company,
}: Props) => {
  const pdhDownloadHandler = async () => {
    // PDFファイルに変換したいコンポーネントのidを検索してDOM要素を取得する

    const target = document.getElementById("pdf-id")
    if (target === null) return

    await html2canvas(target, { scale: 2.0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0)
      const pdf = new jsPDF({
        format: "a4",
      })
      const width = pdf.internal.pageSize.width
      pdf.addImage(imgData, "JPEG", 0, 0, width, 0)
      pdf.save(`${billing.subject}_請求書.pdf`)
    })
  }
  return (
    <>
      <Modal open={isOpen} onClose={close}>
        <CustomLayoutModal>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "30px",
            }}
            variant="h6"
          >
            請求書を発行しますか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton label="キャンセル" handleClick={close} />
            <ReflectButton
              label="発行する"
              handleClick={async (e) => {
                await pdhDownloadHandler()
                close()
              }}
            />
          </Box>
          <Box id="pdf-id" sx={{ padding: "30px 30px 20px" }}>
            <Box sx={{ mb: 1 }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/logos/logo.png`}
                alt="WORK AGILEロゴ"
                width="180px"
                height="30px"
              />
            </Box>
            <CustomGridContainer
              sx={{
                borderTop: "2px solid rgb(128, 138, 159)",
                "& .MuiGrid-container": {
                  paddingTop: "0px",
                  paddingButton: "0px",
                },
                padding: theme.spacing(1, 0),
              }}
              container
            >
              <CustomGridItem item xs={2} sm={2} md={2} lg={2} xl={2}>
                <CustomLabel>郵便番号</CustomLabel>
              </CustomGridItem>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <CustomLabel>〒{company.company_postal_code}</CustomLabel>
              </Grid>
              <CustomGridItem item xs={2} sm={2} md={2} lg={2} xl={2}>
                <CustomLabel>住所</CustomLabel>
              </CustomGridItem>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <CustomLabel>
                  {translatePrefecture(company.company_prefecture_id)}
                  {company.company_city}
                  {company.company_house_number}
                  {company.company_building_name}
                </CustomLabel>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                borderTop: "1px solid rgb(128, 138, 159)",
                "& .MuiGrid-container": {
                  paddingTop: "0px",
                  paddingButton: "0px",
                },
                padding: theme.spacing(1, 0),
              }}
            >
              <CustomGridItem item xs={2} sm={2} md={2} lg={2} xl={2}>
                <CustomLabel>法人名</CustomLabel>
              </CustomGridItem>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <CustomLabel>{company.company_name}御中</CustomLabel>
              </Grid>
            </CustomGridContainer>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                letterSpacing: "1em",
                padding: theme.spacing(2, 0, 1, 0),
              }}
            >
              【請求書】
            </Typography>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                padding: theme.spacing(0, 0, 1, 0),
              }}
            >
              <CustomGridItem item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomLabel sx={{ fontSize: "18px" }}>請求情報</CustomLabel>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                paddingTop: "0px",
              }}
            >
              <CustomGridItem
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              ></CustomGridItem>
              <CustomGridItem item xs={11} sm={11} md={11} lg={11} xl={11}>
                <SubContainer
                  container
                  sx={{
                    "& .MuiGrid-container": {
                      paddingTop: "0px",
                    },
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      請求書発行日
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      {billingDateFormat(billing.payment_date)}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      請求合計 (税込)
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel
                      sx={{ fontSize: "12px" }}
                    >{`￥${billing.billing_amount.toLocaleString()}`}</CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      支払期限
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      {billing.deadline
                        ? billingDateFormat(billing.deadline)
                        : ""}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      お支払方法
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      {changePaymentMethodsText(billing.payment_methods)}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      決済確定日
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      {billingDateFormat(billing.payment_date)}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      請求番号
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      {billing.billing_number}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                padding: theme.spacing(2, 0, 1, 0),
              }}
            >
              <CustomGridItem item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomLabel sx={{ fontSize: "18px" }}>請求内訳</CustomLabel>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                paddingTop: "0px",
              }}
            >
              <CustomGridItem
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              ></CustomGridItem>
              <CustomGridItem item xs={11} sm={11} md={11} lg={11} xl={11}>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "2px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "center" }}>
                      品名
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "center" }}>
                      金額(税込)
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "center" }}>
                      消費税
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      {billing.subject}
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.billing_amount.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.without_tax.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(2, 0),
                    borderBottom: "2px solid rgb(128, 138, 159)",
                  }}
                ></SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "2px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>
                      本請求書合計金額
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.billing_amount.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.without_tax.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                padding: theme.spacing(2, 0, 1, 0),
              }}
            >
              <CustomGridItem item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomLabel sx={{ fontSize: "18px" }}>税率表示</CustomLabel>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                paddingTop: "0px",
              }}
            >
              <CustomGridItem
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              ></CustomGridItem>
              <CustomGridItem item xs={11} sm={11} md={11} lg={11} xl={11}>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "1px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel sx={{ fontSize: "12px" }}>10%対象</CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.billing_amount.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.without_tax.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                padding: theme.spacing(2, 0, 1, 0),
              }}
            >
              <CustomGridItem item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomLabel sx={{ fontSize: "18px" }}>
                  請求書発行者
                </CustomLabel>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                paddingTop: "0px",
              }}
            >
              <CustomGridItem
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              ></CustomGridItem>
              <CustomGridItem item xs={11} sm={11} md={11} lg={11} xl={11}>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                  }}
                >
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CustomLabel
                      sx={{ fontSize: "12px", paddingBottom: "10px" }}
                    >
                      本請求に関するお問い合わせ
                    </CustomLabel>
                    <CustomLabel
                      sx={{ fontSize: "13px", paddingBottom: "10px" }}
                    >
                      森トラスト株式会社 事業管理部 請求担当
                    </CustomLabel>
                    <CustomLabel sx={{ fontSize: "13px" }}>
                      TEL: 03-6435-6486
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/logos/issuer2.png`}
                        width="200px"
                        height="55px"
                      />
                    </Box>
                    <CustomLabel sx={{ fontSize: "13px" }}>
                      登録番号: T8010401029670
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
              </CustomGridItem>
            </CustomGridContainer>
          </Box>
        </CustomLayoutModal>
      </Modal>
    </>
  )
}
