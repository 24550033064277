import {
  FetchMeetingRoomResponseType,
  FetchMeetingRoomsRequestType,
} from "../../models/company/useMeetingRooms/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchMeetingRoomsRequest = async (
  params: FetchMeetingRoomsRequestType
): Promise<ApiResponse<FetchMeetingRoomResponseType>> => {
  try {
    const data = await api.get("/company/meeting_rooms", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
