import React from "react"

import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { CheckInData } from "../../../../models/employee/useSchedule/type"
import enTranslations from "../../../../translations/employeeHome/employeeHomeCheckInOut/en"
import jaTranslations from "../../../../translations/employeeHome/employeeHomeCheckInOut/ja"
import { CancelButton } from "../../../public/CancelButton"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"

interface Props {
  notReservedModalMessage: string
  hours: number[]
  minutes: number[]
  reservationHour: number
  reservationMinutes: number
  handleReservationHourChange: (event: SelectChangeEvent<number>) => void
  handleReservationMinutesChange: (event: SelectChangeEvent<number>) => void
  setNotReserved: React.Dispatch<React.SetStateAction<boolean>>
  hasAnotherSchedule: boolean
  hasAnotherCheckIn: boolean
  handleInstantCheckInFromHome: (
    checkInData: CheckInData,
    onSuccess: () => void
  ) => Promise<void>
  checkInData: CheckInData
  onSuccess: () => void
}

// チェックイン時に座席が予約済みではない場合にその場で予約を実行するモーダル
export const CheckInNotReservedModal = ({
  notReservedModalMessage,
  hours,
  minutes,
  reservationHour,
  reservationMinutes,
  handleReservationHourChange,
  handleReservationMinutesChange,
  setNotReserved,
  hasAnotherSchedule,
  hasAnotherCheckIn,
  handleInstantCheckInFromHome,
  checkInData,
  onSuccess,
}: Props) => {
  const theme = useTheme()

  const alertFlag = hasAnotherSchedule || hasAnotherCheckIn

  const isReservationButtonDisabled =
    reservationHour === -1 || reservationMinutes === -1

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        <Typography align="left" color={alertFlag ? "red" : "black"}>
          {notReservedModalMessage}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* <Typography pb="1rem">何時まで予約しますか？</Typography> */}
        <Typography pb="1rem">{translations.setTimeReservation}</Typography>
        <Grid container columnSpacing={3}>
          <Grid item xs={6}>
            <FormControl sx={{ height: "50px" }} fullWidth>
              <Select
                fullWidth
                sx={{
                  background: "rgba(112,112,112,0.05)",
                }}
                MenuProps={{
                  sx: {
                    maxHeight: theme.spacing(40),
                  },
                }}
                IconComponent={GreenArrowIcon}
                onChange={handleReservationHourChange}
                value={reservationHour}
                displayEmpty
              >
                <MenuItem value={-1} disabled>
                  {/* 時 */}
                  {translations.Hour}
                </MenuItem>
                {hours.map((hour, index) => {
                  return (
                    <MenuItem key={index} value={hour}>
                      {hour}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ height: "50px" }} fullWidth>
              <Select
                fullWidth
                sx={{
                  background: "rgba(112,112,112,0.05)",
                }}
                MenuProps={{
                  sx: {
                    maxHeight: theme.spacing(40),
                  },
                }}
                IconComponent={GreenArrowIcon}
                onChange={handleReservationMinutesChange}
                value={reservationMinutes}
                displayEmpty
              >
                <MenuItem value={-1} disabled>
                  {/* 分 */}
                  {translations.Minutes}
                </MenuItem>
                {minutes.map((minute, index) => {
                  return (
                    <MenuItem key={index} value={minute}>
                      {minute.toString().length === 0 ? `0${minute}` : minute}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <ReflectButton
            // label="予約する"
            label={translations.ConfirmReservation}
            handleClick={() =>
              handleInstantCheckInFromHome(checkInData, onSuccess)
            }
            disabled={isReservationButtonDisabled}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CancelButton
            // label="キャンセル"
            label={translations.CANCEL}
            handleClick={() => setNotReserved(false)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
