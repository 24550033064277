import QRCode from "qrcode.react"

import React from "react"

import { Box, Grid, styled, Typography } from "@mui/material"

import { QrObjectType } from "../../../../models/company/useQrCode/type"

//使用しているOSの判定
const ua = window.navigator.userAgent.toLowerCase()

const QrCodeListWrapper = styled(Box)(({ theme }) => ({
  minHeight: "80%",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(
    4.5,
    ua.indexOf("windows nt") !== -1 ? 1 : 3.75,
    ua.indexOf("windows nt") !== -1 ? 8.535 : 8.285,
    ua.indexOf("windows nt") !== -1 ? 1 : 4
  ),
}))

const QrCodeContainer = styled(Grid)(({ theme }) => ({
  width: "90%",
  margin: theme.spacing(2, "auto"),
}))

interface Props {
  qrCodeList: QrObjectType[]
}
// qrCodeListを24個ずつの配列に分割する
const sliceByNumber = (array: QrObjectType[], number: number) => {
  const length = Math.ceil(array.length / number)
  return new Array(length)
    .fill(array)
    .map((_, i) => array.slice(i * number, (i + 1) * number))
}
// QRコード画面：印刷範囲
class CompanyQrCodePrintComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <Box>
        {sliceByNumber(this.props.qrCodeList, 24).map((qrCodeObject, index) => {
          return (
            <QrCodeListWrapper key={index}>
              <QrCodeContainer
                container
                rowSpacing={ua.indexOf("windows nt") !== -1 ? 3 : 3}
                columnSpacing={ua.indexOf("windows nt") !== -1 ? 6.2 : 3.5}
              >
                {qrCodeObject.map((qrCode, index) => {
                  return (
                    <Grid item sm={3} key={index}>
                      <Typography
                        variant="body2"
                        sx={{
                          width: "58px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          float: "left",
                          fontSize: "0.8rem",
                        }}
                      >
                        {qrCode.floorName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={
                          qrCode.type === "Seat"
                            ? {
                                fontSize: "0.8rem",
                              }
                            : {
                                width: "59px",
                                fontSize: "0.8rem",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }
                        }
                      >
                        -
                        {qrCode.type === "Seat"
                          ? `座席${qrCode.name}`
                          : qrCode.name}
                      </Typography>
                      <QRCode value={qrCode.url} size={115} />
                    </Grid>
                  )
                })}
              </QrCodeContainer>
            </QrCodeListWrapper>
          )
        })}
      </Box>
    )
  }
}

export default CompanyQrCodePrintComponent
