import { CreateOutlookEventRequestType } from "../../models/employee/useCalendar/type"
import { ScheduleForm } from "../../models/employee/useSchedule/useScheduleForm/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const createOutlookEventRequest = async (
  params: CreateOutlookEventRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/employee/calendar", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
