import QRCode from "qrcode.react"

import React from "react"

import { Box, Typography } from "@mui/material"

import { ReflectButton } from "../../../public/ReflectButton"

interface Props {
  meetingRoomCheckInUrl: string
}

export const SignageMeetingRoomCheckIn: React.FC<Props> = ({
  meetingRoomCheckInUrl,
}) => {
  return (
    <>
      <Box my={1}>
        <Typography variant="h6">チェックインはこちら</Typography>
      </Box>
      <Box mt={1} mb={2}>
        <QRCode value={meetingRoomCheckInUrl} />
      </Box>
      <Box my={1}>
        <Typography variant="h6">チェックイン完了後はこちら</Typography>
      </Box>
      <Box mb={3}>
        <ReflectButton
          label="完了"
          handleClick={() => window.location.reload()}
        />
      </Box>
    </>
  )
}
