import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
import { AES, enc } from "crypto-js"
import * as Yup from "yup"

import { useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { pageType } from "../../../../utils/location"
import { PASSWORD_REGEX } from "../../../../utils/regular"
import { ConfirmForgotPasswordType } from "./type"

const confirmForgotPasswordData: ConfirmForgotPasswordType = {
  userName: "",
  verificationCode: "",
  password: "",
  passwordConfirmation: "",
}

Amplify.configure(awsmobile)
export const useConfirmForgotPassword = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)

  const basicSchema = Yup.object().shape({
    password: Yup.string()
      .required(translations.Required)
      .max(50, translations.fiftyPassword)
      .min(8, translations.eightCharacters)
      .matches(PASSWORD_REGEX, translations.PleaseOneSymbol),
    passwordConfirmation: Yup.string()
      .required(translations.Required)
      .oneOf([Yup.ref("password"), null], translations.PasswordNotMatch),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      userName: confirmForgotPasswordData.userName,
      verificationCode: confirmForgotPasswordData.password,
      password: confirmForgotPasswordData.password,
      passwordConfirmation: confirmForgotPasswordData.password,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<ConfirmForgotPasswordType> = useCallback(
    async (data) => {
      setLoading(true)
      try {
        const response = await Auth.forgotPasswordSubmit(
          data.userName,
          data.verificationCode,
          data.password
        )
        if (response === "SUCCESS") {
          navigate(`/${pageType}/login`)
        }
      } catch (error) {
        setOpen(true)
        setErrorMessages([translations.changePassword])
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // URL直打ちなどの手段で遷移した際にemailが存在しない場合はもう一度認証メールを送信させる
  // verify.jsでクエリパラメータに確認コードを埋め込んでいる
  // クエリパラメータを改竄された場合はAmplifyがエラーを出すのでURL直打ち対策は一旦不要と見ている
  useEffect(() => {
    const encrypted = queryParams.get("data")
    if (encrypted) {
      const data = enc.Base64.parse(encrypted).toString(enc.Latin1) // verify.jsでbase64暗号化したデータを複合する。この時、verify.jsデータをlatin1で暗号化したのでlatin1形式でparseする
      const decrypted = AES.decrypt(data, "WORK AGILE ENCRYPTION KEY").toString(
        enc.Utf8
      ) // utf-8形式でdecryptする。こうすることでJSON.parseが可能な文字列として出力される
      const { userName, code } = JSON.parse(decrypted) // JSONとしてparseすることでデータを取り出すことができる
      setValue("verificationCode", code)
      setValue("userName", userName)
    }
  }, [])

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    onSubmit,
    open,
    errorMessages,
    loading,
  }
}
