import React, { memo, useEffect, useState } from "react"
import Moveable from "react-moveable"

import { Box } from "@mui/material"

import { MeetingRoomType } from "../../../../models/company/useLayout/type"
import {
  meetingRoomIconStyle,
  meetingRoomStyle,
} from "../../../../styles/meetingStyle"

interface Props {
  meetingRoom: MeetingRoomType
  isMeetingRoomMoveable: boolean
  handleMeetingRoomCoordinate: (
    targetMeetingRoom: MeetingRoomType,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => void
}

/*
  レイアウト画像にア会議室のアイコンが表示される
*/
export const CompanyLayoutMeetingRoom: React.FC<Props> = ({
  meetingRoom,
  isMeetingRoomMoveable,
  handleMeetingRoomCoordinate,
}: Props) => {
  const layoutImage = document.getElementById(
    "layout-image"
  ) as HTMLImageElement
  const layoutImageWidth = layoutImage?.width
  const layoutImageHeight = layoutImage?.height
  const meetingRoomId = meetingRoom.id
  const meetingRoomCode = meetingRoom.meeting_room_code
  const initMeetingRoomStyle = meetingRoomStyle(
    meetingRoom.start_x,
    meetingRoom.start_y,
    meetingRoom.end_x,
    meetingRoom.end_y,
    meetingRoom.color_code
  )

  const throttles = { drag: 1, resize: 10, rotate: 90 }
  const bounds = {
    left: 0,
    top: 0,
    right: layoutImageWidth,
    bottom: layoutImageHeight,
  }

  const [privateProps, setPrivateProps] = useState(initMeetingRoomStyle)
  const [meetingRoomTarget, setMeetingRoomTarget] =
    useState<HTMLElement | null>()
  const [meetingRoomStyleObject, setMeetingRoomStyleObject] =
    useState(initMeetingRoomStyle)

  useEffect(() => {
    setMeetingRoomTarget(document.getElementById(`M${String(meetingRoomId)}`))
  }, [meetingRoomId])

  useEffect(() => {
    setMeetingRoomStyleObject({
      ...meetingRoomStyleObject,
      borderColor: meetingRoom.color_code,
      top: meetingRoom.start_y,
      left: meetingRoom.start_x,
      height: meetingRoom.end_y - meetingRoom.start_y,
      width: meetingRoom.end_x - meetingRoom.start_x,
    })
  }, [])

  return (
    <React.Fragment>
      <Box
        id={`M${String(meetingRoomId)}`}
        className={"meetingRoom"}
        sx={{
          ...meetingRoomStyleObject,
          borderColor: meetingRoom.color_code,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <span
          style={
            meetingRoomIconStyle(meetingRoom.color_code) as React.CSSProperties
          }
        >
          M
        </span>
        <span
          style={{
            top: "60px",
            left: "60px",
            color: meetingRoom.color_code,
            position: "absolute",
            fontSize: "30px",
          }}
        >
          {meetingRoomCode}
        </span>
      </Box>
      {isMeetingRoomMoveable ? (
        <Moveable
          target={meetingRoomTarget}
          draggable={true}
          resizable={true}
          snappable={true}
          throttleDrag={throttles.drag}
          throttleRotate={throttles.rotate}
          bounds={bounds}
          edge={false}
          zoom={1}
          origin={false}
          padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
          onDrag={({ target, transform }) => {
            target.style.transform = transform
          }}
          onDragEnd={() => {
            const targetElement = document.getElementById(
              "layout-image"
            ) as HTMLImageElement
            const clientRect = targetElement.getBoundingClientRect() as DOMRect
            const positionX = clientRect.left + window.pageXOffset
            const positionY = clientRect.top + window.pageYOffset

            const meetingRoomElement = document.getElementById(
              `M${String(meetingRoomId)}`
            ) as HTMLImageElement

            const meetingRoomRect =
              meetingRoomElement.getBoundingClientRect() as DOMRect

            const x = meetingRoomRect.x - positionX
            const y = meetingRoomRect.y - positionY
            const transformComponent = document.getElementsByClassName(
              "react-transform-component"
            )

            const transform = transformComponent[0] as HTMLElement
            const transformStyle = transform.style.transform.split("scale")[1]
            const scale = transformStyle.replace("(", "").replace(")", "")

            const start_x = x / parseFloat(scale)
            const start_y = y / parseFloat(scale)

            handleMeetingRoomCoordinate(
              meetingRoom,
              start_x,
              start_y,
              start_x + privateProps.width,
              start_y + privateProps.height
            )
          }}
          onResize={({ target, width, height, delta, drag }) => {
            // ドラッグして移動させた情報を取得
            const beforeTranslate = drag.beforeTranslate
            // 移動させた情報に即して位置を変更するためにtranslateを使用
            // beforeTranslate[0]: x座標の移動距離
            // beforeTranslate[1]: y座標の移動距離
            target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`

            // delta[0]: x座標の変化量
            // delta[1]: y座標の変化量
            delta[0] && (target.style.width = `${width}px`)
            delta[1] && (target.style.height = `${height}px`)
            setPrivateProps({
              ...privateProps,
              width: width,
              height: height,
            })
          }}
          onResizeEnd={() => {
            const targetElement = document.getElementById(
              "layout-image"
            ) as HTMLImageElement
            const clientRect = targetElement.getBoundingClientRect() as DOMRect
            const positionX = clientRect.left + window.pageXOffset
            const positionY = clientRect.top + window.pageYOffset

            const meetingRoomElement = document.getElementById(
              `M${String(meetingRoomId)}`
            ) as HTMLImageElement

            const meetingRoomRect =
              meetingRoomElement.getBoundingClientRect() as DOMRect

            const x = meetingRoomRect.x - positionX
            const y = meetingRoomRect.y - positionY

            const transformComponent = document.getElementsByClassName(
              "react-transform-component"
            )

            const transform = transformComponent[0] as HTMLElement
            const transformStyle = transform.style.transform.split("scale")[1]
            const scale = transformStyle.replace("(", "").replace(")", "")

            const start_x = x / parseFloat(scale)
            const start_y = y / parseFloat(scale)

            handleMeetingRoomCoordinate(
              meetingRoom,
              start_x,
              start_y,
              start_x + privateProps.width,
              start_y + privateProps.height
            )
          }}
        />
      ) : null}
    </React.Fragment>
  )
}
