import { AxiosResponse } from "axios"

import { CsvRequestType } from "../../../models/company/useEmployeeCSV/type"
import { api, ApiResponse } from "../../config/axiosConfig"

// サンプルCSVのダウンロード
export const downloadSampleCsvRequest = async (): Promise<AxiosResponse> => {
  const data = await api.get("/company/employees/csv/download_sample_csv", {
    responseType: "blob",
  })
  return data
}

// CSVのダウンロード
export const downloadCsvRequest = async (
  params: CsvRequestType
): Promise<AxiosResponse> => {
  const data = await api.post(
    "/company/employees/csv/download_csv",
    { employee_ids: params.employee_ids },
    {
      responseType: "blob",
    }
  )
  return data
}

// 従業員CSVのアップロード
export const uploadCsvRequest = async (
  params: FormData
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/employees/csv", params, {
      headers: {
        "Content-Type": "multipart-formdata",
      },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
