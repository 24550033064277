import React from "react"

import { Paper } from "@mui/material"

import { MobileDayCalendar } from "../../../components/mobile/schedule/DayMain"
import { AlertContent } from "../../../components/public/AlertContent"

export const MobileDayCalendarPage = () => {
  return (
    <>
      <MobileDayCalendar />
      <AlertContent />
    </>
  )
}
