import { yupResolver } from "@hookform/resolvers/yup"
import { transcode } from "buffer"
import * as Yup from "yup"

import { useState } from "react"
import { useForm } from "react-hook-form"

import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/errorMessage/en"
import jaTranslations from "../../../translations/errorMessage/ja"
import { EMAIL_REGEXP } from "../../../utils/regular"

export const useGuest = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const basicSchema = Yup.object().shape({
    last_name: Yup.string().required(translations.Required),
    first_name: Yup.string(),
    email: Yup.string()
      .required(translations.Required)
      .matches(EMAIL_REGEXP, translations.email),
  })

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
  })

  return {
    form,
  }
}
