import React from "react"

import { Box, styled } from "@mui/material"

import { SignageReceptionMap } from "../../../components/signage/reception/Map"

const CustomBox = styled(Box)(() => ({
  height: "100vh",
  width: "100vw",
}))

export const SignageReceptionMapPage = () => {
  return (
    <CustomBox>
      <SignageReceptionMap />
    </CustomBox>
  )
}
