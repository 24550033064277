import React from "react"

import { Box, Grid, styled, Typography } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { DragEmployee } from "../../../../../models/employee/useSchedule/useLayout/type"
import { WhoIsWheres } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"

const CustomTitleTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(4.375),
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: theme.palette.primary.main,
}))

const CustomNameTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "17px",
  color: "#454545",
}))

const TargetEmployeeList = styled(Grid)(({ theme }) => ({
  width: "70%",
  marginTop: theme.spacing(0.5),
  overflowY: "scroll",
  maxHeight: theme.spacing(30),
  "&::-webkit-scrollbar": {
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

interface Props {
  whoIsWheres: WhoIsWheres
  setDragEmployee: React.Dispatch<React.SetStateAction<DragEmployee>>
}

// 検索対象者一覧
export const EmployeeCalendarLayoutSearch: React.FC<Props> = ({
  whoIsWheres,
  setDragEmployee,
}: Props) => {
  const onDragEmployeeStart = (
    e: React.DragEvent<HTMLDivElement>,
    dragEmployee: DragEmployee
  ) => {
    setDragEmployee(dragEmployee)
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // ゲストには座席を割り当てないと合意済み。よってそもそもドラッグアンドドロップさせない
  const onlyEmployees = whoIsWheres.filter((whoIsWhere) => {
    return whoIsWhere.userable.userable_type === "Employee"
  })

  return (
    <>
      <Grid container alignItems="center">
        <Grid item sm={7}>
          <Box display="flex" marginBottom="20px">
            <CustomTitleTypography>
              {translations.Attendee}
              <Typography fontSize={1}>※{translations.DragDrop}</Typography>
            </CustomTitleTypography>
          </Box>
        </Grid>
      </Grid>
      <TargetEmployeeList container alignItems="center">
        {onlyEmployees.map((whoIsWhere, index) => {
          return (
            <Grid
              key={index}
              sm={11}
              draggable
              item
              sx={{
                margin: "0 0 5px 25px",
                padding: theme.spacing(0.25, 1.5),
                borderRadius: "30px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
              onDragStart={(e) => {
                onDragEmployeeStart(e, {
                  id: whoIsWhere.userable.userable_id,
                  icon: "",
                  last_name: whoIsWhere.userable.last_name,
                  first_name: whoIsWhere.userable.first_name,
                  email: whoIsWhere.userable.email,
                })
              }}
            >
              <Box display="flex">
                <CustomNameTypography marginRight="10px">
                  {whoIsWhere.userable.last_name}
                </CustomNameTypography>
                <CustomNameTypography>
                  {whoIsWhere.userable.first_name}
                </CustomNameTypography>
              </Box>
            </Grid>
          )
        })}
      </TargetEmployeeList>
    </>
  )
}
