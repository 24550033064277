import React, { ChangeEvent, useMemo, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  Modal,
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Popover,
  Alert,
} from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import { PostModalType } from "../../../../models/company/useLayout"
import {
  LayoutType,
  PostStatus,
} from "../../../../models/company/useLayout/type"
import {
  CompanyRelationsType,
  CompanyRelationLayoutType,
} from "../../../../models/public/useCompanyRelations/type"
import { dateStatus } from "../../../../utils/date"
import {
  calendarDateFormat,
  dateTimeFormat,
  todayDateFormat,
} from "../../../../utils/dateTimeFormat"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"
import { WarningText } from "../../../public/WarningText"

const pausedWarningText1 =
  "◉レイアウトの公開を停止すると、ユーザー画面からの対象フロア・レイアウトへのアクセスができなくなり、予約していたデータも参照できなくなります。"

const pausedWarningText2 =
  "◉停止日時を設定した場合、停止日時以降の予約はできなくなり、利用もできなくなりますが、これまで予約していたデータを停止日時までの間参照することができます。"

const pausedWarningText3 =
  "◉停止した後、削除しない限り、再度公開することで、対象フロアの予約・利用が再びできるようになります。"

const pausedWarningText4 =
  "◉レイアウトの軽微な変更は公開を停止しなくても、編集いただき、編集内容を反映ボタンを押すことで変更内容を反映することができます。"

interface Props {
  errorMessages: string[]
  layout: LayoutType
  setLayout: React.Dispatch<React.SetStateAction<LayoutType>>
  branchId: number
  floorId: number
  layoutId: number
  companyRelations: CompanyRelationsType
  changeSelectValue: (
    e: SelectChangeEvent<number | string>,
    company_id: number
  ) => void
  handleCreateLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postStatus: number
  ) => void
  handleCopyLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postStatus: number
  ) => void
  handleDeleteLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  handlePausedLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postStatus: number
  ) => void
  handleRevertToDraftLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postStatus: number
  ) => void
  handleRevertToReleaseLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  handleUnsuspendLayout: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  fetchCompanyRelations: () => Promise<CompanyRelationsType | undefined>
}

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
  borderRadius: "10px",
}))

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
})

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

const DateTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}))

const DateBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StatusLine = styled(Typography)(({ theme }) => ({
  color: "#dcdcdc",
  fontSize: theme.spacing(3),
}))

/*
  レイアウトの拠点・フロアを選択する
*/
export const CompanyLayoutFloorSelect: React.FC<Props> = ({
  errorMessages,
  layout,
  setLayout,
  branchId,
  floorId,
  layoutId,
  companyRelations,
  changeSelectValue,
  handleCreateLayout,
  handleCopyLayout,
  handlePausedLayout,
  handleDeleteLayout,
  handleRevertToDraftLayout,
  handleRevertToReleaseLayout,
  handleUnsuspendLayout,
  fetchCompanyRelations,
}: Props) => {
  const navigate = useNavigate()
  const { formatDate } = dateStatus()
  const [isOpen, setIsOpen] = useState(false)
  const [postStatus, setPostStatus] = useState<number>(0)
  const [postModal, setPostModal] = useState<PostModalType>("release")
  const [initPausedDate, setInitPausedDate] = useState<Date | string>("")
  const [targetReleaseLayout, setTargetReleaseLayout] = useState<
    CompanyRelationLayoutType | undefined
  >(undefined)
  const [isDisable, setIsDisable] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    //Popoverを開く
    setAnchorEl(event.currentTarget)
  }
  const warningOpen = Boolean(anchorEl)
  const id = warningOpen ? "simple-popover" : undefined

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const CustomTextColor = styled("span")(() => ({
    marginTop: "5px",
    color: "red",
    fontSize: "14px",
  }))
  const CustomTextColor2 = styled("span")(() => ({
    color: "red",
    fontSize: "16px",
    textDecoration: "underline",
  }))
  const pausedWarningText = (
    <CustomTextColor>を停止前に必ずお読みください</CustomTextColor>
  )

  const open = () => setIsOpen(true)
  const close = () => {
    setIsOpen(false)
  }

  // 選択中の拠点情報を取得する
  const selectedBranch = companyRelations.branches.filter(
    (branch) => branch.id === branchId
  )[0]

  // 選択中のフロア情報を取得する
  const selectedFloor = () => {
    const targetFloor = selectedBranch?.floors.filter(
      (floor) => floor.id === floorId
    )[0]

    return targetFloor
  }

  const handleDateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.name) {
      case "release_date":
        return setLayout({ ...layout, release_date: e.target.value })
      case "paused_date":
        return setLayout({ ...layout, paused_date: e.target.value })
    }
  }

  const handleChangePostRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "post_status":
        // 公開日時・停止日時の手動設定以外は公開日時・停止日時を初期化する
        if (Number(e.target.value) !== 1) {
          setLayout({
            ...layout,
            release_date: new Date(),
            paused_date: new Date(),
          })
        }
        return setPostStatus(Number(e.target.value))
    }
  }

  const validateCheck = () => {
    switch (postStatus) {
      case 0:
        return true
      case 1:
        return layout.release_date !== ""
      default:
        return false
    }
  }

  const postModalTitle = () => {
    switch (postModal) {
      case "release":
        return "レイアウトを公開しますか？"
      case "edit":
        return "編集内容を保存しますか？"
      case "paused":
        return "レイアウトを停止しますか？"
      case "draft":
        return "公開予定のレイアウトを下書きに戻しますか？"
      case "delete":
        return "レイアウトを削除しますか？"
      case "unsuspend":
        return "レイアウトの停止を解除しますか？"
      case "new":
        return "新しくレイアウトを登録し、新規バージョンを作成しますか？"
    }
  }

  const minDateFormat = useMemo(() => {
    const today = new Date()

    const year = today.getFullYear()

    const month = ("0" + (today.getMonth() + 1)).slice(-2)

    const day = ("0" + today.getDate()).slice(-2)

    return `${year}-${month}-${day}`
  }, [])

  useEffect(() => {
    if (initPausedDate === "") {
      setInitPausedDate(layout.paused_date)
    }
  }, [layout.paused_date])

  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )

  return (
    <>
      <Grid container>
        <Grid container alignItems="center" spacing={2} item sm={12}>
          <Grid item container sm={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="branch_id">拠点名</InputLabel>
                <Select
                  id="branch_id"
                  name="branch_id"
                  variant="outlined"
                  label="拠点名"
                  value={branchId}
                  fullWidth
                  sx={{
                    background: "rgba(112,112,112,0.05)",
                    borderRadius: "6px",
                    fontSize: "12px",
                  }}
                  IconComponent={GreenArrowIcon}
                  onChange={(e) => changeSelectValue(e, companyRelations.id)}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    拠点名を選択してください
                  </MenuItem>
                  {companyRelations.branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.branch_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container sm={2} alignItems="center">
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="floor_id">フロア名</InputLabel>
                <Select
                  id="floor_id"
                  name="floor_id"
                  variant="outlined"
                  label="フロア名"
                  value={floorId}
                  fullWidth
                  sx={{
                    background: "rgba(112,112,112,0.05)",
                    borderRadius: "6px",
                    fontSize: "12px",
                  }}
                  IconComponent={GreenArrowIcon}
                  onChange={(e) => changeSelectValue(e, companyRelations.id)}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    フロア名を選択してください
                  </MenuItem>
                  {selectedBranch &&
                    selectedBranch.floors.map((floor) => (
                      <MenuItem key={floor.id} value={floor.id}>
                        {floor.floor_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container sm={1.5} alignItems="center">
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="layout_id">レイアウトver</InputLabel>
                <Select
                  id="layout_id"
                  name="layout_id"
                  variant="outlined"
                  label="レイアウトver"
                  value={layoutId}
                  fullWidth
                  sx={{
                    background: "rgba(112,112,112,0.05)",
                    borderRadius: "6px",
                    fontSize: "12px",
                  }}
                  onChange={(e) => {
                    setInitPausedDate("")
                    changeSelectValue(e, companyRelations.id)
                  }}
                  IconComponent={GreenArrowIcon}
                >
                  <MenuItem disabled={true} key={0} value={0}>
                    ver選択
                  </MenuItem>
                  {selectedFloor()?.layouts.map((layout) => (
                    <MenuItem key={layout.id} value={layout.id}>
                      {`ver${layout.version}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container alignItems="center" sm={5.5}>
            {/* レイアウトが下書き中または未登録の時 */}
            {(layout.post_status === PostStatus.draft ||
              layout.post_status === PostStatus.not_saved) && (
              <>
                <Button
                  disabled={isDisable}
                  onClick={async (e) => {
                    setIsDisable(true)
                    await handleCreateLayout(e, 0)
                    setIsDisable(false)
                    await fetchCompanyRelations()
                  }}
                >
                  下書き
                </Button>
                <StatusLine>|</StatusLine>
                <Button
                  onClick={() => {
                    const desOrderReleaseLayout = selectedFloor().layouts.sort(
                      (a, b) => (a.release_date > b.release_date ? -1 : 1)
                    ) // 公開中の最新レイアウト取得

                    const targetLastReleaseLayout =
                      desOrderReleaseLayout.filter(
                        (targetLayout) => targetLayout.release_date
                      )[0] // 公開中の最新レイアウト取得

                    setTargetReleaseLayout(targetLastReleaseLayout) // 図面差し替え時、公開中のレイアウトがあった場合にどの旧レイアウトか停止するか分かるようにしている
                    open()
                    setPostModal("release")
                  }}
                >
                  公開
                </Button>
                {!location.pathname.match(/new/) && (
                  <>
                    <StatusLine>|</StatusLine>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("delete")
                      }}
                    >
                      削除
                    </Button>
                  </>
                )}
              </>
            )}

            {layout.post_status === PostStatus.released && (
              <>
                {/* レイアウトが公開中の時 */}
                {/* 公開予約の取消し */}
                {layout.release_date > formatDate(today) && (
                  <>
                    <DateBox>
                      <Typography>{`公開日予約`}</Typography>
                      <DateTypography sx={{ fontWeight: "bold" }}>
                        {calendarDateFormat(new Date(layout.release_date))}
                      </DateTypography>
                    </DateBox>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("draft")
                      }}
                    >
                      公開予約の取消し
                    </Button>
                    <StatusLine>|</StatusLine>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("edit")
                      }}
                    >
                      更新
                    </Button>
                  </>
                )}

                {/* 公開を停止する */}
                {layout.release_date <= formatDate(today) && !initPausedDate && (
                  <>
                    <DateTypography>
                      <Box
                        sx={{
                          borderRadius: "16px",
                          border: "1px solid #22BA9D",
                          backgroundColor: "#FFFFFF",
                          padding: "10px",
                          fontSize: "1rem",
                          color: "#22BA9D",
                          marginRight: "10px",
                        }}
                      >
                        公開中
                      </Box>
                    </DateTypography>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("paused")
                      }}
                    >
                      公開停止
                    </Button>
                    <StatusLine>|</StatusLine>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("edit")
                      }}
                    >
                      更新
                    </Button>
                    <StatusLine>|</StatusLine>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("new")
                      }}
                    >
                      レイアウト作成
                    </Button>
                  </>
                )}
                {/* 停止予約の取消し */}
                {initPausedDate > formatDate(today) && layout.release_date && (
                  <>
                    <DateBox>
                      <Typography>{"停止予定日"}</Typography>
                      <DateTypography sx={{ fontWeight: "bold" }}>
                        {calendarDateFormat(new Date(initPausedDate))}
                      </DateTypography>
                    </DateBox>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("cancel_reservation")
                      }}
                    >
                      停止予約の取消し
                    </Button>
                    <StatusLine>|</StatusLine>
                    <Button
                      onClick={() => {
                        open()
                        setPostModal("edit")
                      }}
                    >
                      更新
                    </Button>
                  </>
                )}
              </>
            )}

            {layout.post_status === PostStatus.paused && (
              <>
                {/* レイアウトが停止中の時 */}
                <Box
                  sx={{
                    borderRadius: "16px",
                    border: "1px solid #d32f2f",
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    fontSize: "1rem",
                    color: "#d32f2f",
                    marginRight: "10px",
                  }}
                >
                  停止中
                </Box>
                <Button
                  onClick={() => {
                    open()
                    setPostModal("delete")
                  }}
                >
                  削除
                </Button>
                <StatusLine>|</StatusLine>
                <Button
                  onClick={() => {
                    open()
                    setPostModal("unsuspend")
                  }}
                >
                  停止解除
                </Button>
              </>
            )}
            <Modal open={isOpen} onClose={close}>
              <CustomLayoutModal>
                <Box sx={{ padding: "30px 30px 20px" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    variant="h6"
                  >
                    {postModalTitle()}
                  </Typography>
                  {errorMessages.length !== 0 && (
                    <ErrorMessageContainer>
                      {ErrorText(errorMessages)}
                    </ErrorMessageContainer>
                  )}
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {postModal === "release" && (
                      <>
                        {targetReleaseLayout !== undefined && (
                          <Grid item xs={12} sm={12}>
                            {WarningText([
                              `現在公開中のver${
                                targetReleaseLayout.version
                              }のレイアウトを${
                                layout.release_date
                                  ? calendarDateFormat(
                                      new Date(layout.release_date)
                                    )
                                  : todayDateFormat()
                              }に停止します`,
                            ])}
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl>
                            <RadioGroup
                              name="post_status"
                              value={postStatus}
                              onChange={handleChangePostRadio}
                            >
                              <>
                                <FormControlLabel
                                  value={0}
                                  control={<Radio />}
                                  label="即日"
                                />
                                <FormControlLabel
                                  value={1}
                                  control={<Radio />}
                                  label="公開日を設定"
                                />
                              </>

                              {postStatus === 1 && (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <TextField
                                    id="datetime-local"
                                    label="公開日"
                                    name="release_date"
                                    type="date"
                                    defaultValue={dateTimeFormat(
                                      new Date(layout.release_date)
                                    )}
                                    onChange={handleDateChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      min: minDateFormat,
                                    }}
                                    sx={{
                                      "& .MuiOutlinedInput-input": {
                                        padding: "5px",
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {postModal === "paused" && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <Box
                            aria-owns={
                              warningOpen ? "mouse-over-popover" : undefined
                            }
                            aria-haspopup="true"
                            display="flex"
                          >
                            <>
                              <Alert
                                severity="warning"
                                sx={{ width: "100%" }}
                                style={{
                                  margin: "auto 0",
                                  alignItems: "center",
                                  height: "60px",
                                }}
                              >
                                <Button onClick={handleClickPopover}>
                                  <CustomTextColor2>注意事項</CustomTextColor2>
                                </Button>
                                {pausedWarningText}
                              </Alert>
                            </>
                          </Box>
                          <Popover
                            id={id}
                            open={warningOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Box sx={{ maxWidth: "500px" }}>
                              {WarningText([
                                pausedWarningText1,
                                pausedWarningText2,
                                pausedWarningText3,
                                pausedWarningText4,
                              ])}
                            </Box>
                          </Popover>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl>
                            <RadioGroup
                              name="post_status"
                              value={postStatus}
                              onChange={handleChangePostRadio}
                            >
                              <>
                                <FormControlLabel
                                  value={0}
                                  control={<Radio />}
                                  label="即日"
                                />
                                <FormControlLabel
                                  value={1}
                                  control={<Radio />}
                                  label="停止日を設定"
                                />
                              </>

                              {postStatus === 1 && (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <TextField
                                    id="datetime-local"
                                    label="停止日"
                                    name="paused_date"
                                    type="date"
                                    defaultValue={dateTimeFormat(
                                      new Date(layout.paused_date)
                                    )}
                                    onChange={handleDateChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      min: minDateFormat,
                                    }}
                                    sx={{
                                      "& .MuiOutlinedInput-input": {
                                        padding: "5px",
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {postModal === "delete" && (
                      <Grid item xs={12} sm={12}>
                        {WarningText(["削除したデータは永久に消滅します"])}
                      </Grid>
                    )}
                    {postModal === "cancel_reservation" && (
                      <Grid item xs={12} sm={12}>
                        {WarningText([
                          `${calendarDateFormat(
                            new Date(layout.paused_date)
                          )}のレイアウト停止予定を取消します`,
                        ])}
                      </Grid>
                    )}
                    {postModal === "unsuspend" && (
                      <Grid item xs={12} sm={12}>
                        {WarningText(["下書きの状態になります"])}
                      </Grid>
                    )}
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "300px",
                      m: "30px auto",
                    }}
                  >
                    {postModal !== "new" && (
                      <CancelButton
                        label="キャンセル"
                        handleClick={close}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "release" && (
                      <ReflectButton
                        label="決定"
                        disabled={!validateCheck() || isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handleCreateLayout(e, 1)
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "edit" && (
                      <ReflectButton
                        label="保存する"
                        disabled={isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handleCreateLayout(e, layout.post_status)
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "paused" && (
                      <ReflectButton
                        label="停止"
                        disabled={isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handlePausedLayout(e, 2)
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "draft" && (
                      <ReflectButton
                        label="下書きに戻す"
                        disabled={isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handleRevertToDraftLayout(e, 0)
                          setIsDisable(false)
                        }} // 下書きのステータスを引数に渡す
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "delete" && (
                      <ReflectButton
                        label="削除"
                        disabled={isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handleDeleteLayout(e)
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "cancel_reservation" && (
                      <ReflectButton
                        label="停止の取消し"
                        disabled={isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handleRevertToReleaseLayout(e)
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "unsuspend" && (
                      <ReflectButton
                        label="停止を解除"
                        disabled={isDisable}
                        handleClick={async (e) => {
                          setIsDisable(true)
                          await handleUnsuspendLayout(e)
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "new" && (
                      <CancelButton
                        label="いいえ"
                        handleClick={close}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                    {postModal === "new" && (
                      <ReflectButton
                        label="はい"
                        disabled={isDisable}
                        handleClick={() => {
                          setIsDisable(true)
                          navigate(
                            `/company/branches/${branchId}/floors/${floorId}/layouts/new`
                          )
                          window.location.reload()
                          setIsDisable(false)
                        }}
                        style={{ width: "140px", height: "50px" }}
                      />
                    )}
                  </Box>
                </Box>
              </CustomLayoutModal>
            </Modal>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
