import React, { memo, useEffect, useState } from "react"

import { Box } from "@mui/material"

import { SeatType } from "../../../../models/company/useQrCode/type"
import {
  freeSeatStyle,
  reservedSeatStyle,
  unavailableStyle,
} from "../../../../styles/seatStyle"

interface Props {
  seat: SeatType
  handleSeatSelect: (seatId: number, seatName: number) => void
  isSeatSelected: boolean
}

// QRコード画面：座席選択モーダル > 座席
export const CompanyQrCodeModalSeat: React.FC<Props> = memo(
  ({ seat, handleSeatSelect, isSeatSelected }: Props) => {
    const seatId = seat.id
    const seatType = seat.seat_type
    const seatName = seat.seat_name

    const [seatStyle, setSeatStyle] = useState<React.CSSProperties | undefined>(
      undefined
    )

    useEffect(() => {
      setSeatStyle(generateStyleObject as React.CSSProperties)
    }, [seatType, isSeatSelected])

    // 指定席・自由席・利用不可席のスタイルの変更
    const generateStyleObject = () => {
      switch (seatType) {
        case 0:
          return {
            ...reservedSeatStyle,
            top: seat.y,
            left: seat.x,
            backgroundColor: isSeatSelected ? "#F4B461" : "#22BA9D", // 座席が選択されている場合は黄色っぽくする
          }
        case 1:
          return {
            ...freeSeatStyle,
            top: seat.y,
            left: seat.x,
            backgroundColor: isSeatSelected ? "#F4B461" : "#fff",
          }
        case 2: {
          return {
            ...unavailableStyle,
            top: seat.y,
            left: seat.x,
          }
        }
        default:
          return {
            ...freeSeatStyle,
            top: seat.y,
            left: seat.x,
          }
      }
    }

    return (
      <Box
        id={String(seatId)}
        className={"seat"}
        sx={seatStyle}
        onClick={() => handleSeatSelect(seatId as number, seatName)}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>{seatName}</p>
        </div>
      </Box>
    )
  }
)
