import { useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { ApiResponse } from "../../api/config/axiosConfig"
import { fetchCheckInMeetingRoomNameRequest } from "../../api/employee/meetingRoomRequest"
import { CheckInMeetingRoomNameResponseType } from "../../models/employee/useCheckInOutMeetingRoom/type"

export const useFetchCheckInMeetingRoomName = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const fetchCheckInMeetingRoomName = useCallback(
    async (targetId: number): Promise<string | null> => {
      try {
        setIsLoading(true)
        const response: ApiResponse<CheckInMeetingRoomNameResponseType> =
          await fetchCheckInMeetingRoomNameRequest(targetId)
        if (response.status === 200 && response.data) {
          return response.data.meeting_room_name
        }
        return null
      } catch (error: any) {
        if (error.response.status === 403) {
          navigate("/mobile/home")
          throw error
        }
        return error.response.data
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  return {
    isLoading,
    fetchCheckInMeetingRoomName,
  }
}
