import React, { useState, useContext } from "react"
import {
  SubmitHandler,
  UseFormHandleSubmit,
  FieldErrors,
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { Create } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import { TextField, Grid, Box, Fab, styled } from "@mui/material"
import Paper from "@mui/material/Paper"
import Switch from "@mui/material/Switch"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { updateFloorOrderRequest } from "../../../../api/company/floorRequest"
import { BranchDetailType } from "../../../../models/company/useBranch/type"
import { FloorFormType } from "../../../../models/company/useFloor/type"
import { RoleContext } from "../../../../providers/RoleProvider"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { Paging } from "../../../public/Pagination"
import { ReflectButton } from "../../../public/ReflectButton"

interface Props {
  control: Control<FloorFormType>
  errors: FieldErrors<FloorFormType>
  isValid: boolean
  errorMessageOpen: boolean
  setErrorMessageOpen: React.Dispatch<React.SetStateAction<boolean>>
  errorMessages: string[]
  handleSubmit: UseFormHandleSubmit<FloorFormType>
  onCreateSubmit: SubmitHandler<FloorFormType>
  getValues: UseFormGetValues<FloorFormType>
  onUpdateSubmit: SubmitHandler<FloorFormType>
  setValue: UseFormSetValue<FloorFormType>
  branchDetail: BranchDetailType
  isCreateFormOpen: boolean
  setIsCreateFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  isUpdateFormOpen: boolean
  setIsUpdateFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
}))

export const CompanyFloorList: React.FC<Props> = ({
  control,
  handleSubmit,
  onCreateSubmit,
  onUpdateSubmit,
  errors,
  isValid,
  getValues,
  setValue,
  errorMessageOpen,
  setErrorMessageOpen,
  errorMessages,
  branchDetail,
  isCreateFormOpen,
  setIsCreateFormOpen,
  isUpdateFormOpen,
  setIsUpdateFormOpen,
}: Props) => {
  const [editFloorId, setEditFloorId] = React.useState<number>(0)
  const { isAdmin } = useContext(RoleContext)
  const navigate = useNavigate()
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const DATA_COUNT_PER_PAGE = 7 // 1ページに表示するレコードの数

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  // ペンマークを押したらフォームの初期値として、押したフロアの
  // floor_name(フロア名)とfloor_number(階数)とunderground_flag(地上・地下)と
  // id(floorのid)の値を取得してフォームの初期値としてセットする
  const handleOpen = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const key_floor_id = Number(e.currentTarget.getAttribute("id"))
    branchDetail.floors.find((floor) => {
      if (floor.id === key_floor_id) {
        setIsUpdateFormOpen(true)
        setValue("floor_name", floor.floor_name)
        setValue("floor_number", Number(floor.floor_number))
        setValue("underground_flag", floor.underground_flag)
        setValue("id", floor.id)
        setEditFloorId(key_floor_id)
      }
    })
  }

  const [floors, setFloors] = useState(
    branchDetail.floors.slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
  )

  const handleDragStart = (e: React.DragEvent<HTMLElement>, index: number) => {
    e.dataTransfer.setData("index", index.toString())
  }

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
  }

  const handleDrop = async (e: React.DragEvent<HTMLElement>, index: number) => {
    const draggingIndex = parseInt(e.dataTransfer.getData("index"))
    const newFloors = [...floors]
    const draggingFloor = newFloors[draggingIndex]
    newFloors.splice(draggingIndex, 1)
    newFloors.splice(index, 0, draggingFloor)
    setFloors(newFloors)
    newFloors.forEach((floor, index) => {
      floor.order_id = index + 1
    })
    branchDetail.floors = newFloors

    await updateFloorOrderRequest(newFloors)
  }

  // キャンセルボタンを押したらフォームと閉じる＆floor_name(フロア名)と
  // floor_number(階数)とunderground_flag(地上・地下)の値を初期化する。
  // ------------------ Why -----------------------------------
  // 初期化しないとペンマークを押した後(更新フォームを開いた後)に、
  // 新規登録しようとすると更新時の値がフォームの初期値としてセットされるため
  // ----------------------------------------------------------
  const handleClose = () => {
    setIsUpdateFormOpen(false)
    setValue("floor_name", "")
    setValue("floor_number", 0)
    setValue("underground_flag", false)
    setErrorMessageOpen(false) // エラーメッセージがある場合に表示をoffにする
  }

  const handleCreateFromOpen = () => setIsCreateFormOpen(true)
  const handleCreateFromClose = () => {
    setIsCreateFormOpen(false)
    setErrorMessageOpen(false) // エラーメッセージがある場合に表示をoffにする
  }

  // underground_flagの値により地上か地下かを表示するメソッド
  const isUnderGroundFlagStr = (underground_flag: boolean) => {
    return !underground_flag ? "地上" : "地下"
  }

  const CustomTextField = styled(TextField)(({ theme }) => ({
    height: "40px",
    fontSize: theme.spacing(1.5),
    color: "#454545",
    background: "#f7f7f7",
    borderRadius: "6px",
    ".MuiOutlinedInput-notchedOutline": { border: 0.05 },
    "& .MuiInputBase-input": {
      paddingTop: "8px",
      paddingRight: "14px",
      paddingBottom: "8px",
      paddingLeft: "14px",
    },
  }))

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 770 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  地上・地下
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  階数
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  フロア名
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  利用可能席数
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  制限出社率
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  最大利用可能席数
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  レイアウト
                </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  QRコード
                </TableCell>
                <TableCell
                  style={{ whiteSpace: "nowrap", fontSize: "10px" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branchDetail.floors
                .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                .map((floor, index: number) => (
                  <TableRow
                    key={floor.floor_name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                    draggable
                  >
                    {isAdmin && isUpdateFormOpen && editFloorId === floor.id ? (
                      <>
                        <TableCell colSpan={9}>
                          <Box sx={{ flexGrow: 1 }} id="pull-down">
                            <Grid container spacing={1}>
                              <Grid
                                item
                                style={{
                                  marginTop: "10px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                階数:
                              </Grid>
                              <Grid item xs={1} md={1.5}>
                                <Controller
                                  control={control}
                                  name="floor_number"
                                  render={({ field }) => (
                                    <CustomTextField
                                      {...field}
                                      error={!!errors.floor_number}
                                      helperText={errors.floor_number?.message}
                                      required
                                      id="floor_number"
                                      name="floor_number"
                                      variant="outlined"
                                      style={{
                                        width: "70px",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                md={0.6}
                                style={{
                                  marginTop: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                地上
                              </Grid>
                              <Grid item xs={1} md={1}>
                                <Controller
                                  control={control}
                                  name="underground_flag"
                                  render={({ field }) => (
                                    <Switch
                                      {...field}
                                      required
                                      checked={field.value}
                                      id="underground_flag"
                                      name="underground_flag"
                                      onChange={(e) => {
                                        field.onChange(e)
                                        e.target.value === "true"
                                          ? setValue("underground_flag", false)
                                          : getValues("underground_flag")
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                md={1}
                                style={{
                                  marginTop: "10px",
                                  fontSize: "12px",
                                }}
                              >
                                地下
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                md={1.3}
                                style={{
                                  marginTop: "10px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                フロア名：
                              </Grid>
                              <Grid item xs={3} md={4}>
                                <Controller
                                  control={control}
                                  name="floor_name"
                                  render={({ field }) => (
                                    <CustomTextField
                                      style={{
                                        width: "350px",
                                      }}
                                      {...field}
                                      error={!!errors.floor_name}
                                      helperText={errors.floor_name?.message}
                                      required
                                      id="floor_name"
                                      name="floor_name"
                                      fullWidth
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              <CustomBox style={{ width: "300px" }}>
                                {errorMessageOpen && ErrorText(errorMessages)}
                              </CustomBox>
                              <Grid
                                item
                                xs={1}
                                md={4}
                                style={{ display: "none" }}
                              >
                                <Controller
                                  control={control}
                                  name="id"
                                  render={() => (
                                    <TextField
                                      type="hidden"
                                      id="id"
                                      name="id"
                                      fullWidth
                                      variant="outlined"
                                      value={floor.id}
                                    />
                                  )}
                                />
                              </Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "450px",
                                  marginTop: "20px",
                                  marginLeft: "447px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={3}
                                  md={8}
                                  style={{ marginBottom: "5px" }}
                                >
                                  <CancelButton
                                    label="キャンセル"
                                    handleClick={handleClose}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  md={8}
                                  style={{ marginBottom: "5px" }}
                                >
                                  <ReflectButton
                                    label={"更新"}
                                    disabled={!isValid}
                                    handleClick={handleSubmit(onUpdateSubmit)}
                                  />
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontSize: "12px" }}
                        >
                          {isUnderGroundFlagStr(floor.underground_flag)}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "12px" }}>
                          {floor.floor_number}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "12px" }}>
                          {floor.floor_name}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "12px" }}>
                          {floor.restricted_seat_count}席
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "12px" }}>
                          {floor.limited_attendance_rate}%
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: "12px" }}>
                          {floor.maximum_available_count}席
                        </TableCell>
                        <TableCell align="center">
                          <button
                            onClick={() => {
                              if (floor.layouts.length !== 0) {
                                // 最新のレイアウトのIDを設定している
                                navigate(
                                  `/company/branches/${branchDetail.id}/floors/${floor.id}/layouts/${floor.layouts[0].id}`
                                )
                              } else {
                                navigate(
                                  `/company/branches/${branchDetail.id}/floors/${floor.id}/layouts/new`
                                )
                              }
                            }}
                            style={{ fontSize: "12px" }}
                          >
                            席情報
                          </button>
                        </TableCell>
                        <TableCell align="center">
                          <button
                            onClick={() =>
                              navigate(
                                `/company/branches/${branchDetail.id}/floors/${floor.id}/qrcode`
                              )
                            }
                            style={{ fontSize: "12px" }}
                          >
                            発行
                          </button>
                        </TableCell>
                        <TableCell align="right" style={{ paddingTop: "18px" }}>
                          {isAdmin && (
                            <Create
                              id={`${floor.id}`}
                              onClick={handleOpen}
                              fontSize="small"
                            />
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isCreateFormOpen && (
          <Box sx={{ flexGrow: 1 }} id="pull-down">
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid
                item
                xs={1}
                style={{
                  paddingLeft: "30px",
                  marginTop: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                階数：
              </Grid>
              <Grid item xs={4} md={1.3}>
                <Controller
                  control={control}
                  name="floor_number"
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      error={!!errors.floor_number}
                      helperText={errors.floor_number?.message}
                      required
                      id="floor_number"
                      name="floor_number"
                      fullWidth
                      variant="outlined"
                      style={{ width: "70px" }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                md={0.8}
                style={{
                  paddingLeft: "29px",
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                地上
              </Grid>
              <Grid item xs={1} md={1} style={{ marginTop: "0px" }}>
                <Controller
                  control={control}
                  name="underground_flag"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      required
                      checked={field.value}
                      id="underground_flag"
                      name="underground_flag"
                      onChange={(e) => {
                        field.onChange(e)
                        e.target.value === "true"
                          ? setValue("underground_flag", false)
                          : getValues("underground_flag")
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                style={{ marginTop: "10px", fontSize: "12px" }}
              >
                地下
              </Grid>
              <Grid
                item
                xs={2}
                md={1.5}
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                フロア名：
              </Grid>
              <Grid item xs={3} md={4}>
                <Controller
                  control={control}
                  name="floor_name"
                  render={({ field }) => (
                    <CustomTextField
                      style={{
                        marginTop: "1px",
                        width: "340px",
                      }}
                      {...field}
                      error={!!errors.floor_name}
                      helperText={errors.floor_name?.message}
                      required
                      id="floor_name"
                      name="floor_name"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomBox style={{ width: "300px" }}>
                  {errorMessageOpen && ErrorText(errorMessages)}
                </CustomBox>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "450px",
                  marginTop: "10px",
                  marginLeft: "510px",
                }}
              >
                <Grid item xs={6} md={8} style={{ marginBottom: "20px" }}>
                  <CancelButton
                    label="キャンセル"
                    handleClick={handleCreateFromClose}
                  />
                </Grid>
                <Grid item xs={6} md={8} style={{ marginBottom: "20px" }}>
                  <ReflectButton
                    label={"登録"}
                    disabled={!isValid}
                    handleClick={handleSubmit(onCreateSubmit)}
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          {branchDetail.floors.length > DATA_COUNT_PER_PAGE && (
            <Paging
              pageCount={Math.ceil(
                branchDetail.floors.length / DATA_COUNT_PER_PAGE
              )}
              page={pageNumber}
              handlePaginationChange={handlePaginationChange}
            />
          )}
        </Box>
        {isAdmin && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Fab
              color="secondary"
              aria-label="add"
              size="small"
              onClick={handleCreateFromOpen}
            >
              <AddIcon id="form" />
            </Fab>
          </Box>
        )}
      </Box>
    </>
  )
}
