import { SchedulableMonthesResponseType } from "../../models/company/useSchedulableMonthes/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchSchedulableMonthesRequest = async (): Promise<
  ApiResponse<SchedulableMonthesResponseType>
> => {
  try {
    const data = await api.get("/company/schedulable_monthes")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateSchedulableMonthesRequest = async (
  params: SchedulableMonthesResponseType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/schedulable_monthes/${params?.id}`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
