import axios from "axios"

import React, { useEffect, useRef, useState, useContext } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"
import { useNavigate } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import SearchIcon from "@mui/icons-material/Search"
import {
  Grid,
  Button,
  Box,
  FormHelperText,
  Typography,
  styled,
  Checkbox,
  SvgIcon,
  CheckboxProps,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  SelectChangeEvent,
  FormControl,
  FormControlLabel,
  Tooltip,
  Radio,
  RadioGroup,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
} from "@mui/material"

import {
  checkAzureAdIntegration,
  getAzureAdGroups,
  syncAzureAdUsers,
} from "../../../../api/AzureAD/azureadRequest"
import { EmployeeListResponseType } from "../../../../models/company/useEmployee/type"
import { SearchEmployeeListRequestType } from "../../../../models/company/useEmployeeListSearch/type"
import { useCompanyRelations } from "../../../../models/public/useCompanyRelations"
import { RoleContext } from "../../../../providers/RoleProvider"
import { useOpen } from "../../../../utils/isOpen"
import { AlertContent, AlertErrorContent } from "../../../public/AlertContent"
import { AlertError } from "../../../public/AlertError"
import { ConfirmModal } from "../../../public/ConfirmModal"
import { CompanyEmployeesIconWrapper } from "../../../public/EmployeeIconWrapper"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { CompanyEmployeesPaging } from "../../../public/Pagination"

export type NoRecordTextType = "表示するデータがありません"

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "stretch",
  width: "100%",
})

const CustomLeft = styled(Grid)({
  display: "flex",
  flexGrow: 1,
  overflowX: "auto",
  marginRight: "5px",
})

const verticalLine = {
  minHeight: "100px",
  border: "solid",
  color: "#E5E5E5",
  marginTop: "-5px",
  marginRight: "25px",
  marginLeft: "0px",
}

const CustomRight = styled(Grid)(({ theme }) => ({
  height: "100%",
  width: "246px",
  marginTop: theme.spacing(0),
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "216px",
  height: "41px",
  fontFamily: "Noto Sans",
  fontSize: theme.spacing(1.75),
  letterSpacing: "0.7px",
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
  borderRadius: "6px",
}))

const SelectButton = styled(Button)(({ theme }) => ({
  "&&": {
    width: "120px",
    height: "35px",
    fontSize: theme.spacing(2),
    fontWeight: "bold",
    borderColor: "#22BA9D",
    borderStyle: "solid",
    borderWidth: "1px",
  },
}))

const CustomSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "36px",
  fontSize: theme.spacing(1.5),
  fontFamily: "Noto Sans",
  color: "#454545",
  backgroundColor: "#f7f7f7",
  textOverflow: "ellipsis",
  borderRadius: "6px",
  ".MuiOutlinedInput-notchedOutline": { border: 0.05 },
}))

const NumberOfDisplaysSelect = styled(Select)(({ theme }) => ({
  marginLeft: "10px",
  width: "67px",
  height: "36px",
  fontFamily: "Noto Sans",
  fontSize: theme.spacing(1.5),
  color: "#454545",
  backgroundColor: "#f7f7f7",
  borderRadius: "6px",
  ".MuiOutlinedInput-notchedOutline": { border: 0.05 },
}))

const PausedIcon = styled(Box)(() => ({
  position: "relative",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  background: "#D85757",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "1px",
    height: "12px",
    background: "#fff",
    transform: "translate(-50%,-50%) rotate(45deg)",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "1px",
    height: "12px",
    background: "#fff",
    transform: "translate(-50%,-50%) rotate(-45deg)",
  },
}))

const displayResultsText = {
  color: "#22BA9D",
  paddingLeft: "12px",
  paddingTop: "3px",
  fontWeight: "bold",
}

const rightSelectTitle = {
  paddingLeft: "28px",
  color: "#22BA9D",
  letterSpacing: "0.6px",
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "12px",
}

const employeeSearchText = {
  display: "flex",
  color: "#22BA9D",
  fontWeight: "bold",
  marginTop: "30px",
}

const CustomFormControlFont = styled(Grid)(({ theme }) => ({
  width: "100px",
  height: "14px",
  fontSize: theme.spacing(1.75),
  fontWeight: "bold",
  color: "#22BA9D",
  letterSpacing: "0.7px",
}))

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "216px",
  height: "36px",
  fontSize: theme.spacing(1.5),
  fontFamily: "Noto Sans",
  color: "#454545",
  background: "#f7f7f7",
  borderRadius: "6px",
  ".MuiOutlinedInput-notchedOutline": { border: 0.05 },
}))

const formLabel = (text: string) => {
  return (
    <Typography sx={{ fontSize: "12px", zIndex: "0" }} key={text}>
      {text}
    </Typography>
  )
}

const employeeTextFont = {
  fontFamily: "Noto Sans",
  fontSize: "12px",
  color: "#454545",
  lineHeight: "1.42",
}

const EmailTextFont = {
  color: "#454545",
  fontFamily: "Noto Sans",
  fontSize: "8px",
  lineHeight: "1.3",
  textDecoration: "underline",
  maxWidth: "130px",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

const employeeFont = {
  color: "#22BA9D",
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "12px",
  paddingTop: "10px",
  paddingRight: "10px",
  paddingBottom: "10px",
  paddingLeft: "40px",
  width: "4%",
}

const employeeTeam = {
  color: "#22BA9D",
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "12px",
  padding: "10px",
  width: "2%",
}

const employeeListTitleFont = {
  color: "#22BA9D",
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "12px",
  padding: "10px",
  width: "5%",
}

const employeeWidth: React.CSSProperties = {
  color: "#454545",
  fontFamily: "Noto Sans",
  fontSize: "12px",
  lineHeight: "1.42",
  paddingLeft: "10px",
  width: "6%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}

const UncheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      border: "1px solid #22BA9D",
      backgroundColor: "white",
    }}
  />
)

const CheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      backgroundColor: "#22BA9D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SvgIcon
      sx={{
        color: "white",
        fontSize: "1rem",
      }}
    >
      <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
    </SvgIcon>
  </Box>
)

const CustomCheckbox: React.FC<CheckboxProps> = (props) => (
  <Checkbox icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...props} />
)

interface Props {
  employeeList: EmployeeListResponseType[]
  downloadSampleCsv: () => Promise<void>
  downloadCsv: (data: number[]) => Promise<void>
  uploadCsv: (file: Blob) => Promise<void>
  handleEmployeeChecked: (employeeId: number) => void
  handleAllEmployeeChecked: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCreateUserOnCognito: () => Promise<void>
  handlePausedEmployee: (isDeleteSchedules: boolean) => Promise<void>
  handleReleaseEmployee: () => Promise<void>
  control: Control<SearchEmployeeListRequestType>
  errors: FieldErrors<SearchEmployeeListRequestType>
  setValue: UseFormSetValue<SearchEmployeeListRequestType>
  onSearchEmployeeListSubmit: SubmitHandler<SearchEmployeeListRequestType>
  handleSubmit: UseFormHandleSubmit<SearchEmployeeListRequestType>
  clearErrors: UseFormClearErrors<SearchEmployeeListRequestType>
}

export const EmployeeList: React.FC<Props> = ({
  employeeList,
  downloadSampleCsv,
  downloadCsv,
  uploadCsv,
  handleEmployeeChecked,
  handleAllEmployeeChecked,
  handleCreateUserOnCognito,
  handlePausedEmployee,
  handleReleaseEmployee,
  control,
  errors,
  clearErrors,
  setValue,
  onSearchEmployeeListSubmit,
  handleSubmit,
}: Props) => {
  const navigate = useNavigate()
  //ローカルストレージから1ページあたりの表示件数を受け取る
  const page_number = Number(
    window.localStorage.getItem("employeeList_page_number")
  )
  // 1ページに表示するレコードの数
  const [dataCountPerPage, setDataCountPerPage] = useState<number>(
    page_number ? page_number : 20
  )
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const csvUploadRef = useRef<HTMLInputElement>(null)
  const [checkedEmployeeIds, setCheckedEmployeeIds] = useState<number[]>([])
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [customError, setCustomError] = useState("")
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0)
  const [selectedFloorId, setSelectedFloorId] = useState<number>(0)
  const [selectedTeamId, setSelectedTeamId] = useState<number>(0)
  const { companyRelations } = useCompanyRelations()
  const [noRecordText, setNoRecordText] =
    useState<NoRecordTextType>("表示するデータがありません") // 表示するデータがない場合のテキスト
  const { isAdmin, isTeamLeader } = useContext(RoleContext)
  const pauseModal = useOpen() // 利用停止モーダルの開閉
  const releaseModal = useOpen() // 利用停止解除モーダルの開閉
  const [isDeleteSchedules, setIsDeleteSchedules] = useState<boolean>(false)
  const [searchButtonClicked, setSearchButtonClicked] = useState(false)
  const [syncGroup, setSyncGroup] = useState<string>("all")
  const [showAlert, setShowAlert] = React.useState(false)
  const [groups, setGroups] = useState<string[]>([])
  const [isAzureAdIntegrated, setIsAzureAdIntegrated] = useState<boolean>(false)

  const handleOpenAlert = async () => {
    await getAzureAdGroups(setGroups, setShowAlert)
  }

  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const handleSync = async () => {
    await syncAzureAdUsers(syncGroup, handleCloseAlert)
  }

  useEffect(() => {
    const fetchAzureAdIntegration = async () => {
      const result = await checkAzureAdIntegration()
      setIsAzureAdIntegrated(result)
    }

    fetchAzureAdIntegration()
  }, [])

  const selectedBranch = companyRelations.branches.find(
    (branch) => branch.id === selectedBranchId
  )

  // シートidが存在している場合はシート番号を返し、なかったら「無」で返す
  const isSeatStr = (flag: string) => {
    return flag === null ? "無" : flag
  }

  // 権限がenumの値で返ってくるため、日本語に変換して表示をする
  const roleStr = (role: string) => {
    if (role === "admin") {
      return "法人管理者"
    } else if (role === "manager") {
      return "拠点長"
    } else if (role === "department") {
      return "所属長"
    } else if (role === "general") {
      return "一般社員"
    }
  }

  const handleChangeIsDeleteSchedules = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (e.target.value) {
      case "true":
        return setIsDeleteSchedules(true)
      case "false":
        return setIsDeleteSchedules(false)
    }
  }

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * dataCountPerPage)
    setPageNumber(page)
  }

  // 1ページあたりの表示件数の変更
  const handleDataCountChange = (e: SelectChangeEvent<unknown>) => {
    setDataCountPerPage(Number(e.target.value))
    // 表示件数を変更した場合ページネーションをリセットする
    setPageStatus(0)
    setPageNumber(1)
    //ローカルストレージに1ページあたりの表示件数を保存
    localStorage["employeeList_page_number"] = Number(e.target.value)
  }

  // 「CSVアップロード」ボタンをクリックした時にfile_filedを起動
  const handleUploadButtonClick = () => {
    csvUploadRef.current?.click()
  }

  // CSVファイルのアップロード
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return
    uploadCsv(e.target.files[0])
  }

  // チェック済みの従業員のリストを作成
  const checkEmployee = () => {
    const checkedEmployeeList = employeeList.filter((employee) => {
      return employee.checked
    })
    if (checkedEmployeeList.length === 0) return

    const idList = checkedEmployeeList.map((employee) => {
      return employee.id
    })
    setCheckedEmployeeIds(idList)
    setErrorMessage("")
    setErrorMessageOpen(false)
  }

  const handleCsvDownload = () => {
    if (checkedEmployeeIds.length == 0) {
      setErrorMessage("従業員を選択してください")
      setErrorMessageOpen(true)
    } else {
      downloadCsv(checkedEmployeeIds)
    }
  }

  useEffect(() => {
    checkEmployee()
  }, [employeeList])

  useEffect(() => {
    resetState()
  }, [employeeList])

  // 検索ボタンをクリックした際にフォームとページネーションを初期化
  const resetState = () => {
    setSelectedBranchId(0)
    setSelectedFloorId(0)
    setSelectedTeamId(-1)
    setValue("branch_id", 0)
    setValue("floor_id", 0)
    setValue("freeword", "")
    setValue("team_id", -1)
    setCheckedBox([])
    setValue("include_unavailable", false)
    setValue("admin_only", false)
    setValue("unavailable_only", false)
    clearErrors(undefined)
  }

  // チェックボックスの状態管理
  const [checkedBox, setCheckedBox] = useState<string[]>([])

  const handleCheckChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: keyof SearchEmployeeListRequestType
  ) => {
    let updatedCheckedBox = checkedBox
    if (checkedBox.includes(value)) {
      updatedCheckedBox = checkedBox.filter((item) => item !== value)
      setCheckedBox(updatedCheckedBox)
    } else {
      updatedCheckedBox = [...checkedBox, value]
      setCheckedBox(updatedCheckedBox)
    }
    setValue(value, updatedCheckedBox.includes(value))
  }

  const checkboxLabel = (text: string) => {
    return (
      <>
        <Typography sx={{ fontSize: "11px", zIndex: "0" }} key={text}>
          {text}
        </Typography>
      </>
    )
  }

  return (
    <>
      <ConfirmModal
        modalTitle={"利用中のアカウントを利用停止します"}
        warningText={[
          "利用停止します。対象アカウントはログインできなくなります。",
          "対象アカウントの予約している席及び会議室・共同予定について予約状態を取り消して停止しますか？ 尚、取り消された予約は他の人の参加する会議も含めて取り消され、再予約が必要となる可能性があります。",
        ]}
        isOpen={pauseModal.isOpen}
        close={pauseModal.close}
        handleClick={() => handlePausedEmployee(isDeleteSchedules)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <FormControl>
            <RadioGroup
              name="is_schedule_delete"
              value={isDeleteSchedules}
              onChange={handleChangeIsDeleteSchedules}
            >
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="予約を取り消して停止"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="予約を残して停止"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </ConfirmModal>
      <ConfirmModal
        modalTitle={"利用停止中のアカウントを解除します"}
        isOpen={releaseModal.isOpen}
        close={releaseModal.close}
        handleClick={() => handleReleaseEmployee()}
      />
      <StyledBox>
        <CustomLeft container>
          <Grid item xs={0.5} spacing={1}>
            <CustomCheckbox
              size="small"
              style={{ padding: "10px 10px 15px 11px" }}
              onChange={handleAllEmployeeChecked}
            />
          </Grid>
          <Grid
            item
            xs={1.2}
            spacing={0.8}
            style={{
              padding: "9px 0px 0px 3px",
              fontFamily: "Noto Sans",
              fontWeight: "bold",
              fontSize: "12px",
              color: "#22BA9D",
            }}
          >
            全て選択
          </Grid>
          <Grid
            item
            xs={1.5}
            spacing={1}
            onClick={handleCsvDownload}
            sx={{
              paddingTop: "9px",
              textDecoration: "underline",
              fontFamily: "Noto Sans",
              fontWeight: "bold",
              fontSize: "12px",
              color: "#22BA9D",
              letterSpacing: "0.6px",
              cursor: "pointer",
            }}
          >
            CSVダウンロード
          </Grid>
          <Grid
            item
            xs={1.7}
            spacing={1}
            onClick={downloadSampleCsv}
            sx={{
              paddingTop: "9px",
              textDecoration: "underline",
              fontFamily: "Noto Sans",
              fontWeight: "bold",
              fontSize: "12px",
              color: "#22BA9D",
              letterSpacing: "0.6px",
              cursor: "pointer",
            }}
          >
            記入例ダウンロード
          </Grid>
          <Grid item xs={2.5} spacing={1}>
            <CustomSelect
              defaultValue="処理を選択"
              sx={{ width: "121px" }}
              IconComponent={GreenArrowIcon}
            >
              <MenuItem value="処理を選択" key={0} disabled>
                処理を選択
              </MenuItem>
              <MenuItem
                value="メール送信"
                key={1}
                onClick={handleCreateUserOnCognito}
              >
                メール送信
              </MenuItem>
              {isAdmin && (
                <div>
                  <MenuItem
                    value="利用停止"
                    key={2}
                    onClick={() => pauseModal.open()}
                  >
                    利用停止
                  </MenuItem>
                  <MenuItem
                    value="利用停止の解除"
                    key={3}
                    onClick={() => releaseModal.open()}
                  >
                    利用停止の解除
                  </MenuItem>
                </div>
              )}
            </CustomSelect>
          </Grid>
          <Grid
            item
            xs={0.85}
            spacing={1}
            style={{
              ...displayResultsText,
              paddingTop: "9px",
              color: "#22BA9D",
              fontFamily: "Noto Sans",
              fontWeight: "bold",
              fontSize: "12px",
              letterSpacing: "0.6px",
            }}
          >
            表示件数
          </Grid>
          <Grid item xs={0.75} spacing={1}>
            <NumberOfDisplaysSelect
              defaultValue={dataCountPerPage}
              onChange={handleDataCountChange}
              sx={{
                background: "rgba(112,112,112,0.05)",
              }}
              IconComponent={GreenArrowIcon}
            >
              <MenuItem value="20" key={0}>
                20
              </MenuItem>
              <MenuItem value="25" key={1}>
                25
              </MenuItem>
              <MenuItem value="50" key={2}>
                50
              </MenuItem>
            </NumberOfDisplaysSelect>
          </Grid>
          <AlertError
            isMessageOpen={errorMessageOpen}
            closeMessage={() => setErrorMessageOpen(false)}
            alertMessage={errorMessage}
          />
          {(!isTeamLeader || searchButtonClicked) && employeeList.length > 0 ? (
            <TableContainer sx={{ height: "100%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={employeeFont}>従業員</TableCell>
                    <TableCell style={employeeTeam}>所属</TableCell>
                    <TableCell
                      style={{
                        ...employeeTeam,
                        minWidth: "170px",
                      }}
                    >
                      所属長
                    </TableCell>
                    <TableCell
                      style={{
                        ...employeeTeam,
                        minWidth: "90px",
                      }}
                    >
                      拠点
                    </TableCell>
                    <TableCell
                      style={{
                        ...employeeTeam,
                        minWidth: "70px",
                      }}
                    >
                      指定席
                    </TableCell>
                    <TableCell style={employeeTeam}>権限</TableCell>
                    <TableCell style={employeeListTitleFont}>備考</TableCell>
                    {isAdmin && isAzureAdIntegrated && (
                      <TableCell style={employeeTeam}>AzureAD</TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {employeeList
                    .slice(pageStatus, pageStatus + dataCountPerPage)
                    .filter(
                      (employee) =>
                        employee.account.available_status !== "deleted"
                    )
                    .map((employee, index) => {
                      return (
                        <>
                          <TableRow
                            key={index}
                            hover
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              navigate(`/company/employees/${employee.id}`)
                            }
                          >
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "2px",
                              }}
                            >
                              <CustomCheckbox
                                size="small"
                                checked={!!employee.checked}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleEmployeeChecked(employee.id)
                                }}
                              />
                              <CompanyEmployeesIconWrapper
                                style={{ margin: "0 15px 0 6px" }}
                              >
                                {employee.icon === null ? (
                                  <Typography
                                    fontSize="0.75rem"
                                    color="inherit"
                                  >
                                    {employee.last_name[0]}
                                    {employee.first_name[0]}
                                  </Typography>
                                ) : (
                                  <img
                                    src={employee.icon}
                                    style={{
                                      borderRadius: "50%",
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </CompanyEmployeesIconWrapper>
                              <Box>
                                <Typography style={employeeTextFont}>
                                  {employee.last_name}
                                  <span style={{ margin: "0 0.2rem" }}></span>
                                  {employee.first_name}
                                </Typography>
                                <Typography style={EmailTextFont}>
                                  {employee.account.email}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "20px",
                                }}
                              >
                                {employee.account.available_status ===
                                  "paused" && (
                                  <Tooltip
                                    title="利用停止中のユーザーです"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          maxWidth: "200px",
                                        },
                                      },
                                    }}
                                  >
                                    <PausedIcon />
                                  </Tooltip>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell style={employeeWidth}>
                              {employee.team.team_name}
                            </TableCell>
                            <TableCell style={employeeWidth}>
                              {employee.team.team_leader_name}
                              <br />
                              <Typography style={EmailTextFont}>
                                {employee.team.team_leader_email}
                              </Typography>
                            </TableCell>
                            <TableCell style={employeeWidth}>
                              {employee.branch.branch_name}
                              <br />
                              <Typography
                                style={{
                                  fontFamily: "Noto Sans",
                                  fontSize: "10px",
                                  color: "#454545",
                                  lineHeight: "1.3",
                                }}
                              >
                                {employee.floor.floor_name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                ...employeeWidth,
                                paddingLeft: "15px",
                              }}
                            >
                              {isSeatStr(employee.seat_name)}
                            </TableCell>
                            <TableCell style={employeeWidth}>
                              {roleStr(employee.role.toString())}
                            </TableCell>
                            <TableCell
                              style={{
                                color: "#454545",
                                fontFamily: "Noto Sans",
                                fontSize: "12px",
                                lineHeight: "1.33",
                                letterSpacing: "0.6px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {employee.note}
                            </TableCell>
                            {isAdmin && isAzureAdIntegrated && (
                              <TableCell>
                                {employee.account.object_id ? (
                                  <CircleOutlinedIcon
                                    sx={{
                                      width: "1.3rem",
                                      height: "1.3rem",
                                      color: "#22BA9D",
                                      backgroundColor: "transparent",
                                    }}
                                  />
                                ) : null}
                              </TableCell>
                            )}
                          </TableRow>
                        </>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : isTeamLeader && searchButtonClicked ? (
            <>
              <Box mt="auto" width="100%" marginBottom="570px">
                <Typography textAlign="center">{noRecordText}</Typography>
              </Box>
            </>
          ) : null}

          <Box
            sx={{
              margin: "0 auto",
            }}
          >
            {((!isTeamLeader && employeeList.length > dataCountPerPage) ||
              (isTeamLeader &&
                searchButtonClicked &&
                employeeList.length > dataCountPerPage)) && (
              <CompanyEmployeesPaging
                pageCount={Math.ceil(employeeList.length / dataCountPerPage)}
                page={pageNumber}
                handlePaginationChange={handlePaginationChange}
              />
            )}
          </Box>
        </CustomLeft>
        <hr style={verticalLine} />
        <CustomRight container item xs={2} spacing={2}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} style={{ marginTop: "0px", textAlign: "right" }}>
              <CustomButton
                variant="outlined"
                disabled={!isAdmin}
                onClick={handleUploadButtonClick}
              >
                <FileUploadOutlinedIcon sx={{ marginRight: "10px" }} />
                CSVアップロード
              </CustomButton>
              <input
                hidden
                ref={csvUploadRef}
                type="file"
                onChange={handleFileUpload}
                accept="text/csv"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "20px", textAlign: "right" }}
            >
              <CustomButton
                variant="contained"
                disabled={!isAdmin}
                onClick={() => navigate("/company/employees/new")}
              >
                <AddIcon sx={{ marginRight: "10px" }} />
                新規従業員の登録
              </CustomButton>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: "20px", textAlign: "right" }}
            >
              {isAdmin && isAzureAdIntegrated && (
                <CustomButton variant="outlined" onClick={handleOpenAlert}>
                  A<span style={{ textTransform: "lowercase" }}>zure</span>
                  ADと同期する
                </CustomButton>
              )}
            </Grid>

            <Dialog
              open={showAlert}
              onClose={handleCloseAlert}
              PaperProps={{ style: { marginTop: "-10vh" } }}
            >
              <DialogTitle>同期する対象を選択</DialogTitle>
              <DialogContent>
                <div style={{ marginBottom: "20px", fontWeight: "bold" }}>
                  ※
                  <span style={{ color: "red" }}>新規で追加されるユーザー</span>
                  につきましては勤務形態、指定席、権限は下記ステータスで登録されます。
                  <br />
                  勤務形態：出社勤務、指定席：なし、権限：一般社員
                  <br />
                  勤務形態、指定席、権限については、WORKAGILE上で設定下さい。
                </div>

                <FormControl fullWidth>
                  <Select
                    labelId="sync-group-label"
                    value={syncGroup}
                    onChange={(e) => setSyncGroup(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 6,
                        },
                      },
                    }}
                  >
                    <MenuItem value="all">全てのメンバー</MenuItem>
                    {groups
                      .filter((group) => group !== "all")
                      .sort((a, b) => a.localeCompare(b, "ja"))
                      .map((group) => (
                        <MenuItem key={group} value={group}>
                          {group}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <SelectButton
                  onClick={handleSync}
                  color="primary"
                  size="large"
                  variant="contained"
                >
                  同期する
                </SelectButton>
                <SelectButton
                  onClick={handleCloseAlert}
                  color="primary"
                  size="large"
                >
                  キャンセル
                </SelectButton>
              </DialogActions>
            </Dialog>
            <AlertContent />
            <AlertErrorContent />
            <FormControl>
              <Grid item xs={12} style={employeeSearchText}>
                <SearchIcon
                  sx={{
                    fontSize: "20px",
                    marginRight: "8px",
                  }}
                />
                <CustomFormControlFont>従業員検索</CustomFormControlFont>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "15px" }}>
                <Controller
                  control={control}
                  name={"freeword"}
                  render={({ field }) => (
                    <CustomTextField
                      id="search_word_id"
                      label={formLabel("従業員名を入力")}
                      variant="outlined"
                      size="small"
                      {...field}
                      error={!!errors.freeword}
                      helperText={errors.freeword?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  marginTop: "5px",
                  maxWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Controller
                  control={control}
                  name={"include_unavailable"}
                  render={({ field }) => (
                    <FormControlLabel
                      label={checkboxLabel("利用停止中のユーザーを含める")}
                      key="include_unavailable"
                      sx={{ marginLeft: "-8px" }}
                      control={
                        <CustomCheckbox
                          {...field}
                          size="small"
                          checked={checkedBox.includes("include_unavailable")}
                          onChange={(e) => {
                            handleCheckChanged(e, "include_unavailable")
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  marginTop: "-5px",
                  maxWidth: "100%",
                }}
              >
                <Controller
                  control={control}
                  name={"admin_only"}
                  render={({ field }) => (
                    <FormControlLabel
                      label={checkboxLabel("管理権限のある人だけ表示")}
                      key="admin_only"
                      sx={{ marginLeft: "-8px" }}
                      control={
                        <CustomCheckbox
                          {...field}
                          size="small"
                          checked={checkedBox.includes("admin_only")}
                          onChange={(e) => {
                            handleCheckChanged(e, "admin_only")
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  marginTop: "-5px",
                  maxWidth: "100%",
                }}
              >
                <Controller
                  control={control}
                  name={"unavailable_only"}
                  render={({ field }) => (
                    <FormControlLabel
                      label={checkboxLabel("利用停止中のユーザーのみ表示")}
                      key="unavailable_only"
                      sx={{ marginLeft: "-8px" }}
                      control={
                        <CustomCheckbox
                          {...field}
                          size="small"
                          checked={checkedBox.includes("unavailable_only")}
                          onChange={(e) => {
                            handleCheckChanged(e, "unavailable_only")
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </FormControl>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-around"
              spacing={1}
              sx={{ marginTop: "0px" }}
            >
              <Grid
                item
                xs={3.75}
                style={{ ...rightSelectTitle, paddingTop: "4px" }}
              >
                拠点
              </Grid>
              <Grid item xs={8.25}>
                <Controller
                  control={control}
                  name="branch_id"
                  render={({ field }) => (
                    <CustomSelect
                      variant="outlined"
                      defaultValue={selectedBranchId}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setValue("branch_id", e.target.value as number)
                        setSelectedBranchId(e.target.value as number)
                        setValue("floor_id", 0)
                        setSelectedFloorId(0)
                      }}
                      fullWidth
                      error={!!errors.branch_id}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      disabled={!isAdmin}
                      IconComponent={isTeamLeader ? () => null : GreenArrowIcon}
                    >
                      <MenuItem key={0} value={0} disabled>
                        拠点
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.branch_name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
                <FormHelperText error={!!errors.branch_id?.message}>
                  {errors.branch_id?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-around"
              spacing={1}
              sx={{ marginTop: "0px" }}
            >
              <Grid
                item
                xs={3.75}
                style={{ ...rightSelectTitle, paddingBottom: "10px" }}
              >
                所属
              </Grid>
              <Grid item xs={8.25}>
                <Controller
                  control={control}
                  name="team_id"
                  render={({ field }) => (
                    <CustomSelect
                      defaultValue={selectedTeamId}
                      error={
                        !!errors.team_id || (isTeamLeader && !!customError)
                      }
                      {...field}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={GreenArrowIcon}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setValue("team_id", e.target.value as number)
                        setSelectedTeamId(e.target.value as number)
                        if (isTeamLeader && e.target.value === -1) {
                          setCustomError("所属を選択してください")
                        } else {
                          setCustomError("")
                        }
                      }}
                    >
                      <MenuItem key={-1} value={-1}>
                        未選択
                      </MenuItem>
                      {!isTeamLeader && (
                        <MenuItem key={0} value={0}>
                          全て
                        </MenuItem>
                      )}
                      {companyRelations.teams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                          {team.team_name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
                <FormHelperText
                  error={!!errors.team_id || (isTeamLeader && !!customError)}
                >
                  {errors.team_id?.message || customError}
                </FormHelperText>
                <FormHelperText error={!!errorMessage}>
                  {errorMessage}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-around"
              spacing={1}
              sx={{ marginTop: "0px" }}
            >
              <Grid item xs={3.75} style={rightSelectTitle}>
                フロア
              </Grid>
              <Grid item xs={8.25}>
                <Controller
                  control={control}
                  name="floor_id"
                  render={({ field }) => (
                    <CustomSelect
                      defaultValue={selectedFloorId}
                      {...field}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      disabled={!isAdmin}
                      IconComponent={isTeamLeader ? () => null : GreenArrowIcon}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        setValue("floor_id", e.target.value as number)
                        setSelectedFloorId(e.target.value as number)
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        全て
                      </MenuItem>
                      {selectedBranchId !== 0 &&
                        selectedBranch?.floors.map((floor) => (
                          <MenuItem key={floor.id} value={floor.id}>
                            {floor.floor_name}
                          </MenuItem>
                        ))}
                    </CustomSelect>
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: "20px", textAlign: "right" }}
            >
              <CustomButton
                variant="outlined"
                disabled={
                  isTeamLeader
                    ? selectedTeamId === -1 || selectedTeamId == null
                    : false
                }
                onClick={async () => {
                  if (
                    !isTeamLeader ||
                    (isTeamLeader &&
                      selectedTeamId != null &&
                      selectedTeamId !== -1)
                  ) {
                    setSearchButtonClicked(true)
                    setPageNumber(1)
                    setPageStatus(0)
                    await new Promise((resolve) => setTimeout(resolve, 0))
                    handleSubmit(onSearchEmployeeListSubmit)()
                  }
                }}
              >
                検索
              </CustomButton>
              <FormHelperText error={!!errors.require?.message}>
                {errors.require?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        </CustomRight>
      </StyledBox>
    </>
  )
}
