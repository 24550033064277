const ja = {
  FindMembers: "検索対象者",
  Member: "従業員",
  BusinessUnit: "所属",
  Manager: "所属長",
  Location: "拠点",
  Floor: "フロア",
  Favorite: "お気に入り",
  Team: "グループ",
  Cancel: "キャンセル",
  Enter: "確定",
  employeeMembers: "従業員検索",
  searchName: "従業員名を入力",
  Search: "検索",
  FindLocation: "場所を確認する",
  search: "検索する",
}
export default ja
