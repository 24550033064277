import React, { memo } from "react"

import { Button, styled } from "@mui/material"

export const CustomButton = styled(Button)({
  height: "50px",
  width: "200px",
  fontWeight: "bold",
  borderRadius: "2px",
  textTransform: "none",
})

interface Props {
  label: string
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isValid?: boolean
  labelStyle?: React.CSSProperties
}

export const WideReflectButton: React.FC<Props> = memo(
  ({ label, handleClick, labelStyle }: Props) => {
    return (
      <CustomButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        <span style={labelStyle}>{label}</span>
      </CustomButton>
    )
  }
)
