import React, { useState, useContext, useEffect } from "react"
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormClearErrors,
  UseFormTrigger,
} from "react-hook-form"
import { useNavigate, useLocation } from "react-router-dom"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Box,
  Button,
  Typography,
  IconButton,
  styled,
  Modal,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  EmployeeGroupRequestType,
  EmployeeGroupsType,
} from "../../../../../models/employee/useEmployeeGroup/type"
import { SearchedEmployeeResult } from "../../../../../models/employee/useSchedule/days/type"
import { EmployeeScheduleRequestType } from "../../../../../models/employee/useSchedule/type"
import { EmployeesType } from "../../../../../models/employee/useSchedule/type"
import { RoleContext } from "../../../../../providers/RoleProvider"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleHeaderBar/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleHeaderBar/ja"
import {
  DAY_CALENDAR_PATHNAME,
  WEEK_CALENDAR_PATHNAME,
  MONTH_CALENDAR_PATHNAME,
} from "../../../../../utils/const"
import { generateButtonStyle } from "../../../../../utils/generateButtonStyle"
import { oneWeek } from "../../../../../utils/oneWeek"
import { AlertSuccess } from "../../../../public/AlertSuccess"
import { EmployeeGroupFormModal } from "../../common/EmployeeGroupFormModal"
import { EmployeeGroupModal } from "../../common/EmployeeGroupModal"

const CustomTypography = styled(Typography)({
  letterSpacing: "0.6px",
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "17px",
  margin: "0 auto",
})

const CustomBoldTypography = styled(Typography)({
  letterSpacing: "0px",
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
})

const defaultButtonStyle = {
  borderRadius: theme.spacing(4),
}

const EmployeeGroupFormModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(70),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
}))

interface Props {
  beginningOfWeek: Date
  endOfWeek: Date
  setBeginningOfWeek: React.Dispatch<React.SetStateAction<Date>>
  setEndOfWeek: React.Dispatch<React.SetStateAction<Date>>
  formatDate: (date: Date) => string
  displayOnlyReserved: () => void
  displayAll: () => void
  displayOnlyNonReserved: () => void
  selectedEmployees: SearchedEmployeeResult
  handleWeekScheduleFetch: (data: EmployeeScheduleRequestType) => Promise<void>
  employees: EmployeesType
  control: Control<EmployeeGroupRequestType>
  errors: FieldErrors<EmployeeGroupRequestType>
  trigger: UseFormTrigger<EmployeeGroupRequestType>
  clearErrors: UseFormClearErrors<EmployeeGroupRequestType>
  isValid: boolean
  handleSubmit: UseFormHandleSubmit<EmployeeGroupRequestType>
  onCreateSubmit: SubmitHandler<EmployeeGroupRequestType>
  getValues: UseFormGetValues<EmployeeGroupRequestType>
  setValue: UseFormSetValue<EmployeeGroupRequestType>
  errorMessages: string[]
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  showSuccessMessage: boolean
  setShowSuccessMessage: React.Dispatch<React.SetStateAction<boolean>>
  successMessage: string
  displayReserved: boolean
  displayNonReserved: boolean
  isCreateEmployeeGroupModalOpen: boolean
  setIsCreateEmployeeGroupModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >
  employeeGroups: EmployeeGroupsType
  fetchEmployeeGroups: () => Promise<void>
  deleteEmployeeGroup: (employeeGroupId: number) => Promise<void>
  onUpdateSubmit: SubmitHandler<EmployeeGroupRequestType>
  editFormOpen: boolean
  setEditFormOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const EmployeeScheduleWeekHeader: React.FC<Props> = ({
  beginningOfWeek,
  endOfWeek,
  setBeginningOfWeek,
  setEndOfWeek,
  formatDate,
  displayOnlyReserved,
  displayAll,
  displayOnlyNonReserved,
  selectedEmployees,
  handleWeekScheduleFetch,
  employees,
  control,
  errors,
  trigger,
  clearErrors,
  isValid,
  handleSubmit,
  onCreateSubmit,
  getValues,
  setValue,
  errorMessages,
  setErrorMessages,
  showSuccessMessage,
  setShowSuccessMessage,
  successMessage,
  displayReserved,
  displayNonReserved,
  isCreateEmployeeGroupModalOpen,
  setIsCreateEmployeeGroupModalOpen,
  employeeGroups,
  fetchEmployeeGroups,
  deleteEmployeeGroup,
  onUpdateSubmit,
  editFormOpen,
  setEditFormOpen,
}: Props) => {
  const navigate = useNavigate()
  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)
  const week = oneWeek()
  const [isEmployeeGroupModalOpen, setIsEmployeeGroupModalOpen] =
    useState<boolean>(false)
  const [employeeIds, setEmployeeIds] = useState<number[]>([])
  const location = useLocation()
  const { handleRoleChange } = useContext(RoleContext)

  const startMonth = beginningOfWeek.getMonth() + 1
  const startDay = beginningOfWeek.getDate()

  const endMonth = endOfWeek.getMonth() + 1
  const endDay = endOfWeek.getDate()

  // 前週の開始日と終了日を設定、API通信投げる処理
  const previousWeekSchedule = () => {
    const employeeIds = selectedEmployees.map((employee) => {
      return employee.id
    })
    handleWeekScheduleFetch({
      start_date: formatDate(week.previousStartOfWeek(beginningOfWeek)),
      end_date: formatDate(week.previousEndOfWeek(beginningOfWeek)),
      employee_ids: employeeIds,
    })
    setBeginningOfWeek(week.previousStartOfWeek(beginningOfWeek))
    setEndOfWeek(week.previousEndOfWeek(beginningOfWeek))
  }

  // 翌週の開始日と終了日を設定、API通信投げる処理
  const nextWeekSchedule = () => {
    const employeeIds = selectedEmployees.map((employee) => {
      return employee.id
    })
    handleWeekScheduleFetch({
      start_date: formatDate(week.nextStartOfWeek(endOfWeek)),
      end_date: formatDate(week.nextEndOfWeek(endOfWeek)),
      employee_ids: employeeIds,
    })
    setBeginningOfWeek(week.nextStartOfWeek(endOfWeek))
    setEndOfWeek(week.nextEndOfWeek(endOfWeek))
  }

  // お気に入りグループ作成モーダルを開く
  const handleCreateEmployeeGroupModalOpen = () => {
    clearErrors() // closeした時にエラーメッセージが表示されているので打ち消す
    setIsCreateEmployeeGroupModalOpen(true)
  }

  // お気に入りグループ作成モーダルを閉じる
  const handleCreateEmployeeGroupModalClose = () => {
    if (confirm("編集中の内容は失われますがよろしいですか？")) {
      setValue("group_name", "")
      setValue("group_name_kana", "")
      setValue("employee_ids", [])
      trigger() // 先にバリデーションをトリガーしてボタンを押下不可にする
      setErrorMessages([])
      setIsCreateEmployeeGroupModalOpen(false)
    }
  }

  // お気に入りグループモーダルを開く
  const handleEmployeeGroupModalOpen = () => {
    setEditFormOpen(false)
    setIsEmployeeGroupModalOpen(true)
  }

  // お気に入りグループモーダルを閉じる
  const handleEmployeeGroupModalClose = () => {
    if (editFormOpen) {
      if (!confirm("編集中の内容は失われますがよろしいですか？")) {
        return // 編集中にモーダル外部をクリックした場合は確認のダイアログを出した上でモーダル自体を閉じる
      }
    }
    setIsEmployeeGroupModalOpen(false)
  }

  // 予定が表示されている従業員のうち、自分を対象から外す
  const exceptMeEmployees = employees.slice(1)
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // ページが遷移、権限変更があれば権限取得
  useEffect(() => {
    handleRoleChange()
  }, [location.pathname])

  return (
    <>
      <Box
        sx={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton onClick={previousWeekSchedule}>
            <ChevronLeftIcon color="primary" />
          </IconButton>
          <Box>
            <CustomTypography>{`${startMonth}${translations.month} ${startDay}${translations.day} - ${endMonth}${translations.month} ${endDay}${translations.day}`}</CustomTypography>
          </Box>
          <IconButton
            disabled={schedulableMaxDate <= endOfWeek}
            onClick={nextWeekSchedule}
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </Box>
        <Box display="flex" marginLeft="auto">
          <Box display="flex" alignItems="center">
            <Box>
              <CustomBoldTypography color="primary">
                {translations.Schedule} :
              </CustomBoldTypography>
            </Box>
            <Button
              sx={{
                ...generateButtonStyle(
                  !displayReserved && !displayNonReserved,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayAll}
            >
              {translations.All}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(displayReserved, defaultButtonStyle),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayOnlyReserved}
            >
              {translations.Desks}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(displayNonReserved, defaultButtonStyle),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayOnlyNonReserved}
            >
              {translations.Events}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" marginLeft="0.2rem">
            <Box>
              <CustomBoldTypography color="primary">
                {translations.Calendar} :
              </CustomBoldTypography>
            </Box>
            <Button
              sx={() => ({
                ...generateButtonStyle(
                  window.location.pathname === MONTH_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              })}
              onClick={() =>
                navigate(MONTH_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Month}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={() => ({
                ...generateButtonStyle(
                  window.location.pathname === WEEK_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              })}
              onClick={() =>
                navigate(WEEK_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Week}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={() => ({
                ...generateButtonStyle(
                  window.location.pathname === DAY_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              })}
              onClick={() =>
                navigate(DAY_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                    navigateFrom: window.location.pathname,
                  },
                })
              }
            >
              {translations.Day}
            </Button>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={() => {
                  handleCreateEmployeeGroupModalOpen()
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/favorite.svg`}
                  style={{
                    height: "15px",
                  }}
                />
              </IconButton>

              <Modal
                open={isCreateEmployeeGroupModalOpen}
                onClose={handleCreateEmployeeGroupModalClose}
                disableAutoFocus
              >
                <EmployeeGroupFormModalOverlay>
                  <EmployeeGroupFormModal
                    formType="create"
                    employeeIds={employeeIds}
                    handleClose={handleCreateEmployeeGroupModalClose}
                    setEmployeeIds={setEmployeeIds}
                    employees={exceptMeEmployees}
                    control={control}
                    errors={errors}
                    isValid={isValid}
                    handleSubmit={handleSubmit}
                    onSubmit={onCreateSubmit}
                    getValues={getValues}
                    setValue={setValue}
                    errorMessages={errorMessages}
                  />
                </EmployeeGroupFormModalOverlay>
              </Modal>
              {employeeGroups.length > 0 && (
                <Box display="flex">
                  <Typography variant="h5" color="#dcdcdc">
                    |
                  </Typography>
                  <IconButton
                    onClick={() => {
                      handleEmployeeGroupModalOpen()
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/list.svg`}
                    />
                  </IconButton>
                  <Modal
                    open={isEmployeeGroupModalOpen}
                    onClose={handleEmployeeGroupModalClose}
                  >
                    <Box>
                      <EmployeeGroupModal
                        employeeGroups={employeeGroups}
                        fetchEmployeeGroups={fetchEmployeeGroups}
                        deleteEmployeeGroup={deleteEmployeeGroup}
                        successMessage={successMessage}
                        setShowSuccessMessage={setShowSuccessMessage}
                        showSuccessMessage={showSuccessMessage}
                        getValues={getValues}
                        setValue={setValue}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        clearErrors={clearErrors}
                        isValid={isValid}
                        handleSubmit={handleSubmit}
                        onUpdateSubmit={onUpdateSubmit}
                        editFormOpen={editFormOpen}
                        setEditFormOpen={setEditFormOpen}
                      />
                    </Box>
                  </Modal>
                </Box>
              )}
              <AlertSuccess
                isMessageOpen={showSuccessMessage}
                closeMessage={() => setShowSuccessMessage(false)}
                alertMessage={successMessage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
