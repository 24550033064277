import React from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { Box, Typography, styled, Button } from "@mui/material"

import { pageType } from "../utils/location"

const ErrorContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100vh",
  width: "100vw",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}))

const ErrorTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(4),
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.spacing(5),
  },
}))

const ErrorSubTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
    fontSize: theme.spacing(3),
  },
}))

type StatusType = "404" | "500" | undefined

export const ErrorPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const errorPageTitle = (status: StatusType) => {
    switch (status) {
      case "404":
        return "404 Not Found"
      case "500":
        return "500 Internal Server Error"
      default:
        return "404 Not Found"
    }
  }

  const errorPageDescription = (status: StatusType) => {
    switch (status) {
      case "404":
        return (
          <>
            <Typography>
              ご指定のページが見つかりませんでした。
              <br />
              アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。
            </Typography>
          </>
        )
      case "500":
        return (
          <>
            <Typography>
              サーバで問題が発生しているため、アクセスできません。
              <br />
              しばらく経ってからもう一度お試しください。
            </Typography>
          </>
        )
      default:
        return (
          <>
            <Typography>
              ご指定のページが見つかりませんでした。
              <br />
              アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。
            </Typography>
          </>
        )
    }
  }

  return (
    <ErrorContent>
      <ErrorTitle gutterBottom>
        {errorPageTitle(location.state as StatusType)}
      </ErrorTitle>
      <ErrorSubTitle>
        {errorPageDescription(location.state as StatusType)}
      </ErrorSubTitle>
      <Button
        sx={{ m: 3 }}
        type="submit"
        variant="contained"
        size="large"
        onClick={() => navigate(`/${pageType}/home`)}
      >
        トップに戻る
      </Button>
    </ErrorContent>
  )
}
