import {
  EmployeeStatusListRequestType,
  EmployeeStatusListResponseType,
} from "../../models/employee/useLayout/status/type"
import {
  EmployeeLayoutRequestType,
  EmployeeLayoutResponseType,
} from "../../models/employee/useLayout/type"
import {
  LayoutRequestType,
  LayoutResponse,
} from "../../models/employee/useSchedule/useLayout/type"
import { api, ApiResponse } from "../config/axiosConfig"

// 拠点とフロアと日付を指定して指定時刻の座席・会議室の利用状況を参照する
export const fetchEmployeeLayoutRequest = async (
  params: EmployeeLayoutRequestType
): Promise<ApiResponse<EmployeeLayoutResponseType>> => {
  try {
    const data = await api.get("/employee/layouts", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchEmployeeCalendarLayoutRequest = async (
  params: LayoutRequestType
): Promise<ApiResponse<LayoutResponse>> => {
  try {
    const data = await api.get("/employee/layouts/calendar", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchScheduleStatusListRequest = async (
  params: EmployeeStatusListRequestType
): Promise<ApiResponse<EmployeeStatusListResponseType>> => {
  try {
    const data = await api.get("/employee/layouts/status", { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
