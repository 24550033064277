import { passwordChangeTimesResponseType } from "../../models/employee/useAccounts/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchPasswordChangeTimesRequest = async (): Promise<
  ApiResponse<passwordChangeTimesResponseType>
> => {
  try {
    const data = await api.get("/employee/accounts")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updatePasswordChangeTimesRequest = async (
  params: passwordChangeTimesResponseType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(`/employee/accounts/1`, params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const needChangePasswordRequest = async (
  account_id: number
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/employee/accounts/need_change_password", {
      account_id: account_id,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
