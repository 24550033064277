import React from "react"

import { Typography, Box, styled } from "@mui/material"

import { hours } from "../../../../../utils/hours"

const CustomMainComponent = styled(Box)(() => ({
  position: "sticky",
  height: "1.5rem",
  width: `${130 * 24 + 15}px`,
  top: -4,
  backgroundColor: "white",
  zIndex: 10001,
}))

const CustomTypography = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: "#C1C1C1",
}))

export const EmployeeScheduleTimeline: React.FC = () => {
  return (
    <>
      <CustomMainComponent>
        {hours.map((hour, index) => (
          <Box
            key={index}
            sx={{
              marginRight: "10px",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "1rem",
              lineHeight: "1rem",
              width: "30px",
              position: "absolute",
              left: `${index * 130}px`,
              backgroundColor: "white",
            }}
          >
            <CustomTypography>{hour}</CustomTypography>
          </Box>
        ))}
      </CustomMainComponent>
    </>
  )
}
