import React from "react"

import { Box, Typography } from "@mui/material"

import { MeetingRoomDetailScheduleType } from "../../../../models/signage/useMeetingRoom/type"
import { EmployeeIconSmallWrapper } from "../../../public/EmployeeIconWrapper"

interface Props {
  meetingRoomDetailSchedule: MeetingRoomDetailScheduleType
  creatorIconUrl: string
}

export const MeetingRoomScheduleCreator: React.FC<Props> = ({
  meetingRoomDetailSchedule,
  creatorIconUrl,
}: Props) => {
  return (
    <>
      <Box my={3} display="flex" alignItems="center">
        {meetingRoomDetailSchedule.is_secret ? (
          <Typography>非公開</Typography>
        ) : (
          <>
            <EmployeeIconSmallWrapper>
              {creatorIconUrl === "" ? (
                <Typography variant="body2" color="inherit">
                  {meetingRoomDetailSchedule.schedule_creator.last_name[0]}
                  {meetingRoomDetailSchedule.schedule_creator.first_name[0]}
                </Typography>
              ) : (
                <img
                  src={creatorIconUrl}
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </EmployeeIconSmallWrapper>
            <Typography ml={1}>
              {meetingRoomDetailSchedule.schedule_creator.team_name}
            </Typography>
            <Typography ml={1}>
              {meetingRoomDetailSchedule.schedule_creator.last_name}
            </Typography>
            <Typography ml={1}>
              {meetingRoomDetailSchedule.schedule_creator.first_name}
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}
