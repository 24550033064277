import React from "react"

import { CalendarEditComponent } from "../../../../components/employee/calendar/edit"
import { ContentEmployeePaper } from "../../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../../components/public/ContentTemplate"
import { SchedulableMaxDateProvider } from "../../../../providers/SchedulableMaxDateProvider"

export const CalendarEditPage = () => {
  return (
    <ContentTemplate>
      <ContentEmployeePaper>
        <SchedulableMaxDateProvider>
          <CalendarEditComponent />
        </SchedulableMaxDateProvider>
      </ContentEmployeePaper>
    </ContentTemplate>
  )
}
