import React, { useState, useEffect } from "react"

import SearchIcon from "@mui/icons-material/Search"
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Grid,
  IconButton,
  Button,
  Modal,
  styled,
} from "@mui/material"

import { useSyncLanguageAcrossTabs } from "../../../../../src/utils/useSyncLanguageAcrossTabs"
import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { SearchUserable } from "../../../../models/employee/useSchedule/useScheduleUserableSearch/type"
import {
  TeamType,
  EmployeeGroupType,
  MobileStatusListRequestType,
} from "../../../../models/mobile/useTeamSearch/type"
import enTranslations from "../../../../translations/mobileTeamSearch/en"
import jaTranslations from "../../../../translations/mobileTeamSearch/ja"
import { MAX_NUMBER_OF_SEARCH } from "../../../../utils/const"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { MobileMemberSearch } from "../MobileMemberSearch"

const CustomButton = styled(Button)(() => ({
  color: "#707070",
  fontWeight: "bold",
  "&:hover": {
    color: "#22BA9D",
  },
}))

interface Props {
  teams: TeamType[]
  selectedTeams: TeamType[]
  setSelectedTeams: React.Dispatch<React.SetStateAction<TeamType[]>>
  employeeGroups: EmployeeGroupType[]
  selectedEmployeeGroups: EmployeeGroupType[]
  setSelectedEmployeeGroups: React.Dispatch<
    React.SetStateAction<EmployeeGroupType[]>
  >
  selectedEmployees: SearchUserable[]
  setSelectedEmployees: React.Dispatch<React.SetStateAction<SearchUserable[]>>
  selectedTeamIds: number[]
  setSelectedTeamIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedEmployeeGroupIds: number[]
  setSelectedEmployeeGroupIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedEmployeeIds: number[]
  setSelectedEmployeeIds: React.Dispatch<React.SetStateAction<number[]>>
  startDate: Date
  endDate: Date
  formatDate: (date: Date) => string
  fetchMobileScheduleStatusList: (
    params: MobileStatusListRequestType
  ) => Promise<void>
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
}

export const MobileTeamSearchSelectForm: React.FC<Props> = ({
  teams,
  selectedTeams,
  setSelectedTeams,
  employeeGroups,
  selectedEmployeeGroups,
  setSelectedEmployeeGroups,
  selectedEmployees,
  setSelectedEmployees,
  selectedTeamIds,
  setSelectedTeamIds,
  selectedEmployeeGroupIds,
  setSelectedEmployeeGroupIds,
  selectedEmployeeIds,
  setSelectedEmployeeIds,
  startDate,
  endDate,
  formatDate,
  fetchMobileScheduleStatusList,
  setErrorMessages,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useSyncLanguageAcrossTabs()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [teamCount, setTeamCount] = useState<number>(0)
  const [employeeGroupCount, setEmployeeGroupCount] = useState<number>(0)
  const [employeeCount, setEmployeeCount] = useState<number>(0)

  // チェックされているかどうかの確認
  const isTeamSelected = (teamId: number) => {
    return selectedTeamIds.includes(teamId)
  }
  const isEmployeeGroupSelected = (employeeGroupId: number) => {
    return selectedEmployeeGroupIds.includes(employeeGroupId)
  }
  const isEmployeeSelected = (employeeId: number) => {
    return selectedEmployeeIds.includes(employeeId)
  }

  // チェックされた項目を上位に並べ替える
  const sortedSelectedTeams = teams.sort((team) => {
    if (selectedTeamIds.includes(team.id)) return -1
    else return 1
  })
  const sortedSelectedEmployeeGroups = employeeGroups.sort((group) => {
    if (selectedEmployeeGroupIds.includes(group.id)) return -1
    else return 1
  })
  const sortedSelectedEmployees = selectedEmployees.sort((employee) => {
    if (selectedEmployeeIds.includes(employee.id)) return -1
    else return 1
  })

  // 所属人数を計算
  const teamMemberCount = (teams: TeamType[]) => {
    const countArray = teams.map((team) => {
      return team.member_count
    })
    return countArray.reduce((sum, element) => sum + element, 0)
  }
  const groupMemberCount = (groups: EmployeeGroupType[]) => {
    const countArray = groups.map((group) => {
      return group.member_count
    })
    return countArray.reduce((sum, element) => sum + element, 0)
  }

  const handleTeamCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetTeam = teams.find((team) => {
      return team.id === Number(e.target.value)
    })
    if (!targetTeam) return
    if (e.target.checked) {
      const newSelectedTeams = [...selectedTeams, targetTeam]
      if (
        teamMemberCount(newSelectedTeams) + employeeGroupCount + employeeCount >
        MAX_NUMBER_OF_SEARCH
      ) {
        setErrorMessages([translations.fiftyMembers])
        return
      }
      setSelectedTeams(newSelectedTeams)
      setTeamCount(teamMemberCount(newSelectedTeams))
      setErrorMessages([])
    } else {
      const excludeSelectedTeam = selectedTeams.filter((team) => {
        return team.id !== targetTeam.id
      })
      setSelectedTeams(excludeSelectedTeam)
      setTeamCount(teamCount - teamMemberCount([targetTeam]))
      if (
        teamCount -
          teamMemberCount([targetTeam]) +
          employeeGroupCount +
          employeeCount <=
        50
      ) {
        setErrorMessages([])
      }
    }
  }

  const handleEmployeeGroupCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetGroup = employeeGroups.find((group) => {
      return group.id === Number(e.target.value)
    })
    if (!targetGroup) return
    if (e.target.checked) {
      const newSelectedGroups = [...selectedEmployeeGroups, targetGroup]
      if (
        teamCount + groupMemberCount(newSelectedGroups) + employeeCount >
        MAX_NUMBER_OF_SEARCH
      ) {
        setErrorMessages([translations.fiftyMembers])
        return
      }
      setSelectedEmployeeGroups(newSelectedGroups)
      setEmployeeGroupCount(groupMemberCount(newSelectedGroups))
      setErrorMessages([])
    } else {
      const excludeSelectedGroup = selectedEmployeeGroups.filter((group) => {
        return group.id !== targetGroup.id
      })
      setSelectedEmployeeGroups(excludeSelectedGroup)
      setEmployeeGroupCount(
        employeeGroupCount - groupMemberCount([targetGroup])
      )
      if (
        teamCount +
          employeeGroupCount -
          groupMemberCount([targetGroup]) +
          employeeCount <=
        50
      ) {
        setErrorMessages([])
      }
    }
  }

  const handleEmployeeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetEmployee = selectedEmployees.find((employee) => {
      return employee.id === Number(e.target.value)
    })
    if (!targetEmployee) return
    if (e.target.checked) {
      const newSelectedEmployees = [...selectedEmployees, targetEmployee]
      if (
        teamCount + employeeGroupCount + newSelectedEmployees.length >
        MAX_NUMBER_OF_SEARCH
      ) {
        setErrorMessages([translations.fiftyMembers])
        return
      }
      setSelectedEmployees(newSelectedEmployees)
      setEmployeeCount(newSelectedEmployees.length)
      setErrorMessages([])
    } else {
      const excludeSelectedEmployees = selectedEmployees.filter((employee) => {
        return employee.id !== targetEmployee.id
      })
      setSelectedEmployees(excludeSelectedEmployees)
      setEmployeeCount(employeeCount - 1)
      if (teamCount + employeeGroupCount + employeeCount - 1 <= 50) {
        setErrorMessages([])
      }
    }
  }

  // 選択を全解除
  const handleUnCheck = (category: string) => {
    switch (category) {
      case "teams":
        setSelectedTeams([])
        setSelectedTeamIds([])
        setTeamCount(0)
        break
      case "employeeGroups":
        setSelectedEmployeeGroups([])
        setSelectedEmployeeGroupIds([])
        setEmployeeGroupCount(0)
        break
      case "employees":
        setSelectedEmployees([])
        setSelectedEmployeeIds([])
        setEmployeeCount(0)
    }
  }

  // 選択された対象からapi通信に必要なidを取得
  useEffect(() => {
    setSelectedTeamIds(
      selectedTeams.map((team) => {
        return team.id
      })
    )
    setSelectedEmployeeGroupIds(
      selectedEmployeeGroups.map((employeeGroup) => {
        return employeeGroup.id
      })
    )
    setSelectedEmployeeIds(
      selectedEmployees.map((employee) => {
        return employee.id
      })
    )
  }, [selectedEmployeeGroups, selectedTeams, selectedEmployees])

  // 検対象者のステータススケジュールを取得する
  const handleSearchStatus = async () => {
    const params = {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      team_ids: selectedTeamIds,
      employee_group_ids: selectedEmployeeGroupIds,
      employee_ids: selectedEmployeeIds,
    }
    fetchMobileScheduleStatusList(params)
  }

  // 選択が変化する度に対象者のステータススケジュールを取得
  useEffect(() => {
    handleSearchStatus()
  }, [selectedTeamIds, selectedEmployeeGroupIds, selectedEmployeeIds])

  const modalOpen = () => {
    setIsModalOpen(true)
  }

  const modalClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Box mb={2}>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<GreenArrowIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              justifyContent: "left",
              height: "30px",
            }}
          >
            <FormControl fullWidth>
              {selectedTeams.length === 0 && (
                <InputLabel
                  id="team_label"
                  margin="dense"
                  sx={{ fontSize: "12px", top: -15, left: -20, height: "30px" }}
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  {translations.WhereMember}
                </InputLabel>
              )}
              <Select
                labelId="team_label"
                id="team_ids"
                name="team_ids"
                multiple
                label={translations.WhereMember}
                value={selectedTeams}
                IconComponent={() => null}
                sx={{
                  width: "280px",
                  boxShadow: "none",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                size="small"
                input={<OutlinedInput disabled={true} />}
                renderValue={(selected) =>
                  selected.map((value) => value.team_name).join(",")
                }
              />
            </FormControl>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                maxHeight: "120px",
                overflowY: "scroll",
              }}
            >
              <Button
                size="small"
                onClick={() => handleUnCheck("teams")}
                sx={{ mr: 1.5 }}
              >
                <Typography variant="body2">
                  {translations.UnselectBusinessUnit}
                </Typography>
              </Button>
              {sortedSelectedTeams.map((team) => {
                return (
                  <Grid container key={team.id} alignItems="center">
                    <Checkbox
                      size="small"
                      checked={isTeamSelected(team.id)}
                      onChange={handleTeamCheck}
                      value={team.id}
                    />
                    <Typography variant="body2">{team.team_name}</Typography>
                  </Grid>
                )
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<GreenArrowIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              justifyContent: "left",
              height: "30px",
            }}
          >
            <FormControl fullWidth>
              {selectedEmployeeGroups.length === 0 && (
                <InputLabel
                  id="employee_group_label"
                  sx={{
                    fontSize: "12px",
                    top: -15,
                    left: -20,
                    height: "30px",
                  }}
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  {translations.LookingFavoriteMembers}
                </InputLabel>
              )}
              <Select
                labelId="employee_group_label"
                id="employee_group_ids"
                name="employee_group_ids"
                variant="standard"
                multiple
                value={selectedEmployeeGroups}
                IconComponent={() => null}
                sx={{
                  width: "280px",
                  boxShadow: "none",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                size="small"
                input={<OutlinedInput disabled={true} />}
                renderValue={(selected) =>
                  selected.map((value) => value.group_name).join(",")
                }
              />
            </FormControl>
          </AccordionSummary>
          <AccordionDetails>
            {employeeGroups.length === 0 && (
              <Box ml={1.5}>
                <Typography variant="body2">
                  {translations.NoFavoriteTeams}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                maxHeight: "120px",
                overflowY: "scroll",
              }}
            >
              {employeeGroups.length > 0 && (
                <Button
                  size="small"
                  onClick={() => handleUnCheck("employeeGroups")}
                  sx={{ mr: 1.5 }}
                >
                  <Typography variant="body2">
                    {translations.UnselecTeams}
                  </Typography>
                </Button>
              )}
              <Box>
                {sortedSelectedEmployeeGroups.map((employeeGroup) => {
                  return (
                    <Grid container key={employeeGroup.id} alignItems="center">
                      <Checkbox
                        size="small"
                        checked={isEmployeeGroupSelected(employeeGroup.id)}
                        onChange={handleEmployeeGroupCheck}
                        value={employeeGroup.id}
                      />
                      <Typography variant="body2">
                        {employeeGroup.group_name}
                      </Typography>
                    </Grid>
                  )
                })}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion disableGutters>
          <AccordionSummary
            expandIcon={<GreenArrowIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{
              justifyContent: "left",
              height: "30px",
            }}
          >
            <FormControl fullWidth>
              {selectedEmployees.length === 0 && (
                <InputLabel
                  id="employee_label"
                  sx={{ fontSize: "12px", top: -15, left: -20, height: "30px" }}
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  {translations.AreYouLooking}
                </InputLabel>
              )}
              <Select
                labelId="employee_label"
                id="employee_ids"
                name="employee_ids"
                variant="standard"
                multiple
                value={selectedEmployees}
                IconComponent={() => null}
                sx={{
                  width: "280px",
                  boxShadow: "none",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                size="small"
                input={<OutlinedInput disabled={true} />}
                renderValue={(selected) =>
                  selected
                    .map((value) => value.last_name + " " + value.first_name)
                    .join(",")
                }
              />
            </FormControl>
          </AccordionSummary>
          <AccordionDetails>
            <CustomButton size="small" onClick={modalOpen} sx={{ mr: 1.5 }}>
              {translations.AddMembers}
            </CustomButton>
            {/* <Box onClick={modalOpen}>探したいメンバーの追加</Box> */}
            <Box
              sx={{
                maxHeight: "120px",
                overflowY: "scroll",
              }}
            >
              {selectedEmployees.length > 0 && (
                <Button
                  size="small"
                  onClick={() => handleUnCheck("employees")}
                  sx={{ mr: 1.5 }}
                >
                  <Typography variant="body2">
                    {translations.UnselectMembers}
                  </Typography>
                </Button>
              )}
              {sortedSelectedEmployees.map((employee) => {
                return (
                  <Grid container key={employee.id} alignItems="center">
                    <Checkbox
                      size="small"
                      checked={isEmployeeSelected(employee.id)}
                      onChange={handleEmployeeCheck}
                      value={employee.id}
                    />
                    <Typography variant="body2">
                      {employee.last_name} {employee.first_name}
                    </Typography>
                  </Grid>
                )
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Modal
          open={isModalOpen}
          onClose={modalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MobileMemberSearch
            modalClose={modalClose}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
        </Modal>
      </Box>
    </>
  )
}
