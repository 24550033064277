import React, { useEffect } from "react"

import { CompanyBranchList } from "../../../components/company/branches/CompanyBranchList"
import { AlertContent } from "../../../components/public/AlertContent"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useBranch } from "../../../models/company/useBranch"

export const CompanyBranchListPage = () => {
  const { branchRelation, fetchBranchRelations, loading } = useBranch()

  useEffect(() => {
    fetchBranchRelations()
  }, [])

  return (
    <>
      <ContentTemplate>
        <ContentCompanyPaper>
          {loading ? (
            <Loading type="content" loading={loading} />
          ) : (
            <CompanyBranchList branchRelation={branchRelation} />
          )}
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertContent />
    </>
  )
}
