import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { fetchSignageFloorRequest } from "../../../api/signage/floorRequest"
import { SIGNAGE_FLOOR_RELOAD_MINUTES } from "../../../utils/const"
import { downloadIcon } from "../../../utils/downLoadIcon"
import { downloadLayoutImage } from "../../../utils/downloadLayoutImage"
import {
  SeatType,
  MeetingRoomType,
  AreaType,
  SignageFloorLayoutType,
} from "./type"

export const useSignageFloor = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [layoutImg, setLayoutImg] = useState<string>("") // UI上に描画する画像
  const [seats, setSeats] = useState<SeatType[]>([])
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomType[]>([])
  const [areas, setAreas] = useState<AreaType[]>([])
  const navigate = useNavigate()

  // フロアのレイアウトデータを取得
  const fetchSignageFloorLayout = useCallback(async (floorId: number) => {
    try {
      setLoading(true)
      const response = await fetchSignageFloorRequest({
        floor_id: floorId,
      })
      if (response.status === 200 && response.data) {
        iconSetToSeats(response.data)
        iconSetToMeetingRooms(response.data)
        iconSetToAreas(response.data)
        const result = await downloadLayoutImage(
          response.data.company_id,
          response.data.branch_id,
          response.data.floor_id,
          response.data.layout_image
        )
        if (result) {
          setLayoutImg(URL.createObjectURL(result.Body as Blob))
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      setSeats([])
      setMeetingRooms([])
      setAreas([])
      if (error.response.status === 403) {
        navigate("/signage/home")
      }
    } finally {
      setLoading(false)
      window.setTimeout(() => {
        fetchSignageFloorLayout(floorId)
      }, 60000 * SIGNAGE_FLOOR_RELOAD_MINUTES) // 定期的に最新のレイアウト情報が取得されるように、都度timerをセットしておく。
    }
  }, [])

  // S3から席を使用している従業員のアイコンを取得し、レイアウトデータに含める
  const iconSetToSeats = (response: SignageFloorLayoutType) => {
    const seatObject: SeatType[] = []
    response.seats.map(async (seat) => {
      if (seat.schedule?.userable?.icon) {
        const resultImageBlob = await downloadIcon(
          response.company_id,
          seat.schedule.userable.id,
          seat.schedule.userable.icon
        )
        seat.schedule.image_blob = resultImageBlob ? resultImageBlob : ""
      }
      seatObject.push(seat)
    })

    setSeats(seatObject)
  }

  // S3から会議室を使用している従業員のアイコンを取得し、レイアウトデータに含める
  const iconSetToMeetingRooms = (response: SignageFloorLayoutType) => {
    const meetingRoomObject: MeetingRoomType[] = []
    response.meeting_rooms.map(async (oldMeetingRoom) => {
      await Promise.all(
        oldMeetingRoom.schedules.map(async (schedule) => {
          if (schedule.userable.icon) {
            const resultImageBlob = await downloadIcon(
              response.company_id,
              schedule.userable.id,
              schedule.userable.icon
            )
            schedule.image_blob = resultImageBlob ? resultImageBlob : ""
          }
        })
      )

      meetingRoomObject.push(oldMeetingRoom)
    })
    setMeetingRooms(meetingRoomObject)
  }
  // S3からエリアを使用している従業員のアイコンを取得し、レイアウトデータに含める
  const iconSetToAreas = (response: SignageFloorLayoutType) => {
    const areaObject: AreaType[] = []
    response.areas.map(async (area) => {
      const areaToSeatObject: SeatType[] = []
      await Promise.all(
        area.children.map(async (seat) => {
          if (seat?.schedule?.userable?.icon) {
            const resultImageBlob = await downloadIcon(
              response.company_id,
              seat.schedule.userable.id,
              seat.schedule.userable.icon
            )
            seat.schedule.image_blob = resultImageBlob ? resultImageBlob : ""
          }
          areaToSeatObject.push(seat)
        })
      )
      areaObject.push({
        ...area,
        children: areaToSeatObject,
      })
    })
    setAreas(areaObject)
  }

  return {
    layoutImg,
    seats,
    meetingRooms,
    areas,
    fetchSignageFloorLayout,
  }
}
