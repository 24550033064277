import React from "react"

import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeSchedule/employeeShowScheduleModal/en"
import jaTranslations from "../../../translations/employeeSchedule/employeeShowScheduleModal/ja"

const DateFormatter: React.FC<{ date: Date }> = ({ date: now }) => {
  const dateString = now.toLocaleString()
  const date = new Date(dateString.replace(/-/g, "/"))
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)
  const hour = ("0" + date.getHours()).slice(-2)
  const min = ("0" + date.getMinutes()).slice(-2)

  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <span>{`${month}${translations.month}${day}${translations.day}(${
      translations.weekdays[date.getDay()]
    }${translations.week}) ${hour}:${min}`}</span>
  )
}

export default DateFormatter
