import React, { useState, useEffect, memo } from "react"
import { useParams } from "react-router-dom"

import {
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  styled,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material"

import { useSignageMeetingRoom } from "../../../../models/signage/useMeetingRoom"
import { CancelButton } from "../../../public/CancelButton"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "1.5rem",
  marginBottom: "3rem",
}))

const CustomBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "2rem",
}))

const NotExtendBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "2rem",
}))

interface Props {
  extendModalClose: () => void
}

export const ExtendMeetingModal: React.FC<Props> = memo(
  ({ extendModalClose }: Props) => {
    const urlParams = useParams()
    const {
      fetchNextMeetingRoomSchedule,
      nextReservableMinutes,
      handleExtendMeetingRoomSchedule,
      loading,
    } = useSignageMeetingRoom()
    const [selectedMinutes, setSelectedMinutes] = useState<number>(0)

    useEffect(() => {
      fetchNextMeetingRoomSchedule(Number(urlParams.meeting_room_id))
    }, [])

    const handleMinutesSelect = (event: SelectChangeEvent<number>) => {
      setSelectedMinutes(Number(event.target.value))
    }

    const handleSubmit = () => {
      handleExtendMeetingRoomSchedule(
        selectedMinutes,
        Number(urlParams.schedule_id)
      )
      extendModalClose()
    }

    if (loading) {
      return (
        <>
          <CustomBox>
            <CircularProgress />
          </CustomBox>
        </>
      )
    } else {
      return (
        <>
          {nextReservableMinutes.length === 0 ? (
            <NotExtendBox>
              <Typography variant="h6" fontWeight="bold" color="#707070" mb={4}>
                この会議は延長できません
              </Typography>
              <CancelButton label="戻る" handleClick={extendModalClose} />
            </NotExtendBox>
          ) : (
            <>
              <Typography variant="h6" fontWeight="bold">
                延長時間を選択してください
              </Typography>
              <CustomGridItem item container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    延長時間
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      variant="outlined"
                      value={selectedMinutes}
                      onChange={(event) => {
                        handleMinutesSelect(event)
                      }}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      IconComponent={GreenArrowIcon}
                    >
                      <MenuItem disabled={true} key={0} value={0}>
                        選択してください
                      </MenuItem>
                      {nextReservableMinutes.map((minute, index) => {
                        return (
                          <MenuItem key={index} value={minute}>
                            {minute}分
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </CustomGridItem>
              <CustomBox>
                <CancelButton
                  label="キャンセル"
                  handleClick={extendModalClose}
                />
                <ReflectButton
                  label="延長する"
                  handleClick={handleSubmit}
                  disabled={selectedMinutes === 0}
                />
              </CustomBox>
            </>
          )}
        </>
      )
    }
  }
)
