import { EmployeeRoles } from "../../models/public/useEmployeeRoles/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchEmployeeRoleRequest = async (): Promise<
  ApiResponse<EmployeeRoles | null>
> => {
  try {
    const data = await api.get("/public/employee_roles")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
