import { yupResolver } from "@hookform/resolvers/yup"
import { Storage, Auth, Amplify } from "aws-amplify"
import * as Yup from "yup"

import { useState, useEffect } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { SelectChangeEvent } from "@mui/material"

import {
  createLayoutRequest,
  deleteLayoutRequest,
  fetchLayoutRequest,
  fetchReservedSeatRequest,
  pausedLayoutRequest,
  revertToDraftLayoutRequest,
  revertToReleaseLayoutRequest,
  unsuspendLayoutRequest,
  updateDefaultLayoutImage,
} from "../../../api/company/layoutRequest"
import awsmobile from "../../../aws-exports"
import { arrayMax } from "../../../utils/arrayMax"
import { downloadIcon } from "../../../utils/downLoadIcon"
import { downloadLayoutImage } from "../../../utils/downloadLayoutImage"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { NOTE_NUMBER } from "../../../utils/regular"
import {
  SeatType,
  MeetingRoomType,
  MeetingRoomFormType,
  AreaType,
  AreaFormType,
  LayoutType,
  PostStatus,
  ResavableSeatType,
} from "./type"

/* 定数
  SPECIFIC: 特定
  ALL: 全て
  DEFAULT_COLOR_CODE: エリア・会議室の枠線の色
*/
export const SPECIFIC = 0
export const ALL = 1
export const DEFAULT_COLOR_CODE = "#F4B461"

/* 初期値
  meetingRoomData: 会議室の初期値
  meetingRoomFormData: 会議室フォームの初期値
*/
const meetingRoomData: MeetingRoomType = {
  id: 0,
  meeting_room_code: "",
  meeting_room_name: "会議室（名称未設定）",
  capacity: 1,
  availability_type: SPECIFIC,
  reservable_type: SPECIFIC,
  availability_team_ids: [],
  reservable_team_ids: [],
  available_time: 0,
  meeting_room_description: "",
  outside_team_usable: false,
  start_x: 100,
  start_y: 100,
  end_x: 200,
  end_y: 200,
  meeting_room_image: "",
  color_code: DEFAULT_COLOR_CODE,
  isCheck: false,
  tag_ids: [],
  appliance_ids: [],
  auto_cancel_times_id: 0,
  meeting_room_reservable_all: 1,
  meeting_room_availability_all: 1,
}

const areaData: AreaType = {
  id: 0,
  area_name: "",
  is_reservable: false,
  availability_type: 0,
  reservable_type: 0,
  area_description: "",
  start_x: 0,
  start_y: 0,
  end_x: 0,
  end_y: 0,
  color_code: DEFAULT_COLOR_CODE,
  is_area_to_meeting_room: false,
  availability_team_ids: [],
  reservable_team_ids: [],
  area_to_meeting_room: {
    available_time: 0,
    outside_team_usable: false,
    meeting_room_code: "",
  },
  area_image: "",
  tag_ids: [],
  auto_cancel_times_id: 0,
  area_availability_all: 1,
  area_reservable_all: 1,
  children: [
    {
      id: 0,
      layout_id: 0,
      area_id: 0,
      seat_name: 0,
      available_time_type: 0,
      seat_type: 0,
      x: 0,
      y: 0,
    },
  ],
}

const layoutData: LayoutType = {
  layout_image: "",
  post_status: 4,
  release_date: "",
  paused_date: "",
  version: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
  scale: 0.5,
}

const areaFormData: AreaFormType = {
  id: 0,
  area_name: "",
  is_reservable: false,
  is_area_to_meeting_room: false,
  availability_type: 0,
  reservable_type: 0,
  availability_team_ids: [],
  reservable_team_ids: [],
  area_description: "",
  available_time: 0,
  color_code: DEFAULT_COLOR_CODE,
  outside_team_usable: false,
  tag_ids: [],
  image_blob: undefined,
  auto_cancel_times_id: 0,
  area_reservable_all: 1,
  area_availability_all: 1,
}

const meetingRoomFormData: MeetingRoomFormType = {
  id: 0,
  meeting_room_name: "会議室（名称未設定）",
  availability_type: 0,
  reservable_type: 0,
  availability_team_ids: [],
  reservable_team_ids: [],
  available_time: 0,
  color_code: DEFAULT_COLOR_CODE,
  meeting_room_description: "",
  capacity: 1,
  tag_ids: [],
  appliance_ids: [],
  image_blob: undefined,
  auto_cancel_times_id: 0,
  meeting_room_reservable_all: 1,
  meeting_room_availability_all: 1,
}

export type SidebarTabType =
  | "seat"
  | "seatForm"
  | "meetingRoom"
  | "meetingRoomForm"
  | "areaForm"

export type SidebarLayoutComponent =
  | "seatAdd"
  | "seatList"
  | "meetingRoomAdd"
  | "meetingRoomList"
  | "meetingRoomForm"
  | "areaForm"

export type PostModalType =
  | "release"
  | "edit"
  | "delete"
  | "draft"
  | "paused"
  | "cancel_reservation"
  | "unsuspend"
  | "new"

const seatColorChange = (sidebarTabType: SidebarTabType) => {
  switch (sidebarTabType) {
    case "seat":
      return "#22BA9D"
    case "seatForm":
      return "#22BA9D"
    case "areaForm":
      return "#22BA9D"
    default:
      return "#FFFFFF"
  }
}

const meetingRoomColorChange = (sidebarTabType: SidebarTabType) => {
  switch (sidebarTabType) {
    case "meetingRoom":
      return "#22BA9D"
    case "meetingRoomForm":
      return "#22BA9D"
    default:
      return "#FFFFFF"
  }
}

export const useLayout = () => {
  const navigate = useNavigate()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const draftSuccessMessageObject = useAlertMessage()
  const draftErrorMessageObject = useAlertMessage()
  const [companyId, setCompanyId] = useState<number>(0)
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)
  const [layoutId, setLayoutId] = useState<number>(0)
  const [layoutImgBlob, setLayoutImgBlob] = useState<Blob | undefined>() // S3にデータとして送信する画像
  const [layoutImg, setLayoutImg] = useState<string>("") // UI上に描画する画像
  const [layout, setLayout] = useState<LayoutType>(layoutData)
  const [seats, setSeats] = useState<SeatType[]>([])
  const [resavableSeats, setResavableSeats] = useState<ResavableSeatType[]>([]) // 指定席
  const [areaToSeats, setAreaToSeats] = useState<SeatType[]>([])
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomType[]>([])
  const [meetingRoom, setMeetingRoom] =
    useState<MeetingRoomType>(meetingRoomData)
  const [area, setArea] = useState<AreaType>(areaData)
  const [areas, setAreas] = useState<AreaType[]>([])
  const [deleteSeats, setDeleteSeats] = useState<number[]>([])
  const [deleteMeetingRooms, setDeleteMeetingRooms] = useState<number[]>([])
  const [deleteAreas, setDeleteAreas] = useState<number[]>([])
  const [sidebarLayoutComponent, setSidebarLayoutComponent] =
    useState<SidebarLayoutComponent>("seatList") // レイアウトのサイドバーの表示内容の切り替え
  const [sidebarTabType, setSidebarTabType] = useState<SidebarTabType>("seat") // レイアウトのサイドバーのタブ切り替え
  const seatTabColor = seatColorChange(sidebarTabType)
  const meetingRoomTabColor = meetingRoomColorChange(sidebarTabType)
  const [loading, setLoading] = useState<boolean>(false)

  const resetAreaSeats = () => setAreaToSeats([])

  // レイアウトの拠点・フロア情報を保持する
  const changeSelectValue = async (
    e: SelectChangeEvent<number | string>,
    company_id: number
  ) => {
    setCompanyId(company_id)
    switch (e.target.name) {
      case "branch_id":
        setFloorId(0)
        return setBranchId(Number(e.target.value))
      case "floor_id":
        return setFloorId(Number(e.target.value))
      case "layout_id":
        return setLayoutId(Number(e.target.value))
    }
  }

  useEffect(() => {
    if (layoutId && layoutId !== 0) {
      setLoading(true)
      layoutReflection(layoutId).then(() => {
        // リロードしたときに現在選択しているレイアウトを表示できるようにURLを変更する
        history.replaceState(
          null,
          "",
          `/company/branches/${branchId}/floors/${floorId}/layouts/${layoutId}`
        )
        setLoading(false)
      })
    }
  }, [layoutId])

  // レイアウト情報を反映させる
  const layoutReflection = async (layoutId: number) => {
    try {
      const response = await fetchLayoutRequest({
        layout_id: layoutId,
      })
      if (response.data && response.status === 200) {
        // 会議室画像をS3からダウンロードする
        const meetingRoomsObject: MeetingRoomType[] = []
        await Promise.all(
          response.data.layout.meeting_rooms.map(async (oldMeetingRoom) => {
            const resultImageBlob = await downloadAreaOrMeetingRoomFile(
              oldMeetingRoom.meeting_room_image
            )
            meetingRoomsObject.push({
              ...oldMeetingRoom,
              image_blob: resultImageBlob ? resultImageBlob : undefined,
            })
          })
        )

        // エリア画像をS3からダウンロードする
        const areasObject: AreaType[] = []
        await Promise.all(
          response.data.layout.areas.map(async (oldArea) => {
            const resultImageBlob = await downloadAreaOrMeetingRoomFile(
              oldArea.area_image
            )
            areasObject.push({
              ...oldArea,
              image_blob: resultImageBlob ? resultImageBlob : undefined,
            })
          })
        )

        setSeats(response.data.layout.seats)
        setMeetingRooms(meetingRoomsObject)
        setAreas(areasObject)
        setLayout({
          layout_image: response.data.layout.layout_image,
          post_status: response.data.layout.post_status as PostStatus,
          release_date: response.data.layout.release_date,
          paused_date: response.data.layout.paused_date,
          version: response.data.layout.version,
          width: response.data.layout.width,
          height: response.data.layout.height,
          x: response.data.layout.x,
          y: response.data.layout.y,
          scale: response.data.layout.scale,
        })
        if (response.data.layout.layout_image) {
          const result = await downloadLayoutImage(
            companyId,
            branchId,
            floorId,
            response.data.layout.layout_image
          )
          if (result) {
            setLayoutImgBlob(result.Body as Blob)
            setLayoutImg(URL.createObjectURL(result.Body as Blob))
          }
        }
      }
    } catch (error) {
      setSeats([])
      setMeetingRooms([])
      setAreas([])
      setLayout(layoutData)
    }
  }

  /* バリデーション
    meetingRoomSchema: 会議室フォームのバリデーション
    areaSchema: エリアフォームのバリデーション
  */
  const meetingRoomSchema = Yup.object().shape({
    // meeting_room_name: Yup.string(),
    available_time: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください")
      .min(0, "0以上を入力してください")
      .max(10000, "10000以下を入力してください"),
    meeting_room_description: Yup.string().matches(
      NOTE_NUMBER,
      "1000文字まで入力可能です"
    ),
    capacity: Yup.number()
      .typeError("数値を入力してください")
      .min(1, "1以上を入力してください")
      .max(10000, "10000以下を入力してください"),
  })

  const areaSchema = Yup.object().shape({
    area_name: Yup.string().required("必須になります"),
    available_time: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください")
      .min(0, "0以上を入力してください")
      .max(10000, "10000以下を入力してください"),
    area_description: Yup.string().matches(
      NOTE_NUMBER,
      "1000文字まで入力可能です"
    ),
  })

  /* React-Hook-Form
    meetingRoomForm: 会議室
    areaForm: エリア
  */
  const meetingRoomForm = useForm({
    mode: "onBlur",
    defaultValues: {
      id: meetingRoomFormData.id,
      meeting_room_name: meetingRoomFormData.meeting_room_name,
      availability_type: meetingRoomFormData.availability_type,
      reservable_type: meetingRoomFormData.reservable_type,
      reservable_team_ids: meetingRoomFormData.reservable_team_ids,
      availability_team_ids: meetingRoomFormData.availability_team_ids,
      available_time: meetingRoomFormData.available_time,
      meeting_room_description: meetingRoomFormData.meeting_room_description,
      color_code: meetingRoomFormData.color_code,
      capacity: meetingRoomFormData.capacity,
      tag_ids: meetingRoomFormData.tag_ids,
      appliance_ids: meetingRoomFormData.appliance_ids,
      image_blob: meetingRoomFormData.image_blob,
      auto_cancel_times_id: meetingRoomFormData.auto_cancel_times_id,
      meeting_room_reservable_all:
        meetingRoomFormData.meeting_room_reservable_all,
      meeting_room_availability_all:
        meetingRoomFormData.meeting_room_availability_all,
    },
    resolver: yupResolver(meetingRoomSchema),
  })

  const areaForm = useForm({
    mode: "onBlur",
    defaultValues: {
      id: areaFormData.id,
      area_name: areaFormData.area_name,
      is_reservable: areaFormData.is_reservable,
      is_area_to_meeting_room: areaFormData.is_area_to_meeting_room,
      availability_type: areaFormData.availability_type,
      reservable_type: areaFormData.reservable_type,
      availability_team_ids: areaFormData.availability_team_ids,
      reservable_team_ids: areaFormData.reservable_team_ids,
      area_description: areaFormData.area_description,
      available_time: areaFormData.available_time,
      color_code: areaFormData.color_code,
      outside_team_usable: areaFormData.outside_team_usable,
      tag_ids: areaFormData.tag_ids,
      image_blob: areaFormData.image_blob,
      auto_cancel_times_id: areaFormData.auto_cancel_times_id,
      area_reservable_all: areaFormData.area_reservable_all,
      area_availability_all: areaFormData.area_availability_all,
    },
    resolver: yupResolver(areaSchema),
  })

  /* 席
    handleAddSeats: 席の追加
    handleDeleteSeats: 席の削除
    handleSeatCoordinate: 席の座標取得
    seatAllCheck: 全件チェック
  */
  const handleAddSeats = (seatCount: number, seatType: number) => {
    const seatArray = []

    // エリアの席数
    const areaToSeatIdArray: number[] = []
    const areaToSeatNameArray: number[] = []
    areas.map((area) => {
      area.children.map((seat) => {
        if (seat.id) {
          areaToSeatIdArray.push(seat.id)
          areaToSeatNameArray.push(seat.seat_name)
        }
      })
    })

    // 単体席
    const seatIdArray: number[] = []
    const seatNameArray: number[] = []
    seats.map((seat) => {
      if (seat.id) {
        seatIdArray.push(seat.id)
        seatNameArray.push(seat.seat_name)
      }
    })

    const seatNameSun = areaToSeatNameArray.concat(seatNameArray)

    const areaToSeatIdMax = Math.max(
      areaToSeatIdArray.length === 0 ? 0 : areaToSeatIdArray.reduce(arrayMax)
    )

    const seatNameMax = Math.max(
      seatNameSun.length === 0 ? 0 : seatNameSun.reduce(arrayMax)
    )

    const seatIdMax = Math.max(
      seatIdArray.length === 0 ? 0 : seatIdArray.reduce(arrayMax)
    )

    for (let i = 1; i <= seatCount; ++i) {
      seatArray.push({
        id: seatIdMax + areaToSeatIdMax + i,
        layout_id: layoutId,
        area_id: null,
        seat_name: seatNameMax + i,
        available_time_type: 0,
        seat_type: seatType,
        x: 100,
        y: 100,
        isCheck: false,
        isNew: true,
      })
    }

    setSeats([...seats, ...seatArray])
  }

  const handleDeleteSeats = () => {
    // 論理削除する席
    const deleteSeatArray = deleteSeats
    // 論理削除するエリア
    const deleteAreaArray = deleteAreas

    // チェックがついていない席を取得
    const seatArray = seats.filter((seat) => {
      if (seat.isCheck !== true) {
        return true
      } else if (seat.isCheck === true && !seat.isNew && seat.id) {
        deleteSeatArray.push(seat.id)
        return false
      } else {
        return false
      }
    })

    const areaArray = areas
      .map((area) => {
        // チェックがついていないエリアに紐づく席を取得
        const areaToSeats = area.children
          .map((seat) => {
            if (seat.isCheck !== true) {
              seat.area_id = null
              return seat
            } else if (seat.isCheck === true && !seat.isNew && seat.id) {
              seat.isCheck = false
              seat.area_id = null
              seatArray.push(seat)
              return null
            }
          })
          .flatMap((e) => e ?? [])

        // エリアに紐づく席を対象のエリアに代入
        area.children = areaToSeats

        // チェックがついていないエリアを返す
        if (area.isCheck !== true) {
          return area
        } else {
          if (!area.isNew && area.id) {
            deleteAreaArray.push(area.id)
          }
          // エリアにチェックがついている場合、エリアに紐づいている席を複数の席オブジェクトに代入する
          seatArray.push(...area.children)
        }
      })
      .flatMap((e) => e ?? [])

    // 削除された以外の席とエリア（＋エリアに紐づく席）を格納する
    setSeats(seatArray)
    setAreas(areaArray)

    // 削除された席とエリア
    setDeleteSeats(deleteSeatArray)
    setDeleteAreas(deleteAreaArray)
  }

  const handleSeatCoordinate = (x: number, y: number, targetSeat: SeatType) => {
    setSeats(
      seats.map((oldSeat) => {
        if (targetSeat.id === oldSeat.id) {
          return {
            ...targetSeat,
            x: x,
            y: y,
          }
        }
        return oldSeat
      })
    )
  }

  const seatAllCheck = (isSeatAllCheck: boolean) => {
    setSeats(() => {
      return seats.map((oldSeat) => {
        return {
          ...oldSeat,
          isCheck: isSeatAllCheck,
        }
      })
    })

    setAreas(
      areas.map((oldArea) => {
        const areaChildren = oldArea.children.map((oldSeat) => {
          return {
            ...oldSeat,
            isCheck: isSeatAllCheck,
          }
        })
        oldArea.children = areaChildren
        oldArea.isCheck = isSeatAllCheck
        return oldArea
      })
    )
  }

  const meetingRoomOrAreaAllCheck = (isMeetingRoomOrAreaAllCheck: boolean) => {
    setMeetingRooms(() => {
      return meetingRooms.map((oldMeetingRoom) => {
        return {
          ...oldMeetingRoom,
          isCheck: isMeetingRoomOrAreaAllCheck,
        }
      })
    })

    setAreas(
      areas.map((oldArea) => {
        const areaChildren = oldArea.children.map((oldSeat) => {
          return {
            ...oldSeat,
            isCheck: isMeetingRoomOrAreaAllCheck,
          }
        })
        oldArea.children = areaChildren
        oldArea.isCheck = isMeetingRoomOrAreaAllCheck
        return oldArea
      })
    )
  }

  /* 会議室

    handleAddMeetingRooms: 会議室の追加
    handleUpdateMeetingRoom: 会議室の更新
    handleDeleteMeetingRoomsOrAreas: 会議室・エリアの削除
    handleMeetingRoomCoordinate: 会議室の座標取得
  */
  const handleAddMeetingRooms = (meetingRoomCount: number) => {
    const meetingRoomArray = []

    const meetingRoomIdArray: number[] = []
    meetingRooms.map((meetingRoom) => {
      if (meetingRoom.id) {
        meetingRoomIdArray.push(meetingRoom.id)
      }
    })

    const meetingRoomIdMax = Math.max(
      meetingRoomIdArray.length === 0 ? 0 : meetingRoomIdArray.reduce(arrayMax)
    )

    for (let i = 1; i <= meetingRoomCount; i++) {
      meetingRoomArray.push({
        id: meetingRoomIdMax + i,
        floor_id: floorId,
        meeting_room_code: meetingRooms.length + i,
        meeting_room_name: "会議室（名称未設定）",
        capacity: 1,
        availability_type: SPECIFIC,
        reservable_type: SPECIFIC,
        available_time: 0,
        meeting_room_description: "",
        availability_team_ids: [],
        reservable_team_ids: [],
        tag_ids: [],
        appliance_ids: [],
        outside_team_usable: false,
        start_x: 100,
        start_y: 100,
        end_x: 200,
        end_y: 200,
        color_code: DEFAULT_COLOR_CODE,
        meeting_room_image: "",
        isCheck: false,
        isNew: true,
        auto_cancel_times_id: 0,
        meeting_room_reservable_all: 1,
        meeting_room_availability_all: 1,
      })
    }

    setMeetingRooms([...meetingRooms, ...meetingRoomArray])
  }

  const handleUpdateMeetingRoom: SubmitHandler<MeetingRoomFormType> = async (
    data
  ) => {
    setMeetingRooms(() => {
      return meetingRooms.map((oldMeetingRoom) => {
        // 全てか特定かの設定は使っていないため、コメントアウトする
        // const availabilityType =
        //   data.availability_team_ids.length === 0 ? ALL : data.availability_type
        // const reservableType =
        //   data.reservable_team_ids.length === 0 ? ALL : data.reservable_type

        if (data.id === oldMeetingRoom.id) {
          return {
            ...data,
            availability_type: SPECIFIC,
            reservable_type: SPECIFIC,
            outside_team_usable: oldMeetingRoom.outside_team_usable,
            start_x: oldMeetingRoom.start_x,
            start_y: oldMeetingRoom.start_y,
            end_x: oldMeetingRoom.end_x,
            end_y: oldMeetingRoom.end_y,
            meeting_room_code: oldMeetingRoom.meeting_room_code,
            meeting_room_image: oldMeetingRoom.meeting_room_image,
            isDelete: false,
            isNew: oldMeetingRoom.isNew,
            meeting_room_reservable_all: data.meeting_room_reservable_all,
            meeting_room_availability_all: data.meeting_room_availability_all,
          }
        }
        return oldMeetingRoom
      })
    })
    setSidebarTabType("meetingRoom")
    setSidebarLayoutComponent("meetingRoomList")
  }

  const handleDeleteMeetingRoomsOrAreas = () => {
    // 論理削除する会議室
    const deleteMeetingRoomArray = deleteMeetingRooms
    // 論理削除するエリア
    const deleteAreaArray = deleteAreas

    const meetingRoomArray = meetingRooms.filter((meetingRoom) => {
      if (meetingRoom.isCheck !== true) {
        return true
      } else if (
        meetingRoom.isCheck === true &&
        !meetingRoom.isNew &&
        meetingRoom.id
      ) {
        deleteMeetingRoomArray.push(meetingRoom.id)
      }
    })

    const areaArray = areas.filter((area) => {
      if (area.isCheck !== true) {
        return true
      } else if (area.isCheck === true && !area.isNew && area.id) {
        deleteAreaArray.push(area.id)
      }
    })

    // 削除された以外の会議室とエリアを格納する
    setAreas(areaArray)
    setMeetingRooms(meetingRoomArray)

    // 削除された会議室とエリア
    setDeleteMeetingRooms(deleteMeetingRoomArray)
    setDeleteAreas(deleteAreaArray)
  }

  const handleMeetingRoomCoordinate = (
    targetMeetingRoom: MeetingRoomType,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => {
    setMeetingRooms(
      meetingRooms.map((oldMeetingRoom) => {
        if (targetMeetingRoom.id === oldMeetingRoom.id) {
          return {
            ...targetMeetingRoom,
            start_x: startX,
            start_y: startY,
            end_x: endX,
            end_y: endY,
          }
        }
        return oldMeetingRoom
      })
    )
  }

  /* エリア
    handleCreateArea: エリアの追加
    handleUpdateArea: エリアの更新
    handleAreaSeats: エリアの席を追加
    handleAreaCoordinate: エリアの座標取得
    handleAreaToSeatsCoordinate: エリアに紐づく席の座標取得
  */
  const handleCreateArea: SubmitHandler<AreaFormType> = async (data) => {
    const areaIdArray: number[] = []
    areas.map((area) => {
      if (area.id) {
        areaIdArray.push(area.id)
      }
    })

    const areaIdMax = Math.max(
      areaIdArray.length === 0 ? 0 : areaIdArray.reduce(arrayMax)
    )

    // 全てか特定かの設定は使っていないため、コメントアウトする
    // const availabilityType =
    //   data.availability_team_ids.length === 0 ? ALL : data.availability_type
    // const reservableType =
    //   data.reservable_team_ids.length === 0 ? ALL : data.reservable_type

    const areaNewData: AreaType = {
      id: areaIdMax + 1,
      area_name: data.area_name,
      is_reservable: data.is_reservable,
      is_area_to_meeting_room: data.is_area_to_meeting_room,
      availability_type: SPECIFIC,
      reservable_type: SPECIFIC,
      availability_team_ids: data.availability_team_ids,
      reservable_team_ids: data.reservable_team_ids,
      area_description: data.area_description,
      color_code: data.color_code,
      start_x: 100,
      start_y: 100,
      end_x: 200,
      end_y: 200,
      tag_ids: data.tag_ids,
      area_image: "",
      image_blob: data.image_blob,
      isCheck: false,
      children: [],
      isNew: true,
      auto_cancel_times_id: data.auto_cancel_times_id,
      area_reservable_all: data.area_reservable_all,
      area_availability_all: data.area_availability_all,
    }

    if (areaNewData.is_area_to_meeting_room) {
      areaNewData.area_to_meeting_room = {
        available_time: data.available_time,
        outside_team_usable: data.outside_team_usable,
        meeting_room_code: meetingRooms.length + 1,
      }
    }

    if (areaToSeats) {
      areaNewData.children = areaToSeats

      // エリアに紐づく席はseatsから削除する
      const resultSeat = seats.filter((seat) => {
        const isDeleteSeat = areaToSeats.find((deleteSeat) => {
          return seat.id === deleteSeat.id
        })
        return isDeleteSeat ? false : true
      })

      setSeats(resultSeat)
    }

    setAreas([...areas, areaNewData])
    setSidebarTabType("seat")
    setSidebarLayoutComponent("seatList")
  }

  const handleUpdateArea: SubmitHandler<AreaFormType> = async (data) => {
    setAreas(
      areas.map((oldArea) => {
        if (data.id === oldArea.id) {
          let areaToMeetingRoom = undefined

          if (data.is_area_to_meeting_room) {
            areaToMeetingRoom = {
              available_time: data.available_time,
              outside_team_usable: data.outside_team_usable,
              meeting_room_code: meetingRooms.length + 1,
            }
          }
          let resultAreaToSeats: SeatType[] = []
          if (areaToSeats) {
            // エリアに紐づく席はseatsから削除する
            const resultSeats = seats.filter((seat) => {
              const isDeleteSeat = areaToSeats.find((deleteSeat) => {
                return seat.id === deleteSeat.id
              })
              return isDeleteSeat ? false : true
            })

            resultAreaToSeats = areaToSeats
              .map((seat) => {
                if (seat.isAreaSeatDeleteCheck !== true) {
                  seat.area_id = null
                  return seat
                } else if (
                  seat.isAreaSeatDeleteCheck === true &&
                  !seat.isNew &&
                  seat.id
                ) {
                  seat.isCheck = false
                  seat.isAreaSeatDeleteCheck = false
                  seat.area_id = null
                  resultSeats.push(seat)
                }
              })
              .flatMap((e) => e ?? [])

            setSeats(resultSeats)
          }

          // 全てか特定かの設定は使っていないため、コメントアウトする
          // const availabilityType =
          //   data.availability_team_ids.length === 0
          //     ? ALL
          //     : data.availability_type
          // const reservableType =
          //   data.reservable_team_ids.length === 0 ? ALL : data.reservable_type

          return {
            ...data,
            availability_type: SPECIFIC,
            reservable_type: SPECIFIC,
            start_x: oldArea.start_x,
            start_y: oldArea.start_y,
            end_x: oldArea.end_x,
            end_y: oldArea.end_y,
            area_to_meeting_room: areaToMeetingRoom,
            area_image: oldArea.area_image,
            isCheck: false,
            children: resultAreaToSeats,
            area_reservable_all: data.area_reservable_all,
            area_availability_all: data.area_availability_all,
          }
        }
        return oldArea
      })
    )

    setSidebarTabType("seat")
    setSidebarLayoutComponent("seatList")
  }

  // エリア作成ボタンを押下したときに発火する
  // チェックボックスにチェックを入れた席をareaToSeatを更新する
  const handleAreaSeats = () => {
    const targetSeatArray = seats
      .map((targetSeat) => {
        if (targetSeat.isCheck) {
          targetSeat.isCheck = false
          return targetSeat
        }
      })
      .flatMap((e) => e ?? [])

    areaForm.reset({
      id: areaFormData.id,
      area_name: areaFormData.area_name,
      is_reservable: areaFormData.is_reservable,
      is_area_to_meeting_room: areaFormData.is_area_to_meeting_room,
      availability_type: areaFormData.availability_type,
      reservable_type: areaFormData.reservable_type,
      availability_team_ids: areaFormData.availability_team_ids,
      reservable_team_ids: areaFormData.reservable_team_ids,
      area_description: areaFormData.area_description,
      available_time: areaFormData.available_time,
      color_code: areaFormData.color_code,
      outside_team_usable: areaFormData.outside_team_usable,
      tag_ids: areaFormData.tag_ids,
      image_blob: areaFormData.image_blob,
      auto_cancel_times_id: areaFormData.auto_cancel_times_id,
      area_reservable_all: areaFormData.area_reservable_all,
      area_availability_all: areaFormData.area_availability_all,
    })
    setArea(areaData)
    setAreaToSeats(targetSeatArray)
  }

  const handleAddAreaSeat = (seat: SeatType) => {
    setAreaToSeats((prevSeats) => {
      const existingSeatIndex = prevSeats.findIndex(
        (areaToSeat) => areaToSeat.id === seat.id
      )
      if (existingSeatIndex !== -1) {
        // 席がすでに存在する場合、その要素のisAreaSeatDeleteCheckを更新
        return prevSeats.map((areaToSeat, index) =>
          index === existingSeatIndex
            ? { ...areaToSeat, isAreaSeatDeleteCheck: false }
            : areaToSeat
        )
      } else {
        // 席が存在しない場合、新しい席を配列に追加
        seat.area_id = area.id
        return [...prevSeats, seat]
      }
    })
  }

  const handleDeleteAreaSeat = (seat: SeatType) => {
    setAreaToSeats((prevSeats) =>
      // 配列からは消さずにisAreaSeatDeleteCheckを更新して判別
      prevSeats.map((areaToSeat) =>
        areaToSeat.id === seat.id
          ? { ...areaToSeat, isAreaSeatDeleteCheck: true }
          : areaToSeat
      )
    )
  }

  const handleAreaCoordinate = (
    areaTarget: AreaType,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => {
    setAreas(
      areas.map((oldArea) => {
        if (areaTarget.id === oldArea.id) {
          return {
            ...areaTarget,
            start_x: startX,
            start_y: startY,
            end_x: endX,
            end_y: endY,
          }
        }
        return oldArea
      })
    )
  }

  const handleAreaToSeatsCoordinate = (
    x: number,
    y: number,
    targetSeat: SeatType
  ) => {
    setAreas(
      areas.map((area) => {
        const areaChildren = area.children.map((seat) => {
          if (targetSeat.id === seat.id) {
            return {
              ...targetSeat,
              x: x,
              y: y,
            }
          }
          return seat
        })
        area.children = areaChildren
        return area
      })
    )
  }

  // レイアウト画像をS3登録
  Amplify.configure(awsmobile)
  const uploadFile = async (file: Blob) => {
    if (!file) return

    const fileName = Date.now() + "." + file.type.replace(/(.*)\//g, "")
    const result = await Storage.put(fileName, file, {
      level: "public",
      contentType: file.type,
      customPrefix: {
        public: `company${companyId}/layouts/branch${branchId}/floor${floorId}/`,
      },
    })
    if (result) {
      return result
    }
  }

  // エリア・会議室画像のアップロード
  const uploadAreaOrMeetingRoomFile = async (file: Blob | undefined) => {
    if (!file) return

    // ファイル名重複を防ぐために10桁の乱数を生成
    const min = 10000000000
    const max = 19999999999
    const NUM = Math.floor(Math.random() * (max + 1 - min)) + min
    const NUM2 = ("" + NUM).slice(-10)

    const fileName =
      NUM2 + "_" + Date.now() + "." + file.type.replace(/(.*)\//g, "")
    const result = await Storage.put(fileName, file, {
      level: "public",
      contentType: file.type,
      customPrefix: {
        public: `company${companyId}/layouts/branch${branchId}/floor${floorId}/images/`,
      },
    })
    if (result) {
      return result
    }
  }

  // エリア・会議室画像のダウンロード
  const downloadAreaOrMeetingRoomFile = async (img: string) => {
    if (!img) return

    const currentCredentials = await Auth.currentCredentials()
    const identityId = currentCredentials.identityId
    const result = await Storage.get(img, {
      level: "public",
      download: true,
      identityId: identityId,
      customPrefix: {
        public: `company${companyId}/layouts/branch${branchId}/floor${floorId}/images/`,
      },
    })
    return result.Body as Blob
  }

  /* レイアウト
    handleCreateLayout: レイアウトの登録・更新
    handleCopyLayout: レイアウトの複製
    handleDeleteLayout: レイアウトの停止
    handleRevertToDraftLayout: レイアウトを下書き状態に戻す
    handleDeleteLayout: レイアウトの削除
  */

  const handleCreateLayout = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    postStatus: number
  ) => {
    if (layoutImgBlob) {
      let layoutImage = layout.layout_image

      if (layoutImage === "" || layout.isNewLayoutImage) {
        const resultLayoutImage = await uploadFile(layoutImgBlob)
        layoutImage = resultLayoutImage?.key ? resultLayoutImage.key : ""
      }

      // 会議室画像をS3に保存する
      const meetingRoomsObject: MeetingRoomType[] = []
      await Promise.all(
        meetingRooms.map(async (oldMeetingRoom) => {
          const resultImage = await uploadAreaOrMeetingRoomFile(
            oldMeetingRoom.image_blob
          )
          meetingRoomsObject.push({
            ...oldMeetingRoom,
            meeting_room_image: resultImage?.key ? resultImage.key : "",
          })
        })
      )

      // エリア画像をS3に保存する
      const areasObject: AreaType[] = []
      await Promise.all(
        areas.map(async (oldArea) => {
          const resultImage = await uploadAreaOrMeetingRoomFile(
            oldArea.image_blob
          )
          areasObject.push({
            ...oldArea,
            area_image: resultImage?.key ? resultImage.key : "",
          })
        })
      )

      // 公開日の設定
      let result_date: Date | string = new Date()
      if (postStatus === 0) {
        result_date = ""
      } else if (postStatus === 1) {
        if (new Date(layout.release_date) >= new Date()) {
          result_date = new Date(layout.release_date)
        } else {
          result_date = new Date()
        }
      }

      const requestData = await {
        layout: {
          company_id: companyId,
          branch_id: branchId,
          floor_id: floorId,
          layout_image: layoutImage,
          post_status: postStatus,
          release_date: result_date,
          paused_date: layout.paused_date,
          version: layout.version,
          width: layout.width,
          height: layout.height,
          x: layout.x,
          y: layout.y,
          scale: layout.scale,
          layout_id: layoutId,
          seats: seats.map((oldSeat) => {
            return { ...oldSeat, id: oldSeat.isNew ? null : oldSeat.id }
          }),
          meeting_rooms: meetingRoomsObject.map((oldMeetingRoom) => {
            return {
              ...oldMeetingRoom,
              id: oldMeetingRoom.isNew ? null : oldMeetingRoom.id,
            }
          }),
          areas: areasObject.map((oldArea) => {
            const areaChildren = oldArea.children.map((oldSeat) => {
              return { ...oldSeat, id: oldSeat.isNew ? null : oldSeat.id }
            })
            oldArea.children = areaChildren
            return { ...oldArea, id: oldArea.isNew ? null : oldArea.id }
          }),
          delete: {
            seats: deleteSeats,
            meeting_rooms: deleteMeetingRooms,
            areas: deleteAreas,
          },
        },
      }

      const { data, error } = await createLayoutRequest({
        ...requestData,
      })

      if (!error) {
        if (
          requestData.layout.post_status === PostStatus.draft &&
          !requestData.layout.release_date &&
          data?.layout_id
        ) {
          setLoading(true)
          layoutReflection(data.layout_id).then(() => {
            setLoading(false)
            // リロードしたときに現在選択しているレイアウトを表示できるようにURLを変更する
            history.replaceState(
              null,
              "",
              `/company/branches/${branchId}/floors/${floorId}/layouts/${data?.layout_id}`
            )
          })
          setLayoutId(data.layout_id) // LayoutIdを保存すると、最新のレイアウト情報を取得するAPIが叩かれる
          draftSuccessMessageObject.handleSetMessage("下書きを保存しました")
          draftSuccessMessageObject.openMessage()
        } else {
          if (
            window.location.pathname.includes("new")
            // 新規登録時
          ) {
            localStorage.setItem(
              "alertContent",
              "レイアウトの登録が完了しました"
            )
            navigate(
              `/company/branches/${branchId}/floors/${floorId}/layouts/${data?.layout_id}`
            )
          } else {
            // 編集時
            localStorage.setItem(
              "alertContent",
              "レイアウトの更新が完了しました"
            )
          }
          window.location.reload()
        }
      } else {
        if (requestData.layout.post_status === PostStatus.draft) {
          draftErrorMessageObject.handleSetMessage(
            "下書きの登録に失敗しました。入力に誤りがないかご確認お願いします"
          )
          draftErrorMessageObject.openMessage()
        } else {
          setErrorMessages([
            "レイアウト登録に失敗しました。",
            "入力に誤りがないかご確認ください。",
          ])
        }
      }
    } else {
      setErrorMessages(["レイアウト画像を選択してください"])
    }
  }

  const handleCopyLayout = async () => {
    const layoutImage = layout.layout_image

    const requestData = await {
      layout: {
        company_id: companyId,
        branch_id: branchId,
        floor_id: floorId,
        layout_image: layoutImage,
        post_status: 0,
        release_date: "",
        paused_date: layout.paused_date,
        version: layout.version + 1,
        width: layout.width,
        height: layout.height,
        x: layout.x,
        y: layout.y,
        scale: layout.scale,
        layout_id: 0,
        seats: seats.map((oldSeat) => {
          return { ...oldSeat, id: null }
        }),
        meeting_rooms: meetingRooms.map((oldMeetingRoom) => {
          return {
            ...oldMeetingRoom,
            id: null,
          }
        }),
        areas: areas.map((oldArea) => {
          const areaChildren = oldArea.children.map((oldSeat) => {
            return { ...oldSeat, id: null }
          })
          oldArea.children = areaChildren
          return { ...oldArea, id: null }
        }),
        delete: {
          seats: deleteSeats,
          meeting_rooms: deleteMeetingRooms,
          areas: deleteAreas,
        },
      },
    }

    const { data, error } = await createLayoutRequest({
      ...requestData,
    })
    if (!error) {
      if (
        requestData.layout.post_status === PostStatus.draft &&
        !requestData.layout.release_date &&
        data?.layout_id
      ) {
        setLoading(true)
        layoutReflection(data.layout_id).then(() => {
          setLoading(false)
          // リロードしたときに現在選択しているレイアウトを表示できるようにURLを変更する
          history.replaceState(
            null,
            "",
            `/company/branches/${branchId}/floors/${floorId}/layouts/${data?.layout_id}`
          )
          window.location.reload()
        })
        setLayoutId(data.layout_id) // LayoutIdを保存すると、最新のレイアウト情報を取得するAPIが叩かれる
      }
    }
  }

  const handlePausedLayout = async () => {
    // // 公開日の設定
    let result_date: Date | string = new Date()
    if (new Date(layout.paused_date) >= new Date()) {
      result_date = new Date(layout.paused_date)
    } else {
      result_date = new Date()
    }

    const data = {
      layout: {
        layout_id: layoutId,
        paused_date: result_date,
      },
    }

    const { error } = await pausedLayoutRequest({
      ...data,
    })

    if (!error) {
      localStorage.setItem("alertContent", "レイアウトの停止を設定しました")
      window.location.reload()
    } else {
      setErrorMessages([
        "レイアウトの停止に失敗しました。",
        "入力に誤りがないかご確認ください。",
      ])
    }
  }

  const handleDeleteLayout = async () => {
    const data = {
      layout_id: layoutId,
    }

    const { error } = await deleteLayoutRequest({
      ...data,
    })

    if (!error) {
      localStorage.setItem("alertContent", "レイアウトを削除しました")
      navigate(`/company/branches/${branchId}`)
    } else {
      setErrorMessages(["レイアウトの削除に失敗しました。"])
    }
  }

  const handleRevertToDraftLayout = async () => {
    const data = {
      layout_id: layoutId,
    }

    const { error } = await revertToDraftLayoutRequest({
      ...data,
    })

    if (!error) {
      localStorage.setItem("alertContent", "公開予約を取消しました")
      window.location.reload()
    } else {
      setErrorMessages(["公開予約の取消しに失敗しました。"])
    }
  }

  const handleRevertToReleaseLayout = async () => {
    const data = {
      layout_id: layoutId,
    }

    const { error } = await revertToReleaseLayoutRequest({
      ...data,
    })

    if (!error) {
      localStorage.setItem("alertContent", "停止予約を取消しました")
      window.location.reload()
    } else {
      setErrorMessages(["停止予約の取消しに失敗しました。"])
    }
  }

  const handleUnsuspendLayout = async () => {
    const data = {
      layout_id: layoutId,
    }

    const { error } = await unsuspendLayoutRequest({
      ...data,
    })

    if (!error) {
      localStorage.setItem("alertContent", "停止を解除しました")
      window.location.reload()
    } else {
      setErrorMessages(["停止解除に失敗しました。"])
    }
  }

  // 指定席の取得
  const handleReservedSeat = async (
    companyId: number,
    branchId: number,
    floorId: number
  ) => {
    setLoading(true)
    const response = await fetchReservedSeatRequest({
      floor_id: floorId,
    })

    if (response.data && response.status === 200) {
      setLayout({
        layout_image: response.data.floor.layout.layout_image,
        post_status: response.data.floor.layout.post_status,
        release_date: response.data.floor.layout.release_date,
        paused_date: response.data.floor.layout.paused_date,
        version: response.data.floor.layout.version,
        width: response.data.floor.layout.width,
        height: response.data.floor.layout.height,
        x: response.data.floor.layout.x,
        y: response.data.floor.layout.y,
        scale: response.data.floor.layout.scale,
      })
      if (response.data.floor.layout.layout_image) {
        const result = await downloadLayoutImage(
          companyId,
          branchId,
          floorId,
          response.data.floor.layout.layout_image
        )
        if (result) setLayoutImg(URL.createObjectURL(result.Body as Blob))
      }

      const employeeObject: ResavableSeatType[] = []
      await Promise.all(
        response.data.floor.seats.map(async (oldSeat) => {
          const resultImage = await downloadIcon(
            companyId,
            oldSeat.employee.employee_id,
            oldSeat.employee.icon
          )
          employeeObject.push({
            ...oldSeat,
            employee: {
              employee_id: oldSeat.employee.employee_id,
              icon: resultImage ? resultImage : "",
              last_name: oldSeat.employee.last_name,
              first_name: oldSeat.employee.first_name,
            },
          })
        })
      )

      setResavableSeats(employeeObject)
      setLoading(false)
    }
  }

  // レイアウトの初期画面設定を保存
  const handleUpdateDefaultLayoutImage = async (
    x: number,
    y: number,
    scale: number
  ) => {
    const params = {
      layout_id: layoutId,
      x: x,
      y: y,
      scale: scale,
    }

    const { error } = await updateDefaultLayoutImage({
      ...params,
    })
    if (!error) {
      localStorage.setItem("alertContent", "初期画面設定を保存しました")
      window.location.reload()
    } else {
      setErrorMessages(["初期画面設定の保存に失敗しました。"])
    }
  }

  return {
    branchId,
    floorId,
    layoutId,
    setLayoutId,
    errorMessages,
    changeSelectValue,
    setLayout,
    layout,
    setLayoutImg,
    layoutImg,
    setLayoutImgBlob,
    handleAddSeats,
    seats,
    areaToSeats,
    setAreaToSeats,
    handleDeleteSeats,
    setSeats,
    meetingRoom,
    meetingRooms,
    setMeetingRoom,
    setMeetingRooms,
    meetingRoomForm,
    area,
    areas,
    setArea,
    setAreas,
    areaForm,
    handleAddMeetingRooms,
    handleDeleteMeetingRoomsOrAreas,
    handleUpdateMeetingRoom,
    sidebarLayoutComponent,
    setSidebarLayoutComponent,
    sidebarTabType,
    setSidebarTabType,
    seatTabColor,
    meetingRoomTabColor,
    handleCreateLayout,
    handleCopyLayout,
    handleSeatCoordinate,
    handleMeetingRoomCoordinate,
    handleAreaCoordinate,
    handleCreateArea,
    handleAreaSeats,
    handleUpdateArea,
    handleAreaToSeatsCoordinate,
    handleAddAreaSeat,
    handleDeleteAreaSeat,
    handlePausedLayout,
    handleDeleteLayout,
    handleRevertToDraftLayout,
    handleRevertToReleaseLayout,
    handleUnsuspendLayout,
    draftSuccessMessageObject,
    draftErrorMessageObject,
    loading,
    setCompanyId,
    setBranchId,
    setFloorId,
    handleReservedSeat,
    resavableSeats,
    seatAllCheck,
    meetingRoomOrAreaAllCheck,
    resetAreaSeats,
    handleUpdateDefaultLayoutImage,
  }
}
