import React from "react"

import { MobileAuthPageTemplate } from "../../../components/mobile/common/AuthPageTemplate"
import { PasswordChangeForm } from "../../../components/mobile/login/PasswordChangeForm"
import { usePasswordChange } from "../../../models/public/useAuth/passwordChange"

export const MobilePasswordChange = () => {
  const authObject = usePasswordChange()

  return (
    <MobileAuthPageTemplate loading={authObject.loading}>
      <PasswordChangeForm {...authObject} />
    </MobileAuthPageTemplate>
  )
}
