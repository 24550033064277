import { useEffect, useState, useCallback } from "react"
import { useLocation } from "react-router-dom"

import { useSyncLanguageAcrossTabs } from "../../../../src/utils/useSyncLanguageAcrossTabs"
import {
  fetchEmployeeTeamRequest,
  fetchEmployeeRelationsRequest,
  fetchMobileScheduleStatusListRequest,
} from "../../../api/employee/mobileTeamSearchRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/mobileTeamSearch/en"
import jaTranslations from "../../../translations/mobileTeamSearch/ja"
import { dateStatus } from "../../../utils/date"
import { downloadIcon } from "../../../utils/downLoadIcon"
import { fifteenMinutesIntervalHours } from "../../../utils/hours"
import { SearchUserable } from "../../employee/useSchedule/useScheduleUserableSearch/type"
import {
  MobileStatusListRequestType,
  MobileStatusListResponseType,
  EmployeeStatusListLocationType,
  SortByStatusType,
  TeamType,
  EmployeeGroupType,
} from "./type"

export const useTeamSearch = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useSyncLanguageAcrossTabs()

  const locationState = useLocation().state as EmployeeStatusListLocationType
  const [teams, setTeams] = useState<TeamType[]>([])
  const [myTeamId, setMyTeamId] = useState<number>(0)
  const [selectedTeams, setSelectedTeams] = useState<TeamType[]>([])
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroupType[]>([])
  const [selectedEmployeeGroups, setSelectedEmployeeGroups] = useState<
    EmployeeGroupType[]
  >([])
  const [selectedEmployees, setSelectedEmployees] = useState<SearchUserable[]>(
    []
  )

  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([])
  const [selectedEmployeeGroupIds, setSelectedEmployeeGroupIds] = useState<
    number[]
  >([])
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [timeIndex, setTimeIndex] = useState<number>(
    locationState ? locationState?.timeIndex : 0
  )
  const [scheduleParDateList, setScheduleParDateList] = useState<
    MobileStatusListResponseType[]
  >([])
  const [sortByStatusSchedules, setSortByStatusSchedules] = useState<
    SortByStatusType[]
  >([])

  const {
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    startDate,
    endDate,
    formatDate,
    setStartDate,
    setEndDate,
  } = dateStatus(locationState ? locationState?.date : new Date())

  // ログインユーザーの所属情報を取得
  const fetchEmployeeTeam = useCallback(async () => {
    try {
      const response = await fetchEmployeeTeamRequest()
      if (response.data && response.status === 200) {
        setSelectedTeams([response.data])
        setSelectedTeamIds([response.data.id])
        setMyTeamId(response.data.id)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  // 所属・お気に入りグループのプルダウンの中身を取得
  const fetchEmployeeRelations = useCallback(async () => {
    try {
      const response = await fetchEmployeeRelationsRequest()
      if (response.data && response.status === 200) {
        const sortedTeams = handleSortTeams(response.data.teams)
        setTeams(sortedTeams)
        setEmployeeGroups(response.data.employee_groups)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  // 自分の所属を先頭に並び替える
  const handleSortTeams = (teams: TeamType[]) => {
    return teams.sort((team) => {
      if (team.id === myTeamId) {
        return -1
      } else return 1
    })
  }

  // スライダーの初期値を現在時刻に合わせる
  const setSliderPosition = () => {
    const nowTime = new Date()
    let nowMinutes = nowTime.getMinutes()
    const nowHours = nowTime.getHours()
    // 現在時刻が6時から23時代の間の場合
    if (6 <= nowHours && nowHours <= 23) {
      switch ((nowMinutes / 15) | 0) {
        case 0:
          nowMinutes = 0
          break
        case 1:
          nowMinutes = 15
          break
        case 2:
          nowMinutes = 30
          break
        case 3:
          nowMinutes = 45
          break
      }
      //timeIndexをセットする
      const time = nowHours + ":" + String(nowMinutes).padStart(2, "0")
      setTimeIndex(
        fifteenMinutesIntervalHours.findIndex(
          (fifteenMinutesIntervalHour) => fifteenMinutesIntervalHour === time
        )
      )
      timeFilterSchedule(formatDate(date), time)
    }
  }

  // 検索対象者のステータススケジュールを取得する
  const fetchMobileScheduleStatusList = async (
    params: MobileStatusListRequestType
  ) => {
    setLoading(true)
    try {
      const response = await fetchMobileScheduleStatusListRequest(params)
      if (response.data && response.status === 200) {
        await Promise.all(
          response.data.status.map(
            async (schedule: MobileStatusListResponseType) => {
              schedule.image_blob = ""
              if (schedule.userable.icon && response.data) {
                const icon = await downloadIcon(
                  response.data.company_id,
                  schedule.userable.id,
                  schedule.userable.icon
                )
                if (icon) {
                  schedule.image_blob = icon
                }
              }
            }
          )
        )
        setScheduleParDateList(response.data.status)
      } else if (response.status === 204) {
        setScheduleParDateList([])
        setSortByStatusSchedules([])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 時間でフィルターしたスケジュール
  const timeFilterSchedule = (date: string, time: string) => {
    if (scheduleParDateList.length > 0) {
      const scheduleFilteredByDate: MobileStatusListResponseType[] =
        scheduleParDateList.filter((list) => {
          return list.scheduled_date === date
        })
      const targetStartTime = new Date(
        `${date.replace(/-/g, "/")} ${time}`
      ).getTime()
      const scheduleFilteredByTime = scheduleFilteredByDate.filter(
        (schedule) => {
          const startTimeNumber = new Date(schedule.start_time).getTime()
          const endTimeNumber = new Date(schedule.end_time).getTime()
          return (
            startTimeNumber <= targetStartTime &&
            endTimeNumber >= targetStartTime
          )
        }
      )
      handleSortByStatusSchedules(scheduleFilteredByTime)
    }
  }

  // ステータス別にソート
  const handleSortByStatusSchedules = (
    filteredSchedules: MobileStatusListResponseType[]
  ) => {
    const inOfficeData = filteredSchedules.filter((schedule) => {
      return schedule.schedule_status === "in_office"
    })
    const floorNameList = inOfficeData.map((schedule) => {
      return schedule.place.place_name
    })
    const uniqueFloorNameList = Array.from(new Set(floorNameList))

    const sortByStatusData: SortByStatusType[] = []
    uniqueFloorNameList.forEach((nameList) => {
      const targetSchedule = inOfficeData.filter((schedule) => {
        return schedule.place.place_name === nameList
      })
      sortByStatusData.push({
        status: nameList,
        employees: targetSchedule,
      })
    })

    const remoteData = filteredSchedules.filter((schedule) => {
      return schedule.schedule_status === "remote"
    })
    const goingOutData = filteredSchedules.filter((schedule) => {
      return schedule.schedule_status === "going_out"
    })
    const tripData = filteredSchedules.filter((schedule) => {
      return schedule.schedule_status === "trip"
    })
    const holidayData = filteredSchedules.filter((schedule) => {
      return schedule.schedule_status === "holiday"
    })
    sortByStatusData.push(
      { status: translations.status[0], employees: remoteData },
      { status: translations.status[1], employees: goingOutData },
      { status: translations.status[2], employees: tripData },
      { status: translations.status[3], employees: holidayData }
    )
    setSortByStatusSchedules(sortByStatusData)
  }
  return {
    loading,
    date,
    setDate,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    formatDate,
    timeIndex,
    setTimeIndex,
    teams,
    selectedTeams,
    setSelectedTeams,
    employeeGroups,
    selectedEmployeeGroups,
    setSelectedEmployeeGroups,
    selectedEmployees,
    setSelectedEmployees,
    selectedTeamIds,
    setSelectedTeamIds,
    selectedEmployeeGroupIds,
    setSelectedEmployeeGroupIds,
    selectedEmployeeIds,
    setSelectedEmployeeIds,
    sortByStatusSchedules,
    fetchEmployeeTeam,
    fetchEmployeeRelations,
    fetchMobileScheduleStatusList,
    timeFilterSchedule,
    scheduleParDateList,
    setSliderPosition,
  }
}
