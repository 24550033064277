// 別タブで開いた時にリロードせずに反映
import { useEffect } from "react"

import { useLanguage } from "../contexts/LanguageContext"

export const useSyncLanguageAcrossTabs = () => {
  const { setLanguage } = useLanguage()

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "language" && e.newValue) {
        setLanguage(e.newValue)
      }
    }
    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [setLanguage])
}
