import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { SignageMainOutlineButton } from "../../../components/public/SignageMainOutlineButton"
import { theme } from "../../../config/theme"
import { useSignageReception } from "../../../models/signage/useReception"

const CustomBox = styled(Box)(() => ({
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(8),
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  marginBottom: theme.spacing(15),
}))

export const SignageReceptionNotReservedPage = () => {
  const navigate = useNavigate()
  const { returnTopPage, resetTimer } = useSignageReception()
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)

  useEffect(() => {
    returnTopPage()
  }, [])

  return (
    <CustomBox>
      <CustomTypography variant="h3">
        社内担当者へご連絡ください。
        <Typography fontSize="36px" component="div">
          Please contact our internal staff directly.
        </Typography>
      </CustomTypography>
      <SignageMainOutlineButton
        label="TOPに戻る"
        subLabel="Back to TOP"
        handleClick={() => {
          resetTimer()
          navigate(`/signage/branches/${branchId}/reception/home`)
        }}
      />
    </CustomBox>
  )
}
