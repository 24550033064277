import { translations } from "@aws-amplify/ui-react"

import React, { useMemo, useState } from "react"

import { Box, Tooltip, Typography } from "@mui/material"

import { SeatType } from "../../../../../models/signage/useFloor/type"
import {
  freeSeatStyle,
  notCheckInSeatStyle,
  unavailableStyle,
  checkInSeatStyle,
} from "../../../../../styles/seatStyle"
import { formatTime } from "../../../../../utils/time"

interface Props {
  seat: SeatType
}

// 座席オブジェクト
export const SignageFloorSeat = (props: Props) => {
  const { seat } = props

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const generateStyleObject = (x: number, y: number) => {
    let objectStyle = undefined
    if (seat.unavailable_flag) {
      objectStyle = unavailableStyle
    } else if (!seat.schedule) {
      objectStyle = freeSeatStyle
    } else if (seat.schedule.checked_in) {
      objectStyle = checkInSeatStyle
    } else {
      objectStyle = notCheckInSeatStyle
    }
    return { ...objectStyle, top: y, left: x }
  }

  const timeFormat = (time: string) => {
    const isoTime = time.replace(" ", "T").replace(" ", "")
    const date = new Date(isoTime)
    const hour = ("0" + date.getHours()).slice(-2)
    const min = ("0" + date.getMinutes()).slice(-2)

    return `${hour}:${min}`
  }

  const tooltipText = () => {
    const seatInfo = `座席番号:${seat.seat_name}\n`
    const employeeName = `予約者: ${seat.schedule?.userable.last_name} ${seat.schedule?.userable.first_name}\n`
    const scheduleTitle =
      seat.schedule?.schedule_type === "status"
        ? ""
        : `件名: ${seat.schedule?.schedule_title}\n`
    const scheduleTime = seat.schedule?.whole_day_flag
      ? "終日"
      : `${timeFormat(seat.schedule?.start_time as string)} ~ ${timeFormat(
          seat.schedule?.end_time as string
        )}`

    return `${seatInfo}${employeeName}${scheduleTitle}${scheduleTime}`
  }

  return (
    <>
      <Box
        id={String(seat.id)}
        className={"seat"}
        sx={
          generateStyleObject(seat.x, seat.y) as React.CSSProperties | undefined
        }
        // TODO: この辺のイベントがどう動くか実機検証必要
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {seat.schedule ? (
            <Tooltip
              title={tooltipText()}
              placement="bottom"
              disableInteractive
              open={tooltipOpen}
              componentsProps={{
                tooltip: {
                  sx: {
                    whiteSpace: "pre-wrap",
                    position: "fixed",
                    width: "100px",
                  },
                },
              }}
            >
              {seat.schedule?.image_blob ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src={seat.schedule.image_blob}
                />
              ) : (
                <Typography
                  sx={{ fontSize: "20px" }}
                  color="inherit"
                  display="flex"
                >
                  <span>{seat.schedule.userable.last_name[0]}</span>
                  <span>{seat.schedule.userable.first_name[0]}</span>
                </Typography>
              )}
            </Tooltip>
          ) : (
            seat.seat_name
          )}
        </div>
      </Box>
    </>
  )
}
