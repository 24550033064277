import React, { ReactNode, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

import { Box, styled } from "@mui/material"

import { MobileHeader } from "../../components/mobile/header"
import { Loading } from "../../components/public/Loading"
import { theme } from "../../config/theme"
import { useCompany } from "../../models/company/useCompany"
import { useIcon } from "../../models/public/useIcon"

const CustomMainContent = styled(Box)(() => ({
  padding: theme.spacing(1.5),
  width: "100%",
  backgroundColor: "#fff",
  overflow: "scroll",
}))

export const MobileCommonTemplate = ({ children }: { children: ReactNode }) => {
  const companyObject = useCompany()
  const iconObject = useIcon()
  const headerObject = companyObject.headerCompanyInfo
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    companyObject.fetchCompany().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (headerObject && !headerObject.is_temporary) {
      iconObject.fetchFile(headerObject.company.id, headerObject.employee.id)
    }
  }, [headerObject])

  return (
    <>
      {loading ? (
        <>
          <Loading type="fullScreen" />
        </>
      ) : (
        <>
          {headerObject ? (
            <>
              <Box sx={{ boxSizing: "border-box" }}>
                <MobileHeader {...companyObject} {...iconObject} />
                <CustomMainContent>{children}</CustomMainContent>
              </Box>
            </>
          ) : (
            <Navigate to="/company/register-info" />
          )}
        </>
      )}
    </>
  )
}
