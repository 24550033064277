import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  Button,
  Grid,
  Menu,
  styled,
  Tooltip,
  Typography,
} from "@mui/material"

import { loginCompanyEmployeesSearchRequest } from "../../../api/company/loginCompanyEmployeesSearchRequest"
import { checkUserEmployeeTypeRequest } from "../../../api/employee/checkUserRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import { Company, Employee } from "../../../models/company/useCompany/type"
import { SearchEmployeeListRequestType } from "../../../models/company/useEmployeeListSearch/type"
import { useAuth } from "../../../models/public/useAuth"
import { useFederatedSignIn } from "../../../models/public/useFederatedSignIn"
import { AuthContext } from "../../../providers/AuthProvider"
import { RoleContext } from "../../../providers/RoleProvider"
import enTranslations from "../../../translations/employeeIcon/employeeMenuIcon/en"
import jaTranslations from "../../../translations/employeeIcon/employeeMenuIcon/ja"
import { pageType } from "../../../utils/location"
import { Loading } from "../Loading"

const EmployeeIcon = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "50%",
  height: "50px",
  width: "50px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const CameraIcon = styled("img")(() => ({
  position: "absolute",
  bottom: "-20%",
  right: "-20%",
}))

const IconContainer = styled(Grid)(() => ({
  position: "relative",
}))

const MenuContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  border: "1px solid #707070",
  color: "#707070",
  borderRadius: "30px",
  padding: theme.spacing(1, 3),
}))

interface Props {
  anchorEl: HTMLElement | null
  handleClose: () => void
  employee?: Employee
  email?: string
  company?: Company
  is_temporary?: boolean
  handleCreateIcon: (
    file: Blob,
    companyId: number,
    employeeId: number
  ) => Promise<void>
  loading: boolean
  imageUrl: string
}

export const HeaderMenu: React.FC<Props> = ({
  anchorEl,
  handleClose,
  employee,
  email,
  company,
  is_temporary,
  handleCreateIcon,
  // fetchFile,
  imageUrl,
  loading,
}: Props) => {
  const { signOut } = useAuth()
  const { federatedSignOut } = useFederatedSignIn()
  const navigate = useNavigate()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { isAdmin, isTeamLeader } = useContext(RoleContext)
  const { currentUser } = useContext(AuthContext)
  const [isSSOUser, setIsSSOUser] = useState(false)

  useEffect(() => {
    // ユーザーのメールアドレスが存在する場合、ユーザータイプを確認
    const email = currentUser?.attributes.email
    if (email) {
      checkUserEmployeeTypeRequest(email).then((response) => {
        if (response.data?.user_type === "SSO") {
          setIsSSOUser(true)
        }
      })
    }
  }, [currentUser])

  // ファイルのアップロード
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!company) {
      // 基本的にcompanyは存在する前提だが、もしいなかった場合のためにガード文を挿入
      window.location.reload()
      return
    }
    if (e.target.files) {
      handleCreateIcon(
        e.target.files[0] as Blob,
        company.id as number,
        employee?.id as number
      )
    }
  }

  // カメラアイコンをクリックした時にfile fieldを起動する
  const handleCameraIconClick = () => {
    fileInputRef.current?.click()
  }

  const openNewPage = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer")
  }

  const handleLogout = async () => {
    if (isSSOUser) {
      await federatedSignOut()
    } else {
      await signOut()
    }
  }
  // 法人管理者、所属長が従業員に登録されているか確認
  const handleEmployeeNavigation = async () => {
    try {
      const params: SearchEmployeeListRequestType = {
        freeword: "",
        branch_id: 0,
        floor_id: 0,
        team_id: 0,
        include_unavailable: false,
        admin_only: false,
        unavailable_only: false,
      }

      const response = await loginCompanyEmployeesSearchRequest(params)
      if (
        response.status === 200 &&
        response.data &&
        response.data.length > 0
      ) {
        navigate("/employee/home")
      } else {
        alert(
          "現在ログインしているメールアドレスが登録された従業員を作成後、再度従業員画面へアクセスしてください"
        )
      }
    } catch (error) {
      console.error(error)
      alert("従業員の確認中にエラーが発生しました")
    }
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <Menu
        id="header_menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 50,
          horizontal: 25,
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuContainer container spacing={4}>
          <IconContainer item>
            <EmployeeIcon>
              {imageUrl === "" ? (
                <Typography variant="h6" color="inherit">
                  {employee?.last_name[0]}
                  {employee?.first_name[0]}
                </Typography>
              ) : (
                <img
                  src={imageUrl}
                  style={{
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </EmployeeIcon>
            <Tooltip
              title={
                is_temporary
                  ? "アイコンを設定するには従業員を登録してください"
                  : translations.PleaseSetIcon
              }
              placement="bottom"
              disableInteractive
            >
              <CameraIcon
                src={`${process.env.PUBLIC_URL}/images/icons/camera.svg`}
                width="28px"
                onClick={is_temporary ? () => null : handleCameraIconClick}
                sx={{
                  cursor: is_temporary ? "normal" : "pointer",
                }}
              />
            </Tooltip>
            <input
              hidden
              ref={fileInputRef}
              type="file"
              onChange={handleFileUpload}
              accept="image/jpeg,image/png"
            />
          </IconContainer>
          <Grid item container justifyContent="center">
            <Grid item sm={12}>
              <Typography textAlign="center" fontWeight="bold">
                {employee?.last_name}
                {employee?.first_name}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography textAlign="center" variant="subtitle2">
                {email}
              </Typography>
            </Grid>
          </Grid>
          {location.pathname.match("/employee/") && (
            <Grid item>
              <CustomButton
                onClick={() => navigate("/employee/profile")}
                sx={{ textTransform: "none" }}
              >
                {translations.Profile}
              </CustomButton>
            </Grid>
          )}
          <Grid item>
            <CustomButton
              onClick={() =>
                openNewPage("https://workagile.jp/index.php/guide/")
              }
              sx={{ textTransform: "none" }}
            >
              {translations.Help}
            </CustomButton>
          </Grid>
          {!isSSOUser && (
            <Grid item>
              <CustomButton
                onClick={() => {
                  localStorage.setItem("expiredChangePassword", "true")
                  navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
                }}
                sx={{ textTransform: "none" }}
              >
                {translations.ChangePassword}
              </CustomButton>
            </Grid>
          )}
          {location.pathname.match("/employee/") && (isAdmin || isTeamLeader) && (
            <Grid item>
              <CustomButton
                onClick={() =>
                  navigate(
                    isTeamLeader ? "/company/employees" : "/company/home"
                  )
                }
                sx={{ textTransform: "none" }}
              >
                {isTeamLeader
                  ? translations.Report
                  : translations.ManagementConsole}
              </CustomButton>
            </Grid>
          )}

          {location.pathname.match("/company/") && (isAdmin || isTeamLeader) && (
            <Grid item>
              <CustomButton
                onClick={handleEmployeeNavigation}
                sx={{ textTransform: "none" }}
              >
                {translations.employee} {/* 従業員画面へ */}
              </CustomButton>
            </Grid>
          )}
          <Grid item>
            <CustomButton onClick={handleLogout} sx={{ textTransform: "none" }}>
              {translations.LogOut}
            </CustomButton>
          </Grid>
          <Grid item container>
            <Grid item sx={{ flexGrow: 5 }}>
              {" "}
            </Grid>
            <Grid item sx={{ fontSize: "small" }}>
              v.1.9.14
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              {" "}
            </Grid>
          </Grid>
        </MenuContainer>
      </Menu>
    </>
  )
}
