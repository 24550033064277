import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useEffect, useRef } from "react"

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import { Box, Typography, Button, Popover, styled } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import {
  AreaType,
  MeetingRoomType,
  LayoutType,
} from "../../../../models/employee/useLayout/type"
import { SeatObjectType } from "../../../../models/employee/useLayout/type"
import enTranslations from "../../../../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../../../../translations/employeeStatus/employeeStatusHeader/ja"
import { AvailableSeat } from "../../../public/AvailableSeat"
import { NotCheckInSeat } from "../../../public/NotCheckInSeat"
import { UnavailableSeat } from "../../../public/UnavailableSeat"
import { EmployeeLayoutArea } from "../EmployeeLayoutArea"
import { EmployeeLayoutMeetingRoom } from "../EmployeeLayoutMeetingRoom"
import { EmployeeLayoutSeat } from "../EmployeeLayoutSeat"

const ZoomButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
}))

interface Props {
  layoutImg: string
  layout: LayoutType
  seats: SeatObjectType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
  setSelectedSeat: React.Dispatch<
    React.SetStateAction<SeatObjectType | undefined>
  >
  setSelectedMeetingRoom: React.Dispatch<
    React.SetStateAction<MeetingRoomType | undefined>
  >
}

// レイアウト表示部分
export const EmployeeLayoutMain: React.FC<Props> = ({
  layoutImg,
  layout,
  seats,
  meetingRooms,
  areas,
  setSelectedSeat,
  setSelectedMeetingRoom,
}: Props) => {
  // アイコン凡例の表示
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [x, setX] = React.useState(layout.x)
  const [y, setY] = React.useState(layout.y)
  const [scale, setScale] = React.useState(layout.scale)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const highLightedSeats = seats.filter((seat) => {
    return seat.is_highlight
  })

  const highLightedMeetingRooms = meetingRooms.filter((meetingRoom) => {
    return meetingRoom.is_highlight
  })

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // 座席もしくは会議室がハイライトされた場合にズームする
  useEffect(() => {
    if (highLightedSeats.length > 0) {
      const targetSeat = document.getElementById(String(highLightedSeats[0].id))
      layoutImageRef.current?.zoomToElement(targetSeat as HTMLElement, 1.3)
    }
  }, [highLightedSeats.length])

  useEffect(() => {
    if (highLightedMeetingRooms.length > 0) {
      const targetMeetingRoom = document.getElementById(
        `M${String(highLightedMeetingRooms[0].id)}`
      )
      layoutImageRef.current?.zoomToElement(
        targetMeetingRoom as HTMLElement,
        1.3
      )
    }
  }, [highLightedMeetingRooms.length])

  useEffect(() => {
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout])

  useEffect(() => {
    layoutImageRef.current?.setTransform(x, y, scale)
  }, [x, y, scale])

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          padding: theme.spacing(1.875, 0.5, 1.875, 0),
        }}
      >
        <Box>
          <Button
            sx={{
              fontSize: "12px",
              fontFamily: "Noto Sans",
              mr: 2,
              borderRadius: "16px",
            }}
            type="submit"
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
          >
            {translations.Legend}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: -15,
              horizontal: -10,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                height: "56px",
                width: "650px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="20%"
              >
                <AvailableSeat />
                <Typography variant="body1" pl={"7px"}>
                  {translations.Available}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="15%"
              >
                <NotCheckInSeat />
                <Typography variant="body1" pl={"7px"}>
                  {translations.Reserved}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="25%"
              >
                <AccountCircleOutlinedIcon />
                <Typography variant="body1" pl={"7px"}>
                  {translations.CheckedIn}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="25%"
              >
                <UnavailableSeat />
                <Typography variant="body1" pl={"7px"}>
                  {translations.NotAvailable}
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box>
          <ZoomButtonBox color="primary">
            <MinusButton
              onClick={async () => {
                // 最小10%を超えないようにする
                if (
                  layoutImageRef.current?.state?.scale &&
                  layoutImageRef.current?.state?.scale <= 0.1
                ) {
                  return
                } else {
                  layoutImageRef.current?.setTransform(
                    x,
                    y,
                    layoutImageRef.current?.state.scale - 0.1
                  )
                }
              }}
            >
              ー
            </MinusButton>
            <PlusButton
              onClick={async () => {
                // 最大100%を超えないようにする
                if (
                  layoutImageRef.current?.state?.scale &&
                  layoutImageRef.current?.state?.scale >= 1.0
                ) {
                  return
                } else {
                  layoutImageRef.current?.setTransform(
                    x,
                    y,
                    layoutImageRef.current?.state.scale + 0.1
                  )
                }
              }}
            >
              ＋
            </PlusButton>
          </ZoomButtonBox>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <TransformWrapper
          disabled={false}
          minScale={0.1}
          maxScale={1.0}
          wheel={{ disabled: false, step: 0.05 }}
          panning={{
            disabled: false,
          }}
          initialPositionX={x}
          initialPositionY={y}
          initialScale={scale}
          ref={layoutImageRef}
        >
          {!layoutImg && <p>{translations.LayoutNot}</p>}
          <TransformComponent
            wrapperStyle={{
              width: "calc(100vw - 408px)",
              height: "calc(100vh - 228px)",
            }}
          >
            <img id="layout-image" src={layoutImg} />
            {seats.map((seat, index) => {
              return (
                <Box key={index}>
                  <EmployeeLayoutSeat
                    seat={seat}
                    setSelectedSeat={setSelectedSeat}
                  />
                </Box>
              )
            })}
            {meetingRooms.map((meetingRoom, index) => {
              return (
                <Box key={index}>
                  <EmployeeLayoutMeetingRoom
                    meetingRoom={meetingRoom}
                    setSelectedMeetingRoom={setSelectedMeetingRoom}
                  />
                </Box>
              )
            })}
            {areas.map((area, index) => (
              <Box key={index}>
                <EmployeeLayoutArea area={area} />
              </Box>
            ))}
          </TransformComponent>
        </TransformWrapper>
      </Box>
    </>
  )
}
