import React from "react"

import { MobileAuthPageTemplate } from "../../../components/mobile/common/AuthPageTemplate"
import { ForgotPasswordAppliedMessage } from "../../../components/public/auth/ForgotPasswordAppliedMessage"

export const MobileForgotPasswordApplied = () => {
  return (
    <MobileAuthPageTemplate loading={false}>
      <ForgotPasswordAppliedMessage />
    </MobileAuthPageTemplate>
  )
}
