import { isSameDay } from "date-fns"

import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Typography, styled, Grid } from "@mui/material"

import { theme } from "../../../../config/theme"
import {
  MeetingRoomScheduleType,
  SignageMeetingRoomType,
} from "../../../../models/signage/useMeetingRoom/type"
import {
  dateTimeOnlyFormat,
  formatDateForSafari,
} from "../../../../utils/dateTimeFormat"
import {
  roundDownMinutesDate,
  differenceFromStartTimeToEndTime,
  findHourIndex,
  scheduleBarHeight,
  scheduleBarTopPosition,
  processMultiDaySchedule,
  describeTimeRange,
  isEndTimeAtMidnight,
} from "../../../../utils/schedules"

const defaultHeight = 36
const startPosition = 10

const CustomScheduleBox = styled(Grid)(() => ({
  position: "absolute",
  width: "90%",
  color: "white",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  cursor: "pointer",
  zIndex: "1001",
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: theme.spacing(0.3),
}))

// TODO:デザイン要確認＆useMediaQuery使うか考える
const CustomGrid = styled(Grid)(({ theme }) => ({
  textAlign: "right",
  fontSize: "1rem",
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.5rem",
  },
}))

interface Props {
  schedule: MeetingRoomScheduleType
  meetingRoomData: SignageMeetingRoomType | null
  meetingRoomCheckInUrl?: string
  meetingRoomCheckOutUrl?: string
}

// NaN表示になる問題解消のため、一度stringに変換
const setStartTime = (startTime: string) => {
  const startTimeString = startTime.toLocaleString()
  return new Date(startTimeString.replace(/-/g, "/"))
}

const setEndTime = (endTime: string) => {
  const endTimeString = endTime.toLocaleString()
  return new Date(endTimeString.replace(/-/g, "/"))
}

const currentPageDate = new Date()

export const MeetingRoomScheduleTable: React.FC<Props> = ({
  schedule,
  meetingRoomData,
}: Props) => {
  const [processedSchedules, setProcessedSchedules] = useState<
    { startTime: Date; endTime: Date }[] | null
  >(null)

  const navigate = useNavigate()
  const originalStartTime = setStartTime(schedule.start_time)
  const originalEndTime = setEndTime(schedule.end_time)
  const timeNow = new Date()

  useEffect(() => {
    if (
      schedule.start_time &&
      schedule.end_time &&
      schedule.start_time !== "" &&
      schedule.end_time !== "" &&
      meetingRoomData &&
      meetingRoomData.id !== 0
    ) {
      const result = processMultiDaySchedule(schedule)
      setProcessedSchedules(result)
    }
  }, [schedule, meetingRoomData])

  if (!processedSchedules || !meetingRoomData || meetingRoomData.id === 0) {
    return <div>Loading...</div>
  }

  return (
    <>
      {processedSchedules.map((processedSchedule) => {
        if (isSameDay(processedSchedule.startTime, currentPageDate)) {
          const startTimeString = processedSchedule.startTime.toISOString()
          const endTimeString = processedSchedule.endTime.toISOString()

          // formatDateForSafari 関数には文字列を渡す
          const startTime = new Date(formatDateForSafari(startTimeString))
          const endTime = new Date(formatDateForSafari(endTimeString))

          const diffHours = differenceFromStartTimeToEndTime(
            roundDownMinutesDate(startTime).getTime(),
            isEndTimeAtMidnight(endTime)
              ? endTime.getTime()
              : roundDownMinutesDate(endTime).getTime()
          )
          const hourIndex = findHourIndex(startTime)
          const height = scheduleBarHeight(diffHours, defaultHeight)
          const top = scheduleBarTopPosition(
            roundDownMinutesDate(startTime).getMinutes(),
            hourIndex,
            startPosition,
            defaultHeight
          )

          return (
            <>
              {meetingRoomData && meetingRoomData.id !== 0 && (
                <CustomScheduleBox
                  sx={{
                    height: `${height * 0.95}px`,
                    top: `${top}px`,
                  }}
                  onClick={() => {
                    {
                      navigate(
                        `/signage/meeting-rooms/${meetingRoomData.id}/schedules/${schedule.id}`
                      )
                    }
                  }}
                >
                  <Grid
                    item
                    xs={8.5}
                    sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
                  >
                    <CustomTypography>
                      {schedule.schedule_title}
                    </CustomTypography>
                  </Grid>
                  <CustomGrid item xs={3.5}>
                    {describeTimeRange(
                      originalStartTime,
                      originalEndTime,
                      timeNow
                    )}
                  </CustomGrid>
                </CustomScheduleBox>
              )}
            </>
          )
        }
      })}
    </>
  )
}
