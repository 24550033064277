import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { Box, Typography, styled, Grid } from "@mui/material"

import { CompanyLayoutAddMeetingRooms } from "../../../components/company/layouts/CompanyLayoutAddMeetingRooms"
import { CompanyLayoutAddSeats } from "../../../components/company/layouts/CompanyLayoutAddSeats"
import { CompanyLayoutAreaForm } from "../../../components/company/layouts/CompanyLayoutAreaForm"
import { CompanyLayoutFloorSelect } from "../../../components/company/layouts/CompanyLayoutFloorSelect"
import { CompanyLayoutForm } from "../../../components/company/layouts/CompanyLayoutForm"
import { CompanyLayoutMeetingRoomForm } from "../../../components/company/layouts/CompanyLayoutMeetingRoomForm"
import { CompanyLayoutMeetingRoomList } from "../../../components/company/layouts/CompanyLayoutMeetingRoomList"
import { CompanyLayoutSeatList } from "../../../components/company/layouts/CompanyLayoutSeatList"
import { CompanyLayoutSideberMenu } from "../../../components/company/layouts/CompanyLayoutSideberMenu"
import { CompanyLayoutSideberTemplate } from "../../../components/company/uiParts/CompanyLayoutSideberTemplate"
import { AlertContent } from "../../../components/public/AlertContent"
import { AlertError } from "../../../components/public/AlertError"
import { AlertSuccess } from "../../../components/public/AlertSuccess"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { theme } from "../../../config/theme"
import { useLayout } from "../../../models/company/useLayout"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import { useTag } from "../../../models/public/useTag"

export const CustomSideberBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  marginTop: "-24px",
})

export const CustomTabBox = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  padding: "10px 0",
  border: "1px solid #22BA9D",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 8px gray",
  },
})

export const CompanyLayoutCreatePage = () => {
  const urlParams = useParams<{
    company_id: string
    branch_id: string
    floor_id: string
    layout_id: string
  }>()

  const { tagCategories, appliances } = useTag()
  const { companyRelations, fetchCompanyRelations } = useCompanyRelations()
  const {
    branchId,
    floorId,
    layoutId,
    errorMessages,
    changeSelectValue,
    setLayoutImgBlob,
    setLayoutImg,
    layoutImg,
    setLayout,
    layout,
    handleAddSeats,
    seats,
    areaToSeats,
    setAreaToSeats,
    setSeats,
    handleDeleteSeats,
    meetingRoom,
    meetingRooms,
    setMeetingRoom,
    setMeetingRooms,
    handleAddMeetingRooms,
    handleUpdateMeetingRoom,
    handleDeleteMeetingRoomsOrAreas,
    meetingRoomForm,
    area,
    areas,
    setArea,
    setAreas,
    areaForm,
    sidebarLayoutComponent,
    setSidebarLayoutComponent,
    sidebarTabType,
    setSidebarTabType,
    seatTabColor,
    meetingRoomTabColor,
    handleCreateLayout,
    handleCopyLayout,
    handleSeatCoordinate,
    handleMeetingRoomCoordinate,
    handleAreaCoordinate,
    handleCreateArea,
    handleAreaSeats,
    handleUpdateArea,
    handleAreaToSeatsCoordinate,
    handleAddAreaSeat,
    handleDeleteAreaSeat,
    handlePausedLayout,
    handleDeleteLayout,
    handleRevertToDraftLayout,
    handleRevertToReleaseLayout,
    handleUnsuspendLayout,
    draftSuccessMessageObject,
    draftErrorMessageObject,
    loading,
    setCompanyId,
    setBranchId,
    setFloorId,
    setLayoutId,
    seatAllCheck,
    meetingRoomOrAreaAllCheck,
    resetAreaSeats,
    handleUpdateDefaultLayoutImage,
  } = useLayout()

  // レイアウトのサイドバーの表示
  const renderLayoutSidebar = () => {
    switch (sidebarLayoutComponent) {
      case "seatAdd":
        return (
          <CompanyLayoutAddSeats
            setSidebarLayoutComponent={setSidebarLayoutComponent}
            handleAddSeats={handleAddSeats}
            setSidebarTabType={setSidebarTabType}
          />
        )
      case "seatList":
        return (
          <CompanyLayoutSeatList
            seats={seats}
            setSeats={setSeats}
            areas={areas}
            setArea={setArea}
            setAreas={setAreas}
            setAreaToSeats={setAreaToSeats}
            setSidebarTabType={setSidebarTabType}
            setSidebarLayoutComponent={setSidebarLayoutComponent}
          />
        )
      case "meetingRoomAdd":
        return (
          <CompanyLayoutAddMeetingRooms
            setSidebarLayoutComponent={setSidebarLayoutComponent}
            handleAddMeetingRooms={handleAddMeetingRooms}
            setSidebarTabType={setSidebarTabType}
          />
        )
      case "meetingRoomList":
        return (
          <CompanyLayoutMeetingRoomList
            setMeetingRoom={setMeetingRoom}
            setArea={setArea}
            setSidebarTabType={setSidebarTabType}
            meetingRooms={meetingRooms}
            areas={areas}
            setAreas={setAreas}
            setAreaToSeats={setAreaToSeats}
            setMeetingRooms={setMeetingRooms}
            setSidebarLayoutComponent={setSidebarLayoutComponent}
          />
        )
      case "meetingRoomForm":
        return (
          <CompanyLayoutMeetingRoomForm
            companyRelations={companyRelations}
            meetingRoom={meetingRoom}
            meetingRoomForm={meetingRoomForm}
            handleUpdateMeetingRoom={handleUpdateMeetingRoom}
            tagCategories={tagCategories}
            appliances={appliances}
            setSidebarLayoutComponent={setSidebarLayoutComponent}
            setSidebarTabType={setSidebarTabType}
          />
        )
      case "areaForm":
        return (
          <CompanyLayoutAreaForm
            companyRelations={companyRelations}
            area={area}
            areaToSeats={areaToSeats}
            areaForm={areaForm}
            handleCreateArea={handleCreateArea}
            handleUpdateArea={handleUpdateArea}
            tagCategories={tagCategories}
            setSidebarLayoutComponent={setSidebarLayoutComponent}
            setSidebarTabType={setSidebarTabType}
            resetAreaSeats={resetAreaSeats}
          />
        )
    }
  }

  useEffect(() => {
    const companyRelation = async () => {
      const data = await fetchCompanyRelations()
      if (!data) return
      setCompanyId(data.id)
      setBranchId(Number(urlParams.branch_id))
      setFloorId(Number(urlParams.floor_id))
      setLayoutId(urlParams.layout_id ? Number(urlParams.layout_id) : 0)
    }
    companyRelation()
  }, [])

  if (loading) {
    return <Loading type="content" loading={loading} />
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <ContentTemplate>
          <ContentCompanyPaper>
            <Grid container spacing={3}>
              <Grid
                sx={{
                  paddingRight: theme.spacing(2),
                }}
                item
                sm={9}
              >
                <>
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    <Grid item sm={12}>
                      <CompanyLayoutFloorSelect
                        errorMessages={errorMessages}
                        layout={layout}
                        setLayout={setLayout}
                        changeSelectValue={changeSelectValue}
                        companyRelations={companyRelations}
                        branchId={branchId}
                        floorId={floorId}
                        layoutId={layoutId}
                        handleCreateLayout={handleCreateLayout}
                        handleCopyLayout={handleCopyLayout}
                        handlePausedLayout={handlePausedLayout}
                        handleDeleteLayout={handleDeleteLayout}
                        handleRevertToDraftLayout={handleRevertToDraftLayout}
                        handleRevertToReleaseLayout={
                          handleRevertToReleaseLayout
                        }
                        handleUnsuspendLayout={handleUnsuspendLayout}
                        fetchCompanyRelations={fetchCompanyRelations}
                      />
                    </Grid>
                  </Grid>
                  <CompanyLayoutForm
                    floorId={floorId}
                    setLayoutImgBlob={setLayoutImgBlob}
                    setLayoutImg={setLayoutImg}
                    layoutImg={layoutImg}
                    layout={layout}
                    setLayout={setLayout}
                    seats={seats}
                    areas={areas}
                    areaToSeats={areaToSeats}
                    handleSeatCoordinate={handleSeatCoordinate}
                    handleAreaCoordinate={handleAreaCoordinate}
                    handleMeetingRoomCoordinate={handleMeetingRoomCoordinate}
                    handleAreaToSeatsCoordinate={handleAreaToSeatsCoordinate}
                    meetingRooms={meetingRooms}
                    handleAddAreaSeat={handleAddAreaSeat}
                    handleDeleteAreaSeat={handleDeleteAreaSeat}
                    sidebarLayoutComponent={sidebarLayoutComponent}
                    handleUpdateDefaultLayoutImage={
                      handleUpdateDefaultLayoutImage
                    }
                  />
                </>
              </Grid>
              <Grid
                item
                sx={{ borderLeft: "1px solid #e3e3e3", marginTop: "25px" }}
                sm={3}
              >
                <CustomSideberBox>
                  <CustomTabBox
                    sx={{
                      backgroundColor: seatTabColor,
                      borderRadius: "8px 0 0 8px",
                    }}
                    onClick={() => {
                      setSidebarTabType("seat")
                      setSidebarLayoutComponent("seatList")
                    }}
                  >
                    <img
                      src={
                        sidebarTabType === "seat" ||
                        sidebarTabType === "seatForm" ||
                        sidebarTabType === "areaForm"
                          ? `${process.env.PUBLIC_URL}/images/icons/seat-white.svg`
                          : `${process.env.PUBLIC_URL}/images/icons/active-layout.svg`
                      }
                      height="20px"
                      width="20px"
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        color:
                          sidebarTabType === "seat" ||
                          sidebarTabType === "seatForm" ||
                          sidebarTabType === "areaForm"
                            ? "#FFFFFF"
                            : "#22BA9D",
                      }}
                    >
                      席
                    </Typography>
                  </CustomTabBox>
                  <CustomTabBox
                    sx={{
                      backgroundColor: meetingRoomTabColor,
                      borderRadius: "0 8px 8px 0",
                    }}
                    onClick={() => {
                      setSidebarTabType("meetingRoom")
                      setSidebarLayoutComponent("meetingRoomList")
                    }}
                  >
                    <img
                      src={
                        sidebarTabType === "meetingRoom" ||
                        sidebarTabType === "meetingRoomForm"
                          ? `${process.env.PUBLIC_URL}/images/icons/conference-white.svg`
                          : `${process.env.PUBLIC_URL}/images/icons/conference.svg`
                      }
                      height="20px"
                      width="20px"
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        color:
                          sidebarTabType === "meetingRoom" ||
                          sidebarTabType === "meetingRoomForm"
                            ? "#FFFFFF"
                            : "#22BA9D",
                      }}
                    >
                      会議室
                    </Typography>
                  </CustomTabBox>
                </CustomSideberBox>
                <CompanyLayoutSideberTemplate>
                  <CompanyLayoutSideberMenu
                    seats={seats}
                    sidebarTabType={sidebarTabType}
                    setSidebarTabType={setSidebarTabType}
                    handleDeleteSeats={handleDeleteSeats}
                    handleAreaSeats={handleAreaSeats}
                    handleDeleteMeetingRoomsOrAreas={
                      handleDeleteMeetingRoomsOrAreas
                    }
                    layoutImg={layoutImg}
                    setSidebarLayoutComponent={setSidebarLayoutComponent}
                    seatAllCheck={seatAllCheck}
                    meetingRoomOrAreaAllCheck={meetingRoomOrAreaAllCheck}
                  />
                  {layoutImg !== "" && renderLayoutSidebar()}
                </CompanyLayoutSideberTemplate>
              </Grid>
            </Grid>
          </ContentCompanyPaper>
        </ContentTemplate>
      </Box>
      <AlertSuccess
        isMessageOpen={draftSuccessMessageObject.isOpenMessage}
        closeMessage={draftSuccessMessageObject.closeMessage}
        alertMessage={draftSuccessMessageObject.alertMessage}
      />
      <AlertError
        isMessageOpen={draftErrorMessageObject.isOpenMessage}
        closeMessage={draftErrorMessageObject.closeMessage}
        alertMessage={draftErrorMessageObject.alertMessage}
      />
      <AlertContent />
    </>
  )
}
