import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import {
  Grid,
  Box,
  styled,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material"

import { useAkerun } from "../../../models/company/useAkerun"
import {
  AkerunBranchType,
  AkerunOrganizationResponseType,
} from "../../../models/company/useAkerun/type"
import { useBranch } from "../../../models/company/useBranch"
import { CancelButton } from "../../public/CancelButton"
import { ErrorText } from "../../public/ErrorText"
import { GreenArrowIcon } from "../../public/GreenArrowIcon"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 15, 5, 15),
}))

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

const branchFont = {
  color: "#22BA9D",
  fontSize: "20px",
  fontWeight: "bold",
  paddingTop: "30px",
  paddingLeft: "30px",
  //   width: "4%",
}

const branchWidth = {
  paddingLeft: "30px",
  width: "50%",
  fontSize: "15px",
}

const CustomSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "35px",
  fontSize: theme.spacing(2),
  backgroundColor: "#EEEEEE",
  border: "none",
  textOverflow: "ellipsis",
}))

export const Akerun = () => {
  const navigate = useNavigate()
  const { branchRelation, fetchBranchRelations } = useBranch()
  const {
    organizations,
    setOrganizations,
    fetchAkerunOrganization,
    onUpdateOrganizationSubmit,
  } = useAkerun()
  const [loading, setLoading] = useState<boolean>(true)
  // requestOrganizations配列のstateを保持
  const [requestOrganizations, setRequestOrganizations] = useState<
    AkerunOrganizationResponseType[]
  >([])

  //「更新」ボタン押下後、プルダウンの内容をチェック
  const [nullCheck, setNullCheck] = useState<boolean>(true)
  // 値を1つも変更していない場合は更新できないようにする。
  const checkSelect = () => {
    let hasChanges = false

    branchRelation.forEach((branch) => {
      const currentSelectedId =
        selectedOrganizationIds[branch.id] || branch.akerun_organization_id || 0
      const previousSelectedId = branch.akerun_organization_id || 0

      if (currentSelectedId !== previousSelectedId) {
        hasChanges = true
      }
    })

    if (!hasChanges) {
      setNullCheck(false)
    }
    return hasChanges
  }

  const [selectedOrganizationIds, setSelectedOrganizationIds] = useState<
    Record<number, number>
  >({})

  useEffect(() => {
    const branchState = async () => {
      await Promise.all([fetchBranchRelations(), fetchAkerunOrganization()])
    }
    branchState().then(() => {
      setLoading(false)
    })
  }, [])

  // 以前選択してた事業所名を変更せず更新しても値を保持
  useEffect(() => {
    branchRelation.forEach((branch) => {
      const selectedId =
        selectedOrganizationIds[branch.id] || branch.akerun_organization_id
      if (selectedId) {
        const targetOrganization = organizations.find(
          (organization) => organization.id === selectedId
        )
        if (targetOrganization) {
          const existingIndex = requestOrganizations.findIndex(
            (org) => org.branch_id === branch.id
          )
          const newEntry = {
            id: targetOrganization.id,
            name: targetOrganization.name,
            branch_id: branch.id,
            branch_name: branch.branch_name,
          }
          if (existingIndex === -1) {
            setRequestOrganizations((prev) => [...prev, newEntry])
          } else {
            setRequestOrganizations((prev) =>
              prev.map((org, index) =>
                index === existingIndex ? newEntry : org
              )
            )
          }
        }
      }
    })
  }, [selectedOrganizationIds])

  return (
    <>
      {loading ? (
        <>
          <Loading type="content" />
        </>
      ) : (
        <>
          {!nullCheck && (
            <ErrorMessageContainer>
              {ErrorText(["最低１つ拠点を紐付けてください"])}
            </ErrorMessageContainer>
          )}
          <CustomGridContainer container spacing={0}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={branchFont}>拠点（WORK AGILE）</TableCell>
                    <TableCell style={branchFont}>事業所名（Akerun）</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branchRelation.map((branch, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={branchWidth}>
                          {branch.branch_name}
                        </TableCell>
                        <TableCell style={branchWidth}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="branch_id">
                              事業所名 *
                            </InputLabel>
                            <CustomSelect
                              value={
                                selectedOrganizationIds[branch.id] ||
                                branch.akerun_organization_id ||
                                0
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                              }}
                              IconComponent={GreenArrowIcon}
                              label="事業所名 *"
                              labelId="事業所名 *"
                              onChange={(e) => {
                                // 現在選択してる事業所の値を更新する
                                setSelectedOrganizationIds(
                                  (prevSelectedOrganizationIds) => {
                                    const updatedSelectedOrganizationIds = {
                                      ...prevSelectedOrganizationIds,
                                      [branch.id]: e.target.value as number,
                                    }
                                    return updatedSelectedOrganizationIds
                                  }
                                )
                                // 選択された事業所
                                const targetOrganization = organizations.find(
                                  (organization) =>
                                    organization.id === e.target.value
                                )
                                // 変更なのかどうか
                                const result = requestOrganizations.some(
                                  (u) => u.branch_id === branch.id
                                )
                                if (targetOrganization) {
                                  if (!result) {
                                    // 変更でない場合、パラメータ用の配列に新規追加
                                    setRequestOrganizations(
                                      (prevRequestOrganizations) => [
                                        ...prevRequestOrganizations,
                                        {
                                          id: targetOrganization.id,
                                          name: targetOrganization.name,
                                          branch_id: branch.id,
                                          branch_name: branch.branch_name,
                                        },
                                      ]
                                    )
                                  } else {
                                    // 変更の場合、パラメータ用の配列の事業所の値を更新
                                    setRequestOrganizations(
                                      (prevRequestOrganizations) =>
                                        prevRequestOrganizations.map((obj) =>
                                          obj.branch_id === branch.id
                                            ? {
                                                ...obj,
                                                id: targetOrganization.id,
                                                name: targetOrganization.name,
                                              }
                                            : obj
                                        )
                                    )
                                  }
                                }
                              }}
                            >
                              <MenuItem key={0} value={0} disabled>
                                事業所名を選択
                              </MenuItem>
                              {organizations.map((organization) => (
                                <MenuItem
                                  key={organization.id}
                                  value={organization.id}
                                >
                                  {organization.name}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomGridContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <Grid item xs={4} sm={3}>
              <ReflectButton
                label="更新"
                handleClick={() => {
                  setNullCheck(true)
                  // 最低一つ事業所が選択されているかチェック
                  if (checkSelect()) {
                    // 更新処理を呼び出し
                    onUpdateOrganizationSubmit(requestOrganizations)
                    // 外部連携の設定画面に遷移
                    navigate("/company/settings/external-system-link")
                  }
                }}
              />
            </Grid>
            <CancelButton
              label="戻る"
              handleClick={() =>
                navigate("/company/settings/external-system-link")
              }
            />
          </Box>
        </>
      )}
    </>
  )
}
