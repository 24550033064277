import React, { useState, memo } from "react"

import { Box, Typography, TextField, Grid, styled, Button } from "@mui/material"

import {
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import { numberCheck } from "../../../../utils/numberCheck"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
})

export const CustomTypography = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 4px gray",
  },
})

const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
})

interface Props {
  handleAddMeetingRooms: (meetingRoomCount: number) => void
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
}

/* 
  レイアウトのサイドバーで会議室を追加
*/
export const CompanyLayoutAddMeetingRooms: React.FC<Props> = memo(
  ({
    handleAddMeetingRooms,
    setSidebarLayoutComponent,
    setSidebarTabType,
  }: Props) => {
    const [meetingRoomCount, setMeetingRoomCount] = useState<number>(0)

    return (
      <>
        <Box
          sx={{
            paddingBottom: "20px",
          }}
        >
          <CustomBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/conference.svg`}
              height="20px"
              width="20px"
            />
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              会議室の追加
            </Typography>
          </CustomBox>
        </Box>
        <CustomBox sx={{ marginBottom: "20px" }}>
          <Typography sx={{ color: "#707070" }} variant="caption">
            追加する会議室の数を入力し、保存してください。
          </Typography>
        </CustomBox>
        {/* <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "20px",
          }}
          container
          spacing={3}
        >
          <Grid item xs={12} sm={8}>
            <Typography variant="subtitle2">会議室の追加</Typography>
          </Grid>
          <Grid sx={{ color: "#F2A541" }} item xs={12} sm={4}>
            <CustomTypography
              onClick={() => setSidebarLayoutComponent("meetingRoomList")}
              variant="caption"
            >
              キャンセル
            </CustomTypography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ color: "#707070" }} variant="caption">
              追加する会議室の数を入力し、保存してください。
            </Typography>
          </Grid>
        </Grid> */}
        <CustomBox sx={{ marginBottom: "20px" }}>
          <CustomTextField
            id="meeting_room_count"
            name="meeting_room_count"
            required
            variant="outlined"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{ maxLength: 3 }}
            onChange={(e) => {
              setMeetingRoomCount(
                numberCheck(Number(e.target.value)) ? Number(e.target.value) : 0
              )
            }}
          />
          <Typography
            sx={{
              ml: 1,
              color: "#22BA9D",
              fontWeight: "bold",
            }}
            variant="subtitle1"
          >
            室
          </Typography>
        </CustomBox>
        <CustomBox sx={{ marginBottom: "40px" }}>
          <Button
            variant="outlined"
            fullWidth
            disabled={!meetingRoomCount}
            onClick={() => {
              setSidebarLayoutComponent("meetingRoomList")
              handleAddMeetingRooms(meetingRoomCount)
            }}
          >
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              追加
            </Typography>
          </Button>
        </CustomBox>
        <CustomBox sx={{ justifyContent: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/back.svg`}
            height="20px"
            width="20px"
          />
          <Typography
            sx={{
              ml: 1,
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
                boxShadow: "0 0 4px gray",
              },
            }}
            variant="subtitle1"
            onClick={() => {
              setSidebarTabType("meetingRoom")
              setSidebarLayoutComponent("meetingRoomList")
            }}
          >
            一覧へ戻る
          </Typography>
        </CustomBox>
      </>
    )
  }
)
