import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useRef, useState, useEffect } from "react"

import { Box, styled } from "@mui/material"

import {
  AreaType,
  MeetingRoomType,
  LayoutType,
} from "../../../../models/company/useDashboard/type"
import { SeatType } from "../../../../models/company/useDashboard/type"
import { CompanyDashboardArea } from "../CompanyDashboardArea"
import { CompanyDashboardMeetingRoom } from "../CompanyDashboardMeetingRoom"
import { CompanyDashboardSeat } from "../CompanyDashboardSeat"

const CompanyTransformContainer = styled(Box)(() => ({
  maxHeight: "555px",
  overflow: "auto",
}))

const EmployeeTransformContainer = styled(Box)(() => ({
  height: "calc(100vh - 370px)",
  overflow: "auto",
}))

interface Props {
  layoutImg: string
  layout: LayoutType
  seats: SeatType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
}

// 法人管理画面レイアウト表示部分
export const CompanyDashboardMain: React.FC<Props> = ({
  layoutImg,
  layout,
  seats,
  meetingRooms,
  areas,
}: Props) => {
  // アイコン凡例の表示
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [x, setX] = useState<number>(layout.x)
  const [y, setY] = useState<number>(layout.y)
  const [scale, setScale] = useState<number>(layout.scale)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  useEffect(() => {
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout])

  useEffect(() => {
    layoutImageRef.current?.setTransform(x, y, scale)
  }, [x, y, scale])

  return (
    <>
      <CompanyTransformContainer>
        <TransformWrapper
          disabled={false}
          minScale={0.1}
          maxScale={1.0}
          wheel={{ disabled: false, step: 0.05 }}
          panning={{
            disabled: false,
          }}
          initialPositionX={x}
          initialPositionY={y}
          initialScale={scale}
          ref={layoutImageRef}
        >
          <TransformComponent
            wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <img id="layout-image" src={layoutImg} />
            {seats.map((seat) => {
              return <CompanyDashboardSeat key={`seat${seat.id}`} seat={seat} />
            })}
            {meetingRooms.map((meetingRoom) => {
              return (
                <CompanyDashboardMeetingRoom
                  key={`meetingRoom${meetingRoom.id}`}
                  meetingRoom={meetingRoom}
                />
              )
            })}
            {areas.map((area) => {
              return (
                <>
                  <CompanyDashboardArea key={`area${area.id}`} area={area} />
                  {area.children.map((seat) => {
                    return (
                      <CompanyDashboardSeat
                        key={`seat${seat.id}`}
                        seat={seat}
                      />
                    )
                  })}
                </>
              )
            })}
          </TransformComponent>
        </TransformWrapper>
      </CompanyTransformContainer>
    </>
  )
}

// 従業員画面レイアウト表示部分
export const EmployeeLayoutMain: React.FC<Props> = ({
  layoutImg,
  layout,
  seats,
  meetingRooms,
  areas,
}: Props) => {
  // アイコン凡例の表示
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [x, setX] = useState<number>(layout.x)
  const [y, setY] = useState<number>(layout.y)
  const [scale, setScale] = useState<number>(layout.scale)

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  useEffect(() => {
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout])

  useEffect(() => {
    layoutImageRef.current?.setTransform(x, y, scale)
  }, [x, y, scale])

  return (
    <>
      <EmployeeTransformContainer>
        <TransformWrapper
          disabled={false}
          minScale={0.1}
          maxScale={1.0}
          wheel={{ disabled: false, step: 0.05 }}
          panning={{
            disabled: false,
          }}
          initialPositionX={x}
          initialPositionY={y}
          initialScale={scale}
          ref={layoutImageRef}
        >
          <TransformComponent
            wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <img id="layout-image" src={layoutImg} />
            {seats.map((seat) => {
              return <CompanyDashboardSeat key={`seat${seat.id}`} seat={seat} />
            })}
            {meetingRooms.map((meetingRoom) => {
              return (
                <CompanyDashboardMeetingRoom
                  key={`meetingRoom${meetingRoom.id}`}
                  meetingRoom={meetingRoom}
                />
              )
            })}
            {areas.map((area) => {
              return (
                <>
                  <CompanyDashboardArea key={`area${area.id}`} area={area} />
                  {area.children.map((seat) => {
                    return (
                      <CompanyDashboardSeat
                        key={`seat${seat.id}`}
                        seat={seat}
                      />
                    )
                  })}
                </>
              )
            })}
          </TransformComponent>
        </TransformWrapper>
      </EmployeeTransformContainer>
    </>
  )
}
