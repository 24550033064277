import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"

import React, { useEffect } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Grid,
  Typography,
  styled,
  Box,
  IconButton,
  Slider,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { useSyncLanguageAcrossTabs } from "../../../../../src/utils/useSyncLanguageAcrossTabs"
import { useLanguage } from "../../../../contexts/LanguageContext"
import {
  MobileStatusListRequestType,
  MobileStatusListResponseType,
} from "../../../../models/mobile/useTeamSearch/type"
import enTranslations from "../../../../translations/mobileTeamSearch/en"
import jaTranslations from "../../../../translations/mobileTeamSearch/ja"
import { fifteenMinutesIntervalHours } from "../../../../utils/hours"
import { useOpen } from "../../../../utils/isOpen"

const CustomBoldTypography = styled(Typography)({
  letterSpacing: "0.8px",
  fontWeight: "bold",
})

interface Props {
  loading: boolean
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  startDate: Date
  endDate: Date
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>

  dayOfWeek: string[]
  formatDate: (date: Date) => string
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  timeFilterSchedule: (date: string, time: string) => void
  fetchMobileScheduleStatusList: (
    params: MobileStatusListRequestType
  ) => Promise<void>
  selectedTeamIds: number[]
  selectedEmployeeGroupIds: number[]
  selectedEmployeeIds: number[]
  scheduleParDateList: MobileStatusListResponseType[]
  setSliderPosition: () => void
}

export const MobileTeamSearchHeader: React.FC<Props> = ({
  loading,
  date,
  setDate,
  setStartDate,
  startDate,
  endDate,
  setEndDate,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  weekdayNumber,
  setWeekdayNumber,
  dayOfWeek,
  formatDate,
  timeIndex,
  setTimeIndex,
  timeFilterSchedule,
  fetchMobileScheduleStatusList,
  selectedTeamIds,
  selectedEmployeeGroupIds,
  selectedEmployeeIds,
  scheduleParDateList,
  setSliderPosition,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useSyncLanguageAcrossTabs()

  const month = date.getMonth() + 1
  const day = date.getDate()
  const scheduleCalendar = useOpen()
  const weekText = "(" + dayOfWeek[weekdayNumber] + ")"

  const time = fifteenMinutesIntervalHours[timeIndex]

  // 時刻を切り替えた時の挙動
  const handleTimeChange = (event: Event, value: number | number[]) => {
    if (typeof value === "number") {
      setTimeIndex(value)
      const time = fifteenMinutesIntervalHours[value]
      timeFilterSchedule(formatDate(date), time)
    }
  }
  useEffect(() => {
    setSliderPosition()
  }, [])

  // 日付を切り替えた場合の挙動
  useEffect(() => {
    // if (date < startDate || date > endDate) {
    //   const newStartDate = new Date(
    //     date.getFullYear(),
    //     date.getMonth(),
    //     date.getDate() - 1
    //   )
    //   const newEndDate = new Date(
    //     date.getFullYear(),
    //     date.getMonth(),
    //     date.getDate() + 1
    //   )
    //   fetchMobileScheduleStatusList({
    //     start_date: formatDate(newStartDate),
    //     end_date: formatDate(newEndDate),
    //     team_ids: selectedTeamIds,
    //     employee_group_ids: selectedEmployeeGroupIds,
    //     employee_ids: selectedEmployeeIds,
    //   })
    //   setStartDate(newStartDate)
    //   setEndDate(newEndDate)
    // } else {
    //   timeFilterSchedule(formatDate(date), time)
    // }

    const newStartDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    )
    const newEndDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    )
    fetchMobileScheduleStatusList({
      start_date: formatDate(newStartDate),
      end_date: formatDate(newEndDate),
      team_ids: selectedTeamIds,
      employee_group_ids: selectedEmployeeGroupIds,
      employee_ids: selectedEmployeeIds,
    })
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }, [date])

  useEffect(() => {
    if (scheduleParDateList.length > 0 && !loading) {
      timeFilterSchedule(formatDate(date), time)
    }
  }, [scheduleParDateList])

  // dateにカレンダーで選択された日付を設定する。
  const handleDateChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setDate(new Date(newValue))
    }
  }

  // weekdayにカレンダーで選択された日付の曜日を設定する。
  const handleWeekdayChange = (newValue: Date | null) => {
    if (newValue !== null) {
      setWeekdayNumber(newValue.getDay())
    }
  }

  return (
    <>
      <Grid item container alignItems="center" justifyContent="center">
        <IconButton
          onClick={() => {
            previousDate()
            previousWeekday()
          }}
        >
          <ChevronLeftIcon color="primary" fontSize="large" />
        </IconButton>
        <Box>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={language === "ja" ? ja : enUS}
          >
            <DesktopDatePicker
              label="DatePicker"
              value={date}
              open={scheduleCalendar.isOpen}
              onChange={(event) => {
                handleDateChange(event)
                handleWeekdayChange(event)
                scheduleCalendar.close()
              }}
              onClose={scheduleCalendar.close}
              slots={{
                textField: () => (
                  <CustomBoldTypography
                    variant="h6"
                    onClick={scheduleCalendar.open}
                  >
                    {`${month}${translations.month}${day}${translations.day} ${translations.weekdays[weekdayNumber]}`}
                  </CustomBoldTypography>
                ),
                previousIconButton: IconButton,
                nextIconButton: IconButton,
              }}
              slotProps={{
                popper: {
                  sx: { marginTop: "200px", marginLeft: "30px" },
                },
                textField: {
                  onClick: scheduleCalendar.open,
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        <IconButton
          onClick={() => {
            nextDate()
            nextWeekday()
          }}
        >
          <ChevronRightIcon color="primary" fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item container justifyContent="center" mt={1}>
        <Grid item xs={10}>
          <Slider
            aria-label="Always visible"
            max={fifteenMinutesIntervalHours.length - 1}
            step={1}
            valueLabelDisplay="on"
            valueLabelFormat={fifteenMinutesIntervalHours[timeIndex]}
            value={timeIndex}
            onChange={handleTimeChange}
          />
        </Grid>
      </Grid>
    </>
  )
}
