import React from "react"

import { Box, Grid, Modal, styled, CircularProgress } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { SearchedEmployeeResult } from "../../../../../models/employee/useSchedule/scheduleSearchEmployeeForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleSideSearch/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleSideSearch/ja"
import { CancelButton } from "../../../../public/CancelButton"
import { ReflectButton } from "../../../../public/ReflectButton"
import { SearchedEmployeeList } from "../EmployeeScheduleSearchedEmployeeList"

const EmployeeSearchModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(125),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
}))

const LoadingBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(125),
  height: theme.spacing(80),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
}))

interface Props {
  isSearchModalOpen: boolean
  handleClose: () => void
  handleFixAndClose: () => void
  handleEmployeeChecked: (selectedEmployeeId: number) => void
  searchedEmployeeResult: SearchedEmployeeResult
  loading: boolean
}

// 検索対象者一覧
export const EmployeeScheduleSearchedModal = ({
  isSearchModalOpen,
  handleClose,
  handleFixAndClose,
  searchedEmployeeResult,
  handleEmployeeChecked,
  loading,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <Modal open={isSearchModalOpen} onClose={handleClose} disableAutoFocus>
      <>
        {loading ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <EmployeeSearchModalOverlay>
            <Box
              alignItems="center"
              sx={{
                height: "80%",
              }}
            >
              <SearchedEmployeeList
                employees={searchedEmployeeResult}
                handleEmployeeChecked={handleEmployeeChecked}
              />
            </Box>
            <Grid container justifyContent="center" spacing={3} mt={3}>
              <Grid
                item
                sm={4}
                sx={{
                  textAlign: "center",
                }}
              >
                <CancelButton
                  label={translations.Cancel}
                  handleClick={handleClose}
                />
              </Grid>
              <Grid
                item
                sm={4}
                sx={{
                  textAlign: "center",
                }}
              >
                <ReflectButton
                  label={translations.Enter}
                  handleClick={handleFixAndClose}
                />
              </Grid>
            </Grid>
          </EmployeeSearchModalOverlay>
        )}
      </>
    </Modal>
  )
}
