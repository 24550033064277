const ja = {
  Location: "拠点",
  Floor: "フロア",
  AllDay: "終日",
  Month: "月",
  Day: "日",
  Available: "予約・利用可",
  Reserved: "予約中",
  CheckedIn: "チェックイン中",
  NotAvailable: "予約・利用不可",
  Legend: "アイコン凡例",
  ConfirmReservation: "予約する",
  Attendee: "対象者",
  DragDrop: "ドラッグ＆ドロップ",
  SelectedDesk: "選択された席",
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  NoTitle: "タイトルなし",
  Reserve: "座席予約してもよろしいですか？",
  edit: "編集に戻る",
  LayoutNot: "現在レイアウトが公開されていません。管理者様にご確認ください。",
}
export default ja
