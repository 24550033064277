import React from "react"

import { Box } from "@mui/material"

import { CalendarNewComponent } from "../../../../components/employee/calendar/new"
import { ContentEmployeePaper } from "../../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../../components/public/ContentTemplate"
import { SchedulableMaxDateProvider } from "../../../../providers/SchedulableMaxDateProvider"

export const CalendarNew = () => {
  return (
    <ContentTemplate>
      <ContentEmployeePaper>
        <SchedulableMaxDateProvider>
          <CalendarNewComponent />
        </SchedulableMaxDateProvider>
      </ContentEmployeePaper>
    </ContentTemplate>
  )
}
