import React from "react"

import { Box, Typography } from "@mui/material"

interface Props {
  meeting_room_name: string
  capacity: number
}

export const MeetingRoomScheduleSideBar: React.FC<Props> = ({
  meeting_room_name,
  capacity,
}: Props) => {
  return (
    <>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          borderTop={1}
          borderLeft={1}
          borderBottom={1}
          borderColor="#C1C1C1"
          paddingLeft={1}
          sx={{
            height: "4rem",
            lineHeight: "4rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Noto Sans",
              letterSpacing: "0.7",
            }}
          >
            <span>{meeting_room_name}</span>
            <span style={{ paddingLeft: "1rem" }}>{capacity}名</span>
          </Typography>
        </Box>
      </Box>
    </>
  )
}
