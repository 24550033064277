import { api, ApiResponse } from "../config/axiosConfig"

export const inviteUsersRequest = async (params: {
  emails: string[]
  send_status: boolean[]
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("company/azuread/invite_users", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
