import { WorkHourResponseType } from "../../models/company/useWorkHour/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchWorkHourRequest = async (): Promise<
  ApiResponse<WorkHourResponseType>
> => {
  try {
    const data = await api.get("/company/work_hour")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
