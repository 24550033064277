import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState, useCallback } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useParams } from "react-router-dom"

import {
  createFloorRequest,
  updateFloorRequest,
} from "../../../api/company/floorRequest"
import { HALF_WIDTH_NUMBER } from "../../../utils/regular"
import { FloorFormType } from "./type"

const defaultFloor: FloorFormType = {
  floor_number: 0,
  attendance_restriction_rate: 0,
  floor_name: "",
  underground_flag: false,
  id: 0,
  branch_id: 0,
}

export const useFloor = () => {
  const urlParams = useParams<{ id: string }>()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState<boolean>(false)

  const basicSchema = Yup.object().shape({
    floor_name: Yup.string().required("必須になります"),
    floor_number: Yup.string()
      .required("必須になります")
      .matches(HALF_WIDTH_NUMBER, "数字のみ入力してください"),
    underground_flag: Yup.string().required("必須になります"),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      floor_number: defaultFloor.floor_number,
      floor_name: defaultFloor.floor_name,
      underground_flag: defaultFloor.underground_flag,
      attendance_restriction_rate: defaultFloor.attendance_restriction_rate,
      id: defaultFloor.id,
      branch_id: Number(urlParams.id),
    },
    resolver: yupResolver(basicSchema),
  })

  const onCreateSubmit: SubmitHandler<FloorFormType> = useCallback(
    async (data) => {
      const { error } = await createFloorRequest({
        ...data,
        id: undefined, // 新規登録なのでidはundefinedで送信(これがないとid=0になる)
      })
      if (!error) {
        // 成功メッセージの表示
        setShowSuccessMessage(true)
        setSuccessMessage("フロアの登録が完了しました")
        setIsCreateFormOpen(false) // フォームを非表示
        // フォームの入力内容をリセット
        reset({
          floor_number: defaultFloor.floor_number,
          floor_name: defaultFloor.floor_name,
          underground_flag: defaultFloor.underground_flag,
          attendance_restriction_rate: defaultFloor.attendance_restriction_rate,
          id: defaultFloor.id,
          branch_id: Number(urlParams.id),
        })
        // エラーメッセージをリセット
        setErrorMessages([])
        setErrorMessageOpen(false)
      } else {
        setErrorMessages(error)
        setErrorMessageOpen(true)
      }
    },
    []
  )

  const onUpdateSubmit: SubmitHandler<FloorFormType> = useCallback(
    async (data) => {
      const { error } = await updateFloorRequest({
        ...data,
      })
      if (!error) {
        setShowSuccessMessage(true)
        setSuccessMessage("フロアの更新が完了しました")
        setIsUpdateFormOpen(false)
        reset({
          floor_number: defaultFloor.floor_number,
          floor_name: defaultFloor.floor_name,
          underground_flag: defaultFloor.underground_flag,
          attendance_restriction_rate: defaultFloor.attendance_restriction_rate,
          id: defaultFloor.id,
          branch_id: Number(urlParams.id),
        })
        setErrorMessages([])
        setErrorMessageOpen(false)
      } else {
        setErrorMessages(error)
        setErrorMessageOpen(true)
      }
    },
    []
  )

  return {
    control,
    errors,
    isValid,
    errorMessageOpen,
    setErrorMessageOpen,
    errorMessages,
    handleSubmit,
    onCreateSubmit,
    onUpdateSubmit,
    getValues,
    setValue,
    showSuccessMessage,
    successMessage,
    setShowSuccessMessage,
    isCreateFormOpen,
    setIsCreateFormOpen,
    isUpdateFormOpen,
    setIsUpdateFormOpen,
  }
}
