import React from "react"

import { Typography, Alert, Grid } from "@mui/material"

export const WarningText: React.FC<string[]> = (warningMessages: string[]) => {
  return (
    <>
      <Grid
        item
        style={{ margin: "auto 0", display: "flex", justifyContent: "center" }}
      >
        <Alert severity="warning" sx={{ width: "80%", padding: "6px 14px" }}>
          {warningMessages.map((message, index) => {
            return (
              <Typography component="p" variant="caption" key={index}>
                {message}
              </Typography>
            )
          })}
        </Alert>
      </Grid>
    </>
  )
}
