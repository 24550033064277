import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { Grid, Box, Typography, styled, Button } from "@mui/material"
import Item from "@mui/material/Grid"

import { BranchType } from "../../../../models/company/useBranch/type"
import { RoleContext } from "../../../../providers/RoleProvider"
import { prefectureList, Prefecture } from "../../../../support/prefecture"
import { Paging } from "../../../public/Pagination"
import { DeleteBranchModal } from "./DeleteBranchModal"

const CustomButton = styled(Box)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.spacing(2),
  textAlign: "center",
}))

const CustomBranchItem = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  color: "text.primary",
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1.875, 1.875, 0),
  marginLeft: theme.spacing(1.875),
  marginBottom: theme.spacing(2),
  background: "#FFFFFF",
  maxHeight: theme.spacing(29),
}))

const CustomLine = styled("hr")(() => ({
  height: "auto",
  border: "solid",
  color: "#E5E5E5",
  marginTop: "0px",
}))

const CustomPlusButton = styled(Button)(() => ({
  width: "100%",
  height: "100%",
}))

const CustomDeleteButton = styled(Button)({
  borderRadius: "50%",
  borderWidth: "2px",
  minWidth: "auto",
  width: "32px",
  height: "32px",
  padding: 0,
})

// TODO
// 後にコンポーネント化します
const CustomSummaryContainer = styled(Grid)(({ theme }) => ({
  height: "200px",
  width: "200px",
  marginTop: theme.spacing(0),
  marginLeft: theme.spacing(1),
}))

interface Props {
  branchRelation: BranchType[]
}

export const CompanyBranchList: React.FC<Props> = ({
  branchRelation,
}: Props) => {
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const DATA_COUNT_PER_PAGE = 20 // 1ページに表示するレコードの数
  const { isAdmin } = useContext(RoleContext)

  const [selectedBranch, setSelectedBranch] = useState<BranchType | undefined>(
    undefined
  )
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  const translatePrefecture = (prefectureId: number) => {
    return prefectureList.filter((prefectureHash: Prefecture) => {
      return prefectureHash.id === prefectureId
    })[0].name
  }
  // 席数合計
  let seat_sum = 0
  for (let i = 0; i < branchRelation.length; i++) {
    seat_sum += branchRelation[i].seat_count
  }

  // 現収容人数
  let seating = 0
  for (let i = 0; i < branchRelation.length; i++) {
    seating += branchRelation[i].restricted_seat_count
  }

  // 従業員数
  let number_of_employees = 0
  for (let i = 0; i < branchRelation.length; i++) {
    number_of_employees += branchRelation[i].employee_count
  }

  const navigate = useNavigate()
  return (
    <>
      <Box style={{ display: "flex", height: "100%" }}>
        <Grid container spacing={1.875} mt="0rem">
          <Grid container>
            {/* 法人管理者以外は新規登録画面に遷移させない */}
            {isAdmin && (
              <CustomBranchItem
                item
                xs={3.75}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  padding: "0",
                }}
              >
                <CustomPlusButton
                  onClick={() => navigate("/company/branches/new")}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/plus.svg`}
                  />
                </CustomPlusButton>
              </CustomBranchItem>
            )}
            {branchRelation
              .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
              .map((branch, index) => {
                return (
                  <CustomBranchItem key={index} item xs={3.75}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        id="branch_name"
                        gutterBottom
                        component="div"
                        color="primary"
                        fontWeight="bold"
                        fontSize={18}
                        textOverflow="ellipsis"
                        noWrap
                      >
                        {branch.branch_name}
                      </Typography>
                      <CustomDeleteButton
                        onClick={() => {
                          setIsOpenDeleteModal(true)
                          setSelectedBranch(branch)
                        }}
                        variant="outlined"
                      >
                        <DeleteOutlineIcon />
                      </CustomDeleteButton>
                    </Box>
                    <Typography
                      id="branch_postal_code"
                      variant="caption"
                      gutterBottom
                      component="div"
                    >
                      〒{branch.branch_postal_code}
                    </Typography>
                    <Typography
                      id="branch_city"
                      variant="caption"
                      component="div"
                      gutterBottom
                      textOverflow="ellipsis"
                      noWrap
                    >
                      <>
                        {translatePrefecture(branch.branch_prefecture_id)}
                        {branch.branch_city}
                        {branch.branch_house_number}
                        {branch.branch_building_name}
                      </>
                    </Typography>
                    <Grid container spacing={0.5}>
                      <Grid
                        item
                        container
                        xs={5.5}
                        justifyContent="space-around"
                      >
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            color="primary"
                            fontWeight="bold"
                            fontSize={12}
                          >
                            <Item>席数</Item>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontSize={12} gutterBottom align="right">
                            <Item id="capacity">{branch.seat_count}</Item>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={6.5}
                        justifyContent="space-around"
                      >
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            color="primary"
                            fontWeight="bold"
                            fontSize={12}
                          >
                            <Item>制限席数</Item>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontSize={12} gutterBottom align="right">
                            <Item id="capacity">
                              {branch.restricted_seat_count}
                            </Item>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={5.5}
                        justifyContent="space-around"
                      >
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            color="primary"
                            fontWeight="bold"
                            fontSize={12}
                          >
                            <Item>従業員数</Item>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontSize={12} gutterBottom align="right">
                            <Item>{branch.employee_count}</Item>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={6.5}
                        justifyContent="space-around"
                      >
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            color="primary"
                            fontWeight="bold"
                            fontSize={12}
                          >
                            <Item>拠点責任者</Item>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            fontSize={12}
                            gutterBottom
                            align="right"
                            textOverflow="ellipsis"
                            noWrap
                          >
                            {branch.branch_leader_name != "" && (
                              <>{branch.branch_leader_name}</>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <CustomButton sx={{ paddingTop: "0px" }}>
                      <Button
                        onClick={() => {
                          navigate(`/company/branches/${branch.id}`)
                        }}
                      >
                        <Typography fontWeight="bold">詳細を見る</Typography>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/icons/next.svg`}
                          style={{
                            marginLeft: "1rem",
                          }}
                        />
                      </Button>
                    </CustomButton>
                  </CustomBranchItem>
                )
              })}
          </Grid>
          <Box
            sx={{
              margin: "0 auto",
            }}
          >
            {branchRelation.length > DATA_COUNT_PER_PAGE && (
              <Paging
                pageCount={Math.ceil(
                  branchRelation.length / DATA_COUNT_PER_PAGE
                )}
                page={pageNumber}
                handlePaginationChange={handlePaginationChange}
              />
            )}
          </Box>
        </Grid>
        <CustomLine />
        <CustomSummaryContainer container item xs={2} spacing={3}>
          <Grid item xs={8}>
            <Typography color="primary" fontSize={12} fontWeight="bold">
              拠点
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingRight: "15px" }}>
            <Typography align="right" fontSize={12} fontWeight="bold">
              {branchRelation.length}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="primary" fontSize={12} fontWeight="bold">
              席
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingRight: "15px" }}>
            <Typography align="right" fontSize={12} fontWeight="bold">
              {seat_sum}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="primary" fontSize={12} fontWeight="bold">
              現収容人数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingRight: "15px" }}>
            <Typography align="right" fontSize={12} fontWeight="bold">
              {seating}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="primary" fontSize={12} fontWeight="bold">
              従業員
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingRight: "15px" }}>
            <Typography align="right" fontSize={12} fontWeight="bold">
              {number_of_employees}
            </Typography>
          </Grid>
        </CustomSummaryContainer>
      </Box>

      {isOpenDeleteModal && selectedBranch && (
        <DeleteBranchModal
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          isOpen={isOpenDeleteModal}
          setIsOpen={setIsOpenDeleteModal}
        />
      )}
    </>
  )
}
