import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useState, useRef, useEffect } from "react"
import { useDropzone, Accept } from "react-dropzone"
import Resizer from "react-image-file-resizer"
import { NumericFormat } from "react-number-format"

import {
  Box,
  Typography,
  Button,
  Popover,
  styled,
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
  Modal,
} from "@mui/material"

import { theme } from "../../../../config/theme"
import { SidebarLayoutComponent } from "../../../../models/company/useLayout"
import { MeetingRoomType } from "../../../../models/company/useLayout/type"
import {
  AreaType,
  LayoutType,
  PostStatus,
  SeatType,
} from "../../../../models/company/useLayout/type"
import { useAlertMessage } from "../../../../utils/isAlertMessage"
import { useOpen } from "../../../../utils/isOpen"
import { AlertError } from "../../../public/AlertError"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { FreeSeat } from "../../../public/FreeSeat"
import { ReflectButton } from "../../../public/ReflectButton"
import { ReservedSeat } from "../../../public/ReservedSeat"
import { UnavailableSeat } from "../../../public/UnavailableSeat"
import { CompanyLayoutArea } from "../CompanyLayoutArea"
import { CompanyLayoutMeetingRoom } from "../CompanyLayoutMeetingRoom"
import { CompanyLayoutSeat } from "../CompanyLayoutSeat"

const CustomImageBox = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    fontSize: "12px",
    padding: "5px",
  },
})

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
}))

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
})

const ZoomButtonBox = styled(Box)(() => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(() => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
  fontSize: "12px",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
  fontSize: "12px",
}))

const CustomLayoutModalDefaultSetting = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "50%",
  borderRadius: "10px",
}))

interface Props {
  floorId: number
  layout: LayoutType
  setLayout: React.Dispatch<React.SetStateAction<LayoutType>>
  setLayoutImgBlob: React.Dispatch<React.SetStateAction<Blob | undefined>>
  layoutImg: string
  setLayoutImg: React.Dispatch<React.SetStateAction<string>>
  seats: SeatType[]
  areaToSeats: SeatType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
  handleSeatCoordinate: (x: number, y: number, targetSeat: SeatType) => void
  handleAreaCoordinate: (
    targetArea: AreaType,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => void
  handleMeetingRoomCoordinate: (
    targetMeetingRoom: MeetingRoomType,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => void
  handleAreaToSeatsCoordinate: (
    x: number,
    y: number,
    targetSeat: SeatType
  ) => void
  sidebarLayoutComponent: SidebarLayoutComponent
  handleAddAreaSeat: (seat: SeatType) => void
  handleDeleteAreaSeat: (seat: SeatType) => void
  handleUpdateDefaultLayoutImage: (x: number, y: number, scale: number) => void
}

/*
  レイアウトのフォーム画面
*/
export const CompanyLayoutForm: React.FC<Props> = ({
  floorId,
  layout,
  setLayout,
  setLayoutImgBlob,
  layoutImg,
  setLayoutImg,
  seats,
  areaToSeats,
  meetingRooms,
  areas,
  handleSeatCoordinate,
  handleAreaCoordinate,
  handleMeetingRoomCoordinate,
  handleAreaToSeatsCoordinate,
  sidebarLayoutComponent,
  handleAddAreaSeat,
  handleDeleteAreaSeat,
  handleUpdateDefaultLayoutImage,
}: Props) => {
  const accept: Accept = { "image/*": [".jpeg", ".png"] }
  const [isMoveable, setIsMoveable] = useState<boolean>(false)
  const errorMessageObject = useAlertMessage()
  const modalErrorMessageObject = useAlertMessage()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null) // アイコン凡例の表示
  const [imageWidth, setImageWidth] = useState<number>(layout.width)
  const [imageHeight, setImageHeight] = useState<number>(layout.height)
  const [x, setX] = useState<number>(layout.x)
  const [y, setY] = useState<number>(layout.y)
  const [scale, setScale] = useState<number>(layout.scale)
  const [inputImageWidth, setInputImageWidth] = useState<number>(0) // 実寸入力時に使用する
  const [inputImageHeight, setInputImageHeight] = useState<number>(0) // 実寸入力時に使用する
  const [acceptedFile, setAcceptedFile] = useState<Blob>()
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  // 初期画面設定のモーダル用
  const { isOpen: ModalOpen, open: openModal, close: closeModal } = useOpen()
  // レイアウト画像サイズのモーダル用
  const {
    isOpen: isOpenSizeModal,
    open: openSizeModal,
    close: closeSizeModal,
  } = useOpen()

  // 初期画面表示
  const handleReset = () => {
    if (layoutImageRef.current) {
      layoutImageRef.current.setTransform(x, y, scale)
    }
  }

  // ドラッグアンドドロップで画像を画面に挿入する関数
  const onDrop = async <T extends File>(acceptedFiles: T[]) => {
    if (layoutImg !== "") {
      if (
        !window.confirm(
          "配置した席・会議室・エリアの位置が変更される可能性があります。よろしいでしょうか？"
        )
      ) {
        return
      }
    }

    if (floorId === 0) {
      errorMessageObject.openMessage()
      errorMessageObject.handleSetMessage("拠点とフロアを選択してください")
      return
    }

    setAcceptedFile(acceptedFiles[0] as Blob)

    openSizeModal()
  }

  /* 画像のドラッグアンドドロップの設定
    getInputProps・・・インプット要素のプロパティ
    getRootProps・・・ドラッグアンドドロップのプロパティ
    isDragActive・・・対象のエリアにドラッグされるとtrueになる
    isDragAccept・・・対象のエリアに正しい画像がドラッグされるとtrueになる
  */
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept,
      onDrop,
      maxFiles: 1,
    })

  /* 画像のリサイズ
    画像のリサイズに時間がかかる場合があるため、Promiseを使用しています
  */
  const resizeFile = (file: Blob, imageWidth: number, imageHeight: number) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file, // 画像ファイルのパス
        imageWidth, // 最大幅
        imageHeight, // 最大高さ
        "JPEG", // JPEGに変換
        100, // 圧縮の倍率。特に設定しない場合は、100になる。
        0, // 画像の回転角度
        (uri) => {
          resolve(uri)
        },
        "blob", // blobに変換
        imageWidth,
        imageHeight
      )
    })

  // 座席の編集可否の設定
  const handleMoveable = () => {
    setIsMoveable(!isMoveable)
  }

  // 画像登録
  const handleImageUpdate = async () => {
    if (inputImageWidth <= 0 || inputImageHeight <= 0) {
      modalErrorMessageObject.openMessage()
      modalErrorMessageObject.handleSetMessage(
        "実寸の横・縦を1以上で入力してください"
      )
      return
    }

    if (!acceptedFile) {
      errorMessageObject.openMessage()
      errorMessageObject.handleSetMessage(
        "画像のアップロードに失敗しました。もう一度お試しください。"
      )
      closeSizeModal()
      return
    }

    try {
      const createObjectURL = (window.URL || window.webkitURL).createObjectURL // 画像オブジェクトを作成
      const width = (inputImageWidth / 0.7) * 60
      const height = (inputImageHeight / 0.7) * 60
      const image = await resizeFile(acceptedFile, width, height) // 画像リサイズ
      setLayoutImgBlob(image as Blob)
      setLayoutImg(createObjectURL(image as Blob | MediaSource))
      setImageWidth(inputImageWidth)
      setImageHeight(inputImageHeight)
      setLayout({
        ...layout,
        isNewLayoutImage: true,
        width: inputImageWidth,
        height: inputImageHeight,
      })
      setIsMoveable(false)
    } catch (err) {
      alert(".jpeg.pngの画像を選択してください")
    }
    closeSizeModal()
  }

  // 拡大縮小するときにTransformWrapperのdisableをtrue→falseに変換する必要がある
  const handleZoomInOutDisable = () => {
    setIsMoveable(false)
  }

  const handleZoomInOutEnable = () => {
    setIsMoveable(true)
  }

  const color = isDragAccept ? "green" : "red"
  const border = `${isDragActive ? "dashed" : "none"} 2px ${color}`

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? "simple-popover" : undefined

  useEffect(() => {
    setImageWidth(layout.width)
    setImageHeight(layout.height)
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout])

  const reversedSeats = [...seats].reverse()

  return (
    <>
      <Modal open={isOpenSizeModal} onClose={closeSizeModal}>
        <CustomLayoutModal>
          <Box sx={{ padding: "30px 30px 20px" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "20px",
              }}
              variant="h6"
            >
              実寸を入力してください
            </Typography>
            {modalErrorMessageObject.isOpenMessage && (
              <ErrorMessageContainer textAlign="center">
                {ErrorText([modalErrorMessageObject.alertMessage])}
              </ErrorMessageContainer>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "450px",
                m: "30px auto",
              }}
            >
              <NumericFormat
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                suffix="m"
                allowNegative={true}
                decimalScale={1}
                maxLength={5}
                fixedDecimalScale={true}
                onValueChange={(values) => {
                  setInputImageWidth(Number(values.value))
                }}
                customInput={TextField}
                size="small"
                label="横"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ width: "100px" }}
              />
              <Box>
                <span>&times;</span>
              </Box>
              <NumericFormat
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                suffix="m"
                allowNegative={true}
                decimalScale={1}
                maxLength={5}
                fixedDecimalScale={true}
                onValueChange={(values) => {
                  setInputImageHeight(Number(values.value))
                }}
                customInput={TextField}
                size="small"
                label="縦"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ width: "100px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "450px",
                m: "30px auto",
              }}
            >
              <CancelButton label="キャンセル" handleClick={closeSizeModal} />
              <ReflectButton
                label="確定する"
                handleClick={async () => {
                  await handleImageUpdate()
                }}
              />
            </Box>
          </Box>
        </CustomLayoutModal>
      </Modal>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0px",
        }}
      >
        {layoutImg !== "" && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Grid style={{ marginRight: "5px" }}>
                <Typography fontSize="12px">実寸</Typography>
              </Grid>
              <Grid>
                <CustomTextField
                  disabled
                  required
                  label="横"
                  name="image_width"
                  type="number"
                  variant="outlined"
                  value={imageWidth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "78px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid style={{ margin: "0 10px" }}>
                <InputLabel>&times;</InputLabel>
              </Grid>
              <Grid>
                <CustomTextField
                  disabled
                  required
                  label="縦"
                  name="image_height"
                  type="number"
                  variant="outlined"
                  value={imageHeight}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "78px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {/* レイアウトが下書き中または未登録の時 */}
              {(layout.post_status === PostStatus.draft ||
                layout.post_status === PostStatus.not_saved) && (
                <CustomBox
                  sx={{ mr: 1, cursor: "pointer" }}
                  {...getRootProps()}
                >
                  <input style={{ marginLeft: "10px" }} {...getInputProps()} />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/upload.svg`}
                    height="20px"
                    width="20px"
                  />
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#22BA9D",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                    variant="subtitle1"
                    onClick={() => handleMoveable()}
                  >
                    レイアウト画像変更
                  </Typography>
                </CustomBox>
              )}
              {/* レイアウトが未登録以外の時 */}
              {layout.post_status !== PostStatus.not_saved && (
                <>
                  <Box sx={{ margin: "0 0 0 5px" }}>
                    <Button
                      sx={{ borderRadius: "16px", fontSize: "12px" }}
                      type="button"
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleReset}
                    >
                      初期画面表示
                    </Button>
                  </Box>
                  <Box sx={{ margin: "0 10px 0 5px" }}>
                    <Button
                      sx={{ borderRadius: "16px", fontSize: "12px" }}
                      type="button"
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={openModal}
                    >
                      初期画面設定
                    </Button>
                  </Box>
                </>
              )}

              {/* 初期画面設定用モーダル */}
              <Modal open={ModalOpen} onClose={closeModal}>
                <CustomLayoutModalDefaultSetting>
                  <Box sx={{ padding: "30px 30px 20px" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      variant="h6"
                    >
                      対象フロアのユーザー画面での表示位置を現在表示されている位置・倍率に変更します。
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      variant="h6"
                      width="100%"
                    >
                      よろしいですか？
                    </Typography>
                    {/* <Grid
                      container
                      sx={{ display: "flex", justifyContent: "left" }}
                    > */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "450px",
                        m: "30px auto",
                      }}
                    >
                      <CancelButton label="いいえ" handleClick={closeModal} />
                      <ReflectButton
                        label="はい"
                        disabled={layoutImageRef.current == null}
                        handleClick={async () => {
                          if (layoutImageRef.current) {
                            const x = layoutImageRef.current.state.positionX
                            const y = layoutImageRef.current.state.positionY
                            const scale = layoutImageRef.current.state.scale
                            await handleUpdateDefaultLayoutImage(x, y, scale)
                          }
                          closeModal()
                        }}
                      />
                    </Box>
                  </Box>
                </CustomLayoutModalDefaultSetting>
              </Modal>

              <Button
                sx={{ borderRadius: "16px" }}
                type="submit"
                variant={isMoveable ? "contained" : "outlined"}
                color="primary"
                size="small"
                onClick={() => handleMoveable()}
              >
                <img
                  src={
                    isMoveable
                      ? `${process.env.PUBLIC_URL}/images/icons/pencil-white.svg`
                      : `${process.env.PUBLIC_URL}/images/icons/pencil.svg`
                  }
                  height="15px"
                  width="15px"
                />

                <Typography
                  sx={{
                    ml: 1,
                    color: isMoveable ? "#FFFFFF" : "#22BA9D",
                    fontSize: "12px",
                  }}
                  variant="body2"
                >
                  編集
                </Typography>
              </Button>
              <Box sx={{ margin: "0 10px" }}>
                <Button
                  sx={{ borderRadius: "16px", fontSize: "12px" }}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleClick}
                >
                  アイコン凡例
                </Button>
              </Box>
              <ZoomButtonBox color="primary">
                <MinusButton
                  onClick={async () => {
                    // 最小10%を超えないようにする
                    if (
                      layoutImageRef.current?.state?.scale &&
                      layoutImageRef.current?.state?.scale <= 0.1
                    ) {
                      return
                    } else {
                      await handleZoomInOutDisable()
                      await layoutImageRef.current?.setTransform(
                        x,
                        y,
                        layoutImageRef.current?.state.scale - 0.1
                      )
                      await handleZoomInOutEnable()
                    }
                  }}
                >
                  ー
                </MinusButton>
                <PlusButton
                  onClick={async () => {
                    // 最大100%を超えないようにする
                    if (
                      layoutImageRef.current?.state?.scale &&
                      layoutImageRef.current?.state?.scale >= 1.0
                    ) {
                      return
                    } else {
                      await handleZoomInOutDisable()
                      await layoutImageRef.current?.setTransform(
                        x,
                        y,
                        layoutImageRef.current?.state.scale + 0.1
                      )
                      await handleZoomInOutEnable()
                    }
                  }}
                >
                  ＋
                </PlusButton>
              </ZoomButtonBox>
            </Box>
          </>
        )}
      </Box>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -20,
          horizontal: -10,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            height: "56px",
            width: "400px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <ReservedSeat />
          <Typography variant="body1">指定席</Typography>
          <FreeSeat />
          <Typography variant="body1">自由席</Typography>
          <UnavailableSeat />
          <Typography variant="body1">利用不可</Typography>
        </Box>
      </Popover>
      {/* </Box> */}
      {layoutImg === "" ? (
        <Box
          width="100%"
          height="100%"
          border={border}
          style={{ alignItems: "center" }}
        >
          <CustomImageBox {...getRootProps()}>
            <input id="layout-image" {...getInputProps()} />
            {isDragActive ? (
              <Typography>.jpeg.pngの画像を置いてください</Typography>
            ) : (
              <Box width={500} height={180} style={{ textAlign: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/upload.svg`}
                  style={{
                    height: "60%",
                    width: "180px",
                  }}
                />
                <Typography sx={{ mt: 1 }} gutterBottom>
                  このフロアのレイアウトはまだアップロードされていません
                </Typography>
                <Typography>
                  レイアウト図をドラッグアンドドロップしてください
                </Typography>
              </Box>
            )}
          </CustomImageBox>
        </Box>
      ) : (
        <TransformWrapper
          disabled={isMoveable}
          minScale={0.1}
          maxScale={1.0}
          wheel={{ disabled: false, step: 0.05 }}
          panning={{
            disabled: false,
          }}
          initialPositionX={x}
          initialPositionY={y}
          initialScale={scale}
          ref={layoutImageRef}
        >
          <>
            <TransformComponent
              wrapperStyle={{ maxWidth: "100%", maxHeight: "600px" }}
            >
              <img id="layout-image" src={layoutImg} />
              {reversedSeats.map((seat) => {
                return (
                  <CompanyLayoutSeat
                    key={`seat${seat.id}`}
                    seat={seat}
                    isSeatMoveable={isMoveable}
                    handleSeatCoordinate={handleSeatCoordinate}
                    sidebarLayoutComponent={sidebarLayoutComponent}
                    handleAddAreaSeat={handleAddAreaSeat}
                    handleDeleteAreaSeat={handleDeleteAreaSeat}
                  />
                )
              })}
              {meetingRooms.map((meetingRoom) => {
                return (
                  <CompanyLayoutMeetingRoom
                    key={`meetingRoom${meetingRoom.id}`}
                    meetingRoom={meetingRoom}
                    isMeetingRoomMoveable={isMoveable}
                    handleMeetingRoomCoordinate={handleMeetingRoomCoordinate}
                  />
                )
              })}
              {areas.map((area) => {
                return (
                  <Box key={`area${area.id}`}>
                    <CompanyLayoutArea
                      area={area}
                      isAreaMoveable={isMoveable}
                      handleAreaCoordinate={handleAreaCoordinate}
                    />
                    {area.children.map((seat) => {
                      return (
                        <CompanyLayoutSeat
                          key={`seat${seat.id}`}
                          seat={seat}
                          area={area}
                          areaToSeats={areaToSeats}
                          isSeatMoveable={isMoveable}
                          handleSeatCoordinate={handleAreaToSeatsCoordinate}
                          sidebarLayoutComponent={sidebarLayoutComponent}
                          handleAddAreaSeat={handleAddAreaSeat}
                          handleDeleteAreaSeat={handleDeleteAreaSeat}
                        />
                      )
                    })}
                  </Box>
                )
              })}
            </TransformComponent>
          </>
        </TransformWrapper>
      )}
      <AlertError
        isMessageOpen={errorMessageObject.isOpenMessage}
        closeMessage={errorMessageObject.closeMessage}
        alertMessage={errorMessageObject.alertMessage}
      />
    </>
  )
}
