import { useState } from "react"

import { fetchWorkHourRequest } from "../../../api/company/workHourRequest"
import { WorkHourResponseType } from "./type"

export const useWorkHour = () => {
  const [workHour, setWorkHour] = useState<WorkHourResponseType>({
    id: 0,
    am_start_time: new Date(),
    am_end_time: new Date(),
    pm_start_time: new Date(),
    pm_end_time: new Date(),
  })

  const fetchWorkHour = async () => {
    try {
      const response = await fetchWorkHourRequest()
      if (response.data && response.status === 200) {
        setWorkHour(response.data)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    workHour,
    fetchWorkHour,
  }
}
