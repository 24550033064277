import {
  SignageMeetingRoomType,
  MeetingRoomDetailScheduleType,
} from "../../models/signage/useMeetingRoom/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchMeetingRoomSchedulesRequest = async (params: {
  meeting_room_id: number
}): Promise<ApiResponse<SignageMeetingRoomType>> => {
  try {
    const data = await api.get("/signage/meeting_rooms", { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

export const fetchMeetingRoomScheduleDetailRequest = async (params: {
  schedule_id: number
}): Promise<ApiResponse<MeetingRoomDetailScheduleType>> => {
  try {
    const data = await api.get(`/signage/meeting_rooms/${params?.schedule_id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

// 次の会議予定までの残り時間を取得
export const fetchNextMeetingRoomSchedulesRequest = async (params: {
  meeting_room_id: number
}): Promise<ApiResponse<number>> => {
  try {
    const data = await api.get("/signage/meeting_rooms/next_reservation", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

export const extendMeetingRoomScheduleRequest = async (params: {
  extend_time: number
  schedule_id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post(
      "/signage/meeting_rooms/extend_schedule_time",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
