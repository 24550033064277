import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Grid,
  styled,
} from "@mui/material"

import { useMeetingRoom } from "../../../../models/employee/useRelations/meetingRoom"
import { CancelButton } from "../../../public/CancelButton"
import { Paging } from "../../../public/Pagination"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomTableCell = styled(TableCell)(() => ({
  borderBottom: "none",
}))

interface Props {
  layoutId: number
  setMeetingRoomModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleMeetingRoomSelected: (
    e: React.ChangeEvent<HTMLInputElement>,
    meetingRoomId: number,
    meetingRoomName: string
  ) => void
  isMeetingRoomChecked: (meetingRoomId: number) => boolean
  handleMeetingRoomFixed: () => void
}

// QRコード画面：会議室選択モーダル
export const CompanyQrCodeMeetingRoomModal: React.FC<Props> = ({
  layoutId,
  setMeetingRoomModalOpen,
  handleMeetingRoomSelected,
  isMeetingRoomChecked,
  handleMeetingRoomFixed,
}: Props) => {
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const { meetingRooms, fetchMeetingRooms } = useMeetingRoom()
  const DATA_COUNT_PER_PAGE = 5 // 1ページに表示するレコードの数

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  // モーダルを開いた時に会議室の一覧を参照
  useEffect(() => {
    fetchMeetingRooms(layoutId)
  }, [])

  // 選択されている会議室の確定
  const handleFixAndClose = () => {
    setMeetingRoomModalOpen(false)
    handleMeetingRoomFixed()
  }

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Typography variant="h6" fontWeight="bold">
            会議室を選択
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%"></TableCell>
                  <TableCell width="60%">
                    <Typography color="primary" fontWeight="bold">
                      会議室名
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="primary" fontWeight="bold">
                      会議室コード
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetingRooms
                  .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                  .map((meetingRoom, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <CustomTableCell>
                            <Checkbox
                              onChange={(e) =>
                                handleMeetingRoomSelected(
                                  e,
                                  meetingRoom.id,
                                  meetingRoom.meeting_room_name
                                )
                              }
                              checked={isMeetingRoomChecked(meetingRoom.id)}
                              size="small"
                            />
                          </CustomTableCell>
                          <CustomTableCell>
                            <Typography>
                              {meetingRoom.meeting_room_name}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Typography>
                              {meetingRoom.meeting_room_code}
                            </Typography>
                          </CustomTableCell>
                        </TableRow>
                      </>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {meetingRooms.length > DATA_COUNT_PER_PAGE && (
            <Paging
              pageCount={Math.ceil(meetingRooms.length / DATA_COUNT_PER_PAGE)}
              page={pageNumber}
              handlePaginationChange={handlePaginationChange}
            />
          )}
        </Grid>
        <Grid item sm={12} margin="2rem">
          <Typography align="center">印刷範囲に表示しますか？</Typography>
        </Grid>
        <Grid item sm={12} display="flex" justifyContent="space-around">
          <CancelButton
            label="キャンセル"
            handleClick={() => setMeetingRoomModalOpen(false)}
          />
          <ReflectButton label="表示する" handleClick={handleFixAndClose} />
        </Grid>
      </Grid>
    </>
  )
}
