import React from "react"

import { SignUpAppliedMessage } from "../../../components/company/auth/SignUpAppliedMessage"
import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"

export const SignUpApplied = () => {
  return (
    <AuthPageTemplate loading={false}>
      <SignUpAppliedMessage />
    </AuthPageTemplate>
  )
}
