import { yupResolver } from "@hookform/resolvers/yup"
import { getValue } from "@testing-library/user-event/dist/utils"
import { addDays } from "date-fns"
import { start } from "repl"
import * as Yup from "yup"

import { useState, useEffect, useCallback, useContext } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { SelectChangeEvent } from "@mui/material"

import {
  reportsRequest,
  reportMeetingRoomsRequest,
  reportAttendancesRequest,
  reportSeatOperationRequest,
  searchReportRequest,
  searchReportMeetingRoomRequest,
  searchReportAttendancesRequest,
  reportAreaRequest,
  searchReportAreaRequest,
  downloadEmployeeAttendanceRequest,
  downloadSeatOperationsRequest,
  downloadMeetingRoomOperationsRequest,
  reportSeatHeatmapRequest,
} from "../../../api/company/reportRequest"
import { RoleContext } from "../../../providers/RoleProvider"
import { useDownloadCsv } from "../../../utils/csv"
import { dateStatus } from "../../../utils/date"
import { downloadLayoutImage } from "../../../utils/downloadLayoutImage"
import { FULL_WIDTH_KATAKANA } from "../../../utils/regular"
import { CompanyRelationsType } from "../../public/useCompanyRelations/type"
import { useAreas } from "../useAreas"
import { useMeetingRooms } from "../useMeetingRooms"
import {
  ReportType,
  ReportMeetingRoomType,
  ReportAttendanceType,
  ReportSeatOperationType,
  SearchReportAttendanceRequestType,
  SearchReportRequestType,
  SearchReportRequestTypeWithBranchId,
  SearchReportMeetingRoomRequestType,
  ReportSeatOperationRequestType,
  ReportMeetingRoomRequestType,
  ReportAreaType,
  ReportAreaRequestType,
  SearchReportAreaRequestType,
  ReportAttendanceResponseType,
  ReportSeatHeatmapType,
  ReportSeatHeatmapRequestType,
  ReportSeatHeatmapFormType,
} from "./type"

const initialReportRelation: ReportType = {
  id: 0,
  reports: [
    {
      id: 0,
      company_id: 0,
      branch_id: 0,
      targeted_at: "",
      day_of_week: 0,
      all_employee_count: 0,
      attendance_employee_count: 0,
      all_seat_count: 0,
      restricted_seat_count: 0,
      operation_seat_count: 0,
      branch: {
        id: 0,
        branch_name: "",
      },
    },
  ],
  report_meeting_rooms: [
    {
      id: 0,
      targeted_at: "",
      checked_in_meeting_room_count: 0,
      reserved_meeting_room_count: 0,
    },
  ],
  report_areas: [
    {
      id: 0,
      targeted_at: "",
      all_seat_count: 0,
      operation_seat_count: 0,
      area_id: 0,
      floor: {
        id: 0,
        floor_name: "",
        branch: {
          id: 0,
          branch_name: "",
        },
      },
      area: {
        id: 0,
        area_name: "",
      },
    },
  ],
  company_name: "",
}

export const useReport = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [reportRelation, setReportRelations] = useState<ReportType>(
    initialReportRelation
  )
  const { download } = useDownloadCsv()

  const fetchReportRelations = async (params: SearchReportRequestType) => {
    try {
      const response = await reportsRequest(params)
      if (response.status === 200 && response.data) {
        setReportRelations(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }

  const searchReportRelations = async (
    params: SearchReportRequestTypeWithBranchId
  ) => {
    try {
      const response = await searchReportRequest(params)
      if (response.status === 200 && response.data) {
        setReportRelations(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }

  const downloadEmployeeAttendance = async (
    params: SearchReportRequestTypeWithBranchId
  ) => {
    setLoading(true)
    try {
      const response = await downloadEmployeeAttendanceRequest(params)
      if (response.status === 200 && response.data) {
        download(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadSeatOperations = async (
    params: SearchReportRequestTypeWithBranchId
  ) => {
    setLoading(true)
    try {
      const response = await downloadSeatOperationsRequest(params)
      if (response.status === 200 && response.data) {
        download(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadMeetingRoomOperations = async (
    params: SearchReportRequestTypeWithBranchId
  ) => {
    setLoading(true)
    try {
      const response = await downloadMeetingRoomOperationsRequest(params)
      if (response.status === 200 && response.data) {
        download(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    fetchReportRelations,
    reportRelation,
    searchReportRelations,
    loading,
    setLoading,
    downloadEmployeeAttendance,
    downloadSeatOperations,
    downloadMeetingRoomOperations,
  }
}

const initialReportMeetingRoomRelation: ReportMeetingRoomType = {
  id: 0,
  report_meeting_rooms: [
    {
      id: 0,
      company_id: 0,
      branch_id: 0,
      meeting_room_id: 0,
      targeted_at: "",
      day_of_week: 0,
      available_status: "",
      checked_in_meeting_room_count: 0,
      reserved_meeting_room_count: 0,
      meeting_room: {
        id: 0,
        meeting_room_name: "",
      },
    },
  ],
  company_name: "",
}

export const useReportMeetingRoom = () => {
  // グラフ別にstate管理する
  const [reportMeetingRoomRelation, setReportRelations] =
    useState<ReportMeetingRoomType>(initialReportMeetingRoomRelation)
  const [hourGraphReportMeetingRoomRelation, setHourGraphReportRelations] =
    useState<ReportMeetingRoomType>(initialReportMeetingRoomRelation)
  const [
    dayOfWeekGraphReportMeetingRoomRelation,
    setDayOfWeekGraphReportRelations,
  ] = useState<ReportMeetingRoomType>(initialReportMeetingRoomRelation)
  const [
    parMeetingRoomGraphReportMeetingRoomRelation,
    setParMeetingRoomGraphReportRelations,
  ] = useState<ReportMeetingRoomType>(initialReportMeetingRoomRelation)

  const fetchReportRelations = async (params: ReportMeetingRoomRequestType) => {
    try {
      const response = await reportMeetingRoomsRequest(params)
      if (response.status === 200 && response.data) {
        setReportRelations(response.data)
        setHourGraphReportRelations(response.data)
        setDayOfWeekGraphReportRelations(response.data)
        setParMeetingRoomGraphReportRelations(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }

  return {
    hourGraphReportMeetingRoomRelation,
    dayOfWeekGraphReportMeetingRoomRelation,
    setHourGraphReportRelations,
    setDayOfWeekGraphReportRelations,
    fetchReportRelations,
    setReportRelations,
    reportMeetingRoomRelation,
    parMeetingRoomGraphReportMeetingRoomRelation,
    setParMeetingRoomGraphReportRelations,
  }
}

const initialSearchReportAttendanceParams: SearchReportAttendanceRequestType = {
  freeword: "",
  branch_id: -1,
  floor_id: 0,
  start_date: "",
  end_date: "",
  target: "employee",
  require: false,
  team_id: -1,
}

export const useReportAttendance = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [searchedReportAttendanceList, setSearchedReportRelations] =
    useState<ReportAttendanceType>()
  const maxDate = addDays(new Date(), -1)
  const [searchParams, setSearchParams] =
    useState<SearchReportAttendanceRequestType | null>(null)
  const { isTeamLeader } = useContext(RoleContext)

  const validateRequireByEmployee = function (this: any) {
    const { freeword, branch_id, team_id, target } = this.parent
    if (
      target === "employee" &&
      freeword.length === 0 &&
      branch_id === -1 &&
      (isTeamLeader ? team_id === -1 : team_id === -1)
    ) {
      return this.createError({
        message: isTeamLeader
          ? "所属が必須です"
          : "拠点、所属、または従業員名のいずれかが必須です",
      })
    }
    return true
  }

  const validateRequireByTeam = function (this: any) {
    const { freeword, team_id, target } = this.parent
    if (target === "team" && freeword.length === 0 && team_id === -1) {
      return this.createError({
        message: "所属、部署名の入力が必須です",
      })
    }
    return true
  }

  const basicSchema = Yup.object().shape({
    freeword: Yup.string()
      .test("require_freeword_by_employee", validateRequireByEmployee)
      .test("require_freeword_by_team", validateRequireByTeam),
    branch_id: Yup.number().test(
      "require_branch_id",
      validateRequireByEmployee
    ),
    team_id: Yup.number().test("require_team_id", validateRequireByTeam),
    floor_id: Yup.number().min(0, "フロアを選択してください"),
    start_date: Yup.date().max(maxDate, ""),
    end_date: Yup.date().max(maxDate, ""),
    target: Yup.string(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      freeword: initialSearchReportAttendanceParams.freeword,
      branch_id: initialSearchReportAttendanceParams.branch_id,
      floor_id: initialSearchReportAttendanceParams.floor_id,
      start_date: initialSearchReportAttendanceParams.start_date,
      end_date: initialSearchReportAttendanceParams.end_date,
      target: initialSearchReportAttendanceParams.target,
      require: initialSearchReportAttendanceParams.require,
      team_id: initialSearchReportAttendanceParams.team_id,
    },
    resolver: yupResolver(basicSchema),
  })

  const dateFormat = (date: Date) => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = newDate.getMonth() + 1
    const day = newDate.getDate()

    return `${year}-${month}-${day}`
  }

  // ページ表示時の取得処理
  const fetchReportAttendanceList = async (data: {
    start_date: string
    end_date: string
    page: number
    rowsPerPage: number
  }) => {
    setLoading(true)
    let result = null
    try {
      const response = await reportAttendancesRequest(data)
      if (response.status === 200 && response.data) {
        setSearchedReportRelations(response.data)
        result = response.data
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
    return result
  }

  const onSearchReportAttendanceListSubmit: SubmitHandler<SearchReportAttendanceRequestType> =
    useCallback(async (data) => {
      setLoading(true)
      setSearchParams(data)
      try {
        const response = await searchReportAttendancesRequest(data)
        if (response.status === 200 && response.data) {
          if ("employees" in response.data && "teams" in response.data) {
            setSearchedReportRelations(response.data)
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("Search error: ", error) // エラーをログ出力
      } finally {
        setLoading(false)
      }
    }, [])

  return {
    fetchReportAttendanceList,
    onSearchReportAttendanceListSubmit,
    searchedReportAttendanceList,
    control,
    errors,
    isValid,
    dirtyFields,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    dateFormat,
    loading,
    setLoading,
    searchParams,
  }
}

const initialReportSeatOperationRelation: ReportSeatOperationType = {
  all_seat_count: 0,
  checked_in_count: 0,
  reserved_count: 0,
}

export const useReportSeatOperation = () => {
  const [reportSeatOperationRelation, setReportSeatOperationRelation] =
    useState<ReportSeatOperationType>(initialReportSeatOperationRelation)

  const fetchSeatOperationRelations = async (
    params: ReportSeatOperationRequestType
  ) => {
    try {
      const response = await reportSeatOperationRequest(params)
      if (response.status === 200 && response.data) {
        setReportSeatOperationRelation(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }

  return {
    reportSeatOperationRelation,
    fetchSeatOperationRelations,
  }
}

export const useReportMeetingRoomState = (
  companyRelations: CompanyRelationsType
) => {
  const today = new Date()
  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>([])
  const [selectedFloorIds, setSelectedFloorIds] = useState<number[]>([])
  const [selectedMeetingRoomIds, setSelectedMeetingRoomIds] = useState<
    number[]
  >([])
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([])
  const [selectedCapacities, setSelectedCapacities] = useState<number[]>([])
  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(yesterDay)
  const [pullDownLoading, setPullDownLoading] = useState<boolean>(true)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [searchMeetingRoomLoading, setSearchMeetingRoomLoading] =
    useState<boolean>(false)

  const { meetingRooms, fetchMeetingRooms, tags } = useMeetingRooms()
  const { formatDate } = dateStatus()

  const changeBranchId = (e: SelectChangeEvent<unknown>) => {
    const branchIds = e.target.value
    const targetBranchIds =
      typeof branchIds === "string"
        ? branchIds.split(",").map(Number)
        : branchIds

    // 「全ての拠点」を選択した時
    if ((targetBranchIds as number[]).includes(0)) {
      if (selectedBranchIds.length === companyRelations.branches.length) {
        setSelectedBranchIds([])
      } else {
        const allBranchIds = companyRelations.branches.map((branch) => {
          return branch.id
        })
        setSelectedBranchIds(allBranchIds)
      }
    } else {
      setSelectedBranchIds(targetBranchIds as number[])
    }
  }

  const changeFloorId = (e: SelectChangeEvent<unknown>) => {
    const floorIds = e.target.value
    const targetFloorIds =
      typeof floorIds === "string" ? floorIds.split(",").map(Number) : floorIds

    // 「全てのフロア」を選択した時
    if ((targetFloorIds as number[]).includes(0)) {
      if (selectedFloorIds.length === selectedBranchesFloors.length) {
        setSelectedFloorIds([])
      } else {
        const allFloorIds = selectedBranchesFloors.map((floor) => {
          return floor.id
        })
        setSelectedFloorIds(allFloorIds)
      }
    } else {
      setSelectedFloorIds(targetFloorIds as number[])
    }
  }

  const changeMeetingRoomId = (e: SelectChangeEvent<unknown>) => {
    const meetingRoomIds = e.target.value
    const targetMeetingRoomIds =
      typeof meetingRoomIds === "string"
        ? meetingRoomIds.split(",").map(Number)
        : meetingRoomIds

    // 「全てのフロア」を選択した時
    if ((targetMeetingRoomIds as number[]).includes(0)) {
      if (selectedMeetingRoomIds.length === meetingRooms.length) {
        setSelectedMeetingRoomIds([])
      } else {
        const allMeetingRoomIds = meetingRooms.map((meetingRoom) => {
          return meetingRoom.id
        })
        setSelectedMeetingRoomIds(allMeetingRoomIds)
      }
    } else {
      setSelectedMeetingRoomIds(targetMeetingRoomIds as number[])
    }
  }

  const changeTagId = (e: SelectChangeEvent<unknown>) => {
    const tagIds = e.target.value
    const targetTagIds =
      typeof tagIds === "string" ? tagIds.split(",").map(Number) : tagIds

    setSelectedTagIds(targetTagIds as number[])
  }

  const changeCapacity = (e: SelectChangeEvent<unknown>) => {
    const capacities = e.target.value
    const targetCapacities =
      typeof capacities === "string"
        ? capacities.split(",").map(Number)
        : capacities

    // 「全てのフロア」を選択した時
    if ((targetCapacities as number[]).includes(0)) {
      if (selectedCapacities.length === meetingRoomCapacities.length) {
        setSelectedCapacities([])
      } else {
        setSelectedCapacities(meetingRoomCapacities)
      }
    } else {
      setSelectedCapacities(targetCapacities as number[])
    }
  }

  const handleStartDateChange = (newStartDate: Date | null) => {
    if (newStartDate !== null) {
      setStartDate(newStartDate)
      if (newStartDate.getTime() > endDate.getTime()) {
        setEndDate(new Date(newStartDate))
      }
    }
  }

  const handleEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate !== null) {
      setEndDate(newEndDate)
      if (newEndDate.getTime() < startDate.getTime()) {
        setStartDate(new Date(newEndDate))
      }
    }
  }

  const selectedBranches = companyRelations.branches.filter((branch) => {
    return selectedBranchIds.includes(branch.id)
  })

  const selectedBranchNames = selectedBranches.map((branch) => {
    return branch.branch_name
  })

  const selectedBranchesFloors = selectedBranches.flatMap((branch) => {
    return branch.floors
  })

  const selectedBranchesFloorIds = selectedBranchesFloors.map((floor) => {
    return floor.id
  })

  const selectedFloors = selectedBranchesFloors.filter((floor) => {
    return selectedFloorIds.includes(floor.id)
  })

  const selectedFloorNames = selectedFloors.map((floor) => {
    return `${floor.branch_name}-${floor.floor_name}`
  })

  const selectedMeetingRooms = meetingRooms.filter((meetingRoom) => {
    return selectedMeetingRoomIds.includes(meetingRoom.id)
  })

  const selectedMeetingRoomNames = selectedMeetingRooms.map((meetingRoom) => {
    return `${meetingRoom.branch.branch_name}-${meetingRoom.floor.floor_name}-${meetingRoom.meeting_room_name}`
  })

  const selectedTags = tags.filter((tag) => {
    return selectedTagIds.includes(tag.id)
  })

  const selectedTagNames = selectedTags.map((tag) => {
    return `#${tag.tag_name}`
  })

  const selectedCapacitiesString = selectedCapacities.map((capacity) => {
    return `${capacity}人`
  })

  const layoutIds = meetingRooms.map((meetingRoom) => {
    return meetingRoom.layout_id
  })

  const disableFlag =
    selectedMeetingRoomIds.length === 0 &&
    selectedTagIds.length === 0 &&
    selectedCapacities.length === 0

  const meetingRoomCapacities = Array.from(
    new Map(
      meetingRooms.map((meetingRoom) => [
        meetingRoom.capacity,
        meetingRoom.capacity,
      ])
    ).values()
  ).sort((previous, next) => previous - next)

  useEffect(() => {
    if (companyRelations.id > 0) {
      const allBranchIds = companyRelations.branches.map((branch) => {
        return branch.id
      })
      setSelectedBranchIds(allBranchIds)
      setPullDownLoading(false)
    }
  }, [companyRelations])

  useEffect(() => {
    const newSelectedFloorIds = selectedFloorIds.filter((id) => {
      return selectedBranchesFloorIds.includes(id)
    })

    setSelectedFloorIds(newSelectedFloorIds)
  }, [selectedBranchesFloors])

  useEffect(() => {
    if (selectedFloorIds.length > 0) {
      const fetchDataFunction = async () => {
        setSearchMeetingRoomLoading(true)
        try {
          await fetchMeetingRooms({
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
            floor_ids: selectedFloorIds,
          })
        } catch (error) {
          console.log(error)
        } finally {
          setSearchMeetingRoomLoading(false)
        }
      }
      fetchDataFunction()
      setSelectedTagIds([])
      setSelectedCapacities([])
    }
  }, [selectedFloorIds.length, startDate, endDate])

  useEffect(() => {
    const meetingRoomIds = meetingRooms.map((meetingRoom) => {
      return meetingRoom.id
    })
    setSelectedMeetingRoomIds(meetingRoomIds)
  }, [meetingRooms])

  return {
    selectedBranchIds,
    selectedBranchNames,
    selectedFloorIds,
    selectedFloorNames,
    selectedMeetingRoomIds,
    selectedMeetingRoomNames,
    startDate,
    endDate,
    changeBranchId,
    changeFloorId,
    handleStartDateChange,
    handleEndDateChange,
    selectedBranchesFloors,
    meetingRooms,
    changeMeetingRoomId,
    yesterDay,
    selectedTagIds,
    selectedTagNames,
    changeTagId,
    tags,
    selectedCapacities,
    changeCapacity,
    meetingRoomCapacities,
    disableFlag,
    layoutIds,
    selectedBranches,
    selectedCapacitiesString,
    pullDownLoading,
    searchLoading,
    setSearchLoading,
    searchMeetingRoomLoading,
  }
}

export const useSearchReportMeetingRoom = () => {
  // グラフ別にstate管理したいが、検索処理は共通化したいためsetStateアクションを引数で取れるようにする
  const searchReportRelations = async (
    params: SearchReportMeetingRoomRequestType,
    setGraphDataStateAction: React.Dispatch<
      React.SetStateAction<ReportMeetingRoomType>
    >,
    setLoadingStateAction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoadingStateAction(true)
    try {
      const response = await searchReportMeetingRoomRequest(params)
      if (response.status === 200 && response.data) {
        setGraphDataStateAction(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoadingStateAction(false)
    }
  }

  return {
    searchReportRelations,
  }
}

export const useReportOperation = () => {
  const [loading, setLoading] = useState<boolean>(true)
  // グラフ別にstateを用意
  const [branchReportRelation, setBranchReportRelations] = useState<ReportType>(
    initialReportRelation
  ) // 拠点別
  const [attendanceReportRelation, setAttendanceReportRelations] =
    useState<ReportType>(initialReportRelation) // 出社日の推移
  const [dayOfWeekReportRelation, setDayOfWeekReportRelations] =
    useState<ReportType>(initialReportRelation) //曜日別

  const fetchReportRelations = async (params: SearchReportRequestType) => {
    try {
      const response = await reportsRequest(params)
      if (response.status === 200 && response.data) {
        // APIからのレスポンスを全てのstateに突っ込む
        setBranchReportRelations(response.data)
        setAttendanceReportRelations(response.data)
        setDayOfWeekReportRelations(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }

  return {
    fetchReportRelations,
    branchReportRelation,
    attendanceReportRelation,
    dayOfWeekReportRelation,
    setBranchReportRelations,
    setAttendanceReportRelations,
    setDayOfWeekReportRelations,
    loading,
    setLoading,
  }
}

const initialReportAreaRelation: ReportAreaType = {
  id: 0,
  report_areas: [
    {
      id: 0,
      targeted_at: "",
      all_seat_count: 0,
      operation_seat_count: 0,
      seat_operation_status: [],
      area_id: 0,
      floor: {
        id: 0,
        floor_name: "",
        branch: {
          id: 0,
          branch_name: "",
        },
      },
      area: {
        id: 0,
        area_name: "",
      },
    },
  ],
  company_name: "",
}

export const useReportArea = () => {
  const [reportAreaRelation, setReportRelations] = useState<ReportAreaType>(
    initialReportAreaRelation
  )
  const [heatMapReportAreaRelation, setHeatMapReportRelations] =
    useState<ReportAreaType>(initialReportAreaRelation)

  const fetchReportRelations = async (params: ReportAreaRequestType) => {
    try {
      const response = await reportAreaRequest(params)
      if (response.status === 200 && response.data) {
        setReportRelations(response.data)
        setHeatMapReportRelations(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }

  return {
    heatMapReportAreaRelation,
    setHeatMapReportRelations,
    fetchReportRelations,
    setReportRelations,
    reportAreaRelation,
  }
}

export const useSearchReportArea = () => {
  // グラフ別にstate管理したいが、検索処理は共通化したいためsetStateアクションを引数で取れるようにする
  const searchReportRelations = async (
    params: SearchReportAreaRequestType,
    setGraphDataStateAction: React.Dispatch<
      React.SetStateAction<ReportAreaType>
    >,
    setLoadingStateAction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoadingStateAction(true)
    try {
      const response = await searchReportAreaRequest(params)
      if (response.status === 200 && response.data) {
        setGraphDataStateAction(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoadingStateAction(false)
    }
  }

  return {
    searchReportRelations,
  }
}

export const useReportAreaState = (companyRelations: CompanyRelationsType) => {
  const today = new Date()
  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>([])
  const [selectedFloorIds, setSelectedFloorIds] = useState<number[]>([])
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([])
  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(yesterDay)
  const [pullDownLoading, setPullDownLoading] = useState<boolean>(true)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [searchTagLoading, setSearchTagLoading] = useState<boolean>(false)

  const { areas, fetchAreas, tags } = useAreas()
  const { formatDate } = dateStatus()

  const changeBranchId = (e: SelectChangeEvent<unknown>) => {
    const branchIds = e.target.value
    const targetBranchIds =
      typeof branchIds === "string"
        ? branchIds.split(",").map(Number)
        : branchIds

    // 「全ての拠点」を選択した時
    if ((targetBranchIds as number[]).includes(0)) {
      if (selectedBranchIds.length === companyRelations.branches.length) {
        setSelectedBranchIds([])
      } else {
        const allBranchIds = companyRelations.branches.map((branch) => {
          return branch.id
        })
        setSelectedBranchIds(allBranchIds)
      }
    } else {
      setSelectedBranchIds(targetBranchIds as number[])
    }
  }

  const changeFloorId = (e: SelectChangeEvent<unknown>) => {
    const floorIds = e.target.value
    const targetFloorIds =
      typeof floorIds === "string" ? floorIds.split(",").map(Number) : floorIds

    // 「全てのフロア」を選択した時
    if ((targetFloorIds as number[]).includes(0)) {
      if (selectedFloorIds.length === selectedBranchesFloors.length) {
        setSelectedFloorIds([])
      } else {
        const allFloorIds = selectedBranchesFloors.map((floor) => {
          return floor.id
        })
        setSelectedFloorIds(allFloorIds)
      }
    } else {
      setSelectedFloorIds(targetFloorIds as number[])
    }
  }

  const changeTagId = (e: SelectChangeEvent<unknown>) => {
    const tagIds = e.target.value
    const targetTagIds =
      typeof tagIds === "string" ? tagIds.split(",").map(Number) : tagIds

    setSelectedTagIds(targetTagIds as number[])
  }

  // NOTE: ガード文で書いた方がスマート
  const handleStartDateChange = (newStartDate: Date | null) => {
    if (newStartDate !== null) {
      setStartDate(newStartDate)
      if (newStartDate.getTime() > endDate.getTime()) {
        setEndDate(new Date(newStartDate))
      }
    }
  }

  // NOTE: ガード文で書いた方がスマート
  const handleEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate !== null) {
      setEndDate(newEndDate)
      if (newEndDate.getTime() < startDate.getTime()) {
        setStartDate(new Date(newEndDate))
      }
    }
  }

  const selectedBranches = companyRelations.branches.filter((branch) => {
    return selectedBranchIds.includes(branch.id)
  })

  const selectedBranchNames = selectedBranches.map((branch) => {
    return branch.branch_name
  })

  const selectedBranchesFloors = selectedBranches.flatMap((branch) => {
    return branch.floors
  })

  const selectedBranchesFloorIds = selectedBranchesFloors.map((floor) => {
    return floor.id
  })

  const selectedFloors = selectedBranchesFloors.filter((floor) => {
    return selectedFloorIds.includes(floor.id)
  })

  const selectedFloorNames = selectedFloors.map((floor) => {
    return `${floor.branch_name}-${floor.floor_name}`
  })

  const selectedTags = tags.filter((tag) => {
    return selectedTagIds.includes(tag.id)
  })

  const selectedTagNames = selectedTags.map((tag) => {
    return `#${tag.tag_name}`
  })

  const layoutIds = areas.map((area) => {
    return area.layout_id
  })

  const disableFlag = selectedFloorIds.length === 0 || layoutIds.length === 0
  useEffect(() => {
    if (companyRelations.id > 0) {
      const allBranchIds = companyRelations.branches.map((branch) => {
        return branch.id
      })
      setSelectedBranchIds(allBranchIds)
      setPullDownLoading(false)
    }
  }, [companyRelations])

  useEffect(() => {
    const newSelectedFloorIds = selectedFloorIds.filter((id) => {
      return selectedBranchesFloorIds.includes(id)
    })

    setSelectedFloorIds(newSelectedFloorIds)
  }, [selectedBranchesFloors])

  useEffect(() => {
    if (selectedFloorIds.length > 0) {
      const fetchDataFunction = async () => {
        setSearchTagLoading(true)
        try {
          await fetchAreas({
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
            floor_ids: selectedFloorIds,
          })
        } catch (error) {
          console.log(error)
        } finally {
          setSearchTagLoading(false)
        }
      }
      fetchDataFunction()
      setSelectedTagIds([])
    }
  }, [selectedFloorIds.length, startDate, endDate])

  return {
    selectedBranchIds,
    selectedBranchNames,
    selectedFloorIds,
    selectedFloorNames,
    startDate,
    endDate,
    changeBranchId,
    changeFloorId,
    handleStartDateChange,
    handleEndDateChange,
    selectedBranchesFloors,
    yesterDay,
    selectedTagIds,
    selectedTagNames,
    changeTagId,
    tags,
    disableFlag,
    layoutIds,
    selectedBranches,
    pullDownLoading,
    searchLoading,
    setSearchLoading,
    setSearchTagLoading,
    searchTagLoading,
  }
}

export const useSearchReportOperation = () => {
  // グラフ別にstate管理したいが、検索処理は共通化したいためsetStateアクションを引数で取れるようにする
  const searchReportRelations = async (
    params: SearchReportRequestTypeWithBranchId,
    setGraphDataStateAction: React.Dispatch<React.SetStateAction<ReportType>>,
    setLoadingStateAction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoadingStateAction(true)
    try {
      const response = await searchReportRequest(params) // api/company/reports#search
      if (response.status === 200 && response.data) {
        setGraphDataStateAction(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoadingStateAction(false)
    }
  }

  return {
    searchReportRelations,
  }
}

export const useReportOperationState = (
  companyRelations: CompanyRelationsType
) => {
  const today = new Date()
  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>([])
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(yesterDay)

  const { formatDate } = dateStatus()

  const changeBranchId = (e: SelectChangeEvent<unknown>) => {
    const branchIds = e.target.value
    const targetBranchIds =
      typeof branchIds === "string"
        ? branchIds.split(",").map(Number)
        : branchIds

    // 「全ての拠点」を選択した時
    if ((targetBranchIds as number[]).includes(0)) {
      if (selectedBranchIds.length === companyRelations.branches.length) {
        setSelectedBranchIds([])
      } else {
        const allBranchIds = companyRelations.branches.map((branch) => {
          return branch.id
        })
        setSelectedBranchIds(allBranchIds)
      }
    } else {
      setSelectedBranchIds(targetBranchIds as number[])
    }
  }

  const handleStartDateChange = (newStartDate: Date | null) => {
    if (newStartDate !== null) {
      setStartDate(newStartDate)
      if (newStartDate.getTime() > endDate.getTime()) {
        setEndDate(new Date(newStartDate))
      }
    }
  }

  const handleEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate !== null) {
      setEndDate(newEndDate)
      if (newEndDate.getTime() < startDate.getTime()) {
        setStartDate(new Date(newEndDate))
      }
    }
  }

  const selectedBranches = companyRelations.branches.filter((branch) => {
    return selectedBranchIds.includes(branch.id)
  })

  const selectedBranchNames = selectedBranches.map((branch) => {
    return branch.branch_name
  })

  const disableFlag = selectedBranchIds.length === 0

  useEffect(() => {
    if (companyRelations.id > 0) {
      const allBranchIds = companyRelations.branches.map((branch) => {
        return branch.id
      })
      setSelectedBranchIds(allBranchIds)
    }
  }, [companyRelations])

  return {
    selectedBranchIds,
    selectedBranchNames,
    changeBranchId,
    handleStartDateChange,
    handleEndDateChange,
    yesterDay,
    searchLoading,
    setSearchLoading,
    startDate,
    endDate,
    disableFlag,
  }
}

const initialReportSeatHeatmapRelation: ReportSeatHeatmapType = {
  id: 0,
  floor: {
    layout_image: "",
  },
  seats: [
    {
      id: null,
      seat_name: "",
      x: 0,
      y: 0,
      operating_rate: 0,
    },
  ],
  meeting_rooms: [
    {
      id: null,
      floor_id: 0,
      meeting_room_code: "",
      meeting_room_name: "",
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      color_code: "",
    },
  ],
  areas: [
    {
      id: null,
      floor_id: 0,
      area_name: "",
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      color_code: "",
    },
  ],
}

export const useReportSeatHeatmap = (
  companyRelations: CompanyRelationsType
) => {
  const today = new Date()
  const maxDate = addDays(new Date(), -1)
  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const [reportSeatHeatmapRelation, setReportSeatHeatmapRelation] =
    useState<ReportSeatHeatmapType>(initialReportSeatHeatmapRelation)
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(yesterDay)
  const [layoutImg, setLayoutImg] = useState<string>("")
  const { formatDate } = dateStatus()

  const basicSchema = Yup.object().shape({
    branch_id: Yup.number().min(1, ""),
    floor_id: Yup.number().min(1, ""),
  })

  const dateSchema = Yup.object().shape({
    startDate: Yup.date().required(""),
    endDate: Yup.date().min(Yup.ref("startDate"), "").required(""),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ReportSeatHeatmapFormType>({
    defaultValues: {
      branch_id: 0,
      floor_id: 0,
    },
    resolver: yupResolver(basicSchema),
  })

  // ヒートマップ用データを取得する処理
  const reportSeatHeatmapSubmit: SubmitHandler<ReportSeatHeatmapFormType> =
    useCallback(
      async (data) => {
        setSearchLoading(true)

        const isValidDate = await dateSchema.isValid({
          startDate: startDate,
          endDate: endDate,
        })
        if (!isValidDate) {
          setSearchLoading(false)
          return
        }

        try {
          const response = await reportSeatHeatmapRequest({
            ...data,
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
          })
          if (response.status === 200 && response.data) {
            setReportSeatHeatmapRelation(response.data)
            if (response.data.floor.layout_image) {
              const result = await downloadLayoutImage(
                companyRelations.id,
                data.branch_id,
                data.floor_id,
                response.data.floor.layout_image
              )
              if (result) {
                setLayoutImg(URL.createObjectURL(result.Body as Blob))
              }
            } else {
              setLayoutImg("")
            }
          }
          // eslint-disable-next-line
        } catch (error: any) {
          console.log("Search error: ", error) // エラーをログ出力
        } finally {
          setSearchLoading(false)
        }
      },

      [companyRelations.id, startDate, endDate]
    )

  const onSubmit = () => {
    handleSubmit(reportSeatHeatmapSubmit)()
  }

  const handleStartDateChange = (newStartDate: Date | null) => {
    if (newStartDate !== null) {
      setStartDate(newStartDate)
      if (newStartDate.getTime() > endDate.getTime()) {
        setEndDate(new Date(newStartDate))
      }
    }
  }

  const handleEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate !== null) {
      setEndDate(newEndDate)
      if (newEndDate.getTime() < startDate.getTime()) {
        setStartDate(new Date(newEndDate))
      }
    }
  }

  const selectedBranch = companyRelations.branches.find(
    (branch) => branch.id === selectedBranchId
  )

  return {
    control,
    setValue,
    errors,
    selectedBranch,
    setSelectedBranchId,
    searchLoading,
    reportSeatHeatmapRelation,
    onSubmit,
    startDate,
    endDate,
    yesterDay,
    layoutImg,
    handleStartDateChange,
    handleEndDateChange,
  }
}
