import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Grid, Box, styled } from "@mui/material"

import { useAmEndTime } from "../../../models/company/useAmEndTime"
import { useAmStartTime } from "../../../models/company/useAmStartTime"
import { usePmEndTime } from "../../../models/company/usePmEndTime"
import { usePmStartTime } from "../../../models/company/usePmStartTime"
import { useWorkHour } from "../../../models/company/useWorkHour"
import { formatDateForSafari } from "../../../utils/dateTimeFormat"
import { CancelButton } from "../../public/CancelButton"
import { ErrorText } from "../../public/ErrorText"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"
import { TimeInputForm } from "./TimeInputForm/TimeInputForm"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}))

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

export const WorkHour = () => {
  const navigate = useNavigate()
  const { amStartTime, setAmStartTime, updateAmStartTime } = useAmStartTime()
  const { amEndTime, setAmEndTime, updateAmEndTime } = useAmEndTime()
  const { pmStartTime, setPmStartTime, updatePmStartTime } = usePmStartTime()
  const { pmEndTime, setPmEndTime, updatePmEndTime } = usePmEndTime()
  const { workHour, fetchWorkHour } = useWorkHour()
  const [loading, setLoading] = useState<boolean>(true)

  const updateWorkHour = () => {
    updateAmStartTime()
    updateAmEndTime()
    updatePmStartTime()
    updatePmEndTime()
  }

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchWorkHour()])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  const amStartTimeCheck =
    amStartTime < pmEndTime &&
    amStartTime < amEndTime &&
    amStartTime < pmStartTime

  const amEndTimeCheck = amEndTime < pmStartTime && amEndTime < pmEndTime

  const pmStartTimeCheck = pmStartTime < pmEndTime

  return (
    <>
      {loading ? (
        <>
          <Loading type="content" />
        </>
      ) : (
        <>
          {(!amStartTimeCheck || !amEndTimeCheck || !pmStartTimeCheck) && (
            <ErrorMessageContainer>
              {ErrorText(["就業時間を正しく設定してください"])}
            </ErrorMessageContainer>
          )}
          <CustomGridContainer container spacing={5}>
            <TimeInputForm
              inputLabel="始業時間"
              inputTime={amStartTime}
              defaultTimeHour={new Date(
                formatDateForSafari(workHour.am_start_time.toString())
              ).getHours()}
              defaultTimeMinutes={new Date(
                formatDateForSafari(workHour.am_start_time.toString())
              ).getMinutes()}
              setTimeFunction={setAmStartTime}
            />
            <TimeInputForm
              inputLabel="終業時間"
              inputTime={pmEndTime}
              defaultTimeHour={new Date(
                formatDateForSafari(workHour.pm_end_time.toString())
              ).getHours()}
              defaultTimeMinutes={new Date(
                formatDateForSafari(workHour.pm_end_time.toString())
              ).getMinutes()}
              setTimeFunction={setPmEndTime}
            />
            <TimeInputForm
              inputLabel="休憩開始時間"
              inputTime={amEndTime}
              defaultTimeHour={new Date(
                formatDateForSafari(workHour.am_end_time.toString())
              ).getHours()}
              defaultTimeMinutes={new Date(
                formatDateForSafari(workHour.am_end_time.toString())
              ).getMinutes()}
              setTimeFunction={setAmEndTime}
            />
            <TimeInputForm
              inputLabel="休憩終了時間"
              inputTime={pmStartTime}
              defaultTimeHour={new Date(
                formatDateForSafari(workHour.pm_start_time.toString())
              ).getHours()}
              defaultTimeMinutes={new Date(
                formatDateForSafari(workHour.pm_start_time.toString())
              ).getMinutes()}
              setTimeFunction={setPmStartTime}
            />
          </CustomGridContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <Grid item xs={4} sm={3}>
              <ReflectButton
                label="更新"
                disabled={
                  !amStartTimeCheck || !amEndTimeCheck || !pmStartTimeCheck
                }
                handleClick={() => {
                  updateWorkHour()
                  navigate("/company/settings/environment")
                }}
              />
            </Grid>
            <CancelButton
              label="戻る"
              handleClick={() => navigate("/company/settings/environment")}
            />
          </Box>
        </>
      )}
    </>
  )
}
