import { Auth } from "aws-amplify"

import React, { useContext } from "react"
import { Navigate, RouteProps, useNavigate } from "react-router-dom"

import { useAuth } from "../models/public/useAuth"
import { AuthContext } from "../providers/AuthProvider"
import { RoleContext } from "../providers/RoleProvider"

// ログインしていないユーザーがアクセスしてはいけない画面がある場合にRoutingを制御する

const isPaidCheck = (isPaid: boolean) => {
  if (isPaid) {
    return true
  } else {
    return location.pathname === "/employee/home"
  }
}

const isActiveCheck = (isActive: boolean, initActive: boolean) => {
  if (isActive) {
    return true
  } else {
    return location.pathname === "/employee/home" && initActive === null
  }
}

export const EmployeePrivateRoute: React.FC<RouteProps> = ({ element }) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useContext(AuthContext)
  const { role, isPaid, isActive } = useContext(RoleContext)
  const { signOut } = useAuth()

  const loginPageNavigate = async () => {
    await signOut().then(() => {
      localStorage.setItem(
        "alertErrorContent",
        "現在、WORKAGILEにはログインできません。御社のWORKAIGLE運用ご担当者様までご連絡ください。"
      )
      navigate("/employee/login")
      window.location.reload()
    })
  }

  return (
    <>
      {isAuthenticated && role ? (
        <>
          {isActiveCheck(isActive, role.is_active) && isPaid
            ? element
            : loginPageNavigate()}
        </>
      ) : (
        <Navigate to="/employee/login" /> // TaODO 従業員画面が実装されたらリダイレクト先をpropsで分岐する
      )}
    </>
  )
}
