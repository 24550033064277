import React from "react"

import { AuthPageTemplate } from "../../components/public/AuthPageTemplate"
import { SignageMAFForm } from "../../components/signage/login/SignageMFAForm"
import { useMFA } from "../../models/public/useAuth/MFA"

export const SignageMFA = () => {
  const authObject = useMFA()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <SignageMAFForm {...authObject} />
    </AuthPageTemplate>
  )
}
