const ja = {
  WorkStatus: "席状況",
  AllDay: "終日",
  Legend: "アイコン凡例",
  Available: "予約・利用可",
  Reserved: "予約中",
  CheckedIn: "チェックイン中",
  NotAvailable: "予約・利用不可",
  Mode: "モード",
  DeskStatus: "席状況",
  Status: "状況",
  WorkFromHome: "在宅",
  OutOfOffice: "外出",
  BusinessTrip: "出張",
  Vacation: "休暇",
  BookDesk: "予約",
  Location: "拠点",
  Floor: "フロア",
  month: "月",
  day: "日",
  WorkPlaceStatus: "席状況",
  weekdays: ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"],
  thisUser: "このユーザーは",
  is: "です。",
  schedule_statuses: {
    出社: "出社",
    在宅: "在宅",
    外出: "外出",
    出張: "出張",
    休暇: "休暇",
    その他: "その他",
  },
  // エラーメッセージ
  LayoutNot: "現在レイアウトが公開されていません。管理者様にご確認ください。",
  UserExist: "このユーザーは指定日時にそのフロアには存在しません。",
  NotDesk: "このユーザーは座席を予約していません。",
}
export default ja
