import { Billing, PaymentHistory } from "../../models/company/useBilling/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchBillingsRequest = async (): Promise<
  ApiResponse<PaymentHistory>
> => {
  try {
    const data = await api.get("/company/billings")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
