import React, { memo } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  Typography,
  AppBar,
  styled,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material"

import { theme } from "../../../config/theme"
import { HeaderCompanyType } from "../../../models/company/useCompany/type"
import { useAuth } from "../../../models/public/useAuth"
import { pageType } from "../../../utils/location"

const CustomAppBar = styled(AppBar)(() => ({
  height: "50px",
  width: "100%",
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}))

const CustomToolbar = styled(Grid)(() => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

const CompanyContent = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "5px",
}))

const EmployeeContent = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "right",
  marginRight: "5px",
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

interface Props {
  headerCompanyInfo?: HeaderCompanyType
}

export const SignageHeader: React.FC<Props> = memo(
  ({ headerCompanyInfo }: Props) => {
    const navigate = useNavigate()
    const { signOut } = useAuth()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <CustomAppBar color="inherit" elevation={0} position="relative">
          <CustomToolbar container spacing={0.3}>
            <CompanyContent item xs={8} md={9}>
              <IconButton
                onClick={() => {
                  navigate("/signage/home")
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/logos/logo-icon.svg`}
                  height="30px"
                  width="30px"
                  alt="WORK AGILEロゴ"
                />
              </IconButton>
              <p
                style={{
                  height: "30px",
                  borderRight: "1px solid #e3e3e3",
                  margin: "0 10px",
                }}
              ></p>
              <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                <CustomTypography
                  sx={{ fontSize: "1rem", variant: "button", color: "inherit" }}
                >
                  {headerCompanyInfo?.company.company_name}
                </CustomTypography>
              </Box>
            </CompanyContent>
            <EmployeeContent item xs={3.5} md={2.5}>
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/setting.svg`}
                  height="30px"
                  width="30px"
                  alt="設定"
                />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={signOut}>ログアウト</MenuItem>
                <MenuItem
                  onClick={() => {
                    localStorage.setItem("expiredChangePassword", "true")
                    navigate(`/${pageType}/password-change`)
                  }}
                >
                  パスワード変更
                </MenuItem>
              </Menu>
            </EmployeeContent>
          </CustomToolbar>
        </CustomAppBar>
      </>
    )
  }
)
