import React from "react"

import { Box, styled } from "@mui/material"

import { MemberSchedulesType } from "../../../../../models/employee/useSchedule/weeks/type"
import { EmployeeScheduleWeek } from "../EmployeeScheduleWeek"

const CustomBox = styled(Box)(() => ({
  borderColor: "#C1C1C1",
  orderBottomWidth: "1px",
  height: "3.5rem",
  width: "14%",
}))

interface Props {
  schedule: MemberSchedulesType[]
  displayReserved: boolean
  displayNonReserved: boolean
  index: number
}

export const EmployeeScheduleWeekTable: React.FC<Props> = ({
  schedule,
  displayReserved,
  displayNonReserved,
  index,
}: Props) => {
  return (
    <>
      <Box
        display="flex"
        sx={{
          position: index == 0 ? "sticky" : "none",
          top: index == 0 ? "72px" : "none",
          backgroundColor: "white",
          zIndex: index == 0 ? 5000 : 0,
        }}
      >
        {schedule.map((value, index) => (
          <CustomBox key={index} border={1}>
            <EmployeeScheduleWeek
              {...value}
              displayReserved={displayReserved}
              displayNonReserved={displayNonReserved}
            />
          </CustomBox>
        ))}
      </Box>
    </>
  )
}
