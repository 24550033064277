import { Auth } from "aws-amplify"

import React, { useContext, useEffect } from "react"
import { Navigate, RouteProps, useNavigate } from "react-router-dom"

import { useAuth } from "../models/public/useAuth"
import { AuthContext } from "../providers/AuthProvider"
import { RoleContext } from "../providers/RoleProvider"

// ログインしていないユーザーがアクセスしてはいけない画面がある場合にRoutingを制御する
// 使い方
{
  /* <Route
    path="/home"
    element={
      <CompanyAdminRoute
        path="/layouts/new"
        element={
          <CommonTemplate>
            <CompanyHomePage />
          </CommonTemplate>
        }
      />
    }
  /> */
}

const isPaidCheck = (isPaid: boolean) => {
  if (isPaid) {
    return true
  } else {
    return (
      location.pathname === "/company/settings/payment" ||
      location.pathname === "/company/settings/payment/methods" ||
      location.pathname === "/company/home" ||
      location.pathname === "/company/register-info" // これ追加しないと法人登録前に無限ループしちゃう
    )
  }
}

// 現在のplan_idが１（契約満了）かどうか
const isFinishTrial = (latestContract: boolean) => {
  if (latestContract) {
    return true
  } else {
    return false
  }
}

const isActiveCheck = (isActive: boolean, initActive: boolean) => {
  // initActiveは、法人新規登録画面へ遷移するときに/company/homeに入って、そこから/companyregister-infoに遷移する
  // その時だけnullになるので、nullの時は法人新規登録画面へ遷移できるようにしている
  if (isActive) {
    return true
  } else {
    return (
      (location.pathname === "/company/home" && initActive === null) ||
      location.pathname === "/company/register-info" // これ追加しないと法人登録前に無限ループしちゃう
    )
  }
}

export const CompanyAdminRoute: React.FC<RouteProps> = ({ element }) => {
  const { isAuthenticated } = useContext(AuthContext)
  const { role, isAdmin, isPaid, isActive, latestContract } =
    useContext(RoleContext)
  const queryString = new URLSearchParams(window.location.search)
  const navigate = useNavigate()

  const { signOut } = useAuth()

  const loginPageNavigate = async () => {
    await signOut().then(() => {
      localStorage.setItem(
        "alertErrorContent",
        "現在、WORKAGILEにはログインできません。御社のWORKAIGLE運用ご担当者様までご連絡ください。"
      )
      navigate("/company/login")
      window.location.reload()
    })
  }

  return (
    <>
      {isAuthenticated && role ? (
        isAdmin ? (
          <>
            {isActiveCheck(isActive, role.is_active) ? (
              isPaidCheck(isPaid) ? (
                element
              ) : (
                <Navigate to="/company/home" />
              )
            ) : // 無料プランかつ利用日を過ぎている場合
            isFinishTrial(latestContract) ? (
              location.pathname === "/company/settings/payment" ||
              location.pathname === "/company/settings/payment/methods" ||
              location.pathname === "/company/settings/payment/paid" ||
              location.pathname === "/company/settings/payment/credit_card" ||
              location.pathname === "/company/settings/payment/plan" ||
              location.pathname === "/company/settings/payment/history" ? (
                element
              ) : (
                <Navigate to="/company/settings/payment" />
              )
            ) : (
              loginPageNavigate()
            )}
          </>
        ) : (
          <Navigate
            to="/employee/home"
            state={{
              m_token: queryString.get("m_token"),
              m_refresh_token: queryString.get("m_refresh_token"),
              m_expires_at: queryString.get("m_expires_at"),
              m_user_name: queryString.get("m_user_name"),
              m_user_email: queryString.get("m_user_email"),
              m_user_timezone: queryString.get("m_user_timezone"),
              g_token: queryString.get("g_token"),
              g_refresh_token: queryString.get("g_refresh_token"),
              g_expires_at: queryString.get("g_expires_at"),
              g_user_name: queryString.get("g_user_name"),
              g_user_email: queryString.get("g_user_email"),
              g_user_timezone: queryString.get("g_user_timezone"),
            }}
          /> // 管理者以外がアクセスしてきた場合は従業員HOMEに遷移させる
        )
      ) : (
        <Navigate to="/company/login" /> // ログアウト時は共通で法人ログイン画面へ
      )}
    </>
  )
}
