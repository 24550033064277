import React, { useEffect } from "react"

import Box from "@mui/material/Box"

import { CompanyBranchDetail } from "../../../components/company/branches/CompanyBranchDetail"
import { CompanyFloorList } from "../../../components/company/floor/CompanyFloorList"
import { AlertContent } from "../../../components/public/AlertContent"
import { AlertSuccess } from "../../../components/public/AlertSuccess"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useBranch } from "../../../models/company/useBranch"
import { useFloor } from "../../../models/company/useFloor"

export const CompanyBranchShowPage = () => {
  const { fetchBranchDetail, branchDetail, loading, forbidden } = useBranch()

  const floorObject = useFloor()
  useEffect(() => {
    fetchBranchDetail()
  }, [])

  // フロアの作成・更新に成功した時に最新の拠点情報を参照する
  useEffect(() => {
    if (floorObject.showSuccessMessage) {
      fetchBranchDetail()
    }
  }, [floorObject.showSuccessMessage])

  return (
    <>
      <ContentTemplate>
        <ContentCompanyPaper>
          {loading ? (
            <Loading type="content" loading={loading} />
          ) : (
            <Box style={{ display: "flex", height: "100%" }}>
              <CompanyBranchDetail
                branchDetail={branchDetail}
                forbidden={forbidden}
              />
              <CompanyFloorList {...floorObject} branchDetail={branchDetail} />
            </Box>
          )}
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertSuccess
        isMessageOpen={floorObject.showSuccessMessage}
        closeMessage={() => floorObject.setShowSuccessMessage(false)}
        alertMessage={floorObject.successMessage}
      />
      <AlertContent />
    </>
  )
}
