const en = {
  WorkStatus: "WORKPLACE STATUS",
  AllDay: "ALL DAY",
  Legend: "Map Legend",
  Available: "Available",
  Reserved: "Reserved",
  CheckedIn: "Checked in",
  NotAvailable: "Not Available",
  Mode: "Mode",
  DeskStatus: "Desk status",
  Status: "Status",
  WorkFromHome: "Work from home",
  OutOfOffice: "Out of office",
  BusinessTrip: "Business trip",
  Vacation: "DAY OFF/TIME OFF",
  BookDesk: "Book a desk",
  Location: "location",
  Floor: "floor",
  month: " / ",
  day: "",
  WorkPlaceStatus: "WORKPLACE STATUS",
  weekdays: ["(Sun)", "(Mon)", "(Tue)", "(Wed)", "(Thu)", "(Fri)", "(Sat)"],
  LayoutNot: "Layout does not exist.",
  thisUser: "This user is",
  is: "",
  schedule_statuses: {
    出社: "In Office.",
    在宅: "at home.",
    外出: "out of the office.",
    出張: "traveling.",
    休暇: "on vacation.",
    その他: "Other.",
  },
  // エラーメッセージ
  UserExist:
    "This user does not exist on the floor at the specified date and time.",
  NotDesk: "This user has not reserved a desk.",
}
export default en
