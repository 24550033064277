//未出社(予約済み)アイコン
export const notCheckInSeatStyle = {
  position: "absolute",
  borderRadius: "50%",
  backgroundColor: "#707070",
  cursor: "pointer",
  boxSizing: "border-box",
  zIndex: 1,
  color: "#fff",
  fontSize: "30px",
  width: "60px",
  height: "60px",
  border: "solid 5px #707070",
  boxShadow: "0 0 2px #1d0204",
  "&::before": {},
}

//指定席アイコン
export const reservedSeatStyle = {
  position: "absolute",
  borderRadius: "50%",
  backgroundColor: "#22BA9D",
  border: "solid 5px #fff",
  boxShadow: "0 0 2px #1d0204",
  cursor: "pointer",
  boxSizing: "border-box",
  zIndex: 1,
  color: "#fff",
  fontSize: "30px",
  width: "60px",
  height: "60px",
  "&::before": {},
}

//自由席アイコン
export const freeSeatStyle = {
  position: "absolute",
  borderRadius: "50%",
  border: "solid 5px #22BA9D",
  backgroundColor: "#FFFFFF",
  boxShadow: "0 0 2px #1d0204",
  cursor: "pointer",
  boxSizing: "border-box",
  zIndex: 1,
  color: "#000000",
  fontSize: "30px",
  width: "60px",
  height: "60px",
  "&::before": {},
}

//利用不可アイコン
export const unavailableStyle = {
  position: "absolute",
  border: "solid 6px #333",
  borderRadius: "50%",
  backgroundColor: "#FFFFFF",
  zIndex: 1,
  color: "#000000",
  fontSize: "30px",
  width: "60px",
  height: "60px",
  cursor: "pointer",
  boxShadow: "0 0 2px #1d0204",
  "&::before": {
    content: '""',
    position: "absolute",
    transform: "translate(-50%,-50%) rotate(45deg)",
    top: "50%",
    left: "50%",
    width: "2px",
    height: "60px",
    backgroundColor: "#FFFFFF",
    background: "#333",
  },
}

//出社済みアイコン
export const checkInSeatStyle = {
  position: "absolute",
  borderRadius: "50%",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  boxSizing: "border-box",
  zIndex: 1,
  color: "#000000",
  fontSize: "30px",
  width: "60px",
  height: "60px",
  border: "solid 5px #FFFFFF",
  boxShadow: "0 0 2px #1d0204",
  "&::before": {},
}

//未出社(予約済み)アイコン（会議室）
export const reservedEmployeesStyle = {
  width: "60px",
  height: "60px",
  position: "relative",
  borderRadius: "50%",
  backgroundColor: "#707070",
  color: "#fff",
  fontSize: "30px",
  boxSizing: "border-box",
  zIndex: 1,
  border: "solid 5px #707070",
  boxShadow: "0 0 2px #1d0204",
  "&::before": {},
}

//出社済みアイコン（会議室）
export const checkInReservedEmployeesStyle = {
  width: "60px",
  height: "60px",
  position: "relative",
  borderRadius: "50%",
  backgroundColor: "#FFFFFF",
  color: "#000000",
  fontSize: "30px",
  boxSizing: "border-box",
  zIndex: 1,
  border: "solid 5px #FFFFFF",
  boxShadow: "0 0 2px #1d0204",
  "&::before": {},
}

// ヒートマップ用カラー
export const heatmapColorThresholds = {
  veryLow: "#C9E8E7", // 0~20%
  low: "#A5D9D7", // 20~40%
  middle: "#78C5C2", // 40~60%
  high: "#4BB2AE", // 60~80%
  veryHigh: "#3C8E8B", // 80~100%
}
