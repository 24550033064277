import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { employeeSearchRequest } from "../../../api/employee/employeeRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/errorMessage/en"
import jaTranslations from "../../../translations/errorMessage/ja"
import { LAYOUT_PAGE_PATHNAME } from "../../../utils/const"
import { downloadIcon } from "../../../utils/downLoadIcon"
import {
  Employee,
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "./type"

const initialSearchEmployeeParams: SearchEmployeeRequestType = {
  freeword: "",
  branch_id: 0,
  floor_id: 0,
  team_id: 0,
  employee_group_id: 0,
}

// 従業員検索
export const useSearchEmployee = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [loading, setLoading] = useState<boolean>(false)
  // この関数はお気に入り編集モーダルからも呼ばれているので、レイアウトページだけで使う処理を行うためのbool値を用意
  const onLayoutPage = LAYOUT_PAGE_PATHNAME.includes(window.location.pathname)
  const storageEmployees = localStorage.getItem("layoutSelectedEmployees")
  const [searchEmployeeParams] = useState<SearchEmployeeRequestType>(
    initialSearchEmployeeParams
  )

  const [searchedEmployeeResult, setSearchedEmployeeResult] =
    useState<SearchedEmployeeResult>([])
  const [selectedEmployees, setSelectedEmployees] =
    useState<SearchedEmployeeResult>(
      storageEmployees && onLayoutPage ? JSON.parse(storageEmployees) : []
    )
  const [checkedEmployees, setCheckedEmployees] =
    useState<SearchedEmployeeResult>([])

  // 必須項目（従業員名、拠点、所属のいずれか）のバリデーション
  const validateRequireValues = function (this: any) {
    const { freeword, branch_id, team_id, employee_group_id } = this.parent
    return !(
      freeword.length === 0 &&
      branch_id === 0 &&
      team_id === 0 &&
      employee_group_id === 0
    )
  }

  const basicSchema = Yup.object().shape({
    freeword: Yup.string().test(
      "maxlength",
      translations.fiftyEmployeeSearch,
      (freeword) => {
        if (freeword == undefined) {
          return true
        }
        return freeword.length <= 50
      }
    ),
    floor_id: Yup.number().when("branch_id", {
      is: (branch_id: number) => branch_id !== 0,
      then: Yup.number().min(1, translations.pleaseFloor),
    }),
    require: Yup.boolean().test(
      "require_validation",
      translations.EmployeeNameRequired,
      validateRequireValues
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      freeword: searchEmployeeParams.freeword,
      branch_id: searchEmployeeParams.branch_id,
      floor_id: searchEmployeeParams.floor_id,
      team_id: searchEmployeeParams.team_id,
      employee_group_id: searchEmployeeParams.employee_group_id,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<SearchEmployeeRequestType> = async (data) => {
    try {
      setLoading(true)
      const response = await employeeSearchRequest(data)
      if (response.status == 200 && response.data) {
        const checkedEmployeeIds = checkedEmployees.map((employee) => {
          return employee.id
        })
        const defaultSearchEmployeeResult = response.data.map((employee) => {
          return {
            ...employee,
            checked: checkedEmployeeIds.includes(employee.id),
          }
        })
        defaultSearchEmployeeResult.sort(
          (a, b) => Number(b.checked) - Number(a.checked)
        )
        setSearchedEmployeeResult(defaultSearchEmployeeResult)

        // iconをS3と同期
        const fetchIconsPromises = defaultSearchEmployeeResult.map(
          async (employee: Employee) => {
            if (employee.icon)
              employee.icon = await downloadIcon(
                employee.account.company_id,
                employee.id,
                employee.icon
              )
            return await {
              ...employee,
              checked: checkedEmployeeIds.includes(employee.id),
            }
          }
        )
        Promise.all(fetchIconsPromises).then(setSearchedEmployeeResult)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 従業員をcheckする
  const handleEmployeeChecked = (selectedEmployeeId: number) => {
    const newSearchEmployeeResult = searchedEmployeeResult.map((employee) => {
      if (employee.id === selectedEmployeeId) {
        return { ...employee, checked: !employee.checked }
      } else {
        return employee
      }
    })
    const checkedEmployee = newSearchEmployeeResult.filter((employee) => {
      return employee.id === selectedEmployeeId
    })[0]
    if (checkedEmployee.checked) {
      setCheckedEmployees([...checkedEmployees, checkedEmployee])
    } else {
      const newCheckedEmployees = checkedEmployees.filter((employee) => {
        return employee.id !== checkedEmployee.id
      })
      setCheckedEmployees(newCheckedEmployees)
    }
    setSearchedEmployeeResult(newSearchEmployeeResult)
  }

  // checkした従業員を確定して検索対象者に追加する
  const handleEmployeeFixed = () => {
    const newSelectedEmployees = selectedEmployees.concat(checkedEmployees) // 既存の検索対象者と新しくcheckを入れた従業員を結合

    // 結合した配列をユニークにする
    const uniqueSelectedEmployees = Array.from(
      new Map(
        newSelectedEmployees.map((employee) => [employee.id, employee])
      ).values()
    )
    setSelectedEmployees(uniqueSelectedEmployees)

    if (onLayoutPage) {
      localStorage.setItem(
        "layoutSelectedEmployees",
        JSON.stringify(uniqueSelectedEmployees)
      )
    }
  }

  // checkした従業員を確定して検索対象者に追加する,returnあり
  const handleEmployeeFixedAndReturn = () => {
    const newSelectedEmployees = selectedEmployees.concat(checkedEmployees) // 既存の検索対象者と新しくcheckを入れた従業員を結合

    // 結合した配列をユニークにする
    const uniqueSelectedEmployees = Array.from(
      new Map(
        newSelectedEmployees.map((employee) => [employee.id, employee])
      ).values()
    )
    setSelectedEmployees(uniqueSelectedEmployees)
    return uniqueSelectedEmployees
  }

  // 検索対象者を削除
  const deletedSelectedEmployee = (selectedEmployeeId: number) => {
    const newSelectedEmployees = selectedEmployees.filter((employee) => {
      return employee.id !== selectedEmployeeId
    })

    setSelectedEmployees(newSelectedEmployees)

    if (onLayoutPage) {
      localStorage.setItem(
        "layoutSelectedEmployees",
        JSON.stringify(newSelectedEmployees)
      )
    }
  }

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    clearErrors,
    searchedEmployeeResult,
    handleEmployeeChecked,
    handleEmployeeFixed,
    selectedEmployees,
    setSearchedEmployeeResult,
    deletedSelectedEmployee,
    loading,
    handleEmployeeFixedAndReturn,
  }
}
