import React, { Suspense } from "react"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { CircularProgress, styled } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeTitle/ja"
import { EmployeeHomeContent } from "./EmployeeHomeContent"

////////// Title

const EmployeeHomeCheckInOutTitleStyle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  marginLeft: "9px",
})

const EmployeeHomeCheckInOutTitle: React.FC = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <>
      <EmployeeHomeCheckInOutTitleStyle>
        {translations.checkInOut}
      </EmployeeHomeCheckInOutTitleStyle>
    </>
  )
}

////////// Content

const EmployeeHomeCheckInOutContent = React.lazy(() =>
  import("./checkInOut/EmployeeHomeCheckInOutMain").then(
    ({ EmployeeHomeCheckInOutMain }) => ({
      default: EmployeeHomeCheckInOutMain,
    })
  )
)

////////// Icon

const EmployeeLeftIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "20px",
  height: "20px",
})

const EmployeeLeftIcon = (
  <EmployeeLeftIconStyled>
    <CheckCircleIcon
      style={{
        color: "#22ba9d",
      }}
    />
  </EmployeeLeftIconStyled>
)

export const EmployeeHomeCheckInOut = () => {
  const { language, setLanguage } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  // const switchLanguage = (lang: string) => {
  //   setLanguage(lang)
  // }
  return (
    <>
      <EmployeeHomeContent
        title={<EmployeeHomeCheckInOutTitle />}
        content={
          <>
            <Suspense fallback={<CircularProgress />}>
              <EmployeeHomeCheckInOutContent />
            </Suspense>
          </>
        }
        leftIcon={EmployeeLeftIcon}
        areaName="Checkin"
      />
    </>
  )
}
