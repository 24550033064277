import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import CloseIcon from "@mui/icons-material/Close"
import { Box, Button, styled, Typography, TextField } from "@mui/material"

import { theme } from "../../../../config/theme"
import {
  userableSearchRequestState,
  useScheduleUserableSearch,
} from "../../../../models/employee/useSchedule/useScheduleUserableSearch"
import { SearchUserable } from "../../../../models/employee/useSchedule/useScheduleUserableSearch/type"

const CustomModalBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "90%",
  width: "95%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
}))

const CustomBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

const CustomMemberList = styled(Box)(() => ({
  height: "70%",
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  overflowY: "scroll",
}))

const CustomMemberBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(1.5),
}))

interface Props {
  modalClose: () => void
  selectedEmployees: SearchUserable[]
  setSelectedEmployees: React.Dispatch<React.SetStateAction<SearchUserable[]>>
}

export const MobileMemberSearch: React.FC<Props> = ({
  modalClose,
  selectedEmployees,
  setSelectedEmployees,
}) => {
  const navigate = useNavigate()

  const { userableSearchRequest, setUserableSearchRequest } =
    userableSearchRequestState()

  //"userableSearch"：検索候補、"scheduleUserableSearch"：検索apiを投げるメソッド
  const { userableSearch, scheduleUserableSearch } = useScheduleUserableSearch()
  // 検索候補があるかどうかの判定
  const [isUserable, setIsUserable] = useState<boolean>(false)
  const [searchTargets, setSearchTargets] = useState<SearchUserable[]>([])

  // key入力時に予測候補を表示する
  const handleSearchMember = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/ /g, "")
    setIsUserable(true)
    setUserableSearchRequest(value)
    scheduleUserableSearch(value)
    if (value === "") {
      setIsUserable(false)
    }
  }

  // 予測候補の選択時
  const handleSelectUserClick = (userable: SearchUserable) => {
    setIsUserable(false)
    setUserableSearchRequest("")
    setSearchTargets([...searchTargets, userable])
  }

  // 検索候補者の削除
  const handleDeleteMember = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newSearchTargets = searchTargets.filter((member) => {
      return member.email != event.currentTarget.value
    })
    setSearchTargets(newSearchTargets)
  }

  const handleFixSearchTarget = () => {
    const unionTargets = [...selectedEmployees, ...searchTargets]
    const uniqueSelectedTargets = unionTargets.filter((e, index, self) => {
      return self.findIndex((el) => el.email === e.email) === index
    })
    setSelectedEmployees(uniqueSelectedTargets)
    modalClose()
  }

  return (
    <>
      <CustomModalBox>
        <CustomBox>
          <Button
            variant="outlined"
            onClick={() => {
              setSearchTargets([])
              modalClose()
            }}
          >
            戻る
          </Button>
          <Typography>探したいメンバーの追加</Typography>
          <Button variant="contained" onClick={handleFixSearchTarget}>
            追加
          </Button>
        </CustomBox>
        <Box
          sx={{
            marginTop: theme.spacing(2),
            position: "relative",
          }}
        >
          <TextField
            fullWidth
            size="small"
            value={userableSearchRequest}
            label="メンバーを追加（漢字、メール入力）"
            onChange={handleSearchMember}
          />
          {isUserable && (
            <Box
              role="listbox"
              sx={{
                border: "1px",
                borderColor: "rgb(0 0 0 / 50%)",
                boxShadow: "0 2px 10px 0 rgb(0 0 0 / 50%)",
                height: "150px",
                overflowY: "scroll",
                backgroundColor: "#fff",
                position: "absolute",
                zIndex: "10000",
                top: "50px",
                display: "block",
                width: "100%",
              }}
            >
              {userableSearch.map((userable, index) => {
                if (
                  searchTargets.some((searchCandidate) => {
                    return searchCandidate.email === userable.email
                  })
                ) {
                  return
                } else if (
                  selectedEmployees.some((selectedEmployee) => {
                    return selectedEmployee.email === userable.email
                  })
                ) {
                  return
                }
                return (
                  <Box
                    key={index}
                    onMouseOver={(e) => {
                      const list = e.currentTarget.parentElement?.children
                      if (list) {
                        Array.from(list).forEach((child) => {
                          child.setAttribute("aria-selected", "false")
                        })
                      }
                      e.currentTarget.setAttribute("aria-selected", "true")
                    }}
                    onClick={() => {
                      handleSelectUserClick(userable)
                    }}
                    aria-selected={false}
                    sx={{
                      '&[aria-selected="true"]': {
                        backgroundColor: "rgb(0 0 0 / 10%)",
                      },
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography>
                      {userable.last_name} {userable.first_name}
                    </Typography>
                    <Typography fontSize="0.5rem">{userable.email}</Typography>
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
        <CustomMemberList>
          {searchTargets.map((member) => (
            <CustomMemberBox key={member.id}>
              <Box>
                <Typography>
                  {member.last_name} {member.first_name}
                </Typography>
                <Typography fontSize="0.5rem">{member.email}</Typography>
              </Box>
              <Button
                value={member.email}
                onClick={(event) => {
                  handleDeleteMember(event)
                }}
              >
                <CloseIcon sx={{ color: "#707070" }} />
              </Button>
            </CustomMemberBox>
          ))}
        </CustomMemberList>
      </CustomModalBox>
    </>
  )
}
