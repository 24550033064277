import React from "react"

import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material"

import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"

interface Props {
  companyRelations: CompanyRelationsType
  changeSelectValue: (e: SelectChangeEvent<number>, companyId: number) => void
  branchId: number
  floorId: number
}

// 拠点・フロアおよび日時の切り替えを行う
export const CompanyDashboardHeader: React.FC<Props> = ({
  companyRelations,
  changeSelectValue,
  branchId,
  floorId,
}: Props) => {
  // 選択中の拠点情報を取得する
  const selectedBranch = companyRelations.branches.filter(
    (branch) => branch.id === branchId
  )[0]

  return (
    <>
      <Grid container alignItems="center" width="500px" spacing={1.875}>
        <Grid item sm={6} alignItems="center">
          <Grid item xs={12} sm={4}>
            <InputLabel
              htmlFor="branch_id"
              color="primary"
              sx={{
                color: "#22BA9D",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Noto Sans",
              }}
            >
              拠点
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <Select
                id="branch_id"
                name="branch_id"
                fullWidth
                value={branchId}
                variant="outlined"
                onChange={(e) => changeSelectValue(e, companyRelations.id)}
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  fontSize: "14px",
                  fontFamily: "Noto Sans",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem disabled={true} key={0} value={0}>
                  拠点
                </MenuItem>
                {companyRelations.branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.branch_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item sm={6} alignItems="center">
          <Grid item xs={12} sm={4}>
            <InputLabel
              htmlFor="floor_id"
              sx={{
                color: "#22BA9D",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Noto Sans",
              }}
            >
              フロア
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <Select
                id="floor_id"
                name="floor_id"
                fullWidth
                variant="outlined"
                onChange={(e) => changeSelectValue(e, companyRelations.id)}
                value={floorId}
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  fontSize: "14px",
                  fontFamily: "Noto Sans",
                }}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem disabled={true} key={0} value={0}>
                  フロア
                </MenuItem>
                {branchId > 0 &&
                  selectedBranch.floors
                    .filter((floor) =>
                      floor.layouts.some(
                        (layout) =>
                          layout.release_date !== null &&
                          new Date(layout.release_date) <= new Date()
                      )
                    )
                    .map((floor) => (
                      <MenuItem key={floor.id} value={floor.id}>
                        {floor.floor_name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
