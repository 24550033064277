import React, { useEffect, useState } from "react"

import { Grid } from "@mui/material"

import { Loading } from "../../../components/public/Loading"
import { SignageReceptionHome } from "../../../components/signage/reception/Home"
import { useSignage } from "../../../models/company/useSignage"
import { useSignageSetting } from "../../../models/signage/useSetting"

export const SignageReceptionHomePage = () => {
  const signageObject = useSignageSetting()
  const { imageUrl, receptionMessage, fetchSignage } = useSignage()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const signageRequest = async () => {
      await Promise.all([
        fetchSignage(),
        signageObject.fetchSignageDisplayData(),
      ])
    }
    signageRequest().then(() => {
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading type="content" loading={loading} />
  }

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        sm={6.5}
        sx={{
          height: "100%",
          backgroundColor: "#fff",
        }}
      >
        {imageUrl === "" ? (
          <img
            src={`${process.env.PUBLIC_URL}/images/covers/signage_cover.svg`}
            width="600px"
            height="700px"
          />
        ) : (
          <img src={imageUrl} width="100%" height="100%" />
        )}
      </Grid>
      <Grid
        item
        sm={5.5}
        sx={{
          height: "100%",
          backgroundColor: "#fff",
        }}
      >
        <SignageReceptionHome receptionMessage={receptionMessage} />
      </Grid>
    </Grid>
  )
}
