import { useState, useCallback, useEffect, useContext } from "react"
import { useLocation } from "react-router-dom"

import { companyRelationsRequest } from "../../../api/public/companyRelationsRequest"
import { AuthContext } from "../../../providers/AuthProvider"
import { CompanyRelationsType } from "./type"

const companyRelationsData: CompanyRelationsType = {
  id: 0,
  default_branch_id: null,
  default_floor_id: null,
  branches: [
    {
      id: 0,
      branch_name: "",
      floors: [
        {
          id: 0,
          floor_number: "",
          floor_name: "",
          branch_name: "",
          branch_id: 1,
          layouts: [
            {
              id: 0,
              layout_image: "",
              post_status: "",
              release_date: new Date(),
              paused_date: new Date(),
              version: 0,
            },
          ],
        },
      ],
    },
  ],
  teams: [
    {
      id: 0,
      team_name: "",
    },
  ],
}

export const useCompanyRelations = () => {
  const [companyRelations, setCompanyRelations] =
    useState<CompanyRelationsType>(companyRelationsData)
  const { currentUser } = useContext(AuthContext)
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)
  const location = useLocation()

  const fetchCompanyRelations = useCallback(async () => {
    try {
      const response = await companyRelationsRequest({
        pathname: location.pathname,
      })
      if (response.status === 200 && response.data) {
        setCompanyRelations(response.data)
        if (response.data.default_branch_id) {
          setBranchId(response.data.default_branch_id)
        }
        if (response.data.default_floor_id) {
          setFloorId(response.data.default_floor_id)
        }
        return response.data
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      fetchCompanyRelations()
    }
  }, [])

  const selectedBranch = companyRelations.branches.filter(
    (branch) => branch.id === branchId
  )[0]

  return {
    companyRelations,
    fetchCompanyRelations,
    branchId,
    setBranchId,
    floorId,
    setFloorId,
    selectedBranch,
  }
}
