import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Button,
  Box,
  Typography,
  styled,
  Modal,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material"

import { useCompanyWeeklyOffDay } from "../../../models/company/useCompanyWeeklyOffDay"
import { CompanyWeeklyOffDayType } from "../../../models/company/useCompanyWeeklyOffDay/type"
import { usePasswordExpireMonthes } from "../../../models/company/usePasswordExpireMonthes"
import { useSchedulableMonthes } from "../../../models/company/useSchedulableMonthes"
import { useWorkHour } from "../../../models/company/useWorkHour"
import { dateTimeOnlyFormat } from "../../../utils/dateTimeFormat"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { useOpen } from "../../../utils/isOpen"
import { AlertError } from "../../public/AlertError"
import { CancelButton } from "../../public/CancelButton"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"

const CustomListBox = styled(Box)(({ theme }) => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  backgroundColor: "#FFFFFF",
  marginBottom: theme.spacing(2),
}))

const ToDoListBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(5),
}))

const ToDoTitleBox = styled(Box)(() => ({
  display: "flex",
  width: "210px",
  color: "#707070",
  fontWeight: "bold",
  fontSize: "1rem",
}))

const ToDoContentBox = styled(Box)(() => ({}))

const ToDoListText = styled(Typography)(() => ({
  color: "#707070",
  fontWeight: "bold",
  fontSize: "12px",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "216px",
  height: "40px",
  fontFamily: "Noto Sans",
  fontSize: theme.spacing(2),
  fontWeight: "bold",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(0.75),
  marginRight: theme.spacing(6),
}))

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  alignItems: "center",
  width: "600px",
  borderRadius: "10px",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export const EnvironmentSettings = () => {
  const navigate = useNavigate()
  const errorMessageObject = useAlertMessage()
  const [loading, setLoading] = useState<boolean>(true)
  const { workHour, fetchWorkHour } = useWorkHour()
  const { companyWeeklyOffDay, fetchCompanyWeeklyOffDay } =
    useCompanyWeeklyOffDay()
  const schedulableMonthesOpen = useOpen()

  const {
    schedulableMonthes,
    setSchedulableMonthes,
    fetchSchedulableMonthes,
    updateSchedulableMonthes,
    monthNumber,
  } = useSchedulableMonthes()
  const passwordExpireMonthesOpen = useOpen()
  const {
    passwordExpireMonthes,
    setPasswordExpireMonthes,
    fetchPasswordExpireMonthes,
    updatePasswordExpireMonthes,
  } = usePasswordExpireMonthes()

  const [tempPasswordExpireMonthes, setTempPasswordExpireMonthes] = useState(
    passwordExpireMonthes
  )

  // 英語の曜日と日本語の曜日のマッピング
  const weekdayNames: {
    [key in keyof Omit<CompanyWeeklyOffDayType, "id">]: string
  } = {
    sunday: "日",
    monday: "月",
    tuesday: "火",
    wednesday: "水",
    thursday: "木",
    friday: "金",
    saturday: "土",
  }

  // 公休日の表示
  const weekDayDisplay = (weekDays: CompanyWeeklyOffDayType): string => {
    const weekDayArray = (
      Object.entries(weekDays) as [
        Exclude<keyof CompanyWeeklyOffDayType, "id">,
        boolean
      ][]
    )
      .filter(([_, value]) => value === true)
      .map(([key]) => {
        return weekdayNames[key]
      })

    return weekDayArray.length === 0 ? "なし" : weekDayArray.join("・")
  }

  useEffect(() => {
    const planState = async () => {
      await Promise.all([
        fetchWorkHour(),
        fetchCompanyWeeklyOffDay(),
        fetchSchedulableMonthes(),
        fetchPasswordExpireMonthes(),
      ])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>
      {loading ? (
        <>
          <Loading type="content" />
        </>
      ) : (
        <>
          <AlertError
            isMessageOpen={errorMessageObject.isOpenMessage}
            closeMessage={errorMessageObject.closeMessage}
            alertMessage={errorMessageObject.alertMessage}
          />
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>就業時間</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  <Box>
                    就業時間:{dateTimeOnlyFormat(workHour?.am_start_time)}~
                    {dateTimeOnlyFormat(workHour?.pm_end_time)}
                  </Box>
                  <Box>
                    休憩時間:{dateTimeOnlyFormat(workHour?.am_end_time)}〜
                    {dateTimeOnlyFormat(workHour?.pm_start_time)}
                  </Box>
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/company/settings/environment/work_hour")
                }}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>公休日</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  公休日: {weekDayDisplay(companyWeeklyOffDay)}
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/company/settings/environment/weekly_off_day")
                }}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>サイネージ</ToDoTitleBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/company/settings/environment/signage")
                }}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>予定作成可能期間</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  <Box>{monthNumber}ヶ月</Box>
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={schedulableMonthesOpen.open}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>パスワード有効期限</ToDoTitleBox>
              <ToDoContentBox>
                <ToDoListText>
                  <Box>{passwordExpireMonthes}ヶ月</Box>
                </ToDoListText>
              </ToDoContentBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setTempPasswordExpireMonthes(passwordExpireMonthes)
                  passwordExpireMonthesOpen.open()
                }}
              >
                変更
              </CustomButton>
            </Box>
          </CustomListBox>
          <Modal
            open={schedulableMonthesOpen.isOpen}
            onClose={schedulableMonthesOpen.close}
          >
            <CustomLayoutModal>
              <CustomGridContainer container spacing={3}>
                <CustomGridItem item xs={6}>
                  <InputLabel htmlFor="schedulable_monthes">
                    <Typography color="inherit" fontWeight="bold">
                      予定作成可能期間
                    </Typography>
                  </InputLabel>
                </CustomGridItem>
                <Grid item xs={6}>
                  <TextField
                    name="schedulable_monthes"
                    InputProps={{ inputProps: { min: 1, max: 3 } }}
                    value={schedulableMonthes}
                    onChange={(e) => {
                      const number = parseInt(e.target.value)
                      if (1 <= number && number <= 3) {
                        setSchedulableMonthes(parseInt(e.target.value))
                      }
                    }}
                    type={"number"}
                  ></TextField>
                </Grid>
              </CustomGridContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "450px",
                  m: "30px auto",
                }}
              >
                <Grid item xs={4} sm={3}>
                  <ReflectButton
                    label="更新"
                    handleClick={() => {
                      updateSchedulableMonthes()
                      schedulableMonthesOpen.close()
                    }}
                  />
                </Grid>
                <CancelButton
                  label="閉じる"
                  handleClick={() => {
                    setSchedulableMonthes(monthNumber)
                    schedulableMonthesOpen.close()
                  }}
                />
              </Box>
            </CustomLayoutModal>
          </Modal>
          <Modal
            open={passwordExpireMonthesOpen.isOpen}
            onClose={passwordExpireMonthesOpen.close}
          >
            <CustomLayoutModal>
              <CustomGridContainer container spacing={3}>
                <CustomGridItem item xs={6}>
                  <InputLabel htmlFor="password_expire_monthes">
                    <Typography color="inherit" fontWeight="bold">
                      パスワード有効期限
                    </Typography>
                  </InputLabel>
                </CustomGridItem>
                <Grid item xs={6}>
                  <TextField
                    name="password_expire_monthes"
                    InputProps={{ inputProps: { min: 1, max: 3 } }}
                    value={tempPasswordExpireMonthes}
                    onChange={(e) => {
                      const newValue = Number(e.target.value)
                      if (newValue >= 1 && newValue <= 3) {
                        setTempPasswordExpireMonthes(newValue)
                      }
                    }}
                    type="number"
                  />
                </Grid>
              </CustomGridContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "450px",
                  m: "30px auto",
                }}
              >
                <Grid item xs={4} sm={3}>
                  <ReflectButton
                    label="更新"
                    handleClick={async () => {
                      await updatePasswordExpireMonthes(
                        tempPasswordExpireMonthes
                      )
                      passwordExpireMonthesOpen.close()
                    }}
                  />
                </Grid>
                <CancelButton
                  label="閉じる"
                  handleClick={passwordExpireMonthesOpen.close}
                />
              </Box>
            </CustomLayoutModal>
          </Modal>
        </>
      )}
    </>
  )
}
