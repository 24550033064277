// axiosに関する設定
import { Auth, Amplify } from "aws-amplify"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

import { NavigateFunction } from "react-router-dom"

import awsmobile from "../../aws-exports"
import { Config } from "../../config"

export const api = axios.create({
  baseURL: Config.api.endpoint,
  responseType: "json",
})

// Apiのレスポンスの共通の型
export interface ApiResponse<T> {
  data?: T
  error?: string[]
  status?: number
}

export const setUpAxiosInterceptor = (
  navigate: NavigateFunction,
  setIsForbiddenError: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  Amplify.configure(awsmobile)
  // 各APIリクエスト送信時の共通処理
  api.interceptors.request.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (config: AxiosRequestConfig) => {
      // 成功時の処理
      await Auth.currentSession().then((session) => {
        const idToken = session.getIdToken() // ログイン中のセッションからidTokenを抜き出す
        const jwt = idToken.getJwtToken() // tokenはjwtTokenとpayload(ユーザー情報)からなる。認証に使うのはjwtTokenで、これを認証ヘッダーに含める
        config.headers = {
          Authorization: `Bearer ${jwt}`,
        }
      })
      return config
    },
    (error) => {
      // 失敗時の処理
      console.log(error)
    }
  )

  // 各APIリクエストでレスポンスを受け取った時の共通処理
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (!error.response) {
        // 通信に失敗した場合など、そもそもレスポンスが返ってこなかった場合
        navigate("/500", { state: "500" })
      } else {
        // 失敗時の処理
        console.error(error.response.status)
        switch (error.response.status) {
          case 403:
            setIsForbiddenError(true) // AxiosInterceptor.tsxで設定したエラー情報を表示
            throw error
          case 404:
            navigate("/404", { state: "404" })
            break
          case 422:
            throw error
          default:
            // 指定していないステータスのエラーが起こった場合
            navigate("/500", { state: "500" })
            break
        }
      }
    }
  )
}
