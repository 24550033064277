import { useState } from "react"

import {
  downloadSampleCsvRequest,
  downloadCsvRequest,
  uploadCsvRequest,
} from "../../../api/company/employees/csvRequest"
import { useDownloadCsv } from "../../../utils/csv"

export const useEmployeeCsv = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [uploading, setUpLoading] = useState<boolean>(false)
  const [uploadingMessage, setUpLoadingMessage] = useState<string>("")
  const [failureMessageOpen, setFailureMessageOpen] = useState<boolean>(false)
  const [failureMessage, setFailureMessage] = useState<string>("")
  const { download } = useDownloadCsv()

  const downloadSampleCsv = async () => {
    setLoading(true)
    try {
      const response = await downloadSampleCsvRequest()
      if (response.status === 200 && response.data) {
        download(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadCsv = async (data: number[]) => {
    setLoading(true)
    try {
      const response = await downloadCsvRequest({ employee_ids: data })
      if (response.status === 200 && response.data) {
        download(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const uploadCsv = async (file: Blob) => {
    setUpLoading(false)
    setLoading(true)
    const formData = new FormData() // multipart-formdataの場合はjsonではなくFormData.appendを使う
    formData.append("csv", file)
    try {
      const { error } = await uploadCsvRequest(formData)
      if (!error) {
        // 正常にアップロードできた場合
        setUpLoading(true)
        setUpLoadingMessage(
          "CSVファイルをアップロード中です。\n完了次第ログイン中のメールアドレスに通知しますので少々お待ちください。"
        )
      } else {
        // ヘッダーが不正な場合などステータス422が返却された場合
        setFailureMessageOpen(true)
        setFailureMessage(
          "CSVのインポートに失敗しました。\n送信内容に誤りがないかご確認ください。"
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    downloadSampleCsv,
    downloadCsv,
    uploadCsv,
    uploading,
    uploadingMessage,
    setUpLoading,
    failureMessage,
    failureMessageOpen,
    setFailureMessageOpen,
  }
}
