const en = {
  InviteOthers: "Invite others",
  Attendees: "Attendees",
  InviteExternalGuests: "Invite external guests",
  SpaceBooking: "Space Booking",
  Location: "Location",
  Floor: "Floor",
  AddEmployee: "Add employee name or email",
  AttendanceRequired: "Attendance as required",
  Attendanceoptional: "Attendance as optional (Recommendation)",
  gest: "(gest)",
}
export default en
