import React from "react"

import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { MAFForm } from "../../../components/public/auth/MFAForm"
import { useMFA } from "../../../models/public/useAuth/MFA"

export const EmployeeMFA = () => {
  const authObject = useMFA()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <MAFForm {...authObject} />
    </AuthPageTemplate>
  )
}
