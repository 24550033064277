const en = {
  scheduleTitle: "Add schedule title",
  Allday: "All day",
  day: "th",
  everyMonths: "every months",
  everyWeeks: "every weeks",
  first: "1st",
  second: "2nd",
  third: "3rd",
  fourth: "4th",
  last: "last",
  DayOfWeek: "Day of Week",
  private: "Private",
  public: "Public",
  Public: "Public",
  Attendees: "Attendees",
  Message: "Message",
  AddDeskMeetingRoom: "Add a desk or space",
  StartDate: "Start date",
  StartTime: "Start time",
  EndDate: "End date",
  EndTime: "End time",
  RepeatSettings: "Repeat settings",
  Weekly: "Weekly",
  Monthly: "Monthly",
  Day: "Day",
  ConfirmReservation: "Confirm Reservation",
  Add: "Add",
  Reset: "Reset",
  RepeatSetting: "Repeat Settings",
  Update: "Update",
  Edit: "Edit",
  Confirm: "Confirm",
  Capacity: "Capacity",

  // 曜日
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",

  // 会議室、席追加
  UsedBefore: "Desks you've used before / Spaces",
  FloorMap: "Floor map",
  Desks: "Desks",
  MeetingRooms: "Meeting Spaces",
  // "確定する",

  // エラーメッセージ
  SchedulePast:
    "In WORK AGILE, it is not possible to create a schedule in the past.",
  SpanDays:
    "In WORK AGILE, it is not possible to create a schedule that spans days.",
  OutsideBusinessHours:
    "In WORK AGILE, it is not possible to create a schedule outside of business hours.",
  StartDateReset:
    "If you want to change the start date, please reset the selected seat/meeting room once.",
  StartTimeReset:
    "If you want to change the start time, please reset the selected seat/meeting room once.",
  EndDateReset:
    "If you want to change the end date, please reset the selected seat/meeting room once.",
  EndTimeReset:
    "If you want to change the end time, please reset the selected seat/meeting room once.",
  AllDayReset:
    "If you want to change the all-day event, please reset the selected seat/meeting room once.",
  RepeatReset:
    "If you want to change the repeat setting or change the repeat date, please reset the selected seat/meeting room once.",
  RepeatFrequencyChange:
    "If you want to change the repeat frequency, please reset the selected seat/meeting room once.",
}
export default en
