import React from "react"
import { Route, Routes } from "react-router-dom"

import { SignageTemplate } from "../pages/SignageTemplate"
import { SignageConfirmForgotPassword } from "../pages/signage/confirmForgotPassword"
import { SignageFloorMapPage } from "../pages/signage/floor/map"
import { SignageForgotPassword } from "../pages/signage/forgotPassword"
import { SignageForgotPasswordApplied } from "../pages/signage/forgotPasswordApplied"
import { SignageLogin } from "../pages/signage/login"
import { SignageMeetingRoomPage } from "../pages/signage/meetingRoom/meetingRoom"
import { SignageMeetingRoomSchedulePage } from "../pages/signage/meetingRoom/meetingRoomSchedule"
import { SignageMFA } from "../pages/signage/mfa"
import { EmployeePasswordChange } from "../pages/signage/passwordChange"
import { SignageReceptionCompletedPage } from "../pages/signage/reception/completed"
import { SignageReceptionHomePage } from "../pages/signage/reception/home"
import { SignageReceptionMapPage } from "../pages/signage/reception/map"
import { SignageReceptionMenuPage } from "../pages/signage/reception/menu"
import { SignageReceptionNotReservedPage } from "../pages/signage/reception/notReserved"
import { SignageReceptionQrErrorPage } from "../pages/signage/reception/qrError"
import { SignageReceptionQrReaderPage } from "../pages/signage/reception/qrReader"
import { SignageReceptionTooEarlyPage } from "../pages/signage/reception/tooEarly"
// import { SignageReceptionSearchChoicePage } from "../pages/signage/reception/searchChoice"
// import { SignageReceptionSearchCompanyNamePage } from "../pages/signage/reception/searchCompanyName"
// import { SignageReceptionSearchGuestNamePage } from "../pages/signage/reception/searchGuestName"
import { SignageSettingsPage } from "../pages/signage/setting"
import { SignageBranchLeaderRoute } from "./SignageBranchLeaderRoute"
import { SignagePublicRoute } from "./SignagePublicRoute"

export const SignageRouter: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <SignagePublicRoute path="/login" element={<SignageLogin />} />
          }
        />
        <Route
          path="/mfa"
          element={<SignagePublicRoute path="/mfa" element={<SignageMFA />} />}
        />
        <Route
          path="/forgot-password"
          element={
            <SignagePublicRoute
              path="/forgot-password"
              element={<SignageForgotPassword />}
            />
          }
        />
        <Route
          path="/forgot-password/applied"
          element={
            <SignagePublicRoute
              path="/forgot-password/applied"
              element={<SignageForgotPasswordApplied />}
            />
          }
        />
        <Route
          path="/forgot-password/confirm"
          element={
            <SignagePublicRoute
              path="/forgot-password/confirm"
              element={<SignageConfirmForgotPassword />}
            />
          }
        />
        <Route path="/password-change" element={<EmployeePasswordChange />} />
        <Route
          path="/home"
          element={
            <SignageBranchLeaderRoute
              path="/home"
              element={
                <SignageTemplate>
                  <SignageSettingsPage />
                </SignageTemplate>
              }
            />
          }
        />
        <Route
          path="/floors/:floor_id/map"
          element={
            <SignageBranchLeaderRoute
              path="/floors/:floor_id/map"
              element={
                <SignageTemplate>
                  <SignageFloorMapPage />
                </SignageTemplate>
              }
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/home"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/home"
              element={<SignageReceptionHomePage />}
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/menu"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/menu"
              element={<SignageReceptionMenuPage />}
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/map"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/map"
              element={<SignageReceptionMapPage />}
            />
          }
        />
        {/* <Route
          path="/reception/searchChoice"
          element={
            <SignageBranchLeaderRoute
              path="/reception/searchChoice"
              element={<SignageReceptionSearchChoicePage />}
            />
          }
        /> */}
        {/* <Route
          path="/reception/searchCompanyName"
          element={
            <SignageBranchLeaderRoute
              path="/reception/searchCompanyName"
              element={<SignageReceptionSearchCompanyNamePage />}
            />
          }
        />
        <Route
          path="/reception/searchGuestName"
          element={
            <SignageBranchLeaderRoute
              path="/reception/searchGuestName"
              element={<SignageReceptionSearchGuestNamePage />}
            />
          }
        /> */}
        <Route
          path="/branches/:branch_id/reception/qr-reader"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/qr-reader"
              element={<SignageReceptionQrReaderPage />}
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/not-reserved"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/not-reserved"
              element={<SignageReceptionNotReservedPage />}
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/qr-error"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/not-reserved"
              element={<SignageReceptionQrErrorPage />}
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/completed"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/completed"
              element={<SignageReceptionCompletedPage />}
            />
          }
        />
        <Route
          path="/branches/:branch_id/reception/too-early"
          element={
            <SignageBranchLeaderRoute
              path="/branches/:branch_id/reception/too-early"
              element={<SignageReceptionTooEarlyPage />}
            />
          }
        />
        <Route
          path="/meeting-rooms/:meeting_room_id"
          element={
            <SignageBranchLeaderRoute
              path="/meeting-rooms/:meeting_room_id"
              element={
                <SignageTemplate>
                  <SignageMeetingRoomPage />
                </SignageTemplate>
              }
            />
          }
        />
        <Route
          path="/meeting-rooms/:meeting_room_id/schedules/:schedule_id"
          element={
            <SignageBranchLeaderRoute
              path="/meeting-rooms/:meeting_room_id/schedules/:schedule_id"
              element={
                <SignageTemplate>
                  <SignageMeetingRoomSchedulePage />
                </SignageTemplate>
              }
            />
          }
        />
      </Routes>
    </>
  )
}
