import React from "react"

import { Box, Typography, styled } from "@mui/material"

import { theme } from "../../../../config/theme"
import { SignageMeetingRoomType } from "../../../../models/signage/useMeetingRoom/type"

const NoImageBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const NoImageTypography = styled(Typography)(() => ({
  color: "#707070",
  fontWeight: "bold",
  letterSpacing: "1rem",
}))

const MeetingRoomNameBox = styled(Box)(() => ({
  position: "absolute",
  bottom: theme.spacing(7),
  marginLeft: theme.spacing(4),
}))

const MeetingRoomNameTypography = styled(Typography)(() => ({
  color: "white",
  fontSize: "70px",
  textShadow: "1px 1px 2px gray",
  letterSpacing: theme.spacing(1),
}))

const MeetingRoomCodeTypography = styled(Typography)(() => ({
  color: "white",
  fontSize: theme.spacing(3),
  textShadow: "1px 1px 2px gray",
  letterSpacing: theme.spacing(1),
}))

interface Props {
  meetingRoomData: SignageMeetingRoomType
  meetingRoomImageUrl: string
}

export const MeetingRoomImage: React.FC<Props> = ({
  meetingRoomData,
  meetingRoomImageUrl,
}) => {
  const meetingRoomCode =
    "M" + ("0" + meetingRoomData.meeting_room_code).slice(-2)
  return (
    <>
      {meetingRoomImageUrl ? (
        <img
          src={meetingRoomImageUrl}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <NoImageBox>
          <NoImageTypography variant="h4">NO IMAGE</NoImageTypography>
        </NoImageBox>
      )}
      <MeetingRoomNameBox>
        <MeetingRoomNameTypography>
          {meetingRoomData.meeting_room_name}
        </MeetingRoomNameTypography>
        <MeetingRoomCodeTypography>{meetingRoomCode}</MeetingRoomCodeTypography>
      </MeetingRoomNameBox>
    </>
  )
}
