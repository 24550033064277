import React, { useContext, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import PermIdentityIcon from "@mui/icons-material/PermIdentity"
import {
  Box,
  Button,
  Checkbox,
  Modal,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { useGuest } from "../../../../../models/employee/useGuest"
import {
  ScheduleForm,
  ScheduleFormUserable,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import {
  userableSearchRequestState,
  useScheduleUserableSearch,
} from "../../../../../models/employee/useSchedule/useScheduleUserableSearch"
import { SearchUserable } from "../../../../../models/employee/useSchedule/useScheduleUserableSearch/type"
import { AuthContext } from "../../../../../providers/AuthProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleShare/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleShare/ja"
import { ShareScheduleGuestForm } from "../ShareScheduleGuestForm"

const CustomGuestModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "50%",
  Height: "70%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(5, 10),
  borderRadius: theme.spacing(1),
}))

export const ShareScheduleComponent = () => {
  const { control, setValue, watch, getValues } = useFormContext<ScheduleForm>()
  const whoIsWheres = getValues("schedule.share_schedule.who_is_wheres")
  const [isUserable, setIsUserable] = useState<boolean>(false)
  const [isGuestModalOpen, setIsGuestModalOpen] = useState<boolean>(false)
  const { currentUser } = useContext(AuthContext)
  const { userableSearchRequest, setUserableSearchRequest } =
    userableSearchRequestState()
  const { userableSearch, scheduleUserableSearch } = useScheduleUserableSearch()
  const guestObject = useGuest()
  const guestForm = guestObject.form
  // ゲストが招待されていない場合Mailアイコンを非表示に(ゲストが入館しないため)
  const isGuestIncludedToMembers = !!getValues(
    "schedule.share_schedule.who_is_wheres"
  ).find((whoIsWhere) => {
    return whoIsWhere.userable.userable_type === "Guest"
  })
  // 会議室が予約されていない場合Mailアイコンを非表示に(ゲストが入館しないため)
  const isMeetingReserved = !!getValues(
    "schedule.share_schedule.who_is_wheres"
  ).find((whoIsWhere) => {
    return whoIsWhere.reservable.reservable_type === "MeetingRoom"
  })

  const handleSelectUserClick = (userable: SearchUserable) => {
    setIsUserable(false)
    setUserableSearchRequest("")
    setValue("schedule.share_schedule.who_is_wheres", [
      ...whoIsWheres,
      {
        userable: {
          userable_id: userable.id,
          last_name: userable.last_name,
          first_name: userable.first_name,
          email: userable.email,
          userable_type: "Employee",
          company_name: undefined,
          should_send_guest_reception_notification_mail: false,
        },
        reservable: {
          reservable_id: undefined,
          name: undefined,
          reservable_type: undefined,
        },
      },
    ])
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // key入力時に予測候補を表示する
  const handleSearchUserable = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/ /g, "")
    setIsUserable(true)
    setUserableSearchRequest(value)
    scheduleUserableSearch(value)
    if (value === "") {
      setIsUserable(false)
    }
  }

  // 共同予定者の削除
  const handleDeleteUserClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setValue(
      "schedule.share_schedule.who_is_wheres",
      getValues("schedule.share_schedule.who_is_wheres").filter(
        (whoIsWhere) => {
          return whoIsWhere.userable.email != event.currentTarget.value
        }
      )
    )
    watch("schedule.share_schedule.who_is_wheres")
  }

  const handleGuestFix = () => {
    const values = guestForm.getValues()
    setValue("schedule.share_schedule.who_is_wheres", [
      ...whoIsWheres,
      {
        userable: {
          userable_id: undefined,
          last_name: values.last_name,
          first_name: values.first_name,
          email: values.email,
          userable_type: "Guest",
          company_name: values.company_name,
          should_send_guest_reception_notification_mail: false, // ゲストの場合はそもそも通知しないのでfalse
        },
        reservable: {
          reservable_id: undefined,
          name: undefined,
          reservable_type: undefined,
        },
      },
    ])
    handleGuestModalClose()
  }

  const handleGuestModalOpen = () => {
    guestForm.clearErrors()
    setIsGuestModalOpen(true)
  }

  const handleGuestModalClose = () => {
    // モーダルを閉じる時に入力した情報をリセットする
    guestForm.resetField("last_name")
    guestForm.resetField("first_name")
    guestForm.resetField("company_name")
    guestForm.resetField("email")
    setIsGuestModalOpen(false)
  }

  const handleShouldSendGuestReceptionMailToggle = (
    userable: ScheduleFormUserable
  ) => {
    setValue(
      "schedule.share_schedule.who_is_wheres",
      getValues("schedule.share_schedule.who_is_wheres").map((whoIsWhere) => {
        if (
          whoIsWhere.userable.userable_type === userable.userable_type &&
          whoIsWhere.userable.userable_id === userable.userable_id
        ) {
          return {
            userable: {
              ...whoIsWhere.userable,
              should_send_guest_reception_notification_mail:
                !whoIsWhere.userable
                  .should_send_guest_reception_notification_mail,
            },
            reservable: { ...whoIsWhere.reservable },
          }
        } else {
          return whoIsWhere
        }
      })
    )
  }

  return (
    <>
      <Box display="flex">
        <Box display="flex" alignItems="center">
          <PermIdentityIcon sx={{ fontSize: "25px", color: "#22BA9D" }} />
        </Box>
        <Box
          sx={{
            color: "#22BA9D",
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Noto Sans",
            marginLeft: "1rem",
          }}
        >
          {translations.InviteOthers}
        </Box>
      </Box>
      <Box display="flex" marginTop="1rem">
        <Box>
          <Box display="flex" alignItems="center" my="1rem">
            <Box
              sx={{
                lineHeight: "30px",
                color: "#22BA9D",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "Noto Sans",
              }}
            >
              {translations.Attendees}
            </Box>
            <Box
              sx={{
                display: "inline-block",
                borderRadius: "50%",
                textAlign: "center",
                height: "30px",
                lineHeight: "30px",
                width: "30px",
                color: "#22BA9D",
                fontSize: "12px",
                fontWeight: "bold",
                fontFamily: "Noto Sans",
                backgroundColor: "rgb(34, 186, 157, 0.1)",
                marginLeft: "1rem",
              }}
            >
              {whoIsWheres.length != 0 ? whoIsWheres.length : 0}
            </Box>
          </Box>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <TextField
              fullWidth
              value={userableSearchRequest}
              label={translations.AddEmployee}
              onChange={handleSearchUserable}
              InputLabelProps={{
                sx: {
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                },
              }}
              InputProps={{
                sx: {
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 15px",
                  },
                },
              }}
            />
            {isUserable && (
              <Box
                aria-label="共有スケジュール"
                tabIndex={-1}
                role="listbox"
                sx={{
                  border: "1px",
                  borderColor: "rgb(0 0 0 / 50%)",
                  boxShadow: "0 2px 10px 0 rgb(0 0 0 / 50%)",
                  height: "150px",
                  overflowY: "scroll",
                  backgroundColor: "#fff",
                  position: "absolute",
                  zIndex: "1000",
                  top: "60px",
                  display: "block",
                  width: "100%",
                }}
              >
                {userableSearch.map((userable, index) => {
                  if (
                    whoIsWheres.some((whoIsWhere) => {
                      return whoIsWhere.userable.email === userable.email
                    })
                  ) {
                    return
                  }
                  return (
                    <Box
                      key={index}
                      role="option"
                      tabIndex={-1}
                      onMouseOver={(e) => {
                        const list = e.currentTarget.parentElement?.children
                        if (list) {
                          Array.from(list).forEach((child) => {
                            child.setAttribute("aria-selected", "false")
                          })
                        }
                        e.currentTarget.setAttribute("aria-selected", "true")
                      }}
                      onClick={() => {
                        handleSelectUserClick(userable)
                      }}
                      aria-selected={false}
                      sx={{
                        '&[aria-selected="true"]': {
                          backgroundColor: "rgb(0 0 0 / 10%)",
                        },
                        padding: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography>
                        {userable.last_name} {userable.first_name}
                      </Typography>
                      <Typography fontSize="12px">{userable.email}</Typography>
                    </Box>
                  )
                })}
              </Box>
            )}
          </Box>

          <Box display="flex">
            <Controller
              control={control}
              name="schedule.share_schedule.notification"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  name="notification"
                  checked={
                    watch("schedule.share_schedule.notification") == "auto"
                  }
                  onChange={() => {
                    setValue("schedule.share_schedule.notification", "auto")
                  }}
                />
              )}
            />
            <Box
              display="flex"
              alignItems="center"
              fontSize="12px"
              fontFamily="Noto Sans"
            >
              {translations.AttendanceRequired}
            </Box>
          </Box>
          <Box display="flex">
            <Controller
              control={control}
              name="schedule.share_schedule.notification"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  name="notification"
                  checked={
                    watch("schedule.share_schedule.notification") == "manual"
                  }
                  onChange={() => {
                    setValue("schedule.share_schedule.notification", "manual")
                  }}
                />
              )}
            />
            <Box
              display="flex"
              alignItems="center"
              fontSize="12px"
              fontFamily="Noto Sans"
            >
              {translations.Attendanceoptional}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: "15px",
          }}
        >
          <Box mt="0.5rem" mb="1rem">
            <Button
              variant="contained"
              onClick={handleGuestModalOpen}
              sx={{
                fontSize: "14px",
                fontFamily: "Noto Sans",
              }}
            >
              {translations.InviteExternalGuests}
            </Button>
            <Modal open={isGuestModalOpen} onClose={handleGuestModalClose}>
              <CustomGuestModalBox>
                <ShareScheduleGuestForm
                  guestForm={guestForm}
                  handleGuestFix={handleGuestFix}
                  handleClose={handleGuestModalClose}
                />
              </CustomGuestModalBox>
            </Modal>
          </Box>
          {/* NOTE: 従業員→ゲストの順になるようにしたい */}
          <Box
            sx={{
              height: "150px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            {whoIsWheres.length != 0 ? (
              whoIsWheres.map((whoIsWhere, index) => (
                <Box key={index} display="flex" marginTop="0.5rem">
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle2"
                      textOverflow="ellipsis"
                      width="8.5rem"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {whoIsWhere.userable.last_name}
                      {whoIsWhere.userable.first_name}
                      {whoIsWhere.userable.userable_type === "Guest" && (
                        <span>{translations.gest}</span>
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      width: "2rem",
                      display: "flex",
                      justifyContent: "flex-end",
                      right: "0.2rem",
                    }}
                  >
                    <Box>
                      {whoIsWhere.userable.email !==
                        currentUser?.attributes.email &&
                        whoIsWhere.userable.userable_type == "Employee" &&
                        isGuestIncludedToMembers &&
                        isMeetingReserved && (
                          <Tooltip
                            title="ゲスト入館時に通知を送信"
                            placement="bottom"
                            disableInteractive
                          >
                            <Button
                              sx={{
                                padding: "0",
                                margin: "0",
                                minWidth: "0",
                              }}
                              onClick={() =>
                                handleShouldSendGuestReceptionMailToggle(
                                  whoIsWhere.userable
                                )
                              }
                            >
                              <MailOutlineIcon
                                color={
                                  whoIsWhere.userable
                                    .should_send_guest_reception_notification_mail
                                    ? "primary"
                                    : "disabled"
                                }
                              />
                            </Button>
                          </Tooltip>
                        )}
                    </Box>
                    <Box>
                      {whoIsWhere.userable.email !==
                        currentUser?.attributes.email && (
                        <Button
                          sx={{
                            padding: "0",
                            margin: "0",
                            minWidth: "0",
                          }}
                          value={whoIsWhere.userable.email}
                          onClick={(event) => {
                            handleDeleteUserClick(event)
                          }}
                        >
                          <HighlightOffIcon />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
