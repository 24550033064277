import React, { memo } from "react"

import Typography from "@mui/material/Typography"

interface Props {
  title: string
}

export const ContentTitle: React.FC<Props> = memo(({ title }: Props) => {
  return (
    <>
      <Typography
        fontSize="18px"
        color="#454545"
        fontWeight="bold"
        letterSpacing="1.2px"
      >
        {title}
      </Typography>
    </>
  )
})
