import { useCallback, useState } from "react"

export const useAlertMessage = () => {
  const [isOpenMessage, setIsOpenMessage] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>("")

  const openMessage = useCallback(() => setIsOpenMessage(true), [])
  const closeMessage = useCallback(() => setIsOpenMessage(false), [])
  const handleSetMessage = useCallback(
    (message: string) => setAlertMessage(message),
    []
  )

  return {
    isOpenMessage,
    openMessage,
    closeMessage,
    alertMessage,
    handleSetMessage,
  }
}
