import React, { useState } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  Grid,
  Typography,
  styled,
  TextField,
  InputLabel,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material"

import { searchAddressRequest } from "../../../../api/public/searchAdressRequest"
import {
  CompanyFormType,
  HeaderCompanyType,
} from "../../../../models/company/useCompany/type"
import {
  prefectureList,
  searchPrefectureBasedOnName,
} from "../../../../utils/prefecture"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { RequiredFlag } from "../../../public/RequiredFlag"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
  overflowY: "scroll",
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(70),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: theme.spacing(2),
  padding: theme.spacing(1, 8),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

interface Props {
  companyRequestInfo: CompanyFormType
  control: Control<CompanyFormType>
  handleSubmit: UseFormHandleSubmit<CompanyFormType>
  onSubmit: SubmitHandler<CompanyFormType>
  errors: FieldErrors<CompanyFormType>
  isValid: boolean
  getValues: UseFormGetValues<CompanyFormType>
  setValue: UseFormSetValue<CompanyFormType>
  open: boolean
  errorMessages: string[]
  headerCompanyInfo?: HeaderCompanyType
}

export const CompanyInfoRegistrationInfoForm = ({
  headerCompanyInfo,
  control,
  handleSubmit,
  onSubmit,
  errors,
  isValid,
  getValues,
  setValue,
}: Props) => {
  const [addressSearchError, setAddressSearchError] = useState<boolean>(false)
  const navigate = useNavigate()

  // 住所を検索して自動入力する
  const handleAddressSearch = async (postalCode: string) => {
    try {
      const response = await searchAddressRequest({
        postalCode: postalCode,
      })

      if (response.data && response.status === 200) {
        const searchResult = response.data.results[0] // resultsは配列みたいだが、基本複数ヒットしない前提で最初の1件目を取得する
        setValue(
          "company_prefecture_id",
          searchPrefectureBasedOnName(searchResult.address1) as number
        )
        setValue(
          "company_city",
          `${searchResult.address2}${searchResult.address3}`
        )
      }
    } catch (error) {
      setAddressSearchError(true)
    }
  }

  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={1}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      {headerCompanyInfo ? (
        <>
          <CustomGridItem item container spacing={4}>
            <CustomGridItem item>
              <Box textAlign="center">
                <Typography>すでに利用登録がお済みのようです。</Typography>
                <Typography>
                  以下のボタンからWORK AGILEの利用を開始してください。
                </Typography>
              </Box>
            </CustomGridItem>
            <Grid item>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <CustomButton
                  onClick={() => navigate("/company/home")}
                  variant="contained"
                >
                  WORK AGILEの利用を開始する
                </CustomButton>
              </Box>
            </Grid>
          </CustomGridItem>
        </>
      ) : (
        <>
          <CustomGridItem item container spacing={4}>
            <CustomGridItem item>
              <Box textAlign="center">
                <Typography>法人・組織・チームが活用する</Typography>
                <Typography>
                  アカウント情報・法人本拠地住所を登録してください
                </Typography>
              </Box>
            </CustomGridItem>
          </CustomGridItem>
          <CustomGridItem
            item
            container
            justifyContent="space-around"
            spacing={2}
            sx={{
              height: "80vh", // フォームバリデーションに引っかかったときに要素が多すぎて縦スクロールが必要になった。デザイン当て込むときに何やかんや修正するので一旦これで放置
            }}
          >
            <CustomGridItem item container spacing={2}>
              <CustomGridItem item container xs={12} sm={6} spacing={2}>
                <CustomGridItem item xs={12} sm={5}>
                  <InputLabel htmlFor="last_name">
                    <Typography color="primary" fontWeight="bold">
                      姓
                      <RequiredFlag />
                    </Typography>
                  </InputLabel>
                </CustomGridItem>
                <Grid item xs={12} sm={7}>
                  <Controller
                    control={control}
                    name="last_name"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.last_name}
                        helperText={errors.last_name?.message}
                        id="last_name"
                        name="last_name"
                        label="ご氏名（姓）"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </CustomGridItem>
              <CustomGridItem item container xs={12} sm={6} spacing={2}>
                <CustomGridItem item xs={12} sm={5}>
                  <InputLabel htmlFor="first_name">
                    <Typography color="primary" fontWeight="bold">
                      名
                      <RequiredFlag />
                    </Typography>
                  </InputLabel>
                </CustomGridItem>
                <Grid item xs={12} sm={7}>
                  <Controller
                    control={control}
                    name="first_name"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.first_name}
                        helperText={errors.first_name?.message}
                        id="first_name"
                        name="first_name"
                        label="ご氏名（名）"
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </CustomGridItem>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={6} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="last_name_kana">
                  <Typography color="primary" fontWeight="bold">
                    セイ
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="last_name_kana"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.last_name_kana}
                      helperText={errors.last_name_kana?.message}
                      id="last_name_kana"
                      name="last_name_kana"
                      label="ご氏名（セイ）"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={6} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="first_name_kana">
                  <Typography color="primary" fontWeight="bold">
                    メイ
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="first_name_kana"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.first_name_kana}
                      helperText={errors.first_name_kana?.message}
                      id="first_name_kana"
                      name="first_name_kana"
                      label="ご氏名（メイ）"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_name">
                  <Typography color="primary" fontWeight="bold">
                    法人/チーム名
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_name}
                      helperText={errors.company_name?.message}
                      id="company_name"
                      name="company_name"
                      label="法人/チーム名"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_name_kana">
                  <Typography color="primary" fontWeight="bold">
                    法人/チーム名 カナ
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="company_name_kana"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_name_kana}
                      helperText={errors.company_name_kana?.message}
                      id="company_name_kana"
                      name="company_name_kana"
                      label="法人/チーム名（カナ）"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_postal_code">
                  <Typography color="primary" fontWeight="bold">
                    法人本拠地住所
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="company_postal_code"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_postal_code}
                      helperText={errors.company_postal_code?.message}
                      id="company_postal_code"
                      name="company_postal_code"
                      label="〒000-0000"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                {addressSearchError && (
                  <FormHelperText error>
                    住所の検索に失敗しました
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    !getValues("company_postal_code") ||
                    !!errors.company_postal_code
                  }
                  onClick={() =>
                    handleAddressSearch(getValues("company_postal_code"))
                  }
                >
                  住所自動入力
                </Button>
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_prefecture_id">
                  <Typography color="primary" fontWeight="bold">
                    都道府県
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <CustomGridItem item xs={12} sm={7}>
                <FormControl sx={{ width: 400 }}>
                  <InputLabel htmlFor="company_prefecture_id">
                    都道府県
                  </InputLabel>
                  <Controller
                    control={control}
                    name="company_prefecture_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={!!errors.company_prefecture_id}
                        id="company_prefecture_id"
                        name="company_prefecture_id"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        IconComponent={GreenArrowIcon}
                      >
                        <MenuItem disabled={true} key={0} value={0}>
                          都道府県を選択してください
                        </MenuItem>
                        {prefectureList.map((prefecture) => (
                          <MenuItem key={prefecture.id} value={prefecture.id}>
                            {prefecture.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText
                    error={!!errors.company_prefecture_id?.message}
                  >
                    {errors.company_prefecture_id?.message}
                  </FormHelperText>
                </FormControl>
              </CustomGridItem>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_city">
                  <Typography color="primary" fontWeight="bold">
                    市区町村
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="company_city"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_city}
                      helperText={errors.company_city?.message}
                      id="company_city"
                      name="company_city"
                      label="市区町村"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_house_number">
                  <Typography color="primary" fontWeight="bold">
                    番地
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="company_house_number"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_house_number}
                      helperText={errors.company_house_number?.message}
                      id="company_house_number"
                      name="company_house_number"
                      label="番地"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_building_name">
                  <Typography color="primary" fontWeight="bold">
                    マンション・ビル
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="company_building_name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_building_name}
                      helperText={errors.company_building_name?.message}
                      id="company_building_name"
                      name="company_building_name"
                      label="マンション・ビル"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <CustomGridItem item container xs={12} sm={12} spacing={2}>
              <CustomGridItem item xs={12} sm={5}>
                <InputLabel htmlFor="company_tel">
                  <Typography color="primary" fontWeight="bold">
                    電話番号
                    <RequiredFlag />
                  </Typography>
                </InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={7}>
                <Controller
                  control={control}
                  name="company_tel"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.company_tel}
                      helperText={errors.company_tel?.message}
                      id="company_tel"
                      name="company_tel"
                      label="00-0000-0000"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
            </CustomGridItem>
            <Grid item>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                >
                  登録
                </CustomButton>
              </Box>
            </Grid>
          </CustomGridItem>
        </>
      )}
    </CustomGridContainer>
  )
}
