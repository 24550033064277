import React, { memo, useEffect, useState } from "react"
import Moveable from "react-moveable"

import { Box } from "@mui/material"

import { AreaType } from "../../../../models/company/useLayout/type"
import { areaIconStyle, areaStyle } from "../../../../styles/areaStyle"

interface Props {
  area: AreaType
  isAreaMoveable: boolean
  handleAreaCoordinate: (
    targetArea: AreaType,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => void
}

/*
  レイアウト画像にア会議室のアイコンが表示される
*/
export const CompanyLayoutArea: React.FC<Props> = memo(
  ({ area, isAreaMoveable, handleAreaCoordinate }: Props) => {
    const layoutImage = document.getElementById(
      "layout-image"
    ) as HTMLImageElement
    const layoutImageWidth = layoutImage?.width
    const layoutImageHeight = layoutImage?.height
    const areaId = area.id
    const areaName = area.area_name
    const isAreaToMeetingRoom = area.is_area_to_meeting_room
    const areaToMeetingRoom = area.area_to_meeting_room
    const initAreaStyle = areaStyle(
      area.start_x,
      area.start_y,
      area.end_x,
      area.end_y,
      area.color_code
    )

    const throttles = { drag: 1, resize: 10, rotate: 90 }
    const bounds = {
      left: 0,
      top: 0,
      right: layoutImageWidth,
      bottom: layoutImageHeight,
    }
    const [privateProps, setPrivateProps] = useState(initAreaStyle)
    const [areaTarget, setAreaTarget] = useState<HTMLElement | null>()
    const [areaStyleObject, setAreaStyleObject] = useState(initAreaStyle)

    useEffect(() => {
      setAreaTarget(document.getElementById(`A${String(areaId)}`))
    }, [areaId])

    useEffect(() => {
      setAreaStyleObject({
        ...areaStyleObject,
        borderColor: area.color_code,
        top: area.start_y,
        left: area.start_x,
        height: area.end_y - area.start_y,
        width: area.end_x - area.start_x,
      })
    }, [])

    return (
      <React.Fragment>
        <Box
          id={`A${String(areaId)}`}
          className={"area"}
          sx={{ ...areaStyleObject, borderColor: area.color_code }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: area.color_code,
              fontSize: "30px",
            }}
          >
            {areaName}
          </div>
          {isAreaToMeetingRoom && areaToMeetingRoom && (
            <>
              <span
                style={areaIconStyle(area.color_code) as React.CSSProperties}
              >
                M
              </span>
              <span
                style={{
                  top: "60px",
                  left: "60px",
                  color: area.color_code,
                  position: "absolute",
                  fontSize: "30px",
                }}
              >
                {areaToMeetingRoom.meeting_room_code}
              </span>
            </>
          )}
        </Box>
        {isAreaMoveable ? (
          <Moveable
            target={areaTarget}
            draggable={true}
            resizable={true}
            snappable={true}
            throttleDrag={throttles.drag}
            throttleRotate={throttles.rotate}
            bounds={bounds}
            edge={false}
            zoom={1}
            origin={false}
            padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
            onDrag={({ target, transform }) => {
              target.style.transform = transform
            }}
            onDragEnd={() => {
              const targetElement = document.getElementById(
                "layout-image"
              ) as HTMLImageElement
              const clientRect =
                targetElement.getBoundingClientRect() as DOMRect
              const positionX = clientRect.left + window.pageXOffset
              const positionY = clientRect.top + window.pageYOffset

              const areaElement = document.getElementById(
                `A${String(areaId)}`
              ) as HTMLImageElement

              const areaRect = areaElement.getBoundingClientRect() as DOMRect

              const x = areaRect.x - positionX
              const y = areaRect.y - positionY

              const transformComponent = document.getElementsByClassName(
                "react-transform-component"
              )

              const transform = transformComponent[0] as HTMLElement
              const transformStyle = transform.style.transform.split("scale")[1]
              const scale = transformStyle.replace("(", "").replace(")", "")

              const start_x = x / parseFloat(scale)
              const start_y = y / parseFloat(scale)

              handleAreaCoordinate(
                area,
                start_x,
                start_y,
                start_x + privateProps.width,
                start_y + privateProps.height
              )
            }}
            onResize={({ target, width, height, delta, drag }) => {
              // ドラッグして移動させた情報を取得
              const beforeTranslate = drag.beforeTranslate
              // 移動させた情報に即して位置を変更するためにtranslateを使用
              // beforeTranslate[0]: x座標の移動距離
              // beforeTranslate[1]: y座標の移動距離
              target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`

              // delta[0]: x座標の変化量
              // delta[1]: y座標の変化量
              delta[0] && (target.style.width = `${width}px`)
              delta[1] && (target.style.height = `${height}px`)
              setPrivateProps({
                ...privateProps,
                width: width,
                height: height,
              })
            }}
            onResizeEnd={() => {
              const targetElement = document.getElementById(
                "layout-image"
              ) as HTMLImageElement
              const clientRect =
                targetElement.getBoundingClientRect() as DOMRect
              const positionX = clientRect.left + window.pageXOffset
              const positionY = clientRect.top + window.pageYOffset

              const areaElement = document.getElementById(
                `A${String(areaId)}`
              ) as HTMLImageElement

              const areaRect = areaElement.getBoundingClientRect() as DOMRect

              const x = areaRect.x - positionX
              const y = areaRect.y - positionY

              const transformComponent = document.getElementsByClassName(
                "react-transform-component"
              )

              const transform = transformComponent[0] as HTMLElement
              const transformStyle = transform.style.transform.split("scale")[1]
              const scale = transformStyle.replace("(", "").replace(")", "")

              const start_x = x / parseFloat(scale)
              const start_y = y / parseFloat(scale)

              handleAreaCoordinate(
                area,
                start_x,
                start_y,
                start_x + privateProps.width,
                start_y + privateProps.height
              )
            }}
          />
        ) : null}
      </React.Fragment>
    )
  }
)
