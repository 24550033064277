import { translations } from "@aws-amplify/ui-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
import * as Yup from "yup"

import { useState, useCallback, useContext } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/employeeSettings/employeeSettingsMain/en"
import jaTranslations from "../../../../translations/employeeSettings/employeeSettingsMain/ja"
import { pageType } from "../../../../utils/location"
import { PIN_CODE } from "../../../../utils/regular"
import { MFAType } from "./type"

// 現在設定されているMFAの設定。AWSからの戻り値
type CurrentMFAType = "NOMFA" | "SOFTWARE_TOKEN_MFA" | "SMS_MFA"

const authFormData: MFAType = {
  pinCode: "",
}

export const useMFA = () => {
  const navigate = useNavigate()
  const [authData] = useState<MFAType>(authFormData)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  Amplify.configure(awsmobile)
  const [qrCodeUrl, setQRCodeUrl] = useState<string>("")
  const [currentMFASettings, setCurrentMFASettings] =
    useState<CurrentMFAType>("NOMFA")
  const [loading, setLoading] = useState<boolean>(false)
  const { currentUser, setIsAuthenticated } = useContext(AuthContext)
  // 言語切り替え
  const { language } = useLanguage()
  const currentTranslations =
    language === "en" ? enTranslations : jaTranslations

  const basicSchema = Yup.object().shape({
    pinCode: Yup.string()
      .required(translations.Required)
      .length(6, translations.digitsCode)
      .matches(PIN_CODE, translations.halfWidthNumbers),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      pinCode: authData.pinCode,
    },
    resolver: yupResolver(basicSchema),
  })

  // QRコードURLの取得
  const fetchQRCodeUrl = useCallback(async () => {
    try {
      await Auth.setupTOTP(currentUser).then((code) => {
        const issuer = encodeURI("WORKAGILE") // トークンの発行者(通常はサービス名が入る)
        // 以下のURLで「WORKAGILE・ユーザーのメールアドレス」の形式でAuthyに表示される
        // TODO
        // アイコン画像入手次第iconパラメータを付与する
        const url =
          "otpauth://totp/WORKAGILE:" +
          currentUser?.attributes.email +
          "?secret=" +
          code +
          "&issuer=" +
          issuer
        setQRCodeUrl(url) // QRコードのURLを設定
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  // ログイン中のユーザーがMFA登録済みかどうか取得する
  const fetchCurrentUserMFASettings = useCallback(async () => {
    try {
      await Auth.getPreferredMFA(currentUser, { bypassCache: false }).then(
        (data) => {
          if (data !== "NOMFA") {
            // dataがNOMFAではない場合は2段階認証設定済みとみなす
            setCurrentMFASettings(data as CurrentMFAType)
          }
        }
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  // デバイス登録
  const onMFASetup: SubmitHandler<MFAType> = useCallback(async (data) => {
    try {
      await Promise.all([
        await Auth.verifyTotpToken(currentUser, data.pinCode), // tokenの検証
        await Auth.setPreferredMFA(currentUser, "TOTP"), // 検証後MFAの設定を行う
      ]).then(() => {
        // フラッシュメッセージの表示
        localStorage.setItem("alertContent", "2段階認証の設定が完了しました")
        window.location.reload()
      })
    } catch (error) {
      setOpen(true)
      setErrorMessages([currentTranslations.NotTwoFactor])
    }
  }, [])

  // ログイン時の2要素認証
  const onConfirmSignIn: SubmitHandler<MFAType> = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Auth.confirmSignIn(
        currentUser,
        data.pinCode,
        "SOFTWARE_TOKEN_MFA"
      )
      if (response.signInUserSession) {
        setIsAuthenticated(true)
        localStorage.setItem("login", "homeLogin")
        navigate(`/${pageType}/home`)
        // const passwordExpireTimes = new Date(
        //   passwordChangeTimes.getFullYear(),
        //   passwordChangeTimes.getMonth(),
        //   passwordChangeTimes.getDay()
        // )
        // console.log(passwordExpireTimes)
        // if (new Date() < passwordExpireTimes) {
        //   localStorage.setItem("login", "homeLogin")
        //   navigate(`/${pageType}/home`)
        // } else {
        //   localStorage.setItem("login", "homeLogin")
        //   localStorage.setItem("expiredChangePassword", "true")
        //   navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
        // }
      }
    } catch (error) {
      console.log(error)
      setOpen(true)
      setErrorMessages(["認証に失敗しました"])
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    control,
    authData,
    errors,
    isValid,
    handleSubmit,
    onMFASetup,
    getValues,
    setValue,
    open,
    errorMessages,
    fetchQRCodeUrl,
    qrCodeUrl,
    currentMFASettings,
    fetchCurrentUserMFASettings,
    onConfirmSignIn,
    loading,
  }
}
