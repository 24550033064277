import { Storage, Amplify } from "aws-amplify"

import { useCallback, useState } from "react"
import Resizer from "react-image-file-resizer"

import {
  createIconRequest,
  fetchIconRequest,
} from "../../../api/public/iconRequest"
import awsmobile from "../../../aws-exports"
import { HEADER_ICON_HEIGHT, HEADER_ICON_WIDTH } from "../../../utils/icon"

Amplify.configure(awsmobile)

export const useIcon = () => {
  const [imageUrl, setImageUrl] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState<string>("")

  // アイコンのリサイズ
  const resizeFile = async (file: Blob) => {
    return new Promise((resolve) => {
      try {
        Resizer.imageFileResizer(
          file, // 画像ファイルのパス
          HEADER_ICON_WIDTH, // 最大幅
          HEADER_ICON_HEIGHT, // 最大高さ
          file.type, // ファイル形式
          100, // 圧縮の倍率。特に設定しない場合は、100になる。
          0, // 画像の回転角度
          (uri) => {
            resolve(uri)
          },
          "blob" // blobに変換
        )
      } catch (error) {
        alert(".jpeg.pngの画像を選択してください")
        throw error
      }
    })
  }

  // S3に画像の投稿
  const uploadFile = async (
    file: Blob,
    companyId: number,
    employeeId: number
  ) => {
    const customPrefix = `company${companyId}/icons/employee${employeeId}/`

    if (!file) return

    try {
      const fileName = Date.now() + "." + file.type.replace(/(.*)\//g, "")
      const result = await Storage.put(fileName, file, {
        level: "public",
        contentType: file.type,
        customPrefix: {
          public: customPrefix,
        },
      })
      if (result) {
        return result
      }
    } catch (error) {
      console.log(error)
    }
  }

  // S3から画像の参照
  const fetchFile = useCallback(
    async (companyId: number, employeeId: number) => {
      setLoading(true)
      const customPrefix = `company${companyId}/icons/employee${employeeId}/`

      try {
        const response = await fetchIconRequest({ id: employeeId }) // ファイル名をemployeesテーブルから取得

        if (response.status === 200 && response.data) {
          const storageImageUrl = await Storage.get(response.data, {
            level: "public",
            customPrefix: {
              public: customPrefix,
            },
          })
          setImageUrl(storageImageUrl)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // S3に画像投稿＋DBにファイル名保存
  const handleCreateIcon = async (
    file: Blob,
    companyId: number,
    employeeId: number
  ) => {
    try {
      setLoading(true)
      const resizedFile = await resizeFile(file) // TODO resize必要？？この後の実装で判断する。とりあえず拡張子の制限は欲しい
      const uploadedFile = await uploadFile(
        resizedFile as Blob,
        companyId,
        employeeId
      )

      if (!uploadedFile) return

      const { error } = await createIconRequest({
        icon: uploadedFile.key || "",
        id: employeeId,
      })
      if (!error) {
        fetchFile(companyId, employeeId).then(() => {
          setUploaded(true)
          setUploadSuccessMessage("アイコンの登録が完了しました")
        })
      } else {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    resizeFile,
    uploadFile,
    fetchFile,
    imageUrl,
    loading,
    setLoading,
    handleCreateIcon,
    uploaded,
    uploadSuccessMessage,
    setUploaded,
    setImageUrl,
  }
}
