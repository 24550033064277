import { useState } from "react"

export const monthStatus = (today: Date = new Date()) => {
  const beginningOfThisMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  )
  const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  const [beginningOfMonth, setBeginningOfMonth] =
    useState<Date>(beginningOfThisMonth)
  const [endOfMonth, setEndOfMonth] = useState<Date>(endOfThisMonth)

  // 前月の表示開始日と表示最終日を取得
  const previousMonthSchedule = (beginningOfMonth: Date) => {
    const newBeginningOfMonth = new Date(
      beginningOfMonth.getFullYear(),
      beginningOfMonth.getMonth() - 1,
      1
    )
    const newEndOfMonth = new Date(
      beginningOfMonth.getFullYear(),
      beginningOfMonth.getMonth(),
      0
    )
    const newFirstDay = HandleFirstDayOfTargetMonth(newBeginningOfMonth)
    const newLastDay = HandleLastDayOfTargetMonth(newEndOfMonth)
    return { newBeginningOfMonth, newEndOfMonth, newFirstDay, newLastDay }
  }

  // 前月の表示開始日と表示最終日を取得
  const nextMonthSchedule = (beginningOfMonth: Date) => {
    const newBeginningOfMonth = new Date(
      beginningOfMonth.getFullYear(),
      beginningOfMonth.getMonth() + 1,
      1
    )
    const newEndOfMonth = new Date(
      beginningOfMonth.getFullYear(),
      beginningOfMonth.getMonth() + 2,
      0
    )
    const newFirstDay = HandleFirstDayOfTargetMonth(newBeginningOfMonth)
    const newLastDay = HandleLastDayOfTargetMonth(newEndOfMonth)
    return { newBeginningOfMonth, newEndOfMonth, newFirstDay, newLastDay }
  }

  // 対象月の1週目の日曜日を算出
  const HandleFirstDayOfTargetMonth = (beginningOfMonth: Date) => {
    const targetWeekNum = beginningOfMonth.getDay()
    const firstDay = new Date(
      beginningOfMonth.getFullYear(),
      beginningOfMonth.getMonth(),
      beginningOfMonth.getDate() - targetWeekNum
    )
    return firstDay
  }

  // 対象月の最後の週の土曜日を算出
  const HandleLastDayOfTargetMonth = (endOfMonth: Date) => {
    const targetWeekNum = endOfMonth.getDay()
    const lastDay = new Date(
      endOfMonth.getFullYear(),
      endOfMonth.getMonth(),
      endOfMonth.getDate() - targetWeekNum + 6
    )
    return lastDay
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = ("00" + (date.getMonth() + 1)).slice(-2)
    const day = ("00" + date.getDate()).slice(-2)
    return year + "-" + month + "-" + day
  }

  return {
    formatDate,
    beginningOfMonth,
    setBeginningOfMonth,
    endOfMonth,
    setEndOfMonth,
    previousMonthSchedule,
    nextMonthSchedule,
    HandleFirstDayOfTargetMonth,
    HandleLastDayOfTargetMonth,
  }
}
