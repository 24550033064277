import { WhoIsWheres } from "../../models/employee/useSchedule/useScheduleForm/type"

export const useUpdateShareScheduleReservable = (whoIsWheres: WhoIsWheres) => {
  const hostWhoIsWhere = whoIsWheres[0]
  const updateWhoIsWheres = whoIsWheres.map((whoIsWhere) => {
    return {
      userable: whoIsWhere.userable,
      reservable: hostWhoIsWhere.reservable,
    }
  })
  return updateWhoIsWheres
}
