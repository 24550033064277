import React, { useEffect } from "react"

import { CompanyBranchForm } from "../../../components/company/branches/CompanyBranchForm"
import { AlertContent } from "../../../components/public/AlertContent"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useBranch } from "../../../models/company/useBranch"

export const CompanyBranchUpdatePage = () => {
  const branchObject = useBranch()

  useEffect(() => {
    branchObject.editBranch()
  }, [])

  return (
    <>
      <ContentTemplate>
        <ContentCompanyPaper>
          {branchObject.loading ? (
            <Loading type="content" loading={branchObject.loading} />
          ) : (
            <CompanyBranchForm {...branchObject} />
          )}
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertContent />
    </>
  )
}
