import React, { useEffect } from "react"

import { TeamList } from "../../../components/company/teams/CompanyTeamList"
import { AlertContent } from "../../../components/public/AlertContent"
import { AlertErrorContent } from "../../../components/public/AlertContent"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useTeam } from "../../../models/company/useTeam"

// TODO
// useEmployee

export const CompanyTeamListPage = () => {
  const teamObject = useTeam()

  useEffect(() => {
    teamObject.fetchTeamList()
  }, [])

  return (
    <>
      <Loading type="content" loading={teamObject.loading} />
      <ContentTemplate>
        <ContentCompanyPaper>
          <TeamList {...teamObject} />
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertContent />
      <AlertErrorContent />
    </>
  )
}
