import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
// import Cookies from "js-cookie"
import * as Yup from "yup"

import { useCallback, useContext, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { pageType } from "../../../../utils/location"
import { PASSWORD_REGEX } from "../../../../utils/regular"
import { useAccounts } from "../../../employee/useAccounts"
import { ChangePasswordType } from "./type"

const changePasswordData: ChangePasswordType = {
  password: "",
  passwordConfirmation: "",
}

Amplify.configure(awsmobile)
export const usePasswordChange = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { currentUser } = useContext(AuthContext)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const {
    passwordChangeTimes,
    setPasswordChangeTimes,
    fetchPasswordChangeTimes,
    updatePasswordChangeTimes,
  } = useAccounts()

  let basicSchema
  if (localStorage.getItem("expiredChangePassword") == "true") {
    basicSchema = Yup.object().shape({
      password: Yup.string()
        .required(translations.Required)
        .max(50, translations.fiftyPassword)
        .min(8, translations.eightCharacters)
        .matches(PASSWORD_REGEX, translations.PleaseOneSymbol),
    })
  } else {
    basicSchema = Yup.object().shape({
      password: Yup.string()
        .required(translations.Required)
        .max(50, translations.fiftyPassword)
        .min(8, translations.eightCharacters)
        .matches(PASSWORD_REGEX, translations.PleaseOneSymbol),
      passwordConfirmation: Yup.string()
        .required(translations.Required)
        .oneOf([Yup.ref("password"), null], translations.PasswordNotMatch),
    })
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      password: changePasswordData.password,
      passwordConfirmation: changePasswordData.password,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<ChangePasswordType> = useCallback(
    async (data) => {
      setLoading(true)
      try {
        if (localStorage.getItem("expiredChangePassword")) {
          await Auth.currentSession()
          await Auth.changePassword(
            currentUser,
            data.password,
            data.passwordConfirmation
          )
          localStorage.removeItem("expiredChangePassword")
          await updatePasswordChangeTimes()
          navigate(`/${pageType}/login`)
        } else {
          const response = await Auth.completeNewPassword(
            currentUser,
            data.password
          )
          await updatePasswordChangeTimes()
          if (response.signInUserSession) {
            setPasswordChangeTimes(new Date())

            // const savedPassword = Cookies.get("password")
            // // ログイン時に「メールアドレスとパスワードを保存する」にチェックを入れている場合、最新のパスワードに書き換える
            // if (savedPassword) {
            //   Cookies.set("password", data.password, {
            //     expires: 30,
            //   })
            // }
            navigate(`/${pageType}/home`)
          }
        }
      } catch (error) {
        setOpen(true)
        setErrorMessages([translations.changePassword])
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    onSubmit,
    open,
    errorMessages,
    loading,
  }
}
