import React from "react"

import { MobileAuthPageTemplate } from "../../../components/mobile/common/AuthPageTemplate"
import { ConfirmForgotPasswordForm } from "../../../components/public/auth/ConfirmForgotPasswordForm"
import { useConfirmForgotPassword } from "../../../models/public/useAuth/confirmForgotPassword"

export const MobileConfirmForgotPassword = () => {
  const authObject = useConfirmForgotPassword()

  return (
    <MobileAuthPageTemplate loading={authObject.loading}>
      <ConfirmForgotPasswordForm {...authObject} />
    </MobileAuthPageTemplate>
  )
}
