import React from "react"

import { PasswordChangeForm } from "../../../components/employee/auth/PasswordChangeForm"
import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { usePasswordChange } from "../../../models/public/useAuth/passwordChange"

export const EmployeePasswordChange = () => {
  const authObject = usePasswordChange()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <PasswordChangeForm {...authObject} />
    </AuthPageTemplate>
  )
}
