import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Button,
  Grid,
  Box,
  styled,
  Typography,
  Modal,
  InputLabel,
} from "@mui/material"

import { useSyncLanguageAcrossTabs } from "../../../../src/utils/useSyncLanguageAcrossTabs"
import { ReflectButton } from "../../../components/public/ReflectButton"
import { useLanguage } from "../../../contexts/LanguageContext"
import { usePasswordExpireMonthes } from "../../../models/company/usePasswordExpireMonthes"
import { ProfileResponseType } from "../../../models/employee/useProfile/type"
import { AuthContext } from "../../../providers/AuthProvider"
import enTranslations from "../../../translations/mobileHome/mobileHomeProfileIcon/en"
import jaTranslations from "../../../translations/mobileHome/mobileHomeProfileIcon/ja"
import { useOpen } from "../../../utils/isOpen"
import { CancelButton } from "../../public/CancelButton"

const CustomListBox = styled(Box)(({ theme }) => ({
  height: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space_around",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  backgroundColor: "#FFFFFF",
  marginBottom: theme.spacing(2),
}))

const ToDoListBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(6),
}))

const ToDoTitleBox = styled(Box)(() => ({
  display: "flex",
  width: "60px",
}))

const ToDoListText = styled(Typography)(({ theme }) => ({
  color: "#707070",
  fontWeight: "bold",
  //   marginRight: theme.spacing(6),
}))

const ToDoContentBox = styled(Box)(() => ({}))

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  alignItems: "center",
  width: "600px",
  borderRadius: "10px",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(3),
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "80px",
  height: "56px",
  fontSize: theme.spacing(2),
  fontWeight: "bold",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  marginRight: theme.spacing(6),
  marginLeft: theme.spacing(6),
}))

// モバイルでの言語切り替え
export const MobileLanguageChangeData: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const mobileLanguageChangeOpen = useOpen()
  const {
    passwordExpireMonthes,
    setPasswordExpireMonthes,
    fetchPasswordExpireMonthes,
    updatePasswordExpireMonthes,
  } = usePasswordExpireMonthes()

  const navigate = useNavigate()
  // 言語の切り替え
  const { language, setLanguage } = useLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language)
  const translations = language === "en" ? enTranslations : jaTranslations

  const switchLanguage = (lang: string) => {
    setSelectedLanguage(lang)
  }

  //   従業員ごとに言語を切り替える
  const { currentUser } = useContext(AuthContext)
  const userID = currentUser ? currentUser.attributes.sub : "default"

  //   従業員が切り替わるたびに言語が切り替わる
  useEffect(() => {
    const storedLanguage = localStorage.getItem(`language_${userID}`)
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage)
      setLanguage(storedLanguage)
    }
  }, [userID, currentUser])

  useEffect(() => {
    const planState = async () => {
      await fetchPasswordExpireMonthes()
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>
      {loading ? null : (
        <>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>
                <ToDoListText variant="h5" mb={14}>
                  {translations.Language}
                </ToDoListText>
              </ToDoTitleBox>
            </ToDoListBox>
            <Box>
              <ToDoListText variant="h6">
                <Box>{language === "en" ? "English (BETA)" : "日本語"}</Box>
              </ToDoListText>
            </Box>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={mobileLanguageChangeOpen.open}
                sx={{ textTransform: "none" }}
              >
                {translations.Change}
              </CustomButton>
            </Box>
          </CustomListBox>
          <Modal
            open={mobileLanguageChangeOpen.isOpen}
            onClose={mobileLanguageChangeOpen.close}
          >
            <CustomLayoutModal>
              <CustomGridContainer container spacing={3}>
                <CustomGridItem item xs={6}>
                  <InputLabel htmlFor="password_expire_monthes">
                    <Typography color="inherit" fontWeight="bold">
                      {translations.Language}
                    </Typography>
                  </InputLabel>
                </CustomGridItem>
                <Box sx={{ marginRight: 2 }}>
                  <Button
                    sx={{ padding: "10px", marginTop: "24px" }}
                    variant={
                      selectedLanguage === "en" ? "contained" : "outlined"
                    }
                    onClick={() => setSelectedLanguage("en")}
                  >
                    English
                  </Button>
                </Box>
                <Button
                  sx={{
                    padding: "10px",
                    marginTop: "24px",
                    marginRight: "30px",
                  }}
                  variant={selectedLanguage === "ja" ? "contained" : "outlined"}
                  onClick={() => setSelectedLanguage("ja")}
                >
                  日本語
                </Button>
              </CustomGridContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "450px",
                  m: "30px auto",
                }}
              >
                <Grid item xs={4} sm={3}>
                  <ReflectButton
                    // sx={{ textTransform: "none" }}
                    label={translations.Update}
                    handleClick={() => {
                      localStorage.setItem(
                        `language_${userID}`,
                        selectedLanguage
                      )
                      setLanguage(selectedLanguage)
                      navigate("/mobile/home")
                    }}
                  />
                </Grid>
                <CancelButton
                  label={translations.Close}
                  handleClick={mobileLanguageChangeOpen.close}
                />
              </Box>
            </CustomLayoutModal>
          </Modal>
        </>
      )}
    </>
  )
}
