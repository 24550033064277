import { useState } from "react"

import { fetchPlansRequest } from "../../../api/company/plansRequest"
import { Plan } from "../usePlan/type"

export const usePlan = () => {
  const [plans, setPlans] = useState<Plan[]>([])

  const fetchPlans = async () => {
    try {
      const response = await fetchPlansRequest()
      if (response.data && response.status === 200) {
        setPlans(response.data)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    fetchPlans,
    plans,
  }
}
