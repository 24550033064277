import React from "react"

import { Box, Typography, styled } from "@mui/material"

import { showMeetingRoomScheduleRequest } from "../../../../../api/employee/scheduleRequest"
import { ScheduleType } from "../../../../../models/employee/useSchedule/days/type"
import { FetchScheduleShowFunction } from "../../../../../models/employee/useSchedule/show/type"
import { formatDateForSafari } from "../../../../../utils/dateTimeFormat"
import {
  roundDownMinutesDate,
  differenceFromStartTimeToEndTime,
  findHourIndex,
  processMultiDaySchedule,
  isSameDay,
  isEndTimeAtMidnight,
} from "../../../../../utils/schedules"

const defaultWidth = 80
const startPosition = 15

// 予定表の幅
const scheduleBarWidth = (diffHours: number): number => {
  const intHour = Math.floor(diffHours)
  const decimalHour = diffHours - intHour
  return intHour * defaultWidth + decimalHour * defaultWidth
}

// 予定表バーの始点
const scheduleBarLeftPosition = (
  startTimeMinutes: number,
  hourIndex: number
): number => {
  const decimalHour = startTimeMinutes / 60 // start_timeの小数点以下を算出
  return startPosition + hourIndex * defaultWidth + decimalHour * defaultWidth
}

const CustomScheduleBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  color: "white",
  height: theme.spacing(4.5),
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(1),
  zIndex: "1001",
  overflowX: "hidden",
  whiteSpace: "nowrap",
}))

interface Props {
  id: number
  start_time: string
  end_time: string
  schedule_title: string
  open: () => void
  fetchScheduleShowData: FetchScheduleShowFunction
  scheduleDate: string
}

export const MeetingRoomSchedule: React.FC<Props> = ({
  id,
  start_time,
  end_time,
  schedule_title,
  open,
  fetchScheduleShowData,
  scheduleDate,
}: Props) => {
  const processedSchedules = processMultiDaySchedule({
    start_time,
    end_time,
  })
  const currentPageDate = new Date(formatDateForSafari(scheduleDate))

  const fetchShowData = async (
    scheduleInformationIds: number[],
    scheduleDate: string,
    scheduleIds: number[],
    schedules: ScheduleType[]
  ) => {
    await fetchScheduleShowData(
      scheduleInformationIds,
      new Date(scheduleDate),
      scheduleIds,
      schedules
    )
  }

  const handleScheduleShowDataClick = () => {
    const response = showMeetingRoomScheduleRequest(id)
    response
      .then((res) => {
        if (res.data) {
          const scheduleData = res.data
          open()
          fetchShowData(
            scheduleData.schedule_information_ids,
            scheduleData.schedule_date,
            scheduleData.schedule_ids,
            scheduleData.schedules
          )
        }
      })
      .catch((error) => {
        console.error("エラーが発生しました:", error)
      })
  }

  return (
    <>
      {processedSchedules.map((processedSchedule, index) => {
        const startTimeString = processedSchedule.startTime.toISOString()
        const endTimeString = processedSchedule.endTime.toISOString()

        // formatDateForSafari 関数には文字列を渡す
        const startTime = new Date(formatDateForSafari(startTimeString))
        const endTime = new Date(formatDateForSafari(endTimeString))

        // scheduleDate と同じ日のスケジュールのみを表示
        if (!isSameDay(startTime, currentPageDate)) {
          return null // 同じ日ではない場合は表示しない
        }

        // 予定の位置や幅を計算
        // 日を跨ぐ予定の場合、最終日以外は23:59:59をendtimeとしているので、roundDownMinutesDateを適用しない
        const diffHours = differenceFromStartTimeToEndTime(
          roundDownMinutesDate(startTime).getTime(),
          isEndTimeAtMidnight(endTime)
            ? endTime.getTime()
            : roundDownMinutesDate(endTime).getTime()
        )
        const hourIndex = findHourIndex(startTime)
        const width = scheduleBarWidth(diffHours)
        const left = scheduleBarLeftPosition(
          roundDownMinutesDate(startTime).getMinutes(),
          hourIndex
        )

        return (
          <>
            <CustomScheduleBox
              key={index}
              sx={{
                width: `${width}px`,
                left: `${left}px`,
                backgroundColor: "#C1C1C1",
              }}
              onClick={handleScheduleShowDataClick}
            >
              <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                <Typography>{schedule_title}</Typography>
              </Box>
            </CustomScheduleBox>
          </>
        )
      })}
    </>
  )
}
