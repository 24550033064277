const en = {
  createSchedule: "Create a schedule",
  findMember: "Find members",
  searchName: "Search by name",
  location: "Location",
  floor: "Floor",
  businessUnit: "Business Unit",
  Favorite: "Favorite",
  Team: "Team",
  Search: "Search",
  Member: "MEMBER",
  BusinessUnit: "BUSINESS UNIT",
  Manager: "MANAGER",
  Location: "LOCATION",
  Cancel: "CANCEL",
  Enter: "ENTER",
}
export default en
