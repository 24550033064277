import { useState, useCallback, useEffect } from "react"

import { tagCategoryApplianceRequest } from "../../../api/public/tagCategoryApplianceRequest"
import { ApplianceType, TagCategoryType } from "./type"

export const useTag = () => {
  const [tagCategories, setTagCategories] = useState<TagCategoryType[]>([])
  const [appliances, setAppliances] = useState<ApplianceType[]>([])

  const fetchTagCategoryAppliance = useCallback(async () => {
    try {
      const response = await tagCategoryApplianceRequest()

      if (response.data && response.status === 200) {
        setTagCategories(response.data.tag_categories)
        setAppliances(response.data.appliances)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log("error")
    }
  }, [])

  useEffect(() => {
    fetchTagCategoryAppliance()
  }, [])

  return {
    tagCategories,
    appliances,
  }
}
