import { useState } from "react"

import {
  scheduleUserableGuestSearchRequest,
  scheduleUserableSearchRequest,
} from "../../../../api/employee/scheduleRequest"
import {
  SearchGuestSet,
  UserableSearchGuestResponse,
  UserableSearchResponse,
} from "./type"

const initialUserableResponse: UserableSearchResponse = []
const initialGuestUserableResponse: UserableSearchGuestResponse = []

export const userableState = (
  userableSearchObject: UserableSearchResponse = initialUserableResponse
) => {
  const [userableSearch, setUserableSearch] =
    useState<UserableSearchResponse>(userableSearchObject)

  return { userableSearch, setUserableSearch }
}

export const userableGuestState = (
  userableGuestSearchObject: UserableSearchGuestResponse = initialGuestUserableResponse
) => {
  const [userableGuestSearch, setUserableGuestSearch] =
    useState<UserableSearchGuestResponse>(userableGuestSearchObject)

  return { userableGuestSearch, setUserableGuestSearch }
}

export const userableSearchRequestState = () => {
  const [userableSearchRequest, setUserableSearchRequest] = useState<string>("")

  return { userableSearchRequest, setUserableSearchRequest }
}

export const useScheduleUserableSearch = () => {
  const { userableSearch, setUserableSearch } = userableState()
  const scheduleUserableSearch = async (params: string) => {
    try {
      const response = await scheduleUserableSearchRequest(params)
      if (response.status === 200 && response.data) {
        setUserableSearch(response.data)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    }
  }

  return { userableSearch, scheduleUserableSearch }
}

export const useScheduleUserableGuestSearch = () => {
  const { userableGuestSearch, setUserableGuestSearch } = userableGuestState()
  const scheduleUserableGuestSearch = async (params: string) => {
    try {
      const response = await scheduleUserableGuestSearchRequest(params)
      if (response.status === 200 && response.data) {
        setUserableGuestSearch(response.data)
      }
      return response.data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    }
  }

  return { userableGuestSearch, scheduleUserableGuestSearch }
}
