import React from "react"

import { CompanyEmployeeForm } from "../../../components/company/employee/CompanyEmployeeForm"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useEmployee } from "../../../models/company/useEmployee"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CompanyEmployeeCreatePage = () => {
  const employeeObject = useEmployee()
  const { companyRelations } = useCompanyRelations()

  return (
    <>
      <Loading type="content" loading={employeeObject.loading} />
      <ContentTemplate>
        <ContentCompanyPaper>
          <CompanyEmployeeForm
            {...employeeObject}
            companyRelations={companyRelations}
          />
        </ContentCompanyPaper>
      </ContentTemplate>
    </>
  )
}
