import React from "react"

import { Typography, Alert, Grid } from "@mui/material"

export const MobileErrorText: React.FC<string[]> = (
  errorMessages: string[]
) => {
  return (
    <>
      <Grid
        item
        style={{
          margin: "auto 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          {errorMessages.map((message, index) => {
            return (
              <Typography
                component="p"
                variant="caption"
                key={index}
                fontSize="0.7rem"
              >
                {message}
              </Typography>
            )
          })}
        </Alert>
      </Grid>
    </>
  )
}
