import React from "react"

import { Box } from "@mui/material"

import { Schedule } from "../../../../../models/employee/useSchedule/meetingRooms/type"
import { FetchScheduleShowFunction } from "../../../../../models/employee/useSchedule/show/type"
import { hours } from "../../../../../utils/hours"
import { MeetingRoomSchedule } from "../MeetingRoomSchedule"

const defaultWidth = 80
const startPosition = 15

interface Props {
  schedules: Schedule[]
  open: () => void
  fetchScheduleShowData: FetchScheduleShowFunction
  scheduleDate: string
}

export const MeetingRoomScheduleTable: React.FC<Props> = ({
  schedules,
  open,
  fetchScheduleShowData,
  scheduleDate,
}: Props) => {
  return (
    <>
      <Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {hours.map((_, index) => (
            <Box
              key={index}
              border={1}
              borderColor="#C1C1C1"
              sx={{
                borderBottomWidth: "1px",
                position: "absolute",
                width: "80px",
                left: `${startPosition + index * defaultWidth}px`,
                height: "4rem",
                zIndex: "100",
              }}
            />
          ))}
          {schedules?.map((value, index) => (
            <MeetingRoomSchedule
              key={index}
              {...value}
              open={open}
              fetchScheduleShowData={fetchScheduleShowData}
              scheduleDate={scheduleDate}
            />
          ))}
        </Box>
        <Box
          borderTop={1}
          borderColor="#C1C1C1"
          sx={{
            width: "70%",
            height: "4rem",
          }}
        ></Box>
      </Box>
    </>
  )
}
