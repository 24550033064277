const en = {
  home: "Home",
  locationManagement: "Location",
  employeeManagement: "Employee",
  terms: "Terms",
  reports: "Reports",
  schedule: "Schedule",
  workplace: "Workplace",
  settings: "Settings",
}
export default en
