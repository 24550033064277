import React, { useEffect, useState } from "react"

import { Box, Checkbox, Grid, Modal, styled, Typography } from "@mui/material"

import { CancelButton } from "../CancelButton"
import { ReflectButton } from "../ReflectButton"
import { WarningText } from "../WarningText"

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "50%",
}))

interface Props {
  modalTitle: string
  warningText?: string[]
  isOpen: boolean
  close: () => void
  handleClick: () => void
  children?: React.ReactNode
}

export const ConfirmModal: React.FC<Props> = ({
  modalTitle,
  warningText,
  isOpen,
  close,
  handleClick,
  children = <div></div>,
}: Props) => {
  return (
    <>
      <Modal open={isOpen} onClose={close}>
        <CustomLayoutModal>
          <Box sx={{ padding: "30px 30px 20px" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "20px",
              }}
              variant="h6"
            >
              {modalTitle}
            </Typography>
            <Grid item xs={12} sm={12}>
              {warningText && WarningText(warningText)}
            </Grid>
            <Grid item xs={12} sm={12}>
              {children}
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton label="キャンセル" handleClick={close} />
            <ReflectButton
              label="確定する"
              handleClick={async (e) => {
                await handleClick()
                close()
              }}
            />
          </Box>
        </CustomLayoutModal>
      </Modal>
    </>
  )
}
