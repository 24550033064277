import {
  EmployeeResponseType,
  EmployeeRequestType,
  CreateOrUpdateEmployeeResponseType,
  EmployeeListResponseType,
} from "../../models/company/useEmployee/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const employeeListRequest = async (): Promise<
  ApiResponse<EmployeeListResponseType[]>
> => {
  try {
    const data = await api.get("/company/employees")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const createEmployeeRequest = async (
  params: EmployeeRequestType
): Promise<ApiResponse<CreateOrUpdateEmployeeResponseType>> => {
  try {
    const { data } = await api.post("/company/employees", params)
    return { data: data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const employeeDetailRequest = async (params: {
  employee_id: number
}): Promise<ApiResponse<EmployeeResponseType>> => {
  try {
    const data = await api.get(`/company/employees/${params?.employee_id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

export const updateEmployeeRequest = async (
  params: EmployeeRequestType
): Promise<ApiResponse<CreateOrUpdateEmployeeResponseType>> => {
  try {
    const { data } = await api.patch(
      `/company/employees/${params?.employee_id}`,
      params
    )
    return { data: data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const editEmployeeRequest = async (params: {
  employee_id: number
}): Promise<ApiResponse<EmployeeResponseType>> => {
  try {
    const data = await api.get(`/company/employees/${params?.employee_id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

export const pausedEmployeeRequest = async (
  account_ids: number[],
  is_delete_schedules: boolean
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/employees/paused", {
      account_ids: account_ids,
      is_delete_schedules: is_delete_schedules,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const releaseEmployeeRequest = async (
  account_ids: number[]
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/employees/release", {
      account_ids: account_ids,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const deleteEmployeeRequest = async (
  account_id: number
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/employees/delete", {
      account_id: account_id,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
