import {
  CreateGoogleEventRequestType,
  UpdateGoogleEventRequestType,
} from "../../models/employee/useCalendar/type"
import { AcceptStatusRequestType } from "../../models/employee/useSchedule/days/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const createGoogleEventRequest = async (
  params: CreateGoogleEventRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/employee/calendar/google_create", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateGoogleEventRequest = async (
  params: UpdateGoogleEventRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/employee/calendar/${params.schedule.id}/google_update`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateMicrosoftEventRequest = async (
  params: CreateGoogleEventRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/employee/calendar/${params.schedule.id}`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateGoogleApproveRequest = async (
  params: AcceptStatusRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/employee/calendar/${params.id}/google_update_accept_status`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateMicrosoftApproveRequest = async (
  params: AcceptStatusRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/employee/calendar/${params.id}/microsoft_update_accept_status`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
