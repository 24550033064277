import { translations } from "@aws-amplify/ui-react"
import { transcode } from "buffer"

import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { Box, Button, Grid, Modal, Typography } from "@mui/material"

import { api } from "../../../api/config/axiosConfig"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeSettings/employeeSettingsIntegrations/en"
import jaTranslations from "../../../translations/employeeSettings/employeeSettingsIntegrations/ja"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}

type TokenCheck = {
  m_company_token: boolean
  m_login_user_token: boolean
  g_company_token: boolean
  g_login_user_token: boolean
}

export const ExternalSystemLinkComponent: React.FC = () => {
  const [searchParams] = useSearchParams()
  const externalItems = ["MICROSOFT", "GOOGLE"]
  const navigate = useNavigate()
  const [tokenCheck, setTokenCheck] = useState<TokenCheck>({
    m_company_token: false,
    m_login_user_token: false,
    g_company_token: false,
    g_login_user_token: false,
  })
  useEffect(() => {
    ;(async () => {
      if (searchParams.get("m_user_name")) {
        await api
          .post("/auth", {
            m_token: searchParams.get("m_token"),
            m_refresh_token: searchParams.get("m_refresh_token"),
            m_expires_at: searchParams.get("m_expires_at"),
            m_user_name: searchParams.get("m_user_name"),
            m_user_email: searchParams.get("m_user_email"),
            m_user_timezone: searchParams.get("m_user_timezone")
              ? searchParams.get("m_user_timezone")
              : "Tokyo Standard Time",
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/employee/settings/external-system-link")
          })
      } else if (searchParams.get("g_user_name")) {
        await api
          .post("/auth", {
            g_token: searchParams.get("g_token"),
            g_refresh_token: searchParams.get("g_refresh_token"),
            g_expires_at: searchParams.get("g_expires_at"),
            g_user_name: searchParams.get("g_user_name"),
            g_user_email: searchParams.get("g_user_email"),
            g_user_timezone: searchParams.get("g_user_timezone"),
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/employee/settings/external-system-link")
          })
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await api.get("/auth/confirm").then((response) => {
        setTokenCheck(response.data)
      })
    })()
  }, [])

  return (
    <>
      <Box display="flex" justifyContent="start">
        {externalItems.map((item) => (
          <Box
            marginLeft={2}
            key={item}
            sx={{
              border: "2px solid #C1C3C8",
              width: "28rem",
              padding: "8px",
            }}
          >
            <LinkComponent
              item={item}
              tokenCheck={tokenCheck}
              setTokenCheck={setTokenCheck}
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

interface Props {
  item: string
  tokenCheck: TokenCheck
  setTokenCheck: React.Dispatch<React.SetStateAction<TokenCheck>>
}
const LinkComponent: React.FC<Props> = ({
  item,
  tokenCheck,
  setTokenCheck,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>("")

  const open = (item: string) => {
    setSelectedItem(item)
  }

  const close = () => {
    setSelectedItem("")
  }
  // 言語切り替え
  const { language } = useLanguage()
  const currentTranslations =
    language === "en" ? enTranslations : jaTranslations

  // useEffect(() => {
  //   window.location.reload()
  // }, [])
  switch (item) {
    case "MICROSOFT":
      return tokenCheck.m_login_user_token ? (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#1976d2",
                  fontSize: "2rem",
                }}
              >
                Outlook
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
            >
              {currentTranslations.Disconnect}
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.CancelOutlook}
                </Typography>
                <Typography>{currentTranslations.UnlinkFromOutlook}</Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    textTransform: "none",
                  }}
                  type="submit"
                  onClick={() => {
                    api
                      .delete("/auth/ms_delete", {
                        params: {
                          pathname: window.location.pathname,
                        },
                      })
                      .then(() =>
                        api
                          .get("/auth/confirm")
                          .then((response) => {
                            setTokenCheck(response.data)
                          })
                          .then(() => close())
                      )
                  }}
                >
                  {currentTranslations.Disconnect}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#C1C3C8",
                  fontSize: "2rem",
                }}
              >
                Outlook
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
              disabled={!tokenCheck.m_company_token}
            >
              {currentTranslations.Connect}
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.ConnectOutLook}
                </Typography>
                <Typography>
                  {currentTranslations.ConnectMicrosoftAccount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <form
                  method="post"
                  name="form1"
                  action={`${process.env.REACT_APP_API_HOST}/auth/microsoft_graph_auth?${window.location.pathname}`}
                >
                  <Button
                    sx={{
                      border: "1px solid",
                      textTransform: "none",
                    }}
                    type="submit"
                  >
                    {currentTranslations.Connect}
                  </Button>
                </form>
              </Box>
            </Box>
          </Modal>
        </>
      )
    case "GOOGLE":
      return tokenCheck.g_login_user_token ? (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/on_google-calendar.svg`}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#1976d2",
                  fontSize: "2rem",
                }}
              >
                Google
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
            >
              {currentTranslations.Disconnect}
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.DisconnectGoogle}
                </Typography>
                <Typography>
                  {currentTranslations.DisconnectGoogleCalendar}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                  }}
                  onClick={() => {
                    api
                      .delete("/auth/google_delete", {
                        params: {
                          pathname: window.location.pathname,
                        },
                      })
                      .then(() =>
                        api
                          .get("/auth/confirm")
                          .then((response) => {
                            setTokenCheck(response.data)
                          })
                          .then(() => close())
                      )
                  }}
                  type="submit"
                >
                  {currentTranslations.Disconnect}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/off_google-calendar.svg`}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#C1C3C8",
                  fontSize: "2rem",
                }}
              >
                {item.toLowerCase()}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                textTransform: "none",
                fontFamily: "Noto Sans",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
              disabled={!tokenCheck.g_company_token}
            >
              {currentTranslations.Connect}
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.ConnectGoogle}
                </Typography>
                <Typography>
                  {currentTranslations.ConnectGoogleAccount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <form
                  method="post"
                  name="form1"
                  action={`${process.env.REACT_APP_API_HOST}/auth/google_oauth2?${window.location.pathname}`}
                >
                  <Button
                    sx={{
                      border: "1px solid",
                      textTransform: "none",
                    }}
                    type="submit"
                  >
                    {currentTranslations.Connect}
                  </Button>
                </form>
              </Box>
            </Box>
          </Modal>
        </>
      )
    default:
      return <Box></Box>
  }
}
