import React, { useState } from "react"

import { Box, Grid, Tooltip, Typography } from "@mui/material"

import {
  MeetingRoomType,
  Schedule,
} from "../../../models/company/useDashboard/type"
import {
  meetingRoomStyle,
  meetingRoomIconStyle,
} from "../../../styles/meetingStyle"
import { reservedEmployeesStyle } from "../../../styles/seatStyle"
import {
  formatDateForSafari,
  formatScheduleTimeRangeForDisplay,
} from "../../../utils/dateTimeFormat"

const checkInReservedEmployeesStyle = {
  width: "25px",
  height: "25px",
  position: "relative",
  borderRadius: "50%",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
}

interface Props {
  meetingRoom: MeetingRoomType
}

/*
  レイアウト画像に会議室のアイコンが表示される
*/
export const EmployeeHomeLayoutMeetingRoom: React.FC<Props> = ({
  meetingRoom,
}: Props) => {
  const {
    id,
    meeting_room_code,
    start_x,
    start_y,
    end_x,
    end_y,
    schedules,
    color_code,
    meeting_room_name,
  } = meetingRoom

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const tooltipText = (schedules: Schedule[]) => {
    const schedule = schedules[0]
    let employeesName = ""
    schedules.map((schedule) => {
      switch (schedule.userable_type) {
        case "Employee":
          employeesName += `${schedule?.userable.last_name} ${schedule?.userable.first_name}, `
          break
        case "Guest":
          employeesName += `${schedule?.userable.last_name} ${schedule?.userable.first_name}(ゲスト), `
      }
    })
    const scheduleTitle = schedule?.schedule_title
    const scheduleTime = schedule?.whole_day_flag
      ? "終日"
      : formatScheduleTimeRangeForDisplay(
          formatDateForSafari(schedule?.start_time as string),
          formatDateForSafari(schedule?.end_time as string)
        )
    const seatInfo = meeting_room_code
    return `会議室番号: ${seatInfo}\n会議室名称: ${meeting_room_name}\n予約者: ${employeesName}\n件名: ${scheduleTitle}\n${scheduleTime}`
  }

  return (
    <Box
      id={`M${String(id)}`}
      className={"meetingRoom"}
      sx={meetingRoomStyle(start_x, start_y, end_x, end_y, color_code)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <span style={meetingRoomIconStyle(color_code) as React.CSSProperties}>
        M
      </span>
      <span
        style={{
          top: "60px",
          left: "60px",
          color: color_code,
          position: "absolute",
          fontSize: "30px",
        }}
      >
        {meeting_room_code}
      </span>
      {schedules && (
        <Tooltip
          title={schedules.length > 0 ? tooltipText(schedules) : ""}
          placement="bottom"
          disableInteractive
          open={schedules.length > 0 && tooltipOpen}
          componentsProps={{
            tooltip: {
              sx: {
                whiteSpace: "pre-wrap",
                position: "fixed",
                width: "200px",
              },
            },
          }}
        >
          <Grid
            container
            style={{
              bottom: "10px",
              left: "40px",
              position: "absolute",
              display: "flex",
              alignItems: "center",
            }}
          >
            {schedules.map((schedule, index) => {
              return (
                <Grid item key={index} sm={4}>
                  <Box
                    sx={
                      schedule.checked_in
                        ? (checkInReservedEmployeesStyle as React.CSSProperties)
                        : (reservedEmployeesStyle as React.CSSProperties)
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {schedule.image_blob ? (
                      <img
                        src={schedule.image_blob}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{ fontSize: "20px" }}
                        color="inherit"
                        display="flex"
                      >
                        <span>{schedule.userable.last_name[0]}</span>
                        <span>{schedule.userable.first_name?.[0]}</span>
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Tooltip>
      )}
    </Box>
  )
}
