import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
// import Cookies from "js-cookie"
import * as Yup from "yup"

import { useState, useCallback, useEffect, useContext } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { EMAIL_REGEXP, PASSWORD_REGEX } from "../../../../utils/regular"
import { LoginType } from "./type"

const authFormData: LoginType = {
  email: "",
  password: "",
  rememberLoginData: true,
}

export interface SignInParams {
  username: string
  password: string
  attributes: {
    email: string
  }
}

// チェックイン用のログインモデル関数
export const useCheckInLogin = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  Amplify.configure(awsmobile)
  const navigate = useNavigate()
  const [authData] = useState<LoginType>(authFormData)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { setCurrentUser } = useContext(AuthContext)
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)

  // // コンポーネント読み込み時にcookieの情報を読み取ってemail, passwordが入っていれば自動でフォーム入力する
  // useEffect(() => {
  //   const email = Cookies.get("email")
  //   const password = Cookies.get("password")
  //   if (email) {
  //     setValue("email", email)
  //   }
  //   if (password) {
  //     setValue("password", password)
  //   }
  // }, [])

  const basicSchema = Yup.object().shape({
    email: Yup.string()
      .required(translations.Required)
      .matches(EMAIL_REGEXP, translations.email),
    password: Yup.string(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: authData.email,
      password: authData.password,
      rememberLoginData: true,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<LoginType> = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Auth.signIn(data.email, data.password)
      setCurrentUser(response) // ログイン情報をstateに格納して2要素認証画面で使いまわす
      if (response.challengeName === "SOFTWARE_TOKEN_MFA") {
        navigate(
          `/mobile/check-in/mfa?floor_id=${queryParams.get(
            "floor_id"
          )}&id=${queryParams.get("id")}&type=${queryParams.get(
            "type"
          )}&name=${queryParams.get("name")}`
        ) // デバイス登録済みの場合2要素認証画面に遷移する
      } else if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate(
          `/mobile/check-in/password-change?floor_id=${queryParams.get(
            "floor_id"
          )}&id=${queryParams.get("id")}&type=${queryParams.get(
            "type"
          )}&name=${queryParams.get("name")}`
        )
      } else if (response.signInUserSession) {
        navigate(
          `/mobile/check-in?floor_id=${queryParams.get(
            "floor_id"
          )}&id=${queryParams.get("id")}&type=${queryParams.get(
            "type"
          )}&name=${queryParams.get("name")}`
        )
      }
      // if (data.rememberLoginData) {
      //   // メールアドレスとPWの記憶にチェックが入っている場合Cookieに情報を格納する
      //   Cookies.set("email", data.email, { expires: 30 })
      //   Cookies.set("password", data.password, {
      //     expires: 30,
      //   })
      // }
    } catch (error) {
      setOpen(true)
      setErrorMessages(["ログインに失敗しました"])
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    control,
    authData,
    errors,
    isValid,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    open,
    errorMessages,
    loading,
  }
}
