import React, { useEffect } from "react"
import { useLayoutEffect } from "react"

import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { PasswordChangeForm } from "../../../components/public/auth/PasswordChangeForm"
import { useAuth } from "../../../models/public/useAuth"
import { usePasswordChange } from "../../../models/public/useAuth/passwordChange"

export const CompanyPasswordChange = () => {
  const authObject = usePasswordChange()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <PasswordChangeForm {...authObject} />
    </AuthPageTemplate>
  )
}
