import { useContext, useState } from "react"

import { fetchScheduleDayRequest } from "../../../../api/employee/scheduleRequest"
import { AuthContext } from "../../../../providers/AuthProvider"
import { EmployeeScheduleRequestType, EmployeesType } from "../type"
import { ScheduleTable } from "./type"

export const useDaySchedule = (
  locationEmployees: EmployeesType | undefined
) => {
  const [scheduleMembers, setScheduleMembers] = useState<EmployeesType>([])
  const [daySchedules, setDaySchedules] = useState<ScheduleTable>([])
  const [checkedEmployees, setCheckedEmployees] = useState<EmployeesType>([])
  const [scheduleDayLoading, setScheduleDayLoading] = useState<boolean>(false)
  const { currentUser } = useContext(AuthContext)

  const fetchScheduleDay = async (data: EmployeeScheduleRequestType) => {
    setScheduleDayLoading(true)
    try {
      const response = await fetchScheduleDayRequest(data)
      if (response.status === 200 && response.data) {
        // 予定表サイドバーに表示する従業員名一覧
        const sideBarData = response.data.map((allSchedules) => {
          return allSchedules.employee
        })
        setScheduleMembers(sideBarData)
        if (currentUser) {
          if (checkedEmployees.length === 0 && !locationEmployees) {
            setCheckedEmployees(
              sideBarData.filter(
                (member) => currentUser.attributes.email === member.email
              )
            )
          }
          if (locationEmployees) {
            setCheckedEmployees(locationEmployees)
          }
        }

        // 予定表の右側に表示する予定の一覧
        const tableData = response.data.flatMap((allSchedules) => {
          return allSchedules.schedule
        })
        setDaySchedules(tableData)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log("error")
    } finally {
      setScheduleDayLoading(false)
    }
  }

  // 各子供コンポーネントから予定の取得処理を呼び出すための関数
  const handleDayScheduleFetch = async (data: EmployeeScheduleRequestType) => {
    await fetchScheduleDay(data)
  }

  return {
    scheduleMembers,
    setScheduleMembers,
    daySchedules,
    setDaySchedules,
    handleDayScheduleFetch,
    scheduleDayLoading,
    checkedEmployees,
    setCheckedEmployees,
  }
}
