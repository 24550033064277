import React from "react"

import { SignUpForm } from "../../../components/company/auth/SignUpForm"
import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { useSignUp } from "../../../models/public/useAuth/signUp"

export const CompanySignUp = () => {
  const authObject = useSignUp()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <SignUpForm {...authObject} />
    </AuthPageTemplate>
  )
}
