import React, { useEffect, useState } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"

import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Checkbox,
  FormHelperText,
  Button,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  EmployeeGroupEmployeeType,
  EmployeeGroupRequestType,
} from "../../../../../models/employee/useEmployeeGroup/type"
import { useSearchEmployee } from "../../../../../models/employee/useEmployeeSearch"
import enTranslations from "../../../../../translations/employeeSchedule/employeeGroupFormModal/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeGroupFormModal/ja"
import { CancelButton } from "../../../../public/CancelButton"
import { ErrorText } from "../../../../public/ErrorText"
import { ReflectButton } from "../../../../public/ReflectButton"
import { EmployeeSearchModal } from "../../../common/EmployeeSearchModal"

const CustomTitleTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  position: "sticky",
  top: "0",
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  position: "sticky",
  top: "0",
}))

const CustomNameTypography = styled(Typography)(() => ({
  fontSize: "12px",
  fontFamily: "Noto Sans",
}))

const SelectedEmployeeList = styled(Box)(({ theme }) => ({
  overflowY: "scroll",
  maxHeight: "100%",
  width: "40%",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
  marginLeft: theme.spacing(1),
}))

const EmployeeAddButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(4),
}))

const EmployeeSearchModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(125),
  minHeight: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
}))

interface Props {
  formType: "create" | "update"
  employees: EmployeeGroupEmployeeType[]
  handleClose: () => void
  employeeIds: number[]
  setEmployeeIds: React.Dispatch<React.SetStateAction<number[]>>
  control: Control<EmployeeGroupRequestType>
  errors: FieldErrors<EmployeeGroupRequestType>
  isValid: boolean
  handleSubmit: UseFormHandleSubmit<EmployeeGroupRequestType>
  onSubmit: SubmitHandler<EmployeeGroupRequestType>
  getValues: UseFormGetValues<EmployeeGroupRequestType>
  setValue: UseFormSetValue<EmployeeGroupRequestType>
  errorMessages: string[]
}

export const EmployeeGroupFormModal = ({
  formType,
  handleClose,
  setEmployeeIds,
  employees,
  control,
  errors,
  isValid,
  handleSubmit,
  onSubmit,
  getValues,
  setValue,
  errorMessages,
}: Props) => {
  const employeeSearchObject = useSearchEmployee()
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false)
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // 従業員検索から従業員を選ぶたびにstateとformStateを変更する
  useEffect(() => {
    if (employeeSearchObject.selectedEmployees.length > 0) {
      // 検索結果から選んだ人を抽出
      const selectedEmployeeIds = employeeSearchObject.selectedEmployees.map(
        (employee) => {
          return employee.id
        }
      )

      // 既存でチェックをつけた従業員と合わせる
      const newSelectedEmployeeIds =
        getValues("employee_ids").concat(selectedEmployeeIds)

      // 検索結果と既存のメンバーがかぶっていると重複してしまうのでユニークにする
      const uniqueSelectedEmployeeIds = Array.from(
        new Map(newSelectedEmployeeIds.map((id) => [id, id])).values()
      )

      // stateの変更
      setValue("employee_ids", uniqueSelectedEmployeeIds)
      setEmployeeIds(getValues("employee_ids"))
    }
  }, [employeeSearchObject.selectedEmployees.length])

  // ↓画面操作↓
  // 候補者のうちチェックを入れた従業員だけemployee_idsとしてパラメーターにのせる
  const checkEmployee = (e: React.ChangeEvent<HTMLInputElement>) => {
    let employeeIds = getValues("employee_ids")
    if (e.target.checked) {
      employeeIds.push(Number(e.target.value))
      setValue("employee_ids", employeeIds)
    } else {
      employeeIds = employeeIds.filter((id) => {
        return id != Number(e.target.value)
      })
    }
    setValue("employee_ids", employeeIds)
    setEmployeeIds(getValues("employee_ids"))
  }

  const handleSearchModalOpen = () => {
    setIsSearchModalOpen(true)
  }

  // 検索結果を確定せずに閉じる場合と確定して閉じる場合で関数を分ける
  const handleSearchModalClose = () => {
    setIsSearchModalOpen(false)
  }

  // ↓UIに使う変数↓
  // formTypeに応じてUIを変更する場合はここに追記していく
  const titleText =
    // formType === "create" ? "お気に入りを作成" : "お気に入りを編集"
    formType === "create" ? translations.createFavorite : translations.Edit

  const messageText =
    formType === "create" ? translations.LikeAddTeam : translations.likeChange
  const buttonText =
    formType === "create" ? translations.Create : translations.Change

  const newEmployees = employees.concat(employeeSearchObject.selectedEmployees) // 既存の検索対象者と新しくcheckを入れた従業員を結合

  // 結合した配列をユニークにする
  const uniqueSelectedEmployees = Array.from(
    new Map(newEmployees.map((employee) => [employee.id, employee])).values()
  )

  return (
    <>
      {isSearchModalOpen ? (
        <EmployeeSearchModalOverlay>
          <EmployeeSearchModal
            {...employeeSearchObject}
            handleClose={handleSearchModalClose}
            setIsSearchModalOpen={setIsSearchModalOpen}
          />
        </EmployeeSearchModalOverlay>
      ) : (
        <Box
          sx={{
            position: "relative",
            height: "100%",
          }}
        >
          <CustomTitleTypography>{titleText}</CustomTitleTypography>
          <Grid
            container
            direction="column"
            spacing={2}
            pt="2rem"
            alignItems="space-around"
            height="100%"
          >
            <Grid item display="flex" container sm={1}>
              <Grid item xs={4} display="flex" alignItems="center">
                <CustomTypography color="primary">
                  {translations.TeamName}
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name={"group_name"}
                  render={({ field }) => (
                    <TextField
                      label={translations.TeamName}
                      {...field}
                      fullWidth
                      InputProps={{
                        sx: {
                          "& .MuiOutlinedInput-input": {
                            padding: "12px 15px",
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "12px",
                          fontFamily: "Noto Sans",
                        },
                      }}
                    />
                  )}
                />
                <FormHelperText error={!!errors.group_name?.message}>
                  {errors.group_name?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item display="flex" container sm={1}>
              <Grid item xs={4} display="flex" alignItems="center">
                <CustomTypography color="primary">
                  {translations.TeamNameKana}
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  name={"group_name_kana"}
                  render={({ field }) => (
                    <TextField
                      label={translations.TeamNameKana}
                      {...field}
                      fullWidth
                      InputProps={{
                        sx: {
                          "& .MuiOutlinedInput-input": {
                            padding: "12px 15px",
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "12px",
                          fontFamily: "Noto Sans",
                        },
                      }}
                    />
                  )}
                />
                <FormHelperText error={!!errors.group_name_kana?.message}>
                  {errors.group_name_kana?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid
              item
              sm={3}
              sx={{
                height: errorMessages.length > 0 ? "25%" : "35%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <SelectedEmployeeList>
                {uniqueSelectedEmployees.map((employee, index) => (
                  <Box key={index} display="flex" alignItems="center">
                    <Checkbox
                      checked={getValues("employee_ids").includes(employee.id)}
                      value={employee.id}
                      onChange={checkEmployee}
                    />
                    <Box>
                      <CustomNameTypography>
                        {employee.last_name}
                        {employee.first_name}
                      </CustomNameTypography>
                    </Box>
                  </Box>
                ))}
              </SelectedEmployeeList>
              {formType === "update" && (
                <EmployeeAddButton
                  variant="contained"
                  onClick={handleSearchModalOpen}
                >
                  {translations.AddMember}
                </EmployeeAddButton>
              )}
            </Grid>
            <Grid
              item
              sm={2}
              spacing={2}
              mt="auto"
              pb="1.5rem"
              container
              direction="column"
            >
              <Grid item sm={0.25}>
                {isValid && (
                  <Typography textAlign="center">{messageText}</Typography>
                )}
              </Grid>
              <Grid item sm={0.25}>
                <Box>
                  {errorMessages.length > 0 && ErrorText(errorMessages)}
                </Box>
              </Grid>
              <Box display="flex" justifyContent="center">
                <Box marginRight="15px">
                  <CancelButton
                    label={translations.Cancel}
                    handleClick={handleClose}
                  />
                </Box>
                <Box>
                  <ReflectButton
                    label={buttonText}
                    disabled={!isValid}
                    handleClick={handleSubmit(onSubmit)}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
