import { useState, useCallback } from "react"

import { fetchMeetingRoomScheduleRequest } from "../../../../api/employee/scheduleRequest"
import { MeetingRoomParDate, MeetingRoomScheduleRequestType } from "./type"

export const useMeetingRoomSchedules = () => {
  const [meetingRoomSchedules, setMeetingRoomSchedules] = useState<
    MeetingRoomParDate[]
  >([])
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)

  // 会議室の予定の一覧を取得する関数
  const fetchMeetingRoomSchedules = useCallback(
    async (data: MeetingRoomScheduleRequestType) => {
      try {
        const response = await fetchMeetingRoomScheduleRequest({
          floor_id: data.floor_id,
          branch_id: data.branch_id,
          start_date: data.start_date,
          end_date: data.end_date,
        })
        if (response.status === 200 && response.data) {
          setMeetingRoomSchedules(response.data.meeting_room_par_date_list)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("error")
      }
    },
    []
  )

  return {
    meetingRoomSchedules,
    fetchMeetingRoomSchedules,
    branchId,
    setBranchId,
    floorId,
    setFloorId,
  }
}
