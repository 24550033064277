import React from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { SetupMFA } from "../../../components/public/SetupMFA"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeSettings/employeeSettingsMain/en"
import jaTranslations from "../../../translations/employeeSettings/employeeSettingsMain/ja"
import { EnvironmentSettingsComponent } from "./EnvironmentSettings"
import { ExternalSystemLinkComponent } from "./ExternalSystemLink"

const CustomMainBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.875, 1.875, 0, 1.875),
}))

const CustomFooter = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1.875),
  height: "20px",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
}))

const CustomTitleHeader = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  width: "280px",
  borderBottomStyle: "solid",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  textAlign: "center",
}))

const HeaderLine = styled(Box)(() => ({
  borderBottomStyle: "solid",
  borderColor: "#a9a9a9",
  flexGrow: 1,
}))

interface SettingsMenuType {
  name: string
  url: string
  component: JSX.Element
}

// TODO
// URLは必要に応じて変更する
const settingsMenu: SettingsMenuType[] = [
  {
    name: "2要素認証",
    url: "/employee/settings/setup-mfa",
    component: <div></div>,
  },
  {
    name: "外部連携",
    url: "/employee/settings/external-system-link",
    component: <div></div>,
  },
  {
    name: "環境設定",
    url: "/employee/settings/environment-settings",
    component: <div></div>,
  },
]

// 選択されているタブのスタイル
const activeMenuStyle = {
  borderColor: "#22BA9D",
  color: "#22BA9D",
  textDecoration: "none",
}

// 非選択時のタブのスタイル
const inactiveMenuStyle = {
  borderColor: "#808A9F",
  color: "#808A9F",
  textDecoration: "none",
}

// 従業員設定ページ。employee/settings以下のURLは全てこのコンポーネントを読み込む。
export const EmployeeSettingsPage = () => {
  const navigate = useNavigate()
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const handleClick = () => {
    navigate("/employee/home")
  }

  // タブの言語切り替え
  settingsMenu[0].name = translations.twoFactorAuth
  settingsMenu[1].name = translations.Integrations
  settingsMenu[2].name = translations.environmentSettings

  // タブ切り替え時にどのコンポーネントを表示するのかを制御する
  const settingsMainContent = () => {
    switch (location.pathname) {
      case "/employee/settings/setup-mfa":
        return <SetupMFA />
      case "/employee/settings/external-system-link":
        return <ExternalSystemLinkComponent />
      case "/employee/settings/environment-settings":
        return <EnvironmentSettingsComponent />
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column" height="calc(100vh - 70px)">
        <CustomMainBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#F5F5F5",
            }}
          >
            {/* settingsMenu.slice(1) → メニュー1番目は飛ばす(2段階認証) */}
            {settingsMenu.slice(1).map((menu, index) => {
              return (
                <NavLink
                  key={index}
                  to={menu.url}
                  style={({ isActive }) => {
                    return isActive ? activeMenuStyle : inactiveMenuStyle
                  }}
                >
                  <CustomTitleHeader>{menu.name}</CustomTitleHeader>
                </NavLink>
              )
            })}
            <HeaderLine />
          </Box>
        </CustomMainBox>
        <CustomMainBox>{settingsMainContent()}</CustomMainBox>
      </Box>
      <CustomFooter>
        <Typography
          sx={{
            fontSize: "10px",
            fontFamily: "Noto Sans",
            lineHeight: "20px",
          }}
          variant="overline"
        >
          <a
            href="https://workagile.jp/index.php/privacy/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
            }}
          >
            {translations.TermsService}{" "}
          </a>
          <a
            href="https://workagile.jp/index.php/terms/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
            }}
          >
            {translations.PrivacyPolicy}
          </a>
        </Typography>
      </CustomFooter>
    </>
  )
}
