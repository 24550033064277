import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import {
  Grid,
  Button,
  Box,
  styled,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Typography,
} from "@mui/material"

import { TeamListResponseType } from "../../../../models/company/useTeam/type"
import { CancelButton } from "../../../public/CancelButton"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { Paging } from "../../../public/Pagination"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomButton = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "35px",
  fontSize: theme.spacing(2),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
  borderRadius: "6px",
}))

const NumberOfDisplaysSelect = styled(Select)(({ theme }) => ({
  width: "100px",
  height: "35px",
  fontSize: theme.spacing(2),
  marginLeft: "15px",
  color: "#454545",
  backgroundColor: "#f7f7f7",
  borderRadius: "6px",
  ".MuiOutlinedInput-notchedOutline": { border: 0.05 },
}))

const displayedResultsText = {
  color: "#22BA9D",
  paddingLeft: "12px",
  paddingTop: "7px",
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "14px",
}

const teamFont = {
  color: "#22BA9D",
  fontSize: "13px",
  fontWeight: "bold",
  paddingTop: "20px",
  paddingLeft: "30px",
  width: "4%",
}

const teamWidth = {
  paddingLeft: "30px",
  width: "20%",
}

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
  borderRadius: "10px",
}))

interface Props {
  teamList: TeamListResponseType[]
  deleteTeam: (id: number) => void
}

export const TeamList: React.FC<Props> = ({ teamList, deleteTeam }: Props) => {
  const navigate = useNavigate()
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const [isOpen, setIsOpen] = useState(false)
  const [team, setTeam] = useState<TeamListResponseType>({
    id: 0,
    team_name: "",
    team_name_kana: "",
    team_leader_name: "",
    parent_id: 0,
  })

  const DATA_COUNT_PER_PAGE = 20 // 1ページに表示するレコードの数

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  return (
    <>
      <Box style={{ display: "flex", height: "auto" }}>
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={1} spacing={1} style={displayedResultsText}>
            表示件数
          </Grid>
          <Grid item xs={1} spacing={1}>
            <NumberOfDisplaysSelect
              defaultValue="20"
              IconComponent={GreenArrowIcon}
            >
              <MenuItem value="20">20</MenuItem>
            </NumberOfDisplaysSelect>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              marginLeft: "60px",
              marginRight: "140px",
            }}
          >
            <CustomButton
              variant="contained"
              onClick={() => {
                navigate(`/company/teams/new`)
              }}
            >
              組織の新規登録
            </CustomButton>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={teamFont}>所属名</TableCell>
                  <TableCell style={teamFont}>所属名(カナ)</TableCell>
                  <TableCell style={teamFont}>所属長</TableCell>
                  <TableCell style={teamFont}></TableCell>
                  <TableCell style={teamFont}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamList
                  .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                  .map((team, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={teamWidth}>
                          {team.team_name}
                        </TableCell>
                        <TableCell style={teamWidth}>
                          {team.team_name_kana}
                        </TableCell>
                        <TableCell style={teamWidth}>
                          {team.team_leader_name}
                        </TableCell>
                        <TableCell style={teamWidth}>
                          {team.parent_id !== 0 && (
                            <CustomButton
                              variant="outlined"
                              onClick={() => {
                                navigate(`/company/teams/${team.id}/edit`)
                              }}
                            >
                              編集
                            </CustomButton>
                          )}
                        </TableCell>
                        <TableCell>
                          {team.parent_id !== 0 && (
                            <Button
                              onClick={() => {
                                setIsOpen(true)
                                setTeam(team)
                              }}
                            >
                              <DeleteOutlineIcon />
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                <Modal open={isOpen} onClose={close}>
                  <CustomLayoutModal>
                    <Box sx={{ padding: "30px 30px 20px" }}>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        variant="h6"
                      >
                        {team.team_name}を削除します。よろしいですか？
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "450px",
                          m: "30px auto",
                        }}
                      >
                        <CancelButton
                          label="キャンセル"
                          handleClick={() => {
                            setIsOpen(false)
                          }}
                        />
                        <ReflectButton
                          label="削除"
                          handleClick={() => {
                            deleteTeam(team.id), setIsOpen(false)
                          }}
                        />
                      </Box>
                    </Box>
                  </CustomLayoutModal>
                </Modal>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
      <Box
        justifyContent={"center"}
        sx={{
          marginTop: "330px",
        }}
      >
        {teamList.length > DATA_COUNT_PER_PAGE && (
          <Paging
            pageCount={Math.ceil(teamList.length / DATA_COUNT_PER_PAGE)}
            page={pageNumber}
            handlePaginationChange={handlePaginationChange}
          />
        )}
      </Box>
    </>
  )
}
