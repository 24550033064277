import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  InputLabel,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { Employee } from "../../../../../models/employee/useEmployeeSearch/type"
import { useProfile } from "../../../../../models/employee/useProfile"
import enTranslations from "../../../../../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../../../../../translations/employeeStatus/employeeStatusHeader/ja"
import { GreenArrowIcon } from "../../../../public/GreenArrowIcon"

const CustomInputLabel = styled(InputLabel)({
  fontSize: "12px",
  fontFamily: "Noto Sans",
})

const CustomMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontFamily: "Noto Sans",
  color: "#454545",
  lineHeight: "17px",
})

type LayoutModeType = "layout" | "status" | "reservation"

interface Props {
  type: LayoutModeType
  branchId: number
  floorId: number
  date: Date
  wholeDayFlag: boolean
  timeIndex: number
  time: string
}

// レイアウトページのモード切り替えプルダウン
export const EmployeeLayoutModeSelect = ({
  type,
  branchId,
  floorId,
  date,
  wholeDayFlag,
  timeIndex,
  time,
}: Props) => {
  const result = time.split(":")
  const { fetchEmployeeProfile, employeeProfile } = useProfile()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useEffect(() => {
    fetchEmployeeProfile()
  }, [])

  const navigate = useNavigate()
  const handleModeChange = (e: SelectChangeEvent) => {
    switch (e.target.value as LayoutModeType) {
      case "layout":
        return navigate("/employee/layouts", {
          state: {
            branchId: branchId,
            floorId: floorId,
            date: date,
            wholeDayFlag: wholeDayFlag,
            timeIndex: timeIndex,
          },
        })
      case "status":
        return navigate("/employee/layouts/status", {
          state: {
            branchId: branchId,
            date: date,
            wholeDayFlag: wholeDayFlag,
            timeIndex: timeIndex,
          },
        })
      case "reservation": {
        //ログインユーザの情報を格納
        const whoIsWheres = [
          {
            userable: {
              userable_id: employeeProfile.id,
              last_name: employeeProfile.last_name,
              first_name: employeeProfile.first_name,
              email: employeeProfile.account.email,
              userable_type: "Employee",
            },
            reservable: {
              reservable_id: undefined,
              name: "",
              reservable_type: "Seat",
            },
          },
        ]
        //検索対象者の情報を格納
        const storageEmployees = JSON.parse(
          String(localStorage.getItem("layoutSelectedEmployees"))
        )
        if (storageEmployees) {
          storageEmployees.map((selectedEmployee: Employee) => {
            whoIsWheres.push({
              userable: {
                userable_id: selectedEmployee.id,
                last_name: selectedEmployee.last_name,
                first_name: selectedEmployee.first_name,
                email: selectedEmployee.account.email,
                userable_type: "Employee",
              },
              reservable: {
                reservable_id: undefined,
                name: "",
                reservable_type: "Seat",
              },
            })
          })
        }
        return navigate("/employee/calendar/layouts", {
          state: {
            branchId: branchId,
            floorId: floorId,
            timeIndex: timeIndex,
            pathname: location.pathname,
            scheduleForm: {
              schedule: {
                id: 0,
                schedule_title: "",
                message_content: "",
                schedule_date_type: 0,
                start_date: new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate()
                ),
                start_time: new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                  Number(result[0]),
                  Number(result[1])
                ),
                end_date: new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate()
                ),
                end_time: new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  date.getDate(),
                  Number(result[0]) + 1,
                  Number(result[1])
                ),
                whole_day_flag: wholeDayFlag,
                is_secret: 0,
                share_schedule: {
                  who_is_wheres: whoIsWheres,
                  notification: "auto",
                },
              },
            },
          },
        })
      }
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <FormControl fullWidth>
        <CustomInputLabel htmlFor="mode">{translations.Mode}</CustomInputLabel>
        <Select
          id="mode"
          name="mode"
          label="モード"
          fullWidth
          value={type}
          onChange={handleModeChange}
          variant="outlined"
          sx={{
            fontSize: "12px",
            fontFamily: "Noto Sans",
            color: "#454545",
            lineHeight: "17px",
            letterSpacing: "0.6px",
            "& .MuiOutlinedInput-input": { padding: "12px 0 12px 15px" },
          }}
          IconComponent={GreenArrowIcon}
        >
          <CustomMenuItem value={"layout"} disabled={type === "layout"}>
            {translations.DeskStatus}
          </CustomMenuItem>
          <CustomMenuItem value={"status"} disabled={type === "status"}>
            {translations.Status}
          </CustomMenuItem>
          <CustomMenuItem
            value={"reservation"}
            disabled={
              floorId === 0 ||
              type === "reservation" ||
              date <
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate()
                )
            }
          >
            {translations.BookDesk}
          </CustomMenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
