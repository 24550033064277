import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { useCompany } from "../../../models/company/useCompany"
import { usePasswordExpireMonthes } from "../../../models/company/usePasswordExpireMonthes"
import { useAccounts } from "../../../models/employee/useAccounts"
import { pageType } from "../../../utils/location"
import { FirstStepList } from "./FirstStepList"

const WelcomeMessage = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.5",
  color: "#707070",
}))

const CustomTitleHeader = styled(Box)(() => ({
  width: "220px",
  borderBottomStyle: "solid",
  borderColor: "#22BA9D",
  color: "#22BA9D",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.5",
  textAlign: "center",
  cursor: "pointer",
}))

const DashboardHeader = styled(Box)(({ theme }) => ({
  width: "220px",
  marginLeft: theme.spacing(0.5),
  borderBottomStyle: "solid",
  borderColor: "#808A9F",
  color: "#808A9F",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.5",
  textAlign: "center",
  textDecoration: "none",
  cursor: "pointer",
}))

const HeaderLine = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  borderBottomStyle: "solid",
  borderColor: "#a9a9a9",
  borderWidth: "thin",
  flexGrow: 1,
}))

const StartMessage = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1.875, 0),
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  letterSpacing: "0.5",
  color: "#808A9F",
}))

// TODO
// ページ遷移未実装分あり（ダッシュボード、URL決まったら実装）
// 全ての準備を完了すると利用準備は表示されなくなる
export const CompanyHomeFirst = () => {
  const navigate = useNavigate()
  const { companyPreparation, fetchCompanyPreparation } = useCompany()
  const { passwordChangeTimes, fetchPasswordChangeTimes } = useAccounts()
  const { fetchPasswordExpireMonthes } = usePasswordExpireMonthes()

  useEffect(() => {
    fetchCompanyPreparation()
    fetchPasswordChangeTimes().then((data) => {
      if (data) {
        if (new Date() > new Date(data.password_change_times)) {
          localStorage.setItem("expiredChangePassword", "true")
          navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
        }
      }
    })
    fetchPasswordExpireMonthes()
  }, [])

  useEffect(() => {
    console.log(passwordChangeTimes)
  }, [passwordChangeTimes])

  return (
    <>
      <WelcomeMessage>WORK AGILEへようこそ！</WelcomeMessage>

      <Box
        sx={{
          display: "flex",
          marginTop: "15px",
        }}
      >
        <CustomTitleHeader>利用準備</CustomTitleHeader>

        <DashboardHeader
          onClick={() => {
            navigate("/company/dashboard")
          }}
        >
          ダッシュボード
        </DashboardHeader>
        <HeaderLine />
      </Box>
      <StartMessage>
        <span>さぁ始めましょう！</span>
        <span style={{ paddingLeft: "1rem" }}>
          以下の手順を遂行し、セットアップを完成させましょう。
        </span>
      </StartMessage>
      <FirstStepList companyPreparation={companyPreparation} />
    </>
  )
}
