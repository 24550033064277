import React from "react"

import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { ConfirmForgotPasswordForm } from "../../../components/public/auth/ConfirmForgotPasswordForm"
import { useConfirmForgotPassword } from "../../../models/public/useAuth/confirmForgotPassword"

export const CompanyConfirmForgotPassword = () => {
  const authObject = useConfirmForgotPassword()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <ConfirmForgotPasswordForm {...authObject} />
    </AuthPageTemplate>
  )
}
