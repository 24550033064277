import React, { useContext } from "react"
import { Navigate, RouteProps, useNavigate } from "react-router-dom"

import { useAuth } from "../models/public/useAuth"
import { AuthContext } from "../providers/AuthProvider"
import { RoleContext } from "../providers/RoleProvider"

export const SignageBranchLeaderRoute: React.FC<RouteProps> = ({ element }) => {
  const navigate = useNavigate()
  const { isAuthenticated } = useContext(AuthContext)
  const { role, isAdmin, isBranchLeader, isPaid, isActive } =
    useContext(RoleContext)
  const { signOut } = useAuth()

  const loginPageNavigate = async () => {
    await signOut().then(() => {
      localStorage.setItem(
        "alertErrorContent",
        "現在、WORKAGILEにはログインできません。御社のWORKAIGLE運用ご担当者様までご連絡ください。"
      )
      navigate("/signage/login")
      window.location.reload()
    })
  }

  return (
    <>
      {isAuthenticated && role ? (
        isAdmin || isBranchLeader ? (
          <>{isPaid && isActive ? element : loginPageNavigate()}</>
        ) : (
          <Navigate to="/employee/home" /> // 管理者以外がアクセスしてきた場合は従業員HOMEに遷移させる
        )
      ) : (
        <Navigate to="/signage/login" /> // ログアウト時は共通でサイネージログイン画面へ
      )}
    </>
  )
}
