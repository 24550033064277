import React, { useState } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"

import {
  Grid,
  Typography,
  styled,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  FormHelperText,
  FormControl,
} from "@mui/material"

import { SignUpType } from "../../../../models/public/useAuth/signUp/type"
import { ErrorText } from "../../../public/ErrorText"
import { RequiredFlag } from "../../../public/RequiredFlag"

const CustomGridContainer = styled(Grid)(() => ({}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(53),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: theme.spacing(2),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(40),
}))

interface Props {
  authData: SignUpType
  control: Control<SignUpType>
  handleSubmit: UseFormHandleSubmit<SignUpType>
  onSubmit: SubmitHandler<SignUpType>
  errors: FieldErrors<SignUpType>
  isValid: boolean
  getValues: UseFormGetValues<SignUpType>
  setValue: UseFormSetValue<SignUpType>
  open: boolean
  errorMessages: string[]
}

export const SignUpForm: React.FC<Props> = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
  isValid,
  getValues,
  setValue,
  open,
  errorMessages,
}: Props) => {
  const [privacyAcceptanceMessage, setPrivacyAcceptanceMessage] = useState<
    boolean | undefined
  >(undefined)

  // 利用規約への同意がなされていない場合にエラーメッセージを表示する
  // useFormのvalidateModeがonBlurになっているとチェックボックスのバリデーションの挙動が
  // 不自然だった(チェック後に画面の他の箇所をクリックしないとバリデーション機能しない)ので
  // チェックボックスの操作と同時にバリデーションが動くようにした
  const handleCheck = () => {
    const privacyAcceptance = getValues().privacyAcceptance
    if (!privacyAcceptance) {
      setPrivacyAcceptanceMessage(false)
    } else {
      setPrivacyAcceptanceMessage(true)
    }
    setValue("privacyAcceptance", !privacyAcceptance)
  }

  return (
    <CustomGridContainer
      container
      alignItems="center"
      flexDirection="column"
      spacing={1}
      marginTop="2px"
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <CustomGridItem item>
        <Box textAlign="center" fontSize="13px">
          下記フォームに必要事項を入力し、
          <Typography fontSize="13px">
            「法人アカウント作成」ボタンを押してください。
          </Typography>
        </Box>
      </CustomGridItem>
      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={10}>
          <InputLabel htmlFor="email">
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            >
              メールアドレス
              <RequiredFlag />
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
                id="email"
                name="email"
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={10}>
          <InputLabel htmlFor="password">
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            >
              パスワード登録
              <RequiredFlag />
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.password}
                helperText={errors.password?.message}
                id="password"
                name="password"
                variant="outlined"
                type="password"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={10}>
          <InputLabel htmlFor="passwordConfirmation">
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
              // TODO パスワード強度チェック
            >
              パスワード確認
              <RequiredFlag />
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Controller
            control={control}
            name="passwordConfirmation"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.passwordConfirmation}
                helperText={errors.passwordConfirmation?.message}
                id="passwordConfirmation"
                name="passwordConfirmation"
                variant="outlined"
                type="password"
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      <Grid item>
        <Grid item>
          <Controller
            name="privacyAcceptance"
            render={({ field }) => (
              <>
                <FormControl error>
                  <FormControlLabel
                    sx={{ marginRight: "0" }}
                    label={
                      <Typography variant="subtitle2" fontSize="12px">
                        <a
                          href="https://workagile.jp/index.php/privacy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          プライバシーポリシー
                        </a>
                        と
                        <a
                          href="https://workagile.jp/index.php/terms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          利用規約
                        </a>
                        に同意します（必須）
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={field.value}
                        id="privacyAcceptance"
                        {...field}
                        onChange={handleCheck}
                      />
                    }
                  />
                  {privacyAcceptanceMessage && (
                    <FormHelperText>
                      利用規約への同意をお願いいたします
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            )}
            control={control}
          />
        </Grid>
        <Grid item>
          <Controller
            name="receiveNotification"
            render={({ field }) => (
              <FormControlLabel
                sx={{ marginRight: "0" }}
                label={
                  <Typography variant="subtitle2" fontSize="12px">
                    働き方などに関するお役立ち情報を受け取る
                  </Typography>
                }
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    id="receiveNotification"
                  />
                }
              />
            )}
            control={control}
          />
        </Grid>
      </Grid>
      {open && (
        <Grid item>
          <ErrorMessageContainer textAlign="center">
            {ErrorText(errorMessages)}
          </ErrorMessageContainer>
        </Grid>
      )}
      <Grid item>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CustomButton
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            disabled={!(isValid && privacyAcceptanceMessage === false)}
          >
            <Box textAlign="center">
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                無料で30日間お試し！
              </Typography>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                法人アカウントを作成する
              </Typography>
            </Box>
          </CustomButton>
        </Box>
      </Grid>
      <Grid item>
        <Box textAlign="center" sx={{ color: "#454545" }}>
          <Typography variant="caption" fontSize="10px">
            ※クリックした段階では仮登録です。登録されたメール宛に
            <br />
            認証メールが届きますので、メール案内に従い本登録を行ってください。
          </Typography>
        </Box>
      </Grid>
    </CustomGridContainer>
  )
}
