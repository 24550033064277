import {
  AkerunFomeType,
  AkerunOrganizationResponseType,
} from "../../models/company/useAkerun/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const updateAkerunRequest = async (
  params: AkerunFomeType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/akerun", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const akerunFetchOrganizationRequest = async (): Promise<
  ApiResponse<AkerunOrganizationResponseType[]>
> => {
  try {
    const data = await api.get("/company/akerun/get_organization")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateAkerunOrganizationRequest = async (
  params: AkerunOrganizationResponseType[]
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/akerun/organization_update", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
