import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Box, Grid, Typography, styled, Button, Modal } from "@mui/material"

import {
  updateCheckInOutMeetingRoomRequest,
  updateCheckOutLeaveMeetingRoomRequest,
} from "../../../../api/employee/meetingRoomRequest"
import { updateCheckOutLeaveScheduleRequest } from "../../../../api/employee/seatRequest"
import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { useCheckInOutMeetingRoom } from "../../../../models/employee/useCheckInOutMeetingRoom"
import { useCheckInOutSeat } from "../../../../models/employee/useCheckInOutSeat"
import { useScheduleCheckOut } from "../../../../models/mobile/useSchedule/checkOut"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { dateTimeJaFormat } from "../../../../utils/dateTimeFormat"
import { useOpen } from "../../../../utils/isOpen"
import { AlertError } from "../../../public/AlertError"
import { ErrorText } from "../../../public/ErrorText"
import { Loading } from "../../../public/Loading"
import { WideCancelButton } from "../../../public/WideCancelButton"
import { WideReflectButton } from "../../../public/WideReflectButton"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "85%",
  padding: theme.spacing(4),
}))

const CustomModalBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}))

const CustomtextColor = styled("span")(() => ({
  color: "#66CFBB",
  fontSize: "15px",
}))

const Customspan = styled("span")(() => ({
  fontSize: "13px",
}))

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

interface Props {
  companyRelations: CompanyRelationsType
}

export const CheckOutMain = ({ companyRelations }: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)
  const {
    branchName,
    floorNumber,
    checkOutType,
    checkOutTarget,
    checkOut,
    checkOutError,
    checkOutErrorMessage,
    setCheckOutError,
    setCheckOutStateFromQueryString,
    dataLoading,
    setDataLoading,
  } = useScheduleCheckOut()

  const dateTimeJaMobileFormat = (now: Date) => {
    const dateString = now.toLocaleString()
    const date = new Date(dateString.replace(/-/g, "/"))
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const day = ("0" + date.getDate()).slice(-2)
    const hour = ("0" + date.getHours()).slice(-2)
    const min = ("0" + date.getMinutes()).slice(-2)

    return `${month}${translations.month}${day}${translations.day} ${hour}:${min}`
  }

  const { isLoading, checkInOutSeat, updateCheckInOutSeat, errorMessages } =
    useCheckInOutSeat()
  const checkInOutOpen = useOpen()
  const { checkInOutMeetingRoom, updateCheckInOutMeetingRoom } =
    useCheckInOutMeetingRoom()
  const isCheckIn = checkInOutSeat.check_in.is_check_in
  const isCheckInMeetingRoom = checkInOutMeetingRoom.check_in.is_check_in

  const [scheduleLeaveIsOpen, setScheduleLeaveIsOpen] = useState<boolean>(false)
  const scheduleLeaveIsClose = () => {
    setScheduleLeaveIsOpen(false)
  }
  const [scheduleLeaveMeetingRoomIsOpen, setScheduleLeaveMeetingRoomIsOpen] =
    useState<boolean>(false)
  const scheduleLeaveMeetingRoomIsClose = () => {
    setScheduleLeaveMeetingRoomIsOpen(false)
  }
  const [currentCheckoutResource, setCurrentCheckoutResource] = useState<
    string | null
  >(null)

  const scheduleLeaveSchedule = async () => {
    if (!checkInOutSeat.check_in?.schedule?.id) return Promise.resolve()
    try {
      const response = await updateCheckInOutSeat({
        checked_in: !checkInOutSeat.check_in.is_check_in,
        schedule_id: checkInOutSeat.check_in.schedule.id,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const scheduleLeaveMeetingRoomSchedule = async () => {
    if (!checkInOutMeetingRoom.check_in?.schedule?.id) return Promise.resolve()
    try {
      //NULLの場合はリターンで返すよ
      const response = await updateCheckInOutMeetingRoom({
        checked_in: !checkInOutMeetingRoom.check_in.is_check_in,
        schedule_id: checkInOutMeetingRoom.check_in.schedule.id,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const scheduleScheduleRescission = async () => {
    if (!checkInOutSeat.check_in?.schedule?.id) return Promise.resolve()
    try {
      const response = await updateCheckOutLeaveScheduleRequest({
        checked_in: !checkInOutSeat.check_in.is_check_in,
        schedule_id: checkInOutSeat.check_in.schedule.id,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const scheduleScheduleMeetingRoomRescission = async () => {
    if (!checkInOutMeetingRoom.check_in?.schedule?.id) return Promise.resolve()
    try {
      const response = await updateCheckOutLeaveMeetingRoomRequest({
        checked_in: !checkInOutMeetingRoom.check_in.is_check_in,
        schedule_id: checkInOutMeetingRoom.check_in.schedule.id,
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleUpdateCheckOutInSeat = async () => {
    // Seat checkout logic
    if (!checkInOutSeat.check_in?.schedule?.id) return

    setCurrentCheckoutResource("seat")
    if (checkInOutSeat.check_in.is_check_in) {
      setScheduleLeaveIsOpen(true)
      checkInOutOpen.close()
    } else {
      await updateCheckInOutSeat({
        checked_in: !checkInOutSeat.check_in.is_check_in,
        schedule_id: checkInOutSeat.check_in.schedule.id,
      })
    }
  }

  const handleUpdateCheckOutInMeetingRoom = async () => {
    // Meeting room checkout logic
    if (!checkInOutMeetingRoom.check_in?.schedule?.id) return

    setCurrentCheckoutResource("meetingRoom")
    if (isCheckInMeetingRoom) {
      setScheduleLeaveMeetingRoomIsOpen(true)
      checkInOutOpen.close()
    } else {
      await updateCheckInOutMeetingRoom({
        checked_in: !checkInOutMeetingRoom.check_in.is_check_in,
        schedule_id: checkInOutMeetingRoom.check_in.schedule.id,
      })
    }
  }

  useEffect(() => {
    if (companyRelations) {
      setCheckOutStateFromQueryString(companyRelations)
    }
  }, [companyRelations])

  // PrivateRouteだとクエリパラメータを読み込めないのでここでリダイレクトさせる
  useEffect(() => {
    if (!currentUser) {
      navigate(
        `/mobile/check-out/login?floor_id=${queryParams.get(
          "floor_id"
        )}&id=${queryParams.get("id")}&type=${queryParams.get(
          "type"
        )}&name=${queryParams.get("name")}`
      )
    }
  }, [])
  return (
    <>
      {dataLoading ? (
        <Loading type="content" loading={dataLoading} />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
            <CustomTypography variant="h5">{branchName}</CustomTypography>
            <CustomTypography variant="h5">{floorNumber}F</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
            {checkOutType === "Seat" && (
              <CustomTypography variant="h4">
                {translations.Desk}：{checkOutTarget}
              </CustomTypography>
            )}
            {checkOutType === "MeetingRoom" && (
              <CustomTypography variant="h5">
                {translations.RoomName}：{checkOutTarget}
              </CustomTypography>
            )}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <CustomTypography>{translations.YouCheckOut}</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <WideReflectButton
              label={translations.Checkout}
              handleClick={
                checkOutType === "Seat"
                  ? handleUpdateCheckOutInSeat
                  : handleUpdateCheckOutInMeetingRoom
              }
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/home")}
            >
              <Typography fontWeight="bold">{translations.Home}</Typography>
            </Button>
          </Grid>
          <AlertError
            isMessageOpen={checkOutError}
            alertMessage={checkOutErrorMessage}
            closeMessage={() => setCheckOutError(false)}
          />
        </Grid>
      )}
      <Modal
        open={scheduleLeaveIsOpen || scheduleLeaveMeetingRoomIsOpen}
        onClose={
          scheduleLeaveIsOpen
            ? scheduleLeaveIsClose
            : scheduleLeaveMeetingRoomIsClose
        }
      >
        <ModalBox>
          <CustomModalBox>
            {currentCheckoutResource === "seat" &&
              isCheckIn &&
              checkInOutSeat.check_in.schedule && (
                <Customspan>
                  {translations.CurrentDesk}{" "}
                  <CustomtextColor>
                    {dateTimeJaMobileFormat(
                      checkInOutSeat.check_in.schedule.end_time
                    )}
                  </CustomtextColor>
                  {translations.to}
                  <br />
                  {translations.Reserved}
                  <br />
                  {translations.WouldYouAgain}
                </Customspan>
              )}
            {currentCheckoutResource === "meetingRoom" &&
              isCheckInMeetingRoom &&
              checkInOutMeetingRoom.check_in?.schedule && (
                <Customspan>
                  {translations.CurrentMeetingRoom}
                  <CustomtextColor>
                    {dateTimeJaMobileFormat(
                      checkInOutMeetingRoom.check_in.schedule.end_time
                    )}
                  </CustomtextColor>
                  {translations.to}
                  <br />
                  {translations.Reserved}
                  <br />
                  {translations.WouldYouAgain}
                </Customspan>
              )}
          </CustomModalBox>
          <Box pb="1rem">
            {errorMessages.length !== 0 && (
              <ErrorMessageContainer textAlign="center">
                {ErrorText(errorMessages)}
              </ErrorMessageContainer>
            )}
          </Box>
          <CustomModalBox>
            <WideReflectButton
              label={translations.currentTime}
              handleClick={async () => {
                if (scheduleLeaveIsOpen) {
                  await scheduleScheduleRescission()
                } else if (scheduleLeaveMeetingRoomIsOpen) {
                  await scheduleScheduleMeetingRoomRescission()
                }
                navigate("/mobile/home")
                window.location.reload()
                localStorage.setItem("alertContent", translations.SuccessUpdate)
              }}
              labelStyle={{ fontSize: "15px" }}
            />
          </CustomModalBox>
          <CustomModalBox>
            <WideCancelButton
              label={translations.UseAgain}
              handleClick={async () => {
                if (currentCheckoutResource === "seat") {
                  await scheduleLeaveSchedule()
                } else if (currentCheckoutResource === "meetingRoom") {
                  await scheduleLeaveMeetingRoomSchedule()
                }
                navigate("/mobile/home")
                window.location.reload()
                localStorage.setItem("alertContent", translations.checkOut)
              }}
            />
          </CustomModalBox>
          <CustomModalBox>
            <WideCancelButton
              label={translations.Cancel}
              handleClick={
                currentCheckoutResource === "meetingRoom"
                  ? scheduleLeaveMeetingRoomIsClose
                  : scheduleLeaveIsClose
              }
            />
          </CustomModalBox>
        </ModalBox>
      </Modal>
    </>
  )
}
