const ja = {
  CheckIn: "チェックインをするには",
  CheckInLogin: "アカウントにログインしてください",
  Azure: "AzureAD認証になっております",
  EmailLogin: "メールアドレスのみでログインしてください",
  Provider: "アイデンティティプロバイダーの取得に失敗しました",
  YourPassword: "パスワードを入力してください",
  Email: "メールアドレス",
  Password: "パスワード",
  Login: "ログイン",
  ForgotPassword: "パスワードをお忘れの方はこちら",
  LoginFailed: "ログインに失敗しました",
}
export default ja
