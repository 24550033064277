import React from "react"

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
} from "@mui/material"

import { ReportAttendanceType } from "../../../../models/company/useReport/type"

const tableFont = {
  color: "#22BA9D",
  fontSize: "14px",
  fontWeight: "bold",
  paddingTop: "30px",
  width: "4%",
}
const employeeTextFont = {
  fontSize: "12px",
}

const EmailTextFont = {
  fontSize: "8px",
  textDecoration: "underline",
}

interface Props {
  employeeAttendanceList?: {
    account: { email: string; company_id: number }
    team: { team_name: string }
    floor: { floor_name: string }
    branch: { branch_name: string }
    last_name: string
    first_name: string
    seat_id: number
    seat_name: string
    attendance_rate: number
    reserved_rate: number
    canceled_rate: number
  }[]
}

export const EmployeeAttendanceRate: React.FC<Props> = ({
  employeeAttendanceList,
}: Props) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={tableFont} sx={{ paddingLeft: "30px" }}>
              従業員
            </TableCell>
            <TableCell style={tableFont} sx={{ width: "8%" }}>
              所属
            </TableCell>
            <TableCell style={tableFont} sx={{ width: "8%" }}>
              主拠点
            </TableCell>
            <TableCell style={tableFont}>指定席</TableCell>
            <TableCell style={tableFont}>出社率</TableCell>
            <TableCell style={tableFont}>予約率</TableCell>
            <TableCell style={tableFont} sx={{ whiteSpace: "nowrap" }}>
              予約キャンセル率
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeAttendanceList &&
            employeeAttendanceList.length > 0 &&
            employeeAttendanceList.map((report, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Box>
                      <Typography style={employeeTextFont}>
                        {report.last_name} {report.first_name}
                      </Typography>
                      <Typography style={EmailTextFont}>
                        {report.account.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.team.team_name}
                  </TableCell>
                  <TableCell sx={{ width: "8%", fontSize: "12px" }}>
                    {report.branch.branch_name} {report.floor.floor_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>
                    {report.seat_id == null ? "無" : "有"}
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>
                    {report.attendance_rate}%
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>
                    {report.reserved_rate}%
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>
                    {report.canceled_rate}%
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      {!employeeAttendanceList ||
        (employeeAttendanceList.length === 0 && (
          <Box mt="2rem" width="100%" marginBottom="570px">
            <Typography textAlign="center">
              表示するデータがありません
            </Typography>
          </Box>
        ))}
    </>
  )
}
