import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom"

import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
  Link,
} from "@mui/material"

import { api } from "../../../api/config/axiosConfig"
import { CancelButton } from "../../../components/public/CancelButton"
import { ReflectButton } from "../../../components/public/ReflectButton"
import { theme } from "../../../config/theme"
import { useAkerun } from "../../../models/company/useAkerun"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}

type TokenCheck = {
  m_company_token: boolean
  m_login_user_token: boolean
  g_company_token: boolean
  g_login_user_token: boolean
  akerun_access_token: boolean
  akerun_tying_flag: boolean
  azure_ad_token: boolean
  azure_ad_connected: boolean
}

export const ExternalSystemLinkComponent: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const externalItems = ["MICROSOFT", "GOOGLE"]
  const [tokenCheck, setTokenCheck] = useState<TokenCheck>({
    m_company_token: false,
    m_login_user_token: false,
    g_company_token: false,
    g_login_user_token: false,
    akerun_access_token: false,
    akerun_tying_flag: false,
    azure_ad_token: false,
    azure_ad_connected: false,
  })

  useEffect(() => {
    ;(async () => {
      if (searchParams.get("m_user_name")) {
        await api
          .post("/auth", {
            m_token: searchParams.get("m_token"),
            m_refresh_token: searchParams.get("m_refresh_token"),
            m_expires_at: searchParams.get("m_expires_at"),
            m_user_name: searchParams.get("m_user_name"),
            m_user_email: searchParams.get("m_user_email"),
            m_user_timezone: searchParams.get("m_user_timezone")
              ? searchParams.get("m_user_timezone")
              : "Tokyo Standard Time",
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/company/settings/external-system-link")
          })
      } else if (searchParams.get("g_user_name")) {
        await api
          .post("/auth", {
            g_token: searchParams.get("g_token"),
            g_refresh_token: searchParams.get("g_refresh_token"),
            g_expires_at: searchParams.get("g_expires_at"),
            g_user_name: searchParams.get("g_user_name"),
            g_user_email: searchParams.get("g_user_email"),
            g_user_timezone: searchParams.get("g_user_timezone"),
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/company/settings/external-system-link")
          })
      } else if (searchParams.get("a_token")) {
        await api
          .post("/company/akerun/token_update", {
            code: searchParams.get("a_token"),
            redirect_uri: searchParams.get("redirect_uri"),
          })
          .then(() => {
            navigate("/company/settings/external-system-link")
          })
      } else if (searchParams.get("ad_user_name")) {
        await api
          .post("/auth", {
            ad_token: searchParams.get("ad_token"),
            ad_refresh_token: searchParams.get("ad_refresh_token"),
            ad_expires_at: searchParams.get("ad_expires_at"),
            ad_user_name: searchParams.get("ad_user_name"),
            ad_user_email: searchParams.get("ad_user_email"),
            ad_user_timezone: searchParams.get("ad_user_timezone")
              ? searchParams.get("ad_user_timezone")
              : "Tokyo Standard Time",
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/company/settings/external-system-link")
          })
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      // Outlook & Googleの連携情報の取得
      await api.get("/auth/confirm").then((response) => {
        setTokenCheck(response.data)
      })
    })()
  }, [])

  // Akerun関連
  const [isAkerunModalOpen, setAkerunModalOpen] = useState<boolean>(false)
  const [isAkerunLiftModalOpen, setAkerunLiftModalOpen] =
    useState<boolean>(false)
  // Akerun認証モーダルの開閉
  const handleOpenAkerun = () => {
    setAkerunModalOpen(true)
  }
  const handleCloseAkerun = () => {
    setAkerunModalOpen(false)
  }
  // Akerun解除モーダルの開閉
  const handleOpenAkerunLift = () => {
    setAkerunLiftModalOpen(true)
  }
  const handleCloseAkerunLift = () => {
    setAkerunLiftModalOpen(false)
  }

  return (
    <>
      <Typography
        sx={{
          color: "#C1C3C8",
          padding: "8px",
        }}
      >
        【カレンダー連携】
      </Typography>
      <Box display="flex" justifyContent="start">
        {externalItems.map((item) => (
          <Box
            marginLeft={2}
            key={item}
            sx={{
              border: "2px solid #C1C3C8",
              width: "28rem",
              padding: "8px",
            }}
          >
            <LinkComponent
              item={item}
              tokenCheck={tokenCheck}
              setTokenCheck={setTokenCheck}
            />
          </Box>
        ))}
      </Box>

      <Box display="flex" flexDirection="row" alignItems="start">
        <Box display="flex" flexDirection="column">
          <Typography
            sx={{
              color: "#C1C3C8",
              padding: "8px",
            }}
          >
            【セキュリティ連携（ベータ版）】
          </Typography>
          <Box
            marginLeft={2}
            key={1}
            sx={{
              border: "2px solid #C1C3C8",
              width: "28rem",
              marginTop: "5px",
              padding: "8px",
            }}
          >
            <AkerunComponent
              handleOpenAkerun={handleOpenAkerun}
              isAkerunModalOpen={isAkerunModalOpen}
              handleCloseAkerun={handleCloseAkerun}
              navigate={navigate}
              tokenCheck={tokenCheck}
              setTokenCheck={setTokenCheck}
              handleOpenAkerunLift={handleOpenAkerunLift}
              handleCloseAkerunLift={handleCloseAkerunLift}
              isAkerunLiftModalOpen={isAkerunLiftModalOpen}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography
            sx={{
              color: "#C1C3C8",
              padding: "8px",
            }}
          >
            【ユーザーシステム連携（ベータ版）】
          </Typography>
          <Box
            marginLeft={2}
            key={2}
            sx={{
              border: "2px solid #C1C3C8",
              width: "28rem",
              marginTop: "5px",
              padding: "8px",
            }}
          >
            <AzureADComponent
              tokenCheck={tokenCheck}
              setTokenCheck={setTokenCheck}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

interface Props {
  item: string
  tokenCheck: TokenCheck
  setTokenCheck: React.Dispatch<React.SetStateAction<TokenCheck>>
}
interface AkerunProps {
  handleOpenAkerun: () => void
  isAkerunModalOpen: boolean
  handleCloseAkerun: () => void
  navigate: NavigateFunction
  tokenCheck: TokenCheck
  setTokenCheck: React.Dispatch<React.SetStateAction<TokenCheck>>
  handleOpenAkerunLift: () => void
  handleCloseAkerunLift: () => void
  isAkerunLiftModalOpen: boolean
}
const LinkComponent: React.FC<Props> = ({
  item,
  tokenCheck,
  setTokenCheck,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>("")

  const open = (item: string) => {
    setSelectedItem(item)
  }

  const close = () => {
    setSelectedItem("")
  }

  switch (item) {
    case "MICROSOFT":
      return tokenCheck.m_company_token ? (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#1976d2",
                  fontSize: "2rem",
                }}
              >
                Outlook
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                borderRadius: "6px",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
            >
              解除する
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  marginBottom={2}
                >
                  Outlookとの解除
                </Typography>
                <Typography>
                  Outlookカレンダーの予定との連携を解除します。
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close}>キャンセル</Button>
                <Button
                  sx={{
                    border: "1px solid",
                    borderRadius: "6px",
                  }}
                  type="submit"
                  onClick={() => {
                    api
                      .delete("/auth/ms_delete", {
                        params: {
                          pathname: window.location.pathname,
                        },
                      })
                      .then(() =>
                        api
                          .get("/auth/confirm")
                          .then((response) => {
                            setTokenCheck(response.data)
                          })
                          .then(() => close())
                      )
                  }}
                >
                  解除する
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#C1C3C8",
                  fontSize: "2rem",
                }}
              >
                Outlook
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                borderRadius: "6px",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
              disabled={tokenCheck.g_company_token}
            >
              接続する
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  marginBottom={2}
                >
                  Outlookとの接続
                </Typography>
                <Typography>
                  microsoftアカウントに接続して、 Outlook
                  カレンダーの予定と連携します。
                </Typography>
                <Typography>
                  ※各従業員のメールアドレスが[microsoft365
                  メールアドレス]であることが必須です。
                </Typography>
                <Typography>※管理者アカウントにて接続してください。</Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close}>キャンセル</Button>
                <form
                  method="post"
                  name="form1"
                  action={`${process.env.REACT_APP_API_HOST}/auth/microsoft_graph_auth?${window.location.pathname}`}
                >
                  <Button
                    sx={{
                      border: "1px solid",
                      borderRadius: "6px",
                    }}
                    type="submit"
                  >
                    接続する
                  </Button>
                </form>
              </Box>
            </Box>
          </Modal>
        </>
      )
    case "GOOGLE":
      return tokenCheck.g_company_token ? (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/on_google-calendar.svg`}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#1976d2",
                  fontSize: "2rem",
                }}
              >
                Google
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                borderRadius: "6px",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
            >
              解除する
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  marginBottom={2}
                >
                  Googleカレンダーとの解除
                </Typography>
                <Typography>
                  Googleカレンダーの予定との連携を解除します。
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close}>キャンセル</Button>
                <Button
                  sx={{
                    border: "1px solid",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    api
                      .delete("/auth/google_delete", {
                        params: {
                          pathname: window.location.pathname,
                        },
                      })
                      .then(() =>
                        api
                          .get("/auth/confirm")
                          .then((response) => {
                            setTokenCheck(response.data)
                          })
                          .then(() => close())
                      )
                  }}
                  type="submit"
                >
                  解除する
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <Grid container display="flex" justifyContent="center">
            <Grid item sm={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/off_google-calendar.svg`}
              />
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#C1C3C8",
                  fontSize: "2rem",
                }}
              >
                {item.toLowerCase()}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" marginTop="8px">
            <Button
              sx={{
                border: "1px solid",
                borderRadius: "6px",
              }}
              type="submit"
              onClick={() => {
                open(item)
              }}
              disabled={tokenCheck.m_company_token}
            >
              接続する
            </Button>
          </Box>
          <Modal open={item === selectedItem} onClose={close}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  marginBottom={2}
                >
                  Googleとの接続
                </Typography>
                <Typography>
                  Googleアカウントに接続して、
                  Googleカレンダーの予定と連携します。
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={close}>キャンセル</Button>
                <form
                  method="post"
                  name="form1"
                  action={`${process.env.REACT_APP_API_HOST}/auth/google_oauth2?${window.location.pathname}`}
                >
                  <Button
                    sx={{
                      border: "1px solid",
                      borderRadius: "6px",
                    }}
                    type="submit"
                  >
                    接続する
                  </Button>
                </form>
              </Box>
            </Box>
          </Modal>
        </>
      )
    default:
      return <Box></Box>
  }
}

const AkerunComponent: React.FC<AkerunProps> = ({
  handleOpenAkerun,
  isAkerunModalOpen,
  handleCloseAkerun,
  navigate,
  tokenCheck,
  setTokenCheck,
  handleOpenAkerunLift,
  handleCloseAkerunLift,
  isAkerunLiftModalOpen,
}) => {
  const { handleSubmit, onUpdateSubmit, control, isValid, errors } = useAkerun()

  const CustomTypography = styled(Typography)(() => ({
    letterSpacing: "0.7px",
    fontWeight: "bold",
    position: "sticky",
    top: "0",
  }))

  const AkerunModalOverlay = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    width: theme.spacing(100),
    height: theme.spacing(58),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(5, 10, 1),
    borderRadius: theme.spacing(1),
  }))

  const CustomLayoutModal = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    width: "450px",
    borderRadius: "10px",
  }))

  // Akerun認証情報登録処理
  const handleAkerun = () => {
    handleSubmit(onUpdateSubmit)()
  }

  return (
    <>
      <Grid container display="flex" justifyContent="center">
        {tokenCheck.akerun_access_token ? (
          <Typography
            sx={{
              color: "#7FCCE3",
              fontSize: "2rem",
            }}
          >
            Akerun
          </Typography>
        ) : (
          <Typography
            sx={{
              color: "#C1C3C8",
              fontSize: "2rem",
            }}
          >
            Akerun
          </Typography>
        )}
      </Grid>
      {tokenCheck.akerun_access_token && !tokenCheck.akerun_tying_flag && (
        <Typography
          sx={{
            color: "red",
            fontSize: "13px",
          }}
          display="flex"
          justifyContent="center"
        >
          ※必ず拠点の紐付けを行なってください
        </Typography>
      )}
      <Box display="flex" justifyContent="center" marginTop="8px">
        {tokenCheck.akerun_access_token ? (
          <Button
            sx={{
              border: "1px solid",
              borderRadius: "6px",
            }}
            type="submit"
            onClick={handleOpenAkerunLift}
          >
            解除する
          </Button>
        ) : (
          <Button
            sx={{
              border: "1px solid",
              borderRadius: "6px",
            }}
            type="submit"
            onClick={handleOpenAkerun}
          >
            接続する
          </Button>
        )}
        {tokenCheck.akerun_access_token && (
          <Button
            sx={{
              border: "1px solid",
              borderRadius: "6px",
              marginLeft: "15px",
            }}
            type="button"
            onClick={() => {
              navigate("/company/settings/external-system-link/akerun")
            }}
          >
            拠点を紐付ける
          </Button>
        )}
      </Box>
      <Modal open={isAkerunLiftModalOpen} onClose={handleCloseAkerunLift}>
        <CustomLayoutModal>
          <>
            <Alert
              severity="warning"
              sx={{ width: "90%" }}
              style={{
                margin: "20px",
                alignItems: "center",
                height: "60px",
              }}
            >
              <p style={{ display: "flex", justifyContent: "center" }}>
                本当にAkerunとの連携を解除しますか？
              </p>
            </Alert>
          </>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
              height: "60px",
            }}
          >
            <Grid item xs={4} sm={3}>
              <ReflectButton
                label="解除"
                handleClick={() => {
                  api.delete("/auth/akerun_delete").then(() =>
                    api
                      .get("/auth/confirm")
                      .then((response) => {
                        setTokenCheck(response.data)
                      })
                      .then(() => handleCloseAkerunLift())
                  )
                }}
              />
            </Grid>
            <CancelButton
              label="キャンセル"
              handleClick={handleCloseAkerunLift}
            />
          </Box>
        </CustomLayoutModal>
      </Modal>
      <Modal open={isAkerunModalOpen} onClose={handleCloseAkerun}>
        <AkerunModalOverlay>
          <CustomTypography variant="h5">Akerun連携設定</CustomTypography>
          <Typography
            sx={{
              color: "red",
              fontSize: "13px",
            }}
          >
            ※Akerun連携をご利用の場合、事前にAkerunAPIへのお申し込みが必要です。
          </Typography>
          <Box
            sx={{
              position: "relative",
              height: "70%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              direction="column"
              spacing={2}
              pt="3rem"
              alignItems="space-around"
              height="100%"
            >
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                container
                sm={1}
              >
                <Grid item xs={4} display="flex" alignItems="center">
                  <CustomTypography variant="body1" color="primary">
                    クライアントID
                  </CustomTypography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name="client_id"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="client_id"
                        name="client_id"
                        label="クライアントID"
                        error={!!errors.client_id}
                        helperText={errors.client_id?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                container
                sm={1}
              >
                <Grid item xs={4} display="flex" alignItems="center">
                  <CustomTypography variant="body1" color="primary">
                    クライアントシークレット
                  </CustomTypography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name="client_secret"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="client_secret"
                        name="client_secret"
                        label="クライアントシークレット"
                        error={!!errors.client_secret}
                        helperText={errors.client_secret?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              height: "30%",
              width: "90%",
              display: "flex",
            }}
          >
            <Grid item sm={12} spacing={2} container direction="column">
              <Grid item container justifyContent="center" sm={4}>
                <Grid
                  item
                  sm={6}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <CancelButton
                    label="キャンセル"
                    handleClick={handleCloseAkerun}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <ReflectButton
                    label="接続"
                    disabled={!isValid}
                    handleClick={handleAkerun}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </AkerunModalOverlay>
      </Modal>
    </>
  )
}

const AzureADComponent: React.FC<any> = ({ tokenCheck, setTokenCheck }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [applicationModalOpen, setApplicationModalOpen] = useState(false)

  const handleAzureAdUnlink = async () => {
    await api
      .delete("/auth/delete_ad_user_data", {
        params: {
          pathname: window.location.pathname,
        },
      })
      .then(() => api.get("/auth/confirm"))
      .then((response) => {
        setTokenCheck(response.data)
      })
      .catch((error) => {
        console.error("AzureAD unlink error:", error)
      })
    setModalOpen(false)
  }

  return (
    <>
      <Grid container display="flex" justifyContent="center">
        <Grid item sm={3}>
          <Typography
            sx={{
              color: tokenCheck.azure_ad_token ? "#0078D7" : "#C1C3C8",
              fontSize: "2rem",
            }}
          >
            AzureAD
          </Typography>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" marginTop="8px">
        {/* 接続するボタン。azure_ad_connectedが1(true)の場合のみ表示 */}
        {tokenCheck.azure_ad_connected && (
          <Button
            sx={{ border: "1px solid" }}
            type="submit"
            onClick={() => setModalOpen(true)}
          >
            {tokenCheck.azure_ad_token ? "解除する" : "接続する"}
          </Button>
        )}
        {/* 申込するボタン。azure_ad_connectedが0(false)の場合のみ表示 */}
        {!tokenCheck.azure_ad_connected && (
          <Button
            sx={{ border: "1px solid" }}
            onClick={() => setApplicationModalOpen(true)}
          >
            申込する
          </Button>
        )}
      </Box>
      {/* 接続・解除モーダル */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={style} alignItems="center">
          {tokenCheck.azure_ad_token ? (
            <>
              <Typography
                component="h1"
                fontSize="1.5rem"
                fontWeight="bold"
                marginBottom={2}
              >
                AzureADとの解除
              </Typography>
              <Typography>AzureADの連携を解除します。</Typography>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={() => setModalOpen(false)}>キャンセル</Button>
                <Button
                  sx={{ border: "1px solid" }}
                  type="submit"
                  onClick={handleAzureAdUnlink}
                >
                  解除する
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography
                component="h1"
                fontSize="1.5rem"
                fontWeight="bold"
                marginBottom={2}
              >
                AzureADとの接続
              </Typography>
              <Typography>
                microsoftアカウントに接続して、 AzureADと連携します。
              </Typography>
              <Typography>
                ※各従業員のメールアドレスが[microsoft365
                メールアドレス]であることが必須です。
              </Typography>
              <Typography>※管理者アカウントにて接続してください。</Typography>
              <Typography style={{ color: "red" }}>
                ※連携後は従業員管理画面にて同期を行ってください。
              </Typography>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={() => setModalOpen(false)}>キャンセル</Button>
                <form
                  method="post"
                  action={`${process.env.REACT_APP_API_HOST}/auth/microsoft_graph_auth_company?${window.location.pathname}azure`}
                >
                  <Button sx={{ border: "1px solid" }} type="submit">
                    接続する
                  </Button>
                </form>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      {/* 申込案内モーダル */}
      <Modal
        open={applicationModalOpen}
        onClose={() => setApplicationModalOpen(false)}
      >
        <Box
          sx={{
            ...style,
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <Box mb={4}>
            <Typography variant="h5" fontWeight="bold" mb={2}>
              AzureADとの接続
            </Typography>
            <Typography>
              WORK
              AGILEではAzureADとの従業員データ及びSAML認証の連携ができます。
            </Typography>
            <Typography>連携に際しては、お申し込みが必要です。</Typography>
            <Typography>
              AzureAD連携に関するマニュアルは
              <Link
                href="https://workagile.jp/index.php/2024/06/11/function_guide_entraid/"
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </Link>
              を参照の上、以下どちらかの方法でお問い合わせください。
            </Typography>
            <Typography component="span">
              <br />・
              <Link
                href="https://workagile.jp/index.php/contact-2/"
                target="_blank"
                rel="noopener noreferrer"
              >
                お問合せページ
              </Link>
              からのお申し込み
            </Typography>
            <Typography>
              お問合せ内容でヘルプデスクを選んでいただき、お問い合わせ詳細に「AzureADとの連携を申し込みたい」とお伝えください。
            </Typography>
            <br />・ 営業担当へのご連絡
            <Typography>
              WORK AGILE営業担当の連絡先が分かる方は、お気軽にご連絡ください。
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              paddingBottom: "20px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setApplicationModalOpen(false)}
            >
              キャンセル
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
