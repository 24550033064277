import React from "react"

import { Box, Typography, styled, Button } from "@mui/material"

import { SignageMeetingRoomType } from "../../../../models/signage/useMeetingRoom/type"
import { hours } from "../../../../utils/hours"
import { Loading } from "../../../public/Loading"
import { MeetingRoomScheduleTable } from "../MeetingRoomScheduleTable"
import { MeetingRoomScheduleTimeHeader } from "../MeetingRoomScheduleTimeHeader"

const defaultHeight = 36
const startPosition = 10

const CustomTypography = styled(Typography)(() => ({
  color: "#C1C1C1",
  opacity: 0.7,
  fontSize: "0.9rem",
  textAlign: "right",
  marginRight: "1rem",
}))

const MeetingScheduleBox = styled(Box)(() => ({
  position: "absolute",
  width: "90%",
  borderBottom: "1px solid #C1C1C1",
  opacity: 0.4,
  zIndex: "100",
}))

interface Props {
  loading: boolean
  meetingRoomData: SignageMeetingRoomType
  meetingRoomCheckInUrl: string
  meetingRoomCheckOutUrl: string
}

export const SignageMeetingRoomMain: React.FC<Props> = ({
  loading,
  meetingRoomData,
  meetingRoomCheckInUrl,
  meetingRoomCheckOutUrl,
}: Props) => {
  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <Box position="relative" height="20%">
        <MeetingRoomScheduleTimeHeader />
        <Button
          sx={{
            borderRadius: "16px",
            position: "absolute",
            top: 25,
            right: 60,
            height: "30px",
            width: "70px",
            fontSize: "0.6rem",
            padding: "0px 4px",
          }}
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          再読み込み
        </Button>
      </Box>
      <Box
        height="80%"
        sx={{
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          padding: "10px 20px 20px 20px",
        }}
      >
        <Box display="flex" position="relative">
          <Box width="10%">
            {hours.map((hour, index) => (
              <Box key={index} height="36px">
                <CustomTypography>{hour}</CustomTypography>
              </Box>
            ))}
          </Box>
          <Box width="90%" position="relative">
            <Box width="100%">
              {hours.map((_, index) => (
                <MeetingScheduleBox
                  key={index}
                  sx={{
                    height: `${startPosition + index * defaultHeight}px`,
                  }}
                />
              ))}
            </Box>
            {meetingRoomData.schedules.map((schedule, index) => {
              const isSecret = schedule.is_secret || false
              if (isSecret) {
                return (
                  <MeetingRoomScheduleTable
                    key={index}
                    schedule={{ ...schedule, schedule_title: "非公開" }}
                    meetingRoomData={meetingRoomData}
                  />
                )
              } else {
                return (
                  <MeetingRoomScheduleTable
                    key={index}
                    schedule={schedule}
                    meetingRoomData={meetingRoomData}
                    meetingRoomCheckInUrl={meetingRoomCheckInUrl}
                    meetingRoomCheckOutUrl={meetingRoomCheckOutUrl}
                  />
                )
              }
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}
