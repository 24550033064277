import React, { useEffect, useState } from "react"

import {
  Box,
  Button,
  Grid,
  InputLabel,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import { useBilling } from "../../../models/company/useBilling"
import { Billing } from "../../../models/company/useBilling/type"
import { jaDateFormat } from "../../../utils/dateTimeFormat"
import { useOpen } from "../../../utils/isOpen"
import { Paging } from "../../public/Pagination"
import { BiilingModal } from "./BillingModal"
import { ReceiptModal } from "./ReceiptModal"

const CustomLabel = styled(InputLabel)(() => ({
  fontWeight: "bold",
}))

const CustomBodyCell = styled(TableCell)(() => ({
  width: "6%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "85%",
  fontSize: theme.spacing(1.75),
  fontWeight: "bold",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
}))

export const changePaymentMethodsText = (paymentMethods: string) => {
  switch (paymentMethods) {
    case "credit_card":
      return "クレジットカード"
    case "paid":
      return "paid"
    default:
      return "なし"
  }
}

const changePaymentStatusText = (paymentStatus: string) => {
  switch (paymentStatus) {
    case "no_payment":
      return "未入金"
    case "failure_payment":
      return "売上失敗"
    case "temporary":
      return "与信確保"
    case "capture":
      return "売上確定"
    default:
      return "なし"
  }
}

const initBilling = {
  id: 0,
  payment_date: new Date(),
  subject: "",
  billing_number: "",
  billing_amount: 0,
  payment_methods: "",
  payment_status: "",
  created_at: new Date(),
  deadline: new Date(),
  without_tax: 0,
}

export const PaymentHistory = () => {
  const { fetchBillings, billings, company } = useBilling()
  const modalBillingOpen = useOpen()
  const modalReceiptOpen = useOpen()
  const [selectedbilling, setSelectedBilling] = useState<Billing>(initBilling)
  const [pageStatus, setPageStatus] = useState<number>(0) // 表示するデータの先頭のレコードが何番目なのかを管理する(0, 10, 20, 30...)
  const [pageNumber, setPageNumber] = useState<number>(1) // ページネーションコンポーネントで現在どのページが表示されているかを管理する
  const DATA_COUNT_PER_PAGE = 20 // 1ページに表示するレコードの数

  // ページネーションを操作したときの状態を変更する
  const handlePaginationChange = (
    e: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageStatus((page - 1) * DATA_COUNT_PER_PAGE)
    setPageNumber(page)
  }

  useEffect(() => {
    ;(async () => {
      await fetchBillings()
    })()
  }, [])

  return (
    <>
      <CustomLabel>決済履歴一覧</CustomLabel>
      <Box style={{ display: "flex", height: "auto" }}>
        <Grid container justifyContent={"flex-end"}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>決済日</TableCell>
                  <TableCell>品名</TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell>お支払い方法</TableCell>
                  <TableCell>ステータス</TableCell>
                  <TableCell>請求書</TableCell>
                  <TableCell>領収書</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billings
                  .slice(pageStatus, pageStatus + DATA_COUNT_PER_PAGE)
                  .map((billing, index) => {
                    return (
                      <TableRow key={index}>
                        <CustomBodyCell>
                          {jaDateFormat(billing.payment_date)}
                        </CustomBodyCell>
                        <CustomBodyCell>{billing.subject}</CustomBodyCell>
                        <CustomBodyCell>{`￥${billing.billing_amount.toLocaleString()}`}</CustomBodyCell>
                        <CustomBodyCell>
                          {changePaymentMethodsText(billing.payment_methods)}
                        </CustomBodyCell>
                        <CustomBodyCell>
                          {changePaymentStatusText(billing.payment_status)}
                        </CustomBodyCell>
                        <CustomBodyCell>
                          {billing.payment_status === "capture" && (
                            <CustomButton
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setSelectedBilling(billing)
                                modalBillingOpen.open()
                              }}
                              sx={{ textDecoration: "none" }}
                            >
                              発行
                            </CustomButton>
                          )}
                        </CustomBodyCell>
                        <CustomBodyCell>
                          {billing.payment_status === "capture" && (
                            <CustomButton
                              variant="contained"
                              disabled={billing.payment_methods === "paid"}
                              onClick={() => {
                                setSelectedBilling(billing)
                                modalReceiptOpen.open()
                              }}
                              color="primary"
                            >
                              発行
                            </CustomButton>
                          )}
                        </CustomBodyCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
      <BiilingModal
        {...modalBillingOpen}
        billing={selectedbilling}
        company={company}
      />
      <ReceiptModal
        {...modalReceiptOpen}
        billing={selectedbilling}
        company={company}
      />
      <Box
        justifyContent={"center"}
        sx={{
          marginTop: "330px",
        }}
      >
        {billings.length > DATA_COUNT_PER_PAGE && (
          <Paging
            pageCount={Math.ceil(billings.length / DATA_COUNT_PER_PAGE)}
            page={pageNumber}
            handlePaginationChange={handlePaginationChange}
          />
        )}
      </Box>
    </>
  )
}
