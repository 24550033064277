import { useState } from "react"
import { Accept, useDropzone } from "react-dropzone"
import Resizer from "react-image-file-resizer"

export const useImageUpload = () => {
  const [imgBlob, setImgBlob] = useState<Blob | undefined>(undefined) // S3にデータとして送信する画像
  const [imgFile, setImgFile] = useState<string>("") // UI上に描画する画像

  const onDrop = async <T extends File>(acceptedFiles: T[]) => {
    try {
      const createObjectURL = (window.URL || window.webkitURL).createObjectURL // 画像オブジェクトを作成
      const image = await resizeFile(acceptedFiles[0])
      if (acceptedFiles.length != 0) {
        setImgBlob(image as Blob)
        setImgFile(createObjectURL(image as Blob))
      }
    } catch (err) {
      alert(".jpeg.pngの画像を選択してください")
    }
  }

  const accept: Accept = { "image/*": [".jpeg", ".png"] }
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    maxFiles: 1,
  })

  const resizeFile = (file: Blob) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file, // 画像ファイルのパス
        200, // 最大幅
        200, // 最大高さ
        "JPEG", // JPEGに変換
        100, // 圧縮の倍率。特に設定しない場合は、100になる。
        0, // 画像の回転角度
        (uri) => {
          resolve(uri)
        },
        "blob" // blobに変換
      )
    })

  return { imgBlob, imgFile, getRootProps, getInputProps, setImgFile }
}
