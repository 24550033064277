import React from "react"

import { Box, styled, Typography } from "@mui/material"

import {
  MonthScheduleType,
  MonthMultipleScheduleType,
} from "../../../../../../models/employee/useSchedule/months/type"

const ScheduleBox = styled(Box)(() => ({
  height: "24px",
  borderRadius: "7px",
  marginBottom: "5px",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
}))

interface Props {
  day: {
    date: Date
    multiple_schedules: MonthMultipleScheduleType[]
    multipleDisplayCount: number
  }
  getEventWidth: (startDay: Date, endDay: Date) => number
  displayReserved: boolean
  displayNonReserved: boolean
}

export const MonthMultipleDaySchedules: React.FC<Props> = ({
  day,
  getEventWidth,
  displayReserved,
  displayNonReserved,
}: Props) => {
  const targetDay = new Date(day.date).getDate()
  const startDay = (event: MonthScheduleType) => {
    return new Date(event.start_time).getDate()
  }

  day.multiple_schedules.sort((a, b) => a.index - b.index)

  // 日またぎ予定の配列を、表示可能件数分に絞った配列に変換
  const multipleDisplayList: MonthScheduleType[] = []
  for (let i = 0; i < day.multiple_schedules.length; i++) {
    multipleDisplayList.push(day.multiple_schedules[i])
    if (multipleDisplayList.length === day.multipleDisplayCount) {
      break
    }
  }

  return (
    <>
      {multipleDisplayList.map((event, index) => {
        return (
          <>
            {!displayNonReserved && event.reservable_type && (
              <>
                {targetDay === startDay(event) && (
                  <ScheduleBox
                    key={`${index}1`}
                    sx={{
                      bgcolor: "#DBF4EF",
                      color: "#4EC7B0",
                      width: `${event.width}%`,
                      border: "1px solid #4EC7B0",
                      zIndex: 10,
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {event.schedule_title}
                    </Typography>
                  </ScheduleBox>
                )}
                {/* 週またぎ予定の表示 */}
                {targetDay > startDay(event) &&
                  new Date(day.date).getDay() === 0 && (
                    <ScheduleBox
                      key={`${index}2`}
                      sx={{
                        bgcolor: "#DBF4EF",
                        color: "#4EC7B0",
                        width: `${getEventWidth(day.date, event.end_time)}%`,
                        border: "1px solid #4EC7B0",
                        zIndex: 10,
                      }}
                    >
                      <Typography
                        align="left"
                        ml="5px"
                        fontSize="12px"
                        fontFamily="Noto Sans"
                      >
                        {event.schedule_title}
                      </Typography>
                    </ScheduleBox>
                  )}
                {targetDay !== startDay(event) && targetDay !== 0 && (
                  <ScheduleBox
                    key={`${index}3`}
                    sx={{
                      bgcolor: "#fff",
                      color: "#fff",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {event.schedule_title}
                    </Typography>
                  </ScheduleBox>
                )}
              </>
            )}
            {!displayReserved && !event.reservable_type && (
              <>
                {targetDay === startDay(event) && (
                  <ScheduleBox
                    key={`${index}1`}
                    sx={{
                      bgcolor: "#DBF4EF",
                      color: "#4EC7B0",
                      width: `${event.width}%`,
                      border: "1px solid #4EC7B0",
                      zIndex: 10,
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {event.schedule_title}
                    </Typography>
                  </ScheduleBox>
                )}
                {/* 週またぎ予定の表示 */}
                {targetDay > startDay(event) &&
                  new Date(day.date).getDay() === 0 && (
                    <ScheduleBox
                      key={`${index}2`}
                      sx={{
                        bgcolor: "#DBF4EF",
                        color: "#4EC7B0",
                        width: `${getEventWidth(day.date, event.end_time)}%`,
                        border: "1px solid #4EC7B0",
                        zIndex: 10,
                      }}
                    >
                      <Typography
                        align="left"
                        ml="5px"
                        fontSize="12px"
                        fontFamily="Noto Sans"
                      >
                        {event.schedule_title}
                      </Typography>
                    </ScheduleBox>
                  )}
                {targetDay !== startDay(event) && targetDay !== 0 && (
                  <ScheduleBox
                    key={`${index}3`}
                    sx={{
                      bgcolor: "#fff",
                      color: "#fff",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      align="left"
                      ml="5px"
                      fontSize="12px"
                      fontFamily="Noto Sans"
                    >
                      {event.schedule_title}
                    </Typography>
                  </ScheduleBox>
                )}
              </>
            )}
          </>
        )
      })}
    </>
  )
}
