import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { AlertContent } from "../../../components/public/AlertContent"
import { SignageMeetingRoomMain } from "../../../components/signage/meetingRoom/Main"
import { MeetingRoomScheduleDetail } from "../../../components/signage/meetingRoom/MeetingRoomScheduleDetail"
import { theme } from "../../../config/theme"
import { useSignageMeetingRoom } from "../../../models/signage/useMeetingRoom"

export const SignageMeetingRoomSchedulePage = () => {
  const urlParams = useParams()
  const signageObject = useSignageMeetingRoom()
  const scheduleId = Number(urlParams.schedule_id)
  const meetingRoomId = Number(urlParams.meeting_room_id)

  useEffect(() => {
    const fetchMeetigRoomSchedules = async () => {
      try {
        await signageObject.fetchSignageMeetingRoomSchedules(meetingRoomId)
        await signageObject.fetchMeetingRoomScheduleDetail(scheduleId)
      } catch (error) {
        console.log("error: ", error)
      }
    }
    fetchMeetigRoomSchedules()
  }, [scheduleId])
  return (
    <>
      <AlertContent />
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          container
          sm={6}
          sx={{
            position: "relative",
            paddingRight: theme.spacing(1.5),
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MeetingRoomScheduleDetail {...signageObject} />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            height: "100%",
            backgroundColor: "#fff",
          }}
        >
          <SignageMeetingRoomMain {...signageObject} />
        </Grid>
      </Grid>
    </>
  )
}
