import React from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import {
  Grid,
  Typography,
  styled,
  TextField,
  InputLabel,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material"

import { theme } from "../../../../config/theme"
import { LoginType } from "../../../../models/public/useAuth/login/type"
import { pageType } from "../../../../utils/location"
import { AlertErrorContent } from "../../../public/AlertContent"
import { ErrorText } from "../../../public/ErrorText"
import { Loading } from "../../../public/Loading"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

const CustomGridItem = styled(Grid)(() => ({
  maxWidth: theme.spacing(53),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomButton = styled(Button)(() => ({
  color: "#FFFFFF",
  fontSize: theme.spacing(2),
  padding: theme.spacing(1, 8),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

interface Props {
  authData: LoginType
  control: Control<LoginType>
  handleSubmit: UseFormHandleSubmit<LoginType>
  errors: FieldErrors<LoginType>
  isValid: boolean
  open: boolean
  errorMessages: string[]
  onSignageSubmit: SubmitHandler<LoginType>
  loading: boolean
}

export const SignageLoginForm = ({
  control,
  handleSubmit,
  errors,
  isValid,
  open,
  errorMessages,
  onSignageSubmit,
  loading,
}: Props) => {
  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <AlertErrorContent />
      <CustomGridContainer
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        spacing={3}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <img
              src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
              alt="WORK AGILEロゴ"
            />
          </Grid>
        </Grid>
        <CustomGridItem item>
          <Typography
            variant="subtitle1"
            color="#707070"
            align="center"
            fontWeight="bold"
          >
            管理者のアカウントでログインしてください
          </Typography>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <InputLabel htmlFor="email">
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                メールアドレス
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  id="email"
                  name="email"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </CustomGridItem>
        <CustomGridItem item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <InputLabel htmlFor="password">
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                パスワード
              </Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  required
                  id="password"
                  name="password"
                  fullWidth
                  variant="outlined"
                  type="password"
                />
              )}
            />
          </Grid>
        </CustomGridItem>
        {/* <Grid item>
          <Controller
            name="rememberLoginData"
            render={({ field }) => (
              <FormControlLabel
                sx={{ marginRight: "0" }}
                label={
                  <Typography variant="subtitle2">
                    メールアドレスとパスワードを記憶する
                  </Typography>
                }
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    id="rememberLoginData"
                  />
                }
              />
            )}
            control={control}
          />
        </Grid> */}
        {open && (
          <CustomGridItem item container>
            <Grid xs={12} textAlign="center">
              {ErrorText(errorMessages)}
            </Grid>
          </CustomGridItem>
        )}
        <Grid item>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <CustomButton
              onClick={handleSubmit(onSignageSubmit)}
              variant="contained"
              disabled={!isValid}
              color="primary"
            >
              ログイン
            </CustomButton>
          </Box>
        </Grid>
        <Grid item>
          <Typography align="center">
            <Link
              component={RouterLink}
              to={`/${pageType}/forgot-password`}
              variant="caption"
              color="#43425D"
            >
              パスワードをお忘れの方はこちら
            </Link>
          </Typography>
        </Grid>
      </CustomGridContainer>
    </>
  )
}
