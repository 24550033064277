const en = {
  SeatLocation: "Location",
  Location: "Location",
  Floor: "Floor",
  DeskRoom: "Desk(Room)",
  Attendees: "Attendees",
  Name: "Name",
  Accepted: "Accepted",
  Declined: "Declined",
  from: "-",
  people: "",
  manyPeople: "",
  Description: "Description",
  NoTitle: "(No title)",
  month: " / ",
  day: "",
  week: "",
  everyMonth: "every month",
  everyWeek: "every week",
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  everyMonths: "every months",
  Monthly: "monthly",
  weekly: "weekly",
  Private: "Private",
  first: "1st",
  second: "2st",
  third: "3st",
  fourth: "4st",
  last: "last",
  invitation: "Respond to an invitation",
  Decline: "Decline",
  Accept: "Accept",
  ScheduleDeleted: "Schedule deleted!",
  autoCancel: "This reservation is automatically canceled.",
  gest: "gest",
  schedule_statuses: {
    出社: "In Office",
    在宅: "Work from home",
    外出: "Out of office",
    出張: "Business Trip",
    休暇: "DAY OFF/TIME OFF",
    その他: "Other",
  },
}
export default en
