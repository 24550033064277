import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  fetchSubscriptionNotifyEmailRequest,
  updateSubscriptionNotifyEmailRequest,
} from "../../../api/company/companyRequest"
import { EMAIL_REGEXP } from "../../../utils/regular"

export const useSubscriptionNotifyEmail = () => {
  const navigate = useNavigate()
  const [subscriptionNotifyEmail, setSubscriptionNotifyEmail] =
    useState<string>("")

  const basicSchema = Yup.object().shape({
    email: Yup.string()
      .required("必須になります")
      .matches(EMAIL_REGEXP, "メールアドレスの形式で入力してください"),
  })

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      email: subscriptionNotifyEmail,
    },
    resolver: yupResolver(basicSchema),
  })

  const updateSubscriptionNotifyEmail = async () => {
    try {
      const response = await updateSubscriptionNotifyEmailRequest({
        subscription_notify_email: subscriptionNotifyEmail,
      })
      if (response.status === 204) {
        localStorage.setItem(
          "alertContent",
          "支払い通知メールアドレスを変更しました"
        )
        navigate(`/company/settings/payment`)
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  const fetchSubscriptionNotifyEmail = async () => {
    try {
      const response = await fetchSubscriptionNotifyEmailRequest()
      if (response.data && response.status === 200) {
        setSubscriptionNotifyEmail(response.data.subscription_notify_email)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    form,
    subscriptionNotifyEmail,
    setSubscriptionNotifyEmail,
    fetchSubscriptionNotifyEmail,
    updateSubscriptionNotifyEmail,
  }
}
