import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { Box, Grid, Modal, Typography, styled } from "@mui/material"

import { useBranch } from "../../../../models/company/useBranch"
import { BranchType } from "../../../../models/company/useBranch/type"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { ReflectButton } from "../../../public/ReflectButton"
import { WarningText } from "../../../public/WarningText"

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "35%",
  borderRadius: "10px",
}))

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

type Props = {
  selectedBranch: BranchType | undefined
  setSelectedBranch: Dispatch<SetStateAction<BranchType | undefined>>
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const DeleteBranchModal: React.FC<Props> = ({
  selectedBranch,
  setSelectedBranch,
  isOpen,
  setIsOpen,
}: Props) => {
  const { deleteBranch, errorMessages, setErrorMessages } = useBranch()
  const [deleteButtonDisabled, setDeleteButtonDisabled] =
    useState<boolean>(false)

  useEffect(() => {
    setErrorMessages([])

    // アクティブユーザーまたは利用停止ユーザーが存在する場合削除不可
    if (selectedBranch) {
      const activeEmployeeCount = selectedBranch.employee_count
      const pausedEmployeeCount = selectedBranch.paused_employee_count

      if (activeEmployeeCount !== 0 || pausedEmployeeCount !== 0) {
        setErrorMessages([
          "対象の拠点を主拠点とした従業員がいます。",
          "従業員管理画面から、拠点で検索し、対象の拠点を主拠点とする社員がいない状態にしてから、再度削除してください。",
          "※ 利用停止中社員の所属の場合もある為、利用停止中社員についてもお確かめください。",
        ])
        setDeleteButtonDisabled(true)
      } else {
        setErrorMessages([])
        setDeleteButtonDisabled(false)
      }
    }
  }, [selectedBranch])

  const onDelete = () => {
    if (!selectedBranch) return

    setDeleteButtonDisabled(true)

    deleteBranch(selectedBranch.id)
      .then(() => {
        setDeleteButtonDisabled(false)
        localStorage.setItem("alertContent", "拠点を削除しました")
        window.location.reload()
      })
      .catch((e) => {
        setErrorMessages(e)
      })
  }

  const onClose = () => {
    setSelectedBranch(undefined)
    setIsOpen(false)
  }

  if (!isOpen || !selectedBranch) return <></>

  return (
    <Modal open={isOpen} onClose={onClose}>
      <CustomLayoutModal>
        <Box sx={{ padding: "30px 30px 20px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            variant="h6"
          >
            {`${selectedBranch.branch_name}を削除します。よろしいですか？`}
          </Typography>
          {errorMessages.length === 0 ? (
            <Grid item xs={12} sm={12}>
              {WarningText([
                "削除したデータは永久に消滅します。登録されているフロア設定、レイアウトデータ、座席情報、エリア情報、会議室情報なども消滅し復元できません。",
                "対象拠点で現在時間以降予約されている座席・会議室などの予約は全て解除されます。予約を行っていた従業員には、拠点が削除された旨、同拠点の予約が解除された旨、システムメールにて通知されます。",
              ])}
            </Grid>
          ) : (
            <ErrorMessageContainer>
              {ErrorText(errorMessages)}
            </ErrorMessageContainer>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton label="キャンセル" handleClick={onClose} />
            <ReflectButton
              label="削除"
              disabled={deleteButtonDisabled}
              handleClick={onDelete}
            />
          </Box>
        </Box>
      </CustomLayoutModal>
    </Modal>
  )
}
