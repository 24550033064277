import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Box } from "@mui/material"

import { useEmployeeGroup } from "../../../../../models/employee/useEmployeeGroup"
import { useDaySchedule } from "../../../../../models/employee/useSchedule/days"
import { ScheduleTable } from "../../../../../models/employee/useSchedule/days/type"
import { useScheduleSearchEmployee } from "../../../../../models/employee/useSchedule/scheduleSearchEmployeeForm/index"
import { useScheduleShowData } from "../../../../../models/employee/useSchedule/show"
import { EmployeesType } from "../../../../../models/employee/useSchedule/type"
import { useScheduleStartHour } from "../../../../../models/employee/useScheduleStartHour/index"
import { SchedulableMaxDateProvider } from "../../../../../providers/SchedulableMaxDateProvider"
import { dateStatus } from "../../../../../utils/date"
import { useOpen } from "../../../../../utils/isOpen"
import { AlertContent } from "../../../../public/AlertContent"
import { Loading } from "../../../../public/Loading"
import { EmployeeScheduleSideSearch } from "../../common/EmployeeScheduleSideSearch"
import { EmployeeScheduleDayHeader } from "../EmployeeScheduleDayHeader"
import { EmployeeScheduleDayShowModal } from "../EmployeeScheduleDayShowModal"
import { EmployeeScheduleDaySideBar } from "../EmployeeScheduleDaySidebar"
import { EmployeeScheduleDayTable } from "../EmployeeScheduleDayTable"
import { EmployeeScheduleTimeline } from "../EmployeeScheduleTimeline"

interface LocationState {
  displayReserved: boolean
  displayNonReserved: boolean
  employees: EmployeesType | undefined
  navigateFrom?: string
  scheduleDate?: {
    scheduleStartDate: string
  }
}

export const DayCalendar: React.FC = () => {
  const location = useLocation()
  const {
    scheduleMembers,
    setScheduleMembers,
    daySchedules,
    setDaySchedules,
    handleDayScheduleFetch,
    scheduleDayLoading,
    checkedEmployees,
    setCheckedEmployees,
  } = useDaySchedule((location.state as LocationState)?.employees)
  const dateObject = dateStatus()
  const searchObject = useScheduleSearchEmployee()
  const employeeGroupObject = useEmployeeGroup()
  const [filteredTableData, setFilteredTableData] = useState<ScheduleTable>([])

  const [displayReserved, setDisplayReserved] = useState<boolean>(
    (location.state as LocationState)?.displayReserved || false
  )
  const [displayNonReserved, setDisplayNonReserved] = useState<boolean>(
    (location.state as LocationState)?.displayNonReserved || false
  )

  const { isOpen, open, close } = useOpen()
  const {
    scheduleShowData,
    fetchScheduleShowData,
    onApproveSubmit,
    scheduleShowLoading,
    setScheduleShowData,
  } = useScheduleShowData()

  const defaultWidth = 130
  const startPosition = 15
  const startHour = useScheduleStartHour()

  const loading = scheduleDayLoading || scheduleShowLoading
  const navigateFrom = (location.state as LocationState)?.navigateFrom

  // 横スクロールの初期位置を始業時間に合わせる
  useEffect(() => {
    const scrollContent = document.getElementById("scroll-content")
    if (scrollContent && startHour !== null) {
      scrollContent.scrollLeft = defaultWidth * startHour - startPosition
    }
  }, [startHour])

  useEffect(() => {
    const employeeIds = searchObject.selectedEmployees.map((employee) => {
      return employee.id
    })
    const locationState = location.state as LocationState
    const scheduleStartDate = locationState?.scheduleDate?.scheduleStartDate

    if (scheduleStartDate) {
      const targetDate = new Date(scheduleStartDate)

      const newStartDate = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate() - 1
      )
      const newEndDate = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate() + 1
      )
      handleDayScheduleFetch({
        start_date: dateObject.formatDate(newStartDate),
        end_date: dateObject.formatDate(newEndDate),
        employee_ids: employeeIds,
      })
      dateObject.setStartDate(newStartDate)
      dateObject.setEndDate(newEndDate)
    } else if (navigateFrom) {
      // 予定の登録・削除および予定（週）画面からの遷移時は(localStorage内)"targetDay"の値を反映
      const targetDate = /week/.test(navigateFrom)
        ? new Date(
            parseInt(localStorage.getItem("BeginningOfWeek") as string, 10)
          )
        : new Date(parseInt(localStorage.getItem("targetDay") as string, 10))

      dateObject.setDate(targetDate)
      dateObject.setWeekdayNumber(targetDate.getDay())
      window.localStorage.setItem("targetDay", targetDate.getTime().toString())

      const newStartDate = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate() - 1
      )
      const newEndDate = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate() + 1
      )
      handleDayScheduleFetch({
        start_date: dateObject.formatDate(newStartDate),
        end_date: dateObject.formatDate(newEndDate),
        employee_ids: employeeIds,
      })
      dateObject.setStartDate(newStartDate)
      dateObject.setEndDate(newEndDate)
    } else {
      const targetDate = new Date(
        parseInt(localStorage.getItem("targetDay") as string, 10)
      )
      const newStartDate = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate() - 1
      )
      const newEndDate = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate() + 1
      )
      window.localStorage.setItem(
        "targetDay",
        dateObject.date.getTime().toString()
      )
      handleDayScheduleFetch({
        start_date: dateObject.formatDate(newStartDate),
        end_date: dateObject.formatDate(newEndDate),
        employee_ids: employeeIds,
      })
      dateObject.setStartDate(newStartDate)
      dateObject.setEndDate(newEndDate)
    }
  }, [])

  useEffect(() => {
    setFilteredTableData(filteredData)
  }, [daySchedules, dateObject.date])

  // 一覧になっている予定のうち、選択された日付のデータだけを取得する
  const filteredData = daySchedules.filter((schedule) => {
    return schedule.scheduled_date === dateObject.formatDate(dateObject.date)
  })

  // バツアイコンを押した従業員の予定を配列から除外する
  const changeDisplay = (
    members: EmployeesType,
    schedules: ScheduleTable,
    index: number
  ) => {
    const newMembers = members.filter((member, i) => {
      return i !== index
    })

    const newSchedules = schedules.filter((schedule, i) => {
      return i !== index
    })
    setScheduleMembers(newMembers)
    setDaySchedules(newSchedules)

    const newSelectedEmployees = searchObject.selectedEmployees.filter(
      (employee, i) => {
        return i !== index - 1
      }
    )
    searchObject.setSelectedEmployees(newSelectedEmployees)

    localStorage.setItem(
      "selectedEmployees",
      JSON.stringify(newSelectedEmployees)
    )
  }

  const navigate = useNavigate()
  const newScheduleNavigate = (startTime?: number, endTime?: number) => {
    if (startTime && endTime) {
      navigate("/employee/calendar/new", {
        state: {
          employees: checkedEmployees,
          defaultDate: dateObject.date,
          scheduleDayDefaultStartTime: startTime,
          scheduleDayDefaultEndTime: endTime,
          navigateFrom: window.location.pathname,
        },
      })
    } else {
      navigate("/employee/calendar/new", {
        state: {
          employees: checkedEmployees,
          defaultDate: dateObject.date,
          navigateFrom: window.location.pathname,
        },
      })
    }
  }

  // ヘッダーの「席」を押した時の関数（席予約と会議室予約のみの表示に切り替え）
  const displayOnlyReserved = () => {
    setDisplayReserved(true)
    setDisplayNonReserved(false)
  }

  // ヘッダーの「スケジュール」を押した時の関数（席予約と会議室予約以外の表示に切り替え）
  const displayOnlyNonReserved = () => {
    setDisplayNonReserved(true)
    setDisplayReserved(false)
  }

  // ヘッダーの「全て」を押した時の関数（「席」「スケジュール」選択時の表示解除）
  const displayAll = () => {
    setDisplayReserved(false)
    setDisplayNonReserved(false)
  }

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <AlertContent />
      <SchedulableMaxDateProvider>
        <Box display="flex" width="100%" minHeight="570px">
          <Box marginRight="15px">
            <EmployeeScheduleDayHeader
              {...dateObject}
              {...employeeGroupObject}
              displayOnlyReserved={displayOnlyReserved}
              displayOnlyNonReserved={displayOnlyNonReserved}
              displayAll={displayAll}
              selectedEmployees={searchObject.selectedEmployees}
              handleDayScheduleFetch={handleDayScheduleFetch}
              employees={scheduleMembers}
              displayReserved={displayReserved}
              displayNonReserved={displayNonReserved}
            />
            <Box
              id="scroll-content"
              display="flex"
              sx={{
                overflow: "auto",
                width: "calc(100vw - 415px)",
              }}
            >
              <Box
                sx={{
                  width: "20%",
                  position: "sticky",
                  left: 0,
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      height: "1.5rem",
                      position: "sticky",
                      top: -4,
                      zIndex: 10001,
                      backgroundColor: "white",
                    }}
                  ></Box>
                  <Box borderRight={1} borderColor="#C1C1C1">
                    {scheduleMembers.map((value, index) => (
                      <EmployeeScheduleDaySideBar
                        key={index}
                        {...value}
                        index={index}
                        scheduleMembers={scheduleMembers}
                        filteredTableData={filteredTableData}
                        changeDisplay={changeDisplay}
                        checkedEmployees={checkedEmployees}
                        setCheckedEmployees={setCheckedEmployees}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "80%",
                  zIndex: 1,
                }}
              >
                <Box>
                  <EmployeeScheduleTimeline />
                  {filteredTableData.map((value, index) => (
                    <EmployeeScheduleDayTable
                      key={index}
                      index={index}
                      open={open}
                      {...value}
                      displayReserved={displayReserved}
                      displayNonReserved={displayNonReserved}
                      fetchScheduleShowData={fetchScheduleShowData}
                      newScheduleNavigate={newScheduleNavigate}
                      setScheduleShowData={setScheduleShowData}
                      defaultWidth={defaultWidth}
                      startPosition={startPosition}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box borderLeft="solid 1px #707070" width="275px" paddingLeft="15px">
            <EmployeeScheduleSideSearch
              {...dateObject}
              {...searchObject}
              handleScheduleFetch={handleDayScheduleFetch}
              employees={checkedEmployees}
              employeeGroups={employeeGroupObject.employeeGroups}
              newScheduleNavigate={newScheduleNavigate}
            />
          </Box>
          <EmployeeScheduleDayShowModal
            date={dateObject.date}
            isOpen={isOpen}
            close={close}
            scheduleShowData={scheduleShowData}
            onApproveSubmit={onApproveSubmit}
          />
        </Box>
      </SchedulableMaxDateProvider>
    </>
  )
}
