import React, { useContext } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Box, Typography, Chip, Checkbox } from "@mui/material"

import { ScheduleTable } from "../../../../../models/employee/useSchedule/days/type"
import {
  EmployeesType,
  EmployeeType,
} from "../../../../../models/employee/useSchedule/type"
import { AuthContext } from "../../../../../providers/AuthProvider"

interface Props {
  id: number
  first_name: string
  last_name: string
  email: string
  index: number
  scheduleMembers: EmployeesType
  filteredTableData: ScheduleTable
  changeDisplay: (
    members: EmployeesType,
    schedules: ScheduleTable,
    index: number
  ) => void
  checkedEmployees: EmployeeType[]
  setCheckedEmployees: React.Dispatch<React.SetStateAction<EmployeeType[]>>
}

export const EmployeeScheduleDaySideBar: React.FC<Props> = ({
  id,
  first_name,
  last_name,
  email,
  index,
  scheduleMembers,
  filteredTableData,
  changeDisplay,
  checkedEmployees,
  setCheckedEmployees,
}: Props) => {
  const { currentUser } = useContext(AuthContext)

  const switchVisible = () => {
    changeDisplay(scheduleMembers, filteredTableData, index)
  }

  const handleEmployeeChecked = (checked: boolean) => {
    if (
      !checkedEmployees.find((employee) => employee.email === email) &&
      checked
    ) {
      setCheckedEmployees([
        ...checkedEmployees,
        {
          id: id,
          last_name: last_name,
          first_name: first_name,
          email: email,
        },
      ])
    } else {
      setCheckedEmployees(
        checkedEmployees.filter((employee) => employee.email !== email)
      )
    }
  }

  return (
    <>
      <Box
        sx={{
          position: index == 0 ? "sticky" : "none",
          top: index == 0 ? "20px" : "none",
          backgroundColor: "white",
          zIndex: index == 0 ? 1001 : 100,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          borderTop={1}
          borderLeft={1}
          borderBottom={1}
          borderColor="#C1C1C1"
          paddingLeft={1}
          sx={{
            height: "6rem",
            lineHeight: "6rem",
          }}
        >
          {currentUser && currentUser.attributes.email != email && (
            <Checkbox
              checked={
                !!checkedEmployees.find((employee) => employee.email === email)
              }
              onChange={(_, checked) => {
                handleEmployeeChecked(checked)
              }}
            />
          )}
          <Typography
            marginLeft={
              currentUser && currentUser.attributes.email === email
                ? "2.5rem"
                : "0"
            }
            sx={{
              fontSize: "12px",
              fontFamily: "Noto Sans",
              letterSpacing: "0.8",
            }}
          >
            <span>{last_name}</span>
            <span style={{ paddingLeft: "1rem" }}>{first_name}</span>
          </Typography>

          {email !== currentUser?.attributes.email && (
            <Box mx="0.5rem">
              <Chip
                sx={{
                  "& .MuiChip-icon": {
                    marginRight: "-12px",
                  },
                }}
                label=""
                icon={<CloseIcon />}
                onClick={switchVisible}
                size="small"
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
