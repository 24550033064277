import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { CompanyEmployeeShowData } from "../../../components/company/employee/CompanyEmployeeDetail"
import { AlertContent } from "../../../components/public/AlertContent"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { Loading } from "../../../components/public/Loading"
import { useEmployee } from "../../../models/company/useEmployee"

export const CompanyEmployeeShowPage = () => {
  const employeeObject = useEmployee()
  const urlParams = useParams<{ id: string }>()

  const selectedEmployeeId = Number(urlParams.id)

  useEffect(() => {
    employeeObject.fetchEmployeeDetail(selectedEmployeeId)
  }, [])

  useEffect(() => {
    if (employeeObject.employeeDetail.id > 0) {
      employeeObject.fetchFile(
        employeeObject.employeeDetail.account.company_id,
        employeeObject.employeeDetail.id
      )
    }
  }, [employeeObject.employeeDetail])

  return (
    <>
      <ContentTemplate>
        <ContentCompanyPaper>
          {employeeObject.loading ? (
            <Loading type="content" loading={employeeObject.loading} />
          ) : (
            <CompanyEmployeeShowData {...employeeObject} />
          )}
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertContent />
    </>
  )
}
