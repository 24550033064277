import { TagCategoryApplianceType } from "../../models/public/useTag/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const tagCategoryApplianceRequest = async (): Promise<
  ApiResponse<TagCategoryApplianceType>
> => {
  try {
    const data = await api.get("/public/tags")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
