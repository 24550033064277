import React, { useState } from "react"

import { Box, styled, Typography } from "@mui/material"

import { theme } from "../../../../config/theme"
import {
  dateTimeOnlyFormat,
  dateAndDayOfWeekFormat,
} from "../../../../utils/dateTimeFormat"

const CustomBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}))

const TimeTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  fontSize: "70px",
  letterSpacing: theme.spacing(1),
}))

const DateTypography = styled(Typography)(() => ({
  color: "#454545",
  fontSize: "24px",
  letterSpacing: theme.spacing(1),
  padding: "0 auto",
}))

export const MeetingRoomScheduleTimeHeader = () => {
  const [time, setTime] = useState<Date>(new Date())

  // 時計表示の更新
  const upDateTime = () => {
    setTime(new Date())
    countTime = window.setTimeout(upDateTime, 1000)
  }

  // 1秒ごとにupDateTimeを起動
  let countTime = window.setTimeout(upDateTime, 1000)

  return (
    <CustomBox>
      <TimeTypography>{dateTimeOnlyFormat(time)}</TimeTypography>
      <DateTypography>{dateAndDayOfWeekFormat(time)}</DateTypography>
    </CustomBox>
  )
}
