import React from "react"

import { Box } from "@mui/material"

import { EmployeeLayoutSearch } from "../../common/EmployeeLayoutSearch"

interface Props {
  handleEmployeeSearch: (targetEmployeeId: number) => void
  userNotExists: boolean
  userNotExistsMessage: string
  setUserNotExists: React.Dispatch<React.SetStateAction<boolean>>
}

// 席状況＞状況のサイドバー
export const EmployeeStatusSideBar = ({
  handleEmployeeSearch,
  userNotExists,
  userNotExistsMessage,
  setUserNotExists,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <Box width="100%" height="100%">
        <EmployeeLayoutSearch
          type="status"
          handleEmployeeSearch={handleEmployeeSearch}
          userNotExists={userNotExists}
          userNotExistsMessage={userNotExistsMessage}
          setUserNotExists={setUserNotExists}
        />
      </Box>
    </Box>
  )
}
