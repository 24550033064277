import React, { useState } from "react"

import { fetchAutoCancelTimesRequest } from "../../../api/public/autoCancelTimesRequest"
import { AutoCancelTimesType } from "./type"

export const useAutoCancelTimes = () => {
  const [autoCancelTimes, setAutoCancelTimes] = useState<AutoCancelTimesType[]>(
    []
  )

  const fetchAutoCancelTimes = async () => {
    try {
      const response = await fetchAutoCancelTimesRequest()
      if (response.data && response.status === 200) {
        setAutoCancelTimes(response.data)
      }
    } catch (e) {
      console.log("error")
    }
  }
  return {
    autoCancelTimes,
    fetchAutoCancelTimes,
  }
}
