import {
  CompanyTeamRequestType,
  TeamListResponseType,
  TeamResponseType,
  CreateOrUpdateTeamResponseType,
  TeamHierarchyListResponseType,
} from "../../../models/company/useTeam/type"
import { api, ApiResponse } from "../../config/axiosConfig"

export const TeamListRequest = async (): Promise<
  ApiResponse<TeamListResponseType[]>
> => {
  try {
    const data = await api.get("/company/teams/")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const TeamHierarchyListRequest = async (): Promise<
  ApiResponse<TeamHierarchyListResponseType>
> => {
  try {
    const data = await api.get("/company/teams/hierarchy")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const createTeamRequest = async (
  params: CompanyTeamRequestType
): Promise<ApiResponse<void>> => {
  try {
    await api.post("/company/teams/", params)
    return {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const editTeamRequest = async (params: {
  team_id: number
}): Promise<ApiResponse<TeamResponseType>> => {
  try {
    const data = await api.get(`/company/teams/${params?.team_id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}

export const updateTeamRequest = async (
  params: CompanyTeamRequestType
): Promise<ApiResponse<CreateOrUpdateTeamResponseType>> => {
  try {
    const { data } = await api.patch(`/company/teams/${params?.id}`, params)
    return { data: data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const deleteTeamRequest = async (params: {
  team_id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.delete(`/company/teams/${params?.team_id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 422) {
      throw error
    }
    return { error: error.response.data.message }
  }
}
