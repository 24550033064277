import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { SelectChangeEvent } from "@mui/material"

import {
  checkInRequest,
  checkInEndOfShiftRequest,
  fetchCheckInStatusRequest,
  instantCheckInRequest,
} from "../../../../api/employee/scheduleRequest"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { AuthContext } from "../../../../providers/AuthProvider"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { fiveIntervalMinutes } from "../../../../utils/hours"
import { roundDownMinutes, roundUpMinutes } from "../../../../utils/time"
import { ReservableType } from "../../../company/useQrCode/type"
import { CompanyRelationsType } from "../../../public/useCompanyRelations/type"
import { CheckInData } from "../type"

export const useScheduleCheckIn = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [branchName, setBranchName] = useState<string>("")
  const [floorId, setFloorId] = useState<number>(0)
  const { currentUser } = useContext(AuthContext)
  const [floorNumber, setFloorNumber] = useState<string>("")
  const [checkInType, setCheckInType] = useState<ReservableType>("Seat")
  const [checkInTarget, setCheckInTarget] = useState<string>("")
  const [targetId, setTargetId] = useState<number>(0)
  const [notReservedModalMessage, setNotReservedModalMessage] =
    useState<string>("")
  const [notReserved, setNotReserved] = useState<boolean>(false)
  const [reservationHour, setReservationHour] = useState<number>(-1) // プルダウンの未選択状態を-1で定義
  const [reservationMinutes, setReservationMinutes] = useState<number>(-1)
  const [reservationModalHours, setReservationModalHours] = useState<number[]>(
    []
  )
  const [reservationModalMinutes, setReservationModalMinutes] =
    useState<number[]>(fiveIntervalMinutes)
  const [nextReservation, setNextReservation] = useState<Date | null>(null)
  const [checkInError, setCheckInError] = useState<boolean>(false)
  const [hasAnotherSchedule, setHasAnotherSchedule] = useState<boolean>(false)
  const [hasAnotherCheckIn, setHasAnotherCheckIn] = useState<boolean>(false)
  const [checkInErrorMessage, setCheckInErrorMessage] = useState<string>("")
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [checkInStatus, setCheckInStatus] = useState<boolean>(true)
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)
  const [availableTime, setAvailableTime] = useState<number | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (checkInStatus == false) setDataLoading(false)
  }, [checkInStatus])

  // クエリパラメータから各種stateを設定する
  const setCheckInStateFromQueryString = async (
    companyRelations: CompanyRelationsType
  ) => {
    let floor_id = 0
    const isFloorFound = companyRelations.branches.some((branch) => {
      return branch.floors.some((floor) => {
        if (floor.id === Number(queryParams.get("floor_id"))) {
          floor_id = floor.id
          setFloorId(floor.id)
          setBranchName(branch.branch_name)
          setFloorNumber(floor.floor_number)
          return true // 条件に合致したら、これ以上の繰り返しを停止
        }
        return false // このフロアは条件に合致しない、次のフロアへ
      })
    })

    if (isFloorFound) {
      setCheckInType(queryParams.get("type") as ReservableType)
      setTargetId(Number(queryParams.get("id")))
      setCheckInTarget(queryParams.get("name") as string)
      if (currentUser) {
        if (location.pathname === "/employee/check-in/completed") {
          setCheckInStatus(false)
        } else {
          checkInStatusJudgement(floor_id)
        }
      }
    }
  }

  /** 今その席を自分がチェックインしているか否かを判断する */
  const checkInStatusJudgement = async (floor_id: number) => {
    try {
      const { data, error } = await fetchCheckInStatusRequest({
        reservable_type: checkInType,
        reservable_id: targetId,
        userable_type: "Employee",
      })

      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(
          "チェックイン状態の取得に失敗しました。\n画面を再読み込みしてください"
        )
      }

      if (data && targetId) {
        setAvailableTime(data.available_time)
        if (data.check_in_status) {
          if (floor_id && checkInType && checkInTarget) {
            navigate(
              `/employee/check-out?floor_id=${floor_id}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          }
        } else {
          setCheckInStatus(false)
        }
      }
    } catch (error) {
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  // 終業までチェックインを行う際のデータのやり取り
  const setCheckInEndOfShiftStateFromQueryString = async (
    companyRelations: CompanyRelationsType,
    seatId: number
  ) => {
    const floor_id = companyRelations.default_floor_id
    const isFloorFound = companyRelations.branches.some((branch) => {
      return branch.floors.some((floor) => {
        if (floor.id === floor_id) {
          setFloorId(floor.id)
          setBranchName(branch.branch_name)
          setFloorNumber(floor.floor_number)
          return true // 条件に合致したら、これ以上の繰り返しを停止
        }
        return false // このフロアは条件に合致しない、次のフロアへ
      })
    })

    if (isFloorFound) {
      setCheckInType(queryParams.get("type") as ReservableType)
      setTargetId(Number(queryParams.get("id")))
      setCheckInTarget(queryParams.get("name") as string)
      if (currentUser) {
        if (location.pathname === "/employee/check-in/completed") {
          setCheckInStatus(false)
        } else {
          if (floor_id) {
            const data = await checkInEndOfShiftStatusJudgement(
              floor_id,
              seatId
            )
            return data
          }
        }
      }
    }
  }

  // 終業までチェックインを行う際のデータのやり取りの続き
  const checkInEndOfShiftStatusJudgement = async (
    floor_id: number,
    seatId: number
  ) => {
    try {
      const { data, error } = await fetchCheckInStatusRequest({
        reservable_type: "Seat",
        reservable_id: seatId,
        userable_type: "Employee",
      })

      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(
          "チェックイン状態の取得に失敗しました。\n画面を再読み込みしてください"
        )
      }
      if (data && seatId) {
        setAvailableTime(data.available_time)
        if (data.check_in_status) {
          if (floor_id && checkInType && checkInTarget) {
            navigate(
              `/employee/check-out?floor_id=${floor_id}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          }
        } else {
          setCheckInStatus(false)
        }
      }
      return data
    } catch (error) {
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  // チェックインボタンを押した時の挙動
  const checkIn = async () => {
    try {
      const { data, error } = await checkInRequest({
        reservable_id: targetId,
        reservable_type: checkInType,
      })

      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(translations.NoArea)
      }

      if (data) {
        if (data.check_in_completed) {
          navigate(
            `/employee/check-in/completed?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
          )
        } else {
          if (data.already_reserved) {
            navigate(
              `/employee/check-in/already-reserved?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          } else if (data.is_reservation_seat) {
            navigate(
              `/employee/check-in/reservation-seat?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          } else if (data.is_checked_in_now === "this_seat") {
            navigate(
              `/employee/check-in/already-checked-in?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
            )
          } else {
            handleReservationTimePullDownSet(data.next_reservation_time)
            setNotReserved(true)
            setNotReservedModalMessage(
              notReservedModalText(
                data.has_another_schedule,
                data.is_checked_in_now === "another_seat"
              )
            )
            setHasAnotherSchedule(data.has_another_schedule)
            setHasAnotherCheckIn(data.is_checked_in_now === "another_seat")
          }
        }
      }
    } catch (error) {
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  // 座席に予約が入っていない場合のモーダルのテキストを設定する
  const notReservedModalText = (
    hadAnotherSchedule: boolean,
    isCheckInNow: boolean
  ) => {
    if (hadAnotherSchedule) {
      return translations.anotherReserved
    } else if (isCheckInNow) {
      return translations.currentlyCheckedAnotherDesk
    } else {
      return translations.wouldYouCheckIn
    }
  }

  // 現在時刻を5分単位で切り上げる
  const roundUpCurrentDate = () => roundUpMinutes(new Date(), 5)

  // 次の予約を15分単位で切り捨てる
  const getNextReservationEndTime = (nextReservationDate: string | null) => {
    if (!nextReservationDate) return null
    const roundedNextReservationDate = roundDownMinutes(
      new Date(nextReservationDate),
      15
    )
    return new Date(roundedNextReservationDate)
  }

  // 利用可能な終了時間を計算する
  const calculateAvailableEndTime = (
    roundedCurrentDate: Date,
    availableTime: number | null
  ) => {
    if (!availableTime || availableTime <= 0) return null
    return new Date(roundedCurrentDate.getTime() + availableTime * 60000)
  }

  // 最も早い終了時間を取得する
  const getClosestReservationTime = (
    roundedCurrentDate: Date,
    nextReservationEndTime: Date | null,
    availableEndTime: Date | null
  ) => {
    if (nextReservationEndTime && availableEndTime) {
      return nextReservationEndTime.getTime() - roundedCurrentDate.getTime() <
        availableEndTime.getTime() - roundedCurrentDate.getTime()
        ? nextReservationEndTime
        : availableEndTime
    }
    return nextReservationEndTime || availableEndTime || null
  }

  // 終了時間に基づいて選択可能な時間のリストを生成する
  const generateSelectableHours = (
    roundedCurrentDate: Date,
    endTime: Date | null
  ) => {
    let fromNowHours = [...Array(24 - roundedCurrentDate.getHours())].map(
      (_, i) => i + roundedCurrentDate.getHours()
    )

    if (endTime) {
      const endHour = endTime.getHours()
      const endIndex = fromNowHours.indexOf(endHour)
      if (endIndex !== -1) {
        fromNowHours = fromNowHours.slice(0, endIndex + 1)
      }
    }

    return fromNowHours
  }

  // その場でチェックインを行う際の予約時間のプルダウンの内容を設定する
  const handleReservationTimePullDownSet = (
    nextReservationDate: string | null
  ) => {
    const roundedCurrentDate = roundUpCurrentDate()
    const nextReservationEndTime =
      getNextReservationEndTime(nextReservationDate)
    const availableEndTime = calculateAvailableEndTime(
      roundedCurrentDate,
      availableTime
    )
    const closestReservation = getClosestReservationTime(
      roundedCurrentDate,
      nextReservationEndTime,
      availableEndTime
    )
    if (closestReservation) setNextReservation(closestReservation)

    const fromNowHours = generateSelectableHours(
      roundedCurrentDate,
      closestReservation
    )
    setReservationModalHours(fromNowHours)
  }

  // その場で予約を行う際に予約時間をstateに設定する
  const handleReservationHourChange = (e: SelectChangeEvent<number>) => {
    // 予約時間の「時」変更時、選択可能な「分」一覧を更新する
    // ※予約時間や選択肢は5分単位で丸める
    const now = roundUpMinutes(new Date(), 5)

    const isIncludedMinutesChoicesList = (minutes: number[]) => {
      return minutes.find((minute) => minute === reservationMinutes)
    }

    const selectedHour = Number(e.target.value)
    let selectedMinutes = reservationMinutes
    let minutesChoicesList = fiveIntervalMinutes // 選択可能な「分」一覧

    // 現在時と同じ「時」を選択した場合
    if (selectedHour === now.getHours()) {
      // 過去の「分」は選択肢から除外
      const nowMinutesIndex = minutesChoicesList.indexOf(now.getMinutes())
      minutesChoicesList = minutesChoicesList.slice(nowMinutesIndex)

      // 選択中の「分」が選択肢になければ、最初の選択肢を選んだ状態にする
      if (
        selectedMinutes !== -1 &&
        !isIncludedMinutesChoicesList(minutesChoicesList)
      ) {
        selectedMinutes = minutesChoicesList.slice()[0]
      }
    }

    // 次の予約時刻と同じ「時」を選択した場合
    if (nextReservation && selectedHour === nextReservation.getHours()) {
      // 次の予約時刻と被ってる「分」は選択肢から除外
      const nextReservationMinutes = roundDownMinutes(
        nextReservation,
        5
      ).getMinutes()
      const nextReservationMinutesIndex = minutesChoicesList.indexOf(
        nextReservationMinutes
      )

      minutesChoicesList = minutesChoicesList.slice(
        0,
        nextReservationMinutesIndex + 1
      )

      // 選択中の「分」が選択肢から外れたら、次の予約の直前の「分」を選んだ状態にする
      if (
        selectedMinutes !== -1 &&
        !isIncludedMinutesChoicesList(minutesChoicesList)
      ) {
        selectedMinutes = minutesChoicesList.slice(-1)[0]
      }
    }
    setReservationHour(selectedHour)
    setReservationMinutes(selectedMinutes)
    setReservationModalMinutes(minutesChoicesList)
  }

  const handleReservationMinutesChange = (e: SelectChangeEvent<number>) => {
    setReservationMinutes(Number(e.target.value))
  }

  // その場で予約・チェックインを実行する
  const handleInstantCheckIn = async () => {
    const today = new Date()
    const reservationTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      reservationHour,
      reservationMinutes
    )
    try {
      const { error } = await instantCheckInRequest({
        reservable_id: targetId,
        reservable_type: checkInType,
        end_time: reservationTime,
      })
      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(translations.CheckInFailed)
      } else {
        navigate(
          `/employee/check-in/completed?floor_id=${floorId}&id=${targetId}&type=${checkInType}&name=${checkInTarget}`
        )
      }
    } catch (error) {
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  const handleInstantCheckInFromHome = async (
    checkInData: CheckInData,
    onSuccess: () => void
  ) => {
    if (checkInData.seatId === null) {
      return
    }
    const today = new Date()
    const reservationTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      reservationHour,
      reservationMinutes
    )

    try {
      const response = await instantCheckInRequest({
        reservable_id: checkInData.seatId,
        reservable_type: "Seat",
        end_time: reservationTime,
      })

      if (response.status === 204) {
        onSuccess()
        setNotReserved(false)
      } else {
        setCheckInError(true)
        setCheckInErrorMessage(translations.CheckInFailed)
      }
    } catch (error) {
      console.error("Check-in error", error)
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  // ホーム画面からモーダル内でチェックインを行うときの挙動
  const checkInFromHome = async (checkInData: { seatId: number }) => {
    try {
      const { data, error } = await checkInRequest({
        reservable_id: checkInData.seatId,
        reservable_type: "Seat",
      })

      if (error) {
        setCheckInError(true)
        setCheckInErrorMessage(translations.NoArea)
      }
      if (data) {
        handleReservationTimePullDownSet(data.next_reservation_time)
        setNotReserved(true)
        setNotReservedModalMessage(
          notReservedModalText(
            data.has_another_schedule,
            data.is_checked_in_now === "another_seat"
          )
        )
        setHasAnotherSchedule(data.has_another_schedule)
        setHasAnotherCheckIn(data.is_checked_in_now === "another_seat")
      }
    } catch (error) {
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  // ホーム画面からモーダル内で終業までチェックインを行うときの挙動
  const checkInEndOfShiftFromHome = async (
    checkInData: CheckInData,
    onSuccess: () => void
  ) => {
    try {
      if (checkInData.seatId !== null) {
        const { data, error } = await checkInEndOfShiftRequest({
          reservable_id: checkInData.seatId,
          reservable_type: "Seat",
        })
        console.log("checkInEndOfShiftFromHomeのデータ", data)

        if (error) {
          setCheckInError(true)
          setCheckInErrorMessage(translations.NoArea)
        }
        if (data) {
          onSuccess()
        }
      }
    } catch (error) {
      setCheckInErrorMessage(translations.CheckInFailed)
    }
  }

  return {
    branchName,
    floorNumber,
    checkInType,
    checkInTarget,
    checkIn,
    notReservedModalMessage,
    notReserved,
    setNotReserved,
    reservationModalHours,
    reservationModalMinutes,
    reservationHour,
    reservationMinutes,
    handleReservationHourChange,
    handleReservationMinutesChange,
    handleInstantCheckIn,
    setCheckInStateFromQueryString,
    setCheckInEndOfShiftStateFromQueryString,
    checkInError,
    checkInErrorMessage,
    setCheckInError,
    hasAnotherSchedule,
    dataLoading,
    setDataLoading,
    hasAnotherCheckIn,
    checkInStatusJudgement,
    checkInFromHome,
    checkInEndOfShiftFromHome,
    handleInstantCheckInFromHome,
  }
}
