import React from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import {
  Grid,
  Typography,
  styled,
  TextField,
  InputLabel,
  Box,
  Button,
  Link,
} from "@mui/material"

import { ConfirmForgotPasswordType } from "../../../../models/public/useAuth/confirmForgotPassword/type"
import { pageType } from "../../../../utils/location"
import { ErrorText } from "../../ErrorText"
import { RequiredFlag } from "../../RequiredFlag"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(53),
  padding: theme.spacing(1.875, 0, 0, 3),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomTypography = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Noto Sans",
})

const InputTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
})

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "14px",
  fontFamily: "Noto Sans",
  padding: theme.spacing(1, 8),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(40),
}))

interface Props {
  control: Control<ConfirmForgotPasswordType>
  handleSubmit: UseFormHandleSubmit<ConfirmForgotPasswordType>
  onSubmit: SubmitHandler<ConfirmForgotPasswordType>
  errors: FieldErrors<ConfirmForgotPasswordType>
  isValid: boolean
  open: boolean
  errorMessages: string[]
}

export const ConfirmForgotPasswordForm: React.FC<Props> = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
  isValid,
  open,
  errorMessages,
}: Props) => {
  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <CustomGridItem>
        <CustomTypography color="inherit" align="center">
          新しいパスワードを入力して
          <br />
          パスワードを変更するボタンを押してください
        </CustomTypography>
      </CustomGridItem>
      <CustomGridItem>
        <CustomTypography color="error" align="left">
          ※8文字以上で設定してください
          <br />
          ※英大文字・英小文字・数字・記号をそれぞれ1文字以上使用してください
          <br />
          ※記号は ! / ; : @ [] - ` {} ~ がご利用いただけます
        </CustomTypography>
      </CustomGridItem>
      <CustomGridItem container spacing={1}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="password">
            <InputTypography color="primary">
              パスワード
              <RequiredFlag />
            </InputTypography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.password}
                helperText={errors.password?.message}
                required
                id="password"
                name="password"
                fullWidth
                variant="outlined"
                type="password"
                sx={{
                  "& input": { padding: "15px 12px" },
                }}
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      <CustomGridItem container spacing={1}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="passwordConfirmation">
            <InputTypography color="primary">
              パスワード確認
              <RequiredFlag />
            </InputTypography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={control}
            name="passwordConfirmation"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.passwordConfirmation}
                helperText={errors.passwordConfirmation?.message}
                required
                id="passwordConfirmation"
                name="passwordConfirmation"
                fullWidth
                variant="outlined"
                type="password"
                sx={{
                  "& input": { padding: "15px 12px" },
                }}
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      {open && (
        <Grid sx={{ padding: "15px 0 0 24px" }}>
          <ErrorMessageContainer textAlign="center">
            {ErrorText(errorMessages)}
          </ErrorMessageContainer>
        </Grid>
      )}
      <Grid sx={{ padding: "15px 0 0 24px" }}>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CustomButton
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            disabled={!isValid}
            color="primary"
          >
            パスワードを変更する
          </CustomButton>
        </Box>
      </Grid>
      <Grid sx={{ padding: "15px 0 0 24px" }}>
        <Link
          component={RouterLink}
          to={`/${pageType}/login`}
          variant="caption"
          fontFamily="Noto Sans"
          color="#43425D"
        >
          ログイン画面に戻る
        </Link>
      </Grid>
    </CustomGridContainer>
  )
}
