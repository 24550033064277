import React, { useEffect } from "react"

import { CompanyTeamForm } from "../../../components/company/teams/CompanyTeamForm"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { useTeam } from "../../../models/company/useTeam"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CompanyTeamCreatePage: React.FC = () => {
  const teamObject = useTeam()
  const { companyRelations } = useCompanyRelations()

  useEffect(() => {
    teamObject.fetchTeamHierarchyList()
  }, [])

  return (
    <ContentTemplate>
      <ContentCompanyPaper>
        <CompanyTeamForm {...teamObject} companyRelations={companyRelations} />
      </ContentCompanyPaper>
    </ContentTemplate>
  )
}
