import React, { useEffect, useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Button, Typography, IconButton, styled } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleRequestMonthType } from "../../../../../models/employee/useSchedule/months/type"
import { RoleContext } from "../../../../../providers/RoleProvider"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleHeaderBar/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleHeaderBar/ja"
import {
  DAY_CALENDAR_PATHNAME,
  WEEK_CALENDAR_PATHNAME,
  MONTH_CALENDAR_PATHNAME,
} from "../../../../../utils/const"
import { generateButtonStyle } from "../../../../../utils/generateButtonStyle"

const CustomTypography = styled(Typography)({
  letterSpacing: "0.6px",
  fontSize: "12px",
  fontFamily: "Noto Sans",
  lineHeight: "17px",
  margin: "0 auto",
})

const CustomBoldTypography = styled(Typography)({
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
})

const defaultButtonStyle = {
  borderRadius: theme.spacing(4),
}

interface Props {
  fetchScheduleMonth: (data: ScheduleRequestMonthType) => Promise<void>
  formatDate: (date: Date) => string
  beginningOfMonth: Date
  setBeginningOfMonth: React.Dispatch<React.SetStateAction<Date>>
  endOfMonth: Date
  setEndOfMonth: React.Dispatch<React.SetStateAction<Date>>
  previousMonthSchedule: (beginningOfMonth: Date) => {
    newBeginningOfMonth: Date
    newEndOfMonth: Date
    newFirstDay: Date
    newLastDay: Date
  }
  nextMonthSchedule: (beginningOfMonth: Date) => {
    newBeginningOfMonth: Date
    newEndOfMonth: Date
    newFirstDay: Date
    newLastDay: Date
  }
  HandleFirstDayOfTargetMonth: (beginningOfMonth: Date) => Date
  HandleLastDayOfTargetMonth: (beginningOfMonth: Date) => Date
  displayOnlyReserved: () => void
  displayAll: () => void
  displayOnlyNonReserved: () => void
  displayReserved: boolean
  displayNonReserved: boolean
}

export const EmployeeScheduleMonthHeader: React.FC<Props> = ({
  fetchScheduleMonth,
  formatDate,
  beginningOfMonth,
  setBeginningOfMonth,
  endOfMonth,
  setEndOfMonth,
  previousMonthSchedule,
  nextMonthSchedule,
  HandleFirstDayOfTargetMonth,
  HandleLastDayOfTargetMonth,
  displayOnlyReserved,
  displayAll,
  displayOnlyNonReserved,
  displayReserved,
  displayNonReserved,
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)
  const year = beginningOfMonth.getFullYear()
  const month = beginningOfMonth.getMonth() + 1
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const { handleRoleChange } = useContext(RoleContext)

  // 前月の予定を取得
  const HandlePreviousMonthSchedule = () => {
    const { newBeginningOfMonth, newEndOfMonth, newFirstDay, newLastDay } =
      previousMonthSchedule(beginningOfMonth)
    fetchScheduleMonth({
      start_date: formatDate(newFirstDay),
      end_date: formatDate(newLastDay),
    })
    setBeginningOfMonth(newBeginningOfMonth)
    setEndOfMonth(newEndOfMonth)
  }

  // 翌月の予定を取得
  const HandleNextMonthSchedule = () => {
    const { newBeginningOfMonth, newEndOfMonth, newFirstDay, newLastDay } =
      nextMonthSchedule(beginningOfMonth)
    fetchScheduleMonth({
      start_date: formatDate(newFirstDay),
      end_date: formatDate(newLastDay),
    })
    setBeginningOfMonth(newBeginningOfMonth)
    setEndOfMonth(newEndOfMonth)
  }

  // 初回、当月の予定を取得
  useEffect(() => {
    const firstDay = HandleFirstDayOfTargetMonth(beginningOfMonth)
    const lastDay = HandleLastDayOfTargetMonth(endOfMonth)
    fetchScheduleMonth({
      start_date: formatDate(firstDay),
      end_date: formatDate(lastDay),
    })
  }, [])

  // ページが遷移、権限変更があれば権限取得
  useEffect(() => {
    handleRoleChange()
  }, [location.pathname])

  return (
    <>
      <Box
        sx={{
          marginBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton onClick={HandlePreviousMonthSchedule}>
            <ChevronLeftIcon color="primary" />
          </IconButton>
          <Box>
            <CustomTypography>{`${year}${translations.Year} ${month}${translations.monthCalendar}`}</CustomTypography>
          </Box>
          <IconButton
            disabled={schedulableMaxDate <= endOfMonth}
            onClick={HandleNextMonthSchedule}
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </Box>
        <Box display="flex" marginLeft="auto">
          <Box display="flex" alignItems="center">
            <Box>
              <CustomBoldTypography color="primary">
                {translations.Schedule} :
              </CustomBoldTypography>
            </Box>
            <Button
              sx={{
                ...generateButtonStyle(
                  !displayReserved && !displayNonReserved,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayAll}
            >
              {translations.All}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(displayReserved, defaultButtonStyle),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayOnlyReserved}
            >
              {translations.Desks}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(displayNonReserved, defaultButtonStyle),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={displayOnlyNonReserved}
            >
              {translations.Events}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" marginLeft="2rem">
            <Box>
              <CustomBoldTypography color="primary">
                {translations.Calendar} :
              </CustomBoldTypography>
            </Box>
            <Button
              sx={{
                ...generateButtonStyle(
                  window.location.pathname === MONTH_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={() =>
                navigate(MONTH_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Month}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(
                  window.location.pathname === WEEK_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={() =>
                navigate(WEEK_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Week}
            </Button>
            <Typography variant="h5" color="#dcdcdc">
              |
            </Typography>
            <Button
              sx={{
                ...generateButtonStyle(
                  window.location.pathname === DAY_CALENDAR_PATHNAME,
                  defaultButtonStyle
                ),
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              onClick={() =>
                navigate(DAY_CALENDAR_PATHNAME, {
                  state: {
                    displayNonReserved: displayNonReserved,
                    displayReserved: displayReserved,
                  },
                })
              }
            >
              {translations.Day}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
