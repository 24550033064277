import { passwordExpireMonthesResponseType } from "../../models/company/usePasswordExpireMonthes/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchPasswordExpireMonthesRequest = async (): Promise<
  ApiResponse<passwordExpireMonthesResponseType>
> => {
  try {
    const data = await api.get("/company/password_expire_monthes")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updatePasswordExpireMonthesRequest = async (
  params: passwordExpireMonthesResponseType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/password_expire_monthes/${params?.id}`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
