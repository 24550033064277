import { api, ApiResponse } from "../config/axiosConfig"

export const fetchIconRequest = async (params: {
  id: number
}): Promise<ApiResponse<string>> => {
  try {
    const data = await api.get(`/public/icons/${params.id}`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const createIconRequest = async (params: {
  icon: string
  id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(`/public/icons/${params.id}`, params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
