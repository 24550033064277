const ja = {
  InviteOthers: "共有スケジュール",
  Attendees: "参加者",
  InviteExternalGuests: "外部ユーザーを追加",
  SpaceBooking: "席予約",
  Location: "拠点",
  Floor: "フロア",
  AddEmployee: "従業員名・メールアドレスを入力",
  AttendanceRequired: "参加者が参加する前提で予定を送付する。",
  Attendanceoptional: "対象者がそれぞれ手動で出社可否を選択する。",
  gest: "(ゲスト)",
}
export default ja
