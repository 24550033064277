import React, { memo } from "react"
import { useNavigate } from "react-router-dom"

import MenuIcon from "@mui/icons-material/Menu"
import {
  Box,
  Typography,
  AppBar,
  styled,
  Modal,
  Grid,
  IconButton,
} from "@mui/material"

import { theme } from "../../../config/theme"
import { HeaderCompanyType } from "../../../models/company/useCompany/type"
import { useOpen } from "../../../utils/isOpen"
import { MobileMenuModal } from "../common/MobileMenuModal"

const CustomAppBar = styled(AppBar)(() => ({
  height: "50px",
  width: "100%",
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
}))

const CustomToolbar = styled(Grid)(() => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}))

const CompanyContent = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "5px",
}))

const EmployeeContent = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "right",
  marginRight: "5px",
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const EmployeeIcon = styled(IconButton)(() => ({
  height: "40px",
  width: "40px",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "5px",
  padding: 0,
}))

interface Props {
  headerCompanyInfo?: HeaderCompanyType
  imageUrl: string
}

// TODO
export const MobileHeader: React.FC<Props> = memo(
  ({ headerCompanyInfo, imageUrl }: Props) => {
    const navigate = useNavigate()
    const mobileMenu = useOpen()

    return (
      <>
        <CustomAppBar color="inherit" elevation={0} position="relative">
          <CustomToolbar container spacing={0.3}>
            <CompanyContent item xs={8} md={9}>
              <IconButton
                onClick={() => {
                  navigate("/mobile/home")
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/logos/logo-icon.svg`}
                  height="30px"
                  width="30px"
                  alt="WORK AGILEロゴ"
                />
              </IconButton>
              <p
                style={{
                  height: "30px",
                  borderRight: "1px solid #e3e3e3",
                  margin: "0 10px",
                }}
              ></p>
              <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                <CustomTypography
                  sx={{
                    fontSize: "12px",
                    variant: "button",
                    color: "inherit",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {headerCompanyInfo?.company.company_name}
                </CustomTypography>
              </Box>
            </CompanyContent>
            <EmployeeContent item xs={3.5} md={2.5}>
              <EmployeeIcon id="mobile_employee_icon">
                {imageUrl === "" ? (
                  <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                    <Typography sx={{ fontSize: "7px", color: "inherit" }}>
                      {headerCompanyInfo?.employee.last_name[0]}
                      {headerCompanyInfo?.employee.first_name[0]}
                    </Typography>
                  </Box>
                ) : (
                  <img
                    src={imageUrl}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </EmployeeIcon>
              <IconButton>
                <MenuIcon onClick={mobileMenu.open} />
              </IconButton>
              <Modal open={mobileMenu.isOpen} onClose={mobileMenu.close}>
                <Box>
                  <MobileMenuModal
                    handleCloseMobileMenu={mobileMenu.close}
                    imageUrl={imageUrl}
                    headerCompanyInfo={headerCompanyInfo}
                  />
                </Box>
              </Modal>
            </EmployeeContent>
          </CustomToolbar>
        </CustomAppBar>
      </>
    )
  }
)
