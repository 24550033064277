import { api, ApiResponse } from "../config/axiosConfig"

export const sendPasswordResetEmail = async (
  email: string
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("company/password_resets", { email })
    return data
  } catch (error: any) {
    return { error: error.response.data.error }
  }
}
