import { Chart, registerables } from "chart.js"

import React, { useEffect, useState } from "react"
import { Line, Bar, Pie } from "react-chartjs-2"
import { Link as RouterLink } from "react-router-dom"

import {
  Box,
  Button,
  Grid,
  styled,
  Select,
  MenuItem,
  Link,
  SelectChangeEvent,
  CircularProgress,
  Typography,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText,
} from "@mui/material"

import { AlertError } from "../../../components/public/AlertError"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { GreenArrowIcon } from "../../../components/public/GreenArrowIcon"
import { theme } from "../../../config/theme"
import { useAmStartTime } from "../../../models/company/useAmStartTime"
import { usePmEndTime } from "../../../models/company/usePmEndTime"
import { useReport } from "../../../models/company/useReport"
import { SearchReportRequestTypeWithBranchId } from "../../../models/company/useReport/type"
import { useWorkHour } from "../../../models/company/useWorkHour"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import {
  CONTENT_PAPER_MIN_HEIGHT,
  REPORT_FLOAT_DIGIT,
} from "../../../utils/const"
import { dateStatus } from "../../../utils/date"
import { roundBySpecificDigit } from "../../../utils/number"

Chart.register(...registerables)

const lineOptions = {
  scales: {
    y: {
      min: 0,
      max: 100,
    },
  },
}

const barOptions = {
  indexAxis: "y" as const,
  scales: {
    x: {
      min: 0,
      max: 100,
    },
    y: {
      ticks: {
        crossAlign: "far" as const,
      },
    },
  },
}

const areaBarGraphOption = {
  indexAxis: "y" as const,
  scales: {
    x1: {
      min: 0,
      max: 100,
      position: "top" as const,
    },
    x2: {
      min: 0,
      max: 100,
      position: "bottom" as const,
    },
    y: {
      ticks: {
        crossAlign: "far" as const,
      },
    },
  },
  maintainAspectRatio: false,
}

const boxStyle = {
  margin: 5,
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
}

const CustomSummaryContainer = styled(Grid)(({ theme }) => ({
  height: "200px",
  width: "200px",
  marginTop: theme.spacing(12),
  marginLeft: theme.spacing(1),
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "204px",
  height: "35px",
  fontSize: theme.spacing(2),
  marginTop: theme.spacing(1),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
  borderRadius: "6px",
}))

const ReportCustomSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "50px",
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: "#f7f7f7",
  color: "#454545",
  borderRadius: "6px",
  ".MuiOutlinedInput-notchedOutline": { border: 0.05 },
}))

// 色コード
const BLUE_CODE = "rgb(75, 192, 192)"
const YELLOW_CODE = "rgb(255, 205, 86)"
const GRAY_CODE = "rgb(201, 203, 207)"

export const CompanyReportPage = () => {
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>([])
  const [selectedBranchNames, setSelectedBranchNames] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)

  const today = new Date()
  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 15
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )

  const twentyEightDaysBeforeEndDate = new Date(
    yesterDay.getFullYear(),
    yesterDay.getMonth(),
    yesterDay.getDate() - 28
  )
  const ninetyDaysBeforeEndDate = new Date(
    yesterDay.getFullYear(),
    yesterDay.getMonth(),
    yesterDay.getDate() - 90
  )
  const oneYearBeforeEndDate = new Date(
    yesterDay.getFullYear(),
    yesterDay.getMonth(),
    yesterDay.getDate() - 365
  )

  const { formatDate } = dateStatus()

  const [startDate, setStartDate] = useState<string>(
    formatDate(defaultStartDate)
  )
  const [endDate] = useState<string>(formatDate(yesterDay)) // endDate(データ取得期間終了日)は常に昨日の日付

  const { companyRelations } = useCompanyRelations()
  const {
    loading,
    reportRelation,
    fetchReportRelations,
    searchReportRelations,
    setLoading,
    downloadEmployeeAttendance,
    downloadSeatOperations,
    downloadMeetingRoomOperations,
  } = useReport()

  const changeBranchId = (e: SelectChangeEvent<unknown>) => {
    const branchIds = e.target.value
    const targetBranchIds =
      typeof branchIds === "string"
        ? branchIds.split(",").map(Number)
        : branchIds

    // 「全ての拠点」を選択した時
    if ((targetBranchIds as number[]).includes(0)) {
      if (selectedBranchIds.length === companyRelations.branches.length) {
        setSelectedBranchIds([])
        setSelectedBranchNames([])
      } else {
        const allBranchIds = companyRelations.branches.map((branch) => {
          return branch.id
        })
        const allBranchNames = companyRelations.branches.map((branch) => {
          return branch.branch_name
        })
        setSelectedBranchIds(allBranchIds)
        setSelectedBranchNames(allBranchNames)
      }
    } else {
      const addedSelectedBranchNames = companyRelations.branches
        .filter((branch) => {
          return (targetBranchIds as number[]).includes(branch.id)
        })
        .map((branch) => {
          return branch.branch_name
        })
      setSelectedBranchIds(targetBranchIds as number[])
      setSelectedBranchNames(addedSelectedBranchNames)
    }
  }

  const searchReportRelationsParams: SearchReportRequestTypeWithBranchId = {
    branch_ids: selectedBranchIds,
    start_date: startDate,
    end_date: endDate,
  }

  const handleSearchTermChange = (e: SelectChangeEvent<unknown>) => {
    switch (e.target.value) {
      case "14_days":
        setStartDate(formatDate(defaultStartDate))
        return
      case "28_days":
        setStartDate(formatDate(twentyEightDaysBeforeEndDate))
        return
      case "90_days":
        setStartDate(formatDate(ninetyDaysBeforeEndDate))
        return
      case "365_days":
        setStartDate(formatDate(oneYearBeforeEndDate))
    }
  }

  const handleSearchFormSubmit = () => {
    searchReportRelations(searchReportRelationsParams)
  }

  const branchNameLabel: string[] = []
  const branchIds: number[] = []
  const targetedAtLabel: string[] = []

  const { amStartTime } = useAmStartTime()
  const { pmEndTime } = usePmEndTime()
  const { workHour, fetchWorkHour } = useWorkHour()

  // 稼働席数
  const operationSheetCount: { branchId: number; count: number }[] = []
  const allSheetCount: { branchId: number; count: number }[] = []
  const operationRateWithBranchId: { branchId: number; rate: number }[] = []
  // 全従業員数
  const allEmployeeCount: number[] = []
  // 出社従業員数
  const attendanceEmployeeCount: number[] = []
  // 出社率
  const attendanceRate: number[] = []
  // 稼働席数の平均値
  const seatAve: number[] = []
  // 会議室の利用状況 [稼働率,空予約率,非稼働率]
  const meetingRoomStatus: number[] = []
  // 就業時間の取得
  let workStartTime, workEndTime
  let WorkHours = 0
  if (amStartTime && pmEndTime) {
    workStartTime = new Date(workHour.am_start_time).getHours()
    workEndTime = new Date(workHour.pm_end_time).getHours()

    // 勤務時間 = 終了時間 - 開始時間
    WorkHours = workEndTime - workStartTime
  }
  // 拠点別 出社率 折れ線グラフ
  const lineGraphData = {
    // x 軸のラベル
    labels: targetedAtLabel,
    datasets: [
      {
        label: "出社率(%)",
        // データの値
        data: attendanceRate,
        // グラフの背景色
        backgroundColor: BLUE_CODE,
        // グラフの枠線の色
        borderColor: BLUE_CODE,
      },
    ],
  }
  // 拠点別 稼働席数の平均値 棒グラフ
  const besideBarGraphData = {
    // y 軸のラベル
    labels: branchNameLabel,
    datasets: [
      {
        label: "稼働席数 / 席数の平均値(%)",
        // データの値
        data: seatAve,
        // グラフの背景色
        backgroundColor: BLUE_CODE,
        // グラフの枠線の色
        borderColor: BLUE_CODE,
      },
    ],
  }
  // 会議室利用状況
  const pieGraphData = {
    labels: ["稼働率(%)", "空予約(%)", "非稼働率(%)"],
    datasets: [
      {
        // データの値
        data: meetingRoomStatus,
        // グラフの背景色
        backgroundColor: [BLUE_CODE, YELLOW_CODE, GRAY_CODE],
      },
    ],
  }

  const areaLabels: string[] = []
  const areaIds: number[] = []
  const areaAllSeatCount: { areaId: number; count: number }[] = []
  const areaOperationSeatCount: { areaId: number; count: number }[] = []
  const areaOperationSeatRateWithAreaId: { areaId: number; rate: number }[] = []
  const areaOperationRate: number[] = []
  // エリア別利用状況
  const areaBarGraphData = {
    // y 軸のラベル
    labels: areaLabels,
    datasets: [
      {
        label: "利用率(%)",
        // データの値
        data: areaOperationRate,
        // グラフの背景色
        backgroundColor: BLUE_CODE,
        // グラフの枠線の色
        borderColor: BLUE_CODE,
      },
    ],
  }

  // 座席が1つでも存在する日
  const seatsExistReports = reportRelation.reports.filter((report) => {
    return report.all_seat_count > 0
  })

  // 全取得データのうち、座席が存在する日付の数(ユニークにする)
  const seatExistsDaysCount = Array.from(
    new Map(
      seatsExistReports.map((report) => [report.targeted_at, report])
    ).values()
  )

  // 出社率の推移を日付順に表示するためにソート(デフォルトではcreated_at昇順、基本日付順に保存されてるはずだけど念の為)
  const sortedReports = reportRelation.reports.sort(
    (previous, next) =>
      new Date(previous.targeted_at).getTime() -
      new Date(next.targeted_at).getTime()
  )

  const reportDataBranchCount = Array.from(
    new Map(sortedReports.map((report) => [report.branch_id, report])).values()
  ).length

  // 送られてきたものからデータ抽出
  sortedReports.map((report) => {
    const targetedAtIndex = targetedAtLabel.findIndex(
      (item) => item == report.targeted_at
    )
    if (targetedAtIndex == -1) {
      targetedAtLabel.push(report.targeted_at)
      allEmployeeCount.push(report.all_employee_count)
      attendanceEmployeeCount.push(report.attendance_employee_count)
    } else {
      allEmployeeCount[targetedAtIndex] =
        allEmployeeCount[targetedAtIndex] + report.all_employee_count
      attendanceEmployeeCount[targetedAtIndex] =
        attendanceEmployeeCount[targetedAtIndex] +
        report.attendance_employee_count
    }
    const branchIdIndex = branchIds.findIndex(
      (item) => item == report.branch_id
    )

    // 全拠点選択時は全拠点分のデータ + 各拠点のデータ、拠点を選択した場合は選択した拠点のデータを表示
    if (reportDataBranchCount === companyRelations.branches.length) {
      const allBranchNameIndex = branchNameLabel.findIndex(
        (item) => item == "全拠点"
      )
      if (allBranchNameIndex === -1) {
        branchNameLabel.unshift("全拠点")
        const allBranchOperationSeatCount = reportRelation.reports.reduce(
          (sum, report) => {
            return sum + report.operation_seat_count
          },
          0
        )
        const allBranchSeatCount = reportRelation.reports.reduce(
          (sum, report) => {
            return sum + report.all_seat_count
          },
          0
        )
        operationSheetCount.unshift({
          branchId: 0,
          count: allBranchOperationSeatCount,
        })
        allSheetCount.unshift({
          branchId: 0,
          count: allBranchSeatCount,
        })
        operationRateWithBranchId.push({
          branchId: 0,
          rate: roundBySpecificDigit(
            (allBranchOperationSeatCount / allBranchSeatCount) * 100,
            REPORT_FLOAT_DIGIT
          ),
        })
      }
    }

    if (branchIdIndex == -1) {
      branchIds.push(report.branch_id)
      branchNameLabel.push(report.branch.branch_name)
      operationSheetCount.push({
        branchId: report.branch_id,
        count: report.operation_seat_count,
      })
      allSheetCount.push({
        branchId: report.branch_id,
        count: report.all_seat_count,
      })
    } else {
      operationSheetCount[branchIdIndex].count =
        operationSheetCount[branchIdIndex].count + report.operation_seat_count
      allSheetCount[branchIdIndex].count =
        allSheetCount[branchIdIndex].count + report.all_seat_count
    }
    operationRateWithBranchId.push({
      branchId: report.branch_id,
      rate: roundBySpecificDigit(
        (report.operation_seat_count / report.all_seat_count) * 100,
        REPORT_FLOAT_DIGIT
      ),
    })
  })

  // 出社率 折れ線グラフ データ作成
  for (let i = 0; i < targetedAtLabel.length; i++) {
    // yyyy-mm-dd hh:mm:ss +0900 → mm/dd
    targetedAtLabel[i] = targetedAtLabel[i].slice(5, 10).replace("-", "/")
    // 出社率の計算
    const roundedAttendanceRate = roundBySpecificDigit(
      (attendanceEmployeeCount[i] / allEmployeeCount[i]) * 100,
      REPORT_FLOAT_DIGIT
    )
    attendanceRate.push(roundedAttendanceRate)
  }

  // 稼働席数の平均値 棒グラフ
  // 稼働席数 / 全座席数(%) の期間内の平均
  for (let i = 0; i < operationSheetCount.length; i++) {
    const branchData = operationRateWithBranchId.filter((data) => {
      return data.branchId === operationSheetCount[i].branchId
    })
    let branchOperationRateSum = 0
    branchData.map((data) => {
      branchOperationRateSum += data.rate
    })
    const operationSeatRate = roundBySpecificDigit(
      branchOperationRateSum / branchData.length,
      REPORT_FLOAT_DIGIT
    )
    seatAve.push(operationSeatRate)
  }

  // 会議室利用状況 円グラフ データ作成
  let all_meeting_room_count = 0
  let checked_in_meeting_room_count = 0
  let reserved_meeting_room_count = 0

  reportRelation.report_meeting_rooms.map((report) => {
    all_meeting_room_count += WorkHours
    if (report.checked_in_meeting_room_count > WorkHours) {
      checked_in_meeting_room_count += WorkHours
    } else {
      checked_in_meeting_room_count += report.checked_in_meeting_room_count
    }

    if (report.reserved_meeting_room_count > WorkHours) {
      reserved_meeting_room_count += WorkHours
    } else {
      reserved_meeting_room_count += report.reserved_meeting_room_count
    }
  })

  const checked = (checked_in_meeting_room_count / all_meeting_room_count) * 100
  const reserved = (reserved_meeting_room_count / all_meeting_room_count) * 100

  const checkedRounded = roundBySpecificDigit(checked, REPORT_FLOAT_DIGIT)
  const reservedRounded = roundBySpecificDigit(reserved, REPORT_FLOAT_DIGIT)

  meetingRoomStatus.push(
    checkedRounded,
    reservedRounded,
    100 - checkedRounded - reservedRounded // 100 - 少数第1位の計算だと手元では誤差を確認できなかった
  )

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchWorkHour()])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  // エリア別利用状況棒グラフ計算処理
  reportRelation.report_areas.map((report) => {
    const areaIndex = areaIds.findIndex((item) => item == report.area_id)
    if (areaIndex == -1) {
      areaIds.push(report.area_id)
      areaLabels.push(
        `${report.floor.branch.branch_name}-${report.floor.floor_name}-${report.area.area_name}`
      )
      areaAllSeatCount.push({
        areaId: report.area_id,
        count: report.all_seat_count,
      })
      areaOperationSeatCount.push({
        areaId: report.area_id,
        count: report.operation_seat_count,
      })
    } else {
      areaAllSeatCount[areaIndex].count =
        areaAllSeatCount[areaIndex].count + report.all_seat_count
      areaOperationSeatCount[areaIndex].count =
        areaOperationSeatCount[areaIndex].count + report.operation_seat_count
    }
    areaOperationSeatRateWithAreaId.push({
      areaId: report.area_id,
      rate: roundBySpecificDigit(
        (report.operation_seat_count / report.all_seat_count) * 100,
        REPORT_FLOAT_DIGIT
      ),
    })
  })

  for (let i = 0; i < areaAllSeatCount.length; i++) {
    const areaData = areaOperationSeatRateWithAreaId.filter((data) => {
      return data.areaId === areaAllSeatCount[i].areaId
    })
    let areaOperationRateSum = 0
    areaData.map((data) => {
      areaOperationRateSum += data.rate
    })
    const operationSeatRate = roundBySpecificDigit(
      areaOperationRateSum / areaData.length,
      REPORT_FLOAT_DIGIT
    )
    areaOperationRate.push(operationSeatRate)
  }

  // 従業員別出社状況CSVダウンロード
  const handleDownloadEmployeeAttendance = () => {
    handleCheckPeriod() &&
      downloadEmployeeAttendance(searchReportRelationsParams)
  }

  // 座席別稼働状況CSVダウンロード
  const handleDownloadSeatOperations = () => {
    handleCheckPeriod() && downloadSeatOperations(searchReportRelationsParams)
  }

  // 会議室別稼働状況CSVダウンロード
  const handleDownloadMeetingRoomOperations = () => {
    handleCheckPeriod() &&
      downloadMeetingRoomOperations(searchReportRelationsParams)
  }

  // CSVダウンロードできる期間を制御
  const handleCheckPeriod = () => {
    return true
    // 90日間と365日間でもダウンロードできるようにするため、強制的にtrueを返すようにする
    // if (
    //   startDate === formatDate(ninetyDaysBeforeEndDate) ||
    //   startDate === formatDate(oneYearBeforeEndDate)
    // ) {
    //   setErrorMessage(
    //     "直近14日間、もしくは直近28日間までの期間を選択してください"
    //   )
    //   setErrorMessageOpen(true)
    //   return false
    // } else {
    //   setErrorMessage("")
    //   setErrorMessageOpen(false)
    //   return true
    // }
  }

  // 期間のプルダンの初期値に合わせて開始日と終了日を指定
  useEffect(() => {
    if (companyRelations.id > 0) {
      const allBranchIds = companyRelations.branches.map((branch) => {
        return branch.id
      })
      const allBranchNames = companyRelations.branches.map((branch) => {
        return branch.branch_name
      })
      setSelectedBranchIds(allBranchIds)
      setSelectedBranchNames(allBranchNames)
      fetchReportRelations({
        start_date: formatDate(defaultStartDate),
        end_date: formatDate(yesterDay),
      }).then(() => {
        setLoading(false)
      })
    }
  }, [companyRelations])

  return (
    <>
      <ContentCompanyPaper>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              height: CONTENT_PAPER_MIN_HEIGHT, // 高さ指定しないと中央にローディングアイコンが配置できない
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={64} />
          </Box>
        ) : (
          <Box sx={{ display: "flex", height: "100%" }}>
            <Grid container spacing={3.75}>
              <Grid container>
                <Grid sx={{ ...boxStyle }} item xs={5}>
                  <Typography color="primary" gutterBottom fontWeight="bold">
                    出社率の推移
                  </Typography>
                  <Line data={lineGraphData} options={lineOptions} />
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Link
                        component={RouterLink}
                        to={`/company/reports/attendances`}
                        variant="caption"
                        color="#43425D"
                      >
                        詳細ページ
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "6px" }}
                        onClick={() => {
                          handleDownloadEmployeeAttendance()
                          const alertContent =
                            "CSVの生成が開始されました。\n完了次第ログイン中のメールアドレスに通知しますので少々お待ちください."
                          alert(alertContent)
                        }}
                      >
                        CSVダウンロード
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ ...boxStyle }} item xs={5}>
                  <Typography color="primary" gutterBottom fontWeight="bold">
                    拠点別 稼働席数/席数
                  </Typography>
                  <Bar options={barOptions} data={besideBarGraphData} />
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Link
                        component={RouterLink}
                        to={`/company/reports/operations`}
                        variant="caption"
                        color="#43425D"
                      >
                        詳細ページ
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "6px" }}
                        onClick={() => {
                          handleDownloadSeatOperations()
                          const alertContent =
                            "CSVの生成が開始されました。\n完了次第ログイン中のメールアドレスに通知しますので少々お待ちください."
                          alert(alertContent)
                        }}
                      >
                        CSVダウンロード
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ ...boxStyle }} item xs={5}>
                  <Typography color="primary" gutterBottom fontWeight="bold">
                    会議室稼働率
                  </Typography>
                  <Pie data={pieGraphData} />
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Link
                        component={RouterLink}
                        to={`/company/reports/meeting_rooms`}
                        variant="caption"
                        color="#43425D"
                      >
                        詳細ページ
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "6px" }}
                        onClick={() => {
                          handleDownloadMeetingRoomOperations()
                          const alertContent =
                            "CSVの生成が開始されました。\n完了次第ログイン中のメールアドレスに通知しますので少々お待ちください."
                          alert(alertContent)
                        }}
                      >
                        CSVダウンロード
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ ...boxStyle }} item xs={5}>
                  <Typography color="primary" gutterBottom fontWeight="bold">
                    エリア別利用状況
                  </Typography>
                  <Box
                    sx={{
                      height: theme.spacing(54.5),
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        // Math.max を用いて、最小の高さを設定
                        height: `${Math.max(
                          areaBarGraphData.labels.length * 35,
                          150
                        )}px`,
                      }}
                    >
                      <Bar
                        data={areaBarGraphData}
                        options={areaBarGraphOption}
                      />
                    </Box>
                  </Box>
                  <Link
                    component={RouterLink}
                    to={`/company/reports/areas`}
                    variant="caption"
                    color="#43425D"
                  >
                    詳細ページ
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <CustomSummaryContainer container item xs={2} spacing={3}>
              <Box
                sx={{ fontSize: "1rem", fontWeight: "bold", color: "#22BA9D" }}
              >
                拠点
              </Box>
              <ReportCustomSelect
                value={selectedBranchIds}
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  color: "#454545",
                }}
                multiple
                IconComponent={GreenArrowIcon}
                onChange={(e) => changeBranchId(e)}
                input={<OutlinedInput label="拠点" />}
                renderValue={() => selectedBranchNames.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem key={0} value={0}>
                  <Checkbox
                    checked={
                      selectedBranchIds.length ===
                      companyRelations.branches.length
                    }
                  />
                  <ListItemText primary={"全ての拠点"} />
                </MenuItem>
                {companyRelations.branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    <Checkbox
                      checked={
                        selectedBranchIds.indexOf(Number(branch.id)) > -1
                      }
                    />
                    <ListItemText primary={branch.branch_name} />
                  </MenuItem>
                ))}
              </ReportCustomSelect>
              {companyRelations.branches.length > 0 &&
                selectedBranchIds.length === 0 && (
                  <FormHelperText
                    error
                    sx={{
                      marginBottom: "0.5rem",
                    }}
                  >
                    拠点を一つ以上選択してください
                  </FormHelperText>
                )}
              <Box
                sx={{ fontSize: "1rem", fontWeight: "bold", color: "#22BA9D" }}
              >
                期間
              </Box>
              <ReportCustomSelect
                defaultValue={"14_days"}
                sx={{
                  background: "rgba(112,112,112,0.05)",
                  color: "#454545",
                }}
                IconComponent={GreenArrowIcon}
                onChange={handleSearchTermChange}
              >
                <MenuItem value={"14_days"}>直近14日間</MenuItem>
                <MenuItem value={"28_days"}>直近28日間</MenuItem>
                <MenuItem value={"90_days"}>直近90日間</MenuItem>
                <MenuItem value={"365_days"}>直近365日間</MenuItem>
              </ReportCustomSelect>
              <CustomButton
                variant="outlined"
                onClick={handleSearchFormSubmit}
                disabled={selectedBranchIds.length === 0}
              >
                検索
              </CustomButton>
            </CustomSummaryContainer>
          </Box>
        )}
      </ContentCompanyPaper>
      <AlertError
        isMessageOpen={errorMessageOpen}
        alertMessage={errorMessage}
        closeMessage={() => setErrorMessageOpen(false)}
      />
    </>
  )
}
