import { yupResolver } from "@hookform/resolvers/yup"
// import Cookies from "js-cookie"
import * as Yup from "yup"

import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { confirmRoleRequest } from "../../../api/signage/authRequest"
import { EMAIL_REGEXP } from "../../../utils/regular"
import { AuthorizeAdministratorRequestType } from "./type"

export const useSignageAuthAdmin = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const basicSchema = Yup.object().shape({
    email: Yup.string()
      .required("必須になります")
      .matches(EMAIL_REGEXP, "メールアドレスの形式で入力してください"),
  })

  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(basicSchema),
  })

  const handleAuthorizeAdministrator = async (
    params: AuthorizeAdministratorRequestType
  ) => {
    setLoading(true)
    try {
      const response = await confirmRoleRequest({
        email: params.email,
      })
      if (response.status === 200) {
        setIsAuthenticated(true)
      } else if (response.error) {
        setErrorMessages(response.error)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    handleAuthorizeAdministrator,
    errorMessages,
    isAuthenticated,
    loading,
    form,
    setErrorMessages,
  }
}
