import { useState } from "react"

import { fetchBillingsRequest } from "../../../api/company/billingRequest"
import { Billing, Company } from "./type"

const initCompany = {
  company_name: "",
  company_prefecture_id: 0,
  company_postal_code: "",
  company_city: "",
  company_house_number: "",
  company_building_name: "",
}

export const useBilling = () => {
  const [billings, setBillings] = useState<Billing[]>([])
  const [company, setCompany] = useState<Company>(initCompany)

  const fetchBillings = async () => {
    try {
      const { data } = await fetchBillingsRequest()
      if (data) {
        setBillings(data.billings)
        setCompany(data.company)
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    fetchBillings,
    billings,
    company,
  }
}
