const en = {
  InviteExternalGuests: "Invite external guests",
  LastName: "Last name",
  FirstName: "First name",
  CompanyName: "Company name (optional)",
  Email: "E-mail",
  EmailRequired: "E-mail(required)",
  Cancel: "Cancel",
  Invite: "Invite",
}
export default en
