import React, { useEffect, useState } from "react"
import Moveable from "react-moveable"

import { Box } from "@mui/material"

import { SidebarLayoutComponent } from "../../../../models/company/useLayout"
import { SeatType, AreaType } from "../../../../models/company/useLayout/type"
import {
  freeSeatStyle,
  reservedSeatStyle,
  unavailableStyle,
} from "../../../../styles/seatStyle"
import { SeatButton } from "./../../../public/SeatButton"

interface Props {
  seat: SeatType
  area?: AreaType
  areaToSeats?: SeatType[]
  isSeatMoveable: boolean
  handleSeatCoordinate: (x: number, y: number, targetSeat: SeatType) => void
  sidebarLayoutComponent: SidebarLayoutComponent
  handleAddAreaSeat: (seat: SeatType) => void
  handleDeleteAreaSeat: (seat: SeatType) => void
}

/*
  レイアウト画像の登録フォームで席のアイコンが表示される
*/
export const CompanyLayoutSeat: React.FC<Props> = ({
  seat,
  area,
  areaToSeats,
  isSeatMoveable,
  handleSeatCoordinate,
  sidebarLayoutComponent,
  handleAddAreaSeat,
  handleDeleteAreaSeat,
}: Props) => {
  const layoutImage = document.getElementById(
    "layout-image"
  ) as HTMLImageElement
  const layoutImageWidth = layoutImage?.width
  const layoutImageHeight = layoutImage?.height
  const seatId = seat.id
  const seatType = seat.seat_type
  const seatName = seat.seat_name
  const throttles = { drag: 1, resize: 10, rotate: 90 }
  const bounds = {
    left: 0,
    top: 0,
    right: layoutImageWidth,
    bottom: layoutImageHeight,
  }
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [seatTarget, setSeatTarget] = useState<HTMLElement | null>()
  const [seatStyle, setSeatStyle] = useState<React.CSSProperties | undefined>(
    undefined
  )

  const [isSelectedSeat, setIsSelectedSeat] = useState<boolean>(false)
  // 追加削除ボタンの初期値をエリア追加済みか否かで変える
  const [isDeletedSeat, setIsDeletedSeat] = useState<boolean>(
    areaToSeats ? true : false
  )

  useEffect(() => {
    if (areaToSeats) {
      // 最新の選択エリアのみを更新する
      const isCurrentSeatSelected = areaToSeats.some((areaToSeat) => {
        return areaToSeat.area_id === seat.area_id && areaToSeat.id === seat.id
      })
      setIsSelectedSeat(isCurrentSeatSelected)
    } else {
      setIsSelectedSeat(false)
    }
  }, [areaToSeats, seat])

  useEffect(() => {
    setSeatTarget(document.getElementById(String(seatId)))
  }, [seatId])

  // 指定席・自由席・利用不可
  useEffect(() => {
    setSeatStyle({
      ...seatStyle,
      ...seatTypeStyle(),
    } as React.CSSProperties)
  }, [seat.seat_type])

  // 初期表示
  useEffect(() => {
    setSeatStyle(generateStyleObject() as React.CSSProperties)
  }, [])

  const onMouseLeave = () => {
    return setIsHovered(false)
  }
  const onMouseOver = () => {
    return setIsHovered(true)
  }

  // 指定席・自由席・利用不可席のスタイルの初期化
  const generateStyleObject = () => {
    switch (seatType) {
      case 0:
        return {
          ...reservedSeatStyle,
          top: seat.y,
          left: seat.x,
        }
      case 1:
        return {
          ...freeSeatStyle,
          top: seat.y,
          left: seat.x,
        }
      case 2: {
        return {
          ...unavailableStyle,
          top: seat.y,
          left: seat.x,
        }
      }
      default:
        return {
          ...freeSeatStyle,
          top: seat.y,
          left: seat.x,
        }
    }
  }

  // 指定席・自由席・利用不可席のスタイルの変更
  const seatTypeStyle = () => {
    switch (seatType) {
      case 0:
        return {
          ...reservedSeatStyle,
        }
      case 1:
        return {
          ...freeSeatStyle,
        }
      case 2: {
        return {
          ...unavailableStyle,
        }
      }
      default:
        return {
          ...freeSeatStyle,
        }
    }
  }

  return (
    <React.Fragment>
      <Box
        id={String(seatId)}
        className={"seat"}
        sx={{
          ...seatStyle,
          position: "absolute",
          zIndex: isHovered ? 1000 : 1, // ホバー時に z-index を高く設定
        }}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {seatName}
          {isHovered &&
            sidebarLayoutComponent === "areaForm" &&
            (area == null || (area != null && isSelectedSeat)) && (
              <div
                style={{
                  position: "absolute",
                  width: "auto",
                  top: "50%",
                  left: "calc(100% + 5px)",
                  transform: "translateY(-50%)",
                  backgroundColor: "#808a9f",
                  color: "white",
                  padding: "0.5em 0.5em",
                  borderRadius: "5px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!isDeletedSeat ? (
                  <SeatButton
                    handleClick={() => {
                      setIsDeletedSeat(true)
                      handleAddAreaSeat(seat)
                    }}
                    label="追加"
                    variant="alternate"
                  />
                ) : (
                  <SeatButton
                    handleClick={() => {
                      setIsDeletedSeat(false)
                      handleDeleteAreaSeat(seat)
                    }}
                    label="削除"
                    variant="default"
                  />
                )}
              </div>
            )}
        </div>
      </Box>
      {isSeatMoveable ? (
        <Moveable
          target={seatTarget}
          draggable={true}
          snappable={true}
          throttleDrag={throttles.drag}
          throttleRotate={throttles.rotate}
          bounds={bounds}
          edge={false}
          zoom={1}
          origin={false}
          padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
          onDrag={({ target, transform }) => {
            target.style.transform = transform
          }}
          onDragEnd={() => {
            const targetElement = document.getElementById(
              "layout-image"
            ) as HTMLImageElement
            const clientRect = targetElement.getBoundingClientRect() as DOMRect
            const positionX = clientRect.left + window.pageXOffset
            const positionY = clientRect.top + window.pageYOffset

            const seatElement = document.getElementById(
              String(seatId)
            ) as HTMLImageElement

            const seatRect = seatElement.getBoundingClientRect() as DOMRect

            const x = seatRect.x - positionX
            const y = seatRect.y - positionY

            const transformComponent = document.getElementsByClassName(
              "react-transform-component"
            )

            const transform = transformComponent[0] as HTMLElement
            const transformStyle = transform.style.transform.split("scale")[1]
            const scale = transformStyle.replace("(", "").replace(")", "")

            handleSeatCoordinate(
              x / parseFloat(scale),
              y / parseFloat(scale),
              seat
            )
          }}
        />
      ) : null}
    </React.Fragment>
  )
}
