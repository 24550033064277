import { AxiosResponse } from "axios"

import {
  ReportType,
  ReportMeetingRoomType,
  ReportAttendanceType,
  ReportSeatOperationType,
  SearchReportAttendanceRequestType,
  SearchReportRequestTypeWithBranchId,
  SearchReportRequestType,
  SearchReportMeetingRoomRequestType,
  ReportSeatOperationRequestType,
  ReportMeetingRoomRequestType,
  ReportAreaRequestType,
  ReportAreaType,
  ReportSeatHeatmapType,
  ReportSeatHeatmapRequestType,
} from "../../models/company/useReport/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const reportsRequest = async (
  params: SearchReportRequestType
): Promise<ApiResponse<ReportType>> => {
  try {
    const data = await api.get(`/company/reports`, { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const searchReportRequest = async (
  params: SearchReportRequestTypeWithBranchId
): Promise<ApiResponse<ReportType>> => {
  try {
    const data = await api.get(`/company/reports/search`, { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const downloadEmployeeAttendanceRequest = async (
  params: SearchReportRequestTypeWithBranchId
): Promise<AxiosResponse> => {
  const data = await api.get("/company/reports/employee_attendance_download", {
    responseType: "blob",
    params: params,
  })
  return data
}

export const downloadSeatOperationsRequest = async (
  params: SearchReportRequestTypeWithBranchId
): Promise<AxiosResponse> => {
  const data = await api.get("/company/reports/seat_operations_download", {
    responseType: "blob",
    params: params,
  })
  return data
}

export const downloadMeetingRoomOperationsRequest = async (
  params: SearchReportRequestTypeWithBranchId
): Promise<AxiosResponse> => {
  const data = await api.get(
    "/company/reports/meeting_room_operations_download",
    {
      responseType: "blob",
      params: params,
    }
  )
  return data
}

export const reportMeetingRoomsRequest = async (
  params: ReportMeetingRoomRequestType
): Promise<ApiResponse<ReportMeetingRoomType>> => {
  try {
    const data = await api.get(`/company/report_meeting_rooms`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const searchReportMeetingRoomRequest = async (
  params: SearchReportMeetingRoomRequestType
): Promise<ApiResponse<ReportMeetingRoomType>> => {
  try {
    const data = await api.get(`/company/report_meeting_rooms/search`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const reportAttendancesRequest = async (params: {
  start_date: string
  end_date: string
}): Promise<ApiResponse<ReportAttendanceType>> => {
  try {
    const data = await api.get(`/company/report_attendances`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const searchReportAttendancesRequest = async (
  params: SearchReportAttendanceRequestType
): Promise<ApiResponse<ReportAttendanceType>> => {
  try {
    const data = await api.get(`/company/report_attendances/search`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const reportSeatOperationRequest = async (
  params: ReportSeatOperationRequestType
): Promise<ApiResponse<ReportSeatOperationType>> => {
  try {
    const data = await api.get(`/company/report_seat_operations`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const reportAreaRequest = async (
  params: ReportAreaRequestType
): Promise<ApiResponse<ReportAreaType>> => {
  try {
    const data = await api.get(`/company/report_areas`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const searchReportAreaRequest = async (
  params: ReportAreaRequestType
): Promise<ApiResponse<ReportAreaType>> => {
  try {
    const data = await api.get(`/company/report_areas/search`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const reportSeatHeatmapRequest = async (
  params: ReportSeatHeatmapRequestType
): Promise<ApiResponse<ReportSeatHeatmapType>> => {
  try {
    const data = await api.get(`/company/report_seats_operating_rate`, {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
