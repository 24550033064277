import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Grid } from "@mui/material"

import { CheckInLoginForm } from "../../../components/mobile/checkIn/CheckInLoginForm"
import { Loading } from "../../../components/public/Loading"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useCheckInLogin } from "../../../models/mobile/useCheckInAuth/login"

export const MobileCheckInLogin = () => {
  const authObject = useCheckInLogin()
  const { setLanguage } = useLanguage()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const floorId = queryParams.get("floor_id") || ""
  const id = queryParams.get("id") || ""
  const type = queryParams.get("type") || ""
  const name = queryParams.get("name") || ""

  const navigate = useNavigate()

  const handleLoginSuccess = () => {
    // ログイン前のクエリパラメータをlocalStorageに保存しnavigate時に渡す
    localStorage.setItem("floorId", floorId)
    localStorage.setItem("id", id)
    localStorage.setItem("type", type)
    localStorage.setItem("name", name)
    navigate("/employee/federated-sign-in", {
      state: { floorId, id, type, name },
    })
  }

  return (
    <Grid container justifyContent="center">
      {authObject.loading && (
        <Loading type="content" loading={authObject.loading} />
      )}
      <Grid item xs={10}>
        <CheckInLoginForm
          {...authObject}
          setLanguage={setLanguage}
          onSuccess={handleLoginSuccess}
        />
      </Grid>
    </Grid>
  )
}
