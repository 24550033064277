import React, { Suspense } from "react"

import { CircularProgress } from "@mui/material"

const MobileCheckInOutContent = React.lazy(() =>
  import("../../home/main/CheckInOutFromHome").then(
    ({ CheckInOutFromHome }) => ({
      default: CheckInOutFromHome,
    })
  )
)

const MobileScheduleContent = React.lazy(() =>
  import("../MobileCalendarDay").then(({ MobileScheduleDay }) => ({
    default: MobileScheduleDay,
  }))
)
export const MobileDayCalendar = () => {
  return (
    <>
      <Suspense fallback={<CircularProgress />}>
        <MobileCheckInOutContent />
      </Suspense>

      <Suspense fallback={<CircularProgress />}>
        <MobileScheduleContent />
      </Suspense>
    </>
  )
}
