import React from "react"

import { Box, Typography, styled } from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleType } from "../../../../../models/employee/useSchedule/days/type"
import { FetchScheduleShowFunction } from "../../../../../models/employee/useSchedule/show/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleMain/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleMain/ja"
import { formatDateForSafari } from "../../../../../utils/dateTimeFormat"
import {
  roundDownMinutesDate,
  differenceFromStartTimeToEndTime,
  findHourIndex,
  scheduleBarWidth,
  scheduleBarLeftPosition,
  processMultiDaySchedule,
  isSameDay,
  isEndTimeAtMidnight,
} from "../../../../../utils/schedules"

const defaultWidth = 130
const startPosition = 15

const colorChoice = (schedule_status: string): string => {
  if (schedule_status === "remote") {
    return "#C1C1C1"
  } else if (schedule_status === "going_out") {
    return "#EFC34B"
  } else if (schedule_status === "holiday") {
    return "#4F9BD0"
  } else if (schedule_status === "trip") {
    return "#D972A5"
  } else {
    return "#4EC7B0"
  }
}

const CustomScheduleBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  color: "white",
  height: theme.spacing(4.5),
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(0.75),
  paddingLeft: theme.spacing(1),
  zIndex: "1001",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
}))

interface Props {
  schedule: ScheduleType
  displayReserved: boolean
  displayNonReserved: boolean
  fetchScheduleShowData: FetchScheduleShowFunction
  employee_id: number
  schedule_date: string
  open: () => void
}

export const EmployeeStatusScheduleDay: React.FC<Props> = ({
  schedule,
  displayReserved,
  displayNonReserved,
  fetchScheduleShowData,
  schedule_date,
  employee_id,
  open,
}: Props) => {
  const color = colorChoice(schedule.schedule_status)

  const handleScheduleShowDataClick = () => {
    open()
    fetchShowData([schedule.schedule_information.id], [schedule.id], [schedule])
  }

  const fetchShowData = async (
    scheduleInformationIds: number[],
    scheduleIds: number[],
    schedules: ScheduleType[]
  ) => {
    await fetchScheduleShowData(
      scheduleInformationIds,
      new Date(schedule_date),
      scheduleIds,
      schedules,
      employee_id
    )
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const setScheduleText = (
    schedule_status: string,
    schedule_title: string
  ): string => {
    if (schedule_status === "remote") {
      return translations.WorkFromHome
    } else if (schedule_status === "going_out") {
      return translations.OutOfOffice
    } else if (schedule_status === "trip") {
      return translations.BusinessTrip
    } else if (schedule_status === "holiday") {
      return translations.Vacation
    } else {
      return schedule_title
    }
  }

  // 複数日にまたがる予定の処理
  const processedSchedules = processMultiDaySchedule(schedule)

  return (
    <>
      {processedSchedules.map((processedSchedule, index) => {
        const startTimeString = processedSchedule.startTime.toISOString()
        const endTimeString = processedSchedule.endTime.toISOString()

        // formatDateForSafari 関数には文字列を渡す
        const startTime = new Date(formatDateForSafari(startTimeString))
        const endTime = new Date(formatDateForSafari(endTimeString))

        // scheduleDate と同じ日のスケジュールのみを表示
        const currentPageDate = new Date(schedule_date)
        if (!isSameDay(startTime, currentPageDate)) {
          return null // 同じ日ではない場合は表示しない
        }

        // 日を跨ぐ予定の場合、最終日以外は23:59:59をendtimeとしているので、roundDownMinutesDateを適用しない
        const diffHours = differenceFromStartTimeToEndTime(
          roundDownMinutesDate(startTime).getTime(),
          isEndTimeAtMidnight(endTime)
            ? endTime.getTime()
            : roundDownMinutesDate(endTime).getTime()
        )
        const hourIndex = findHourIndex(startTime)
        const width = scheduleBarWidth(diffHours, defaultWidth)
        const left = scheduleBarLeftPosition(
          roundDownMinutesDate(startTime).getMinutes(),
          hourIndex,
          startPosition,
          defaultWidth
        )

        return (
          <>
            {!displayReserved && schedule.reservable_type !== "Seat" && (
              <CustomScheduleBox
                key={index}
                sx={{
                  width: `${width}px`,
                  left: `${left}px`,
                  backgroundColor: color,
                }}
                onClick={handleScheduleShowDataClick}
              >
                <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                  <Typography>
                    {setScheduleText(
                      schedule.schedule_status,
                      schedule.schedule_title
                    )}
                  </Typography>
                </Box>
              </CustomScheduleBox>
            )}
            {!displayNonReserved && schedule.reservable_type === "Seat" && (
              <>
                <CustomScheduleBox
                  key={index}
                  sx={{
                    width: `${width}px`,
                    left: `${left}px`,
                    backgroundColor: color,
                  }}
                  onClick={handleScheduleShowDataClick}
                >
                  <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                    <Typography
                      sx={{
                        fontSize: "8px",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      {schedule.reservable.floor.branch.branch_name}
                    </Typography>
                  </Box>
                  <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                    <Typography
                      ml="0.5rem"
                      sx={{
                        fontSize: "8px",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      {schedule.reservable.floor.floor_number}
                    </Typography>
                  </Box>
                  <Box sx={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
                    <Typography
                      ml="0.5rem"
                      sx={{
                        fontSize: "8px",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      {schedule.reservable.seat_name}
                    </Typography>
                  </Box>
                  <Box ml="auto" mr="0.5rem" display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/seat.svg`}
                      style={{ width: "12px", height: "12px" }}
                    />
                  </Box>
                </CustomScheduleBox>
              </>
            )}
          </>
        )
      })}
    </>
  )
}
