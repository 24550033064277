import { CognitoUser } from "amazon-cognito-identity-js"
import { Auth, Amplify } from "aws-amplify"

import { useCallback, useState } from "react"

import { createAuthRequest } from "../../../api/company/authRequest"
import { inviteUsersRequest } from "../../../api/company/inviteUsersRequest"
import awsmobile from "../../../aws-exports"

export interface UserAttributes {
  email: string
  "custom:receiveNotification": string
  "custom:isAdmin": string
  sub: string
}

export interface CustomCognitoUser extends CognitoUser {
  attributes: UserAttributes
}

// TODO フロントから直接Cognitoにユーザーを作成する場合に必要
// import AWS from "aws-sdk"
// import { myCredentials } from "../../../config/aws-sdk-config"
// const cognito = new AWS.CognitoIdentityServiceProvider({
//   apiVersion: "2016-04-18",
//   region: awsmobile.aws_cognito_region,
//   credentials: myCredentials,
// })

export const useAuth = () => {
  Amplify.configure(awsmobile)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<CustomCognitoUser | undefined>(
    undefined
  )
  // ユーザーのログイン状態をチェックする
  const authenticate = useCallback(async () => {
    try {
      const response = await Auth.currentSession()
      if (response) {
        setIsAuthenticated(true)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  // 現在ログイン中のユーザーを取得する
  const getCurrentUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(undefined)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut().then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  // TODO: 従業員新規登録画面からユーザーを作る場合に、reactから直接Cognitoと通信する関数を用意する
  // const createUser = async (email: string) => {
  //   // const cognitoParams = {
  //   //   UserPoolId: awsmobile.aws_user_pools_id,
  //   //   Username: email,
  //   //   UserAttributes: [
  //   //     {
  //   //       Name: "email",
  //   //       Value: email,
  //   //     },
  //   //     {
  //   //       Name: "email_verified",
  //   //       Value: "true",
  //   //     },
  //   //   ],
  //   //   TemporaryPassword: Math.random().toString(36),
  //   // }

  //   // const response = await cognito.adminCreateUser(cognitoParams).promise()
  // }

  // 複数人に認証メールを送信する場合処理が長くなるので、Railsにリクエストを送りSidekiq経由でCognitoにユーザーを作成する。
  const createUsers = async (emails: string[]) => {
    return await createAuthRequest({
      emails: emails,
    })
  }

  const inviteUsers = async (emails: string[], send_status: boolean[]) => {
    return await inviteUsersRequest({
      emails: emails,
      send_status: send_status,
    })
  }

  return {
    isAuthenticated,
    setIsAuthenticated,
    currentUser,
    setCurrentUser,
    authenticate,
    getCurrentUser,
    signOut,
    createUsers,
    inviteUsers,
  }
}
