import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useEffect, useRef } from "react"

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import { Box, Typography, Button, Popover, styled } from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  Areas,
  DragEmployee,
  MeetingRooms,
  Seats,
} from "../../../../../models/employee/useSchedule/useLayout/type"
import { WhoIsWheres } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"
import { AvailableSeat } from "../../../../public/AvailableSeat"
import { NotCheckInSeat } from "../../../../public/NotCheckInSeat"
import { UnavailableSeat } from "../../../../public/UnavailableSeat"
import { EmployeeCalendarLayoutMeetingRoom } from "../EmployeeCalendarLayoutMeetingRoom"
import { EmployeeCalendarLayoutSeat } from "../EmployeeCalendarLayoutSeat"
import { EmployeeCalendarLayoutArea } from "../EmployeeCalendarlayoutArea"

const ZoomButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
}))

interface Props {
  layoutImg: string
  layoutX: number
  layoutY: number
  layoutScale: number
  seats: Seats
  meetingRooms: MeetingRooms
  areas: Areas
  whoIsWheres: WhoIsWheres
  setWhoIsWheres: React.Dispatch<React.SetStateAction<WhoIsWheres>>
  dragEmployee: DragEmployee
  startTime: Date
  endTime: Date
}

// レイアウト表示部分
export const EmployeeCalendarLayoutMain: React.FC<Props> = ({
  layoutImg,
  layoutX,
  layoutY,
  layoutScale,
  seats,
  meetingRooms,
  areas,
  whoIsWheres,
  setWhoIsWheres,
  dragEmployee,
  startTime,
  endTime,
}: Props) => {
  // アイコン凡例の表示
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [x, setX] = React.useState(layoutX)
  const [y, setY] = React.useState(layoutY)
  const [scale, setScale] = React.useState(layoutScale)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  useEffect(() => {
    setX(layoutX)
    setY(layoutY)
    setScale(layoutScale)
  }, [layoutX, layoutY, layoutScale])

  useEffect(() => {
    if (layoutImageRef.current) {
      layoutImageRef.current.setTransform(x, y, scale)
    }
  }, [x, y, scale])

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          padding: theme.spacing(1.875, 0, 1.875, 0),
        }}
      >
        <Box>
          <Button
            sx={{
              fontSize: "12px",
              fontFamily: "Noto Sans",
              mr: 2,
              borderRadius: "16px",
            }}
            type="submit"
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
          >
            {translations.Legend}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: -15,
              horizontal: -10,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                height: "56px",
                width: "650px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="20%"
              >
                <AvailableSeat />
                <Typography variant="body1" pl={"7px"}>
                  {translations.Available}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="15%"
              >
                <NotCheckInSeat />
                <Typography variant="body1" pl={"7px"}>
                  {translations.Reserved}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="25%"
              >
                <AccountCircleOutlinedIcon />
                <Typography variant="body1" pl={"7px"}>
                  {translations.CheckedIn}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                width="25%"
              >
                <UnavailableSeat />
                <Typography variant="body1" pl={"7px"}>
                  {translations.NotAvailable}
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Box>
        <Box>
          <ZoomButtonBox color="primary">
            <MinusButton
              onClick={async () => {
                // 最小10%を超えないようにする
                if (
                  layoutImageRef.current?.state?.scale &&
                  layoutImageRef.current?.state?.scale <= 0.1
                ) {
                  return
                } else {
                  layoutImageRef.current?.setTransform(
                    x,
                    y,
                    layoutImageRef.current?.state.scale - 0.1
                  )
                }
              }}
            >
              ー
            </MinusButton>
            <PlusButton
              onClick={async () => {
                // 最大100%を超えないようにする
                if (
                  layoutImageRef.current?.state?.scale &&
                  layoutImageRef.current?.state?.scale >= 1.0
                ) {
                  return
                } else {
                  layoutImageRef.current?.setTransform(
                    x,
                    y,
                    layoutImageRef.current?.state.scale + 0.1
                  )
                }
              }}
            >
              ＋
            </PlusButton>
          </ZoomButtonBox>
        </Box>
      </Box>
      <TransformWrapper
        disabled={false}
        minScale={0.1}
        maxScale={1.0}
        wheel={{ disabled: false, step: 0.05 }}
        panning={{
          disabled: false,
        }}
        initialPositionX={x}
        initialPositionY={y}
        initialScale={scale}
        ref={layoutImageRef}
      >
        {!layoutImg && <p>{translations.LayoutNot}</p>}
        <TransformComponent
          wrapperStyle={{
            width: "calc(100vw - 408px)",
            height: "calc(100vh - 200px)",
          }}
        >
          <img id="layout-image" src={layoutImg} />
          {seats.map((seat, index) => {
            return (
              <EmployeeCalendarLayoutSeat
                key={index}
                seat={seat}
                whoIsWheres={whoIsWheres}
                setWhoIsWheres={setWhoIsWheres}
                dragEmployee={dragEmployee}
              />
            )
          })}
          {meetingRooms.map((meetingRoom, index) => {
            return (
              <EmployeeCalendarLayoutMeetingRoom
                key={index}
                meetingRoom={meetingRoom}
                whoIsWheres={whoIsWheres}
                setWhoIsWheres={setWhoIsWheres}
                startTime={startTime}
                endTime={endTime}
              />
            )
          })}
          {areas.map((area, index) => (
            <EmployeeCalendarLayoutArea key={index} area={area} />
          ))}
        </TransformComponent>
      </TransformWrapper>
    </>
  )
}
