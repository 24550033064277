const en = {
  YourNewPassword: "Enter your new password",
  ConfirmButton: "and press the Confirm button",
  eightCharacters: "Set a minimum password length of eight characters.",
  PleaseInclude:
    "Please include at least one uppercase letter, one lowercase letter, one number and one symbol in your password. ",
  Symbols: "Symbols can be ! / ; : @ [] - ` ~ ",
  OldPassword: "Old password(required)",
  NewPassword: "New password (required)",
  Confirm: "Confirm",
  loginPage: "Return to login page",
}
export default en
