import { useState } from "react"

import { fetchAreasRequest } from "../../../api/company/areaRequest"
import { Area } from "../../../types/area"
import { Tag } from "../../../types/tag"
import { FetchAreasRequestType } from "./type"

// TODO: ファイル名と関数名を合わせる
export const useAreas = () => {
  const [areas, setAreas] = useState<Area[]>([])
  const [tags, setTags] = useState<Tag[]>([])

  const fetchAreas = async (params: FetchAreasRequestType) => {
    try {
      const response = await fetchAreasRequest(params)
      if (response.data && response.status === 200) {
        setAreas(response.data.areas)
        setTags(response.data.tags)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    areas,
    tags,
    fetchAreas,
  }
}
