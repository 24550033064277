import React, { useEffect } from "react"

import { Box, Typography, styled } from "@mui/material"

import { CompanyHomeFirst } from "../../components/company/home"
import { AlertContent } from "../../components/public/AlertContent"
import { useLanguage } from "../../contexts/LanguageContext"
import enTranslations from "../../translations/companyHome/en"
import jaTranslations from "../../translations/companyHome/ja"

// TODO
// ページ遷移未実装分あり（ダッシュボード、URL決まったら実装）

const CustomMainBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.875, 2.5, 0, 1.875),
}))

const CustomTypography = styled(Typography)({
  fontSize: "10px",
  fontFamily: "Noto Sans",
  lineHeight: "20px",
})

const CustomFooter = styled(Box)({
  marginRight: "20px",
  textAlign: "right",
  color: "#707070",
  height: "20px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
})

export const CompanyHomePage = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  useEffect(() => {
    localStorage.removeItem("login")
  }, [])

  return (
    <>
      <CustomMainBox>
        <CompanyHomeFirst />
      </CustomMainBox>
      <CustomFooter>
        <CustomTypography variant="overline">
          <a
            href="https://workagile.jp/index.php/terms/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
              marginRight: "10px",
            }}
          >
            {translations.TermsService}
          </a>
          <a
            href="https://workagile.jp/index.php/privacy/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
            }}
          >
            {translations.PrivacyPolicy}
          </a>
        </CustomTypography>
      </CustomFooter>
      <AlertContent />
    </>
  )
}
