const en = {
  createFavorite: "Create your favorite team",
  TeamName: "Team name",
  TeamNameKana: "Team name Kana",
  Cancel: "Cancel",
  Create: "Create",
  FavoriteTeamList: "Favorite team list",
  Edit: "Edit",
  likeChange: "Would you like to change？",
  Change: "Change",
  AddMember: "Add a member",
  Members: "Members",
  LikeAddTeam: "Would you like to add this team?",
}
export default en
