const en = {
  todayButton: "Today",
  create: "＋ Create",
  status: "Status",
  allDay: "All day",
  week: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  year: "/",
  month: "/",
  day: "",
  TBD: "TBD",
  WorkFromHome: "H",
  OutOfOffice: "O",
  BusinessTrip: "B",
  Vacation: "V",
  ScheduleUpdate: "Your schedule has been successfully updated.",
  ScheduleCreate: "Your schedule has been created.",
  tryAgain: "There is a typing error, please try again",
  checkedIn: "Success! You are checked in.",
  checkedOut: "Success! You are checked out. ",
  CheckInFailed: "Check-in failed.",
}
export default en
