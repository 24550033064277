import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { useFederatedSignIn } from "../../../models/public/useFederatedSignIn"
import { RoleContext } from "../../../providers/RoleProvider"

export const FederatedSignInPage = () => {
  const navigate = useNavigate()
  const { isAuthenticated, fetchTokenFromAzureAD } = useFederatedSignIn()
  const { isAdmin } = useContext(RoleContext)
  const location = useLocation()

  interface QueryParams {
    floorId: string
    id: string
    type: string
    name: string
  }

  const state = (location.state || {}) as QueryParams

  // localStorageから値を取得
  const [queryParams, setQueryParams] = useState<QueryParams>({
    floorId: state.floorId || localStorage.getItem("floorId") || "",
    id: state.id || localStorage.getItem("id") || "",
    type: state.type || localStorage.getItem("type") || "",
    name: state.name || localStorage.getItem("name") || "",
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const code = params.get("code")
    const tenantId = params.get("tenant_id") || "デフォルトのテナントID"

    if (code) {
      fetchTokenFromAzureAD(code, tenantId).catch((e) => {
        console.error("認証処理中にエラーが発生しました:", e)
      })
    }
  }, [fetchTokenFromAzureAD, location.search])

  useEffect(() => {
    if (isAuthenticated) {
      const storedLoginType = localStorage.getItem("loginType")

      if (isAdmin && storedLoginType === "employee") {
        navigate("/employee/home")
      } else if (isAdmin && storedLoginType === "mobile") {
        navigate("/mobile/home")
      } else if (isAdmin && storedLoginType === "mobile-check-in") {
        navigate(
          `/mobile/check-in?floor_id=${queryParams.floorId}&id=${queryParams.id}&type=${queryParams.type}&name=${queryParams.name}`,
          { state: queryParams }
        )
      } else if (isAdmin) {
        navigate("/company/home")
      } else if (storedLoginType === "employee") {
        navigate("/employee/home")
      } else if (storedLoginType === "mobile") {
        navigate("/mobile/home")
      } else if (storedLoginType === "mobile-check-in") {
        navigate(
          `/mobile/check-in?floor_id=${queryParams.floorId}&id=${queryParams.id}&type=${queryParams.type}&name=${queryParams.name}`,
          { state: queryParams }
        )
      } else {
        navigate("/employee/home")
      }

      localStorage.removeItem("loginType")
      localStorage.removeItem("floorId")
      localStorage.removeItem("id")
      localStorage.removeItem("type")
      localStorage.removeItem("name")
    }
  }, [isAuthenticated, queryParams, navigate, isAdmin])

  return <div>Loading...</div>
}
