import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"
import { Amplify } from "aws-amplify"

import React, { useEffect, useMemo, useRef } from "react"

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import {
  Box,
  SelectChangeEvent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"

import { useSyncLanguageAcrossTabs } from "../../../../../src/utils/useSyncLanguageAcrossTabs"
import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import {
  AreaType,
  MeetingRoomType,
  LayoutType,
} from "../../../../models/employee/useLayout/type"
import { SeatObjectType } from "../../../../models/employee/useLayout/type"
import { EmployeeLayoutRequestType } from "../../../../models/employee/useLayout/type"
import {
  Branch,
  CompanyRelationsType,
} from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/mobileWorkPlace/en"
import jaTranslations from "../../../../translations/mobileWorkPlace/ja"
import { MobileEmployeeLayoutArea } from "../MobileEmployeeLayoutArea"
import { MobileEmployeeLayoutMeetingRoom } from "../MobileEmployeeLayoutMeetingRoom"
import { MobileEmployeeLayoutSeat } from "../MobileEmployeeLayoutSeat"
import { MobileLayoutHeader } from "../MobileLayoutHeader/index"

Amplify.configure(awsmobile)

interface Props {
  companyRelations: CompanyRelationsType
  changeSelectValue: (e: SelectChangeEvent<number>) => void
  handleLayoutChange: (params: EmployeeLayoutRequestType) => void
  branchId: number
  floorId: number
  filterSeatSchedules: (scheduled_date: string, start_time: string) => void
  filterMeetingRoomSchedules: (
    scheduled_date: string,
    start_time: string
  ) => void
  handleFilterWholeDaySchedule: (
    scheduledDate: string,
    wholeDayFlag: boolean,
    startTime?: string
  ) => void
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>

  previousDate: () => void
  nextDate: () => void
  dayOfWeek: string[]
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  startDate: Date
  endDate: Date
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  formatDate: (date: Date) => string
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  handleFilterSchedule: (wholeDayFlag: boolean) => void
  selectedBranch: Branch
  layoutImg: string
  layout: LayoutType
  seats: SeatObjectType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
}

export const MobileLayout: React.FC<Props> = ({
  companyRelations,
  handleLayoutChange,
  branchId,
  floorId,
  changeSelectValue,
  layoutImg,
  layout,
  seats,
  meetingRooms,
  areas,
  filterSeatSchedules,
  filterMeetingRoomSchedules,
  handleFilterWholeDaySchedule,
  date,
  setDate,
  previousDate,
  nextDate,
  previousWeekday,
  nextWeekday,
  weekdayNumber,
  setWeekdayNumber,
  dayOfWeek,
  startDate,
  endDate,
  formatDate,
  setStartDate,
  setEndDate,
  timeIndex,
  setTimeIndex,
  wholeDayFlag,
  setWholeDayFlag,
  loading,
  handleFilterSchedule,
  selectedBranch,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useSyncLanguageAcrossTabs()

  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [x, setX] = React.useState(layout.x)
  const [y, setY] = React.useState(layout.y)
  const [scale, setScale] = React.useState(layout.scale)

  const highLightedSeats = seats.filter((seat) => {
    return seat.is_highlight
  })

  const highLightedMeetingRooms = meetingRooms.filter((meetingRoom) => {
    return meetingRoom.is_highlight
  })

  // 座席もしくは会議室がハイライトされた場合にズームする
  useMemo(() => {
    if (highLightedSeats.length > 0) {
      const targetSeat = document.getElementById(String(highLightedSeats[0].id))
      layoutImageRef.current?.zoomToElement(targetSeat as HTMLElement, 1.3)
    }
  }, [highLightedSeats.length])

  useMemo(() => {
    if (highLightedMeetingRooms.length > 0) {
      const targetMeetingRoom = document.getElementById(
        `M${String(highLightedMeetingRooms[0].id)}`
      )
      layoutImageRef.current?.zoomToElement(
        targetMeetingRoom as HTMLElement,
        1.3
      )
    }
  }, [highLightedMeetingRooms.length])

  useEffect(() => {
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout])

  useEffect(() => {
    if (layoutImageRef.current) {
      layoutImageRef.current.setTransform(x, y, scale)
    }
  }, [x, y, scale])

  return (
    <>
      <Accordion>
        <Box sx={{ height: "40px" }}>
          <AccordionSummary
            expandIcon={<KeyboardDoubleArrowDownIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          />
        </Box>
        <Box sx={{ height: "150px" }}>
          <AccordionDetails>
            <MobileLayoutHeader
              changeSelectValue={changeSelectValue}
              companyRelations={companyRelations}
              branchId={branchId}
              floorId={floorId}
              handleLayoutChange={handleLayoutChange}
              filterSeatSchedules={filterSeatSchedules}
              filterMeetingRoomSchedules={filterMeetingRoomSchedules}
              handleFilterWholeDaySchedule={handleFilterWholeDaySchedule}
              date={date}
              setDate={setDate}
              previousDate={previousDate}
              nextDate={nextDate}
              previousWeekday={previousWeekday}
              nextWeekday={nextWeekday}
              weekdayNumber={weekdayNumber}
              setWeekdayNumber={setWeekdayNumber}
              dayOfWeek={dayOfWeek}
              startDate={startDate}
              endDate={endDate}
              timeIndex={timeIndex}
              setTimeIndex={setTimeIndex}
              formatDate={formatDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              wholeDayFlag={wholeDayFlag}
              setWholeDayFlag={setWholeDayFlag}
              loading={loading}
              handleFilterSchedule={handleFilterSchedule}
              selectedBranch={selectedBranch}
            />
          </AccordionDetails>
        </Box>
      </Accordion>
      <TransformWrapper
        disabled={false}
        minScale={0.1}
        maxScale={1.0}
        wheel={{ disabled: false, step: 0.05 }}
        panning={{
          disabled: false,
        }}
        initialPositionX={x}
        initialPositionY={y}
        initialScale={scale}
        ref={layoutImageRef}
      >
        {!layoutImg && <p>{translations.LayoutNot}</p>}
        <TransformComponent
          wrapperStyle={{
            maxWidth: "100%",
            maxHeight: "600px",
          }}
        >
          <img
            id="layout-image"
            src={layoutImg}
            style={{ marginBottom: "400px" }}
          />
          {seats.map((seat, index) => {
            return (
              <>
                <Box key={index}>
                  <MobileEmployeeLayoutSeat seat={seat} />
                </Box>
              </>
            )
          })}
          {meetingRooms.map((meetingRoom, index) => {
            return (
              <>
                <Box key={index}>
                  <MobileEmployeeLayoutMeetingRoom meetingRoom={meetingRoom} />
                </Box>
              </>
            )
          })}
          {areas.map((area, index) => (
            <Box key={index}>
              <MobileEmployeeLayoutArea area={area} />
            </Box>
          ))}
        </TransformComponent>
      </TransformWrapper>
    </>
  )
}
