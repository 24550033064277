import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { AccessTime } from "@mui/icons-material"
import { Box } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"

import { ValidEndDateProps } from ".."
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeNewSchedule/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeNewSchedule/ja"

export const StartDateTimeComponent: React.FC<ValidEndDateProps> = ({
  validEndDateTime,
  setValidEndDateTime,
  setDateError,
  defaultDate,
  scheduleDayDefaultStartTime,
  setErrorMessages,
  checkMeetingOrSeat,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { control, watch, setValue, getValues } = useFormContext<ScheduleForm>()

  const handleStartDateTimeChange = (newStartDateTime: Date | null) => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.StartTimeReset])
    }
    if (newStartDateTime) {
      compareToEndTime(newStartDateTime)
    }
  }

  const compareToEndTime = (newStartDateTime: Date | null) => {
    const endTime = watch("schedule.end_time")
    setValue("schedule.start_time", newStartDateTime)
    if (
      newStartDateTime &&
      endTime &&
      !Number.isNaN(newStartDateTime.getTime())
    ) {
      setValue(
        "schedule.end_time",
        new Date(
          endTime.getFullYear(),
          endTime.getMonth(),
          endTime.getDate(),
          newStartDateTime.getHours() + 1,
          newStartDateTime.getMinutes()
        )
      )
    }
    setValidEndDateTime("rgba(0, 0, 0, 0.23)")
    setDateError(true)
  }

  const handleStartDateTimeBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.StartTimeReset])
    }

    const inputTime = event.target.value
    const hour = ~inputTime.indexOf("午")
      ? inputTime.substring(3, inputTime.indexOf(":"))
      : inputTime.substring(0, inputTime.indexOf(":"))
    const minutes = inputTime.substring(inputTime.indexOf(":") + 1)
    const startDate = watch("schedule.start_date")
    if (hour != null && minutes != null && startDate != null) {
      const newStartTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        Number(hour),
        Number(minutes)
      )
      compareToEndTime(newStartTime)
    }
  }

  //初回レンダリング時
  useEffect(() => {
    //開始時刻の初期表示にセット
    if (scheduleDayDefaultStartTime) {
      setValue(
        "schedule.start_time",
        new Date(
          defaultDate.getFullYear(),
          defaultDate.getMonth(),
          defaultDate.getDate(),
          scheduleDayDefaultStartTime
        )
      )
    }
  }, [])

  return (
    <>
      <Box
        sx={{ width: "35%" }}
        display={watch("schedule.whole_day_flag") ? "none" : "block"}
      >
        <Controller
          control={control}
          name="schedule.start_time"
          render={({ field }) => (
            <TimePicker
              {...field}
              label={translations.StartTime}
              views={["hours", "minutes"]}
              onChange={handleStartDateTimeChange}
              slots={{
                openPickerIcon: AccessTime,
              }}
              slotProps={{
                openPickerIcon: {
                  sx: {
                    padding: "0",
                  },
                },
                textField: {
                  onBlur: handleStartDateTimeBlur,
                  sx: {
                    "& label": {
                      color:
                        validEndDateTime === "rgba(0, 0, 0, 0.23)"
                          ? "rgba(0, 0, 0, 0.6)"
                          : "#d32f2f",
                      fontSize: "12px",
                      fontFamily: "Noto Sans",
                    },
                    "& .MuiOutlinedInput-input": {
                      fontSize: "12px",
                      fontFamily: "Noto Sans",
                      padding: "5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: validEndDateTime,
                      },
                    },
                    width: "100%",
                  },
                  required: true,
                  id: "start_date_time",
                  name: "start_date_time",
                },
              }}
            />
          )}
        />
      </Box>
    </>
  )
}
