import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"

import {
  Box,
  styled,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material"

import { useSubscriptionNotifyEmail } from "../../../models/company/useSubscriptionNotifyEmail"
import { CancelButton } from "../../public/CancelButton"
import { CustomHeadTag } from "../../public/CustomHeadTag"
import { ReflectButton } from "../../public/ReflectButton"

interface Props {
  onClickCancelButton: () => void
}
const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

export const PaymentAddress = ({ onClickCancelButton }: Props) => {
  const {
    form,
    subscriptionNotifyEmail,
    setSubscriptionNotifyEmail,
    updateSubscriptionNotifyEmail,
  } = useSubscriptionNotifyEmail()

  const errors = form.formState.errors

  return (
    <>
      <CustomHeadTag />
      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="email">
            <Typography
              variant="h6"
              color="inherit"
              sx={{
                fontWeight: "bold",
              }}
            >
              新しいメールアドレス
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={form.control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                {...form}
                onBlur={(event) => {
                  setSubscriptionNotifyEmail(event.target.value)
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
                id="email"
                name="email"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "450px",
          m: "30px auto",
        }}
      >
        <CancelButton label="閉じる" handleClick={onClickCancelButton} />
        <ReflectButton
          label="更新"
          disabled={!form.formState.isValid}
          handleClick={updateSubscriptionNotifyEmail}
        />
      </Box>
    </>
  )
}
