import React from "react"

import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} from "@mui/material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { Schedules } from "../../../../../models/employee/useSchedule/show/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeShowScheduleModal/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeShowScheduleModal/ja"

interface Props {
  schedules: Schedules
}

export const SeatOrMeetingRoomTableComponent: React.FC<Props> = ({
  schedules,
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(3)
  const duplicatedMeetingRooms = schedules.filter((schedule, index, self) => {
    return (
      self.findIndex((value) => {
        if (
          schedule.reservable &&
          schedule.reservable.reservable_type === "MeetingRoom"
        ) {
          return (
            schedule.reservable.reservable_type ===
            value.reservable.reservable_type
          )
        }
      }) === index
    )
  })

  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            (duplicatedMeetingRooms.length != 0
              ? duplicatedMeetingRooms.length
              : schedules.filter((schedule) => {
                  return schedule.reservable != undefined
                }).length)
        )
      : 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.Location}</TableCell>
                <TableCell align="right">{translations.Floor}</TableCell>
                <TableCell align="right">{translations.DeskRoom}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? (duplicatedMeetingRooms.length != 0
                    ? duplicatedMeetingRooms
                    : schedules
                  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : duplicatedMeetingRooms.length != 0
                ? duplicatedMeetingRooms
                : schedules
              ).map((schedule, index) => {
                if (schedule.reservable) {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {schedule.reservable.floor.branch.branch_name}
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="right">
                        {schedule.reservable.floor.floor_number}F
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="right">
                        {schedule.reservable.name}
                      </TableCell>
                    </TableRow>
                  )
                }
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3]}
                  colSpan={3}
                  count={
                    duplicatedMeetingRooms.length != 0
                      ? duplicatedMeetingRooms.length
                      : schedules.filter((schedule) => {
                          return schedule.reservable != undefined
                        }).length
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}${translations.from}${to} ${translations.people} / ${count}${translations.manyPeople}`
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
