import awsmobile from "../../aws-exports"

export const redirectToSAMLLogin = (
  email: string,
  identityProvider: string
): void => {
  const clientId = awsmobile.aws_user_pools_web_client_id // CognitoのアプリクライアントID
  const redirectUri = awsmobile.oauth.redirectSignIn // フロントエンドのリダイレクト先
  const domain = awsmobile.oauth.domain

  // SAMLログイン用のURLを構築
  const loginUrl = `https://${domain}/authorize?identity_provider=${encodeURIComponent(
    identityProvider
  )}&response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}`

  // メールアドレスをqueryStringに加えてリダイレクト
  window.location.href = loginUrl + `&email=${encodeURIComponent(email)}`
}
