import React from "react"
import { Route, Routes } from "react-router-dom"

import { CommonTemplate } from "../pages/CommonTemplate"
import { ErrorPage } from "../pages/ErrorPage"
import { CompanyHomePage } from "../pages/company"
import { CompanyInfoRegistration } from "../pages/company/auth/CompanyInfoRegistration"
import { CompanyMFA } from "../pages/company/auth/MFA"
import { SignUpApplied } from "../pages/company/auth/SignUpApplied"
import { CompanyConfirmForgotPassword } from "../pages/company/auth/comfirmForgotPassword"
import { CompanyForgotPassword } from "../pages/company/auth/forgotPassword"
import { CompanyForgotPasswordApplied } from "../pages/company/auth/forgotPasswordApplied"
import { CompanyLogin } from "../pages/company/auth/login"
import { CompanyPasswordChange } from "../pages/company/auth/passwordChange"
import { CompanySignUp } from "../pages/company/auth/signup"
import { CompanyBranchListPage } from "../pages/company/branches"
import { CompanyBranchUpdatePage } from "../pages/company/branches/edit"
import { CompanyBranchCreatePage } from "../pages/company/branches/new"
import { CompanyBranchShowPage } from "../pages/company/branches/show"
import { CompanyDashboardPage } from "../pages/company/dashboard"
import { CompanyEmployeeListPage } from "../pages/company/employees"
import { CompanyEmployeeUpdatePage } from "../pages/company/employees/edit"
import { CompanyEmployeeCreatePage } from "../pages/company/employees/new"
import { CompanyEmployeeShowPage } from "../pages/company/employees/show"
import { CompanyLayoutCreatePage } from "../pages/company/layouts/new"
import { CompanyQrCodeCreatePage } from "../pages/company/qrcode/new"
import { CompanyReportPage } from "../pages/company/reports"
import { CompanyReportAreaPage } from "../pages/company/reports/areas"
import { CompanyReportAttendancePage } from "../pages/company/reports/attendances"
import { CompanyReportMeetingRoomPage } from "../pages/company/reports/meeting_rooms"
import { CompanyReportOperationPage } from "../pages/company/reports/operations"
import { CompanySettingsPage } from "../pages/company/settings"
import { CompanyTeamListPage } from "../pages/company/teams"
import { CompanyTeamUpdatePage } from "../pages/company/teams/edit"
import { CompanyTeamCreatePage } from "../pages/company/teams/new"
import { CompanyAdminRoute } from "./CompanyAdminRoute"
import { CompanyBranchLeaderRoute } from "./CompanyBranchLeaderRoute"
import { CompanyPublicRoute } from "./CompanyPublicRoute"
import { CompanyTeamLeaderRoute } from "./CompanyTeamLeaderRoute"

export const CompanyRouter: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path="/signup"
          element={
            <CompanyPublicRoute path="/signup" element={<CompanySignUp />} />
          }
        />
        <Route
          path="/signup/applied"
          element={
            <CompanyPublicRoute
              path="/signup/applied"
              element={<SignUpApplied />}
            />
          }
        />
        <Route
          path="/login"
          element={
            <CompanyPublicRoute path="/login" element={<CompanyLogin />} />
          }
        />
        <Route
          path="/mfa"
          element={
            <CompanyPublicRoute path="/login" element={<CompanyMFA />} />
          }
        />
        <Route path="/password-change" element={<CompanyPasswordChange />} />
        <Route
          path="/forgot-password"
          element={
            <CompanyPublicRoute
              path="/forgot-password"
              element={<CompanyForgotPassword />}
            />
          }
        />
        <Route
          path="/forgot-password/applied"
          element={
            <CompanyPublicRoute
              path="/forgot-password/applied"
              element={<CompanyForgotPasswordApplied />}
            />
          }
        />
        <Route
          path="/forgot-password/confirm"
          element={
            <CompanyPublicRoute
              path="/forgot-password/confirm"
              element={<CompanyConfirmForgotPassword />}
            />
          }
        />
        <Route
          path="/register-info"
          element={
            <CompanyAdminRoute
              path="/register-info"
              element={<CompanyInfoRegistration />}
            />
          }
        />
        <Route
          path="/employees"
          element={
            <CompanyTeamLeaderRoute
              path="employees"
              element={
                <CommonTemplate>
                  <CompanyEmployeeListPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/employees/new"
          element={
            <CompanyAdminRoute
              path="/employees/new"
              element={
                <CommonTemplate>
                  <CompanyEmployeeCreatePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/employees/:id/edit"
          element={
            <CompanyTeamLeaderRoute
              path="/employees/:id/edit"
              element={
                <CommonTemplate>
                  <CompanyEmployeeUpdatePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/employees/:id"
          element={
            <CompanyTeamLeaderRoute
              path="/employees/:id"
              element={
                <CommonTemplate>
                  <CompanyEmployeeShowPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/branches"
          element={
            <CompanyBranchLeaderRoute
              path="/branches"
              element={
                <CommonTemplate>
                  <CompanyBranchListPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/branches/new"
          element={
            <CompanyAdminRoute
              path="/branches/new"
              element={
                <CommonTemplate>
                  <CompanyBranchCreatePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/branches/:id/edit"
          element={
            <CompanyBranchLeaderRoute
              path="/branches/:id/edit"
              element={
                <CommonTemplate>
                  <CompanyBranchUpdatePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/branches/:id"
          element={
            <CompanyBranchLeaderRoute
              path="/branches/:id"
              element={
                <CommonTemplate>
                  <CompanyBranchShowPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/branches/:branch_id/floors/:floor_id/layouts/:layout_id"
          element={
            <CompanyAdminRoute
              path="/branches/:branch_id/floors/:floor_id/layouts/:layout_id"
              element={
                <CommonTemplate>
                  <CompanyLayoutCreatePage />
                </CommonTemplate>
              }
            ></CompanyAdminRoute>
          }
        />
        <Route
          path="/branches/:branch_id/floors/:floor_id/layouts/new"
          element={
            <CompanyAdminRoute
              path="/branches/:branch_id/floors/:floor_id/layouts/new"
              element={
                <CommonTemplate>
                  <CompanyLayoutCreatePage />
                </CommonTemplate>
              }
            ></CompanyAdminRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <CompanyAdminRoute
              path="/dashboard"
              element={
                <CommonTemplate>
                  <CompanyDashboardPage />
                </CommonTemplate>
              }
            ></CompanyAdminRoute>
          }
        />
        <Route
          path="/branches/:id/floors/:floor_id/qrcode"
          element={
            <CompanyAdminRoute
              path="/branches/:id/floors/:floor_id/qrcode"
              element={
                <CommonTemplate>
                  <CompanyQrCodeCreatePage />
                </CommonTemplate>
              }
            ></CompanyAdminRoute>
          }
        />
        <Route
          path="/home"
          element={
            <CompanyAdminRoute
              path="/home"
              element={
                <CommonTemplate>
                  <CompanyHomePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/teams"
          element={
            <CompanyAdminRoute
              path="/teams"
              element={
                <CommonTemplate>
                  <CompanyTeamListPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/teams/new"
          element={
            <CompanyAdminRoute
              path="/teams/new"
              element={
                <CommonTemplate>
                  <CompanyTeamCreatePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/teams/:id/edit"
          element={
            <CompanyAdminRoute
              path="/employees/:id/edit"
              element={
                <CommonTemplate>
                  <CompanyTeamUpdatePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/reports"
          element={
            <CompanyAdminRoute
              path="/reports"
              element={
                <CommonTemplate>
                  <CompanyReportPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/reports/attendances"
          element={
            <CompanyTeamLeaderRoute
              path="/reports/attendances"
              element={
                <CommonTemplate>
                  <CompanyReportAttendancePage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/reports/meeting_rooms"
          element={
            <CompanyAdminRoute
              path="/reports/meeting_rooms"
              element={
                <CommonTemplate>
                  <CompanyReportMeetingRoomPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/reports/operations"
          element={
            <CompanyAdminRoute
              path="/reports/operations"
              element={
                <CommonTemplate>
                  <CompanyReportOperationPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/reports/areas"
          element={
            <CompanyAdminRoute
              path="/reports/areas"
              element={
                <CommonTemplate>
                  <CompanyReportAreaPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/settings/*"
          element={
            <CompanyAdminRoute
              path="/settings/*"
              element={
                <CommonTemplate>
                  <CompanySettingsPage />
                </CommonTemplate>
              }
            ></CompanyAdminRoute>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}
