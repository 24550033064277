import { useCallback, useState } from "react"

export const dateStatus = (today: Date = new Date()) => {
  const targetDate = new Date(
    localStorage.getItem("targetDay") === null
      ? today
      : new Date(parseInt(localStorage.getItem("targetDay") as string, 10))
  )
  const [date, setDate] = useState<Date>(targetDate)
  const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]
  const [weekdayNumber, setWeekdayNumber] = useState<number>(date.getDay())

  const [startDate, setStartDate] = useState<Date>(targetDate)
  const [endDate, setEndDate] = useState<Date>(targetDate)

  const previousDate = useCallback(() => {
    const newPreviousDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 1
    )
    setDate(newPreviousDate)
    window.localStorage.setItem(
      "targetDay",
      newPreviousDate.getTime().toString()
    )
  }, [date])

  const nextDate = useCallback(() => {
    const newNextDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1
    )
    setDate(newNextDate)
    window.localStorage.setItem("targetDay", newNextDate.getTime().toString())
  }, [date])

  const previousWeekday = useCallback(
    () => setWeekdayNumber(date.getDay() <= 0 ? 6 : date.getDay() - 1),
    [date]
  )

  const nextWeekday = useCallback(
    () => setWeekdayNumber(date.getDay() >= 6 ? 0 : date.getDay() + 1),
    [date]
  )

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = ("00" + (date.getMonth() + 1)).slice(-2)
    const day = ("00" + date.getDate()).slice(-2)
    return year + "-" + month + "-" + day
  }

  return {
    date,
    setDate,
    previousDate,
    nextDate,
    formatDate,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
  }
}
