const ja = {
  LayoutNot: "レイアウトが存在しません",
  Location: "拠点",
  Floor: "フロア",
  month: "月",
  day: "日",
  AllDay: "終日",
  weekdays: ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"],
  Desk: "座席番号",
  Name: "予約者",
  Subject: "件名",
}
export default ja
