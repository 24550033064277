import React, { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Box from "@mui/material/Box"

import { CompanyEmployeeForm } from "../../../components/company/employee/CompanyEmployeeForm"
import { BackIcon } from "../../../components/public/BackIcon"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { ContentTitle } from "../../../components/public/ContentTitle"
import { Loading } from "../../../components/public/Loading"
import { useEmployee } from "../../../models/company/useEmployee"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CompanyEmployeeUpdatePage = () => {
  const employeeObject = useEmployee()
  const { companyRelations } = useCompanyRelations()
  const navigate = useNavigate()
  const urlParams = useParams<{ id: string }>()
  const selectedEmployeeId = Number(urlParams.id)

  useMemo(() => {
    employeeObject.editEmployee(selectedEmployeeId).then(() => {
      employeeObject.fetchFile(
        employeeObject.getValues("company_id"),
        selectedEmployeeId
      )
    })
  }, [])

  return (
    <>
      <ContentTemplate>
        <ContentCompanyPaper>
          {employeeObject.loading ? (
            <Loading type="content" loading={employeeObject.loading} />
          ) : (
            <CompanyEmployeeForm
              {...employeeObject}
              companyRelations={companyRelations}
            />
          )}
        </ContentCompanyPaper>
      </ContentTemplate>
    </>
  )
}
