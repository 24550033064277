import { useState } from "react"

import { useLanguage } from "../contexts/LanguageContext"
import enTranslations from "../translations/employeeHome/employeeHomeSchedule/en"
import jaTranslations from "../translations/employeeHome/employeeHomeSchedule/ja"

export const weekStatus = (today: Date = new Date()) => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const todayNum = today.getDay()
  const beginningOfThisWeek = new Date(
    localStorage.getItem("BeginningOfWeek") === null
      ? new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - todayNum
        )
      : new Date(
          parseInt(localStorage.getItem("BeginningOfWeek") as string, 10)
        )
  )
  const endOfThisWeek = new Date(
    beginningOfThisWeek.getFullYear(),
    beginningOfThisWeek.getMonth(),
    beginningOfThisWeek.getDate() + 6
  )

  const dayOfWeek = translations.week

  // 週の最初の日
  const [beginningOfWeek, setBeginningOfWeek] =
    useState<Date>(beginningOfThisWeek)

  // 週の最後の日
  const [endOfWeek, setEndOfWeek] = useState<Date>(endOfThisWeek)

  // 1週間分の日付配列
  const [week, setWeek] = useState<Array<string>>([])

  // 対象週の日付配列を作成
  const fetchWeek = (beginningOfWeek: Date) => {
    window.localStorage.setItem(
      "BeginningOfWeek",
      beginningOfWeek.getTime().toString()
    )
    const thisWeeks = []
    for (let i = 0; i < 7; i++) {
      const beginningDay = new Date(beginningOfWeek)
      beginningDay.setDate(beginningDay.getDate() + i)
      const month = beginningDay.getMonth() + 1
      const day = beginningDay.getDate()
      thisWeeks.push(month + "/" + day)
    }
    setWeek(thisWeeks)
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = ("00" + (date.getMonth() + 1)).slice(-2)
    const day = ("00" + date.getDate()).slice(-2)
    return year + "-" + month + "-" + day
  }

  return {
    dayOfWeek,
    week,
    beginningOfWeek,
    endOfWeek,
    setBeginningOfWeek,
    setEndOfWeek,
    fetchWeek,
    formatDate,
  }
}
