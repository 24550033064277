const en = {
  FindMembers: "Find members",
  Member: "MEMBER",
  BusinessUnit: "BUSINESS UNIT",
  Manager: "MANAGER",
  Location: "LOCATION",
  Floor: "FLOOR",
  Favorite: "FAVORITE",
  Team: "TEAM",
  Cancel: "CANCEL",
  Enter: "ENTER",
  employeeMembers: "Find members",
  searchName: "Search by name",
  Search: "SEARCH",
  FindLocation: "Find location",
  search: "Search ",
}
export default en
