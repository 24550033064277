import React from "react"
import { useNavigate } from "react-router-dom"

import { Button, Box, Typography, styled, Tooltip } from "@mui/material"

import { PreparationResponseType } from "../../../models/company/useCompany/type"

const CustomListBox = styled(Box)(({ theme }) => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  backgroundColor: "#FFFFFF",
  marginBottom: theme.spacing(1.875),
}))

const CustomImageBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  display: "flex",
  alignItems: "center",
}))

const ToDoListBox = styled(Box)(() => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
}))

const ToDoListText = styled(Typography)(({ theme }) => ({
  color: "#707070",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  marginLeft: theme.spacing(6),
}))

const TooltipBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  display: "flex",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "216px",
  height: "40px",
  backgroundColor: "#22BA9D",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  marginRight: theme.spacing(6),
}))

interface Props {
  companyPreparation: PreparationResponseType
}

// TODO
// 設定変更の表示条件、未実装
export const FirstStepList = ({ companyPreparation }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      {companyPreparation.floor_count === 0 && (
        <CustomListBox>
          <CustomImageBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/branch.svg`}
              width="60px"
            />
          </CustomImageBox>
          <ToDoListBox>
            <ToDoListText>オフィス・ワークプレイス詳細の追加</ToDoListText>
            <TooltipBox>
              <Tooltip
                title="フロア、レイアウト、席等、各オフィス・ワークプレイスの詳細情報を追加してください。"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "200px",
                    },
                  },
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/question.svg`}
                  width="28px"
                />
              </Tooltip>
            </TooltipBox>
          </ToDoListBox>
          <Box>
            <CustomButton
              variant="contained"
              onClick={() => {
                navigate("/company/branches")
              }}
            >
              始める
            </CustomButton>
          </Box>
        </CustomListBox>
      )}
      {companyPreparation.team_count <= 1 && (
        <CustomListBox>
          <CustomImageBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/team.svg`}
              height="60px"
              width="60px"
            />
          </CustomImageBox>
          <ToDoListBox>
            <ToDoListText>組織の追加</ToDoListText>
            <TooltipBox>
              <Tooltip
                title="ワークプレイスの組織情報を登録してください。"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "200px",
                    },
                  },
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/question.svg`}
                  width="28px"
                />
              </Tooltip>
            </TooltipBox>
          </ToDoListBox>
          <Box>
            <CustomButton
              variant="contained"
              onClick={() => {
                navigate("/company/teams/new")
              }}
            >
              追加する
            </CustomButton>
          </Box>
        </CustomListBox>
      )}
      {companyPreparation.employee_count === 0 && (
        <CustomListBox>
          <CustomImageBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/employee.svg`}
              width="60px"
            />
          </CustomImageBox>
          <ToDoListBox>
            <ToDoListText>従業員の追加</ToDoListText>
            <TooltipBox>
              <Tooltip
                title="予定表の利用を開始する為に、あなたの職場のメンバーを登録してください。"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "200px",
                    },
                  },
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/question.svg`}
                  width="28px"
                />
              </Tooltip>
            </TooltipBox>
          </ToDoListBox>
          <Box>
            <CustomButton
              variant="contained"
              onClick={() => {
                navigate("/company/employees/new")
              }}
            >
              追加する
            </CustomButton>
          </Box>
        </CustomListBox>
      )}
      <CustomListBox>
        <CustomImageBox>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/setting.svg`}
            width="60px"
          />
        </CustomImageBox>
        <ToDoListBox>
          <ToDoListText>設定変更</ToDoListText>
          <TooltipBox>
            <Tooltip
              title="決済方法の登録、プランの設定や環境設定を行い、ワークアジャイルを活用してください。"
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "200px",
                  },
                },
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/question.svg`}
                width="28px"
              />
            </Tooltip>
          </TooltipBox>
        </ToDoListBox>
        <Box>
          <CustomButton
            variant="contained"
            onClick={() => {
              navigate("/company/settings/payment")
            }}
          >
            設定する
          </CustomButton>
        </Box>
      </CustomListBox>
    </>
  )
}
