import {
  EmployeeGroupsType,
  EmployeeGroupRequestType,
} from "../../models/employee/useEmployeeGroup/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const createEmployeeGroupRequest = async (
  params: EmployeeGroupRequestType
): Promise<ApiResponse<EmployeeGroupsType>> => {
  try {
    const data = await api.post(`/employee/employee_groups`, params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const fetchEmployeeGroupsRequest = async (): Promise<
  ApiResponse<EmployeeGroupsType>
> => {
  try {
    const data = await api.get("/employee/employee_groups")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const deleteEmployeeGroupRequest = async (params: {
  id: number
}): Promise<ApiResponse<EmployeeGroupsType>> => {
  try {
    const data = await api.patch(
      `/employee/employee_groups/${params.id}/delete`
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateEmployeeGroupRequest = async (
  params: EmployeeGroupRequestType
): Promise<ApiResponse<EmployeeGroupsType>> => {
  try {
    const data = await api.patch(
      `/employee/employee_groups/${params.id}`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
