import { useState } from "react"

import {
  fetchSchedulableMonthesRequest,
  updateSchedulableMonthesRequest,
} from "../../../api/company/schedulableMonthesRequest"

export const useSchedulableMonthes = () => {
  const [schedulableMonthes, setSchedulableMonthes] = useState<number>(3)
  const [schedulableMonthesId, setSchedulableMonthesId] = useState<number>(0)
  const [monthNumber, setMonthNumber] = useState<number>(0)

  const fetchSchedulableMonthes = async () => {
    try {
      const response = await fetchSchedulableMonthesRequest()
      if (response.data && response.status === 200) {
        setSchedulableMonthesId(response.data.id)
        setSchedulableMonthes(response.data.schedulable_monthes)
        setMonthNumber(response.data.schedulable_monthes)
      }
    } catch (e) {
      console.log("error")
    }
  }

  const updateSchedulableMonthes = async () => {
    try {
      const response = await updateSchedulableMonthesRequest({
        id: schedulableMonthesId,
        schedulable_monthes: schedulableMonthes,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "予定作成可能期間を変更しました")
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    schedulableMonthes,
    setSchedulableMonthes,
    fetchSchedulableMonthes,
    updateSchedulableMonthes,
    monthNumber,
  }
}
