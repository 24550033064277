import React, { useContext, useEffect } from "react"
import { NavLink, useLocation } from "react-router-dom"

import { Box, styled } from "@mui/material"

import { EnvironmentSettings } from "../../../components/company/environmentSettings"
import { CompanyWeeklyOffDay } from "../../../components/company/environmentSettings/CompanyWeeklyOffDay"
import { SignageForm } from "../../../components/company/environmentSettings/SignageForm"
import { WorkHour } from "../../../components/company/environmentSettings/WorkHour"
import { Akerun } from "../../../components/company/externalSystemLink/Akerun"
import { ServiceManagement } from "../../../components/company/serviceManagements"
import { PaymentCreditCardForm } from "../../../components/company/serviceManagements/PaymentCreditCardForm"
import { PaymentHistory } from "../../../components/company/serviceManagements/PaymentHistory"
import { PaymentMethods } from "../../../components/company/serviceManagements/PaymentMethods"
import { PaymentPaidForm } from "../../../components/company/serviceManagements/PaymentPaidForm"
import { PaymentPlanForm } from "../../../components/company/serviceManagements/PaymentPlanForm"
import { AlertContent } from "../../../components/public/AlertContent"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { ContentTitle } from "../../../components/public/ContentTitle"
import { SetupMFA } from "../../../components/public/SetupMFA"
import { RoleContext } from "../../../providers/RoleProvider"
import { ExternalSystemLinkComponent } from "./ExternalSystemLink"

const CustomMainBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.875),
  marginRight: theme.spacing(2.5),
  marginLeft: theme.spacing(1.875),
}))

const CustomTitleHeader = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  width: "220px",
  borderBottomStyle: "solid",
  fontSize: "18px",
  fontWeight: "bold",
  textAlign: "center",
}))

const HeaderLine = styled(Box)(() => ({
  borderBottomStyle: "solid",
  borderColor: "#a9a9a9",
  flexGrow: 1,
}))

interface SettingsMenuType {
  name: string
  url: string
  component: JSX.Element
}

// TODO
// URLは必要に応じて変更する
const settingsMenu: SettingsMenuType[] = [
  {
    name: "サービス管理",
    url: "/company/settings/payment",
    component: <div></div>,
  },
  {
    name: "外部連携",
    url: "/company/settings/external-system-link",
    component: <div></div>,
  },
  {
    name: "2要素認証",
    url: "/company/settings/setup-mfa",
    component: <div></div>,
  },
  {
    name: "環境設定",
    url: "/company/settings/environment",
    component: <div></div>,
  },
]

// 選択されているタブのスタイル
const activeMenuStyle = {
  borderColor: "#22BA9D",
  color: "#22BA9D",
  textDecoration: "none",
}

// 非選択時のタブのスタイル
const inactiveMenuStyle = {
  borderColor: "#808A9F",
  color: "#808A9F",
  textDecoration: "none",
}

// 法人設定ページ。company/settings以下のURLは全てこのコンポーネントを読み込む。
export const CompanySettingsPage = () => {
  const location = useLocation()
  const { handleRoleChange } = useContext(RoleContext)

  // 権限変更があれば権限取得
  useEffect(() => {
    handleRoleChange()
  }, [location.pathname])

  // タブ切り替え時にどのコンポーネントを表示するのかを制御する
  const settingsMainContent = () => {
    switch (location.pathname) {
      case "/company/settings/payment":
        return <ServiceManagement />
      case "/company/settings/payment/credit_card":
        return (
          <ContentTemplate>
            <ContentTitle title="クレジットカードの編集" />
            <ContentCompanyPaper>
              <PaymentCreditCardForm />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/payment/methods":
        return (
          <ContentTemplate>
            <ContentTitle title="お支払い方法" />
            <ContentCompanyPaper>
              <PaymentMethods />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/payment/history":
        return (
          <ContentTemplate>
            <ContentCompanyPaper>
              <PaymentHistory />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/payment/paid":
        return (
          <ContentTemplate>
            <ContentTitle title="Paid決済利用申し込み" />
            <ContentCompanyPaper>
              <PaymentPaidForm />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/payment/plan":
        return (
          <ContentTemplate>
            <ContentTitle title="利用プランの変更" />
            <ContentCompanyPaper>
              <PaymentPlanForm />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/external-system-link":
        return <ExternalSystemLinkComponent />
      case "/company/settings/external-system-link/akerun":
        return (
          <ContentTemplate>
            <ContentTitle title="拠点の紐付け" />
            <ContentCompanyPaper>
              <Akerun />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/survey":
        return <div>ウェルネス</div>
      case "/company/settings/setup-mfa":
        return <SetupMFA />
      case "/company/settings/environment":
        return <EnvironmentSettings />
      case "/company/settings/environment/work_hour":
        return (
          <ContentTemplate>
            <ContentTitle title="就業時間" />
            <ContentCompanyPaper>
              <WorkHour />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/environment/weekly_off_day":
        return (
          <ContentTemplate>
            <ContentTitle title="公休日" />
            <ContentCompanyPaper>
              <CompanyWeeklyOffDay />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
      case "/company/settings/environment/signage":
        return (
          <ContentTemplate>
            <ContentTitle title="サイネージ" />
            <ContentCompanyPaper>
              <SignageForm />
            </ContentCompanyPaper>
          </ContentTemplate>
        )
    }
  }

  return (
    <>
      <CustomMainBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {settingsMenu.map((menu, index) => {
            return (
              <NavLink
                key={index}
                to={menu.url}
                style={({ isActive }) => {
                  return isActive ? activeMenuStyle : inactiveMenuStyle
                }}
              >
                <CustomTitleHeader>{menu.name}</CustomTitleHeader>
              </NavLink>
            )
          })}
          <HeaderLine />
        </Box>
      </CustomMainBox>
      <CustomMainBox>{settingsMainContent()}</CustomMainBox>
      <AlertContent />
    </>
  )
}
