import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { employeeSearchRequest } from "../../../api/company/employeeSearchRequest"
import { downloadIcon } from "../../../utils/downLoadIcon"
import {
  Employee,
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "./type"

const initialSearchEmployeeParams: SearchEmployeeRequestType = {
  freeword: "",
  branch_id: 0,
  floor_id: 0,
  team_id: 0,
  employee_group_id: 0,
}

const initialSelectedEmployee: Employee = {
  id: 0,
  account: { id: 0, email: "", company_id: 0 },
  first_name: "",
  first_name_kana: "",
  last_name: "",
  last_name_kana: "",
  team: { id: 0, team_name: "" },
  team_leader_name: "",
  team_leader_email: "",
  branch: {
    branch_name: "",
  },
  floor: {
    floor_number: "",
  },
  checked: false,
  icon: "",
}

// 従業員検索
export const useSearchEmployeeLeader = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [searchEmployeeParams] = useState<SearchEmployeeRequestType>(
    initialSearchEmployeeParams
  )

  const [searchedEmployeeResult, setSearchedEmployeeResult] =
    useState<SearchedEmployeeResult>([])
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>(
    initialSelectedEmployee
  )
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)

  const [isManagerDecisionButton, setIsManagerDecisionButton] =
    useState<boolean>(true)

  // 必須項目（従業員名、拠点、所属のいずれか）のバリデーション
  const validateRequireValues = function (this: any) {
    const { freeword, branch_id, team_id, employee_group_id } = this.parent
    return !(
      freeword.length === 0 &&
      branch_id === 0 &&
      team_id === 0 &&
      employee_group_id === 0
    )
  }

  const basicSchema = Yup.object().shape({
    freeword: Yup.string().test(
      "maxlength",
      "50文字以下で入力してください",
      (freeword) => {
        if (freeword == undefined) {
          return true
        }
        return freeword.length <= 50
      }
    ),
    floor_id: Yup.number().when("branch_id", {
      is: (branch_id: number) => branch_id !== 0,
      then: Yup.number().min(1, "フロアを選択してください"),
    }),
    require: Yup.boolean().test(
      "require_validation",
      "従業員名（カナ）、拠点およびフロア、所属、お気に入りのいずれかが必須です",
      validateRequireValues
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      freeword: searchEmployeeParams.freeword,
      branch_id: searchEmployeeParams.branch_id,
      floor_id: searchEmployeeParams.floor_id,
      team_id: searchEmployeeParams.team_id,
      employee_group_id: searchEmployeeParams.employee_group_id,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<SearchEmployeeRequestType> = useCallback(
    async (data) => {
      try {
        setLoading(true)
        const response = await employeeSearchRequest(data)
        if (response.status == 200 && response.data) {
          const defaultSearchEmployeeResult = response.data.map(
            (employee: Employee) => {
              return { ...employee, checked: false }
            }
          )
          setSearchedEmployeeResult(defaultSearchEmployeeResult)
          // iconをS3と同期
          const fetchIconsPromises = defaultSearchEmployeeResult.map(
            async (employee: Employee) => {
              if (employee.icon)
                employee.icon = await downloadIcon(
                  employee.account.company_id,
                  employee.id,
                  employee.icon
                )
              return await { ...employee, checked: false }
            }
          )
          Promise.all(fetchIconsPromises).then(setSearchedEmployeeResult)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // 従業員をcheckする
  const handleEmployeeChecked = (selectedEmployeeId: number) => {
    const newSearchEmployeeResult = searchedEmployeeResult.map((employee) => {
      if (employee.id === selectedEmployeeId) {
        return { ...employee, checked: !employee.checked }
      } else {
        return employee
      }
    })
    setSearchedEmployeeResult(newSearchEmployeeResult)
    const selectedEmployees = newSearchEmployeeResult.filter((employee) => {
      return employee.checked
    })
    if (selectedEmployees.length > 1) {
      setErrorMessages(["所属長は一人まで選択できます"])
      setErrorMessageOpen(true)
      setIsManagerDecisionButton(true)
      return
    } else if (selectedEmployees.length == 1) {
      setErrorMessages([""])
      setErrorMessageOpen(false)
      setSelectedEmployee(selectedEmployees[0])
      setIsManagerDecisionButton(false)
    } else {
      setErrorMessages([""])
      setErrorMessageOpen(false)
      setIsManagerDecisionButton(true)
    }
  }

  return {
    control,
    errors,
    isValid,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    searchedEmployeeResult,
    setSearchedEmployeeResult,
    selectedEmployee,
    setSelectedEmployee,
    handleEmployeeChecked,
    errorMessages,
    errorMessageOpen,
    isManagerDecisionButton,
    loading,
  }
}
