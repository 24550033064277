import { yupResolver } from "@hookform/resolvers/yup"
import { Auth, Amplify } from "aws-amplify"
import * as Yup from "yup"

import { useState, useCallback } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import awsmobile from "../../../../aws-exports"
import { useLanguage } from "../../../../contexts/LanguageContext"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { EMAIL_REGEXP, PASSWORD_REGEX } from "../../../../utils/regular"
import { SignUpType } from "./type"

const authFormData: SignUpType = {
  email: "",
  password: "",
  passwordConfirmation: "",
  privacyAcceptance: false,
  receiveNotification: false,
}

export interface SignUpParams {
  username: string
  password: string
  attributes: {
    email: string
    "custom:receiveNotification": string
    "custom:isAdmin": string
  }
}

export const useSignUp = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const navigate = useNavigate()
  const [authData] = useState<SignUpType>(authFormData)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  Amplify.configure(awsmobile)

  const basicSchema = Yup.object().shape({
    email: Yup.string()
      .required(translations.Required)
      .matches(EMAIL_REGEXP, translations.email),
    password: Yup.string()
      .required(translations.Required)
      .max(50, translations.fiftyPassword)
      .min(8, translations.eightCharacters)
      .matches(PASSWORD_REGEX, translations.PleaseOneSymbol),
    passwordConfirmation: Yup.string()
      .required(translations.Required)
      .oneOf([Yup.ref("password"), null], translations.PasswordNotMatch),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: authData.email,
      password: authData.password,
      passwordConfirmation: authData.passwordConfirmation,
      privacyAcceptance: authData.privacyAcceptance,
      receiveNotification: authData.receiveNotification,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<SignUpType> = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          "custom:receiveNotification": `${Number(data.receiveNotification)}`, // お知らせ通知を受け取るかどうか
          "custom:isAdmin": "1", // ユーザープール側で管理者かどうか管理する
        },
      })
      if (response.user) {
        navigate("/company/signup/applied") // 仮登録完了画面に遷移
      }
    } catch (error) {
      console.log(error)
      setOpen(true)
      setErrorMessages(["ユーザーの登録に失敗しました"])
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    control,
    authData,
    errors,
    isValid,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    open,
    errorMessages,
    loading,
  }
}
