import {
  CheckInOutMeetingRoomRequestType,
  CheckInOutMeetingRoomResponseType,
} from "../../models/employee/useCheckInOutMeetingRoom/type"
import {
  MeetingRoomResponseType,
  ImputScheduleData,
} from "../../models/employee/useMeetingRooms/type"
import { api, ApiResponse } from "../config/axiosConfig"

// ログインユーザーの所属するフロアの会議室を参照する
export const fetchMeetingRoomsRequest = async (
  params: ImputScheduleData
): Promise<ApiResponse<MeetingRoomResponseType[]>> => {
  try {
    const data = await api.get("/employee/meeting_rooms", { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

/** チェックインの前後の会議室を参照する */
export const fetchCheckInOutMeetingRoomRequest = async (): Promise<
  ApiResponse<CheckInOutMeetingRoomResponseType> // 変更点: 型を席用から会議室用に変更
> => {
  try {
    const data = await api.get("/employee/meeting_rooms/fetch_checked_in_out")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

/** 会議室チェックイン/アウトの更新 */
export const updateCheckInOutMeetingRoomRequest = async (
  params: CheckInOutMeetingRoomRequestType // 型を更新
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post(
      "/employee/meeting_rooms/update_checked_in_out",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

/** 会議室チェックアウトの更新 */
export const updateCheckOutLeaveMeetingRoomRequest = async (
  params: CheckInOutMeetingRoomRequestType // 型を更新
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post(
      "/employee/meeting_rooms/update_checked_out_leave",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchCheckInMeetingRoomNameRequest = async (targetId: number) => {
  try {
    const response = await api.get(
      `/employee/meeting_rooms/${targetId}/fetch_meeting_room_info`
    )
    return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response.status === 403) {
      throw error
    }
    return { error: error.response.data }
  }
}
