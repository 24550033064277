import React, { useEffect, useRef, useState } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import CircleCheckedFilled from "@mui/icons-material/CheckCircle"
import {
  Box,
  styled,
  Grid,
  InputLabel,
  TextareaAutosize,
  FormHelperText,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  ButtonProps,
} from "@mui/material"

import { useSignage } from "../../../models/company/useSignage"
import { CancelButton } from "../../public/CancelButton"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const CustomLabel = styled(InputLabel)(() => ({
  fontWeight: "bold",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "200px",
  height: "35px",
  fontSize: theme.spacing(2),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
}))

export const SignageForm = () => {
  const navigate = useNavigate()
  const {
    form,
    handleImageUpdate,
    imageUrl,
    updateSignage,
    fetchSignage,
    displaySignage,
    setDisplaySignage,
  } = useSignage()
  const [loading, setLoading] = useState<boolean>(true)
  const fileInputRef = useRef<HTMLInputElement>(null)

  // カメラアイコンをクリックした時にfile fieldを起動する
  const handleCameraIconClick = () => {
    fileInputRef.current?.click()
  }

  const errors = form.formState.errors

  useEffect(() => {
    const signageRequest = async () => {
      await fetchSignage()
    }
    signageRequest().then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>
      {loading ? (
        <>
          <Loading type="content" />
        </>
      ) : (
        <>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="signage_reception_message">
                受付サイネージ機能
              </CustomLabel>
            </CustomGridItem>
            <Grid item xs={2} sm={2}>
              <CustomGridItem>
                <Box display="flex" alignItems="center">
                  <RadioGroup
                    row
                    aria-label="display-signage"
                    name="displaySignage"
                    value={displaySignage ? "true" : "false"}
                    onChange={(e) =>
                      setDisplaySignage(e.target.value === "true")
                    }
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio checkedIcon={<CircleCheckedFilled />} />}
                      label="OFF"
                      sx={{ marginLeft: 1 }}
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio checkedIcon={<CircleCheckedFilled />} />}
                      label="ON"
                      sx={{ marginLeft: 1 }}
                    />
                  </RadioGroup>
                </Box>
              </CustomGridItem>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="signage_reception_message">
                ウェルカムメッセージ
              </CustomLabel>
            </CustomGridItem>
            <Grid item xs={2} sm={2}>
              <Controller
                control={form.control}
                name="signage_reception_message"
                render={({ field }) => (
                  <TextareaAutosize
                    {...field}
                    required
                    minRows={3}
                    id="signage_reception_message"
                    name="signage_reception_message"
                    value={form.watch("signage_reception_message")}
                    style={{ width: 400 }}
                  />
                )}
              />
              <FormHelperText error={!!errors.signage_reception_message}>
                {errors.signage_reception_message?.message}
              </FormHelperText>
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container>
            <CustomGridItem item xs={2} sm={2}>
              <CustomLabel htmlFor="signage_image">サイネージ画像</CustomLabel>
            </CustomGridItem>
            <Grid item xs={2} sm={2}>
              <CustomButton variant="outlined" onClick={handleCameraIconClick}>
                画像アップロード
              </CustomButton>
              <input
                hidden
                ref={fileInputRef}
                type="file"
                onChange={handleImageUpdate}
                accept="image/jpeg,image/png"
              />
            </Grid>
          </CustomGridContainer>
          {imageUrl && (
            <Grid item xs={12} sm={12}>
              <img id="signage_image" src={imageUrl} />
            </Grid>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton
              label="戻る"
              handleClick={() => navigate("/company/settings/environment")}
            />
            <ReflectButton
              label="更新する"
              disabled={!form.formState.isValid}
              handleClick={updateSignage}
            />
          </Box>
        </>
      )}
    </>
  )
}
