import React from "react"

import { MobileAuthPageTemplate } from "../../../components/mobile/common/AuthPageTemplate"
import { MAFForm } from "../../../components/public/auth/MFAForm"
import { useMFA } from "../../../models/public/useAuth/MFA"

export const MobileMFA = () => {
  const authObject = useMFA()

  return (
    <MobileAuthPageTemplate loading={authObject.loading}>
      <MAFForm {...authObject} />
    </MobileAuthPageTemplate>
  )
}
