import html2canvas from "html2canvas"
import jsPDF from "jspdf"

import React from "react"

import { Box, Grid, Modal, styled, Typography } from "@mui/material"

import { theme } from "../../../config/theme"
import { Billing, Company } from "../../../models/company/useBilling/type"
import { jaDateFormat } from "../../../utils/dateTimeFormat"
import { CancelButton } from "../../public/CancelButton"
import { ReflectButton } from "../../public/ReflectButton"
import { changePaymentMethodsText } from "./PaymentHistory"

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80%",
  overflow: "auto",
}))

const CustomGridContainer = styled(Grid)(() => ({
  padding: theme.spacing(2, 0),
  display: "flex",
  alignItems: "center",
}))

const SubContainer = styled(Grid)(() => ({
  padding: theme.spacing(2, 0),
}))

const CustomGridItem = styled(Grid)(() => ({}))

const CustomLabel = styled(Typography)(() => ({
  fontSize: "13px",
}))

interface Props {
  isOpen: boolean
  close: () => void
  billing: Billing
  company: Company
}

export const ReceiptModal: React.FC<Props> = ({
  isOpen,
  close,
  billing,
  company,
}: Props) => {
  const pdhDownloadHandler = async () => {
    // PDFファイルに変換したいコンポーネントのidを検索してDOM要素を取得する

    const target = document.getElementById("pdf-id")
    if (target === null) return

    await html2canvas(target, { scale: 2.0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0)
      const pdf = new jsPDF({
        format: "a4",
      })
      const width = pdf.internal.pageSize.width
      pdf.addImage(imgData, "JPEG", 0, 0, width, 0)
      pdf.save(`${billing.subject}_領収書.pdf`)
    })
  }

  return (
    <>
      <Modal open={isOpen} onClose={close}>
        <CustomLayoutModal>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "30px",
            }}
            variant="h6"
          >
            領収書を発行しますか？
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <CancelButton label="キャンセル" handleClick={close} />
            <ReflectButton
              label="発行する"
              handleClick={async (e) => {
                await pdhDownloadHandler()
                close()
              }}
            />
          </Box>
          <Box id="pdf-id" sx={{ padding: "30px 30px 20px" }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "right",
                fontSize: "12px",
              }}
            >
              領収日： {jaDateFormat(billing.payment_date)}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                letterSpacing: "1em",
                padding: theme.spacing(2, 0),
              }}
            >
              【領収書】
            </Typography>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                borderTop: "2px solid rgb(128, 138, 159)",
                "& .MuiGrid-container": {
                  paddingTop: "0px",
                  paddingButton: "0px",
                },
                padding: theme.spacing(1, 0),
              }}
            >
              <CustomGridItem item xs={12} sm={2}>
                <CustomLabel>法人名</CustomLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={10}>
                <CustomLabel>{company.company_name}御中</CustomLabel>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                paddingTop: theme.spacing(2),
              }}
            >
              <CustomGridItem item xs={12} sm={12}>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                  }}
                >
                  <CustomGridItem item xs={12} sm={6}>
                    <SubContainer
                      container
                      sx={{
                        padding: theme.spacing(1, 0),
                        borderTop: "2px solid rgb(128, 138, 159)",
                        borderBottom: "2px solid rgb(128, 138, 159)",
                      }}
                    >
                      <CustomGridItem item xs={12} sm={6}>
                        <CustomLabel
                          sx={{
                            fontSize: "14px",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          合計金額（税込）
                        </CustomLabel>
                      </CustomGridItem>
                      <CustomGridItem item xs={12} sm={6}>
                        <CustomLabel
                          sx={{
                            fontSize: "14px",
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {`￥${billing.billing_amount.toLocaleString()}`}
                        </CustomLabel>
                      </CustomGridItem>
                    </SubContainer>
                    <SubContainer
                      container
                      sx={{
                        padding: theme.spacing(1, 0),
                        borderBottom: "1px solid rgb(128, 138, 159)",
                      }}
                    >
                      <CustomGridItem item xs={12} sm={12}>
                        <CustomLabel
                          sx={{ fontSize: "12px", textAlign: "left" }}
                        >
                          但し、Workagile利用料として
                        </CustomLabel>
                      </CustomGridItem>
                    </SubContainer>
                    <SubContainer
                      container
                      sx={{
                        padding: theme.spacing(1, 0),
                        borderBottom: "1px solid rgb(128, 138, 159)",
                      }}
                    >
                      <CustomGridItem item xs={12} sm={6}>
                        <CustomLabel
                          sx={{ fontSize: "12px", textAlign: "left" }}
                        >
                          お支払い方法
                        </CustomLabel>
                      </CustomGridItem>
                      <CustomGridItem item xs={12} sm={6}>
                        <CustomLabel
                          sx={{ fontSize: "12px", textAlign: "right" }}
                        >
                          {changePaymentMethodsText(billing.payment_methods)}
                        </CustomLabel>
                      </CustomGridItem>
                    </SubContainer>
                  </CustomGridItem>
                  <CustomGridItem
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                    item
                    xs={12}
                    sm={6}
                  >
                    <Box>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/logos/issuer2.png`}
                        width="200px"
                        height="55px"
                      />
                    </Box>
                  </CustomGridItem>
                </SubContainer>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                borderBottom: "2px solid rgb(128, 138, 159)",
                "& .MuiGrid-container": {
                  paddingTop: "0px",
                  paddingButton: "0px",
                },
                padding: theme.spacing(1, 0, 1, 0),
              }}
            >
              <CustomGridItem item xs={12} sm={12}>
                <CustomLabel sx={{ fontSize: "18px" }}>明細</CustomLabel>
              </CustomGridItem>
            </CustomGridContainer>
            <CustomGridContainer
              container
              sx={{
                paddingTop: "0px",
              }}
            >
              <CustomGridItem item xs={12} sm={12}>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "2px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={12} sm={6}>
                    <CustomLabel
                      sx={{
                        fontSize: "12px",
                        paddingLeft: theme.spacing(1),
                      }}
                    >
                      品名
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={12} sm={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "center" }}>
                      金額(税込)
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={12} sm={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "center" }}>
                      消費税
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
                <SubContainer
                  container
                  sx={{
                    padding: theme.spacing(1, 0),
                    borderBottom: "2px solid rgb(128, 138, 159)",
                  }}
                >
                  <CustomGridItem item xs={12} sm={6}>
                    <CustomLabel
                      sx={{ fontSize: "12px", paddingLeft: theme.spacing(1) }}
                    >
                      {billing.subject}
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={12} sm={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.billing_amount.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                  <CustomGridItem item xs={12} sm={3}>
                    <CustomLabel sx={{ fontSize: "12px", textAlign: "right" }}>
                      {`￥${billing.without_tax.toLocaleString()}`}
                    </CustomLabel>
                  </CustomGridItem>
                </SubContainer>
              </CustomGridItem>
            </CustomGridContainer>
          </Box>
        </CustomLayoutModal>
      </Modal>
    </>
  )
}
