export const feeYearCalculation = (
  yearAmount: number,
  accountCount: number
) => {
  if (accountCount > 1000) {
    return 100000 + (accountCount - 1000) * 150
  } else if (accountCount >= 500) {
    return 100000
  } else if (accountCount > 300) {
    return 60000 + (accountCount - 300) * 200
  } else if (accountCount >= 240) {
    return 60000
  } else if (accountCount > 0) {
    return yearAmount * accountCount
  } else {
    return 0
  }
}
