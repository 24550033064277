// アイコン画像のダウンロード
import { Storage, Amplify } from "aws-amplify"

import awsmobile from "../aws-exports"

Amplify.configure(awsmobile)

export const downloadIcon = async (
  companyId: number,
  employeeId: number,
  img: string
) => {
  if (!img) return
  const customPrefix = `company${companyId}/icons/employee${employeeId}/`

  const result = await Storage.get(img, {
    level: "public",
    customPrefix: {
      public: customPrefix,
    },
  })

  return result
}
