import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Grid, Box, styled, Typography, Button } from "@mui/material"

import { checkAzureAdIntegration } from "../../../../api/AzureAD/azureadRequest"
import { sendPasswordResetEmail } from "../../../../api/company/resetPasswordRequest"
import { EmployeeResponseType } from "../../../../models/company/useEmployee/type"
import { RoleContext } from "../../../../providers/RoleProvider"
import { useOpen } from "../../../../utils/isOpen"
import { CancelButton } from "../../../public/CancelButton"
import { ConfirmModal } from "../../../public/ConfirmModal"
import { EmployeeIconWrapper } from "../../../public/EmployeeIconWrapper"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomBox = styled(Box)(() => ({
  justifyContent: "center",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  justifyContent: "space-around",
}))

export const DeleteButton = styled(Button)({
  height: "50px",
  width: "100px",
  fontWeight: "bold",
  borderRadius: "6px",
})

interface Props {
  employeeDetail: EmployeeResponseType
  handleDeleteEmployee: () => void
  forbidden: boolean
  imageUrl: string
}

export const CompanyEmployeeShowData: React.FC<Props> = ({
  employeeDetail,
  handleDeleteEmployee,
  forbidden,
  imageUrl,
}: Props) => {
  const navigate = useNavigate()
  const urlParams = useParams<{ id: string }>()
  const deleteModal = useOpen() // 利用停止モーダルの開閉
  const { isAdmin } = useContext(RoleContext)

  const [isAzureAdIntegrated, setIsAzureAdIntegrated] = useState<boolean>(false)

  useEffect(() => {
    const fetchAzureAdIntegration = async () => {
      const result = await checkAzureAdIntegration()
      setIsAzureAdIntegrated(result)
    }

    fetchAzureAdIntegration()
  }, [])

  const isSeatStr = (flag: number) => {
    return flag === null ? "なし" : "あり"
  }

  const isRoleStr = (role: number) => {
    if (role === 0) {
      return "法人管理者"
    } else if (role === 1) {
      return "拠点長"
    } else if (role === 2) {
      return "所属長"
    } else if (role === 3) {
      return "一般社員"
    }
  }

  const handleSendPasswordResetEmail = async (email: string) => {
    const response = await sendPasswordResetEmail(email)
    console.log("Response from sendPasswordResetEmail: ", response)
    if (response.error) {
      let errorMessage
      if (response.error.includes("status is not FORCE_CHANGE_PASSWORD")) {
        errorMessage = "このユーザーはパスワードを設定されています"
      } else if (response.error.includes("User does not exist")) {
        errorMessage = "処理を選択にて招待メールの送信を行ってください"
      } else {
        errorMessage = response.error
      }
      alert(errorMessage)
      console.error(response.error)
    } else {
      alert("メールの送信に成功しました")
      console.log("Password reset email sent successfully.")
    }
  }

  const boldStyle = {
    fontWeight: "bold",
    fontSize: "12px",
  }

  return (
    <>
      <ConfirmModal
        modalTitle={"利用中のアカウントを削除します"}
        warningText={[
          `${employeeDetail.last_name} ${employeeDetail.first_name}を削除します。`,
          "従業員に関連する情報が存在する場合、顔写真、行動履歴等の全ての情報が削除されます。",
        ]}
        isOpen={deleteModal.isOpen}
        close={deleteModal.close}
        handleClick={() => handleDeleteEmployee()}
      />
      <CustomBox>
        <Grid container justifyContent="space-between">
          <Box>
            <EmployeeIconWrapper>
              {employeeDetail.icon === null ? (
                <Typography variant="h6" color="inherit">
                  {employeeDetail.last_name[0]}
                  {employeeDetail.first_name[0]}
                </Typography>
              ) : (
                <img
                  src={imageUrl}
                  style={{
                    borderRadius: "50%",
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </EmployeeIconWrapper>
            {employeeDetail.account.available_status === "paused" && isAdmin && (
              <DeleteButton
                sx={{ mt: 1 }}
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => deleteModal.open()}
              >
                アカウント削除
              </DeleteButton>
            )}
          </Box>
          <Grid item xs={4.5}>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  従業員名
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex">
                <Typography id="last_name_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.last_name}
                </Typography>
                <Typography
                  id="first_name_id"
                  style={{ paddingLeft: "0.75rem", fontSize: "0.75rem" }}
                >
                  {employeeDetail.first_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  従業員名（カナ）
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex">
                <Typography
                  id="last_name_kana_id"
                  style={{ fontSize: "0.75rem" }}
                >
                  {employeeDetail.last_name_kana}
                </Typography>
                <Typography
                  id="first_name_kana_id"
                  style={{ paddingLeft: "0.75rem", fontSize: "0.75rem" }}
                >
                  {employeeDetail.first_name_kana}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  名前
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} display="flex">
                <Typography id="display_name" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.display_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  メールアドレス
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="email_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.account.email}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  従業員ID
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="employee_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.employee_code}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  社用電話番号
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="phone_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.phone_number}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  拠点名
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="branch_name_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.branch.branch_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  フロア名
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  id="floor_number_id"
                  style={{ fontSize: "0.75rem" }}
                >
                  {employeeDetail.floor.floor_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  所属名
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="team_name_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.team.team_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
          </Grid>
          <Grid item xs={4.5}>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  所属長名
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  id="team_leader_name_id"
                  style={{ fontSize: "0.75rem" }}
                >
                  {employeeDetail.team.team_leader_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  所属長メールアドレス
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  id="team_leader_email_id"
                  style={{ fontSize: "0.75rem" }}
                >
                  {employeeDetail.team.team_leader_email}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  勤務形態
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="remote_flag_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.remote_flag ? "フルリモート勤務" : "出社勤務"}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  指定席
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="is_seat" style={{ fontSize: "0.75rem" }}>
                  {isSeatStr(employeeDetail.seat_id)}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer
              container
              style={{ display: employeeDetail.seat_id ? "" : "none" }}
            >
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  指定席ID
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="seat_id" style={{ fontSize: "0.75rem" }}>
                  {employeeDetail.seat_name}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={6}>
                <Typography style={boldStyle} color="primary">
                  権限
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="role_id" style={{ fontSize: "0.75rem" }}>
                  {isRoleStr(employeeDetail.role)}
                </Typography>
              </Grid>
            </CustomGridContainer>
            {/* 連携時以外はAzureADの項目を非表示 */}
            {isAdmin && isAzureAdIntegrated && (
              <CustomGridContainer container>
                <Grid item xs={12} sm={6}>
                  <Typography style={boldStyle} color="primary">
                    AzureAD
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography id="object_id" style={{ fontSize: "0.75rem" }}>
                    {employeeDetail.account.object_id ? "あり" : "なし"}
                  </Typography>
                </Grid>
              </CustomGridContainer>
            )}
            <CustomGridContainer container>
              <Grid item xs={12} sm={12} mb="1rem">
                <Typography style={boldStyle} color="primary">
                  備考
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  id="note"
                  whiteSpace="pre-wrap"
                  style={{ fontSize: "0.75rem" }}
                >
                  {employeeDetail.note}
                </Typography>
              </Grid>
            </CustomGridContainer>
            <CustomGridContainer container>
              <Grid item xs={12} sm={12} mt="1rem">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: "6px" }}
                  onClick={() => {
                    if (window.confirm("本当に送信しますか?")) {
                      handleSendPasswordResetEmail(employeeDetail.account.email)
                    }
                  }}
                >
                  招待メールを再送信する
                </Button>
              </Grid>
            </CustomGridContainer>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: forbidden ? "center" : "space-between",
            width: "450px",
            m: "30px auto",
          }}
        >
          <CancelButton
            label="戻る"
            handleClick={() => navigate("/company/employees")}
          />
          {!forbidden &&
            employeeDetail.account.available_status !== "deleted" && (
              <ReflectButton
                label="編集"
                handleClick={() =>
                  navigate(`/company/employees/${urlParams.id}/edit`)
                }
              />
            )}
        </Box>
      </CustomBox>
    </>
  )
}
