const en = {
  YourEmail: "Enter your E-mail",
  SendYouCode: "and we will send you a verification code for recovery.",
  Email: "E-mail(required)",
  Confirm: "Confirm",
  loginPage: "Return to login page",
  resetEmail:
    "An invitation to reset your password has been sent to the email you entered.",
  accessURL: "Please access the URL in the email to reset your password.",
  FailedEmail: "Failed to send email",
}
export default en
