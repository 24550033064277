import { Plan } from "../../models/company/usePlan/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchPlansRequest = async (): Promise<ApiResponse<Plan[]>> => {
  try {
    const data = await api.get("/company/plans")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
