import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState, useCallback } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import {
  branchesRequest,
  createBranchRequest,
  updateBranchRequest,
  branchDetailRequest,
  branchDeleteRequest,
} from "../../../api/company/branchRequest"
import { POSTAL_CODE, HALF_WIDTH_NUMBER } from "../../../utils/regular"
import { BranchType, BranchFormType, BranchDetailType } from "./type"

const initialBranchRelation: BranchType[] = [
  {
    id: 0,
    branch_building_name: "",
    branch_city: "",
    branch_house_number: "",
    branch_name: "",
    branch_postal_code: "",
    branch_prefecture_id: 0,
    capacity: 0,
    company_id: 0,
    employee_count: 0,
    paused_employee_count: 0,
    branch_leader_name: "",
    restricted_seat_count: 0,
    seat_count: 0,
    akerun_organization_id: "",
  },
]

const branchResponseData: BranchDetailType = {
  id: 0,
  branch_leader_name: "",
  branch_name: "",
  branch_postal_code: "",
  branch_prefecture_id: 0,
  company_id: 0,
  employee_count: 0,
  floor_total: 0,
  branch_building_name: "",
  branch_id: 0,
  branch_house_number: "",
  capacity: 0,
  branch_city: "",
  floor_space: 0,
  free_seat_count: 0,
  meeting_rooms_count: 0,
  floors: [
    {
      id: 0,
      floor_name: "",
      floor_number: "",
      limited_attendance_rate: 0,
      restricted_seat_count: 0,
      maximum_available_count: 0,
      underground_flag: false,
      order_id: 0,
      layouts: [
        {
          id: 0,
          layout_image: "",
          post_status: 0,
          release_date: new Date(),
          paused_date: new Date(),
          version: 0,
        },
      ],
    },
  ],
  note: "",
  seat_count: 0,
  restricted_seat_count: 0,
}
const defaultBranch: BranchFormType = {
  branch_id: 0,
  branch_name: "",
  branch_postal_code: "",
  branch_prefecture_id: 0,
  branch_city: "",
  branch_house_number: "",
  branch_building_name: "",
  capacity: 0,
  note: "",
  floor_space: 0,
}

export const useBranch = () => {
  const navigate = useNavigate()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [branchRelation, setBranchRelations] = useState<BranchType[]>(
    initialBranchRelation
  )
  const [branchDetail, setBranchDetail] =
    useState<BranchDetailType>(branchResponseData)
  const [loading, setLoading] = useState<boolean>(true)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const urlParams = useParams<{ id: string }>()
  const basicSchema = Yup.object().shape({
    branch_name: Yup.string().required("必須になります"),
    branch_city: Yup.string().required("必須になります"),
    branch_house_number: Yup.string().required("必須になります"),
    branch_postal_code: Yup.string()
      .required("必須になります")
      .matches(POSTAL_CODE, "ハイフンあり、７桁の半角数字で入力してください"),
    branch_prefecture_id: Yup.number()
      .required("必須になります")
      .min(1, "都道府県を選択してください"),
    capacity: Yup.string()
      .required("必須になります")
      .matches(HALF_WIDTH_NUMBER, "半角数字で入力してください"),
    floor_space: Yup.string()
      .nullable()
      .matches(HALF_WIDTH_NUMBER, "半角数字で入力してください"),
    note: Yup.string().max(1000, "1000文字以内で入力してください"),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      branch_name: defaultBranch.branch_name,
      branch_postal_code: defaultBranch.branch_postal_code,
      branch_prefecture_id: defaultBranch.branch_prefecture_id,
      branch_city: defaultBranch.branch_city,
      branch_house_number: defaultBranch.branch_house_number,
      branch_building_name: defaultBranch.branch_building_name,
      capacity: defaultBranch.capacity,
      note: defaultBranch.note,
      floor_space: defaultBranch.floor_space,
      branch_id: defaultBranch.branch_id,
    },
    resolver: yupResolver(basicSchema),
  })

  const fetchBranchRelations = async () => {
    try {
      const response = await branchesRequest()
      if (response.status === 200 && response.data) {
        setBranchRelations(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
  }

  const onCreateSubmit: SubmitHandler<BranchFormType> = useCallback(
    async (data) => {
      const { error } = await createBranchRequest({
        ...data,
      })
      if (!error) {
        localStorage.setItem("alertContent", "拠点の登録が完了しました")
        navigate("/company/branches")
      } else {
        setErrorMessages(error)
        setErrorMessageOpen(true)
      }
    },
    []
  )

  const onUpdateSubmit: SubmitHandler<BranchFormType> = useCallback(
    async (data) => {
      const { error } = await updateBranchRequest({
        ...data,
      })
      if (!error) {
        localStorage.setItem("alertContent", "拠点の更新が完了しました")
        navigate(`/company/branches/${data.branch_id}`)
      } else {
        setErrorMessages(error)
        setErrorMessageOpen(true)
      }
    },
    []
  )

  const editBranch = useCallback(async () => {
    try {
      const response = await branchDetailRequest({ branch_id: urlParams.id })
      if (response.status === 200 && response.data) {
        reset({
          branch_name: response.data.branch_name,
          branch_postal_code: response.data.branch_postal_code,
          branch_prefecture_id: response.data.branch_prefecture_id,
          branch_city: response.data.branch_city,
          branch_house_number: response.data.branch_house_number,
          branch_building_name: response.data.branch_building_name,
          capacity: response.data.capacity,
          note: response.data.note,
          floor_space: response.data.floor_space,
          branch_id: response.data.id,
        })
        setForbidden(false)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 403) {
        setForbidden(true)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const deleteBranch = useCallback(async (id: number) => {
    try {
      setLoading(true)
      const response = await branchDeleteRequest({ branch_id: id })
      if (response.error) {
        console.error(response.error)
        if (response.status === 403) setForbidden(true)
        if (response.error[0] === "対象の拠点を主拠点とした従業員がいます")
          throw [
            "対象の拠点を主拠点とした従業員がいます。",
            "従業員管理画面から、拠点で検索し、対象の拠点を主拠点とする社員がいない状態にしてから、再度削除してください。",
            "※利用停止中社員の所属の場合もある為、利用停止中社員についてもお確かめください。",
          ]
        throw response.error
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const showBranch = useCallback(async () => {
    const matchBranchId = location.pathname.match(/\/company\/branches\/(\d+)/)
    const branch_id = matchBranchId ? matchBranchId[1] : ""
    try {
      const response = await branchDetailRequest({ branch_id: branch_id })
      if (response.status === 200 && response.data) {
        setBranchDetail(response.data)
        setForbidden(false)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 403) {
        setForbidden(true)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchBranchDetail = useCallback(async () => {
    try {
      const response = await branchDetailRequest({ branch_id: urlParams.id })
      if (response.status === 200 && response.data) {
        setBranchDetail(response.data)
        setForbidden(false)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 403) {
        setForbidden(true)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    control,
    errors,
    isValid,
    errorMessageOpen,
    errorMessages,
    setErrorMessages,
    branchDetail,
    handleSubmit,
    onCreateSubmit,
    onUpdateSubmit,
    editBranch,
    deleteBranch,
    showBranch,
    fetchBranchDetail,
    branchRelation,
    getValues,
    setValue,
    fetchBranchRelations,
    loading,
    forbidden,
  }
}
