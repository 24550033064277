import {
  CheckInOutRequestType,
  CheckInOutResponseType,
} from "../../models/employee/useCheckInOutSeat/type"
import { SeatResponseType } from "../../models/employee/useSeats/type"
import { api, ApiResponse } from "../config/axiosConfig"

// 最近利用した席を参照する
export const fetchSeatsRequest = async (
  startTime: Date | null,
  endTime: Date | null
): Promise<ApiResponse<SeatResponseType[]>> => {
  try {
    const data = await api.get("/employee/seats/recent_seats", {
      params: {
        startTime: startTime,
        endTime: endTime,
      },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// チェックインの前後の席を参照する
export const fetchCheckInOutSeatRequest = async (): Promise<
  ApiResponse<CheckInOutResponseType>
> => {
  try {
    const data = await api.get("/employee/seats/fetch_checked_in_out")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// チェックイン/アウトの更新
export const updateCheckInOutSeatRequest = async (
  params: CheckInOutRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/employee/seats/update_checked_in_out", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

/**チェックアウトの更新 */
export const updateCheckOutLeaveScheduleRequest = async (
  params: CheckInOutRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post(
      "/employee/seats/update_checked_out_leave",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

// ログインユーザーの所属するフロアの座席を参照する
export const fetchAllSeatsRequest = async (
  start_date: Date
): Promise<ApiResponse<SeatResponseType[]>> => {
  try {
    const data = await api.get("/employee/seats", {
      params: { start_date: start_date },
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
