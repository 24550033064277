import { Storage } from "aws-amplify"

import { useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"

import {
  fetchMeetingRoomSchedulesRequest,
  fetchMeetingRoomScheduleDetailRequest,
  fetchNextMeetingRoomSchedulesRequest,
  extendMeetingRoomScheduleRequest,
} from "../../../api/signage/meetingRoomRequest"
import { downloadIcon } from "../../../utils/downLoadIcon"
import {
  MeetingRoomScheduleType,
  SignageMeetingRoomType,
  MeetingRoomDetailScheduleType,
} from "./type"

export const initialScheduleData: MeetingRoomScheduleType = {
  id: 0,
  scheduled_date: "",
  schedule_title: "",
  start_time: "",
  end_time: "",
  checked_in: false,
  is_secret: true,
}

const initialMeetingRoomData: SignageMeetingRoomType = {
  id: 0,
  company_id: 0,
  branch_id: 0,
  floor_id: 0,
  meeting_room_name: "",
  meeting_room_code: 0,
  meeting_room_image: "",
  schedules: [initialScheduleData],
}

const initialScheduleDetailData: MeetingRoomDetailScheduleType = {
  id: 0,
  scheduled_date: "",
  schedule_title: "",
  start_time: "",
  end_time: "",
  checked_in: false,
  is_secret: false,
  schedule_creator: {
    id: 0,
    first_name: "",
    last_name: "",
    team_name: "",
    company_id: 0,
    icon: "",
    icon_url: "",
  },
}

export const useSignageMeetingRoom = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [meetingRoomData, setMeetingRoomData] =
    useState<SignageMeetingRoomType>(initialMeetingRoomData)
  const [meetingRoomDetailSchedule, setMeetingRoomDetailSchedule] =
    useState<MeetingRoomDetailScheduleType>(initialScheduleDetailData)
  const [nextReservableMinutes, setNextReservableMinutes] = useState<number[]>(
    []
  )
  const [meetingRoomImageUrl, setMeetingRoomImageUrl] = useState<string>("")
  const [meetingRoomCheckInUrl, setMeetingRoomCheckInUrl] = useState<string>("")
  const [meetingRoomCheckOutUrl, setMeetingRoomCheckOutUrl] =
    useState<string>("")
  const [creatorIconUrl, setCreatorIconUrl] = useState<string>("")
  const navigate = useNavigate()

  // 当日の会議予定一覧を取得
  const fetchSignageMeetingRoomSchedules = useCallback(
    async (meetingRoomId: number) => {
      try {
        setLoading(true)
        const response = await fetchMeetingRoomSchedulesRequest({
          meeting_room_id: meetingRoomId,
        })
        if (response.status === 200 && response.data) {
          setMeetingRoomData(response.data)
          // 会議室画像をS3からダウンロードする
          const resultMeetingRoomImageUrl = await downloadAreaOrMeetingRoomFile(
            response.data.meeting_room_image,
            response.data.company_id,
            response.data.branch_id,
            response.data.floor_id
          )
          resultMeetingRoomImageUrl
            ? setMeetingRoomImageUrl(resultMeetingRoomImageUrl)
            : ""

          // QRコード表示に必要な情報をURLに変換
          const floorId = response.data.floor_id
          const meetingRoomId = response.data.id
          const meetingRoomName = response.data.meeting_room_name
          setMeetingRoomCheckInUrl(
            `${window.location.origin}/mobile/check-in?floor_id=${floorId}&id=${meetingRoomId}&type=MeetingRoom&name=${meetingRoomName}`
          )
          setMeetingRoomCheckOutUrl(
            `${window.location.origin}/mobile/check-out?floor_id=${floorId}&id=${meetingRoomId}&type=MeetingRoom&name=${meetingRoomName}`
          )
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("error")
        if (error.response.status === 403) {
          navigate("/signage/home")
          throw error
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // 会議予定の詳細を取得
  const fetchMeetingRoomScheduleDetail = useCallback(
    async (scheduleId: number) => {
      try {
        setLoading(true)
        const response = await fetchMeetingRoomScheduleDetailRequest({
          schedule_id: scheduleId,
        })
        if (response.status === 200 && response.data) {
          setMeetingRoomDetailSchedule(response.data)
          // 従業員画像をS3からダウンロードする
          const scheduleCreator = response.data.schedule_creator
          if (response.data && scheduleCreator.icon) {
            const resultIconUrl = await downloadIcon(
              scheduleCreator.company_id,
              scheduleCreator.id,
              scheduleCreator.icon
            )
            resultIconUrl ? setCreatorIconUrl(resultIconUrl) : ""
          }
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("error")
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // エリア・会議室画像のダウンロード
  const downloadAreaOrMeetingRoomFile = async (
    img: string,
    companyId: number,
    branchId: number,
    floorId: number
  ) => {
    if (!img) return

    const result = await Storage.get(img, {
      level: "public",
      customPrefix: {
        public: `company${companyId}/layouts/branch${branchId}/floor${floorId}/images/`,
      },
    })
    return result
  }

  // 次の会議予定を取得
  const fetchNextMeetingRoomSchedule = useCallback(
    async (meetingRoomId: number) => {
      try {
        setLoading(true)
        const response = await fetchNextMeetingRoomSchedulesRequest({
          meeting_room_id: meetingRoomId,
        })
        if (response.status === 200 && response.data) {
          handleReservableMinutesSet(response.data)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log("error")
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // プルダウンに表示させるための延長可能な時間の配列を作成
  const handleReservableMinutesSet = (reservableTime: number) => {
    if (reservableTime >= 15 && reservableTime < 30) {
      setNextReservableMinutes([15])
    } else if (reservableTime >= 30 && reservableTime < 45) {
      setNextReservableMinutes([15, 30])
    } else if (reservableTime >= 45 && reservableTime < 60) {
      setNextReservableMinutes([15, 30, 45])
    } else if (reservableTime >= 60) {
      setNextReservableMinutes([15, 30, 45, 60])
    }
  }

  // 会議を延長する
  const handleExtendMeetingRoomSchedule = async (
    extend_time: number,
    schedule_id: number
  ) => {
    try {
      const { error } = await extendMeetingRoomScheduleRequest({
        extend_time: extend_time,
        schedule_id: schedule_id,
      })
      if (!error) {
        localStorage.setItem("alertContent", "会議の延長が完了しました")
        window.location.reload()
      } else {
        // setCheckInError(true)
        // setCheckInErrorMessage("チェックインに失敗しました")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    loading,
    meetingRoomData,
    meetingRoomDetailSchedule,
    fetchSignageMeetingRoomSchedules,
    fetchMeetingRoomScheduleDetail,
    fetchNextMeetingRoomSchedule,
    meetingRoomImageUrl,
    meetingRoomCheckInUrl,
    meetingRoomCheckOutUrl,
    creatorIconUrl,
    nextReservableMinutes,
    handleExtendMeetingRoomSchedule,
  }
}
