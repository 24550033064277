import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Grid, Typography, styled, Button } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useScheduleCheckIn } from "../../../../models/mobile/useSchedule/checkIn"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { Loading } from "../../../public/Loading"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

interface Props {
  companyRelations: CompanyRelationsType
}

// QRで読み取った座席・会議室がすでに予約済みだった場合に表示するコンポーネント
export const CheckInAlreadyReserved = ({ companyRelations }: Props) => {
  const navigate = useNavigate()
  const {
    branchName,
    floorNumber,
    checkInType,
    checkInTarget,
    setCheckInStateFromQueryString,
    dataLoading,
  } = useScheduleCheckIn()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  useEffect(() => {
    if (companyRelations) {
      setCheckInStateFromQueryString(companyRelations)
    }
  }, [companyRelations])

  const checkInTypeText = checkInType === "Seat" ? "座席" : "会議室"

  return (
    <>
      {dataLoading ? (
        <Loading type="content" loading={dataLoading} />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <CustomTypography variant="h5">{branchName}</CustomTypography>
            <CustomTypography variant="h5">{floorNumber}F</CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            {checkInType === "Seat" && (
              <CustomTypography variant="h4">
                {translations.Desk}：{checkInTarget}
              </CustomTypography>
            )}
            {checkInType === "MeetingRoom" && (
              <CustomTypography variant="h4">
                {translations.RoomName}：{checkInTarget}
              </CustomTypography>
            )}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <CustomTypography align="center">
              この{checkInTypeText}は予約済みです。
              <br /> 他の{checkInTypeText}をお探しください。
            </CustomTypography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/layouts")}
            >
              <Typography fontWeight="bold">
                {translations.DeskStatus}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => navigate("/mobile/home")}
            >
              <Typography fontWeight="bold">{translations.Home}</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}
