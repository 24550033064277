const ja = {
  YourEmail: "メールアドレスをご入力ください。",
  SendYouCode: "確認のコードを送信いたします。",
  Email: "メールアドレス（必須）",
  Confirm: "パスワードを変更する",
  loginPage: "ログイン画面に戻る",
  resetEmail:
    "ご入力いただいたメールアドレス宛にパスワード再設定のご案内のご案内をお送りしました。",
  accessURL:
    "メールに記載のURLにアクセスし、パスワードの再設定を行ってください。",
  FailedEmail: "メールの送信に失敗しました",
  Required: "必須になります",
  email: "メールアドレスの形式で入力してください",
}
export default ja
