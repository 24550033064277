import React, { useState, useEffect } from "react"

import { Grid, styled } from "@mui/material"

import { CompanyInfoRegistrationInfoForm } from "../../../components/company/auth/CompanyRegistrationInfoForm"
import { Loading } from "../../../components/public/Loading"
import { useCompany } from "../../../models/company/useCompany"

export const CompanyInfoRegistration = () => {
  const companyInfoObject = useCompany()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    companyInfoObject.fetchCompany().then(() => {
      setLoading(false)
    })
  }, [])

  const GridWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
      height: "100vh",
    },
  }))

  return (
    <>
      {loading ? (
        <Loading type="fullScreen" />
      ) : (
        <GridWrapper container>
          <Grid item md={6} sm={6}>
            <img
              src={`${process.env.PUBLIC_URL}/images/layouts/corporation_top.jpg`}
              alt="ログイン画面"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Grid>
          <Grid item md={6}>
            <CompanyInfoRegistrationInfoForm {...companyInfoObject} />
          </Grid>
        </GridWrapper>
      )}
    </>
  )
}
