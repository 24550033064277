import { useState } from "react"

import { fetchMeetingRoomsRequest } from "../../../api/company/meetingRoomsRequest"
import { Tag } from "../../../types/tag"
import { FetchMeetingRoomsRequestType, FetchMeetingRoomType } from "./type"

export const useMeetingRooms = () => {
  const [meetingRooms, setMeetingRooms] = useState<FetchMeetingRoomType[]>([])
  const [tags, setTags] = useState<Tag[]>([])

  const fetchMeetingRooms = async (params: FetchMeetingRoomsRequestType) => {
    try {
      const response = await fetchMeetingRoomsRequest(params)
      if (response.data && response.status === 200) {
        setMeetingRooms(response.data.meeting_rooms)
        setTags(response.data.tags)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    meetingRooms,
    tags,
    fetchMeetingRooms,
  }
}
