const ja = {
  checkInSeat: "チェックインが必要な座席は現在ありません",
  reservation: "の予約をしています。",
  PleaseCheckIn: "チェックインしてください。",
  checkedIn: "にチェックイン中。",
  Today: "",
  Until: "",
  PleaseCheckOut: "利用を終了する際はチェックアウトしてください。",
  WouldYouCheckIn: "チェックインしますがよろしいですか?",
  WouldYouCheckOut: "チェックアウトしますがよろしいですか？",
  CurrentDesk: "現在の座席は",
  Hour: "時",
  Minutes: "分",
  ReservedUntil: "まで予約されています",
  WouldYouAgain: "チェックアウト後、再度利用しますか？",
  UseAgain: "再度利用する",
  beginningOfUse: "利用開始時に",
  from: "から",
  currentTime: "予約時間を現在時刻へ変更する",
  CANCEL: "キャンセル",
  in: "確定",
  out: "確定",
  ReservationEnd: "予約終了",
  SuccessUpdated: "予約時間が変更されました",
  Floor: "",
  setTimeReservation: "何時まで利用しますか？",
  ConfirmReservation: "予約する",
}
export default ja
