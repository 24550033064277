import { Chart, registerables } from "chart.js"
import { subYears, compareAsc } from "date-fns"
import ja from "date-fns/locale/ja"

import React, { useEffect, useState } from "react"
import { Bar, Pie } from "react-chartjs-2"

import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
  CircularProgress,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { GreenArrowIcon } from "../../../components/public/GreenArrowIcon"
import { theme } from "../../../config/theme"
import { useAmStartTime } from "../../../models/company/useAmStartTime"
import { useContractPlan } from "../../../models/company/useContractPlan"
import { usePmEndTime } from "../../../models/company/usePmEndTime"
import {
  useReportMeetingRoom,
  useReportMeetingRoomState,
  useSearchReportMeetingRoom,
} from "../../../models/company/useReport"
import { useWorkHour } from "../../../models/company/useWorkHour"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import {
  CONTENT_PAPER_MIN_HEIGHT,
  REPORT_FLOAT_DIGIT,
} from "../../../utils/const"
import { dateStatus } from "../../../utils/date"
import {
  average,
  max,
  median,
  min,
  roundBySpecificDigit,
} from "../../../utils/number"

Chart.register(...registerables)

const boxStyle = {
  margin: 3,
}

const barOptions = {
  indexAxis: "y" as const,
  scales: {
    x: {
      min: 0,
      max: 100,
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const dayOfWeekBarOptions = (isTotal: boolean) => {
  return {
    scales: {
      x: {
        stacked: isTotal, // 合計のグラフだけ合体した状態で表示されるようにする
      },
      y: {
        min: 0,
        max: 100,
        stacked: isTotal,
      },
    },
  }
}
// 会議室別稼働率の軸オプション
const barUtilizationRateParMeetingRoomOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  scales: {
    x: {
      ticks: { autoSkip: false },
      stacked: true,
      categoryPercentage: 0.6,
      barPercentage: 0.4,
    },
    y: {
      min: 0,
      max: 100,
      stacked: true,
    },
  },
}

const ReportCustomSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(30),
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: "#EEEEEE",
}))

// 中央値とか最大値とかのプルダウン
const ReportDisplayDataTypeSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(20),
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: "#EEEEEE",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: theme.spacing(5),
  fontSize: theme.spacing(2),
  marginTop: theme.spacing(1),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
}))

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 300,
    },
  },
}

// 色コード
const BLUE_CODE = "rgb(75, 192, 192)"
const YELLOW_CODE = "rgb(255, 205, 86)"
const GRAY_CODE = "rgb(201, 203, 207)"

type DayOfWeekDisplayValueType = "total" | "average" | "median" | "max" | "min"

type DayOfWeekGraphDataType = {
  checkInCount: number
  reservedCount: number
  unusedCount: number
  dayOfWeek: number
  targetedAt: string
}

type DayOfWeekGraphRateDataType = {
  checkInRate: number
  reservedRate: number
  unusedRate: number
  dayOfWeek: number
  targetedAt: string
}

export const CompanyReportMeetingRoomPage = () => {
  const today = new Date()

  // 選択期間開始日の処理
  const { fetchContractPlan, contractPlan } = useContractPlan()
  // 利用開始日
  const registrationStartDate = new Date(
    contractPlan.active_contract.start_date
  )
  // 現時刻から一年前
  const oneYearBefore = subYears(new Date(), 1)
  // 比較して選択できる開始期間にどちらを当てるか決める
  const minDate =
    compareAsc(oneYearBefore, registrationStartDate) < 0
      ? registrationStartDate
      : oneYearBefore

  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const yesterDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )

  useEffect(() => {
    fetchContractPlan()
  }, [])

  const {
    reportMeetingRoomRelation,
    setReportRelations,
    hourGraphReportMeetingRoomRelation,
    dayOfWeekGraphReportMeetingRoomRelation,
    setHourGraphReportRelations,
    setDayOfWeekGraphReportRelations,
    fetchReportRelations,
    parMeetingRoomGraphReportMeetingRoomRelation,
    setParMeetingRoomGraphReportRelations,
  } = useReportMeetingRoom()
  const { amStartTime } = useAmStartTime()
  const { pmEndTime } = usePmEndTime()
  const { workHour, fetchWorkHour } = useWorkHour()
  const { searchReportRelations } = useSearchReportMeetingRoom()
  const { formatDate } = dateStatus()
  const { companyRelations } = useCompanyRelations()
  const pullDownObject = useReportMeetingRoomState(companyRelations)
  const hourGraphPullDownObject = useReportMeetingRoomState(companyRelations)
  const dayOfWeekGraphPullDownObject =
    useReportMeetingRoomState(companyRelations)
  const parMeetingRoomGraphPullDownObject =
    useReportMeetingRoomState(companyRelations)
  const [loading, setLoading] = useState<boolean>(true)
  const [dayOfWeekGraphDisplayDataType, setDayOfWeekGraphDisplayDataType] =
    useState<DayOfWeekDisplayValueType>("total")
  const pullDownBranchLoading =
    hourGraphPullDownObject.pullDownLoading ||
    dayOfWeekGraphPullDownObject.pullDownLoading ||
    pullDownObject.pullDownLoading ||
    parMeetingRoomGraphPullDownObject.pullDownLoading

  // ------------------円グラフ-----------------------------
  // 会議室の利用状況 [稼働率,空予約率,非稼働率]
  const meetingRoomStatus: number[] = []

  // 就業時間の取得
  let workStartTime, workEndTime
  let WorkHours = 0
  if (amStartTime && pmEndTime) {
    workStartTime = new Date(workHour.am_start_time).getHours()
    workEndTime = new Date(workHour.pm_end_time).getHours()

    // 勤務時間 = 終了時間 - 開始時間
    WorkHours = workEndTime - workStartTime
  }

  // 会議室利用状況 円グラフ データ作成
  let all_meeting_room_count = 0
  let checked_in_meeting_room_count = 0
  let reserved_meeting_room_count = 0

  reportMeetingRoomRelation.report_meeting_rooms.map((report) => {
    all_meeting_room_count += WorkHours
    if (report.checked_in_meeting_room_count > WorkHours) {
      checked_in_meeting_room_count += WorkHours
    } else {
      checked_in_meeting_room_count += report.checked_in_meeting_room_count
    }

    if (report.reserved_meeting_room_count > WorkHours) {
      reserved_meeting_room_count += WorkHours
    } else {
      reserved_meeting_room_count += report.reserved_meeting_room_count
    }
  })

  const checked = (checked_in_meeting_room_count / all_meeting_room_count) * 100
  const reserved = (reserved_meeting_room_count / all_meeting_room_count) * 100

  const checkedRounded = roundBySpecificDigit(checked, REPORT_FLOAT_DIGIT)
  const reservedRounded = roundBySpecificDigit(reserved, REPORT_FLOAT_DIGIT)

  meetingRoomStatus.push(
    checkedRounded,
    reservedRounded,
    100 - checkedRounded - reservedRounded // 100 - 少数第1位の計算だと手元では誤差を確認できなかった
  )

  // 会議室利用状況
  const pieGraphData = {
    labels: ["稼働率(%)", "空予約(%)", "非稼働率(%)"],
    datasets: [
      {
        // データの値
        data: meetingRoomStatus,
        // グラフの背景色
        backgroundColor: [BLUE_CODE, YELLOW_CODE, GRAY_CODE],
      },
    ],
  }

  const handlePieGraphSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(pullDownObject.startDate),
        end_date: formatDate(pullDownObject.endDate),
        layout_ids: pullDownObject.layoutIds,
        meeting_room_ids: pullDownObject.selectedMeetingRoomIds,
        tag_ids: pullDownObject.selectedTagIds,
        capacities: pullDownObject.selectedCapacities,
      },
      setReportRelations,
      hourGraphPullDownObject.setSearchLoading
    )
  }

  // ------------------円グラフ-----------------------------

  // ------------------会議室別稼働率------------------------
  // 稼働数
  const utilRateParRoomCheckedInCount: number[] = []
  // 空予約数
  const utilRateParRoomReservedCount: number[] = []
  // 非稼働数
  const utilRateParRoomUnusedCount: number[] = []
  // 稼働率
  const utilRateParRoomCheckedInRate: number[] = []
  // 空予約
  const utilRateParRoomReservedRate: number[] = []
  // 非稼働率
  const utilRateParRoomUnusedRate: number[] = []

  const utilizationRateParMeetingRoomLabels: string[] = []
  const utilizationRateParMeetingRoomIds: number[] = []

  parMeetingRoomGraphReportMeetingRoomRelation.report_meeting_rooms.map(
    (report_meeting_room) => {
      const status = report_meeting_room.available_status
      let unusedCount = 0
      let reservedCount = 0
      let checkedInCount = 0
      for (let i = 0; i < status.length; i++) {
        // 各時間の稼働状況を数える(それ以外、8-20時)
        switch (status.charAt(i)) {
          case "1": // 非稼働
            unusedCount++
            break
          case "2": // 空予約
            reservedCount++
            break
          case "3": // 稼働
            checkedInCount++
            break
        }
      }

      const meetingRoomIndex = utilizationRateParMeetingRoomIds.findIndex(
        (item) => item == report_meeting_room.meeting_room_id
      )

      // 検索処理等で再取得した時に会議室のデータがすでにある場合は追加、ない場合は新規に追加するようにする
      if (meetingRoomIndex === -1) {
        utilizationRateParMeetingRoomLabels.push(
          report_meeting_room.meeting_room.meeting_room_name
        )
        utilizationRateParMeetingRoomIds.push(
          report_meeting_room.meeting_room_id
        )
        utilRateParRoomUnusedCount.push(unusedCount)
        utilRateParRoomCheckedInCount.push(reservedCount)
        utilRateParRoomReservedCount.push(checkedInCount)
      } else {
        utilRateParRoomUnusedCount[meetingRoomIndex] += unusedCount
        utilRateParRoomCheckedInCount[meetingRoomIndex] += reservedCount
        utilRateParRoomReservedCount[meetingRoomIndex] += checkedInCount
      }
    }
  )

  // 一旦合計だけ先に算出して割合は会議室別にまとめて計算する
  for (let i = 0; i < utilizationRateParMeetingRoomLabels.length; i++) {
    const allCount =
      utilRateParRoomCheckedInCount[i] +
      utilRateParRoomReservedCount[i] +
      utilRateParRoomUnusedCount[i]
    const checkedRounded = roundBySpecificDigit(
      (utilRateParRoomCheckedInCount[i] / allCount) * 100,
      REPORT_FLOAT_DIGIT
    )
    const reservedRounded = roundBySpecificDigit(
      (utilRateParRoomReservedCount[i] / allCount) * 100,
      REPORT_FLOAT_DIGIT
    )
    utilRateParRoomCheckedInRate[i] = checkedRounded
    utilRateParRoomReservedRate[i] = reservedRounded
    utilRateParRoomUnusedRate[i] = 100 - checkedRounded - reservedRounded
  }

  const xUtilizationRateParMeetingRoomBarGraphData = {
    // x 軸のラベル
    labels: utilizationRateParMeetingRoomLabels,
    datasets: [
      {
        label: "稼働率(%)",
        // データの値
        data: utilRateParRoomReservedRate,
        // グラフの背景色
        backgroundColor: "rgb(75, 192, 192)",
        // グラフの枠線の色
        borderColor: "rgb(75, 192, 192)",
        // グラフの枠線の太さ
        borderWidth: 1,
        // グラフの棒の太さ
        barThickness: 30,
      },
      {
        label: "空予約率(%)",
        // データの値
        data: utilRateParRoomCheckedInRate,
        // グラフの背景色
        backgroundColor: "rgb(255, 205, 86)",
        // グラフの枠線の色
        borderColor: "rgb(255, 205, 86)",
        // グラフの枠線の太さ
        borderWidth: 1,
        // グラフの棒の太さ
        barThickness: 30,
      },
      {
        label: "非稼働率(%)",
        // データの値
        data: utilRateParRoomUnusedRate,
        // グラフの背景色
        backgroundColor: "rgb(201, 203, 207)",
        // グラフの枠線の色
        borderColor: "rgb(201, 203, 207)",
        // グラフの枠線の太さ
        borderWidth: 1,
        // グラフの棒の太さ
        barThickness: 30,
      },
    ],
  }

  const BAR_WIDTH = 45
  // Canvasの最小幅
  const MIN_CANVAS_WIDTH = 650

  const numberOfRooms = utilizationRateParMeetingRoomLabels.length
  // Canvasの幅を計算：データ数×各棒の幅と最小幅のうち、大きい方
  const canvasWidth = Math.max(numberOfRooms * BAR_WIDTH, MIN_CANVAS_WIDTH)

  const handleParMeetingRoomGraphSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(parMeetingRoomGraphPullDownObject.startDate),
        end_date: formatDate(parMeetingRoomGraphPullDownObject.endDate),
        layout_ids: parMeetingRoomGraphPullDownObject.layoutIds,
        meeting_room_ids:
          parMeetingRoomGraphPullDownObject.selectedMeetingRoomIds,
        tag_ids: parMeetingRoomGraphPullDownObject.selectedTagIds,
        capacities: parMeetingRoomGraphPullDownObject.selectedCapacities,
      },
      setParMeetingRoomGraphReportRelations,
      parMeetingRoomGraphPullDownObject.setSearchLoading
    )
  }
  const maxBarCount = Math.max(
    utilRateParRoomReservedRate.length,
    utilRateParRoomCheckedInRate.length,
    utilRateParRoomUnusedRate.length
  )

  // 棒の幅
  const barWidth = 30
  // 棒と棒の間のスペース
  const barGap = 10
  // コンテナの幅を計算します (棒の数 * (棒の幅 + スペース) )
  const containerWidth = maxBarCount * (barWidth + barGap)

  // ------------------会議室別稼働率------------------------

  // 時間別会議室利用状況グラフのラベル
  const besideBarGraphDataLabels: string[] = [
    "それ以外",
    "8時",
    "9時",
    "10時",
    "11時",
    "12時",
    "13時",
    "14時",
    "15時",
    "16時",
    "17時",
    "18時",
    "19時",
    "20時",
  ]
  // 稼働率
  const checkedInRate: number[] = []
  // 空予約
  const reservedRate: number[] = []
  // 非稼働率
  const unusedRate: number[] = []

  // 時間別会議室利用状況 棒グラフ
  const besideBarGraphData = {
    // x 軸のラベル
    labels: besideBarGraphDataLabels,
    datasets: [
      {
        label: "稼働率(%)",
        // データの値
        data: checkedInRate,
        // グラフの背景色
        backgroundColor: "rgb(75, 192, 192)",
        // グラフの枠線の色
        borderColor: "rgb(75, 192, 192)",
        // グラフの枠線の太さ
        borderWidth: 1,
      },
      {
        label: "空予約率(%)",
        // データの値
        data: reservedRate,
        // グラフの背景色
        backgroundColor: "rgb(255, 205, 86)",
        // グラフの枠線の色
        borderColor: "rgb(255, 205, 86)",
        // グラフの枠線の太さ
        borderWidth: 1,
      },
      {
        label: "非稼働率(%)",
        // データの値
        data: unusedRate,
        // グラフの背景色
        backgroundColor: "rgb(201, 203, 207)",
        // グラフの枠線の色
        borderColor: "rgb(201, 203, 207)",
        // グラフの枠線の太さ
        borderWidth: 1,
      },
    ],
  }

  const checkedInCount: number[] = Array(13).fill(0)
  const reservedCount: number[] = Array(13).fill(0)
  const unusedCount: number[] = Array(13).fill(0)
  let otherCheckedIn = 0
  let otherReserved = 0
  let otherUnused = 0
  hourGraphReportMeetingRoomRelation.report_meeting_rooms.map((report) => {
    const status = report.available_status
    for (let i = 0; i < status.length; i++) {
      // 各時間の稼働状況を数える(それ以外、8-20時)
      switch (status.charAt(i)) {
        case "1": // 非稼働
          7 < i && i < 21 ? unusedCount[i - 8]++ : otherUnused++
          break
        case "2": // 空予約
          7 < i && i < 21 ? reservedCount[i - 8]++ : otherReserved++
          break
        case "3": // 稼働
          7 < i && i < 21 ? checkedInCount[i - 8]++ : otherCheckedIn++
          break
      }
    }
  })
  // それ以外の時間(0-7時、21-23時)を配列に挿入する
  unusedCount.unshift(otherUnused)
  reservedCount.unshift(otherReserved)
  checkedInCount.unshift(otherCheckedIn)

  // 時間別会議室利用状況(それ以外、8-20時)
  for (let i = 0; i < besideBarGraphDataLabels.length; i++) {
    const allCount = unusedCount[i] + reservedCount[i] + checkedInCount[i]
    const checkedRounded = roundBySpecificDigit(
      (checkedInCount[i] / allCount) * 100,
      REPORT_FLOAT_DIGIT
    )
    const reservedRounded = roundBySpecificDigit(
      (reservedCount[i] / allCount) * 100,
      REPORT_FLOAT_DIGIT
    )
    checkedInRate[i] = checkedRounded
    reservedRate[i] = reservedRounded
    unusedRate[i] = 100 - checkedRounded - reservedRounded
  }

  const handleHourGraphSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(hourGraphPullDownObject.startDate),
        end_date: formatDate(hourGraphPullDownObject.endDate),
        layout_ids: hourGraphPullDownObject.layoutIds,
        meeting_room_ids: hourGraphPullDownObject.selectedMeetingRoomIds,
        tag_ids: hourGraphPullDownObject.selectedTagIds,
        capacities: hourGraphPullDownObject.selectedCapacities,
      },
      setHourGraphReportRelations,
      hourGraphPullDownObject.setSearchLoading
    )
  }

  // 曜日別会議室利用状況 棒グラフ
  const checkedInRateDayOfWeek: number[] = Array(7).fill(0)
  const reservedRateDayOfWeek: number[] = Array(7).fill(0)
  const unusedRateDayOfWeek: number[] = Array(7).fill(0)

  // 合計値の場合は曜日別に各凡例ごとの値を計算し、最後に割合にする
  const checkedInTotalCountDayOfWeek: number[] = Array(7).fill(0)
  const reservedTotalCountDayOfWeek: number[] = Array(7).fill(0)
  const unusedTotalCountDayOfWeek: number[] = Array(7).fill(0)

  // 中央値や平均値等は一度日付別にデータ集計し(targetedAtDataList)、割合に変換する(targetedAtRateList)
  // 例: 平均値の場合、取得したデータのうち月曜日の稼働率が日付順に、[30%, 40%, 50%]だったら、平均は30 + 40 + 50 /3 = 40%と出るようにする
  const targetedAtList: string[] = []
  const targetedAtDataList: DayOfWeekGraphDataType[] = []
  const targetedAtRateList: DayOfWeekGraphRateDataType[] = []

  // railsのwdayメソッドでdayOfWeekを設定しているので日曜始まり
  const dayOfWeekGraphDataLabels = [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ]

  // 曜日別 出社数・席利用
  const barDayOfWeekGraphData = {
    // x 軸のラベル
    labels: dayOfWeekGraphDataLabels,
    datasets: [
      {
        label: "稼働率(%)",
        // データの値
        data: checkedInRateDayOfWeek,
        // グラフの背景色
        backgroundColor: "rgb(75, 192, 192)",
        // グラフの枠線の色
        borderColor: "rgb(75, 192, 192)",
        // グラフの枠線の太さ
        borderWidth: 1,
        barThickness: dayOfWeekGraphDisplayDataType === "total" ? 25 : 15,
      },
      {
        label: "空予約率(%)",
        // データの値
        data: reservedRateDayOfWeek,
        // グラフの背景色
        backgroundColor: "rgb(255, 205, 86)",
        // グラフの枠線の色
        borderColor: "rgb(255, 205, 86)",
        // グラフの枠線の太さ
        borderWidth: 1,
        barThickness: dayOfWeekGraphDisplayDataType === "total" ? 25 : 15,
      },
      {
        label: "非稼働率(%)",
        // データの値
        data: unusedRateDayOfWeek,
        // グラフの背景色
        backgroundColor: "rgb(201, 203, 207)",
        // グラフの枠線の色
        borderColor: "rgb(201, 203, 207)",
        // グラフの枠線の太さ
        borderWidth: 1,
        barThickness: dayOfWeekGraphDisplayDataType === "total" ? 25 : 15,
      },
    ],
  }

  dayOfWeekGraphReportMeetingRoomRelation.report_meeting_rooms.map((report) => {
    const status = report.available_status
    const dayOfWeek = report.day_of_week
    let checkInCount = 0
    let reservedCount = 0
    let unusedCount = 0
    for (let i = 0; i < status.length; i++) {
      // 各時間の稼働状況を数えて曜日別に配列に追加していく
      switch (status.charAt(i)) {
        case "1": // 非稼働
          unusedCount++
          unusedTotalCountDayOfWeek[dayOfWeek]++
          break
        case "2": // 空予約
          reservedCount++
          reservedTotalCountDayOfWeek[dayOfWeek]++
          break
        case "3": // 稼働
          checkInCount++
          checkedInTotalCountDayOfWeek[dayOfWeek]++
          break
      }
    }

    // 平均値・中央値・最大値・最小値の計算用に日付別に曜日とカウントを保存
    // targeted_atが一致しているかどうかだけみており、日付順に並べないのでソート等はしなくていい認識
    const targetedAtIndex = targetedAtList.findIndex(
      (item) => item == report.targeted_at
    )
    if (targetedAtIndex === -1) {
      targetedAtList.push(report.targeted_at)
      targetedAtDataList.push({
        targetedAt: report.targeted_at,
        dayOfWeek: report.day_of_week,
        checkInCount: checkInCount,
        reservedCount: reservedCount,
        unusedCount: unusedCount,
      })
    } else {
      targetedAtDataList[targetedAtIndex].checkInCount += checkInCount
      targetedAtDataList[targetedAtIndex].reservedCount += reservedCount
      targetedAtDataList[targetedAtIndex].unusedCount += unusedCount
    }
  })

  // 日付別に保存したカウントを元に日付別に割合を計算する
  for (let i = 0; i < targetedAtList.length; i++) {
    const targetedAtIndex = targetedAtRateList.findIndex(
      (item) => item.targetedAt == targetedAtList[i]
    )
    const allCount =
      targetedAtDataList[i].checkInCount +
      targetedAtDataList[i].reservedCount +
      targetedAtDataList[i].unusedCount
    const checkInRounded = roundBySpecificDigit(
      (targetedAtDataList[i].checkInCount / allCount) * 100,
      REPORT_FLOAT_DIGIT
    )
    const reservedRounded = roundBySpecificDigit(
      (targetedAtDataList[i].reservedCount / allCount) * 100,
      REPORT_FLOAT_DIGIT
    )
    if (targetedAtIndex === -1) {
      targetedAtRateList.push({
        targetedAt: targetedAtDataList[i].targetedAt,
        dayOfWeek: targetedAtDataList[i].dayOfWeek,
        checkInRate: checkInRounded,
        reservedRate: reservedRounded,
        unusedRate: 100 - checkInRounded - reservedRounded,
      })
    } else {
      targetedAtRateList[i].checkInRate = checkInRounded
      targetedAtRateList[i].reservedRate = reservedRounded
      targetedAtRateList[i].unusedRate = 100 - checkInRounded - reservedRounded
    }
  }

  for (let i = 0; i < dayOfWeekGraphDataLabels.length; i++) {
    if (dayOfWeekGraphDisplayDataType === "total") {
      // 合計値の場合は曜日別のカウント値から割合を計算する
      const allCount =
        unusedTotalCountDayOfWeek[i] +
        reservedTotalCountDayOfWeek[i] +
        checkedInTotalCountDayOfWeek[i]
      const checkedRounded = roundBySpecificDigit(
        (checkedInTotalCountDayOfWeek[i] / allCount) * 100,
        REPORT_FLOAT_DIGIT
      )
      const reservedRounded = roundBySpecificDigit(
        (reservedTotalCountDayOfWeek[i] / allCount) * 100,
        REPORT_FLOAT_DIGIT
      )
      checkedInRateDayOfWeek[i] = checkedRounded
      reservedRateDayOfWeek[i] = reservedRounded
      unusedRateDayOfWeek[i] = 100 - checkedRounded - reservedRounded
    } else {
      // 日付別に集計したデータを曜日ごとにまとめなおす
      const dataDayOfWeek = targetedAtRateList.filter((data) => {
        return data.dayOfWeek === i
      })
      const checkInRateValues = dataDayOfWeek.map((data) => {
        return data.checkInRate
      })
      const reservedRateValues = dataDayOfWeek.map((data) => {
        return data.reservedRate
      })
      const unusedRateValues = dataDayOfWeek.map((data) => {
        return data.unusedRate
      })

      // 各種計算式はutils/number.tsにて
      if (dayOfWeekGraphDisplayDataType === "max") {
        checkedInRateDayOfWeek[i] = roundBySpecificDigit(
          max(checkInRateValues),
          REPORT_FLOAT_DIGIT
        )
        reservedRateDayOfWeek[i] = roundBySpecificDigit(
          max(reservedRateValues),
          REPORT_FLOAT_DIGIT
        )
        unusedRateDayOfWeek[i] = roundBySpecificDigit(
          max(unusedRateValues),
          REPORT_FLOAT_DIGIT
        )
      }

      if (dayOfWeekGraphDisplayDataType === "min") {
        checkedInRateDayOfWeek[i] = roundBySpecificDigit(
          min(checkInRateValues),
          REPORT_FLOAT_DIGIT
        )
        reservedRateDayOfWeek[i] = roundBySpecificDigit(
          min(reservedRateValues),
          REPORT_FLOAT_DIGIT
        )
        unusedRateDayOfWeek[i] = roundBySpecificDigit(
          min(unusedRateValues),
          REPORT_FLOAT_DIGIT
        )
      }

      if (dayOfWeekGraphDisplayDataType === "average") {
        checkedInRateDayOfWeek[i] = roundBySpecificDigit(
          average(checkInRateValues),
          REPORT_FLOAT_DIGIT
        )
        reservedRateDayOfWeek[i] = roundBySpecificDigit(
          average(reservedRateValues),
          REPORT_FLOAT_DIGIT
        )
        unusedRateDayOfWeek[i] = roundBySpecificDigit(
          average(unusedRateValues),
          REPORT_FLOAT_DIGIT
        )
      }

      if (dayOfWeekGraphDisplayDataType === "median") {
        checkedInRateDayOfWeek[i] = roundBySpecificDigit(
          median(checkInRateValues),
          REPORT_FLOAT_DIGIT
        )
        reservedRateDayOfWeek[i] = roundBySpecificDigit(
          median(reservedRateValues),
          REPORT_FLOAT_DIGIT
        )
        unusedRateDayOfWeek[i] = roundBySpecificDigit(
          median(unusedRateValues),
          REPORT_FLOAT_DIGIT
        )
      }
    }
  }

  const handleDayOfWeekGraphSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(dayOfWeekGraphPullDownObject.startDate),
        end_date: formatDate(dayOfWeekGraphPullDownObject.endDate),
        layout_ids: dayOfWeekGraphPullDownObject.layoutIds,
        meeting_room_ids: dayOfWeekGraphPullDownObject.selectedMeetingRoomIds,
        tag_ids: dayOfWeekGraphPullDownObject.selectedTagIds,
        capacities: dayOfWeekGraphPullDownObject.selectedCapacities,
      },
      setDayOfWeekGraphReportRelations,
      dayOfWeekGraphPullDownObject.setSearchLoading
    )
  }

  const handleDayOfWeekGraphDisplayDataTypeChange = (
    e: SelectChangeEvent<unknown>
  ) => {
    setDayOfWeekGraphDisplayDataType(
      e.target.value as DayOfWeekDisplayValueType
    )
  }

  useEffect(() => {
    fetchReportRelations({
      start_date: formatDate(defaultStartDate),
      end_date: formatDate(yesterDay),
    }).then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const planState = async () => {
      await Promise.all([fetchWorkHour()])
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <ContentCompanyPaper>
      {loading || pullDownBranchLoading ? (
        <Box
          sx={{
            display: "flex",
            height: CONTENT_PAPER_MIN_HEIGHT, // 高さ指定しないと中央にローディングアイコンが配置できない
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={64} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", height: "100%" }}>
          <Grid container spacing={3}>
            <Grid container>
              {/* TODO: それぞれのGrid内部にグラフを実装する */}
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  全体稼働率
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={pullDownObject.startDate}
                          onChange={pullDownObject.handleStartDateChange}
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={pullDownObject.endDate}
                          onChange={pullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {hourGraphPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handlePieGraphSearch}
                        disabled={pullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>

                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <Grid item sm={12}>
                    <ReportCustomSelect
                      name="branch_ids"
                      value={pullDownObject.selectedBranchIds}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) => pullDownObject.changeBranchId(e)}
                      renderValue={() =>
                        pullDownObject.selectedBranchNames.join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="拠点" disabled>
                        拠点
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            pullDownObject.selectedBranchIds.length ===
                            companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              pullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                  {pullDownObject.selectedBranchIds.length > 0 && (
                    <Grid item sm={12}>
                      <ReportCustomSelect
                        name="floor_ids"
                        value={pullDownObject.selectedFloorIds}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        multiple
                        IconComponent={GreenArrowIcon}
                        onChange={(e) => pullDownObject.changeFloorId(e)}
                        renderValue={() =>
                          pullDownObject.selectedFloorNames.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="フロア" disabled>
                          フロア
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              pullDownObject.selectedFloorIds.length ===
                              pullDownObject.selectedBranchesFloors.length
                            }
                          />
                          <ListItemText primary={"全てのフロア"} />
                        </MenuItem>
                        {pullDownObject.selectedBranchesFloors.map((floor) => (
                          <MenuItem key={floor.id} value={floor.id}>
                            <Checkbox
                              checked={
                                pullDownObject.selectedFloorIds.indexOf(
                                  Number(floor.id)
                                ) > -1
                              }
                            />
                            <ListItemText
                              sx={{
                                "& .MuiListItemText-primary": {
                                  display: "flex",
                                  alignItems: "center",
                                },
                              }}
                              primary={
                                <>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      maxWidth: "10rem",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {floor.branch_name}
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      maxWidth: "5rem",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {floor.floor_name}
                                  </span>
                                </>
                              }
                            />
                          </MenuItem>
                        ))}
                      </ReportCustomSelect>
                    </Grid>
                  )}
                  {pullDownObject.searchMeetingRoomLoading ? (
                    <Box
                      width={theme.spacing(30)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={32} />
                    </Box>
                  ) : (
                    <>
                      {pullDownObject.selectedFloorIds.length > 0 &&
                        pullDownObject.meetingRooms.length > 0 && (
                          <>
                            <ReportCustomSelect
                              name="meeting_room_ids"
                              value={pullDownObject.selectedMeetingRoomIds}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                pullDownObject.changeMeetingRoomId(e)
                              }
                              renderValue={() =>
                                pullDownObject.selectedMeetingRoomNames.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="会議室" disabled>
                                会議室
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    pullDownObject.selectedMeetingRoomIds
                                      .length ===
                                    pullDownObject.meetingRooms.length
                                  }
                                />
                                <ListItemText primary={"全ての会議室"} />
                              </MenuItem>
                              {pullDownObject.meetingRooms.map(
                                (meetingRoom) => (
                                  <MenuItem
                                    key={meetingRoom.id}
                                    value={meetingRoom.id}
                                  >
                                    <Checkbox
                                      checked={
                                        pullDownObject.selectedMeetingRoomIds.indexOf(
                                          Number(meetingRoom.id)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      sx={{
                                        "& .MuiListItemText-primary": {
                                          display: "flex",
                                          alignItems: "center",
                                        },
                                      }}
                                      primary={
                                        <>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "7rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.branch.branch_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "5rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.floor.floor_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            {meetingRoom.meeting_room_name}
                                          </span>
                                        </>
                                      }
                                    />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>

                            <ReportCustomSelect
                              name="tag_ids"
                              value={pullDownObject.selectedTagIds}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginBottom: 0,
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) => pullDownObject.changeTagId(e)}
                              renderValue={() =>
                                pullDownObject.selectedTagNames.join(", ")
                              }
                              inputProps={{
                                style: {
                                  paddingBottom: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="タグ" disabled>
                                タグ
                              </MenuItem>

                              {pullDownObject.tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                  <Checkbox
                                    checked={
                                      pullDownObject.selectedTagIds.indexOf(
                                        Number(tag.id)
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={`#${tag.tag_name}`} />
                                </MenuItem>
                              ))}
                            </ReportCustomSelect>

                            <ReportCustomSelect
                              name="capacity"
                              value={pullDownObject.selectedCapacities}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginTop: theme.spacing(1),
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) => pullDownObject.changeCapacity(e)}
                              renderValue={() =>
                                pullDownObject.selectedCapacitiesString.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="定員数" disabled>
                                定員数
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    pullDownObject.selectedCapacities.length ===
                                    pullDownObject.meetingRoomCapacities.length
                                  }
                                />
                                <ListItemText primary={"全ての定員数"} />
                              </MenuItem>
                              {pullDownObject.meetingRoomCapacities.map(
                                (capacity, index) => (
                                  <MenuItem key={index} value={capacity}>
                                    <Checkbox
                                      checked={
                                        pullDownObject.selectedCapacities.indexOf(
                                          Number(capacity)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={`${capacity}人`} />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>
                          </>
                        )}
                    </>
                  )}
                </Grid>
                <Pie data={pieGraphData} />
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  会議室別稼働率
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={parMeetingRoomGraphPullDownObject.startDate}
                          onChange={
                            parMeetingRoomGraphPullDownObject.handleStartDateChange
                          }
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={parMeetingRoomGraphPullDownObject.endDate}
                          onChange={
                            parMeetingRoomGraphPullDownObject.handleEndDateChange
                          }
                          minDate={minDate}
                          maxDate={parMeetingRoomGraphPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {parMeetingRoomGraphPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleParMeetingRoomGraphSearch}
                        disabled={parMeetingRoomGraphPullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <Grid item sm={12}>
                    <ReportCustomSelect
                      name="branch_ids"
                      value={
                        parMeetingRoomGraphPullDownObject.selectedBranchIds
                      }
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) =>
                        parMeetingRoomGraphPullDownObject.changeBranchId(e)
                      }
                      renderValue={() =>
                        parMeetingRoomGraphPullDownObject.selectedBranchNames.join(
                          ", "
                        )
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="拠点" disabled>
                        拠点
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            parMeetingRoomGraphPullDownObject.selectedBranchIds
                              .length === companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              parMeetingRoomGraphPullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                  {parMeetingRoomGraphPullDownObject.selectedBranchIds.length >
                    0 && (
                    <Grid item sm={12}>
                      <ReportCustomSelect
                        name="floor_ids"
                        value={
                          parMeetingRoomGraphPullDownObject.selectedFloorIds
                        }
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        multiple
                        IconComponent={GreenArrowIcon}
                        onChange={(e) =>
                          parMeetingRoomGraphPullDownObject.changeFloorId(e)
                        }
                        renderValue={() =>
                          parMeetingRoomGraphPullDownObject.selectedFloorNames.join(
                            ", "
                          )
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="フロア" disabled>
                          フロア
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              parMeetingRoomGraphPullDownObject.selectedFloorIds
                                .length ===
                              parMeetingRoomGraphPullDownObject
                                .selectedBranchesFloors.length
                            }
                          />
                          <ListItemText primary={"全てのフロア"} />
                        </MenuItem>
                        {parMeetingRoomGraphPullDownObject.selectedBranchesFloors.map(
                          (floor) => (
                            <MenuItem key={floor.id} value={floor.id}>
                              <Checkbox
                                checked={
                                  parMeetingRoomGraphPullDownObject.selectedFloorIds.indexOf(
                                    Number(floor.id)
                                  ) > -1
                                }
                              />
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                                primary={
                                  <>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "10rem",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {floor.branch_name}
                                    </span>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "5rem",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {floor.floor_name}
                                    </span>
                                  </>
                                }
                              />
                            </MenuItem>
                          )
                        )}
                      </ReportCustomSelect>
                    </Grid>
                  )}
                  {parMeetingRoomGraphPullDownObject.searchMeetingRoomLoading ? (
                    <Box
                      width={theme.spacing(30)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={32} />
                    </Box>
                  ) : (
                    <>
                      {parMeetingRoomGraphPullDownObject.selectedFloorIds
                        .length > 0 &&
                        parMeetingRoomGraphPullDownObject.meetingRooms.length >
                          0 && (
                          <>
                            <ReportCustomSelect
                              name="meeting_room_ids"
                              value={
                                parMeetingRoomGraphPullDownObject.selectedMeetingRoomIds
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                parMeetingRoomGraphPullDownObject.changeMeetingRoomId(
                                  e
                                )
                              }
                              renderValue={() =>
                                parMeetingRoomGraphPullDownObject.selectedMeetingRoomNames.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="会議室" disabled>
                                会議室
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    parMeetingRoomGraphPullDownObject
                                      .selectedMeetingRoomIds.length ===
                                    parMeetingRoomGraphPullDownObject
                                      .meetingRooms.length
                                  }
                                />
                                <ListItemText primary={"全ての会議室"} />
                              </MenuItem>
                              {parMeetingRoomGraphPullDownObject.meetingRooms.map(
                                (meetingRoom) => (
                                  <MenuItem
                                    key={meetingRoom.id}
                                    value={meetingRoom.id}
                                  >
                                    <Checkbox
                                      checked={
                                        parMeetingRoomGraphPullDownObject.selectedMeetingRoomIds.indexOf(
                                          Number(meetingRoom.id)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      sx={{
                                        "& .MuiListItemText-primary": {
                                          display: "flex",
                                          alignItems: "center",
                                        },
                                      }}
                                      primary={
                                        <>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "7rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.branch.branch_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "5rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.floor.floor_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            {meetingRoom.meeting_room_name}
                                          </span>
                                        </>
                                      }
                                    />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>

                            <ReportCustomSelect
                              name="tag_ids"
                              value={
                                parMeetingRoomGraphPullDownObject.selectedTagIds
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginBottom: 0,
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                parMeetingRoomGraphPullDownObject.changeTagId(e)
                              }
                              renderValue={() =>
                                parMeetingRoomGraphPullDownObject.selectedTagNames.join(
                                  ", "
                                )
                              }
                              inputProps={{
                                style: {
                                  paddingBottom: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="タグ" disabled>
                                タグ
                              </MenuItem>
                              {/* <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            parMeetingRoomGraphPullDownObject.selectedTagIds
                              .length ===
                            parMeetingRoomGraphPullDownObject.tags.length
                          }
                        />
                        <ListItemText primary={"全てのタグ"} />
                      </MenuItem> */}
                              {parMeetingRoomGraphPullDownObject.tags.map(
                                (tag) => (
                                  <MenuItem key={tag.id} value={tag.id}>
                                    <Checkbox
                                      checked={
                                        parMeetingRoomGraphPullDownObject.selectedTagIds.indexOf(
                                          Number(tag.id)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={`#${tag.tag_name}`}
                                    />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>

                            <ReportCustomSelect
                              name="capacity"
                              value={
                                parMeetingRoomGraphPullDownObject.selectedCapacities
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginTop: theme.spacing(1),
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                parMeetingRoomGraphPullDownObject.changeCapacity(
                                  e
                                )
                              }
                              renderValue={() =>
                                parMeetingRoomGraphPullDownObject.selectedCapacitiesString.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="定員数" disabled>
                                定員数
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    parMeetingRoomGraphPullDownObject
                                      .selectedCapacities.length ===
                                    parMeetingRoomGraphPullDownObject
                                      .meetingRoomCapacities.length
                                  }
                                />
                                <ListItemText primary={"全ての定員数"} />
                              </MenuItem>
                              {parMeetingRoomGraphPullDownObject.meetingRoomCapacities.map(
                                (capacity, index) => (
                                  <MenuItem key={index} value={capacity}>
                                    <Checkbox
                                      checked={
                                        parMeetingRoomGraphPullDownObject.selectedCapacities.indexOf(
                                          Number(capacity)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={`${capacity}人`} />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>
                          </>
                        )}
                    </>
                  )}
                </Grid>
                <div
                  style={{
                    display: "flex",
                    overflowX: "scroll",
                    width: "100%",
                    height: "400px",
                  }}
                >
                  <div
                    style={{
                      minWidth: `${
                        utilizationRateParMeetingRoomLabels.length * 50
                      }px`,
                    }}
                  >
                    <Bar
                      options={barUtilizationRateParMeetingRoomOptions}
                      data={xUtilizationRateParMeetingRoomBarGraphData}
                    />
                  </div>
                </div>
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  時間帯別会議室利用状況
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={hourGraphPullDownObject.startDate}
                          onChange={
                            hourGraphPullDownObject.handleStartDateChange
                          }
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={hourGraphPullDownObject.endDate}
                          onChange={hourGraphPullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={hourGraphPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {hourGraphPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleHourGraphSearch}
                        disabled={hourGraphPullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>

                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <Grid item sm={12}>
                    <ReportCustomSelect
                      name="branch_ids"
                      value={hourGraphPullDownObject.selectedBranchIds}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) =>
                        hourGraphPullDownObject.changeBranchId(e)
                      }
                      renderValue={() =>
                        hourGraphPullDownObject.selectedBranchNames.join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="拠点" disabled>
                        拠点
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            hourGraphPullDownObject.selectedBranchIds.length ===
                            companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              hourGraphPullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                  {hourGraphPullDownObject.selectedBranchIds.length > 0 && (
                    <Grid item sm={12}>
                      <ReportCustomSelect
                        name="floor_ids"
                        value={hourGraphPullDownObject.selectedFloorIds}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        multiple
                        IconComponent={GreenArrowIcon}
                        onChange={(e) =>
                          hourGraphPullDownObject.changeFloorId(e)
                        }
                        renderValue={() =>
                          hourGraphPullDownObject.selectedFloorNames.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="フロア" disabled>
                          フロア
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              hourGraphPullDownObject.selectedFloorIds
                                .length ===
                              hourGraphPullDownObject.selectedBranchesFloors
                                .length
                            }
                          />
                          <ListItemText primary={"全てのフロア"} />
                        </MenuItem>
                        {hourGraphPullDownObject.selectedBranchesFloors.map(
                          (floor) => (
                            <MenuItem key={floor.id} value={floor.id}>
                              <Checkbox
                                checked={
                                  hourGraphPullDownObject.selectedFloorIds.indexOf(
                                    Number(floor.id)
                                  ) > -1
                                }
                              />
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                                primary={
                                  <>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "10rem",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {floor.branch_name}
                                    </span>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "5rem",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {floor.floor_name}
                                    </span>
                                  </>
                                }
                              />
                            </MenuItem>
                          )
                        )}
                      </ReportCustomSelect>
                    </Grid>
                  )}
                  {hourGraphPullDownObject.searchMeetingRoomLoading ? (
                    <Box
                      width={theme.spacing(30)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={32} />
                    </Box>
                  ) : (
                    <>
                      {hourGraphPullDownObject.selectedFloorIds.length > 0 &&
                        hourGraphPullDownObject.meetingRooms.length > 0 && (
                          <>
                            <ReportCustomSelect
                              name="meeting_room_ids"
                              value={
                                hourGraphPullDownObject.selectedMeetingRoomIds
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                hourGraphPullDownObject.changeMeetingRoomId(e)
                              }
                              renderValue={() =>
                                hourGraphPullDownObject.selectedMeetingRoomNames.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="会議室" disabled>
                                会議室
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    hourGraphPullDownObject
                                      .selectedMeetingRoomIds.length ===
                                    hourGraphPullDownObject.meetingRooms.length
                                  }
                                />
                                <ListItemText primary={"全ての会議室"} />
                              </MenuItem>
                              {hourGraphPullDownObject.meetingRooms.map(
                                (meetingRoom) => (
                                  <MenuItem
                                    key={meetingRoom.id}
                                    value={meetingRoom.id}
                                  >
                                    <Checkbox
                                      checked={
                                        hourGraphPullDownObject.selectedMeetingRoomIds.indexOf(
                                          Number(meetingRoom.id)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      sx={{
                                        "& .MuiListItemText-primary": {
                                          display: "flex",
                                          alignItems: "center",
                                        },
                                      }}
                                      primary={
                                        <>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "7rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.branch.branch_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "5rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.floor.floor_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            {meetingRoom.meeting_room_name}
                                          </span>
                                        </>
                                      }
                                    />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>
                            <ReportCustomSelect
                              name="tag_ids"
                              value={hourGraphPullDownObject.selectedTagIds}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginBottom: 0,
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                hourGraphPullDownObject.changeTagId(e)
                              }
                              renderValue={() =>
                                hourGraphPullDownObject.selectedTagNames.join(
                                  ", "
                                )
                              }
                              inputProps={{
                                style: {
                                  paddingBottom: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="タグ" disabled>
                                タグ
                              </MenuItem>

                              {hourGraphPullDownObject.tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                  <Checkbox
                                    checked={
                                      hourGraphPullDownObject.selectedTagIds.indexOf(
                                        Number(tag.id)
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={`#${tag.tag_name}`} />
                                </MenuItem>
                              ))}
                            </ReportCustomSelect>

                            <ReportCustomSelect
                              name="capacity"
                              value={hourGraphPullDownObject.selectedCapacities}
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginTop: theme.spacing(1),
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                hourGraphPullDownObject.changeCapacity(e)
                              }
                              renderValue={() =>
                                hourGraphPullDownObject.selectedCapacitiesString.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="定員数" disabled>
                                定員数
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    hourGraphPullDownObject.selectedCapacities
                                      .length ===
                                    hourGraphPullDownObject
                                      .meetingRoomCapacities.length
                                  }
                                />
                                <ListItemText primary={"全ての定員数"} />
                              </MenuItem>
                              {hourGraphPullDownObject.meetingRoomCapacities.map(
                                (capacity, index) => (
                                  <MenuItem key={index} value={capacity}>
                                    <Checkbox
                                      checked={
                                        hourGraphPullDownObject.selectedCapacities.indexOf(
                                          Number(capacity)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={`${capacity}人`} />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>
                          </>
                        )}
                    </>
                  )}
                </Grid>

                <Bar options={barOptions} data={besideBarGraphData} />
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  曜日別会議室利用状況
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={dayOfWeekGraphPullDownObject.startDate}
                          onChange={
                            dayOfWeekGraphPullDownObject.handleStartDateChange
                          }
                          minDate={minDate}
                          maxDate={pullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={dayOfWeekGraphPullDownObject.endDate}
                          onChange={
                            dayOfWeekGraphPullDownObject.handleEndDateChange
                          }
                          minDate={minDate}
                          maxDate={dayOfWeekGraphPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {dayOfWeekGraphPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleDayOfWeekGraphSearch}
                        disabled={dayOfWeekGraphPullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>

                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <Grid item sm={12}>
                    <ReportCustomSelect
                      name="branch_ids"
                      value={dayOfWeekGraphPullDownObject.selectedBranchIds}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) =>
                        dayOfWeekGraphPullDownObject.changeBranchId(e)
                      }
                      renderValue={() =>
                        dayOfWeekGraphPullDownObject.selectedBranchNames.join(
                          ", "
                        )
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="拠点" disabled>
                        拠点
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            dayOfWeekGraphPullDownObject.selectedBranchIds
                              .length === companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              dayOfWeekGraphPullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                  {dayOfWeekGraphPullDownObject.selectedBranchIds.length >
                    0 && (
                    <Grid item sm={12}>
                      <ReportCustomSelect
                        name="floor_ids"
                        value={dayOfWeekGraphPullDownObject.selectedFloorIds}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        multiple
                        IconComponent={GreenArrowIcon}
                        onChange={(e) =>
                          dayOfWeekGraphPullDownObject.changeFloorId(e)
                        }
                        renderValue={() =>
                          dayOfWeekGraphPullDownObject.selectedFloorNames.join(
                            ", "
                          )
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="フロア" disabled>
                          フロア
                        </MenuItem>
                        <MenuItem key={0} value={0}>
                          <Checkbox
                            checked={
                              dayOfWeekGraphPullDownObject.selectedFloorIds
                                .length ===
                              dayOfWeekGraphPullDownObject
                                .selectedBranchesFloors.length
                            }
                          />
                          <ListItemText primary={"全てのフロア"} />
                        </MenuItem>
                        {dayOfWeekGraphPullDownObject.selectedBranchesFloors.map(
                          (floor) => (
                            <MenuItem key={floor.id} value={floor.id}>
                              <Checkbox
                                checked={
                                  dayOfWeekGraphPullDownObject.selectedFloorIds.indexOf(
                                    Number(floor.id)
                                  ) > -1
                                }
                              />
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    display: "flex",
                                    alignItems: "center",
                                  },
                                }}
                                primary={
                                  <>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "10rem",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {floor.branch_name}
                                    </span>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        maxWidth: "5rem",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {floor.floor_name}
                                    </span>
                                  </>
                                }
                              />
                            </MenuItem>
                          )
                        )}
                      </ReportCustomSelect>
                    </Grid>
                  )}
                  {dayOfWeekGraphPullDownObject.searchMeetingRoomLoading ? (
                    <Box
                      width={theme.spacing(30)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size={32} />
                    </Box>
                  ) : (
                    <>
                      {dayOfWeekGraphPullDownObject.selectedFloorIds.length >
                        0 &&
                        dayOfWeekGraphPullDownObject.meetingRooms.length >
                          0 && (
                          <>
                            <ReportCustomSelect
                              name="meeting_room_ids"
                              value={
                                dayOfWeekGraphPullDownObject.selectedMeetingRoomIds
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                dayOfWeekGraphPullDownObject.changeMeetingRoomId(
                                  e
                                )
                              }
                              renderValue={() =>
                                dayOfWeekGraphPullDownObject.selectedMeetingRoomNames.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="会議室" disabled>
                                会議室
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    dayOfWeekGraphPullDownObject
                                      .selectedMeetingRoomIds.length ===
                                    dayOfWeekGraphPullDownObject.meetingRooms
                                      .length
                                  }
                                />
                                <ListItemText primary={"全ての会議室"} />
                              </MenuItem>
                              {dayOfWeekGraphPullDownObject.meetingRooms.map(
                                (meetingRoom) => (
                                  <MenuItem
                                    key={meetingRoom.id}
                                    value={meetingRoom.id}
                                  >
                                    <Checkbox
                                      checked={
                                        dayOfWeekGraphPullDownObject.selectedMeetingRoomIds.indexOf(
                                          Number(meetingRoom.id)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      sx={{
                                        "& .MuiListItemText-primary": {
                                          display: "flex",
                                          alignItems: "center",
                                        },
                                      }}
                                      primary={
                                        <>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "7rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.branch.branch_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                              maxWidth: "5rem",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {meetingRoom.floor.floor_name}-
                                          </span>
                                          <span
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            {meetingRoom.meeting_room_name}
                                          </span>
                                        </>
                                      }
                                    />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>
                            <ReportCustomSelect
                              name="tag_ids"
                              value={
                                dayOfWeekGraphPullDownObject.selectedTagIds
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginBottom: 0,
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                dayOfWeekGraphPullDownObject.changeTagId(e)
                              }
                              renderValue={() =>
                                dayOfWeekGraphPullDownObject.selectedTagNames.join(
                                  ", "
                                )
                              }
                              inputProps={{
                                style: {
                                  paddingBottom: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="タグ" disabled>
                                タグ
                              </MenuItem>

                              {dayOfWeekGraphPullDownObject.tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                  <Checkbox
                                    checked={
                                      dayOfWeekGraphPullDownObject.selectedTagIds.indexOf(
                                        Number(tag.id)
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={`#${tag.tag_name}`} />
                                </MenuItem>
                              ))}
                            </ReportCustomSelect>

                            <ReportCustomSelect
                              name="capacity"
                              value={
                                dayOfWeekGraphPullDownObject.selectedCapacities
                              }
                              sx={{
                                background: "rgba(112,112,112,0.05)",
                                marginTop: theme.spacing(1),
                              }}
                              multiple
                              IconComponent={GreenArrowIcon}
                              onChange={(e) =>
                                dayOfWeekGraphPullDownObject.changeCapacity(e)
                              }
                              renderValue={() =>
                                dayOfWeekGraphPullDownObject.selectedCapacitiesString.join(
                                  ", "
                                )
                              }
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="定員数" disabled>
                                定員数
                              </MenuItem>
                              <MenuItem key={0} value={0}>
                                <Checkbox
                                  checked={
                                    dayOfWeekGraphPullDownObject
                                      .selectedCapacities.length ===
                                    dayOfWeekGraphPullDownObject
                                      .meetingRoomCapacities.length
                                  }
                                />
                                <ListItemText primary={"全ての定員数"} />
                              </MenuItem>
                              {dayOfWeekGraphPullDownObject.meetingRoomCapacities.map(
                                (capacity, index) => (
                                  <MenuItem key={index} value={capacity}>
                                    <Checkbox
                                      checked={
                                        dayOfWeekGraphPullDownObject.selectedCapacities.indexOf(
                                          Number(capacity)
                                        ) > -1
                                      }
                                    />
                                    <ListItemText primary={`${capacity}人`} />
                                  </MenuItem>
                                )
                              )}
                            </ReportCustomSelect>
                          </>
                        )}
                    </>
                  )}
                </Grid>
                <Bar
                  options={dayOfWeekBarOptions(
                    dayOfWeekGraphDisplayDataType === "total"
                  )}
                  data={barDayOfWeekGraphData}
                />
                <Box display="flex" justifyContent="flex-end" mt="1rem">
                  <ReportDisplayDataTypeSelect
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    IconComponent={GreenArrowIcon}
                    value={dayOfWeekGraphDisplayDataType}
                    onChange={handleDayOfWeekGraphDisplayDataTypeChange}
                  >
                    <MenuItem value="total">合計</MenuItem>
                    <MenuItem value="average">平均値</MenuItem>
                    <MenuItem value="max">最大値</MenuItem>
                    <MenuItem value="median">中央値</MenuItem>
                    <MenuItem value="min">最小値</MenuItem>
                  </ReportDisplayDataTypeSelect>
                </Box>
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}></Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ContentCompanyPaper>
  )
}
