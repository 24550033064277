import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { SignageMeetingRoomMain } from "../../../components/signage/meetingRoom/Main"
import { MeetingRoomImage } from "../../../components/signage/meetingRoom/MeetingRoomImage"
import { theme } from "../../../config/theme"
import { useSignageMeetingRoom } from "../../../models/signage/useMeetingRoom"

export const SignageMeetingRoomPage = () => {
  const signageObject = useSignageMeetingRoom()
  const urlParams = useParams<{ meeting_room_id: string }>()
  const meetingRoomId = Number(urlParams.meeting_room_id)

  useEffect(() => {
    signageObject.fetchSignageMeetingRoomSchedules(meetingRoomId)
  }, [])
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        container
        sm={6}
        sx={{
          position: "relative",
          paddingRight: theme.spacing(1.5),
        }}
      >
        <MeetingRoomImage {...signageObject} />
      </Grid>
      <Grid
        item
        sm={6}
        sx={{
          height: "100%",
          backgroundColor: "#fff",
        }}
      >
        <SignageMeetingRoomMain {...signageObject} />
      </Grid>
    </Grid>
  )
}
