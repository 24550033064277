const en = {
  // スケジュール新規登録
  scheduleTitle: "Add schedule title",
  StartDate: "Start date",
  StartTime: "Start time",
  EndTime: "End time",
  EndDate: "End date",
  Allday: "All day",
  RepeatSettings: "Repeat settings",
  Weekly: "Weekly",
  Monthly: "Monthly",
  // 曜日
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",

  // 繰り返し予定
  Day: "Day",
  DayOfWeek: "Day of Week",
  day: "th",
  everyMonths: "every months",
  everyWeeks: "every weeks",
  private: "Private",
  first: "1st",
  second: "2nd",
  third: "3rd",
  fourth: "4th",
  last: "last",
  // "Day  every N months  1st, 2nd, 3rd, [dd]th",
  // "Day of Week  every N months    1st, 2nd 3rd Nth   W[Sun Mon Tue Wed Thu Fri Sat] ",
  Public: "Public",
  Private: "Private",
  Description: "Description",
  Cancel: "Cancel",
  ConfirmReservation: "Confirm Reservation",
  Send: "Send",
  sendGuests: "Would you like to send an email invitation to your guests?",
  edit: "Back to edit",
  notSend: "Do not send",
  of: "of",
  every: "every",
  months: "months",
  The: "The",
  Confirm: "Confirm",
  WouldYouConfirmReservation: "Would you like to confirm your reservation?",

  // エラーメッセージ
  SchedulePast:
    "In WORK AGILE, it is not possible to create a schedule in the past.",
  AllDayReset:
    "If you want to change the all-day event, please reset the selected seat/meeting room once.",
  RepeatReset:
    "If you want to change the repeat setting or change the repeat date, please reset the selected seat/meeting room once.",
  SpanDays:
    "In WORK AGILE, it is not possible to create a schedule that spans days.",
  OutsideBusinessHours:
    "In WORK AGILE, it is not possible to create a schedule outside of business hours.",
  StartDateReset:
    "If you want to change the start date, please reset the selected seat/meeting room once.",
  StartTimeReset:
    "If you want to change the start time, please reset the selected seat/meeting room once.",
  EndDateReset:
    "If you want to change the end date, please reset the selected seat/meeting room once.",
  EndTimeReset:
    "If you want to change the end time, please reset the selected seat/meeting room once.",
  backend_specific_error:
    "There is already a desk reserved at the same time, are you sure you want to update it?",
  seat_already_reserved: "This desk is reserved for another user. ",
  room_already_reserved: "This space is reserved by another user. ",
}
export default en
