import React, { useState } from "react"

import SearchIcon from "@mui/icons-material/Search"
import {
  Box,
  Checkbox,
  CheckboxProps,
  SvgIcon,
  Modal,
  styled,
  Tooltip,
  Typography,
} from "@mui/material"

import { theme } from "../../../../../config/theme"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import { useSearchEmployee } from "../../../../../models/employee/useEmployeeSearch"
import enTranslations from "../../../../../translations/employeeStatus/employeeStatusSearch/en"
import jaTranslations from "../../../../../translations/employeeStatus/employeeStatusSearch/ja"
import { AlertError } from "../../../../public/AlertError"
import { EmployeeSearchModal } from "../../../common/EmployeeSearchModal"

const CustomTypography = styled(Typography)(() => ({
  fontFamily: "Noto Sans",
}))

const SearchTargetNumber = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  backgroundColor: "#E8F8F5",
  width: theme.spacing(3.25),
  height: theme.spacing(3.25),
  color: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
}))

const SearchEmployeeList = styled(Box)(({ theme }) => ({
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

const EmployeeSearchModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(125),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
}))

const UncheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      border: "1px solid #22BA9D",
      backgroundColor: "white",
    }}
  />
)

const CheckedIcon = () => (
  <Box
    sx={{
      width: 16,
      height: 16,
      borderRadius: "6px",
      backgroundColor: "#22BA9D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <SvgIcon
      sx={{
        color: "white",
        fontSize: "1.2rem",
      }}
    >
      <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z" />
    </SvgIcon>
  </Box>
)

const CustomCheckbox: React.FC<CheckboxProps> = (props) => (
  <Checkbox icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} {...props} />
)

type EmployeeLayoutSearchType = "layout" | "status"

interface Props {
  type: EmployeeLayoutSearchType
  handleEmployeeSearch: (targetEmployeeId: number) => void
  userNotExists: boolean
  userNotExistsMessage: string
  setUserNotExists: React.Dispatch<React.SetStateAction<boolean>>
}

// 検索対象者一覧
export const EmployeeLayoutSearch = ({
  type,
  handleEmployeeSearch,
  userNotExists,
  userNotExistsMessage,
  setUserNotExists,
}: Props) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false)
  const employeeSearchObject = useSearchEmployee()

  const handleOpen = () => {
    setIsSearchModalOpen(true)
  }

  const handleClose = () => {
    setIsSearchModalOpen(false)
  }
  const handleFixAndClose = () => {
    employeeSearchObject.handleEmployeeFixed()
    setIsSearchModalOpen(false)
  }

  const tooltipText = () => {
    switch (type) {
      case "layout":
        return translations.FindLocation
      case "status":
        return translations.search
    }
  }

  const searchIcon = (employeeId: number) => {
    switch (type) {
      case "layout":
        return (
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/seat.svg`}
            onClick={() => handleEmployeeSearch(employeeId)}
            style={{
              cursor: "pointer",
            }}
          />
        )
      case "status":
        return (
          <SearchIcon
            color="inherit"
            fontSize="small"
            onClick={() => handleEmployeeSearch(employeeId)}
            sx={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: "#22ba9d",
            }}
          />
        )
    }
  }

  // 検索対象者が増えたときに溢れないようにするmaxHight。
  const MAX_EMPLOYEE_LIST_HEIGHT = () => {
    switch (type) {
      case "layout":
        return "650px"
      case "status":
        return "475px"
    }
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <Box display="flex" width="100%" alignItems="center" marginBottom="20px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <SearchIcon
            sx={{
              fontSize: "25px",
              fontWeight: "bold",
              color: "#22ba9d",
            }}
          />
        </Box>
        <Box>
          <CustomTypography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#22ba9d",
              lineHeight: "19px",
              letterSpacing: "0.7px",
              marginRight: "10px",
            }}
          >
            {translations.FindMembers}
          </CustomTypography>
        </Box>
        <Box>
          <SearchTargetNumber>
            {employeeSearchObject.selectedEmployees.length}
          </SearchTargetNumber>
        </Box>
      </Box>
      <SearchEmployeeList maxHeight={MAX_EMPLOYEE_LIST_HEIGHT()}>
        {employeeSearchObject.selectedEmployees.map((employee) => {
          return (
            <Box
              key={employee.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
                marginTop: theme.spacing(0.5),
                height: theme.spacing(5.25),
              }}
            >
              <Box display="flex" alignItems="center">
                <Box>
                  {type === "layout" && (
                    <CustomCheckbox
                      sx={{
                        color: "#22ba9d",
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box display="flex">
                  <CustomTypography
                    sx={{
                      fontSize: "12px",
                      color: "#454545",
                      lineHeight: "17px",
                      marginRight: "10px",
                    }}
                  >
                    {employee.last_name}
                  </CustomTypography>
                  <CustomTypography
                    sx={{
                      fontSize: "12px",
                      color: "#454545",
                      lineHeight: "17px",
                    }}
                  >
                    {employee.first_name}
                  </CustomTypography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Box>
                    <Box display="flex" alignItems="center" marginRight="7px">
                      <Tooltip
                        title={tooltipText()}
                        placement="bottom"
                        disableInteractive
                      >
                        {searchIcon(employee.id)}
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                      onClick={() =>
                        employeeSearchObject.deletedSelectedEmployee(
                          employee.id
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })}
      </SearchEmployeeList>
      <Box
        ml="auto"
        pb="1.5rem"
        display="flex"
        alignItems="top"
        justifyContent="center"
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/plus.svg`}
          style={{
            cursor: "pointer",
          }}
          onClick={handleOpen}
        />
        <Modal open={isSearchModalOpen} onClose={handleClose} disableAutoFocus>
          <EmployeeSearchModalOverlay>
            <EmployeeSearchModal
              {...employeeSearchObject}
              setIsSearchModalOpen={setIsSearchModalOpen}
              handleClose={handleClose}
            />
          </EmployeeSearchModalOverlay>
        </Modal>
        {userNotExists && (
          <AlertError
            isMessageOpen={userNotExists}
            alertMessage={userNotExistsMessage}
            closeMessage={() => setUserNotExists(false)}
          />
        )}
      </Box>
    </>
  )
}
