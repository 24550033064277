import { EmployeeListResponseType } from "../../models/company/useEmployee/type"
import { SearchEmployeeListRequestType } from "../../models/company/useEmployeeListSearch/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const loginCompanyEmployeesSearchRequest = async (
  params: SearchEmployeeListRequestType
): Promise<ApiResponse<EmployeeListResponseType[]>> => {
  try {
    const data = await api.get(
      "/company/employees/search_login_company_employees",
      {
        params: params,
      }
    )
    return data
  } catch (error: any) {
    return { error: error.response.data }
  }
}
