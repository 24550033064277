import React, { useState } from "react"

import { FloorType } from "../useBranch/type"
import { QrObjectType, ReservableType, SeatType } from "./type"

const defaultFloor = {
  id: 0,
  floor_name: "",
  floor_number: "",
  limited_attendance_rate: 0,
  restricted_seat_count: 0,
  maximum_available_count: 0,
  underground_flag: false,
  layouts: [],
  order_id: 0,
}

export const useQrCode = () => {
  const [selectedSeatList, setSelectedSeatList] = useState<QrObjectType[]>([]) // 選択されている座席
  const [selectedMeetingRoomList, setSelectedMeetingRoomList] = useState<
    QrObjectType[]
  >([]) // 選択されている会議室
  const [checkedMeetingRoomList, setCheckedMeetingRoomList] = useState<
    QrObjectType[]
  >([]) // モーダル上でチェックを入れた会議室
  const [floor, setFloor] = useState<FloorType>(defaultFloor)

  // 座席が選択されているかどうか
  const isSeatSelected = (seatId: number) => {
    return (
      selectedSeatList.filter((seat) => {
        return seat.id === seatId
      }).length > 0
    )
  }

  // 座席の選択
  const handleSeatSelect = (seatId: number, seatName: number) => {
    const seatObject = {
      id: seatId,
      floorId: floor.id,
      floorName: floor.floor_name,
      name: String(seatName),
      type: "Seat" as ReservableType,
      url: `${window.location.origin}/mobile/check-in?floor_id=${floor.id}&id=${seatId}&type=Seat&name=${seatName}`,
    }

    if (isSeatSelected(seatId)) {
      const newSelectedSeatList = selectedSeatList.filter((seat) => {
        return seat.id !== seatId
      })
      setSelectedSeatList(newSelectedSeatList)
    } else {
      selectedSeatList.push(seatObject)
      const uniqueSelectedSeatList = Array.from(
        new Map(selectedSeatList.map((seat) => [seat.id, seat])).values()
      )
      setSelectedSeatList(uniqueSelectedSeatList)
    }
  }

  // 座席全選択
  const handleAllSeatSelected = (
    e: React.ChangeEvent<HTMLInputElement>,
    seats: SeatType[]
  ) => {
    if (e.target.checked) {
      const newSelectedSeatList = seats.map((seat) => {
        return {
          id: seat.id as number,
          floorId: floor.id,
          floorName: floor.floor_name,
          name: String(seat.seat_name),
          type: "Seat" as ReservableType,
          url: `${window.location.origin}/mobile/check-in?floor_id=${floor.id}&id=${seat.id}&type=Seat&name=${seat.seat_name}`,
        }
      })
      setSelectedSeatList(newSelectedSeatList)
    } else {
      setSelectedSeatList([])
    }
  }

  // 会議室が選択されているかどうか
  const isMeetingRoomChecked = (meetingRoomId: number) => {
    return (
      checkedMeetingRoomList.filter((meetingRoom) => {
        return meetingRoom.id === meetingRoomId
      }).length > 0
    )
  }

  // 会議室にチェックを入れた時に選択状態にする
  const handleMeetingRoomSelected = (
    e: React.ChangeEvent<HTMLInputElement>,
    meetingRoomId: number,
    meetingRoomName: string
  ) => {
    const meetingRoomObject = {
      id: meetingRoomId,
      floorId: floor.id,
      floorName: floor.floor_name,
      name: meetingRoomName,
      type: "MeetingRoom" as ReservableType,
      url: `${window.location.origin}/mobile/check-in?floor_id=${floor.id}&id=${meetingRoomId}&type=MeetingRoom`,
    }
    if (e.target.checked) {
      checkedMeetingRoomList.push(meetingRoomObject)
      const uniqueMeetingRoomList = Array.from(
        new Map(
          checkedMeetingRoomList.map((meetingRoom) => [
            meetingRoom.id,
            meetingRoom,
          ])
        ).values()
      )
      setCheckedMeetingRoomList(uniqueMeetingRoomList)
    } else {
      const newSelectedMeetingRoomList = checkedMeetingRoomList.filter(
        (meetingRoom) => {
          return meetingRoom.id !== meetingRoomId
        }
      )
      setCheckedMeetingRoomList(newSelectedMeetingRoomList)
    }
  }

  // チェックを入れた会議室を確定してQRコードを表示
  const handleMeetingRoomFixed = () => {
    setSelectedMeetingRoomList(checkedMeetingRoomList)
  }

  // 選択された座席をフロア昇順かつ座席番号昇順にする
  const sortedSeatList = selectedSeatList.sort((previous, next) => {
    if (previous.floorId < next.floorId) return -1
    if (previous.floorId > next.floorId) return 1
    if (Number(previous.name) < Number(next.name)) return -1
    if (Number(previous.name) > Number(next.name)) return 1
    return 0
  })

  // 選択された会議室をフロア順にする
  const sortedMeetingRoomList = selectedMeetingRoomList.sort(
    (previous, next) => {
      if (previous.floorId < next.floorId) return -1
      if (previous.floorId > next.floorId) return 1
      return 0
    }
  )

  return {
    selectedMeetingRoomList,
    handleSeatSelect,
    handleAllSeatSelected,
    isSeatSelected,
    handleMeetingRoomSelected,
    isMeetingRoomChecked,
    handleMeetingRoomFixed,
    setSelectedSeatList,
    setSelectedMeetingRoomList,
    floor,
    setFloor,
    sortedSeatList,
    sortedMeetingRoomList,
  }
}
