import React, { useState, memo } from "react"

import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  styled,
  Button,
} from "@mui/material"

import {
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import { numberCheck } from "../../../../utils/numberCheck"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
})

const CustomSelectField = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
})

export const CustomTypography = styled(Typography)({
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 4px gray",
  },
})

const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
})

interface Props {
  handleAddSeats: (seatCount: number, seatType: number) => void
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
}

/* 
  レイアウトのサイドバーで座席登録を行う
*/
export const CompanyLayoutAddSeats: React.FC<Props> = memo(
  ({ handleAddSeats, setSidebarLayoutComponent, setSidebarTabType }: Props) => {
    const [seatCount, setSeatCount] = useState<number>(0)
    const [seatType, setSeatType] = useState<number>(0)

    return (
      <>
        <Box
          sx={{
            paddingBottom: "20px",
          }}
        >
          <CustomBox sx={{ marginBottom: "20px" }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/active-layout.svg`}
              height="20px"
              width="20px"
            />
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              席の追加
            </Typography>
          </CustomBox>
          <CustomBox sx={{ marginBottom: "20px" }}>
            <Typography sx={{ color: "#707070" }} variant="body2">
              追加する席の数を入力し、保存してください。
            </Typography>
          </CustomBox>
          <CustomBox sx={{ marginBottom: "20px" }}>
            <CustomTextField
              id="seat_count"
              name="seat_count"
              required
              variant="outlined"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 3 }}
              onChange={(e) => {
                setSeatCount(
                  numberCheck(Number(e.target.value))
                    ? Number(e.target.value)
                    : 0
                )
              }}
            />
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              席
            </Typography>
          </CustomBox>
          <CustomBox>
            <FormControl sx={{ width: "100%" }}>
              <CustomSelectField
                id="seat_type"
                name="seat_type"
                variant="outlined"
                margin="dense"
                value={seatType}
                fullWidth
                sx={{ backgroundColor: "#ffffff" }}
                onChange={(e) => setSeatType(Number(e.target.value))}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem disabled={true} key={3} value={3}>
                  座席種
                </MenuItem>
                <MenuItem key={0} value={0}>
                  指定席
                </MenuItem>
                <MenuItem key={1} value={1}>
                  自由席
                </MenuItem>
                <MenuItem key={2} value={2}>
                  利用不可
                </MenuItem>
              </CustomSelectField>
            </FormControl>
          </CustomBox>
        </Box>
        <CustomBox sx={{ marginBottom: "40px" }}>
          <Button
            variant="outlined"
            fullWidth
            disabled={!(seatCount > 0)}
            onClick={() => {
              setSidebarLayoutComponent("seatList")
              setSidebarTabType("seat")
              handleAddSeats(seatCount, seatType)
            }}
          >
            <Typography
              sx={{
                ml: 1,
                color: "#22BA9D",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              追加
            </Typography>
          </Button>
        </CustomBox>
        <CustomBox sx={{ justifyContent: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/icons/back.svg`}
            height="20px"
            width="20px"
          />
          <Typography
            sx={{
              ml: 1,
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
                boxShadow: "0 0 4px gray",
              },
            }}
            variant="subtitle1"
            onClick={() => {
              setSidebarTabType("seat")
              setSidebarLayoutComponent("seatList")
            }}
          >
            一覧へ戻る
          </Typography>
        </CustomBox>
      </>
    )
  }
)
