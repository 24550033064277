import React, { memo, ReactNode } from "react"

import { Box, Paper, styled } from "@mui/material"

const CustomPaper = styled(Paper)(({ theme }) => ({
  minHeight: "585px",
  marginTop: theme.spacing(1.875),
  padding: theme.spacing(1.875),
  borderRadius: theme.spacing(1.5),
  border: "none",
  boxShadow: "0px 1px 4px #15223214",
}))

export const LayoutPaper = memo(({ children }: { children: ReactNode }) => {
  return (
    <>
      <Box>
        <CustomPaper variant="outlined">{children}</CustomPaper>
      </Box>
    </>
  )
})
