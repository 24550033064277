import React, { memo, useState } from "react"

import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Typography,
  styled,
  Button,
  IconButton,
  Popover,
  Collapse,
} from "@mui/material"

import {
  SidebarLayoutComponent,
  SidebarTabType,
} from "../../../../models/company/useLayout"
import { AreaType, SeatType } from "../../../../models/company/useLayout/type"
import { numberCheck } from "../../../../utils/numberCheck"
import { FreeSeat } from "../../../public/FreeSeat"
import { ReservedSeat } from "../../../public/ReservedSeat"
import { UnavailableSeat } from "../../../public/UnavailableSeat"

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
    fontSize: "12px",
  },
})

export const CustomTypography = styled(Typography)({
  fontWeight: "bold",
  fontSize: "12px",
  "&:hover": {
    cursor: "pointer",
    boxShadow: "0 0 4px gray",
  },
})

interface Props {
  seats: SeatType[]
  setSeats: React.Dispatch<React.SetStateAction<SeatType[]>>
  areas: AreaType[]
  setArea: React.Dispatch<React.SetStateAction<AreaType>>
  setAreas: React.Dispatch<React.SetStateAction<AreaType[]>>
  setAreaToSeats: React.Dispatch<React.SetStateAction<SeatType[]>>
  setSidebarTabType: React.Dispatch<React.SetStateAction<SidebarTabType>>
  setSidebarLayoutComponent: React.Dispatch<
    React.SetStateAction<SidebarLayoutComponent>
  >
}

/*
  レイアウトのサイドバーに座席一覧を表示
*/
export const CompanyLayoutSeatList: React.FC<Props> = memo(
  ({
    seats,
    setSeats,
    areas,
    setArea,
    setAreas,
    setAreaToSeats,
    setSidebarTabType,
    setSidebarLayoutComponent,
  }: Props) => {
    // 座席タイプの表示
    const renderSeatObject = (seatType: number) => {
      switch (seatType) {
        case 0:
          return <ReservedSeat />
        case 1:
          return <FreeSeat />
        case 2:
          return <UnavailableSeat />
      }
    }

    const renderSeatType = (seatType: number) => {
      switch (seatType) {
        case 0:
          return "指定席"
        case 1:
          return "自由席"
        case 2:
          return "利用不可"
      }
    }
    //通常座席のPopoverを開く
    const [anchorElSeat, setAnchorElSeat] =
      React.useState<HTMLButtonElement | null>(null)
    const openSeat = Boolean(anchorElSeat)

    //エリア内座席のPopoverを開く
    const [anchorElArea, setAnchorElArea] =
      React.useState<HTMLButtonElement | null>(null)
    const openArea = Boolean(anchorElArea)

    //クリックした座席アイコンのidを保持
    const [seatId, setSeatId] = useState<number>(0)
    const [areaId, setAreaId] = useState<number>(0)

    const handleClickSeat = (event: React.MouseEvent<HTMLButtonElement>) => {
      //Popoverを開く
      setAnchorElSeat(event.currentTarget)
      //seatのidを取得
      setSeatId(Number(event.currentTarget.dataset.seatid))
    }
    const handleClickArea = (event: React.MouseEvent<HTMLButtonElement>) => {
      //Popoverを開く
      setAnchorElArea(event.currentTarget)
      //seatのidを取得
      setSeatId(Number(event.currentTarget.dataset.seatid))
      //areaのidを取得
      setAreaId(Number(event.currentTarget.dataset.areaid))
    }
    //Popoverを閉じる
    const handleClose = () => {
      setAnchorElSeat(null)
      setAnchorElArea(null)
    }

    return (
      <>
        <p
          style={{
            borderBottom: "1px solid #F1F1FF",
          }}
        ></p>
        <Box sx={{ marginBottom: "10px" }}>
          {seats.map((seat, id) => {
            return (
              <Grid
                key={seat.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
                container
                spacing={3}
              >
                <Grid item xs={12} sm={1.5} key={seat.id}>
                  <Checkbox
                    checked={seat.isCheck}
                    color="primary"
                    onChange={() => {
                      setSeats(() => {
                        return seats.map((oldSeat) => {
                          if (seat.id === oldSeat.id) {
                            return { ...seat, isCheck: !oldSeat.isCheck }
                          }
                          return oldSeat
                        })
                      })
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <CustomTextField
                    id="seat_name"
                    name="seat_name"
                    required
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 3 }}
                    value={seat.seat_name}
                    onChange={(e) => {
                      setSeats(() => {
                        return seats.map((oldSeat) => {
                          if (seat.id === oldSeat.id) {
                            return {
                              ...seat,
                              seat_name: numberCheck(Number(e.target.value))
                                ? Number(e.target.value)
                                : seat.seat_name,
                            }
                          }
                          return oldSeat
                        })
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <IconButton
                    data-seatid={seat.id}
                    onClick={handleClickSeat}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {renderSeatObject(seat.seat_type)}
                  </IconButton>
                  <Popover
                    open={openSeat}
                    anchorEl={anchorElSeat}
                    onClose={handleClose}
                    elevation={2}
                    anchorOrigin={{
                      vertical: 40,
                      horizontal: 40,
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box
                      sx={{
                        height: "56px",
                        width: "400px",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <ReservedSeat />
                      <Button
                        onClick={() => {
                          setSeats(() => {
                            return seats.map((newSeat) => {
                              if (seatId === newSeat.id) {
                                return {
                                  ...newSeat,
                                  seat_type: 0,
                                }
                              }
                              return newSeat
                            })
                          })
                          handleClose()
                        }}
                        sx={{
                          color: "#22BA9D",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        指定席
                      </Button>
                      <FreeSeat />
                      <Button
                        onClick={() => {
                          setSeats(() => {
                            return seats.map((newSeat) => {
                              if (seatId === newSeat.id) {
                                return {
                                  ...newSeat,
                                  seat_type: 1,
                                }
                              }
                              return newSeat
                            })
                          })
                          handleClose()
                        }}
                        sx={{
                          color: "#22BA9D",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        自由席
                      </Button>
                      <UnavailableSeat />
                      <Button
                        onClick={() => {
                          setSeats(() => {
                            return seats.map((newSeat) => {
                              if (seatId === newSeat.id) {
                                return {
                                  ...newSeat,
                                  seat_type: 2,
                                }
                              }
                              return newSeat
                            })
                          })
                          handleClose()
                        }}
                        sx={{
                          color: "#22BA9D",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        利用不可
                      </Button>
                    </Box>
                  </Popover>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography
                    sx={{
                      color: "#22BA9D",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {renderSeatType(seat.seat_type)}
                  </Typography>
                </Grid>
              </Grid>
            )
          })}
        </Box>
        {areas.map((area, id) => {
          return (
            <Box key={id} sx={{ margin: "10px 0px" }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
                container
                spacing={3}
                key={area.id}
              >
                <Grid item xs={12} sm={1.5} key={area.id}>
                  <Checkbox
                    checked={area.isCheck}
                    onChange={() => {
                      setAreas(() => {
                        return areas.map((oldArea) => {
                          if (area.id === oldArea.id) {
                            return { ...area, isCheck: !oldArea.isCheck }
                          }
                          return oldArea
                        })
                      })
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icons/toggle.svg`}
                    onClick={() => {
                      setAreas(() => {
                        return areas.map((oldArea) => {
                          if (area.id === oldArea.id) {
                            return { ...area, isExpanded: !oldArea.isExpanded }
                          }
                          return oldArea
                        })
                      })
                    }}
                    style={{
                      transform: area.isExpanded
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    id="area_name"
                    name="area_name"
                    required
                    variant="outlined"
                    value={area.area_name}
                    onChange={(e) => {
                      setAreas(() => {
                        return areas.map((oldArea) => {
                          if (area.id === oldArea.id) {
                            return { ...area, area_name: e.target.value }
                          }
                          return oldArea
                        })
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CustomTypography
                    variant="caption"
                    color="#22BA9D"
                    onClick={() => {
                      setSidebarLayoutComponent("areaForm")
                      setSidebarTabType("areaForm")
                      setArea(area)
                      setAreaToSeats(area.children)
                    }}
                  >
                    編集
                  </CustomTypography>
                </Grid>
                <Box key={id} sx={{ margin: "5px 0px" }}>
                  {area.children.map((seat, id) => {
                    return (
                      <Collapse
                        in={area.isExpanded}
                        timeout="auto"
                        unmountOnExit
                        key={id}
                      >
                        <Grid
                          key={id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={3.5}
                            textAlign="right"
                            key={seat.id}
                          >
                            <Checkbox
                              sx={{ padding: "0px" }}
                              checked={seat.isCheck}
                              onChange={() => {
                                setAreas(() => {
                                  return areas.map((oldArea) => {
                                    if (area.id === oldArea.id) {
                                      const areaTarget = area.children.map(
                                        (oldSeat) => {
                                          if (seat.id === oldSeat.id) {
                                            return {
                                              ...seat,
                                              isCheck: !oldSeat.isCheck,
                                            }
                                          }
                                          return oldSeat
                                        }
                                      )
                                      return {
                                        ...area,
                                        children: areaTarget,
                                      }
                                    }
                                    return oldArea
                                  })
                                })
                              }}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <CustomTextField
                              id="seat_name"
                              name="seat_name"
                              required
                              variant="outlined"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={seat.seat_name}
                              onChange={(e) => {
                                setAreas(() => {
                                  return areas.map((oldArea) => {
                                    if (area.id === oldArea.id) {
                                      const areaTarget = area.children.map(
                                        (oldSeat) => {
                                          if (seat.id === oldSeat.id) {
                                            return {
                                              ...seat,
                                              seat_name: numberCheck(
                                                Number(e.target.value)
                                              )
                                                ? Number(e.target.value)
                                                : seat.seat_name,
                                            }
                                          }
                                          return oldSeat
                                        }
                                      )
                                      return {
                                        ...area,
                                        children: areaTarget,
                                      }
                                    }
                                    return oldArea
                                  })
                                })
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={1.5}>
                            <IconButton
                              data-areaid={area.id}
                              data-seatid={seat.id}
                              onClick={handleClickArea}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {renderSeatObject(seat.seat_type)}
                            </IconButton>
                            <Popover
                              open={openArea}
                              anchorEl={anchorElArea}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 40,
                                horizontal: 40,
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "56px",
                                  width: "400px",
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <ReservedSeat />
                                <Button
                                  onClick={() => {
                                    setAreas(() => {
                                      return areas.map((newArea) => {
                                        if (areaId === newArea.id) {
                                          const areaTarget =
                                            newArea.children.map((newSeat) => {
                                              if (seatId === newSeat.id) {
                                                return {
                                                  ...newSeat,
                                                  seat_type: 0,
                                                }
                                              }
                                              return newSeat
                                            })
                                          return {
                                            ...newArea,
                                            children: areaTarget,
                                          }
                                        }
                                        return newArea
                                      })
                                    })
                                    handleClose()
                                  }}
                                  sx={{
                                    color: "#22BA9D",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  指定席
                                </Button>
                                <FreeSeat />
                                <Button
                                  onClick={() => {
                                    setAreas(() => {
                                      return areas.map((newArea) => {
                                        if (areaId === newArea.id) {
                                          const areaTarget =
                                            newArea.children.map((newSeat) => {
                                              if (seatId === newSeat.id) {
                                                return {
                                                  ...newSeat,
                                                  seat_type: 1,
                                                }
                                              }
                                              return newSeat
                                            })
                                          return {
                                            ...newArea,
                                            children: areaTarget,
                                          }
                                        }
                                        return newArea
                                      })
                                    })
                                    handleClose()
                                  }}
                                  sx={{
                                    color: "#22BA9D",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  自由席
                                </Button>
                                <UnavailableSeat />
                                <Button
                                  onClick={() => {
                                    setAreas(() => {
                                      return areas.map((newArea) => {
                                        if (areaId === newArea.id) {
                                          const areaTarget =
                                            newArea.children.map((newSeat) => {
                                              if (seatId === newSeat.id) {
                                                return {
                                                  ...newSeat,
                                                  seat_type: 2,
                                                }
                                              }
                                              return newSeat
                                            })
                                          return {
                                            ...newArea,
                                            children: areaTarget,
                                          }
                                        }
                                        return newArea
                                      })
                                    })
                                    handleClose()
                                  }}
                                  sx={{
                                    color: "#22BA9D",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  利用不可
                                </Button>
                              </Box>
                            </Popover>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography
                              sx={{
                                color: "#22BA9D",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {renderSeatType(seat.seat_type)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Collapse>
                    )
                  })}
                </Box>
              </Grid>
            </Box>
          )
        })}
      </>
    )
  }
)
