import React, { memo } from "react"

import { Button, styled } from "@mui/material"

export const CustomButton = styled(Button)({
  height: "50px",
  width: "200px",
  fontWeight: "bold",
  borderRadius: "2px",
  textTransform: "none",
})

interface Props {
  label: string
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const WideCancelButton: React.FC<Props> = memo(
  ({ label, handleClick }: Props) => {
    return (
      <CustomButton
        type="submit"
        variant="outlined"
        color="primary"
        size="large"
        onClick={handleClick}
      >
        {label}
      </CustomButton>
    )
  }
)
