import { useCallback, useState } from "react"

import { fetchScheduleWeekRequest } from "../../../../api/employee/scheduleRequest"
import { EmployeeScheduleRequestType, EmployeesType } from "../type"
import { SchedulesTable } from "./type"

export const useWeekSchedule = () => {
  const [scheduleMembers, setScheduleMembers] = useState<EmployeesType>([])
  const [weekSchedules, setWeekSchedules] = useState<SchedulesTable[]>([])
  const [weekScheduleLoading, setWeekScheduleLoading] = useState<boolean>(false)

  const fetchScheduleWeek = useCallback(
    async (data: EmployeeScheduleRequestType) => {
      setWeekScheduleLoading(true)
      try {
        const response = await fetchScheduleWeekRequest(data)
        if (response.status === 200 && response.data) {
          // 予定表サイドバーに表示する従業員名一覧
          const sideBarData = response.data.map((allSchedules) => {
            return allSchedules.employee
          })
          setScheduleMembers(sideBarData)

          // 予定表の右側に表示する予定の一覧
          const scheduleData = response.data.map((allSchedules) => {
            return allSchedules.schedule
          })
          setWeekSchedules(scheduleData)
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log("error")
      } finally {
        setWeekScheduleLoading(false)
      }
    },
    []
  )

  // 各子供コンポーネントから予定の取得処理を呼び出すための関数
  const handleWeekScheduleFetch = async (data: EmployeeScheduleRequestType) => {
    await fetchScheduleWeek(data)
  }

  return {
    scheduleMembers,
    setScheduleMembers,
    weekSchedules,
    setWeekSchedules,
    handleWeekScheduleFetch,
    weekScheduleLoading,
  }
}
