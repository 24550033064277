import React, { Dispatch, SetStateAction } from "react"
import { SubmitHandler } from "react-hook-form"
import { NavigateFunction } from "react-router-dom"

import { Box, Modal, styled, Typography } from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleLayout/ja"
import { CancelButton } from "../../../../public/CancelButton"
import { ErrorText } from "../../../../public/ErrorText"
import { ReflectButton } from "../../../../public/ReflectButton"

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

type Props = {
  isOpen: boolean
  close: () => void
  hasSeat: boolean
  scheduleForm: ScheduleForm
  onNewScheduleSubmit: SubmitHandler<ScheduleForm>
  errorMessages: string[]
  setErrorMessages: Dispatch<SetStateAction<string[]>>
  navigate: NavigateFunction
}

/**
 * 予約確認モーダル
 */
export const CreateScheduleModal: React.FC<Props> = ({
  isOpen,
  close,
  hasSeat,
  scheduleForm,
  onNewScheduleSubmit,
  errorMessages,
  setErrorMessages,
  navigate,
}) => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <Modal open={isOpen} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "40%",
          backgroundColor: "#fff",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px",
        }}
      >
        <Box padding="30px 30px 20px">
          <Typography
            display="flex"
            justifyContent="center"
            sx={{
              marginBottom: "2rem",
            }}
            variant="h6"
          >
            {hasSeat
              ? "座席予約してもよろしいですか？"
              : "予約してもよろしいですか？"}
          </Typography>
          {errorMessages.length !== 0 && (
            <ErrorMessageContainer
              sx={{
                marginBottom: "3rem",
              }}
            >
              {ErrorText(errorMessages)}
            </ErrorMessageContainer>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "10px auto",
            }}
          >
            <ReflectButton
              label={translations.ConfirmReservation}
              handleClick={async () => {
                try {
                  await onNewScheduleSubmit(scheduleForm)
                  navigate("/employee/layouts")
                } catch (error) {
                  console.error(error)
                  setErrorMessages([
                    "スケジュールの作成に失敗しました。再度お試しください。",
                  ])
                }
              }}
            />
            {/* <CancelButton label="編集に戻る" handleClick={close} /> */}
            <CancelButton label={translations.edit} handleClick={close} />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
