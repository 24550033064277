import React, { useState } from "react"
import { useLocation } from "react-router-dom"

import { Box, styled, Typography } from "@mui/material"

import { useMonthSchedule } from "../../../../../models/employee/useSchedule/months"
import { SchedulableMaxDateProvider } from "../../../../../providers/SchedulableMaxDateProvider"
import { monthStatus } from "../../../../../utils/month"
import { weekStatus } from "../../../../../utils/weeks"
import { Loading } from "../../../../public/Loading"
import { EmployeeScheduleMonthHeader } from "../EmployeeScheduleMonthHeader"
import { EmployeeScheduleMonthTable } from "../EmployeeScheduleMonthTable"

const CustomDayBox = styled(Box)(() => ({
  borderBottomWidth: "1px",
  height: "1.5rem",
  width: "14%",
  textAlign: "center",
}))

const CustomTypography = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  letterSpacing: "0.7px",
  color: "#707070",
}))

interface LocationState {
  displayReserved: boolean
  displayNonReserved: boolean
}

export const MonthCalendar: React.FC = () => {
  const location = useLocation()

  const { dayOfWeek } = weekStatus()
  const { calendars, fetchScheduleMonth, monthScheduleLoading, getEventWidth } =
    useMonthSchedule()
  const monthObject = monthStatus()
  const [displayReserved, setDisplayReserved] = useState<boolean>(
    (location.state as LocationState)?.displayReserved || false
  )
  const [displayNonReserved, setDisplayNonReserved] = useState<boolean>(
    (location.state as LocationState)?.displayNonReserved || false
  )

  const loading = monthScheduleLoading

  // ヘッダーの「席」を押した時の関数（席予約と会議室予約のみの表示に切り替え）
  const displayOnlyReserved = () => {
    setDisplayReserved(true)
    setDisplayNonReserved(false)
  }

  // ヘッダーの「スケジュール」を押した時の関数（席予約と会議室予約以外の表示に切り替え）
  const displayOnlyNonReserved = () => {
    setDisplayNonReserved(true)
    setDisplayReserved(false)
  }

  // ヘッダーの「全て」を押した時の関数（「席」「スケジュール」選択時の表示解除）
  const displayAll = () => {
    setDisplayReserved(false)
    setDisplayNonReserved(false)
  }

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <SchedulableMaxDateProvider>
        <EmployeeScheduleMonthHeader
          {...monthObject}
          fetchScheduleMonth={fetchScheduleMonth}
          displayOnlyReserved={displayOnlyReserved}
          displayOnlyNonReserved={displayOnlyNonReserved}
          displayAll={displayAll}
          displayReserved={displayReserved}
          displayNonReserved={displayNonReserved}
        />
        <Box display="flex">
          {dayOfWeek.map((value, index) => (
            <CustomDayBox mb={1} key={index}>
              <CustomTypography>{value}</CustomTypography>
            </CustomDayBox>
          ))}
        </Box>
        <EmployeeScheduleMonthTable
          calendars={calendars}
          getEventWidth={getEventWidth}
          displayReserved={displayReserved}
          displayNonReserved={displayNonReserved}
        />
      </SchedulableMaxDateProvider>
    </>
  )
}
