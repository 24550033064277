/* eslint-disable react/prop-types */
import React, { memo } from "react"

import { Button, styled } from "@mui/material"

export const TwoConditionsCustomButton = styled(Button)({
  height: "40px",
  width: "216px",
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  borderRadius: "6px",
  textTransform: "none",
})

interface TwoConditionsProps {
  label: string
  handleClick: () => void
  conditionOne: string
  conditionTwo: string
}

export const TwoConditionsReflectButton: React.FC<TwoConditionsProps> = memo(
  ({ label, handleClick, conditionOne, conditionTwo }) => {
    return (
      <TwoConditionsCustomButton
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        onClick={handleClick}
        disabled={!conditionOne || !conditionTwo}
      >
        {label}
      </TwoConditionsCustomButton>
    )
  }
)
