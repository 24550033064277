const ja = {
  home: "ホーム",
  locationManagement: "拠点管理",
  locationInformation: "拠点情報",
  signUp: "新規登録",
  edit: "編集",
  detail: "詳細",
  layouts: "レイアウト",
  employeeManagement: "従業員管理",
  employeeInformation: "従業員情報",
  termList: "組織一覧",
  corporateOrganization: "法人組織",
  report: "レポート",
  attendanceRate: "出社率",
  operationRate: "稼働率",
  byLocation: "拠点別",
  operationSeatCount: "稼働席数",
  conferenceRoomUsageStatus: "会議室利用状況",
  byAreaUsageStatus: "エリア別利用状況",
  schedule: "予定表",
  newSchedule: "新規スケジュール",
  editSchedule: "スケジュール編集",
  workPlace: "席状況",
  modes: "モード",
  settings: "設定",
}
export default ja
