import React from "react"

import { Description, Mail } from "@mui/icons-material"
import { Box, Grid, styled, Typography } from "@mui/material"

import { ExternalLink } from "./ExternalLinkComponent"
import { Point } from "./PointComponent"
import { Step } from "./StepComponent"
import { Right } from "./Triangle"

const CustomH1 = styled(Typography)(() => ({
  color: "#22ba9d",
  fontSize: "2.75rem",
  fontWeight: "bold",
  fontFamily: "NotoSans",
}))

export const SignUpInformation = () => {
  return (
    <Grid
      item
      md={9}
      sx={{
        borderRight: "2px solid #f4f8f9",
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: "1000px", margin: "0 auto" }}
      >
        <Grid item md={4}>
          <Grid container justifyContent="flex-end">
            <img
              src={`${process.env.PUBLIC_URL}/images/signup/demoimage.png`}
              alt="サンプル画像"
              width="100%"
            />
          </Grid>
        </Grid>
        <Grid item md={8} sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
          <Typography variant="body1">
            新しい時代のオフィス管理を実際の操作画面でご体験ください！！
          </Typography>
          <CustomH1 variant="h3">WORK AGILEを30日間</CustomH1>
          <CustomH1 variant="h3">無料でお試しいただけます</CustomH1>
          <Typography variant="body1" sx={{ margin: "1rem 0 !important" }}>
            無料体験のために、クレジットカードの登録は不要です。
            <br />
            体験期間終了後に自動で更新される事もありません。
            <br />
            貴社検討に際しての体験期間の延長について相談可能です。
          </Typography>
          <Typography variant="caption" sx={{ color: "#454545" }}>
            WORKAGILEの動作環境 |
            ※WindowsでもMacでも利用可能です。Chrome,Edge等のブラウザに対応しています。動作環境について詳しくはこちらをご確認ください。
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#f4f8f9",
          padding: "1rem 0",
        }}
      >
        <Box sx={{ maxWidth: "1000px", margin: "0 auto" }}>
          <Box
            sx={{
              backgroundColor: "#22ba9d",
              width: "fit-content",
              height: "50px",
              padding: "0 1rem",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#FFFFFF", fontWeight: "bold" }}
            >
              3STEPで簡単に開始できます！
            </Typography>
          </Box>
          <Grid
            container
            alignItems="center"
            height={230}
            marginTop={2}
            marginBottom={2}
          >
            <Step
              stepNum={1}
              title="法人アカウントの作成"
              desc="このページのフォームから必要情報をご登録。ご担当者様のメールアドレスと、パスワードの2点です。"
            />
            <Right />
            <Step
              stepNum={2}
              title="メールアドレス認証"
              desc="届いたメールから、認証を完了してください。"
            />
            <Right />
            <Step
              stepNum={3}
              title="本登録"
              desc="ご担当者名、法人住所など、必要情報をご入力ください。"
            />
          </Grid>
        </Box>
      </Box>
      <Box sx={{ padding: "1rem 0", maxWidth: "1000px", margin: "0 auto" }}>
        <Box textAlign="center" marginTop={4} marginBottom={4}>
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            気軽に安心してお試しいただける3つのポイント
          </Typography>
        </Box>
        <Point
          number={1}
          firstTitle="無料体験お申し込み時"
          secondTitle="クレジットカードの登録は不要です。"
          descriptions={[
            "無料体験期間が終了し、継続を、ご希望される場合のみ、ご登録が必要となります。",
          ]}
        />
        <Point
          number={2}
          firstTitle="無料体験期間終了後"
          secondTitle="自動更新されないので安心です。"
          descriptions={[
            "有料プランに自動で切り替わることはありません。",
            "無料体験期間終了後にWORK AGILEにログインするとプラン選択画面を表示することができます。",
            "継続利用をご希望の場合はプラン選択とクレジットカード登録にお進みください。",
            "無料体験期間中もクレジットカード情報を登録できますが、この場合も自動で有料プランに切り替わることはないため、ご安心ください。",
          ]}
        />
        <Point
          number={3}
          firstTitle="体験期間の延長について"
          secondTitle="ご相談可能です。"
          descriptions={[
            "どのタイミングで、無料体験するか、悩まずとも大丈夫です。",
            "社内向けのデモや、導入テスト、稟議上申に際しての利用など、状況をお伺いして、体験期間の延長については、ご相談に乗らせていただきます。",
            "お気軽に一度、WORK AGILEの機能をお試しください。",
          ]}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#22ba9d",
          padding: "2rem 0",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{ padding: "1rem 0", maxWidth: "1000px", margin: "0 auto" }}
        >
          <ExternalLink
            situation={"体験前に資料を確認したい方"}
            icon={<Description sx={{ fontSize: "60px" }} />}
            title={"資料請求"}
            description={"サービスについての資料をお送りいたします。"}
            linkText={"資料請求はこちら"}
            linkUrl={"https://workagile.jp/index.php/request-service/"}
          />
          <ExternalLink
            situation={"体験前に説明を受けたい方"}
            icon={<Mail sx={{ fontSize: "60px" }} />}
            title={"問い合わせ"}
            description={
              "サービスについての不明な点はお気軽にお問い合わせください。"
            }
            linkText={"問い合わせはこちら"}
            linkUrl={"https://workagile.jp/index.php/contact-2/"}
          />
        </Grid>
      </Box>
    </Grid>
  )
}
