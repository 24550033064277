const ja = {
  YourNewPassword: "新しいパスワードを入力して",
  ConfirmButton: "パスワードを変更するボタンを押してください",
  eightCharacters: "8文字以上で設定してください",
  PleaseInclude:
    "英大文字・英小文字・数字・記号をそれぞれ1文字以上使用してください",
  Symbols: "記号は ! / ; :  @ [] - {} ` ~ がご利用いただけます",
  OldPassword: "古いパスワード（必須）",
  NewPassword: "新しいパスワード（必須）",
  Confirm: "パスワードを変更する",
  loginPage: "ログイン画面に戻る",
}
export default ja
