import React from "react"

import {
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} from "@mui/material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { Schedules } from "../../../../../models/employee/useSchedule/show/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeShowScheduleModal/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeShowScheduleModal/ja"

interface Props {
  schedules: Schedules
}

export const ShareScheduleUserableTableComponent: React.FC<Props> = ({
  schedules,
}) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(3)
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - schedules.length) : 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h1"
        sx={{ color: "#707070", fontWeight: "bold", mt: 2 }}
      >
        {translations.Attendees}
      </Typography>
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>{translations.Name}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? schedules.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : schedules
              ).map((schedule, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {schedule.userable.last_name} {schedule.userable.first_name}
                    {schedule.userable.userable_type === "Guest" && (
                      <span>({translations.gest})</span>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {schedule.userable.userable_type === "Employee" &&
                      (schedule.accept_status
                        ? translations.Accepted
                        : translations.Declined)}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[3]}
                  colSpan={3}
                  count={schedules.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}${translations.from}${to} ${translations.people} / ${count}${translations.manyPeople}`
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
