import React from "react"

import { Box } from "@mui/material"

import { CheckIn } from "../../../components/mobile/checkIn/CheckInMain"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CheckInPage = () => {
  const { companyRelations } = useCompanyRelations()

  return (
    <Box sx={{ height: "100vh", my: "150px" }}>
      <CheckIn companyRelations={companyRelations} />
    </Box>
  )
}
