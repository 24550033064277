import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { SignageMainOutlineButton } from "../../../components/public/SignageMainOutlineButton"
import { theme } from "../../../config/theme"
import { useSignageReception } from "../../../models/signage/useReception"

const CustomBox = styled(Box)(() => ({
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(10),
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  marginBottom: theme.spacing(3),
}))

export const SignageReceptionTooEarlyPage = () => {
  const navigate = useNavigate()
  const { returnTopPage } = useSignageReception()
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)

  useEffect(() => {
    returnTopPage()
  }, [])

  return (
    <CustomBox>
      <Box mb={12} textAlign="center">
        <CustomTypography variant="h3">
          予約開始15分前から入館いただけます。
        </CustomTypography>
        <CustomTypography variant="h3">少々お待ちください。</CustomTypography>
      </Box>
      <SignageMainOutlineButton
        label="TOPに戻る"
        handleClick={() =>
          navigate(`/signage/branches/${branchId}/reception/home`)
        }
      />
    </CustomBox>
  )
}
