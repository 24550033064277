import React from "react"

import { Grid } from "@mui/material"

import { Loading } from "../../../public/Loading"

interface Props {
  loading: boolean
  children: JSX.Element
}

export const MobileAuthPageTemplate = ({ loading, children }: Props) => {
  return (
    <Grid container justifyContent="center">
      {loading && <Loading type="content" loading={loading} />}
      <Grid item xs={10}>
        {children}
      </Grid>
    </Grid>
  )
}
