import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Box, Grid, Typography, styled, Modal, Button } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { useScheduleCheckIn } from "../../../../models/employee/useSchedule/checkIn"
import { CheckInData } from "../../../../models/employee/useSchedule/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/errorMessage/en"
import jaTranslations from "../../../../translations/errorMessage/ja"
import { AlertError } from "../../../public/AlertError"
import { CheckInNotReservedModal } from "../CheckInNotReservedModal"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
}))

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: "85%",
  padding: theme.spacing(4),
}))

interface Props {
  companyRelations: CompanyRelationsType
  checkInData: CheckInData
  onSuccess: () => void
  selectedBranch: string
  selectedFloor: string
}

// チェックインのメイン画面
export const CheckIn = ({
  companyRelations,
  checkInData,
  onSuccess,
  selectedBranch,
  selectedFloor,
}: Props) => {
  const navigate = useNavigate()
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const {
    notReservedModalMessage,
    notReserved,
    setNotReserved,
    reservationModalHours,
    reservationModalMinutes,
    reservationHour,
    reservationMinutes,
    handleReservationHourChange,
    handleReservationMinutesChange,
    checkInError,
    checkInErrorMessage,
    setCheckInError,
    setCheckInEndOfShiftStateFromQueryString,
    hasAnotherSchedule,
    hasAnotherCheckIn,
    checkInFromHome,
    checkInEndOfShiftFromHome,
    handleInstantCheckInFromHome,
  } = useScheduleCheckIn()

  const [startTime, setStartTime] = useState<string | null>(null) // startTimeを状態として管理
  const [employeeName, setEmployeeName] = useState<string | null>(null) // employeeNameを状態として管理
  const [employeeLastName, setEmployeeLastName] = useState<string | null>(null) // employeeLastNameを状態として管理
  const [employeeFirstName, setEmployeeFirstName] = useState<string | null>(
    null
  ) // employeeFirstNameを状態として管理
  const [sameUserFlag, setSameUserFlag] = useState<boolean>(false) // same_user_flagを状態として管理
  const [checkInFlag, setCheckInFlag] = useState<boolean>(false) // check_in_flagを状態として管理
  const [anotherScheduleFlag, setAnotherScheduleFlag] = useState<boolean>(false) // another_schedule_flagを状態として管理
  const [endOfShiftTimeOver, setEndOfShiftTimeOver] = useState<boolean>(false) // end_of_shift_time_overを状態として管理

  const handleCheckIn = () => {
    if (checkInData.seatId !== null) {
      checkInFromHome({ seatId: checkInData.seatId })
    }
  }

  const handleCheckInEndOfShift = () => {
    if (checkInData.seatId !== null) {
      checkInEndOfShiftFromHome(
        {
          seatId: checkInData.seatId,
          floorId: checkInData.floorId,
          branchId: checkInData.branchId,
          branchName: checkInData.branchName,
          floorNumber: checkInData.floorNumber,
          seatName: checkInData.seatName,
        },
        onSuccess
      )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (companyRelations && checkInData.seatId) {
        const data = await setCheckInEndOfShiftStateFromQueryString(
          companyRelations,
          checkInData.seatId
        )
        if (data) {
          setSameUserFlag(data.same_user_flag)
          setCheckInFlag(data.check_in_flag)
          setAnotherScheduleFlag(data.another_schedule_flag)
          setEndOfShiftTimeOver(data.end_of_shift_time_over)
        }
        if (
          data &&
          data.other_reservations &&
          data.other_reservations.length > 0
        ) {
          // 予約がある場合、最も早い予約の開始時間を取得
          const now = new Date()
          const futureReservations = data.other_reservations.filter(
            (reservation) => new Date(reservation.start_time) > now
          )

          if (futureReservations.length > 0) {
            const earliestReservation = futureReservations.reduce(
              (earliest, current) => {
                return new Date(current.start_time) <
                  new Date(earliest.start_time)
                  ? current
                  : earliest
              }
            )
            // 予約者名を取得
            setEmployeeName(earliestReservation.employee_name)
            setEmployeeLastName(earliestReservation.employee_last_name)
            setEmployeeFirstName(earliestReservation.employee_first_name)
            // startTimeを設定
            setStartTime(earliestReservation.start_time)
          }
        }
      }
    }
    fetchData()
  }, [companyRelations, checkInData.seatId])

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h5" marginRight={5}>
            {selectedBranch}
          </CustomTypography>
          <CustomTypography variant="h5">
            {selectedFloor}
            フロア
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mx={2}>
          <CustomTypography variant="h4">
            {translations.Desk}：{checkInData.seatName}
          </CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <CustomTypography>{translations.YouCheckIn}</CustomTypography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCheckIn}
          >
            <Typography fontWeight="bold">
              {translations.scheduleAndCheckIn}
            </Typography>
          </Button>
        </Grid>
        <>
          {!endOfShiftTimeOver && (
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCheckInEndOfShift}
              >
                <Typography fontWeight="bold">
                  {startTime === null
                    ? translations.endOfShiftCheckIn
                    : startTime && sameUserFlag
                    ? translations.endOfReservationCheckIn
                    : language === "ja"
                    ? `${new Date(startTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}までチェックイン`
                    : `CHECK IN UNTIL ${new Date(startTime).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}`}
                </Typography>
              </Button>
            </Grid>
          )}
          {employeeName && startTime && (
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h6" align="center" fontWeight="bold">
                {language === "ja"
                  ? `この座席は、${employeeName}さんが
                    ${new Date(startTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    から予約中です`
                  : `This seat is reserved for ${employeeFirstName} ${employeeLastName} from ${new Date(
                      startTime
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`}
              </Typography>
            </Grid>
          )}
        </>
        {checkInFlag && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="h6" align="center" fontWeight="bold">
              {translations.CheckInNow}
            </Typography>
          </Grid>
        )}
        {!checkInFlag && anotherScheduleFlag && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="h6" align="center" fontWeight="bold">
              {translations.ReserveInNow}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate("/employee/layouts")}
          >
            <Typography fontWeight="bold">{translations.DeskStatus}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => window.location.reload()}
          >
            <Typography fontWeight="bold">{translations.Home}</Typography>
          </Button>
        </Grid>
        <Modal
          open={notReserved}
          onClose={() => setNotReserved(false)}
          disableAutoFocus
        >
          <ModalBox>
            <CheckInNotReservedModal
              notReservedModalMessage={notReservedModalMessage}
              hours={reservationModalHours}
              minutes={reservationModalMinutes}
              reservationHour={reservationHour}
              reservationMinutes={reservationMinutes}
              handleReservationHourChange={handleReservationHourChange}
              handleReservationMinutesChange={handleReservationMinutesChange}
              setNotReserved={setNotReserved}
              checkInData={checkInData}
              handleInstantCheckInFromHome={handleInstantCheckInFromHome}
              onSuccess={onSuccess}
              hasAnotherSchedule={hasAnotherSchedule}
              hasAnotherCheckIn={hasAnotherCheckIn}
            />
          </ModalBox>
        </Modal>
        <AlertError
          isMessageOpen={checkInError}
          alertMessage={checkInErrorMessage}
          closeMessage={() => setCheckInError(false)}
        />
      </Grid>
    </Box>
  )
}
