import React from "react"
import { useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"

import { CompanyBranchForm } from "../../../components/company/branches/CompanyBranchForm"
import { AlertContent } from "../../../components/public/AlertContent"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../components/public/ContentTemplate"
import { useBranch } from "../../../models/company/useBranch"

export const CompanyBranchCreatePage = () => {
  const branchObject = useBranch()
  const navigate = useNavigate()

  return (
    <>
      <ContentTemplate>
        <ContentCompanyPaper>
          <CompanyBranchForm {...branchObject} />
        </ContentCompanyPaper>
      </ContentTemplate>
      <AlertContent />
    </>
  )
}
