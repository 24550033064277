import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState, useCallback, useContext } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  createCompanyRequest,
  fetchCompanyRequest,
  fetchCompanyPrepareRequest,
  updateCompanyNameRequest,
} from "../../../api/company/companyRequest"
import { AuthContext } from "../../../providers/AuthProvider"
import {
  FULL_WIDTH_AND_HALFWIDTH,
  FULL_WIDTH_AND_HALFWIDTH_SPACE,
  FULL_WIDTH_KATAKANA,
  POSTAL_CODE,
  COMPANY_TEL,
} from "../../../utils/regular"
import { useIcon } from "../../public/useIcon"
import {
  CompanyFormType,
  HeaderCompanyType,
  PreparationResponseType,
} from "./type"

const companyInfoData: CompanyFormType = {
  last_name: "",
  first_name: "",
  last_name_kana: "",
  first_name_kana: "",
  company_name: "",
  company_name_kana: "",
  company_postal_code: "",
  company_prefecture_id: 0,
  company_city: "",
  company_house_number: "",
  company_building_name: "",
  company_tel: "",
}

const initialPreparationData: PreparationResponseType = {
  id: 0,
  branch_count: 0,
  employee_count: 0,
  floor_count: 0,
  team_count: 0,
}

export const useCompany = () => {
  const navigate = useNavigate()
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [companyRequestInfo] = useState<CompanyFormType>(companyInfoData)
  const [open, setOpen] = useState<boolean>(false)
  const [headerCompanyInfo, setHeaderCompanyInfo] = useState<
    HeaderCompanyType | undefined
  >(undefined)
  const [companyPreparation, setCompanyPreparation] =
    useState<PreparationResponseType>(initialPreparationData)
  const { currentUser } = useContext(AuthContext)
  const { fetchFile, imageUrl } = useIcon()
  const [companyName, setCompanyName] = useState("")
  const [companyNameKana, setCompanyNameKana] = useState("")

  const basicSchema = Yup.object().shape({
    last_name: Yup.string()
      .required("必須になります")
      .matches(
        FULL_WIDTH_AND_HALFWIDTH,
        "全角ひらがな、カタカナ、漢字、半角アルファベットになります"
      ),
    first_name: Yup.string()
      .required("必須になります")
      .matches(
        FULL_WIDTH_AND_HALFWIDTH,
        "全角ひらがな、カタカナ、漢字、半角アルファベットになります"
      ),
    last_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    first_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    company_name: Yup.string()
      .required("必須になります")
      .matches(
        FULL_WIDTH_AND_HALFWIDTH_SPACE,
        "全角ひらがな、カタカナ、漢字、半角英数字、半角スペース、記号になります。"
      ),
    company_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
    company_postal_code: Yup.string()
      .required("必須になります")
      .matches(POSTAL_CODE, "ハイフンを含む7桁で入力してください"),
    company_prefecture_id: Yup.number().moreThan(
      0,
      "都道府県を選択してください"
    ),
    company_city: Yup.string().required("必須になります"),
    company_house_number: Yup.string().required("必須になります"),
    company_tel: Yup.string()
      .required("必須になります")
      .matches(
        COMPANY_TEL,
        "ハイフンを含む10桁もしくは11桁の半角数字で入力してください"
      ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      last_name: companyInfoData.last_name,
      first_name: companyInfoData.first_name,
      last_name_kana: companyInfoData.last_name_kana,
      first_name_kana: companyInfoData.first_name_kana,
      company_name: companyInfoData.company_name,
      company_name_kana: companyInfoData.company_name_kana,
      company_postal_code: companyInfoData.company_postal_code,
      company_prefecture_id: companyInfoData.company_prefecture_id,
      company_city: companyInfoData.company_city,
      company_house_number: companyInfoData.company_house_number,
      company_building_name: companyInfoData.company_building_name,
      company_tel: companyInfoData.company_tel,
    },
    resolver: yupResolver(basicSchema),
  })

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      company_name: companyInfoData.company_name,
      company_name_kana: companyInfoData.company_name_kana,
    },
    resolver: yupResolver(basicSchema),
  })

  const onSubmit: SubmitHandler<CompanyFormType> = useCallback(async (data) => {
    const { error } = await createCompanyRequest({
      ...data,
      account: {
        email: currentUser?.attributes.email as string,
        receive_notification: Number(
          currentUser?.attributes["custom:receiveNotification"]
        ),
      },
    })
    if (!error) {
      navigate("/company/home")
    } else {
      setErrorMessages(error)
      setOpen(true)
    }
  }, [])

  const updateCompanyName = async () => {
    try {
      const response = await updateCompanyNameRequest({
        company_name: companyName,
        company_name_kana: companyNameKana,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "法人名称を変更しました")
        navigate(`/company/settings/payment`)
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  const fetchCompany = useCallback(async () => {
    try {
      const response = await fetchCompanyRequest()
      if (response.data && response.status === 200) {
        setHeaderCompanyInfo(response.data)
        setCompanyName(response.data.company.company_name)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const fetchCompanyPreparation = useCallback(async () => {
    try {
      const response = await fetchCompanyPrepareRequest()
      if (response.data && response.status === 200) {
        setCompanyPreparation(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  return {
    companyRequestInfo,
    control,
    errors,
    isValid,
    form,
    open,
    errorMessages,
    handleSubmit,
    getValues,
    setValue,
    onSubmit,
    updateCompanyName,
    fetchCompany,
    headerCompanyInfo,
    fetchFile,
    imageUrl,
    companyPreparation,
    fetchCompanyPreparation,
    companyName,
    setCompanyName,
    companyNameKana,
    setCompanyNameKana,
  }
}
