import React, { useEffect } from "react"

import { MobileLanguageChangeData } from "../../../components/mobile/languageChange"
import { useProfile } from "../../../models/employee/useProfile"

export const MobileLanguageChangePage = () => {
  const { fetchEmployeeProfile, employeeProfile } = useProfile()

  useEffect(() => {
    fetchEmployeeProfile()
  }, [])

  return (
    <>
      <MobileLanguageChangeData />
    </>
  )
}
