import enUS from "date-fns/locale/en-US"
import ja from "date-fns/locale/ja"
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js"
import draftToHtmlPuri from "draftjs-to-html"

import React, { useState, useEffect } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Controller, FormProvider } from "react-hook-form"

import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import {
  Box,
  Button,
  Modal,
  Switch,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { theme } from "../../../../config/theme"
import { useLanguage } from "../../../../contexts/LanguageContext"
import { useProfile } from "../../../../models/employee/useProfile"
import { ScheduleInformation } from "../../../../models/employee/useSchedule/show/type"
import { useEditSchedule } from "../../../../models/employee/useSchedule/useScheduleForm"
import {
  ReservableType,
  WhoIsWheres,
} from "../../../../models/employee/useSchedule/useScheduleForm/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../translations/mobileSchedule/en"
import jaTranslations from "../../../../translations/mobileSchedule/ja"
import { oneWeek } from "../../../../utils/oneWeek"
import { weekStatus } from "../../../../utils/weeks"
import { MobileErrorText } from "../../../public/MobileErrorText"
import { AddPlaceModal } from "../MobileEventScheduleForm/AddPlaceModal"
import { EndDateComponent } from "../MobileEventScheduleForm/EndDateComponent"
import { EndDateTimeComponent } from "../MobileEventScheduleForm/EndDateTimeComponent"
import { RepeatScheduleModal } from "../MobileEventScheduleForm/RepeatScheduleModal"
import { StartDateComponent } from "../MobileEventScheduleForm/StartDateComponent"
import { StartDateTimeComponent } from "../MobileEventScheduleForm/StartDateTimeComponent"

const CustomModalBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "95%",
  minHeight: "70%",
  maxHeight: "98%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  overflowY: "scroll",
}))

const CustomHeaderBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}))

const CustomTitleBox = styled(Box)(() => ({
  height: "30px",
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const CustomTitleButtonBox = styled(Box)(() => ({
  height: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
}))

const CustomTitle = styled(Typography)(() => ({
  fontWeight: "bolder",
  color: theme.palette.primary.main,
}))

const CustomContentBox = styled(Box)(() => ({
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
}))

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
})

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

const checkMeetingOrSeat = (whoIsWheres: WhoIsWheres) => {
  const reservableMeetingOrSeat = whoIsWheres.filter((whoIsWhere) => {
    return whoIsWhere.reservable.reservable_id
  })

  return reservableMeetingOrSeat.length !== 0
}

export interface ValidEndDateProps {
  validEndDateTime: string
  setValidEndDateTime: React.Dispatch<React.SetStateAction<string>>
  validEndDate: string
  setValidEndDate: React.Dispatch<React.SetStateAction<string>>
  setDateError: React.Dispatch<React.SetStateAction<boolean>>
  defaultDate: Date
  scheduleDayDefaultStartTime: number
  scheduleDayDefaultEndTime: number
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  checkMeetingOrSeat: (whoIsWheres: WhoIsWheres) => boolean
}

interface Props {
  editModalClose: () => void
  schedule: ScheduleInformation
  companyRelations: CompanyRelationsType
}

interface MeetingRoom {
  meetingRoomId: number
  branchName: string
  floorNumber: string
  roomName: string
  capacity: number
}

interface Seat {
  seatId: number
  branchName: string
  floorName: string
  seatName: string
}

export const MobileEditEventSchedule: React.FC<Props> = ({
  editModalClose,
  schedule,
  companyRelations,
}) => {
  const { fetchEmployeeProfile, employeeProfile } = useProfile()
  const [isSecret, setIsSecret] = useState<boolean>(
    schedule.schedules[0].is_secret
  )
  const [isRepeatModalOpen, setIsRepeatModalOpen] = useState<boolean>(false)
  const [isAddPlaceModalOpen, setIsAddPlaceModalOpen] = useState<boolean>(false)
  const [repeatDays, setRepeatDays] = useState<string[]>([])
  const [checkedSeatId, setCheckedSeatId] = useState<number>(0)
  const [checkedMeetingRoomId, setCheckedMeetingRoomId] = useState<number>(0)
  const [checkedSeat, setCheckedSeat] = useState<Seat | undefined>(undefined)
  const [checkedMeetingRoom, setCheckedMeetingRoom] = useState<
    MeetingRoom | undefined
  >(undefined)
  const [isSeatOrMeetingRoom, setIsSeatOrMeetingRoom] = useState<
    ReservableType | undefined
  >()
  const [wholeDay, setWholeDay] = useState(false)
  const { dayOfWeek } = weekStatus()
  const {
    methods,
    errorMessages,
    onEditScheduleSubmit,
    setErrorMessages,
    validEndDateTime,
    validEndDate,
    setValidEndDateTime,
    setValidEndDate,
  } = useEditSchedule(schedule)
  const { setTargetWeeks } = oneWeek()

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
  } = methods

  const [is_startDate, setIsStartDate] = useState<number>(0)
  const [is_endDate, setIsEndDate] = useState<number>(0)
  const [is_startHours, setIsStartHours] = useState<number>(0)
  const [is_endHours, setIsEndHours] = useState<number>(0)

  const [monthlySetting, setMonthlySetting] = useState<
    "day" | "dayOfWeek" | undefined
  >(
    schedule?.monthly_recurring_type?.daily_or_week_day === "week_day"
      ? "dayOfWeek"
      : "day"
  )

  const initData = convertFromHTML(getValues("schedule.message_content"))
  const initState = ContentState.createFromBlockArray(
    initData.contentBlocks,
    initData.entityMap
  )
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(initState)
  )

  const startDate = getValues("schedule.start_date")
  const endDate = getValues("schedule.end_date")
  const startTime = getValues("schedule.start_time")
  const endTime = getValues("schedule.end_time")
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // 手動で日付・時刻を編集する際にエラーにならないようにする
  useEffect(() => {
    if (typeof startDate === "object" && startDate && "getDate" in startDate) {
      setIsStartDate(getValues("schedule.start_date")!.getDate())
    }
    if (typeof endDate === "object" && endDate && "getDate" in endDate) {
      setIsEndDate(getValues("schedule.end_date")!.getDate())
    }
    if (typeof startTime === "object" && startTime && "getHours" in startTime) {
      setIsStartHours(getValues("schedule.start_time")!.getHours())
    }
    if (typeof endTime === "object" && endTime && "getHours" in endTime) {
      setIsEndHours(getValues("schedule.end_time")!.getHours())
    }
  }, [startDate, endDate, startTime, endTime])

  const whoIsWheres = getValues("schedule.share_schedule.who_is_wheres")
  const [selectedPlace, setSelectedPlace] = useState<string | undefined>(
    whoIsWheres[0].reservable.name
  )

  const [dateError, setDateError] = useState<boolean>(true)

  const handleWholeDayChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setWholeDay(checked)
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.AllDayReset])
    }
    if (validEndDateTime == "#d32f2f" && checked) {
      setValue("schedule.start_time", new Date())
      setValue("schedule.end_time", new Date())
      setValidEndDateTime("rgba(0, 0, 0, 0.23)")
      setDateError(true)
    } else if (checked) {
      setDateError(true)
    }

    setValue("schedule.whole_day_flag", checked)
  }

  // 予定の開始日をlocalStorageに保存（画面遷移後に該当日付の予定を表示するため）
  const handleSetTargetDate = () => {
    const startTime = watch("schedule.start_date")
    if (startTime) {
      const { newBeginningOfWeek } = setTargetWeeks(startTime)
      window.localStorage.setItem("targetDay", startTime.getTime().toString())
      window.localStorage.setItem(
        "BeginningOfWeek",
        newBeginningOfWeek.getTime().toString()
      )
    }
  }

  const handleIsSecretChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    checked
      ? setValue("schedule.is_secret", 0)
      : setValue("schedule.is_secret", 1)
    setIsSecret(!isSecret)
  }

  const repeatModalOpen = () => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.RepeatFrequencyChange])
    }
    setIsRepeatModalOpen(true)
  }

  const repeatModalClose = () => {
    setIsRepeatModalOpen(false)
  }

  const addPlaceModalOpen = () => {
    setIsAddPlaceModalOpen(true)
  }

  const addPlaceModalClose = () => {
    setIsAddPlaceModalOpen(false)
  }

  const handleCancelSelectPlace = () => {
    addPlaceModalClose()
  }

  const resetPlace = () => {
    setCheckedSeatId(0)
    setCheckedMeetingRoomId(0)
    setSelectedPlace(undefined)
    setValue("schedule.share_schedule.who_is_wheres", [
      {
        userable: {
          last_name: employeeProfile.last_name,
          first_name: employeeProfile.first_name,
          email: employeeProfile.account.email,
          userable_type: "Employee",
          userable_id: employeeProfile.id,
          company_name: undefined,
          should_send_guest_reception_notification_mail: true, // 予定作成者は必ずゲスト入館時に通知させる
        },
        reservable: {
          reservable_id: undefined,
          name: undefined,
          reservable_type: undefined,
        },
      },
    ])
  }

  // 選択された場所が席か会議室かを判別
  let target_id = whoIsWheres[0].reservable.reservable_id
  const setSeatOrMeetingRoomId = () => {
    if (isSeatOrMeetingRoom === "Seat") {
      target_id = checkedSeatId
    } else if (isSeatOrMeetingRoom === "MeetingRoom") {
      target_id = checkedMeetingRoomId
    } else {
      target_id = undefined
    }
  }

  const handleFixPlace = () => {
    if (checkedMeetingRoom) {
      setSelectedPlace(
        `${checkedMeetingRoom.branchName} ${checkedMeetingRoom.floorNumber}F ${checkedMeetingRoom.roomName} ${checkedMeetingRoom.capacity}${translations.Capacity}`
      )
    } else if (checkedSeat) {
      setSelectedPlace(
        `${checkedSeat.branchName} ${checkedSeat.floorName} ${checkedSeat.seatName}`
      )
    } else {
      setSelectedPlace(undefined)
    }
    setSeatOrMeetingRoomId()
    const values = getValues("schedule.share_schedule.who_is_wheres").map(
      (whoIsWhere) => {
        return {
          userable: {
            userable_id: whoIsWhere.userable.userable_id,
            last_name: whoIsWhere.userable.last_name,
            first_name: whoIsWhere.userable.first_name,
            email: whoIsWhere.userable.email,
            userable_type: whoIsWhere.userable.userable_type,
            company_name: whoIsWhere.userable.company_name,
            should_send_guest_reception_notification_mail: true, // 予定作成者は必ずゲスト入館時に通知させる
          },
          reservable: {
            reservable_id: target_id,
            name: undefined,
            reservable_type: isSeatOrMeetingRoom,
          },
        }
      }
    )
    setValue("schedule.share_schedule.who_is_wheres", values)
  }

  // モバイル版のみ文字化け（NaN）してしまうため、読み込み時に値を設定し直す
  const setStartTime = () => {
    const startTimeToString = schedule.start_time.toLocaleString()
    if (startTimeToString) {
      const startTime = new Date(startTimeToString.replace(/-/g, "/"))
      const startHour = startTime.getHours()
      const startMinutes =
        startTime.getMinutes() === 0
          ? `0${startTime.getMinutes()}`
          : startTime.getMinutes()
      setValue(
        "schedule.start_time",
        new Date(
          startTime.getFullYear(),
          startTime.getMonth(),
          startTime.getDate(),
          startHour,
          Number(startMinutes)
        )
      )
    }
  }

  // モバイル版のみ文字化け（NaN）してしまうため、読み込み時に値を設定し直す
  const setEndTime = () => {
    const endTimeToString = schedule.end_time.toLocaleString()
    if (endTimeToString) {
      const endTime = new Date(endTimeToString.replace(/-/g, "/"))
      const endHour = endTime.getHours()
      const endMinutes =
        endTime.getMinutes() === 0
          ? `0${endTime.getMinutes()}`
          : endTime.getMinutes()
      setValue(
        "schedule.end_time",
        new Date(
          endTime.getFullYear(),
          endTime.getMonth(),
          endTime.getDate(),
          endHour,
          Number(endMinutes)
        )
      )
    }
  }

  useEffect(() => {
    const repeatData = dayOfWeek.filter((day, i) => {
      return getValues("schedule.weekly.days")[i] === true
    })
    setRepeatDays(repeatData)
  }, [getValues("schedule.weekly.days")])

  useEffect(() => {
    if (schedule.schedules[0].reservable) {
      const reservable = schedule.schedules[0].reservable
      setCheckedSeatId(
        reservable.reservable_type === "Seat" ? reservable.id : 0
      )
      setCheckedMeetingRoomId(
        reservable.reservable_type === "MeetingRoom" ? reservable.id : 0
      )
    } else {
      setCheckedSeatId(0)
      setCheckedMeetingRoomId(0)
    }
    setStartTime()
    setEndTime()
    trigger()
    fetchEmployeeProfile()
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <CustomModalBox>
          <CustomHeaderBox>
            <Button
              onClick={editModalClose}
              color="secondary"
              sx={{ margin: "0", minWidth: "0" }}
            >
              <HighlightOffIcon />
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{ fontWeight: "bold" }}
              disabled={
                !isValid ||
                validEndDateTime == "#d32f2f" ||
                validEndDate == "#d32f2f"
              }
              onClick={() => {
                if (
                  startDate
                    ? startDate <
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate()
                      )
                    : false
                ) {
                  setErrorMessages([translations.SchedulePast])
                  setValidEndDate("#d32f2f")
                } else if (
                  is_startDate != is_endDate &&
                  Boolean(watch("schedule.schedule_date_type")) == false
                ) {
                  {
                    setErrorMessages([translations.SpanDays])
                    setValidEndDate("#d32f2f")
                  }
                } else if (
                  (0 <= is_startHours && is_startHours < 6) ||
                  (0 <= is_endHours && is_endHours < 6)
                ) {
                  {
                    setErrorMessages([translations.OutsideBusinessHours])
                    setValidEndDate("#d32f2f")
                  }
                } else {
                  {
                    if (monthlySetting === "day") {
                      setValue("schedule.monthly.dayOfWeekly", undefined)
                    } else if (monthlySetting === "dayOfWeek") {
                      setValue("schedule.monthly.dayly", undefined)
                    }
                    setErrorMessages([])
                    handleSubmit(onEditScheduleSubmit)()
                    handleSetTargetDate()
                    editModalClose()
                  }
                }
              }}
            >
              {translations.Update}
            </Button>
          </CustomHeaderBox>
          {errorMessages.length !== 0 && (
            <ErrorMessageContainer mb="1rem">
              {MobileErrorText(errorMessages)}
            </ErrorMessageContainer>
          )}
          <Controller
            control={control}
            name="schedule.schedule_title"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.schedule?.schedule_title}
                helperText={errors.schedule?.schedule_title?.message}
                required
                id="schedule_title"
                name="schedule_title"
                label={translations.scheduleTitle}
                fullWidth
                size="small"
              />
            )}
          />
          <CustomTitleBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/active-calendar.svg`}
              style={{
                height: "1.5rem",
                marginRight: "1rem",
              }}
            />
            <Box marginX={1} display="flex" alignItems="center">
              {translations.Allday}
            </Box>
            <Controller
              control={control}
              name="schedule.whole_day_flag"
              render={({ field }) => (
                <Switch
                  {...field}
                  required
                  checked={field.value}
                  id="whole_day_flag"
                  name="whole_day_flag"
                  onChange={handleWholeDayChange}
                />
              )}
            />
          </CustomTitleBox>
          <Box>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={language === "ja" ? ja : enUS}
            >
              <Box display="flex" justifyContent="space-between">
                <Box mr={1}>
                  <StartDateComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={schedule.start_date}
                    scheduleDayDefaultStartTime={0}
                    scheduleDayDefaultEndTime={0}
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                </Box>
                <Box>
                  <StartDateTimeComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={schedule.start_date}
                    scheduleDayDefaultStartTime={0}
                    scheduleDayDefaultEndTime={0}
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Box mr={1}>
                  <EndDateComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={schedule.end_date}
                    scheduleDayDefaultStartTime={0}
                    scheduleDayDefaultEndTime={0}
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                </Box>
                <Box>
                  <EndDateTimeComponent
                    validEndDateTime={validEndDateTime}
                    setValidEndDateTime={setValidEndDateTime}
                    validEndDate={validEndDate}
                    setValidEndDate={setValidEndDate}
                    setDateError={setDateError}
                    dateError={dateError}
                    defaultDate={schedule.end_date}
                    scheduleDayDefaultStartTime={0}
                    scheduleDayDefaultEndTime={0}
                    setErrorMessages={setErrorMessages}
                    checkMeetingOrSeat={checkMeetingOrSeat}
                  />
                </Box>
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography fontSize="0.9rem" mt="1rem">
              {getValues("schedule.schedule_date_type") === 1 && (
                <>
                  {getValues("schedule.weekly_or_monthly") === "weekly" && (
                    <>
                      <Box display="flex">
                        <Typography>{`${getValues(
                          "schedule.weekly.every_week"
                        )}${translations.Weekly}`}</Typography>
                        {repeatDays.map((day, index) => {
                          return (
                            <>
                              <Typography key={index} mx={1}>
                                {day}
                                {translations.DayOfWeek}
                              </Typography>
                            </>
                          )
                        })}
                      </Box>
                    </>
                  )}
                  {getValues("schedule.weekly_or_monthly") === "monthly" && (
                    <>
                      {monthlySetting === "day" && (
                        <Typography>
                          {`${getValues(
                            "schedule.monthly.dayOfWeekly.monthly"
                          )}${translations.everyMonths}`}
                          {`${getValues("schedule.monthly.dayly.day")}${
                            translations.day
                          }`}
                        </Typography>
                      )}
                      {monthlySetting === "dayOfWeek" && (
                        <Typography>
                          {`${getValues(
                            "schedule.monthly.dayOfWeekly.monthly"
                          )}${translations.everyMonths}`}
                          {`${
                            [
                              translations.first,
                              translations.second,
                              translations.third,
                              translations.fourth,
                              translations.last,
                            ][
                              getValues("schedule.monthly.dayOfWeekly.weekly") -
                                1
                            ]
                          }`}
                          {`${
                            [
                              translations.Sunday,
                              translations.Monday,
                              translations.Tuesday,
                              translations.Wednesday,
                              translations.Thursday,
                              translations.Friday,
                              translations.Saturday,
                            ][
                              getValues(
                                "schedule.monthly.dayOfWeekly.dayOfWeek"
                              )
                            ]
                          }${translations.DayOfWeek}`}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={repeatModalOpen}
            >
              {translations.RepeatSetting}
            </Button>
            <Modal
              open={isRepeatModalOpen}
              onClose={repeatModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <RepeatScheduleModal
                repeatModalClose={repeatModalClose}
                setRepeatDays={setRepeatDays}
                dayOfWeek={dayOfWeek}
                monthlySetting={monthlySetting}
                setMonthlySetting={setMonthlySetting}
              />
            </Modal>
          </Box>
          <CustomTitleButtonBox>
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/key.svg`}
              style={{
                height: "1.5rem",
                color: "#22BA9D",
              }}
            />
            <Box mr={1} display="flex" alignItems="center">
              {translations.public}
              <Controller
                control={control}
                name="schedule.is_secret"
                render={({ field }) => (
                  <Switch
                    {...field}
                    required
                    checked={!isSecret}
                    id="is_secret"
                    name="is_secret"
                    onChange={handleIsSecretChange}
                  />
                )}
              />
            </Box>
          </CustomTitleButtonBox>
          <CustomTitleButtonBox>
            <CustomTitle>{translations.Attendees}</CustomTitle>
          </CustomTitleButtonBox>
          <CustomContentBox>
            <Typography>
              {whoIsWheres[0].userable.last_name}
              {whoIsWheres[0].userable.first_name}
            </Typography>
          </CustomContentBox>
          <CustomTitleBox>
            <MailOutlineIcon
              color="primary"
              sx={{
                height: "1.2rem",
                padding: "0 5px 0 0",
              }}
            />
            <CustomTitle marginLeft={1}>{translations.Message}</CustomTitle>
          </CustomTitleBox>
          <Editor
            editorStyle={{ height: "70px" }}
            toolbar={{
              // options: ["inline"],
              options: ["inline", "list"],
              inline: {
                options: ["bold", "underline", "italic"],
              },
              list: { options: ["ordered", "unordered"] },
            }}
            localization={{ locale: "ja" }}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(editorState) => setEditorState(editorState)}
            onChange={() =>
              setValue(
                "schedule.message_content",
                draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()))
              )
            }
          />
          <CustomTitleButtonBox>
            <CustomTitle>{translations.AddDeskMeetingRoom}</CustomTitle>
            <Box>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={resetPlace}
                sx={{ mr: 1 }}
              >
                {translations.Reset}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={addPlaceModalOpen}
              >
                {translations.Add}
              </Button>
            </Box>
            <Modal
              open={isAddPlaceModalOpen}
              onClose={addPlaceModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <AddPlaceModal
                addPlaceModalClose={addPlaceModalClose}
                checkedSeatId={checkedSeatId}
                setCheckedSeatId={setCheckedSeatId}
                checkedMeetingRoomId={checkedMeetingRoomId}
                setCheckedMeetingRoomId={setCheckedMeetingRoomId}
                setIsSeatOrMeetingRoom={setIsSeatOrMeetingRoom}
                handleFixPlace={handleFixPlace}
                handleCancelSelectPlace={handleCancelSelectPlace}
                setCheckedSeat={setCheckedSeat}
                setCheckedMeetingRoom={setCheckedMeetingRoom}
                startDateTime={getValues("schedule.start_time")}
                endDateTime={getValues("schedule.end_time")}
                getValues={getValues}
                employeeProfile={employeeProfile}
                companyRelations={companyRelations}
                isWholeDay={wholeDay}
              />
            </Modal>
          </CustomTitleButtonBox>
          <CustomContentBox>{selectedPlace}</CustomContentBox>
        </CustomModalBox>
      </FormProvider>
    </>
  )
}
