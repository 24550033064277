import React, { useState } from "react"

import { Box, styled, Tooltip, Typography } from "@mui/material"

import { Schedule } from "../../../../../models/employee/useLayout/status/type"
import { formatTime } from "../../../../../utils/time"
import { EmployeeIconWrapper } from "../../../../public/EmployeeIconWrapper"

const SearchedEmployeeList = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "repeat(12, 1fr)",
  gridTemplateColumns: `repeat(auto-fit, ${theme.spacing(9)})`,
  alignItems: "center",
  justifyItems: "center",
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(0.5),
  width: "100%",
  height: "calc(100vh - 235px)",
}))

interface Props {
  schedules: Schedule[]
}

export const EmployeeStatusMain = ({ schedules }: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState<number | undefined>(undefined)

  const tooltipText = (schedule: Schedule) => {
    const userName = `${schedule.userable.last_name}${schedule.userable.first_name}\n`
    const scheduleTime = `${formatTime(schedule.start_time)}~${formatTime(
      schedule.end_time
    )}`
    return `${userName}${scheduleTime}`
  }

  const handleTooltipOpen = (scheduleId: number) => {
    setTooltipOpen(scheduleId)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(undefined)
  }

  return (
    <SearchedEmployeeList>
      {schedules.map((schedule) => {
        return (
          <Box
            key={schedule.id}
            onMouseEnter={() => handleTooltipOpen(schedule.id)}
            onMouseLeave={handleTooltipClose}
            height="fit-content"
            sx={{
              position: "relative",
            }}
          >
            <Tooltip
              title={tooltipText(schedule)}
              placement="bottom-start"
              disableInteractive
              open={tooltipOpen === schedule.id} // 複数のtoolTipが存在するので、ホバーしたscheduleに一致するもののみがopenされるようにする
              componentsProps={{
                tooltip: {
                  sx: {
                    whiteSpace: "pre-wrap",
                    width: "100px",
                  },
                },
              }}
            >
              <EmployeeIconWrapper
                sx={{
                  "&::before": {
                    zIndex: 1,
                    content: '""',
                    position: "absolute",
                    transform: "translate(-50%,-50%) rotate(45deg)",
                    borderRadius: "50%",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    height: "100%",
                    backgroundColor: schedule.is_highlighted
                      ? "rgba(34, 186, 157, 0.4)"
                      : "inherit",
                  },
                }}
              >
                {schedule.image_blob === "" ? (
                  <Typography variant="h6" color="inherit">
                    {schedule.userable.last_name[0]}
                    {schedule.userable.first_name[0]}
                  </Typography>
                ) : (
                  <img
                    src={schedule.image_blob}
                    style={{
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </EmployeeIconWrapper>
            </Tooltip>
          </Box>
        )
      })}
    </SearchedEmployeeList>
  )
}
