import React from "react"
import ReactDOM from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"

import { ThemeProvider, CssBaseline } from "@mui/material"

import { CustomHeadTag } from "./components/public/CustomHeadTag"
import { theme } from "./config/theme"
import { LanguageProvider } from "./contexts/LanguageContext"
import reportWebVitals from "./reportWebVitals"
import { Router } from "./router"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <CssBaseline />
        <LanguageProvider>
          <Router />
        </LanguageProvider>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
