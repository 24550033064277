import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  createTeamRequest,
  TeamListRequest,
  editTeamRequest,
  updateTeamRequest,
  deleteTeamRequest,
  TeamHierarchyListRequest,
} from "../../../api/company/team/teamRequest"
import { FULL_WIDTH_KATAKANA, TEAM_NAME_REGEX } from "../../../utils/regular"
import { useIcon } from "../../public/useIcon"
import {
  CompanyTeamFormType,
  TeamListResponseType,
  TeamHierarchyListResponseType,
} from "./type"

const companyTeamData: CompanyTeamFormType = {
  id: 0,
  team_name: "",
  team_name_kana: "",
  team_leader_name: "",
  parent_id: 0,
  depth: 0,
  employee_id: 0,
}

const initialTeamHierarchyListData: TeamHierarchyListResponseType = {
  id: 0,
  team_name: "",
  team_name_kana: "",
  team_leader_name: "",
  parent_id: 0,
  depth: 0,
  children: [],
}

export const useTeam = () => {
  const navigate = useNavigate()
  const { fetchFile } = useIcon()
  const [team] = useState<CompanyTeamFormType>(companyTeamData)
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const [teamList, setTeamList] = useState<TeamListResponseType[]>([])
  const [teamHierarchyList, setTeamHierarchyList] =
    useState<TeamHierarchyListResponseType>(initialTeamHierarchyListData)

  const basicSchema = Yup.object().shape({
    team_name: Yup.string()
      .required("必須になります")
      .matches(
        TEAM_NAME_REGEX,
        '全角ひらがな、カタカナ、漢字、半角アルファベット、半角数字、半角スペース、全角数字、全角スペース、全角アルファベット、記号 ( ) ・ ： ； 。 、 （） 「」 『』 【】 - — / \\ _ @ # $ % & * + = ^ { } < > | 〜 : ; ’ " ! ? ！ ＠ ＃ ＄ ％ ＾ ＆ ＊ ＿ ＋ ＝ ＜ ＞ ？ ￥ で入力してください'
      ),
    team_name_kana: Yup.string()
      .required("必須になります")
      .matches(FULL_WIDTH_KATAKANA, "全角カタカナになります"),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      id: team.id,
      team_name: team.team_name,
      team_name_kana: team.team_name_kana,
      team_leader_name: team.team_leader_name,
      parent_id: team.parent_id,
      depth: team.depth,
      employee_id: team.employee_id,
    },
    resolver: yupResolver(basicSchema),
  })

  const onCreateSubmit: SubmitHandler<CompanyTeamFormType> = useCallback(
    async (data) => {
      try {
        const { error } = await createTeamRequest({ ...data, id: undefined })
        if (!error) {
          localStorage.setItem("alertContent", "組織の登録が完了しました")
          navigate("/company/teams")
        } else {
          setErrorMessageOpen(true)
          setErrorMessages(error)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const fetchTeamList = useCallback(async () => {
    try {
      const response = await TeamListRequest()
      if (response.status === 200 && response.data) {
        setTeamList(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
  }, [])

  const editTeam = useCallback(async (targetTeamId: number) => {
    setLoading(true)
    try {
      const response = await editTeamRequest({
        team_id: targetTeamId,
      })
      if (response.status === 200 && response.data) {
        reset({
          id: response.data.id,
          team_name: response.data.team_name,
          team_name_kana: response.data.team_name_kana,
          team_leader_name: response.data.team_leader_name,
          parent_id: response.data.parent_id,
          depth: response.data.depth,
          employee_id: response.data.employee_id,
        })
        setForbidden(false)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 403) {
        setForbidden(true)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const onUpdateSubmit: SubmitHandler<CompanyTeamFormType> = useCallback(
    async (data) => {
      try {
        const { error } = await updateTeamRequest({
          ...data,
        })
        if (!error) {
          localStorage.setItem("alertContent", "組織の更新が完了しました")
          navigate(`/company/teams`)
        } else {
          setErrorMessageOpen(true)
          setErrorMessages(error)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const deleteTeam = useCallback(async (targetTeamId: number) => {
    setLoading(true)
    try {
      const response = await deleteTeamRequest({
        team_id: targetTeamId,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "組織の削除が完了しました")
        navigate(`/company/teams`)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
      if (error.response.status === 422) {
        const errorMessage = (() => {
          switch (error.response.data.message[0]) {
            case "TeamHasEmployeeError":
              return `対象の所属に従業員が登録されています。従業員管理画面から、所属で検索し、対象の所属を所属とする従業員がいない状態にしてから、再度削除してください。\n※利用停止中社員の所属の場合もある為、利用停止中社員についてもお確かめください。`
            case "TeamHasChildrenError":
              return `対象の所属に紐づいた所属があります。紐づいた所属の紐づけ先を変更し、紐づいた所属がない状態にしてから、再度削除してください。`
            default:
              return `組織の削除に失敗しました。`
          }
        })()
        localStorage.setItem("alertErrorContent", errorMessage)
        window.location.reload()
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchTeamHierarchyList = useCallback(async () => {
    try {
      const response = await TeamHierarchyListRequest()
      if (response.status === 200 && response.data) {
        setTeamHierarchyList(response.data)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    control,
    team,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    watch,
    errorMessages,
    setErrorMessages,
    fetchFile,
    editTeam,
    deleteTeam,
    loading,
    errorMessageOpen,
    setErrorMessageOpen,
    teamList,
    fetchTeamList,
    onCreateSubmit,
    onUpdateSubmit,
    forbidden,
    fetchTeamHierarchyList,
    teamHierarchyList,
  }
}
