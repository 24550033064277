import { addDays, subYears, compareAsc } from "date-fns"
import ja from "date-fns/locale/ja"

import React, { useContext, useEffect, useState } from "react"
import { Controller } from "react-hook-form"

import {
  Box,
  Button,
  TableContainer,
  Typography,
  styled,
  Stack,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { EmployeeAttendanceRate } from "../../../components/company/report/EmployeeAttendanceRate"
import { TeamAttendanceRate } from "../../../components/company/report/TeamAttendanceRate"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { GreenArrowIcon } from "../../../components/public/GreenArrowIcon"
import { Loading } from "../../../components/public/Loading"
import { Paging } from "../../../components/public/Pagination"
import { theme } from "../../../config/theme"
import { useContractPlan } from "../../../models/company/useContractPlan"
import { useReportAttendance } from "../../../models/company/useReport"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import { RoleContext } from "../../../providers/RoleProvider"
import { generateButtonStyle } from "../../../utils/generateButtonStyle"

const CustomSelect = styled(Select)(({ theme }) => ({
  width: "240px",
  height: "40px",
  backgroundColor: "#7070700d",
}))

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  fontSize: theme.spacing(1.5),
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#000000de",
  fontSize: theme.spacing(2),
  padding: theme.spacing(1, 1.5),
  width: "150px",
  height: "40px",
  outline: "1px solid #00000029",
}))

export const CompanyReportAttendancePage = () => {
  const {
    fetchReportAttendanceList,
    onSearchReportAttendanceListSubmit,
    searchedReportAttendanceList,
    control,
    errors,
    isValid,
    dirtyFields,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    dateFormat,
    loading,
    setLoading,
    searchParams,
  } = useReportAttendance()
  const { companyRelations } = useCompanyRelations()
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0)
  const [selectedTeamId, setSelectedTeamId] = useState<number>(0)
  const [displayPage, setDisplayPage] = useState<"employee" | "team">(
    "employee"
  )
  const { isTeamLeader } = useContext(RoleContext)

  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [isSearched, setIsSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)

  // 選択期間開始日の処理
  const { fetchContractPlan, contractPlan } = useContractPlan()
  // 利用開始日
  const registrationStartDate = new Date(
    contractPlan.active_contract.start_date
  )
  // 現時刻から一年前
  const oneYearBefore = subYears(new Date(), 1)
  // 比較して選択できる開始期間にどちらを当てるか決める
  const minDate =
    compareAsc(oneYearBefore, registrationStartDate) < 0
      ? registrationStartDate
      : oneYearBefore

  let defaultStartDate = addDays(new Date(), -15)
  if (compareAsc(defaultStartDate, minDate) == -1) {
    defaultStartDate = addDays(new Date(), -1)
  }
  const defaultEndDate = addDays(new Date(), -1)
  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(defaultEndDate)
  const maxDate = addDays(new Date(), -1)
  const disabledStyle = isTeamLeader
    ? { color: "gray", cursor: "not-allowed" }
    : {}

  // 日付選択時の処理
  const handleDateChange = (
    newValue: Date | null,
    setStartDate: React.Dispatch<React.SetStateAction<Date>>,
    setEndDate: React.Dispatch<React.SetStateAction<Date>>,
    targetSetDate: "start_date" | "end_date"
  ) => {
    if (newValue !== null) {
      const dateString = dateFormat(newValue)

      // フォームの値のみを更新し、データ取得は行わない
      setValue(targetSetDate, dateString)

      switch (targetSetDate) {
        case "start_date": {
          // 選択したstart_dateがend_dateより未来の場合、end_dateも選択した日付に書き換える
          if (newValue > endDate) {
            setEndDate(new Date(newValue))
          }
          setStartDate(new Date(newValue))
          return
        }
        case "end_date": {
          // 選択したend_dateがstart_dateより過去の場合、start_dateも選択した日付に書き換える
          if (newValue < startDate) {
            setStartDate(new Date(newValue))
          }
          setEndDate(new Date(newValue))
          return
        }
      }
    }
  }

  const selectedBranch = companyRelations.branches.find(
    (branch) => branch.id === selectedBranchId
  )

  const switchSearchLabel = (displayPage: "employee" | "team") => {
    switch (displayPage) {
      case "employee":
        return "従業員名から検索する。"
      case "team":
        return "部署名から検索する。"
    }
  }

  useEffect(() => {
    fetchContractPlan()
  }, [])

  useEffect(() => {
    setValue("start_date", dateFormat(startDate))
    setValue("end_date", dateFormat(endDate))
  }, [])

  // 検索ボタンのクリックイベントの処理
  const handleSearchSubmit = async (data: any) => {
    setIsSearching(true)
    setLoading(true)

    const params = {
      ...data,
      page: page,
      rowsPerPage: rowsPerPage,
    }
    const result = await fetchReportAttendanceList(params)

    if (result) {
      setTotalPages(result.total_pages || 1)
    }

    setIsSearching(false)
    setIsSearched(true)
    setLoading(false)
  }

  const handlePaginationChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    if (searchParams) {
      setLoading(true) // ページ遷移時にローディング状態を true に設定
      const params = {
        ...searchParams,
        page: value,
        rowsPerPage: rowsPerPage,
      }
      const result = await onSearchReportAttendanceListSubmit(params)

      if (result) {
        setTotalPages(result.total_pages || 1)
      }
      setIsSearched(true) // ページ遷移後にisSearchedをtrueに設定
      setLoading(false) // ページ遷移終了時にローディング状態を false に設定
    }
  }

  return (
    <>
      {loading && <Loading type="content" loading={loading} />}
      <ContentCompanyPaper>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box display="flex">
              <Stack spacing={2}>
                {displayPage === "team" && (
                  <Stack direction="row" spacing="20px">
                    <Controller
                      control={control}
                      name="team_id"
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setSelectedTeamId(e.target.value as number)
                          }}
                          error={!!errors.team_id}
                          IconComponent={GreenArrowIcon}
                          sx={{ width: "500px" }}
                        >
                          <MenuItem key={-1} value={-1} disabled>
                            所属
                          </MenuItem>
                          {!isTeamLeader && (
                            <MenuItem key={0} value={0}>
                              全ての所属
                            </MenuItem>
                          )}
                          {companyRelations.teams.map((team) => (
                            <MenuItem key={team.id} value={team.id}>
                              {team.team_name}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      )}
                    />
                  </Stack>
                )}

                {displayPage === "employee" && (
                  <Stack direction="row" spacing="20px">
                    <Controller
                      control={control}
                      name="branch_id"
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setValue("floor_id", 0)
                            setSelectedBranchId(e.target.value as number)
                          }}
                          error={!!errors.branch_id}
                          IconComponent={
                            isTeamLeader ? () => null : GreenArrowIcon
                          } // hide the icon when isTeamLeader is true
                          style={disabledStyle}
                          disabled={isTeamLeader}
                        >
                          <MenuItem key={-1} value={-1} disabled>
                            拠点
                          </MenuItem>
                          <MenuItem key={0} value={0}>
                            全拠点
                          </MenuItem>
                          {companyRelations.branches.map((branch) => (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.branch_name}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      )}
                    />
                    <Controller
                      control={control}
                      name="floor_id"
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          size="small"
                          IconComponent={
                            isTeamLeader ? () => null : GreenArrowIcon
                          } // hide the icon when isTeamLeader is true
                          style={disabledStyle}
                          disabled={isTeamLeader}
                        >
                          <MenuItem key={0} value={0}>
                            フロア（全て）
                          </MenuItem>
                          {selectedBranch &&
                            selectedBranch.floors.map((floor) => (
                              <MenuItem key={floor.id} value={floor.id}>
                                {floor.floor_name}
                              </MenuItem>
                            ))}
                        </CustomSelect>
                      )}
                    />
                    <Controller
                      control={control}
                      name="team_id"
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          size="small"
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            setSelectedTeamId(e.target.value as number)
                          }}
                          error={!!errors.team_id}
                          IconComponent={GreenArrowIcon}
                        >
                          <MenuItem key={-1} value={-1} disabled>
                            所属
                          </MenuItem>
                          {!isTeamLeader && (
                            <MenuItem key={0} value={0}>
                              全ての所属
                            </MenuItem>
                          )}
                          {companyRelations.teams.map((team) => (
                            <MenuItem key={team.id} value={team.id}>
                              {team.team_name}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      )}
                    />
                  </Stack>
                )}
                <Stack direction="row" spacing="20px">
                  <Controller
                    control={control}
                    name={"freeword"}
                    render={({ field }) => (
                      <>
                        <CustomTextField
                          {...field}
                          id="search_word_id"
                          label={switchSearchLabel(displayPage)}
                          variant="outlined"
                          fullWidth
                          error={!!errors.freeword}
                          helperText={errors.freeword?.message}
                          size="small"
                          sx={{ width: "500px" }}
                          onChange={(e) => {
                            field.onChange(e)
                          }}
                          onSubmit={handleSubmit(handleSearchSubmit)}
                        />

                        <Button
                          variant="outlined"
                          disabled={
                            (isTeamLeader && getValues("team_id") === -1) ||
                            Object.keys(dirtyFields).length === 0 ||
                            !isValid
                          }
                          size="small"
                          sx={{
                            height: "40px",
                            width: "100px",
                            fontWeight: "bold",
                          }}
                          onClick={async () => {
                            await new Promise((resolve) =>
                              setTimeout(resolve, 0)
                            )
                            handleSubmit(onSearchReportAttendanceListSubmit)()
                            setIsSearched(true)
                            setPage(1)
                          }}
                        >
                          検索
                        </Button>
                      </>
                    )}
                  />
                </Stack>
              </Stack>
            </Box>
            <FormHelperText error={!!errors.require?.message}>
              {errors.require?.message}
            </FormHelperText>
          </Box>
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
              <CustomButton
                onClick={() => {
                  setDisplayPage("team")
                  setValue("target", "team")
                  clearErrors()
                }}
                sx={generateButtonStyle(displayPage === "team")}
              >
                部署毎
              </CustomButton>
              <CustomButton
                onClick={() => {
                  setValue("target", "employee")
                  setDisplayPage("employee")
                  clearErrors()
                }}
                sx={generateButtonStyle(displayPage === "employee")}
              >
                従業員毎
              </CustomButton>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <Box>
                  <DesktopDatePicker
                    format="yyyy/MM/dd"
                    value={startDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(event) => {
                      handleDateChange(
                        event,
                        setStartDate,
                        setEndDate,
                        "start_date"
                      )
                    }}
                    slotProps={{
                      textField: {
                        name: "start_date",
                        id: "start_date",
                        required: true,
                        size: "small",
                        sx: {
                          width: "150px",
                          fontSize: theme.spacing(1.5),
                        },
                      },
                      popper: {
                        sx: {
                          "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                            {
                              color: "rgba(0, 0, 0, 0.3)",
                            },
                        },
                      },
                      inputAdornment: {
                        sx: {
                          "& .MuiIconButton-root": {
                            padding: "0",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>
              <Typography>〜</Typography>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <Box>
                  <DesktopDatePicker
                    format="yyyy/MM/dd"
                    value={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(event) =>
                      handleDateChange(
                        event,
                        setStartDate,
                        setEndDate,
                        "end_date"
                      )
                    }
                    slotProps={{
                      textField: {
                        name: "end_date",
                        id: "end_date",
                        required: true,
                        size: "small",
                        sx: {
                          width: "150px",
                          fontSize: theme.spacing(1.5),
                        },
                      },
                      popper: {
                        sx: {
                          "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                            {
                              color: "rgba(0, 0, 0, 0.3)",
                            },
                        },
                      },
                      inputAdornment: {
                        sx: {
                          "& .MuiIconButton-root": {
                            padding: "0",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Stack>
          </Stack>
        </Box>
        {!loading && isSearched && (
          <TableContainer>
            {displayPage === "employee" ? (
              <div>
                <EmployeeAttendanceRate
                  employeeAttendanceList={
                    searchedReportAttendanceList?.employees
                  }
                />
                {(searchedReportAttendanceList?.total_pages ?? 0) > 1 && (
                  <Paging
                    page={page}
                    pageCount={searchedReportAttendanceList?.total_pages ?? 1}
                    handlePaginationChange={handlePaginationChange}
                  />
                )}
              </div>
            ) : displayPage === "team" ? (
              <TeamAttendanceRate
                teamAttendanceList={searchedReportAttendanceList?.teams}
              />
            ) : null}
          </TableContainer>
        )}
        {!loading && !isSearched && (
          <Typography
            variant="h5"
            align="center"
            style={{ margin: "auto", marginTop: "20vh" }}
          ></Typography>
        )}
      </ContentCompanyPaper>
    </>
  )
}
