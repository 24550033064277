const en = {
  LayoutNot: "Layout does not exist.",
  Location: "location",
  Floor: "floor",
  month: " / ",
  day: "",
  AllDay: "ALL DAY",
  weekdays: ["(Sun)", "(Mon)", "(Tue)", "(Wed)", "(Thu)", "(Fri)", "(Sat)"],
  Desk: "Desk",
  Name: "Name",
  Subject: "Subject",
}
export default en
