import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

import { employeeListSearchRequest } from "../../../api/company/employees/employeeListSearchRequest"
import {
  employeeListRequest,
  pausedEmployeeRequest,
  releaseEmployeeRequest,
} from "../../../api/company/employeesRequest"
import { downloadIcon } from "../../../utils/downLoadIcon"
import { useAlertMessage } from "../../../utils/isAlertMessage"
import { useAuth } from "../../public/useAuth"
import { EmployeeListResponseType } from "../useEmployee/type"
import { SearchEmployeeListRequestType } from "./type"

const initialSearchEmployeeParams: SearchEmployeeListRequestType = {
  freeword: "",
  branch_id: 0,
  floor_id: 0,
  team_id: -1,
  include_unavailable: false,
  admin_only: false,
  unavailable_only: false,
}

export const useSearchEmployeeList = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [employeeList, setEmployeeList] = useState<EmployeeListResponseType[]>(
    []
  )

  const [searchEmployeeParams] = useState<SearchEmployeeListRequestType>(
    initialSearchEmployeeParams
  )

  const { createUsers } = useAuth()
  const { inviteUsers } = useAuth()
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [successMessageOpen, setSuccessMessageOpen] = useState<boolean>(false)
  const pausedErrorMessage = useAlertMessage()

  // 必須項目（従業員名、拠点、所属のいずれか）のバリデーション
  const validateRequireValues = function (this: any) {
    const { freeword, branch_id, team_id } = this.parent
    return !(freeword.length === 0 && branch_id === 0 && team_id === -1)
  }

  const basicSchema = Yup.object().shape({
    freeword: Yup.string()
      .test("require_freeword", "", validateRequireValues)
      .test("maxlength", "50文字以下で入力してください", (freeword) => {
        if (freeword == undefined) {
          return true
        }
        return freeword.length <= 50
      }),
    branch_id: Yup.number().test(
      "require_branch_id",
      "",
      validateRequireValues
    ),
    floor_id: Yup.number().min(0, "フロアを選択してください"),
    team_id: Yup.number().test("require_team_id", "", validateRequireValues),
    require: Yup.boolean().test(
      "require_validation",
      "従業員名、拠点、所属のいずれかが必須です",
      validateRequireValues
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      freeword: searchEmployeeParams.freeword,
      branch_id: searchEmployeeParams.branch_id,
      floor_id: searchEmployeeParams.floor_id,
      team_id: searchEmployeeParams.team_id,
      include_unavailable: searchEmployeeParams.include_unavailable,
      admin_only: searchEmployeeParams.admin_only,
      unavailable_only: searchEmployeeParams.unavailable_only,
    },
    resolver: yupResolver(basicSchema),
  })

  const initializeEmployeeList = useCallback(async () => {
    setLoading(true)
    try {
      const response = await employeeListRequest()
      if (response.status === 200 && response.data) {
        setEmployeeList(response.data)
        // iconをS3と同期
        const fetchIconsPromises = response.data.map(
          async (employee: EmployeeListResponseType) => {
            if (employee.icon)
              employee.icon = await downloadIcon(
                employee.account.company_id,
                employee.id,
                employee.icon
              )
            return await { ...employee, checked: false }
          }
        )
        Promise.all(fetchIconsPromises).then(setEmployeeList)
      }
    } catch (error: any) {
      console.log("error")
    } finally {
      setLoading(false)
    }
  }, [])

  const onSearchEmployeeListSubmit: SubmitHandler<SearchEmployeeListRequestType> =
    useCallback(async (data) => {
      setLoading(true)
      try {
        const response = await employeeListSearchRequest(data)
        if (response.status == 200 && response.data) {
          const defaultSearchEmployeeResult = response.data.map((employee) => {
            return { ...employee, checked: false }
          })
          setEmployeeList(defaultSearchEmployeeResult)
          // iconをS3と同期
          const fetchIconsPromises = response.data.map(
            async (employee: EmployeeListResponseType) => {
              if (employee.icon)
                employee.icon = await downloadIcon(
                  employee.account.company_id,
                  employee.id,
                  employee.icon
                )
              return await { ...employee, checked: false }
            }
          )
          Promise.all(fetchIconsPromises).then(setEmployeeList)
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }, [])

  // TODO ↓とCSVダウンロード機能等のコードが重複しているため共通化したい
  // /src/models/company/useEmployee/index.ts

  // 個別に従業員を選択する
  const handleEmployeeChecked = (employeeId: number) => {
    const newEmployeeList = employeeList.map((employee) => {
      if (employee.id === employeeId) {
        return { ...employee, checked: !employee.checked }
      } else {
        return employee
      }
    })

    setEmployeeList(newEmployeeList)
  }

  // 全従業員を選択済みにする
  const handleAllEmployeeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmployeeList = employeeList.map((employee) => {
      return { ...employee, checked: e.target.checked }
    })

    setEmployeeList(newEmployeeList)
  }

  // チェックを入れたユーザーについて、AzureADとの連携が
  // あれば招待メールを送信し、なければCognito上にアカウントを作成する
  const handleCreateUserOnCognito = async () => {
    setLoading(true)
    try {
      // チェック済みの従業員のリスト
      const checkedEmployeeList = employeeList.filter((employee) => {
        return (
          employee.checked && employee.account.available_status === "active" // アカウント利用中の従業員のみ対象
        )
      })

      if (checkedEmployeeList.length === 0) return

      // チェック済みの従業員のメールアドレスのリスト
      const emailList = []
      const emailListForAzureAd = []
      const sendStatusList = []

      // チェック済みの従業員のメールアドレスのリストを条件分岐で振り分ける
      for (const employee of checkedEmployeeList) {
        if (employee.account.object_id) {
          emailListForAzureAd.push(employee.account.email) // AzureADユーザー
          sendStatusList.push(employee.account.send_status)
        } else {
          emailList.push(employee.account.email) // その他のユーザー
        }
      }

      // 各ユーザーへの処理
      await handleCognito(emailList)
      await handleAzureAd(emailListForAzureAd, sendStatusList)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleCognito = async (emailList: string[]) => {
    try {
      const { error } = await createUsers(emailList)

      if (!error) {
        setSuccessMessageOpen(true)
        setSuccessMessage("認証メールの送信に成功しました。")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAzureAd = async (
    emailListForAzureAd: string[],
    sendStatusList: boolean[]
  ) => {
    try {
      const { error } = await inviteUsers(emailListForAzureAd, sendStatusList)
      if (!error) {
        setSuccessMessageOpen(true)
        setSuccessMessage("メールの送信に成功しました。")
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 従業員の利用停止をする
  const handlePausedEmployee = async (isDeleteSchedules: boolean) => {
    try {
      // チェック済みの従業員のリスト
      const checkedEmployeeList = employeeList.filter((employee) => {
        return (
          employee.checked && employee.account.available_status === "active"
        ) // アカウント利用中の従業員のみ対象
      })

      if (checkedEmployeeList.length === 0) return

      // チェック済みの従業員のメールアドレスのリスト
      const accountIdList = checkedEmployeeList.map((employee) => {
        return employee.account.id
      })

      const { error } = await pausedEmployeeRequest(
        accountIdList,
        isDeleteSchedules
      )

      if (!error) {
        await initializeEmployeeList()
        setSuccessMessageOpen(true)
        setSuccessMessage("選択した利用中のアカウントを利用停止しました")
      } else {
        pausedErrorMessage.handleSetMessage(error[0])
        pausedErrorMessage.openMessage()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 従業員の利用停止を解除する
  const handleReleaseEmployee = async () => {
    try {
      // チェック済みの従業員のリスト
      const checkedEmployeeList = employeeList.filter((employee) => {
        return (
          employee.checked && employee.account.available_status === "paused"
        ) // アカウントの利用停止中の従業員のみ対象
      })

      if (checkedEmployeeList.length === 0) return

      // チェック済みの従業員のメールアドレスのリスト
      const accountIdList = checkedEmployeeList.map((employee) => {
        return employee.account.id
      })

      const { error } = await releaseEmployeeRequest(accountIdList)

      if (!error) {
        await initializeEmployeeList()
        setSuccessMessageOpen(true)
        setSuccessMessage("選択した利用停止中のアカウントを解除しました")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    control,
    errors,
    clearErrors,
    loading,
    isValid,
    getValues,
    setValue,
    handleSubmit,
    onSearchEmployeeListSubmit,
    employeeList,
    initializeEmployeeList,
    handleEmployeeChecked,
    handleAllEmployeeChecked,
    handleCreateUserOnCognito,
    handlePausedEmployee,
    handleReleaseEmployee,
    successMessage,
    successMessageOpen,
    setSuccessMessageOpen,
    pausedErrorMessage,
  }
}
