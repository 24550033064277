import React, { memo } from "react"

import { Snackbar, Alert } from "@mui/material"

interface Props {
  isMessageOpen: boolean
  closeMessage: () => void
  alertMessage: string
}

export const AlertError: React.FC<Props> = memo(
  ({ isMessageOpen, closeMessage, alertMessage }: Props) => {
    const handleClose = () => {
      closeMessage()
    }

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMessageOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%", whiteSpace: "pre-wrap" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </>
    )
  }
)
