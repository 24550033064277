const en = {
  schedule: "Schedule",
  Schedule: "Schedule",
  All: "All",
  Desks: "Desks",
  Events: "Events",
  Calendar: "Calendar",
  Year: " /",
  month: " /",
  monthCalendar: "",
  Month: "M",
  week: "week",
  Week: "W",
  dayOfWeek: "day of week",
  day: "",
  Day: "D",
  weekdays: ["(Sun)", "(Mon)", "(Tue)", "(Wed)", "(Thu)", "(Fri)", "(Sat)"],
}
export default en
