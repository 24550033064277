import React from "react"
import { useNavigate } from "react-router-dom"

import { Grid, Box, styled, Typography } from "@mui/material"

import { useSyncLanguageAcrossTabs } from "../../../../src/utils/useSyncLanguageAcrossTabs"
import { useLanguage } from "../../../contexts/LanguageContext"
import { ProfileResponseType } from "../../../models/employee/useProfile/type"
import enTranslations from "../../../translations/mobileHeader/en"
import jaTranslations from "../../../translations/mobileHeader/ja"
import { CancelButton } from "../../public/CancelButton"

const CustomBox = styled(Box)(() => ({
  justifyContent: "center",
}))

const ItemTypography = styled(Typography)(() => ({
  fontSize: "14px",
  color: "gray",
}))

const ContentTypography = styled(Typography)(() => ({
  fontSize: "14px",
  color: "black",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  justifyContent: "center",
}))

const CustomGridLabel = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  paddingLeft: theme.spacing(1),
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
}))

interface Props {
  employeeProfile: ProfileResponseType
}

export const MobileEmployeeProfileShowData: React.FC<Props> = ({
  employeeProfile,
}: Props) => {
  //  言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  useSyncLanguageAcrossTabs()

  const navigate = useNavigate()
  const isSeatStr = (flag: number) => {
    return flag === null ? translations.No : translations.Yes
  }

  return (
    <>
      <CustomBox>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.EmployeeName}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="last_name_id">
              {employeeProfile.last_name}
            </ContentTypography>
            <ContentTypography
              id="first_name_id"
              style={{ paddingLeft: "1rem" }}
            >
              {employeeProfile.first_name}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.EmployeeNameKana}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="last_name_kana_id">
              {employeeProfile.last_name_kana}
            </ContentTypography>
            <ContentTypography
              id="first_name_kana_id"
              style={{ paddingLeft: "1rem" }}
            >
              {employeeProfile.first_name_kana}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.Email}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="email_id">
              {employeeProfile.account.email}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.EmployeeId}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="employee_id">
              {employeeProfile.employee_code}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.CompanyPhoneNumber}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="phone_id">
              {employeeProfile.phone_number}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.Location}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="branch_name_id">
              {employeeProfile.branch.branch_name}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.Floor}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="floor_number_id">
              {employeeProfile.floor.floor_name}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.DivisionDepartment}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="team_name_id">
              {employeeProfile.team.team_name}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.ManagerName}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="team_leader_name_id">
              {employeeProfile.team.team_leader_name}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.ManagerEmail}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="team_leader_email_id">
              {employeeProfile.team.team_leader_email}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.WorkArrangement}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="remote_flag_id">
              {employeeProfile.remote_flag
                ? translations.FullTime
                : translations.OfficeWork}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.AssignedDesk}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="is_seat">
              {isSeatStr(employeeProfile.seat_id)}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer
          container
          style={{ display: employeeProfile.seat_id ? "" : "none" }}
        >
          <CustomGridLabel item xs={5} sm={5}>
            <ItemTypography>{translations.AssignedDeskId}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={7} sm={5}>
            <ContentTypography id="seat_id">
              {employeeProfile.seat_id}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <ItemTypography>{translations.Remarks}</ItemTypography>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5} pl={2} pr={2}>
            <ContentTypography id="note">
              {employeeProfile.note}
            </ContentTypography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CancelButton
            label={translations.Return}
            handleClick={() => navigate("/mobile/home")}
          />
        </CustomGridContainer>
      </CustomBox>
    </>
  )
}
