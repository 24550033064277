import React from "react"

import { CircularProgress, Modal, Paper, styled } from "@mui/material"

const LoadingWrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  zIndex: theme.zIndex.tooltip,
  width: "100vw",
  height: "100vh",
  position: "absolute",
}))

const modalStyle = {
  width: "100vw",
  height: "100vh",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

type LoadingType = "fullScreen" | "content"

interface Props {
  type: LoadingType
  loading?: boolean
}

// ローディング画面
export const Loading: React.FC<Props> = ({ type, loading }: Props) => {
  return (
    <>
      {type === "fullScreen" && (
        <LoadingWrapper>
          <CircularProgress size={64} />
        </LoadingWrapper>
      )}
      {type === "content" && (
        <Modal
          sx={modalStyle}
          open={!!loading}
          onClose={() => null}
          disableAutoFocus
        >
          <CircularProgress size={64} />
        </Modal>
      )}
    </>
  )
}
