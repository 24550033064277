import React, { memo } from "react"

import { Box, styled } from "@mui/material"

const CustomIcon = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#707070",
  transform: "rotate(90deg)",
  boxSizing: "border-box",
}))

export const NotCheckInSeat = memo(() => {
  return <CustomIcon />
})
