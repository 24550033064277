import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { updatePaymentMethodsRequest } from "../../../api/company/companyRequest"

export const usePaymentMethods = () => {
  const navigate = useNavigate()
  const [paymentMethods, setPaymentMethods] = useState<number>(0)

  const updatePaymentMethods = async () => {
    try {
      const response = await updatePaymentMethodsRequest({
        payment_methods: paymentMethods,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "支払い方法を更新しました")
        navigate(`/company/settings/payment`)
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    paymentMethods,
    updatePaymentMethods,
    setPaymentMethods,
  }
}
