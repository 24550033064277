import React, { memo } from "react"

import { Box, styled } from "@mui/material"

const CustomIcon = styled(Box)(() => ({
  position: "relative",
  width: "20px",
  height: "20px",
  border: "2px solid #333",
  borderRadius: "50%",
  background: "#fff",
  "&::before": {
    content: '""',
    position: "absolute",
    transform: "translate(-50%,-50%) rotate(45deg)",
    top: "50%",
    left: "50%",
    width: "3px",
    height: "20px",
    background: "#333",
  },
}))

export const UnavailableSeat = memo(() => {
  return (
    <>
      <CustomIcon />
    </>
  )
})
