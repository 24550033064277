import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Modal, Box, Typography, Button, Checkbox } from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { deleteSchedule } from "../../../../../models/employee/useSchedule/show"
import enTranslations from "../../../../../translations/errorMessage/en"
import jaTranslations from "../../../../../translations/errorMessage/ja"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  height: "30%",
}

interface Props {
  date: Date
  schedule_information_id: number
  recurringDeleteModal: boolean
  setRecurringDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const RecurringDeleteModalComponent: React.FC<Props> = ({
  date,
  schedule_information_id,
  recurringDeleteModal,
  setRecurringDeleteModal,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const navigate = useNavigate()
  const [checkedValue, setCheckedValue] = useState<
    "schedule" | "after_schedule" | "all_schedule"
  >("schedule")
  return (
    <>
      <Modal
        open={recurringDeleteModal}
        onClose={() => {
          setRecurringDeleteModal(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography>{translations.DeleteRecurring}</Typography>
            <Box display="flex">
              <Checkbox
                checked={checkedValue === "schedule"}
                onChange={() => {
                  setCheckedValue("schedule")
                }}
              />
              <Typography lineHeight="40px">
                {translations.ThisSchedule}
              </Typography>
            </Box>
            <Box display="flex">
              <Checkbox
                checked={checkedValue === "after_schedule"}
                onChange={() => {
                  setCheckedValue("after_schedule")
                }}
              />
              <Typography lineHeight="40px">
                {translations.FromThisSchedule}
              </Typography>
            </Box>
            <Box display="flex">
              <Checkbox
                checked={checkedValue === "all_schedule"}
                onChange={() => {
                  setCheckedValue("all_schedule")
                }}
              />
              <Typography lineHeight="40px">
                {translations.AllSchedule}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => {
                setRecurringDeleteModal(false)
              }}
            >
              {translations.Cancel}
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => {
                deleteSchedule(
                  schedule_information_id,
                  checkedValue,
                  date
                ).then(() => {
                  location.pathname.match("/employee/home")
                    ? navigate("/employee/home")
                    : navigate("/employee/calendar/days")
                  window.location.reload()
                  localStorage.setItem(
                    "alertContent",
                    translations.ScheduleDeleted
                  )
                })
              }}
            >
              {translations.Delete}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
