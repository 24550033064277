import React from "react"

import { Box, styled, Typography } from "@mui/material"

const StepContaier = styled(Box)(() => ({
  backgroundColor: "#FFFFFF",
  padding: "1.25rem 2rem",
  border: "1px solid #22ba9d",
  borderRadius: "20px",
  width: "calc((100% - 80px) / 3)",
  height: "100%",
}))

interface Props {
  stepNum: number
  title: string
  desc: string
}

export const Step = ({ stepNum, title, desc }: Props) => {
  return (
    <StepContaier>
      <Box textAlign="center">
        <Typography variant="h5" sx={{ color: "#22ba9d", fontWeight: "bold" }}>
          STEP {stepNum}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "#22ba9d", fontWeight: "bold" }}
          marginTop={1.5}
          marginBottom={1.5}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography>{desc}</Typography>
      </Box>
    </StepContaier>
  )
}
