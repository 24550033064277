import React, { createContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import { fetchScheduleStatusListRequest } from "../api/employee/layoutRequest"
import { useLanguage } from "../contexts/LanguageContext"
import { useSchedulableMonthes } from "../models/company/useSchedulableMonthes"
import {
  ScheduleStatusType,
  EmployeeStatusListRequestType,
  ScheduleParDateListType,
  Schedule,
  EmployeeStatusListLocationType,
} from "../models/employee/useLayout/status/type"
import { useCompanyRelations } from "../models/public/useCompanyRelations"
import { CompanyRelationsType } from "../models/public/useCompanyRelations/type"
import enTranslations from "../translations/errorMessage/en"
import jaTranslations from "../translations/errorMessage/ja"
import { dateStatus } from "../utils/date"
import { downloadIcon } from "../utils/downLoadIcon"
import { fifteenMinutesIntervalHours } from "../utils/hours"

type LayoutStatusDataType = {
  locationState: EmployeeStatusListLocationType
  branchId: number
  setBranchId: React.Dispatch<React.SetStateAction<number>>
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  scheduleStatus: ScheduleStatusType
  setScheduleStatus: React.Dispatch<React.SetStateAction<ScheduleStatusType>>
  companyRelations: CompanyRelationsType
  loading: boolean
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  dayOfWeek: string[]
  formatDate: (date: Date) => string
  startDate: Date
  endDate: Date
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  scheduleParDateList: ScheduleParDateListType[]
  userNotExists: boolean
  setUserNotExists: React.Dispatch<React.SetStateAction<boolean>>
  userNotExistsMessage: string
  fetchScheduleStatusList: (params: EmployeeStatusListRequestType) => void
  filterSchedule: (date: string, time: string) => void
  filteredSchedules: Schedule[]
  filterWholeDaySchedule: (
    date: string,
    wholeDayFlag: boolean,
    time?: string
  ) => void
  handleEmployeeSearch: (targetEmployeeId: number) => void
}
interface Props {
  children?: React.ReactNode
}

export const LayoutStatusContext = createContext<LayoutStatusDataType>(
  {} as LayoutStatusDataType
)

export const LayoutStatusProvider: React.FC<Props> = ({ children }: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const companyRelationObject = useCompanyRelations()
  const companyRelations = companyRelationObject.companyRelations
  const locationState = useLocation().state as EmployeeStatusListLocationType
  const [branchId, setBranchId] = useState<number>(0)
  const [scheduleStatus, setScheduleStatus] =
    useState<ScheduleStatusType>("remote")
  const [wholeDayFlag, setWholeDayFlag] = useState<boolean>(
    locationState ? locationState?.wholeDayFlag : false
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [scheduleParDateList, setScheduleParDateList] = useState<
    ScheduleParDateListType[]
  >([])
  const [filteredSchedules, setFilteredSchedules] = useState<Schedule[]>([])
  const [userNotExists, setUserNotExists] = useState<boolean>(false)
  const [userNotExistsMessage, setUserNotExistsMessage] = useState<string>("")

  const {
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    startDate,
    endDate,
    formatDate,
    setStartDate,
    setEndDate,
  } = dateStatus()

  const [timeIndex, setTimeIndex] = useState<number>(0)
  const time = fifteenMinutesIntervalHours[timeIndex]

  const { fetchSchedulableMonthes } = useSchedulableMonthes()

  useEffect(() => {
    if (scheduleParDateList.length > 0 && !loading) {
      handleFilterSchedule(wholeDayFlag)
    }
  }, [loading])

  // URLを直接叩いた場合、locationStateが存在しないので主拠点=branchIdとする
  useEffect(() => {
    if (companyRelationObject.branchId > 0) {
      setBranchId(
        locationState ? locationState?.branchId : companyRelationObject.branchId
      )
    }
  }, [companyRelationObject.branchId])

  // モード切り替えで遷移した場合はlocationStateをセットする
  useEffect(() => {
    setBranchId(
      locationState && locationState?.branchId
        ? locationState.branchId
        : companyRelationObject.branchId
    )
    setWholeDayFlag(locationState ? locationState?.wholeDayFlag : false)
    setDate(
      locationState && locationState?.date ? locationState.date : new Date()
    )
    setTimeIndex(locationState ? locationState?.timeIndex : 0)
  }, [locationState])

  // 日付を切り替えた場合に曜日をセットする
  useEffect(() => {
    setWeekdayNumber(date.getDay())
  }, [date])

  // 終日のチェックボックスを外した時の挙動
  useEffect(() => {
    if (scheduleParDateList.length > 0) {
      if (wholeDayFlag) {
        filterWholeDaySchedule(formatDate(date), wholeDayFlag)
      } else {
        filterWholeDaySchedule(formatDate(date), wholeDayFlag, time) // falseの場合は日付と時刻をベースに予定をfilterし直す
      }
    }
  }, [wholeDayFlag])

  useEffect(() => {
    // branchId > 0はURL直接叩いた場合(locationStateが存在しない場合対策)
    if (companyRelations.id > 0 && branchId > 0) {
      fetchScheduleStatusList({
        branch_id: branchId,
        schedule_status: scheduleStatus,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      })
    }
  }, [companyRelations, scheduleStatus, branchId])

  // 日付を切り替えた場合の挙動
  useEffect(() => {
    if (scheduleParDateList.length > 0) {
      const newStartDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      const newEndDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
      fetchScheduleStatusList({
        branch_id: branchId,
        schedule_status: scheduleStatus,
        start_date: formatDate(newStartDate),
        end_date: formatDate(newEndDate),
      }).then(() => {
        setStartDate(newStartDate)
        setEndDate(newEndDate)
      })
    }
  }, [date])

  const fetchScheduleStatusList = async (
    params: EmployeeStatusListRequestType
  ) => {
    setLoading(true)
    try {
      const response = await fetchScheduleStatusListRequest(params)
      if (response.data && response.status === 200) {
        await Promise.all(
          response.data.schedule_par_date_list.map(async (list) => {
            await Promise.all(
              list.schedules.map(async (schedule) => {
                schedule.image_blob = ""
                if (schedule.userable.icon) {
                  const icon = await downloadIcon(
                    companyRelations.id,
                    schedule.userable.id,
                    schedule.userable.icon
                  )
                  if (icon) {
                    schedule.image_blob = icon
                  }
                }
              })
            )
          })
        )
        setScheduleParDateList(response.data.schedule_par_date_list)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const filterSchedule = (date: string, time: string) => {
    const scheduleFilteredByDate = scheduleParDateList.filter((list) => {
      return list.scheduled_date === date
    })[0]
    const targetStartTime = new Date(`${date} ${time}`).getTime()
    const scheduleFilteredByTime = scheduleFilteredByDate?.schedules.filter(
      (schedule) => {
        const startTimeNumber = new Date(schedule.start_time).getTime()
        const endTimeNumber = new Date(schedule.end_time).getTime()
        return (
          startTimeNumber <= targetStartTime && endTimeNumber >= targetStartTime
        )
      }
    )
    setFilteredSchedules(scheduleFilteredByTime)
  }

  const filterWholeDaySchedule = (
    date: string,
    wholeDayFlag: boolean,
    time?: string
  ) => {
    const scheduleFilteredByDate = scheduleParDateList.filter((list) => {
      return list.scheduled_date === date
    })[0]

    let filteredSchedules = scheduleFilteredByDate?.schedules.filter(
      (schedule) => {
        return schedule.whole_day_flag === wholeDayFlag
      }
    )
    // wholeDayFlagがfalseの場合は日時でfilterし直す
    if (!wholeDayFlag) {
      filteredSchedules = scheduleFilteredByDate?.schedules.filter(
        (schedule) => {
          const targetStartTime = new Date(`${date} ${time}`).getTime()
          const startTimeNumber = new Date(schedule.start_time).getTime()
          const endTimeNumber = new Date(schedule.end_time).getTime()
          return (
            startTimeNumber <= targetStartTime &&
            endTimeNumber >= targetStartTime
          )
        }
      )
    }

    setFilteredSchedules(filteredSchedules)
  }

  const handleFilterSchedule = (wholeDayFlag: boolean) => {
    if (wholeDayFlag) {
      filterWholeDaySchedule(formatDate(date), wholeDayFlag) // 終日のチェックを入れたまま日付を切り替えた場合、終日の予定だけ取得する
    } else {
      // 終日のチェックを外した状態で日付を切り替えた場合、日付と時刻をベースに予定をfilterする
      filterSchedule(formatDate(date), time)
    }
  }

  const handleEmployeeSearch = (targetEmployeeId: number) => {
    const userExists = filteredSchedules.find((schedule) => {
      return schedule.userable.id === targetEmployeeId
    })
    if (userExists) {
      setUserNotExists(false)
      setUserNotExistsMessage("")

      const highLightedUserExists = filteredSchedules.find((schedule) => {
        return schedule.is_highlighted
      })

      if (highLightedUserExists) {
        clearTimeout()
        return
      }

      const newSchedules = filteredSchedules.map((schedule) => {
        if (
          schedule.userable.id === targetEmployeeId &&
          !schedule.is_highlighted
        ) {
          return { ...schedule, is_highlighted: true }
        } else {
          return schedule
        }
      })

      const resetSchedules = filteredSchedules.map((schedule) => {
        if (schedule.userable.id === targetEmployeeId) {
          return { ...schedule, is_highlighted: false }
        } else {
          return schedule
        }
      })

      setFilteredSchedules(newSchedules)

      setTimeout(() => setFilteredSchedules(resetSchedules), 1500)
    } else {
      setUserNotExists(true)
      setUserNotExistsMessage(translations.UserNotFound)
    }
  }

  return (
    <LayoutStatusContext.Provider
      value={{
        locationState,
        branchId,
        setBranchId,
        wholeDayFlag,
        setWholeDayFlag,
        scheduleStatus,
        setScheduleStatus,
        companyRelations,
        loading,
        date,
        setDate,
        previousDate,
        nextDate,
        previousWeekday,
        nextWeekday,
        weekdayNumber,
        setWeekdayNumber,
        dayOfWeek,
        formatDate,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        timeIndex,
        setTimeIndex,
        scheduleParDateList,
        userNotExists,
        setUserNotExists,
        userNotExistsMessage,
        fetchScheduleStatusList,
        filterSchedule,
        filteredSchedules,
        filterWholeDaySchedule,
        handleEmployeeSearch,
      }}
    >
      {children}
    </LayoutStatusContext.Provider>
  )
}
