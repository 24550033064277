import { useCallback, useState } from "react"

import {
  updateGoogleApproveRequest,
  updateMicrosoftApproveRequest,
} from "../../../../api/employee/calendarGoogleRequest"
import {
  deleteScheduleRequest,
  fetchScheduleInformationShowDataRequest,
  updateApproveRequest,
} from "../../../../api/employee/scheduleRequest"
import { AcceptStatusRequestType, ScheduleType } from "../days/type"
import { ScheduleHomeType } from "../useScheduleHome/type"
import { ScheduleNewMessage } from "../useScheduleNewMessage/type"
import { MicrosoftEvents } from "../weeks/type"
import { Loading, ScheduleInformation, ScheduleInformations } from "./type"

const initialScheduleInformation: ScheduleInformation = {
  id: 0,
  schedule_title: "",
  schedule_date_type: "",
  start_date: new Date(),
  end_date: new Date(),
  whole_day_flag: false,
  start_time: new Date(),
  end_time: new Date(),
  message_content: "",
  is_microsoft: false,
  canceled: false,
  schedule_creator_flag: false,
  schedule_creator_id: 0,
  weekly_recurring_type: {
    id: 0,
    every_week: 0,
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  },
  monthly_recurring_type: {
    id: 0,
    daily_or_week_day: "daily",
    every_monthly: 0,
    day: 0,
    number_day_of_week: 0,
    day_of_week: 0,
  },
  schedules: [],
  accept_flag: false,
}

export const useScheduleInformationState = (
  scheduleInformationObject: ScheduleInformation = initialScheduleInformation
) => {
  const [scheduleInformation, setScheduleInformation] =
    useState<ScheduleInformation>(scheduleInformationObject)

  const updateScheduleInformation = useCallback(
    (newScheduleInformation: ScheduleInformation) =>
      setScheduleInformation(newScheduleInformation),
    [scheduleInformation]
  )

  return {
    scheduleInformation,
    updateScheduleInformation,
  }
}

export const useScheduleShowData = () => {
  const [scheduleShowData, setScheduleShowData] = useState<
    Loading<ScheduleInformations>
  >({ loading: false, value: [] })
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [scheduleShowLoading, setScheduleShowLoading] = useState<boolean>(false)

  const fetchScheduleShowData = async (
    scheduleInformationIds: number[],
    scheduleDate: Date,
    scheduleIds: number[],
    schedules:
      | ScheduleType[]
      | MicrosoftEvents
      | ScheduleHomeType[]
      | ScheduleNewMessage[],
    employeeId?: number
  ) => {
    setScheduleShowData({ loading: true, value: [] })
    try {
      const response = await fetchScheduleInformationShowDataRequest(
        scheduleInformationIds,
        scheduleDate,
        scheduleIds,
        schedules,
        employeeId
      )
      if (response.status === 200 && response.data) {
        setScheduleShowData({ loading: false, value: response.data })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error)
    }
  }

  const onApproveSubmit = async (params: AcceptStatusRequestType) => {
    setScheduleShowLoading(true)
    try {
      const { data, error } = await updateApproveRequest({
        ...params,
      })
      if (!error) {
        if (data?.google_authenticated) {
          const { error } = await updateGoogleApproveRequest({
            ...params,
          })
          if (error) {
            setErrorMessageOpen(true)
            setErrorMessages(error)
          }
        } else if (data?.outlook_authenticated) {
          const { error } = await updateMicrosoftApproveRequest({
            ...params,
          })
          if (error) {
            setErrorMessageOpen(true)
            setErrorMessages(error)
          }
        }
        localStorage.setItem(
          "alertContent",
          params.accept_status ? "予定を承認しました" : "予定を拒否しました"
        )
        window.location.reload()
      } else {
        setErrorMessageOpen(true)
        setErrorMessages(error)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setScheduleShowLoading(false)
    }
  }

  return {
    scheduleShowData,
    fetchScheduleShowData,
    onApproveSubmit,
    scheduleShowLoading,
    setScheduleShowData,
  }
}

export const deleteSchedule = async (
  schedule_information_id: number,
  delete_flag: "schedule" | "after_schedule" | "all_schedule",
  date: Date
) => {
  try {
    const response = await deleteScheduleRequest(
      schedule_information_id,
      delete_flag,
      date
    )
    if (response.status === 204) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log(error)
  }
}
