import {
  Signage,
  SignageFormType,
  SignageInformation,
  SignageRequest,
} from "../../models/company/useSignage/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchSignageRequest = async (): Promise<
  ApiResponse<SignageRequest>
> => {
  try {
    const data = await api.get("/company/signages")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateSignageRequest = async (
  params: Signage
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/signages", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const createSignageRequest = async (
  params: SignageFormType
): Promise<ApiResponse<SignageInformation>> => {
  try {
    const data = await api.post("/signage/signages", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
