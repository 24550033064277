import React from "react"

import { Box } from "@mui/material"

import { CheckInReservationSeat } from "../../../components/mobile/checkIn/CheckInReservationSeat"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CheckInReservationSeatPage = () => {
  const { companyRelations } = useCompanyRelations()
  return (
    <Box sx={{ height: "100vh", my: "150px" }}>
      <CheckInReservationSeat companyRelations={companyRelations} />
    </Box>
  )
}
