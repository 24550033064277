import PropTypes from "prop-types"

import React, { memo, useState } from "react"

import { List, Box, styled } from "@mui/material"

import { MyScheduleRequestType } from "../../../models/employee/useSchedule/useScheduleHome/type"
import { oneWeek } from "../../../utils/oneWeek"
import { weekStatus } from "../../../utils/weeks"
import { companyListItems, EmployeeListItems } from "./ListItems"

export const MenuList = styled(Box)(() => ({
  display: "flex",
  borderTop: "none",
  width: "66px",
  overflowY: "auto",
  overflowX: "hidden",
  marginTop: "-20px",
}))

export const CustomList = styled(List)(() => ({
  paddingTop: "20px",
}))

export const CompanySidebar: React.FC = memo(() => {
  return (
    <MenuList>
      <CustomList disablePadding>{companyListItems()}</CustomList>
    </MenuList>
  )
})

CompanySidebar.propTypes = {
  isTeamLeader: PropTypes.bool.isRequired,
}

export const EmployeeSidebar: React.FC = memo(() => {
  const weekObject = weekStatus()
  const { setTargetWeeks } = oneWeek()

  const [beginningOfWeek, setBeginningOfWeek] = useState(new Date())
  const [endOfWeek, setEndOfWeek] = useState(new Date())

  const formatDate = (date: Date) => date.toISOString().split("T")[0]

  const fetchMySchedule = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: MyScheduleRequestType
  ): Promise<void> => {
    const { newBeginningOfWeek, newEndOfThisWeek } = setTargetWeeks(new Date())
    await fetchMySchedule({
      start_date: weekObject.formatDate(newBeginningOfWeek),
      end_date: weekObject.formatDate(newEndOfThisWeek),
    })
  }

  const props = {
    setBeginningOfWeek,
    setEndOfWeek,
    formatDate,
    fetchMySchedule,
  }
  return (
    <MenuList>
      <CustomList>
        <EmployeeListItems {...props} />
      </CustomList>
    </MenuList>
  )
})
