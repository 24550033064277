import styled from "@emotion/styled"

import React from "react"

import { Typography } from "@mui/material"
import { Box } from "@mui/system"

import { hours } from "../../../../../utils/hours"

const CustomTypography = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  color: "#C1C1C1",
}))

const CustomMainComponent = styled(Box)(() => ({
  position: "relative",
  height: "1.5rem",
}))

export const MeetingRoomScheduleTimeLine: React.FC = () => {
  return (
    <>
      <CustomMainComponent>
        {hours.map((hour, index) => (
          <Box
            key={index}
            sx={{
              marginRight: "10px",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "12px",
              fontFamily: "Noto Sans",
              lineHeight: "1rem",
              width: "30px",
              position: "absolute",
              left: `${index * 80}px`,
            }}
          >
            <CustomTypography>{hour}</CustomTypography>
          </Box>
        ))}
      </CustomMainComponent>
    </>
  )
}
