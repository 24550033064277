import {
  ContractPlan,
  ContractPlanRequest,
} from "../../models/company/useContractPlan/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchContractPlanRequest = async (): Promise<
  ApiResponse<ContractPlan>
> => {
  try {
    const data = await api.get("/company/contract_plans")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateContractPlanRequest = async (
  params: ContractPlanRequest
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.post("/company/contract_plans", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

export const updateContractAccountCountRequest = async (
  params: ContractPlanRequest
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      "/company/contract_plans/account_count",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
