import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Grid, Link, styled, Typography } from "@mui/material"

import { pageType } from "../../../../utils/location"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

export const SignageForgotPasswordAppliedMessage = () => {
  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography align="center">
          ご入力いただいたメールアドレス宛に
          <Typography align="center">
            パスワード再設定のご案内のご案内をお送りしました。
          </Typography>
          <Typography align="center" mt={1.5}>
            メールに記載のURLにアクセスし
          </Typography>
          <Typography align="center">
            パスワードの再設定を行ってください。
          </Typography>
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center">
          <Link
            component={RouterLink}
            to={`/${pageType}/login`}
            variant="caption"
            color="#454545"
          >
            <Typography variant="inherit" display="block">
              ログイン画面に戻る
            </Typography>
          </Link>
        </Typography>
      </Grid>
    </CustomGridContainer>
  )
}
