const ja = {
  Notifications: "通知",
  AccountInformation: "アカウント情報",
  ChangePassword: "パスワード再設定",
  SCHEDULE: "予定表",
  WORKPLACE: "席状況",
  TeamSearch: "チームサーチ",
  Logout: "ログアウト",
  NotificationList: "通知一覧",
  Subject: "件名",
  From: "差出人",
  Language: "言語",
  Change: "変更",
  Update: "更新",
  Close: "閉じる",
}
export default ja
