import React from "react"

import { Box } from "@mui/material"

import { Area } from "../../../../../models/employee/useSchedule/useLayout/type"
import { areaStyle } from "../../../../../styles/areaStyle"

interface Props {
  area: Area
}

export const EmployeeCalendarLayoutArea: React.FC<Props> = ({ area }) => {
  return (
    <>
      <Box
        id={`A${String(area.id)}`}
        className={"area"}
        sx={areaStyle(
          area.start_x,
          area.start_y,
          area.end_x,
          area.end_y,
          area.color_code
        )}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: area.color_code,
            fontSize: "30px",
          }}
        >
          {area.area_name}
        </div>
      </Box>
    </>
  )
}
