import React from "react"

import { Grid, styled } from "@mui/material"

import { SignUpInformation } from "../../company/auth/SignUpInformation"
import { Loading } from "../Loading"

interface Props {
  loading: boolean
  children: JSX.Element
}

const GridWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    overflow: "hidden",
    height: "100vh",
  },
}))

export const AuthPageTemplate = ({ loading, children }: Props) => {
  return (
    <GridWrapper container>
      {loading && <Loading type="content" loading={loading} />}
      {location.pathname === "/company/signup" ? (
        <>
          <SignUpInformation />
          <Grid item md={3}>
            {children}
          </Grid>
        </>
      ) : (
        <>
          <Grid item md={6}>
            <img
              src={`${process.env.PUBLIC_URL}/images/layouts/corporation_top.jpg`}
              alt="ログイン画面"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Grid>
          <Grid item md={6}>
            {children}
          </Grid>
        </>
      )}
    </GridWrapper>
  )
}
