import { Chart, registerables } from "chart.js"
import { subYears, compareAsc } from "date-fns"
import ja from "date-fns/locale/ja"

import React, { useEffect, useState } from "react"
import { Bar, Pie } from "react-chartjs-2"
import { Controller } from "react-hook-form"

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import { HeatmapLayoutMain } from "../../../components/company/report/HeatmapLayoutMain"
import { ContentCompanyPaper } from "../../../components/public/ContentPaper"
import { GreenArrowIcon } from "../../../components/public/GreenArrowIcon"
import { useContractPlan } from "../../../models/company/useContractPlan"
import {
  useReportOperation,
  useReportOperationState,
  useReportSeatHeatmap,
  useReportSeatOperation,
  useSearchReportOperation,
} from "../../../models/company/useReport"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import {
  CONTENT_PAPER_MIN_HEIGHT,
  REPORT_FLOAT_DIGIT,
} from "../../../utils/const"
import { dateStatus } from "../../../utils/date"
import {
  average,
  max,
  median,
  min,
  roundBySpecificDigit,
} from "../../../utils/number"

const ReportCustomSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(4),
  width: "100%",
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: "#EEEEEE",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "35px",
  fontSize: theme.spacing(2),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
}))

const ReportDisplayDataTypeSelect = styled(Select)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(20),
  fontSize: theme.spacing(2),
  marginBottom: theme.spacing(1),
  backgroundColor: "#EEEEEE",
}))

Chart.register(...registerables)

const boxStyle = {
  margin: 3,
}

const barOptions = {
  scales: {
    y: {
      min: 0,
    },
  },
}

const attendanceBarOptions = {
  scales: {
    y: {
      min: 0,
      max: 100,
    },
  },
}

const seatOperationBarOptions = {
  scales: {
    y: {
      min: 0,
      max: 100,
    },
  },
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 225,
      width: 250,
    },
  },
}

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "5px",
  },
})

// 色コード
const BLUE_CODE = "rgb(75, 192, 192)"
const YELLOW_CODE = "rgb(255, 205, 86)"
const GRAY_CODE = "rgb(201, 203, 207)"
const PURPLE_CODE = "rgb(147, 112, 219)"
const MAX_CODE = "rgb(0, 128, 128)"
const AVE_CODE = "rgb(32, 178, 170)"
const MIN_CODE = "rgb(64, 224, 208)"

type DayOfWeekGraphDataType = {
  allSeatCount: number
  operationSeatCount: number
  operationSeatRate: number
  dayOfWeek: number
  targetedAt: string
}
type DisplayIntervalValueType = "day" | "week" | "month"
export const CompanyReportOperationPage = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [
    attendanceEmployeeRateCountOfDayOfWeek,
    setAttendanceEmployeeRateCountOfDayOfWeek,
  ] = useState<number[]>([])
  const [
    todaySeatOperationSelectedBranchIds,
    setTodaySeatOperationSelectedBranchIds,
  ] = useState<number[]>([])
  const [
    todaySeatOperationSelectedBranchNames,
    setTodaySeatOperationSelectedBranchNames,
  ] = useState<string[]>([])
  const [selectedTypicalValue, setSelectedTypicalValue] =
    useState<string>("平均値")
  const {
    fetchReportRelations,
    branchReportRelation,
    attendanceReportRelation,
    dayOfWeekReportRelation,
    setBranchReportRelations,
    setAttendanceReportRelations,
    setDayOfWeekReportRelations,
  } = useReportOperation()
  const { companyRelations } = useCompanyRelations()
  const branchPullDownObject = useReportOperationState(companyRelations)
  const attendancePullDownObject = useReportOperationState(companyRelations)
  const dayOfWeekPullDownObject = useReportOperationState(companyRelations)
  const { searchReportRelations } = useSearchReportOperation()
  const { reportSeatOperationRelation, fetchSeatOperationRelations } =
    useReportSeatOperation()
  const heatmapPullDownObject = useReportSeatHeatmap(companyRelations)
  const { formatDate } = dateStatus()
  const [displayIntervalValue, setDisplayIntervalValue] =
    useState<DisplayIntervalValueType>("day")

  const today = new Date()

  // 選択期間開始日の処理
  const { fetchContractPlan, contractPlan } = useContractPlan()
  // 利用開始日
  const registrationStartDate = new Date(
    contractPlan.active_contract.start_date
  )
  // 現時刻から一年前
  const oneYearBefore = subYears(new Date(), 1)
  // 比較して選択できる開始期間にどちらを当てるか決める
  const minDate =
    compareAsc(oneYearBefore, registrationStartDate) < 0
      ? registrationStartDate
      : oneYearBefore

  const defaultStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )
  const defaultEndDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )

  useEffect(() => {
    fetchContractPlan()
  }, [])

  const changeBranchId = (e: SelectChangeEvent<unknown>) => {
    const branchIds = e.target.value
    const targetBranchIds =
      typeof branchIds === "string"
        ? branchIds.split(",").map(Number)
        : branchIds

    // 「全ての拠点」を選択した時
    if ((targetBranchIds as number[]).includes(0)) {
      if (
        todaySeatOperationSelectedBranchIds.length ===
        companyRelations.branches.length
      ) {
        setTodaySeatOperationSelectedBranchIds([])
        setTodaySeatOperationSelectedBranchNames([])
      } else {
        const allBranchIds = companyRelations.branches.map((branch) => {
          return branch.id
        })
        const allBranchNames = companyRelations.branches.map((branch) => {
          return branch.branch_name
        })
        setTodaySeatOperationSelectedBranchIds(allBranchIds)
        setTodaySeatOperationSelectedBranchNames(allBranchNames)
      }
    } else {
      const addedSelectedBranchNames = companyRelations.branches
        .filter((branch) => {
          return (targetBranchIds as number[]).includes(branch.id)
        })
        .map((branch) => {
          return branch.branch_name
        })
      setTodaySeatOperationSelectedBranchIds(targetBranchIds as number[])
      setTodaySeatOperationSelectedBranchNames(addedSelectedBranchNames)
    }
  }

  const handleFetchTodaysSeatOperation = async (branchIds: number[]) => {
    await fetchSeatOperationRelations({
      branch_ids: branchIds,
    })
  }

  // 拠点名
  const branchNameLabel: string[] = []
  const branchIds: number[] = []
  // 従業員数
  const allEmployeeCount: number[] = []
  // 利用可能席数
  const availableSeatCount: number[] = []
  // 出社数(最高値)
  const attendanceMaxEmployeeCount: number[] = []
  // 出社数(平均値)
  const attendanceAveEmployeeCount: number[] = []
  // 出社数(最低値)
  const attendanceMinEmployeeCount: number[] = []
  // 出社人数(拠点ごと)
  const attendanceEmployeeCountOfBranch: number[] = []
  // 出社人数(曜日ごと)
  const attendanceEmployeeCountOfDayOfWeek: number[] = Array(7).fill(0)
  // 出社従業員(曜日ごと)
  const allEmployeeCountOfDayOfWeek: number[] = Array(7).fill(0)
  // 座席利用率(曜日ごと)
  // const attendanceEmployeeRateCountOfDayOfWeek: number[] = []
  // 今日の席稼働率 [チェックイン済,予約中,空席]
  const seatOperationStatus: number[] = []

  // 今日の席稼働率
  const pieGraphData = {
    labels: ["チェックイン済(席)", "予約中(席)", "空席(席)"],
    datasets: [
      {
        // データの値
        data: seatOperationStatus,
        // グラフの背景色
        backgroundColor: [BLUE_CODE, YELLOW_CODE, GRAY_CODE],
      },
    ],
  }

  // 各拠点稼働率
  const barOperationGraphData = {
    // x 軸のラベル
    labels: branchNameLabel,
    datasets: [
      {
        label: "従業員数",
        // データの値
        data: allEmployeeCount,
        // グラフの背景色
        backgroundColor: GRAY_CODE,
        // グラフの枠線の色
        borderColor: GRAY_CODE,
      },
      {
        label: "利用可能席数",
        // データの値
        data: availableSeatCount,
        // グラフの背景色
        backgroundColor: PURPLE_CODE,
        // グラフの枠線の色
        borderColor: PURPLE_CODE,
      },
      {
        label: "出社数(最高値)",
        // データの値
        data: attendanceMaxEmployeeCount,
        // グラフの背景色
        backgroundColor: MAX_CODE,
        // グラフの枠線の色
        borderColor: MAX_CODE,
      },
      {
        label: "出社数(平均値)",
        // データの値
        data: attendanceAveEmployeeCount,
        // グラフの背景色
        backgroundColor: AVE_CODE,
        // グラフの枠線の色
        borderColor: AVE_CODE,
      },
      {
        label: "出社数(最低値)",
        // データの値
        data: attendanceMinEmployeeCount,
        // グラフの背景色
        backgroundColor: MIN_CODE,
        // グラフの枠線の色
        borderColor: MIN_CODE,
      },
    ],
  }

  const typeValue = ["中央値", "最大値", "最小値"]

  // 稼働率
  const attendanceGraphAllEmployeeCount: number[] = []
  const attendanceGraphAttendanceEmployeeCount: number[] = []
  const attendanceRate: number[] = []
  const targetedAtLabel: string[] = []
  const weekNumbers: { weekNumber: number; year: number }[] = []
  const targetedWeekLabel: Array<Array<string>> = []

  // 出社数・稼働率
  const barAttendanceGraphData = {
    // x 軸のラベル
    labels: targetedAtLabel,
    datasets: [
      {
        label: "稼働率(%)",
        // データの値
        data: attendanceRate,
        // グラフの背景色
        backgroundColor: BLUE_CODE,
        // グラフの枠線の色
        borderColor: BLUE_CODE,
      },
    ],
  }

  const targetedAtList: string[] = []
  const targetedAtDataList: DayOfWeekGraphDataType[] = []
  const dayOfWeekGraphDataLabels: string[] = [
    "日",
    "月",
    "火",
    "水",
    "木",
    "金",
    "土",
  ]
  // 稼働率の推移を日付順に表示するためにソート(デフォルトではcreated_at昇順、基本日付順に保存されてるはずだけど念の為)
  const sortedReports = attendanceReportRelation.reports.sort(
    (previous, next) =>
      new Date(previous.targeted_at).getTime() -
      new Date(next.targeted_at).getTime()
  )

  sortedReports.map((report) => {
    if (displayIntervalValue === "day") {
      const targetedAtIndex = targetedAtLabel.findIndex(
        (item) => item == report.targeted_at
      )
      if (targetedAtIndex == -1) {
        targetedAtLabel.push(report.targeted_at)
        attendanceGraphAllEmployeeCount.push(report.all_employee_count)
        attendanceGraphAttendanceEmployeeCount.push(
          report.attendance_employee_count
        )
      } else {
        attendanceGraphAllEmployeeCount[targetedAtIndex] =
          attendanceGraphAllEmployeeCount[targetedAtIndex] +
          report.all_employee_count
        attendanceGraphAttendanceEmployeeCount[targetedAtIndex] =
          attendanceGraphAttendanceEmployeeCount[targetedAtIndex] +
          report.attendance_employee_count
      }
    }

    if (displayIntervalValue === "week") {
      const targetedDate = new Date(report.targeted_at)
      const startDate = new Date(targetedDate.getFullYear(), 0, 1).getTime()
      const days = Math.floor(
        (targetedDate.getTime() - startDate) / (24 * 60 * 60 * 1000)
      )

      const weekNumber = Math.ceil(days / 7)

      const targetedWeekIndex = weekNumbers.findIndex(
        (item) =>
          item.weekNumber == weekNumber &&
          item.year === targetedDate.getFullYear()
      )

      if (targetedWeekIndex == -1) {
        targetedAtLabel.push(String(weekNumber))
        weekNumbers.push({
          weekNumber: weekNumber,
          year: targetedDate.getFullYear(),
        })
        attendanceGraphAllEmployeeCount.push(report.all_employee_count)
        attendanceGraphAttendanceEmployeeCount.push(
          report.attendance_employee_count
        )

        targetedWeekLabel.push([])

        const lastIndex = targetedWeekLabel.length - 1

        const targetedAtIndex = targetedWeekLabel[lastIndex].findIndex(
          (item) => item === report.targeted_at
        )

        if (targetedAtIndex === -1) {
          targetedWeekLabel[lastIndex].push(report.targeted_at)
        }
      } else {
        attendanceGraphAllEmployeeCount[targetedWeekIndex] =
          attendanceGraphAllEmployeeCount[targetedWeekIndex] +
          report.all_employee_count
        attendanceGraphAttendanceEmployeeCount[targetedWeekIndex] =
          attendanceGraphAttendanceEmployeeCount[targetedWeekIndex] +
          report.attendance_employee_count
        const targetedAtIndex = targetedWeekLabel[targetedWeekIndex].findIndex(
          (item) => item === report.targeted_at
        )

        if (targetedAtIndex === -1) {
          targetedWeekLabel[targetedWeekIndex].push(report.targeted_at)
        }
      }
    }

    if (displayIntervalValue === "month") {
      // 年と月が一致する場合は同じラベルに表示する
      const targetedAtIndex = targetedAtLabel.findIndex(
        (item) =>
          new Date(item).getFullYear() ===
            new Date(report.targeted_at).getFullYear() &&
          new Date(item).getMonth() === new Date(report.targeted_at).getMonth()
      )
      if (targetedAtIndex == -1) {
        targetedAtLabel.push(report.targeted_at)
        attendanceGraphAllEmployeeCount.push(report.all_employee_count)
        attendanceGraphAttendanceEmployeeCount.push(
          report.attendance_employee_count
        )
      } else {
        attendanceGraphAllEmployeeCount[targetedAtIndex] =
          attendanceGraphAllEmployeeCount[targetedAtIndex] +
          report.all_employee_count
        attendanceGraphAttendanceEmployeeCount[targetedAtIndex] =
          attendanceGraphAttendanceEmployeeCount[targetedAtIndex] +
          report.attendance_employee_count
      }
    }
  })

  for (let i = 0; i < targetedAtLabel.length; i++) {
    attendanceRate[i] = roundBySpecificDigit(
      (attendanceGraphAttendanceEmployeeCount[i] /
        attendanceGraphAllEmployeeCount[i]) *
        100,
      REPORT_FLOAT_DIGIT
    )
  }

  const handleAttendanceReportSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(attendancePullDownObject.startDate),
        end_date: formatDate(attendancePullDownObject.endDate),
        branch_ids: attendancePullDownObject.selectedBranchIds,
      },
      setAttendanceReportRelations,
      attendancePullDownObject.setSearchLoading
    )
  }

  const handleDisplayIntervalChange = (e: SelectChangeEvent<unknown>) => {
    setDisplayIntervalValue(e.target.value as DisplayIntervalValueType)
  }

  // 曜日別 出社数・席利用
  const barDayOfWeekGraphData = {
    // x 軸のラベル
    labels: dayOfWeekGraphDataLabels,
    datasets: [
      {
        label: "席利用率（%）",
        // データの値
        data: attendanceEmployeeRateCountOfDayOfWeek,
        // グラフの背景色
        backgroundColor: BLUE_CODE,
        // グラフの枠線の色
        borderColor: BLUE_CODE,
      },
    ],
  }
  // 今日の席稼働率
  seatOperationStatus.push(
    reportSeatOperationRelation.checked_in_count,
    reportSeatOperationRelation.reserved_count,
    reportSeatOperationRelation.all_seat_count -
      reportSeatOperationRelation.checked_in_count -
      reportSeatOperationRelation.reserved_count
  )

  // 拠点別
  branchReportRelation.reports.map((report) => {
    const branchIdIndex = branchIds.findIndex(
      (item) => item == report.branch_id
    )
    if (branchIdIndex == -1) {
      branchIds.push(report.branch_id)
      branchNameLabel.push(report.branch.branch_name)
      allEmployeeCount.push(report.all_employee_count)
      availableSeatCount.push(report.restricted_seat_count)
      attendanceMaxEmployeeCount.push(report.attendance_employee_count)
      attendanceMinEmployeeCount.push(report.attendance_employee_count)
      attendanceEmployeeCountOfBranch.push(report.attendance_employee_count)
    } else {
      // 最新の従業員数に更新しておく
      allEmployeeCount[branchIdIndex] = report.all_employee_count
      // 最新の利用可能席数に更新しておく
      availableSeatCount[branchIdIndex] = report.restricted_seat_count
      // 最大値の更新
      const cnt = attendanceMaxEmployeeCount[branchIdIndex]
      if (cnt < report.attendance_employee_count) {
        attendanceMaxEmployeeCount[branchIdIndex] =
          report.attendance_employee_count
      }
      // 最小値の更新
      if (cnt > report.attendance_employee_count) {
        attendanceMinEmployeeCount[branchIdIndex] =
          report.attendance_employee_count
      }
      attendanceEmployeeCountOfBranch[branchIdIndex] =
        attendanceEmployeeCountOfBranch[branchIdIndex] +
        report.attendance_employee_count
    }
  })

  // 日付別にデータを集計
  dayOfWeekReportRelation.reports.map((report) => {
    const targetedAtIndex = targetedAtList.findIndex(
      (item) => item == report.targeted_at
    )
    if (targetedAtIndex === -1) {
      targetedAtList.push(report.targeted_at)
      targetedAtDataList.push({
        targetedAt: report.targeted_at,
        dayOfWeek: report.day_of_week,
        allSeatCount: report.all_seat_count,
        operationSeatCount: report.operation_seat_count,
        operationSeatRate: 0,
      })
    } else {
      targetedAtDataList[targetedAtIndex].allSeatCount += report.all_seat_count
      targetedAtDataList[targetedAtIndex].operationSeatCount +=
        report.operation_seat_count
    }
  })

  for (let i = 0; i < targetedAtList.length; i++) {
    targetedAtDataList[i].operationSeatRate = roundBySpecificDigit(
      (targetedAtDataList[i].operationSeatCount /
        targetedAtDataList[i].allSeatCount) *
        100,
      REPORT_FLOAT_DIGIT
    )
  }

  for (let i = 0; i < dayOfWeekGraphDataLabels.length; i++) {
    // 日付別に集計したデータを曜日ごとにまとめなおす
    const dataDayOfWeek = targetedAtDataList.filter((data) => {
      return data.dayOfWeek === i
    })

    const operationSeatRateDayOfWeek = dataDayOfWeek.map((report) => {
      return report.operationSeatRate
    })

    // 平均値
    if (selectedTypicalValue === "平均値") {
      attendanceEmployeeRateCountOfDayOfWeek[i] = roundBySpecificDigit(
        average(operationSeatRateDayOfWeek),
        REPORT_FLOAT_DIGIT
      )
    }
    // 中央値
    if (selectedTypicalValue === "中央値") {
      attendanceEmployeeRateCountOfDayOfWeek[i] = roundBySpecificDigit(
        median(operationSeatRateDayOfWeek),
        REPORT_FLOAT_DIGIT
      )
      console.log("中央", attendanceEmployeeRateCountOfDayOfWeek)
    }
    // // 最大値
    if (selectedTypicalValue === "最大値") {
      attendanceEmployeeRateCountOfDayOfWeek[i] = roundBySpecificDigit(
        max(operationSeatRateDayOfWeek),
        REPORT_FLOAT_DIGIT
      )
      console.log("最大", attendanceEmployeeRateCountOfDayOfWeek)
    }
    // 最小値
    if (selectedTypicalValue === "最小値") {
      attendanceEmployeeRateCountOfDayOfWeek[i] = roundBySpecificDigit(
        min(operationSeatRateDayOfWeek),
        REPORT_FLOAT_DIGIT
      )
      console.log("最小", attendanceEmployeeRateCountOfDayOfWeek)
    }
  }

  // 拠点別に型定義の平均値を算出する
  for (let i = 0; i < branchIds.length; i++) {
    // 拠点が途中から作られるケースも加味して、拠点別に分母の値が設定されるようにする
    const branchDataCount = branchReportRelation.reports.filter((report) => {
      return report.branch_id === branchIds[i]
    }).length
    attendanceAveEmployeeCount.push(
      roundBySpecificDigit(
        attendanceEmployeeCountOfBranch[i] / branchDataCount,
        REPORT_FLOAT_DIGIT
      )
    )
  }

  for (let i = 0; i < targetedAtLabel.length; i++) {
    // yyyy-mm-dd hh:mm:ss +0900 → mm/dd
    if (displayIntervalValue === "day") {
      targetedAtLabel[i] = targetedAtLabel[i]
        .slice(0, 10)
        .replace("-", "/")
        .replace("-", "/")
    }
    if (displayIntervalValue === "week") {
      const weekLabelLastIndex = targetedWeekLabel[i].length - 1
      targetedAtLabel[i] = `${targetedWeekLabel[i][0]
        .slice(0, 10)
        .replace("-", "/")
        .replace("-", "/")}〜${targetedWeekLabel[i][weekLabelLastIndex]
        .slice(0, 10)
        .replace("-", "/")
        .replace("-", "/")}`
    }
    if (displayIntervalValue === "month") {
      targetedAtLabel[i] = `${new Date(targetedAtLabel[i]).getFullYear()}/${
        new Date(targetedAtLabel[i]).getMonth() + 1
      }`
    }
  }

  const heatmapRelation = heatmapPullDownObject.reportSeatHeatmapRelation

  const handleBranchReportSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(branchPullDownObject.startDate),
        end_date: formatDate(branchPullDownObject.endDate),
        branch_ids: branchPullDownObject.selectedBranchIds,
      },
      setBranchReportRelations,
      branchPullDownObject.setSearchLoading
    )
  }

  const handleDayOfWeekReportReportSearch = () => {
    searchReportRelations(
      {
        start_date: formatDate(dayOfWeekPullDownObject.startDate),
        end_date: formatDate(dayOfWeekPullDownObject.endDate),
        branch_ids: dayOfWeekPullDownObject.selectedBranchIds,
      },
      setDayOfWeekReportRelations,
      dayOfWeekPullDownObject.setSearchLoading
    )
  }

  useEffect(() => {
    if (companyRelations.id > 0) {
      const allBranchIds = companyRelations.branches.map((branch) => {
        return branch.id
      })
      const allBranchNames = companyRelations.branches.map((branch) => {
        return branch.branch_name
      })
      setTodaySeatOperationSelectedBranchIds(allBranchIds)
      setTodaySeatOperationSelectedBranchNames(allBranchNames)
      Promise.all([
        fetchReportRelations({
          start_date: formatDate(defaultStartDate),
          end_date: formatDate(defaultEndDate),
        }),
        handleFetchTodaysSeatOperation(allBranchIds),
      ]).then(() => {
        setLoading(false)
      })
    }
  }, [companyRelations])

  return (
    <ContentCompanyPaper>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: CONTENT_PAPER_MIN_HEIGHT, // 高さ指定しないと中央にローディングアイコンが配置できない
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={64} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", height: "100%" }}>
          <Grid container spacing={3}>
            <Grid container>
              <Grid sx={{ ...boxStyle }} item container xs={5}>
                <Grid item sm={8}>
                  <Typography color="primary" gutterBottom fontWeight="bold">
                    今日の席稼働状況
                  </Typography>
                  <Pie data={pieGraphData} />
                </Grid>
                <Grid item sm={4}>
                  <ReportCustomSelect
                    value={todaySeatOperationSelectedBranchIds}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    multiple
                    IconComponent={GreenArrowIcon}
                    onChange={(e) => changeBranchId(e)}
                    input={<OutlinedInput label="拠点" />}
                    renderValue={() =>
                      todaySeatOperationSelectedBranchNames.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} value={0}>
                      <Checkbox
                        checked={
                          todaySeatOperationSelectedBranchIds.length ===
                          companyRelations.branches.length
                        }
                      />
                      <ListItemText primary={"全ての拠点"} />
                    </MenuItem>
                    {companyRelations.branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        <Checkbox
                          checked={
                            todaySeatOperationSelectedBranchIds.indexOf(
                              Number(branch.id)
                            ) > -1
                          }
                        />
                        <ListItemText primary={branch.branch_name} />
                      </MenuItem>
                    ))}
                  </ReportCustomSelect>
                  <CustomButton
                    variant="outlined"
                    onClick={() =>
                      handleFetchTodaysSeatOperation(
                        todaySeatOperationSelectedBranchIds
                      )
                    }
                  >
                    検索
                  </CustomButton>
                </Grid>
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  直近の各拠点稼働率
                </Typography>

                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={branchPullDownObject.startDate}
                          onChange={branchPullDownObject.handleStartDateChange}
                          minDate={minDate}
                          maxDate={branchPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                    fontSize: "0.75rem",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={branchPullDownObject.endDate}
                          onChange={branchPullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={branchPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {branchPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleBranchReportSearch}
                        disabled={branchPullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <ReportCustomSelect
                    value={branchPullDownObject.selectedBranchIds}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    multiple
                    IconComponent={GreenArrowIcon}
                    onChange={(e) => branchPullDownObject.changeBranchId(e)}
                    input={<OutlinedInput label="拠点" />}
                    renderValue={() =>
                      branchPullDownObject.selectedBranchNames.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} value={0}>
                      <Checkbox
                        checked={
                          branchPullDownObject.selectedBranchIds.length ===
                          companyRelations.branches.length
                        }
                      />
                      <ListItemText primary={"全ての拠点"} />
                    </MenuItem>
                    {companyRelations.branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        <Checkbox
                          checked={
                            branchPullDownObject.selectedBranchIds.indexOf(
                              Number(branch.id)
                            ) > -1
                          }
                        />
                        <ListItemText primary={branch.branch_name} />
                      </MenuItem>
                    ))}
                  </ReportCustomSelect>
                </Grid>

                <Bar options={barOptions} data={barOperationGraphData} />
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  稼働率
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={attendancePullDownObject.startDate}
                          onChange={
                            attendancePullDownObject.handleStartDateChange
                          }
                          minDate={minDate}
                          maxDate={attendancePullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={attendancePullDownObject.endDate}
                          onChange={
                            attendancePullDownObject.handleEndDateChange
                          }
                          minDate={minDate}
                          maxDate={attendancePullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {attendancePullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleAttendanceReportSearch}
                        disabled={attendancePullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item container sm={4.5} direction="column" mt="1rem">
                  <ReportCustomSelect
                    value={attendancePullDownObject.selectedBranchIds}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    multiple
                    IconComponent={GreenArrowIcon}
                    onChange={(e) => attendancePullDownObject.changeBranchId(e)}
                    input={<OutlinedInput label="拠点" />}
                    renderValue={() =>
                      attendancePullDownObject.selectedBranchNames.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem key={0} value={0}>
                      <Checkbox
                        checked={
                          attendancePullDownObject.selectedBranchIds.length ===
                          companyRelations.branches.length
                        }
                      />
                      <ListItemText primary={"全ての拠点"} />
                    </MenuItem>
                    {companyRelations.branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        <Checkbox
                          checked={
                            attendancePullDownObject.selectedBranchIds.indexOf(
                              Number(branch.id)
                            ) > -1
                          }
                        />
                        <ListItemText primary={branch.branch_name} />
                      </MenuItem>
                    ))}
                  </ReportCustomSelect>
                </Grid>
                <Bar
                  options={attendanceBarOptions}
                  data={barAttendanceGraphData}
                />
                <Box display="flex" justifyContent="flex-end" mt="1rem">
                  <ReportDisplayDataTypeSelect
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    IconComponent={GreenArrowIcon}
                    value={displayIntervalValue}
                    onChange={handleDisplayIntervalChange}
                  >
                    <MenuItem value="day">日</MenuItem>
                    <MenuItem value="week">週</MenuItem>
                    <MenuItem value="month">月</MenuItem>
                  </ReportDisplayDataTypeSelect>
                </Box>
              </Grid>
              <Grid sx={{ ...boxStyle }} item xs={5}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  曜日別:席利用率
                </Typography>
                <Grid container>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={9}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={dayOfWeekPullDownObject.startDate}
                          onChange={
                            dayOfWeekPullDownObject.handleStartDateChange
                          }
                          minDate={minDate}
                          maxDate={dayOfWeekPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={dayOfWeekPullDownObject.endDate}
                          onChange={dayOfWeekPullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={dayOfWeekPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item sm={3}>
                    {dayOfWeekPullDownObject.searchLoading ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={32} />
                      </Box>
                    ) : (
                      <CustomButton
                        variant="outlined"
                        onClick={handleDayOfWeekReportReportSearch}
                        disabled={dayOfWeekPullDownObject.disableFlag}
                      >
                        検索
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" sm={9}>
                  <Grid item container sm={5} direction="column" mt="1rem">
                    <ReportCustomSelect
                      value={dayOfWeekPullDownObject.selectedBranchIds}
                      sx={{
                        background: "rgba(112,112,112,0.05)",
                      }}
                      multiple
                      IconComponent={GreenArrowIcon}
                      onChange={(e) =>
                        dayOfWeekPullDownObject.changeBranchId(e)
                      }
                      input={<OutlinedInput label="拠点" />}
                      renderValue={() =>
                        dayOfWeekPullDownObject.selectedBranchNames.join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} value={0}>
                        <Checkbox
                          checked={
                            dayOfWeekPullDownObject.selectedBranchIds.length ===
                            companyRelations.branches.length
                          }
                        />
                        <ListItemText primary={"全ての拠点"} />
                      </MenuItem>
                      {companyRelations.branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          <Checkbox
                            checked={
                              dayOfWeekPullDownObject.selectedBranchIds.indexOf(
                                Number(branch.id)
                              ) > -1
                            }
                          />
                          <ListItemText primary={branch.branch_name} />
                        </MenuItem>
                      ))}
                    </ReportCustomSelect>
                  </Grid>
                </Grid>
                <Bar
                  options={seatOperationBarOptions}
                  data={barDayOfWeekGraphData}
                />

                <Box display="flex" justifyContent="flex-end" mt="1rem">
                  <ReportDisplayDataTypeSelect
                    value={selectedTypicalValue}
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    IconComponent={GreenArrowIcon}
                    input={<OutlinedInput label="種類別" />}
                    MenuProps={MenuProps}
                    onChange={(e) =>
                      setSelectedTypicalValue(e.target.value as string)
                    }
                  >
                    <MenuItem key={"平均値"} value={"平均値"}>
                      <Typography>平均値</Typography>
                    </MenuItem>
                    {typeValue.map((value) => (
                      <MenuItem key={value} value={value}>
                        <Typography>{value}</Typography>
                      </MenuItem>
                    ))}
                  </ReportDisplayDataTypeSelect>
                </Box>
              </Grid>

              {/* 席稼働率ヒートマップ */}
              <Grid sx={{ ...boxStyle }} item xs={10}>
                <Typography color="primary" gutterBottom fontWeight="bold">
                  各席利用状況
                </Typography>
                <Grid container sx={{ mb: "8px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja}
                  >
                    <Grid item container alignItems="center" sm={4}>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={heatmapPullDownObject.startDate}
                          onChange={heatmapPullDownObject.handleStartDateChange}
                          minDate={minDate}
                          maxDate={heatmapPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={1}>
                        <Typography align="center">〜</Typography>
                      </Grid>
                      <Grid item sm={5}>
                        <DesktopDatePicker
                          format="yyyy/MM/dd"
                          value={heatmapPullDownObject.endDate}
                          onChange={heatmapPullDownObject.handleEndDateChange}
                          minDate={minDate}
                          maxDate={heatmapPullDownObject.yesterDay}
                          slotProps={{
                            textField: {
                              sx: {
                                "& label": {
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "5px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)",
                                  },
                                },
                              },
                            },
                            popper: {
                              sx: {
                                "& .css-173a83r-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled":
                                  {
                                    color: "rgba(0, 0, 0, 0.3)",
                                  },
                              },
                            },
                            inputAdornment: {
                              sx: {
                                "& .MuiIconButton-root": {
                                  padding: "0",
                                },
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item container sm={6} spacing={3} alignItems="center">
                    <Grid item container sm={6} alignItems="center">
                      <Controller
                        control={heatmapPullDownObject.control}
                        name="branch_id"
                        render={({ field }) => (
                          <ReportCustomSelect
                            sx={{
                              mb: 0,
                              height: "33px",
                              background: "rgba(112,112,112,0.05)",
                            }}
                            {...field}
                            size="small"
                            onChange={(e) => {
                              field.onChange(e.target.value)
                              heatmapPullDownObject.setValue("floor_id", 0)
                              heatmapPullDownObject.setSelectedBranchId(
                                e.target.value as number
                              )
                            }}
                            error={!!heatmapPullDownObject.errors.branch_id}
                            IconComponent={GreenArrowIcon}
                          >
                            <MenuItem key={0} value={0} disabled>
                              拠点を選択してください
                            </MenuItem>
                            {companyRelations.branches.map((branch) => (
                              <MenuItem key={branch.id} value={branch.id}>
                                {branch.branch_name}
                              </MenuItem>
                            ))}
                          </ReportCustomSelect>
                        )}
                      />
                    </Grid>
                    <Grid item container sm={6} alignItems="center">
                      <Controller
                        control={heatmapPullDownObject.control}
                        name="floor_id"
                        render={({ field }) => (
                          <ReportCustomSelect
                            sx={{
                              mb: 0,
                              height: "33px",
                              background: "rgba(112,112,112,0.05)",
                            }}
                            {...field}
                            size="small"
                            error={!!heatmapPullDownObject.errors.floor_id}
                            IconComponent={GreenArrowIcon}
                          >
                            <MenuItem key={0} value={0} disabled>
                              フロアを選択してください
                            </MenuItem>
                            {heatmapPullDownObject.selectedBranch &&
                              heatmapPullDownObject.selectedBranch.floors.map(
                                (floor) => (
                                  <MenuItem key={floor.id} value={floor.id}>
                                    {floor.floor_name}
                                  </MenuItem>
                                )
                              )}
                          </ReportCustomSelect>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    sm={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Grid item sm={9}>
                      {heatmapPullDownObject.searchLoading ? (
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress size={32} />
                        </Box>
                      ) : (
                        <CustomButton
                          variant="outlined"
                          onClick={heatmapPullDownObject.onSubmit}
                          disabled={dayOfWeekPullDownObject.disableFlag}
                        >
                          表示
                        </CustomButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {heatmapRelation.id === 0 ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "600px",
                      background: "rgba(112,112,112,0.05)",
                      borderRadius: "10px",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      mt: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          height: "150px",
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: "20px",
                        }}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/icons/report-map.png`}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          期間内の各席の稼働状況を表示します。
                        </Typography>
                        <Typography>
                          稼働状況の期間を選択し、見たい拠点、フロアを選択し、表示をクリックしてください。
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <HeatmapLayoutMain
                    layoutImg={heatmapPullDownObject.layoutImg}
                    seats={heatmapRelation.seats}
                    meetingRooms={heatmapRelation.meeting_rooms}
                    areas={heatmapRelation.areas}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </ContentCompanyPaper>
  )
}
