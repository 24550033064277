import React, { memo } from "react"

import { Button, styled } from "@mui/material"

export const CustomButton = styled(Button)({
  height: "50px",
  width: "120px",
  fontWeight: "bold",
  fontSize: "28px",
  borderRadius: "2px",
  textTransform: "none",
})

interface Props {
  label: string
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isValid?: boolean
  variant?: "default" | "alternate"
}

export const SeatButton: React.FC<Props> = memo(
  ({ label, handleClick, isValid, variant }: Props) => {
    const buttonVariant = variant === "alternate" ? "contained" : "outlined"
    const style =
      variant === "default" ? { backgroundColor: "white" } : undefined

    return (
      <CustomButton
        type="button"
        variant={buttonVariant}
        color="primary"
        size="large"
        onClick={handleClick}
        disabled={isValid}
        style={style}
      >
        {label}
      </CustomButton>
    )
  }
)
