import {
  TransformComponent,
  ReactZoomPanPinchRef,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useRef, useEffect } from "react"

import { Box, Button, styled } from "@mui/material"

import { theme } from "../../../../config/theme"
import { useLayout } from "../../../../models/company/useLayout"
import { Loading } from "../../../public/Loading"
import { LayoutSeatObject } from "../LayoutSeatObject"

const ZoomButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: "16px",
  borderColor: theme.palette.primary.main,
  borderStyle: "solid",
  width: theme.spacing(11),
  height: theme.spacing(3.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const MinusButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRight: "1px solid",
  borderRadius: "0",
  height: theme.spacing(2),
  minWidth: "50%",
  width: "50%",
}))

const PlusButton = styled(Button)(() => ({
  minWidth: "50%",
  width: "50%",
}))

interface Props {
  companyId: number
  branchId: number
  floorId: number
  reservedSeatId: number
  onReservedSeatClick: (seatId: number | null, seatName: number) => void
}

export const CompanyLayoutTemplate: React.FC<Props> = ({
  companyId,
  branchId,
  floorId,
  reservedSeatId,
  onReservedSeatClick,
}: Props) => {
  const { layoutImg, loading, handleReservedSeat, resavableSeats } = useLayout()
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)

  useEffect(() => {
    handleReservedSeat(companyId, branchId, floorId)
  }, [])

  return (
    <>
      {loading ? (
        <>
          <Loading type="content" loading={loading} />
          <Box sx={{ height: "500px", width: "100%" }} />
        </>
      ) : (
        <>
          {layoutImg && (
            <Box
              sx={{
                padding: theme.spacing(3, 4, 2, 0),
              }}
            >
              <ZoomButtonBox color="primary">
                <MinusButton
                  onClick={async () => {
                    // 最小10%を超えないようにする
                    if (
                      layoutImageRef.current?.state?.scale &&
                      layoutImageRef.current?.state?.scale <= 0.1
                    ) {
                      return
                    } else {
                      layoutImageRef.current?.setTransform(
                        0,
                        0,
                        layoutImageRef.current?.state.scale - 0.1
                      )
                    }
                  }}
                >
                  ー
                </MinusButton>
                <PlusButton
                  onClick={async () => {
                    // 最大100%を超えないようにする
                    if (
                      layoutImageRef.current?.state?.scale &&
                      layoutImageRef.current?.state?.scale >= 1.0
                    ) {
                      return
                    } else {
                      layoutImageRef.current?.setTransform(
                        0,
                        0,
                        layoutImageRef.current?.state.scale + 0.1
                      )
                    }
                  }}
                >
                  ＋
                </PlusButton>
              </ZoomButtonBox>
            </Box>
          )}
          <TransformWrapper
            initialScale={0.5}
            disabled={false}
            minScale={0.1}
            maxScale={1.0}
            wheel={{ disabled: false, step: 0.05 }}
            panning={{
              disabled: false,
            }}
            ref={layoutImageRef}
          >
            <>
              {!layoutImg && <p>レイアウトが存在しません</p>}
              <TransformComponent
                wrapperStyle={{ maxWidth: "100%", maxHeight: "600px" }}
              >
                <img id="layout-image" src={layoutImg} />
                {resavableSeats.map((seat, index) => {
                  return (
                    <>
                      <Box key={index}>
                        <LayoutSeatObject
                          seat={seat}
                          reservedSeatId={reservedSeatId}
                          onReservedSeatClick={onReservedSeatClick}
                        />
                      </Box>
                    </>
                  )
                })}
              </TransformComponent>
            </>
          </TransformWrapper>
        </>
      )}
    </>
  )
}
