import { useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  fetchPasswordChangeTimesRequest,
  updatePasswordChangeTimesRequest,
  needChangePasswordRequest,
} from "../../../api/employee/accountsRequest"
import { pageType } from "../../../utils/location"

export const useAccounts = () => {
  const navigate = useNavigate()
  const [passwordChangeTimes, setPasswordChangeTimes] = useState<Date>(
    new Date()
  )
  const [passwordChangeTimesId, setPasswordChangeTimesId] = useState<number>(0)

  const handleSetPasswordChangeTimes = (times: Date) => {
    setPasswordChangeTimes(times)
  }
  const handleSetPasswordChangeTimesId = (account_id: number) => {
    setPasswordChangeTimesId(account_id)
  }
  const fetchPasswordChangeTimes = async () => {
    try {
      const response = await fetchPasswordChangeTimesRequest()
      if (response.data && response.status === 200) {
        handleSetPasswordChangeTimesId(response.data.id)
        handleSetPasswordChangeTimes(response.data.password_change_times)
        return response.data
      }
    } catch (e) {
      console.log("error")
    }
  }

  const updatePasswordChangeTimes = async () => {
    try {
      const response = await updatePasswordChangeTimesRequest({
        id: passwordChangeTimesId,
        password_change_times: passwordChangeTimes,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "パスワードを変更しました")
        navigate(`/${pageType}/home`)
      }
    } catch (e) {
      console.log("error")
    }
  }

  /*const needChangePassword = async (params: number) => {
    try {
      const response = await needChangePasswordRequest(params)
      if (response.data && response.status === 200) {
      }
    } catch (error) {
      console.log(error)
    }
  }*/

  return {
    passwordChangeTimes,
    setPasswordChangeTimes,
    fetchPasswordChangeTimes,
    updatePasswordChangeTimes,
    //needChangePassword,
  }
}
