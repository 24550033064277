const en = {
  Notifications: "Notifications",
  AccountInformation: "Account Information",
  ChangePassword: "Change password",
  SCHEDULE: "SCHEDULE",
  WORKPLACE: "WORKPLACE",
  TeamSearch: "Team Search",
  Logout: "Log out",
  NotificationList: "Notification List",
  Subject: "Subject",
  From: "From",
  Language: "Language",
  Change: "Change",
  Update: "Update",
  Close: "Close",
}
export default en
