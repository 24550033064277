import React from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import {
  Grid,
  Typography,
  styled,
  TextField,
  InputLabel,
  Box,
  Button,
  Link,
} from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import { ForgotPasswordType } from "../../../../models/public/useAuth/forgotPassword/type"
import enTranslations from "../../../../translations/mobileLogin/mobileForgotPassword/en"
import jaTranslations from "../../../../translations/mobileLogin/mobileForgotPassword/ja"
import { pageType } from "../../../../utils/location"
import { ErrorText } from "../../../public/ErrorText"
import { RequiredFlag } from "../../../public/RequiredFlag"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(53),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: theme.spacing(2),
  padding: theme.spacing(1, 8),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(40),
}))

interface Props {
  control: Control<ForgotPasswordType>
  handleSubmit: UseFormHandleSubmit<ForgotPasswordType>
  onSubmit: SubmitHandler<ForgotPasswordType>
  errors: FieldErrors<ForgotPasswordType>
  isValid: boolean
  open: boolean
  errorMessages: string[]
}

export const ForgotPasswordForm: React.FC<Props> = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
  isValid,
  open,
  errorMessages,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <CustomGridItem item>
        <Typography variant="subtitle1" color="inherit" align="center">
          {translations.YourEmail}
          <Typography>{translations.SendYouCode}</Typography>
        </Typography>
      </CustomGridItem>
      <CustomGridItem item container spacing={1}>
        <Grid item xs={12} sm={12}>
          <InputLabel htmlFor="email">
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: "bold",
              }}
            >
              {translations.Email}
            </Typography>
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.email}
                helperText={errors.email?.message}
                required
                id="email"
                name="email"
                fullWidth
                variant="outlined"
                type="text"
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      {open && (
        <Grid item>
          <ErrorMessageContainer textAlign="center">
            {ErrorText(errorMessages)}
          </ErrorMessageContainer>
        </Grid>
      )}
      <Grid item>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CustomButton
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            disabled={!isValid}
            color="primary"
          >
            {translations.Confirm}
          </CustomButton>
        </Box>
      </Grid>
      <Grid item>
        <Link
          component={RouterLink}
          to={`/${pageType}/login`}
          variant="caption"
          color="#43425D"
        >
          {translations.loginPage}
        </Link>
      </Grid>
    </CustomGridContainer>
  )
}
