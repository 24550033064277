import React from "react"

import { Box } from "@mui/material"

import { CheckOutMain } from "../../../components/mobile/checkOut/CheckOutMain"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"

export const CheckOutPage = () => {
  const { companyRelations } = useCompanyRelations()

  return (
    <Box sx={{ height: "100vh", my: "150px" }}>
      <CheckOutMain companyRelations={companyRelations} />
    </Box>
  )
}
