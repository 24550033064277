import { useState, useEffect } from "react"

import { fetchEmployeeCompanyInfoRequest } from "../../../api/employee/employeeCompanyInfoRequest"

export const useScheduleStartHour = () => {
  const [startHour, setStartHour] = useState<number | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEmployeeCompanyInfoRequest()
        if (response && response.am_start_time) {
          const amStartTime = response.am_start_time
          const timeParts = amStartTime.split(":")
          const hour = parseInt(timeParts[0], 10)
          setStartHour(hour)
        }
      } catch (error) {
        console.error("データの取得中にエラーが発生しました。", error)
      }
    }

    fetchData()
  }, [])

  return startHour
}

export const useSchedulePMStartHour = () => {
  const [pmStartHour, setPMStartHour] = useState<number | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEmployeeCompanyInfoRequest()
        if (response && response.pm_start_time) {
          const pmStartTime = response.pm_start_time
          const timeParts = pmStartTime.split(":")
          const hour = parseInt(timeParts[0], 10)
          setPMStartHour(hour)
        }
      } catch (error) {
        console.error("データの取得中にエラーが発生しました", error)
      }
    }

    fetchData()
  }, [])

  return pmStartHour
}
