import { translations } from "@aws-amplify/ui-react"

import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Controller,
  FieldValues,
  useFormContext,
  UseFormReturn,
} from "react-hook-form"

import {
  Box,
  FormHelperText,
  Grid,
  styled,
  TextField,
  Typography,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import {
  userableSearchRequestState,
  useScheduleUserableGuestSearch,
  useScheduleUserableSearch,
} from "../../../../../models/employee/useSchedule/useScheduleUserableSearch"
import {
  SearchGuestSet,
  SearchUserable,
} from "../../../../../models/employee/useSchedule/useScheduleUserableSearch/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleExternalUser/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleExternalUser/ja"
import { CancelButton } from "../../../../public/CancelButton"
import { ReflectButton } from "../../../../public/ReflectButton"

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  position: "sticky",
  top: "0",
}))

interface Props {
  guestForm: UseFormReturn<FieldValues>
  handleGuestFix: () => void
  handleClose: () => void
}

export const ShareScheduleGuestForm = ({
  guestForm,
  handleGuestFix,
  handleClose,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const { control, formState } = guestForm
  const { errors, isValid } = formState
  const inputEl = useRef<HTMLInputElement>(null)
  const [GuestSearchValue, setGuestSearchValue] = useState<SearchGuestSet>({
    last_name: "",
    first_name: "",
    email: "",
    company_name: "",
  })
  // ゲスト情報を取得する
  const { userableGuestSearch, scheduleUserableGuestSearch } =
    useScheduleUserableGuestSearch()
  // key入力時に予測候補を表示する
  const handleSearchUserableGuest = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    guestForm.setValue("email", e.target.value)
    const value = e.target.value.replace(/ /g, "")
    setGuestSearchValue({
      ...GuestSearchValue,
      email: value,
    })
    scheduleUserableGuestSearch(value).then((data) => {
      if (data) {
        // ゲストが１件以上検索ヒットした時リストを表示する
        if (data.length < 1) {
          setIsUserable(false)
        } else {
          setIsUserable(true)
        }
      }
    })
  }
  const [isUserable, setIsUserable] = useState<boolean>(false)
  const handleSelectUserClick = (userable: SearchGuestSet) => {
    setIsUserable(false)
    setGuestSearchValue({
      last_name: userable.last_name,
      first_name: userable.first_name,
      email: userable.email,
      company_name: userable.company_name,
    })
    guestForm.setValue("email", userable.email)
    guestForm.setValue("last_name", userable.last_name)
    guestForm.setValue("first_name", userable.first_name)
    guestForm.setValue("company_name", userable.company_name)
    // フォーカスをメールアドレスのテキストフィールドに当てる
    inputEl.current?.focus()
  }
  return (
    <>
      <CustomTypography variant="h5">
        {translations.InviteExternalGuests}
      </CustomTypography>
      <Grid
        container
        direction="column"
        spacing={2}
        pt="2rem"
        alignItems="space-around"
        height="100%"
      >
        <Grid container columnSpacing={4} justifyContent="space-between">
          <Grid item sm={6} container alignItems="center">
            <Grid item xs={2}>
              <CustomTypography variant="subtitle1" color="primary">
                {translations.LastName}
              </CustomTypography>
            </Grid>
            <Grid item xs={10}>
              <Controller
                control={control}
                name={"last_name"}
                render={({ field }) => (
                  <TextField
                    label={translations.LastName}
                    {...field}
                    fullWidth
                    value={GuestSearchValue.last_name}
                    onChange={(e) => {
                      setGuestSearchValue({
                        ...GuestSearchValue,
                        last_name: e.target.value,
                      })
                      guestForm.setValue("last_name", e.target.value)
                    }}
                  />
                )}
              />
              <FormHelperText error={!!errors.last_name?.message}>
                {errors.last_name?.message}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid item sm={6} container alignItems="center">
            <Grid item xs={2}>
              <CustomTypography variant="subtitle1" color="primary">
                {translations.FirstName}
              </CustomTypography>
            </Grid>
            <Grid item xs={10}>
              <Controller
                control={control}
                name={"first_name"}
                render={({ field }) => (
                  <TextField
                    label={translations.FirstName}
                    {...field}
                    fullWidth
                    value={GuestSearchValue.first_name}
                    onChange={(e) => {
                      setGuestSearchValue({
                        ...GuestSearchValue,
                        first_name: e.target.value,
                      })
                      guestForm.setValue("first_name", e.target.value)
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} container>
          <Grid item xs={4} display="flex" alignItems="center">
            <CustomTypography variant="subtitle1" color="primary">
              {translations.CompanyName}
            </CustomTypography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              control={control}
              name={"company_name"}
              render={({ field }) => (
                <TextField
                  label={translations.CompanyName}
                  {...field}
                  fullWidth
                  value={GuestSearchValue.company_name}
                  onChange={(e) => {
                    setGuestSearchValue({
                      ...GuestSearchValue,
                      company_name: e.target.value,
                    })
                    guestForm.setValue("company_name", e.target.value)
                  }}
                />
              )}
            />
            <FormHelperText error={!!errors.company_name?.message}>
              {errors.company_name?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item sm={6} container>
          <Grid item xs={4} display="flex" alignItems="center">
            <CustomTypography variant="subtitle1" color="primary">
              {translations.Email}
            </CustomTypography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              control={control}
              name={"email"}
              render={({ field }) => (
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <TextField
                    fullWidth
                    {...field}
                    value={GuestSearchValue.email}
                    label={translations.EmailRequired}
                    required
                    onChange={handleSearchUserableGuest}
                    autoComplete="new-off"
                    inputRef={inputEl}
                  />
                  {isUserable && (
                    <Box
                      aria-label="共有スケジュール"
                      tabIndex={-1}
                      role="listbox"
                      sx={{
                        border: "1px",
                        borderColor: "rgb(0 0 0 / 50%)",
                        boxShadow: "0 2px 10px 0 rgb(0 0 0 / 50%)",
                        height: "150px",
                        overflowY: "scroll",
                        backgroundColor: "#fff",
                        position: "absolute",
                        zIndex: "1000",
                        top: "60px",
                        display: "block",
                        width: "100%",
                      }}
                    >
                      {userableGuestSearch.map((userable, index) => {
                        return (
                          <Box
                            key={index}
                            role="option"
                            tabIndex={-1}
                            onMouseOver={(e) => {
                              const list =
                                e.currentTarget.parentElement?.children
                              if (list) {
                                Array.from(list).forEach((child) => {
                                  child.setAttribute("aria-selected", "false")
                                })
                              }
                              e.currentTarget.setAttribute(
                                "aria-selected",
                                "true"
                              )
                            }}
                            onClick={() => {
                              handleSelectUserClick(userable)
                            }}
                            aria-selected={false}
                            sx={{
                              '&[aria-selected="true"]': {
                                backgroundColor: "rgb(0 0 0 / 10%)",
                              },
                              padding: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <Typography>
                              {userable.last_name} {userable.first_name}
                            </Typography>
                            <Typography fontSize="0.5rem">
                              {userable.email}
                            </Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  )}
                </Box>
              )}
            />
            <FormHelperText error={!!errors.email?.message}>
              {errors.email?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid
          item
          sm={2}
          spacing={2}
          mt="auto"
          pb="1.5rem"
          container
          direction="column"
        >
          <Grid item container justifyContent="center" sm={4}>
            <Grid
              item
              sm={4}
              sx={{
                textAlign: "center",
              }}
            >
              <CancelButton
                label={translations.Cancel}
                handleClick={handleClose}
                style={{ width: "150px" }}
              />
            </Grid>
            <Grid
              item
              sm={4}
              sx={{
                textAlign: "center",
              }}
            >
              <ReflectButton
                label={translations.Invite}
                disabled={!isValid}
                handleClick={handleGuestFix}
                style={{ width: "150px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
