import {
  CompanyRelationsParams,
  CompanyRelationsType,
} from "../../models/public/useCompanyRelations/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const companyRelationsRequest = async (
  params: CompanyRelationsParams
): Promise<ApiResponse<CompanyRelationsType>> => {
  try {
    const data = await api.get("/public/company_relations", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
