import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

import ChairAltIcon from "@mui/icons-material/ChairAlt"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  ReservableType,
  ScheduleForm,
  WhoIsWhere,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { useCompanyRelations } from "../../../../../models/public/useCompanyRelations"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleReservation/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleReservation/ja"
import { GreenArrowIcon } from "../../../../public/GreenArrowIcon"
import { MeetingRoomModal } from "../MeetingRoomModal"

interface Props {
  monthlySetting: string | undefined
  isWholeDay: boolean
  whoIsWheres: WhoIsWhere[]
}

interface LocationState {
  branchId?: number
  floorId?: number
}

export const SeatOrMeetingReservationComponent: React.FC<Props> = ({
  monthlySetting,
  isWholeDay,
  whoIsWheres,
}) => {
  const { getValues, setValue } = useFormContext<ScheduleForm>()
  const navigate = useNavigate()
  const location = useLocation() as { state: LocationState }
  const {
    companyRelations,
    branchId,
    setBranchId,
    floorId,
    setFloorId,
    selectedBranch,
  } = useCompanyRelations()

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  // プルダウンの変更
  const stateBranchId =
    location.state?.branchId ?? companyRelations.default_branch_id ?? 0
  const stateFloorId =
    location.state?.floorId ?? companyRelations.default_floor_id ?? 0

  useEffect(() => {
    setBranchId(stateBranchId)
    setFloorId(stateFloorId)
  }, [companyRelations])

  const handlePullDownChange = (e: SelectChangeEvent<unknown>) => {
    switch (e.target.name) {
      case "branch_id":
        setBranchId(e.target.value as number)
        setFloorId(0)
        break
      case "floor_id":
        setFloorId(e.target.value as number)
        break
    }
  }

  const isMeetingRoom = () => {
    const meetingRoom = whoIsWheres.find((whoIsWhere) => {
      return whoIsWhere.reservable.reservable_type === "MeetingRoom"
    })

    return Boolean(meetingRoom)
  }

  const duplicatedMeetingRooms = whoIsWheres.filter(
    (whoIsWhere, index, array) => {
      return (
        array.findIndex((value) => {
          return (
            whoIsWhere.reservable.reservable_type ===
            value.reservable.reservable_type
          )
        }) === index && whoIsWhere.reservable.reservable_id
      )
    }
  )

  const handleMeetingRoomClick = () => {
    const values = getValues("schedule.share_schedule.who_is_wheres").map(
      (whoIsWhere) => {
        return {
          userable: {
            userable_id: whoIsWhere.userable.userable_id,
            last_name: whoIsWhere.userable.last_name,
            first_name: whoIsWhere.userable.first_name,
            email: whoIsWhere.userable.email,
            userable_type: whoIsWhere.userable.userable_type,
            company_name: whoIsWhere.userable.company_name,
            should_send_guest_reception_notification_mail:
              whoIsWhere.userable.should_send_guest_reception_notification_mail,
          },
          reservable: {
            reservable_id: undefined,
            name: "",
            reservable_type: "Seat" as ReservableType,
          },
        }
      }
    )
    setValue("schedule.share_schedule.who_is_wheres", values)
  }

  const handleSeatClick = (whoIsWhere: WhoIsWhere) => {
    const values = getValues("schedule.share_schedule.who_is_wheres").map(
      (value) => {
        if (value.userable.userable_id === whoIsWhere.userable.userable_id) {
          return {
            userable: {
              userable_id: value.userable.userable_id,
              last_name: value.userable.last_name,
              first_name: value.userable.first_name,
              email: value.userable.email,
              userable_type: value.userable.userable_type,
              company_name: value.userable.company_name,
              should_send_guest_reception_notification_mail:
                value.userable.should_send_guest_reception_notification_mail,
            },
            reservable: {
              reservable_id: undefined,
              name: "",
              reservable_type: "Seat" as ReservableType,
            },
          }
        } else {
          return {
            userable: {
              userable_id: value.userable.userable_id,
              last_name: value.userable.last_name,
              first_name: value.userable.first_name,
              email: value.userable.email,
              userable_type: value.userable.userable_type,
              company_name: value.userable.company_name,
              should_send_guest_reception_notification_mail:
                value.userable.should_send_guest_reception_notification_mail,
            },
            reservable: {
              reservable_id: value.reservable.reservable_id,
              name: value.reservable.name,
              reservable_type: value.reservable.reservable_type,
            },
          }
        }
      }
    )
    setValue("schedule.share_schedule.who_is_wheres", values)
  }

  return (
    <>
      <Box display="flex">
        <ChairAltIcon
          sx={{ fontSize: "25px", color: "#22BA9D", marginBottom: "1rem" }}
        />
        <Box
          sx={{
            color: "#22BA9D",
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Noto Sans",
            marginLeft: "1rem",
          }}
        >
          {translations.SpaceBooking}
        </Box>
      </Box>
      <Grid container spacing={0.5}>
        <Grid item sm={3.0} display="flex" alignItems="center">
          <Box width="100%">
            <Box
              marginLeft="1rem"
              marginBottom="1rem"
              fontSize="12px"
              fontWeight="bold"
              fontFamily="Noto Sans"
              color="#22BA9D"
            >
              {translations.Location}
            </Box>
            <FormControl fullWidth>
              <Select
                id="branch_id"
                name="branch_id"
                sx={{
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  height: "2.3rem",
                  marginLeft: "1rem",
                  backgroundColor: "rgba(112,112,112,0.05)",
                  "& .MuiSelect-outlined": {
                    padding: "10px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 15px",
                  },
                }}
                onChange={handlePullDownChange}
                defaultValue={0}
                value={branchId}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem disabled key={0} value={branchId}>
                  {translations.Location}
                </MenuItem>
                {companyRelations.branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.branch_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={3.0}>
          <Box>
            <Box
              marginLeft="1rem"
              marginBottom="1rem"
              fontSize="12px"
              fontWeight="bold"
              fontFamily="Noto Sans"
              color="#22BA9D"
            >
              {translations.Floor}
            </Box>
            <FormControl fullWidth>
              <Select
                id="floor_id"
                name="floor_id"
                sx={{
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  height: "2.3rem",
                  marginLeft: "0.5rem",
                  marginRight: "1rem",
                  backgroundColor: "rgba(112,112,112,0.05)",
                  "& .MuiSelect-outlined": {
                    padding: "0 10px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 15px",
                  },
                }}
                onChange={handlePullDownChange}
                value={floorId}
                IconComponent={GreenArrowIcon}
              >
                <MenuItem disabled key={0} value={floorId}>
                  {translations.Floor}
                </MenuItem>
                {selectedBranch &&
                  selectedBranch.floors
                    .filter((floor) =>
                      floor.layouts.some(
                        (layout) =>
                          layout.release_date !== null &&
                          new Date(layout.release_date) <= new Date()
                      )
                    )
                    .map((floor, index) => (
                      <MenuItem key={index + 1} value={floor.id}>
                        {floor.floor_name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={3.0} display="flex" alignItems="end" marginTop="1rem">
          <MeetingRoomModal
            isResetMeetingRoom={duplicatedMeetingRooms.length === 0}
            branchId={branchId}
            floorId={floorId}
            companyRelations={companyRelations}
            isWholeDay={isWholeDay}
          />
        </Grid>
        <Grid item sm={3.0} display="flex" alignItems="end" marginTop="1rem">
          <Button
            sx={{
              textTransform: "none",
              fontFamily: "Noto Sans",
            }}
            variant="outlined"
            fullWidth
            disabled={!(floorId != 0 && branchId != 0)}
            onClick={() => {
              navigate("/employee/calendar/layouts", {
                state: {
                  branchId: branchId,
                  floorId: floorId,
                  monthlySetting: monthlySetting,
                  scheduleForm: getValues(),
                },
              })
            }}
          >
            {translations.Desks}
          </Button>
        </Grid>
      </Grid>
      <Box display="flex" marginTop="15px">
        <Box
          fontSize="12px"
          fontFamily="Noto Sans"
          marginRight="1rem"
          display="flex"
          alignItems="center"
          width="20%"
        >
          {translations.SelectedSpace}:
        </Box>
        <Box
          display="flex"
          width="100%"
          sx={{
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {whoIsWheres.length != 0 && (
            <>
              {isMeetingRoom() && (
                <>
                  {duplicatedMeetingRooms.map((meetingRoom, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-around"
                      sx={{
                        background: "grey",
                        borderRadius: "20px",
                        padding: "0.2rem",
                        marginLeft: "0.2rem",
                        height: "2rem",
                        width: "50%",
                      }}
                    >
                      <Box color="#fff" display="flex">
                        <Typography marginLeft={1}>
                          {meetingRoom.reservable.name}
                        </Typography>
                      </Box>
                      <HighlightOffIcon
                        sx={{ color: "#fff" }}
                        onClick={handleMeetingRoomClick}
                      />
                    </Box>
                  ))}
                </>
              )}
              {whoIsWheres.map((whoIsWhere, index) => {
                if (
                  whoIsWhere.reservable?.reservable_id &&
                  whoIsWhere.reservable.reservable_type === "Seat"
                ) {
                  return (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-around"
                      sx={{
                        background: "grey",
                        borderRadius: "20px",
                        padding: "0.2rem",
                        marginLeft: "0.2rem",
                        height: "2rem",
                        width: "50%",
                      }}
                    >
                      <Box color="#fff" display="flex">
                        <Typography>
                          {whoIsWhere.userable.last_name}
                          {whoIsWhere.userable?.first_name}:
                        </Typography>
                        <Typography marginLeft={1}>
                          {whoIsWhere.reservable.name}
                        </Typography>
                      </Box>
                      <HighlightOffIcon
                        sx={{ color: "#fff" }}
                        onClick={() => {
                          handleSeatClick(whoIsWhere)
                        }}
                      />
                    </Box>
                  )
                }
              })}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
