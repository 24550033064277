import { getDate, getDay } from "date-fns"

import React, { useContext, useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { Box } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import { ScheduleForm } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { SchedulableMaxDateContext } from "../../../../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../../../../translations/mobileSchedule/en"
import jaTranslations from "../../../../../translations/mobileSchedule/ja"
import { CORRECTDATEREGEX } from "../../../../../utils/regular"
import { ValidEndDateProps } from "../../MobileNewEventSchedule"

const getWeek = (newStartTime: Date) => {
  return Math.floor((newStartTime.getDate() - newStartTime.getDay() + 12) / 7)
}

export const StartDateComponent: React.FC<ValidEndDateProps> = ({
  validEndDate,
  setValidEndDateTime,
  setValidEndDate,
  setDateError,
  defaultDate,
  setErrorMessages,
  checkMeetingOrSeat,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const { control, watch, setValue, getValues } = useFormContext<ScheduleForm>()
  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)
  const handleStartDateChange = (newStartDate: Date | null) => {
    const startTime = getValues("schedule.start_time")
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.StartDateReset])
    }
    if (newStartDate && startTime) {
      const newStartTime = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth(),
        newStartDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes()
      )
      setValue("schedule.monthly.dayly.day", getDate(newStartTime))
      setValue("schedule.monthly.dayOfWeekly.weekly", getWeek(newStartTime))
      setValue("schedule.monthly.dayOfWeekly.dayOfWeek", getDay(newStartDate))
      setValue("schedule.start_time", newStartTime)
    }
    compareToStartTime(newStartDate)
    compareToStartDate(newStartDate)
  }

  const handleStartDateBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (
      checkMeetingOrSeat(getValues("schedule.share_schedule.who_is_wheres"))
    ) {
      return setErrorMessages([translations.StartDateReset])
    }
    if (!event.target.value.match(CORRECTDATEREGEX)) {
      setValue("schedule.start_date", new Date())
      return
    }
    const dateStringArray = event.target.value.split("/")
    const startDate = new Date(
      Number(dateStringArray[0]),
      Number(dateStringArray[1]) - 1,
      Number(dateStringArray[2])
    )
    compareToStartDate(startDate)

    setValue("schedule.monthly.dayly.day", getDate(startDate))
    setValue("schedule.monthly.dayOfWeekly.weekly", getWeek(startDate))
    setValue("schedule.monthly.dayOfWeekly.dayOfWeek", getDay(startDate))
    setValue("schedule.start_date", startDate)
  }

  const compareToStartTime = (newStartDate: Date | null) => {
    const endDate = getValues("schedule.end_date")
    const endTime = getValues("schedule.end_time")
    const startTime = getValues("schedule.start_time")
    const startDate = getValues("schedule.start_date")
    let diffDate = 0
    if (startDate && endDate) {
      diffDate = Math.abs(startDate.getDate() - endDate.getDate())
    }
    if (
      newStartDate != null &&
      endDate != null &&
      endTime != null &&
      startTime != null &&
      newStartDate.getFullYear() < endDate.getFullYear() &&
      newStartDate.getMonth() < endDate.getMonth() &&
      newStartDate.getDate() < endDate.getDate() &&
      startTime.getTime() >= endTime.getTime()
    ) {
      setValidEndDateTime("#d32f2f")
      setDateError(false)
    } else {
      setErrorMessages([])
      setValidEndDateTime("rgba(0, 0, 0, 0.23)")
      setDateError(true)
    }
    setValue("schedule.start_date", newStartDate)
    if (diffDate === 0 || diffDate < 0) {
      setValue("schedule.end_date", newStartDate)
      if (newStartDate && endTime) {
        const newEndTime = new Date(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate(),
          endTime.getHours(),
          endTime.getMinutes()
        )
        setValue("schedule.end_time", newEndTime)
        const newEndDate = new Date(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate() + diffDate
        )
      }
    } else {
      if (newStartDate && endTime) {
        const newEndTime = new Date(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate() + 1,
          endTime.getHours(),
          endTime.getMinutes()
        )
        setValue("schedule.end_time", newEndTime)
        const newEndDate = new Date(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate() + diffDate
        )

        const diffEndDate = new Date(
          newStartDate.getFullYear(),
          newStartDate.getMonth(),
          newStartDate.getDate() + diffDate
        )
        setValue("schedule.end_date", diffEndDate)
      }
    }
  }

  const compareToStartDate = (newStartDate: Date | null) => {
    const endDate = getValues("schedule.end_date")
    if (
      newStartDate != null &&
      endDate != null &&
      new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth(),
        newStartDate.getDate()
      ) > new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    ) {
      setValidEndDate("#d32f2f")
      setDateError(false)
    } else {
      setValidEndDate("rgba(0, 0, 0, 0.23)")
      setDateError(true)
    }
    setValue("schedule.start_date", newStartDate)
  }

  //初回レンダリング時
  useEffect(() => {
    if (defaultDate) {
      setValue("schedule.start_date", new Date(defaultDate))
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          width: { xs: "100%", sm: "50%", md: "35%" },
        }}
      >
        <Controller
          control={control}
          name="schedule.start_date"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={translations.StartDate}
              format="yyyy/MM/dd"
              value={watch("schedule.start_date")}
              onChange={handleStartDateChange}
              maxDate={schedulableMaxDate}
              slotProps={{
                textField: {
                  name: "start_date",
                  id: "start_date",
                  required: true,
                  onBlur: handleStartDateBlur,
                  sx: {
                    "& label": {
                      color:
                        validEndDate === "rgba(0, 0, 0, 0.23)"
                          ? "rgba(0, 0, 0, 0.6)"
                          : "#d32f2f",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: validEndDate,
                      },
                    },
                  },
                },
              }}
            />
          )}
        />
      </Box>
    </>
  )
}
