import { useState } from "react"

import { updateAmStartTimeRequest } from "../../../api/company/companyRequest"

export const useAmStartTime = () => {
  const [amStartTime, setAmStartTime] = useState<Date>(
    new Date("2000-01-01 9:00:00")
  )

  const updateAmStartTime = async () => {
    try {
      const response = await updateAmStartTimeRequest({
        am_start_time: amStartTime,
      })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "就業時間を変更しました")
        window.location.reload()
      }
    } catch (e) {
      console.log("error")
    }
  }

  return {
    amStartTime,
    setAmStartTime,
    updateAmStartTime,
  }
}
