import React from "react"

import { Typography, Alert, Grid } from "@mui/material"

export const ErrorText: React.FC<string[]> = (errorMessages: string[]) => {
  return (
    <>
      <Grid
        item
        style={{
          margin: "auto 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Alert severity="error" sx={{ width: "80%" }}>
          {errorMessages.map((message, index) => {
            return (
              <Typography component="p" variant="caption" key={index}>
                {message}
              </Typography>
            )
          })}
        </Alert>
      </Grid>
    </>
  )
}
