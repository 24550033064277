import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import CircleCheckedFilled from "@mui/icons-material/CheckCircle"
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked"
import { Grid, Box, styled, Checkbox, Typography } from "@mui/material"

import { useCompanyWeeklyOffDay } from "../../../models/company/useCompanyWeeklyOffDay"
import { CompanyWeeklyOffDayType } from "../../../models/company/useCompanyWeeklyOffDay/type"
import { CancelButton } from "../../public/CancelButton"
import { ErrorText } from "../../public/ErrorText"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"

const ErrorMessageContainer = styled(Box)(() => ({
  width: "100%",
}))

export const CompanyWeeklyOffDay = () => {
  const navigate = useNavigate()
  const {
    companyWeeklyOffDay,
    setCompanyWeeklyOffDay,
    fetchCompanyWeeklyOffDay,
    createCompanyWeeklyOffDay,
    updateCompanyWeeklyOffDay,
  } = useCompanyWeeklyOffDay()
  const [loading, setLoading] = useState<boolean>(false)

  // 英語の曜日と日本語の曜日のマッピング
  const weekdayNames = {
    sunday: "日曜日",
    monday: "月曜日",
    tuesday: "火曜日",
    wednesday: "水曜日",
    thursday: "木曜日",
    friday: "金曜日",
    saturday: "土曜日",
  }

  // チェックボックスの状態を更新する
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setCompanyWeeklyOffDay((prevDays) => ({ ...prevDays, [name]: checked }))
  }

  // idを除いたCompanyWeeklyOffDayのkeyとvalueの配列を作成
  const weekDayArray = Object.entries(companyWeeklyOffDay).filter(
    ([key]) => key !== "id"
  ) as Array<[keyof Omit<CompanyWeeklyOffDayType, "id">, boolean]>

  // 公休日が6日以下かどうかをチェック
  const isInvalidHoliday = (weekday: CompanyWeeklyOffDayType) => {
    const { id, ...days } = weekday

    return Object.values(days).every((value) => value === true)
  }

  useEffect(() => {
    fetchCompanyWeeklyOffDay()
  }, [])

  return (
    <>
      {loading ? (
        <Loading type="content" />
      ) : (
        <>
          {isInvalidHoliday(companyWeeklyOffDay) && (
            <ErrorMessageContainer>
              {ErrorText(["公休日は6日以下で設定してください"])}
            </ErrorMessageContainer>
          )}
          <Box mt={6}>
            <Grid container display="flex" sx={{ height: "5rem" }}>
              <Grid item xs={1.5}>
                <Typography fontWeight="bold" fontSize="16px">
                  公休日
                </Typography>
              </Grid>
              {weekDayArray.map(([day, isChecked], index) => (
                <Grid item xs={1.5} key={index}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      name={day}
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      sx={{ padding: "0" }}
                      icon={<CircleUnchecked style={{ color: "#22BA9D" }} />}
                      checkedIcon={<CircleCheckedFilled />}
                    />
                    <Box
                      marginLeft={0.5}
                      marginRight={0.5}
                      display="flex"
                      alignItems="center"
                    >
                      {weekdayNames[day]}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "450px",
              m: "30px auto",
            }}
          >
            <Grid item xs={3} sm={4}>
              <ReflectButton
                label="更新"
                disabled={isInvalidHoliday(companyWeeklyOffDay)}
                handleClick={() => {
                  if (companyWeeklyOffDay && companyWeeklyOffDay.id !== null) {
                    updateCompanyWeeklyOffDay()
                  } else {
                    createCompanyWeeklyOffDay()
                  }
                  navigate("/company/settings/environment")
                }}
              />
            </Grid>
            <CancelButton
              label="戻る"
              handleClick={() => navigate("/company/settings/environment")}
            />
          </Box>
        </>
      )}
    </>
  )
}
