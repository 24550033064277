import React, { useState } from "react"

import { Box, styled, Typography, Button, Popover } from "@mui/material"

import { theme } from "../../../../../../config/theme"
import { MonthScheduleType } from "../../../../../../models/employee/useSchedule/months/type"
import {
  dateTimeOnlyFormat,
  dateOnlyFormat,
} from "../../../../../../utils/dateTimeFormat"

const ScheduleBox = styled(Box)(() => ({
  height: "24px",
  borderRadius: "7px",
  marginTop: "10px",
  marginBottom: "5px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 11,
}))

const CustomPopOver = styled(Box)(() => ({
  width: "300px",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
}))

const DayTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
  color: "#454545",
  textAlign: "center",
  marginBottom: "1rem",
}))

interface Props {
  day: {
    date: Date
    multiple_schedules: MonthScheduleType[]
    event_schedules: MonthScheduleType[]
    multipleDisplayCount: number
    singleDisplayCount: number
    otherDisplayCount: number
  }
}

export const MonthOtherDaySchedules: React.FC<Props> = ({ day }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openedId, setOpenedId] = useState<string | undefined>(undefined)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpenedId(event.currentTarget.id)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenedId(undefined)
  }

  // event_schedule配列のうち、未表示分に絞った配列に変換
  const list: MonthScheduleType[] = []
  const count = day.otherDisplayCount

  const singleData = day.event_schedules.filter((schedule) => {
    return schedule.width === null
  })

  list.push(...day.multiple_schedules, ...singleData)

  const otherData = list.slice().reverse()
  const reverseDisplayList = []
  for (let i = 0; i < count; i++) {
    reverseDisplayList.push(otherData[i])
    if (reverseDisplayList.length === count) {
      break
    }
  }

  const displayList = reverseDisplayList.slice().reverse()

  return (
    <>
      <ScheduleBox
        sx={{
          bgcolor: "#DBF4EF",
          color: "#4EC7B0",
          border: "1px solid #4EC7B0",
        }}
      >
        <Button
          id={dateOnlyFormat(day.date)}
          sx={{ height: "24px" }}
          onClick={handleClick}
          aria-describedby={dateOnlyFormat(day.date)}
        >
          <Typography
            align="left"
            ml="5px"
            fontSize="12px"
            fontFamily="Noto Sans"
          >
            他{day.otherDisplayCount}件
          </Typography>
        </Button>
      </ScheduleBox>
      <Popover
        id={dateOnlyFormat(day.date)}
        open={openedId === dateOnlyFormat(day.date)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <CustomPopOver>
          <DayTypography variant="h6">{dateOnlyFormat(day.date)}</DayTypography>
          {displayList.map((event, popIndex) => {
            return (
              <>
                <ScheduleBox
                  key={popIndex}
                  sx={{
                    bgcolor: "#DBF4EF",
                    color: "#4EC7B0",
                    display: "flex",
                  }}
                >
                  <Typography fontSize="12px" fontFamily="Noto Sans">
                    {event.whole_day_flag
                      ? "終日"
                      : dateTimeOnlyFormat(event.start_time)}
                  </Typography>
                  <Typography ml={1} fontSize="12px" fontFamily="Noto Sans">
                    {event.schedule_title}
                  </Typography>
                </ScheduleBox>
              </>
            )
          })}
        </CustomPopOver>
      </Popover>
    </>
  )
}
