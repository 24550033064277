import React, { useEffect } from "react"

import { Box, styled } from "@mui/material"

import { SignageReceptionQrReader } from "../../../components/signage/reception/QrReader"
import { useSignageReception } from "../../../models/signage/useReception"

const CustomBox = styled(Box)(() => ({
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const SignageReceptionQrReaderPage = () => {
  const { returnTopErrorPage } = useSignageReception()

  useEffect(() => {
    returnTopErrorPage()
  }, [])

  return (
    <>
      <CustomBox>
        <SignageReceptionQrReader />
      </CustomBox>
    </>
  )
}
