const en = {
  DeleteSchedule: "DELETE",
  Attendees: "Attendees",
  month: "/",
  day: "",
  week: "",
  Accepted: "Accepted",
  Declined: "Declined",
  invitation: "Respond to an invitation",
  Decline: "Decline",
  Accept: "Accept",
  everyMonth: "every month",
  everyWeek: "every week",
  everyWeeks: "every weeks",
  weekly: "weekly",
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  private: "Private",
  public: "Public",
  Message: "Message",
  DesksRooms: "Desk/Space",
  Edit: "Edit",
  ScheduleDelete: "Schedule deleted!",
  gest: "(gest)",
  schedule_statuses: {
    出社: "In Office",
    在宅: "Work from home",
    外出: "Out of office",
    出張: "Business Trip",
    休暇: "DAY OFF/TIME OFF",
    その他: "Other",
  },
  StartDate: "Start date",
  StartTime: "Start time",
  EndTime: "End time",
  EndDate: "End date",
}
export default en
