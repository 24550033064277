import React from "react"

import { Box } from "@mui/material"

import { SchedulesType } from "../../../../../models/employee/useSchedule/days/type"
import {
  FetchScheduleShowFunction,
  Loading,
  ScheduleInformations,
} from "../../../../../models/employee/useSchedule/show/type"
import { hours } from "../../../../../utils/hours"
import { EmployeeEventScheduleDay } from "../EmployeeEventScheduleDay"
import { EmployeeStatusScheduleDay } from "../EmployeeStatusScheduleDay"

interface Props {
  employee_id: number
  scheduled_date: string
  status_schedules: SchedulesType
  event_schedules: SchedulesType
  displayReserved: boolean
  displayNonReserved: boolean
  open: () => void
  fetchScheduleShowData: FetchScheduleShowFunction
  newScheduleNavigate: (startTime?: number, endTime?: number) => void
  setScheduleShowData: React.Dispatch<
    React.SetStateAction<Loading<ScheduleInformations>>
  >
  index: number
  defaultWidth: number
  startPosition: number
}

export const EmployeeScheduleDayTable: React.FC<Props> = ({
  employee_id,
  scheduled_date,
  status_schedules,
  event_schedules,
  displayReserved,
  displayNonReserved,
  fetchScheduleShowData,
  newScheduleNavigate,
  setScheduleShowData,
  open,
  index,
  defaultWidth,
  startPosition,
}: Props) => {
  const openNewSchedule = (startTime: number, endTime: number) => {
    newScheduleNavigate(startTime, endTime)
  }
  return (
    <>
      <Box
        sx={{
          position: index == 0 ? "sticky" : "none",
          top: index == 0 ? "20px" : "none",
          backgroundColor: "white",
          zIndex: index == 0 ? 10000 : 0,
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          {hours.map((_, hourIndex) => (
            <Box
              key={hourIndex}
              border={1}
              borderColor="#C1C1C1"
              sx={{
                borderBottomWidth: "1px",
                position: "absolute",
                width: `${defaultWidth}px`,
                left: `${startPosition + hourIndex * defaultWidth}px`,
                height: "3rem",
                zIndex: index == 0 ? 1000 : 0,
                backgroundColor: index == 0 ? "white" : "",
              }}
              onClick={() => {
                const StartTime = hourIndex
                const EndTime = hourIndex + 1
                openNewSchedule(StartTime, EndTime)
              }}
            />
          ))}
          {status_schedules.map((value, index) => (
            <EmployeeStatusScheduleDay
              key={index}
              schedule={value}
              displayReserved={displayReserved}
              displayNonReserved={displayNonReserved}
              fetchScheduleShowData={fetchScheduleShowData}
              employee_id={employee_id}
              schedule_date={scheduled_date}
              open={open}
            />
          ))}
        </Box>
        <Box
          borderTop={1}
          borderColor="#C1C1C1"
          sx={{
            width: "70%",
            height: "3rem",
          }}
        ></Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          {hours.map((_, hourIndex) => (
            <Box
              key={hourIndex}
              borderBottom={1}
              borderRight={1}
              borderLeft={1}
              borderColor="#C1C1C1"
              sx={{
                borderBottomWidth: "1px",
                position: "absolute",
                width: `${defaultWidth}px`,
                left: `${startPosition + hourIndex * defaultWidth}px`,
                height: "3rem",
                zIndex: index == 0 ? 1000 : 0,
                backgroundColor: index == 0 ? "white" : "",
              }}
              onClick={() => {
                const StartTime = hourIndex
                const EndTime = hourIndex + 1
                openNewSchedule(StartTime, EndTime)
              }}
            />
          ))}
          {event_schedules.map((value, index) => (
            <EmployeeEventScheduleDay
              key={index}
              employee_id={employee_id}
              open={open}
              schedule_date={scheduled_date}
              schedule={value}
              displayReserved={displayReserved}
              displayNonReserved={displayNonReserved}
              fetchScheduleShowData={fetchScheduleShowData}
              setScheduleShowData={setScheduleShowData}
            />
          ))}
        </Box>
        <Box
          borderBottom={1}
          borderColor="#C1C1C1"
          sx={{
            width: "70%",
            height: "3rem",
          }}
        ></Box>
      </Box>
    </>
  )
}
