import React, { useEffect, useState } from "react"
import {
  SubmitHandler,
  UseFormHandleSubmit,
  FieldErrors,
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  InputLabel,
  TextField,
  Grid,
  Box,
  styled,
  Button,
  Modal,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  List,
  ListSubheader,
  SelectChangeEvent,
  Typography,
} from "@mui/material"

import { theme } from "../../../../config/theme"
import { useSearchEmployeeLeader } from "../../../../models/company/useEmployeeLeaderSearch"
import {
  CompanyTeamFormType,
  TeamHierarchyListResponseType,
} from "../../../../models/company/useTeam/type"
import { CompanyRelationsType } from "../../../../models/public/useCompanyRelations/type"
import { CancelButton } from "../../../public/CancelButton"
import { ErrorText } from "../../../public/ErrorText"
import { GreenArrowIcon } from "../../../public/GreenArrowIcon"
import { ReflectButton } from "../../../public/ReflectButton"
import { WarningText } from "../../../public/WarningText"
import { TeamEmployeeSearchModal } from "./TeamEmployeeSearchModal/index"

const CustomButton = styled(Button)(() => ({
  width: "200px",
  height: "35px",
  fontSize: theme.spacing(1.75),
  fontWeight: "bold",
  borderColor: "#22BA9D",
  borderStyle: "solid",
  borderRadius: "6px",
}))

const CustomBox = styled(Box)(() => ({
  width: "100%",
  letterSpacing: "0.7px",
  color: "#454545",
  marginLeft: "2rem",
  marginBottom: "1rem",
}))

const CustomList = styled(List)(() => ({
  width: "100%",
  ml: 2,
  height: "400px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
  },
}))

const CustomTypography = styled(Typography)(() => ({
  letterSpacing: "0.7px",
  fontWeight: "bold",
}))

const CustomGridContainer = styled(Grid)(() => ({
  padding: theme.spacing(1),
  width: "100%",
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

const EmployeeSearchModalOverlay = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  width: theme.spacing(125),
  height: theme.spacing(80),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(0.5),
}))

interface Props {
  control: Control<CompanyTeamFormType>
  team: CompanyTeamFormType
  handleSubmit: UseFormHandleSubmit<CompanyTeamFormType>
  errors: FieldErrors<CompanyTeamFormType>
  isValid: boolean
  getValues: UseFormGetValues<CompanyTeamFormType>
  setValue: UseFormSetValue<CompanyTeamFormType>
  watch: UseFormWatch<CompanyTeamFormType>
  onCreateSubmit: SubmitHandler<CompanyTeamFormType>
  onUpdateSubmit: SubmitHandler<CompanyTeamFormType>
  errorMessages: string[]
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>
  companyRelations: CompanyRelationsType
  errorMessageOpen: boolean
  setErrorMessageOpen: React.Dispatch<React.SetStateAction<boolean>>
  forbidden: boolean
  teamHierarchyList: TeamHierarchyListResponseType
}

export const CompanyTeamForm: React.FC<Props> = ({
  control,
  errors,
  isValid,
  setValue,
  getValues,
  watch,
  handleSubmit,
  onCreateSubmit,
  onUpdateSubmit,
  errorMessages,
  setErrorMessages,
  companyRelations,
  errorMessageOpen,
  setErrorMessageOpen,
  forbidden,
  teamHierarchyList,
}: Props) => {
  const navigate = useNavigate()
  const employeeSearchObject = useSearchEmployeeLeader()
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false)
  const [isCompanyTeamsExist, setIsCompanyTeamsExist] = useState<boolean>(false)
  const [warningMessages, setWarningMessages] = useState<string[]>([])

  const handleSubmitEvent =
    getValues("id") === 0 ? onCreateSubmit : onUpdateSubmit

  const handleOpen = () => {
    setIsSearchModalOpen(true)
    setErrorMessages([""])
    setErrorMessageOpen(false)
  }

  const handleClose = () => {
    setIsSearchModalOpen(false)
  }

  // 紐付ける組織のバリデーション
  const parentTeamValidation = () => {
    if (
      isValid &&
      companyRelations.teams.length > 0 &&
      getValues("parent_id") === 0
    ) {
      setErrorMessages(["紐付ける組織を選択してください"])
      setErrorMessageOpen(true)
      setIsCompanyTeamsExist(false)
    } else {
      setErrorMessages([""])
      setErrorMessageOpen(false)
      setIsCompanyTeamsExist(true)
    }
  }

  // 紐付ける組織を選択した時の処理
  const parentTeamSelected = (e: SelectChangeEvent<number>) => {
    if (e.target.value !== 0) {
      parentTeamValidation()
    }
  }

  interface TeamTreeProps {
    teamHierarchyList: TeamHierarchyListResponseType
  }

  // 階層構造のコンポーネント
  const TeamTree: React.FC<TeamTreeProps> = ({
    teamHierarchyList,
  }: TeamTreeProps) => {
    return (
      <>
        <Box sx={{ pl: `${teamHierarchyList.depth}0px`, fontSize: "14px" }}>
          {teamHierarchyList.team_name}
          {teamHierarchyList.children?.map((team) => {
            return <TeamTree key={team.team_name} teamHierarchyList={team} />
          })}
        </Box>
      </>
    )
  }

  useEffect(() => {
    parentTeamValidation()
  }, [isCompanyTeamsExist, isValid])

  useEffect(() => {
    setWarningMessages([
      "最初にトップ階層となる組織を作成してください。他の組織は全てその配下に紐付ける形になります",
    ])
  }, [])
  return (
    <>
      <Grid container maxHeight="80%" mb={3}>
        <Grid item xs={12} sm={7.5}>
          <Box
            sx={{
              width: "80%",
            }}
          >
            {companyRelations.teams.length === 0 &&
              WarningText(warningMessages)}
          </Box>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="team_name">所属名</InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={5}>
              <Controller
                control={control}
                name="team_name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.team_name}
                    helperText={errors.team_name?.message}
                    required
                    id="team_name"
                    name="team_name"
                    label="所属名"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="team_name_kana">所属名（カナ）</InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={5}>
              <Controller
                control={control}
                name="team_name_kana"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.team_name_kana}
                    helperText={errors.team_name_kana?.message}
                    required
                    id="team_name_kana"
                    name="team_name_kana"
                    label="所属名（カナ）"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </CustomGridContainer>
          {companyRelations.teams.length > 0 && (
            <CustomGridContainer container spacing={3}>
              <CustomGridItem item xs={12} sm={3}>
                <InputLabel htmlFor="team_name_kana">紐付ける組織</InputLabel>
              </CustomGridItem>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="branch_id">組織名 *</InputLabel>
                  <Controller
                    control={control}
                    name="parent_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={!!errors.parent_id}
                        id="parent_id"
                        name="parent_id"
                        variant="outlined"
                        margin="dense"
                        label="組織名 *"
                        labelId="組織名 *"
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e)
                          parentTeamSelected(e)
                        }}
                        disabled={forbidden}
                        sx={{
                          background: "rgba(112,112,112,0.05)",
                        }}
                        IconComponent={GreenArrowIcon}
                      >
                        <MenuItem disabled={true} key={0} value={0}>
                          選択する
                        </MenuItem>
                        {companyRelations.teams.map(
                          (team) =>
                            team.id !== getValues("id") && (
                              <MenuItem key={team.id} value={team.id}>
                                {team.team_name}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    )}
                  />
                  <FormHelperText error={!!errors.parent_id?.message}>
                    {errors.parent_id?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </CustomGridContainer>
          )}
          <CustomGridContainer container spacing={3}>
            <CustomGridItem item xs={12} sm={3}>
              <InputLabel htmlFor="team_manager_id">所属長</InputLabel>
            </CustomGridItem>
            <Grid item xs={12} sm={5} display="flex">
              <Controller
                control={control}
                name="employee_id"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="employee_id"
                    name="employee_id"
                    variant="outlined"
                    value={watch("team_leader_name")}
                    disabled
                    sx={{
                      background: "rgba(112,112,112,0.05)",
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ mb: 0, mx: "auto" }}
              display="flex"
              alignItems="end"
            >
              <CustomButton
                variant="outlined"
                id="select_team_manager"
                onClick={handleOpen}
              >
                所属長を選択
              </CustomButton>
            </Grid>
          </CustomGridContainer>
          <Modal
            open={isSearchModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <EmployeeSearchModalOverlay>
              <TeamEmployeeSearchModal
                {...employeeSearchObject}
                teamFormSetValue={setValue}
                companyRelations={companyRelations}
                setIsSearchModalOpen={setIsSearchModalOpen}
                handleClose={handleClose}
              />
            </EmployeeSearchModalOverlay>
          </Modal>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ borderLeft: "solid 1px #E5E5E5" }}>
          <CustomBox>
            <CustomList
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  sx={{ width: "100%", mb: "1rem", pl: 0 }}
                  component="div"
                  id="nested-list-subheader"
                >
                  <CustomTypography fontSize="16px" color="primary">
                    組織階層
                  </CustomTypography>
                </ListSubheader>
              }
            >
              <TeamTree teamHierarchyList={teamHierarchyList} />
            </CustomList>
          </CustomBox>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "50%",
          margin: "0 auto",
        }}
      >
        {errorMessageOpen && ErrorText(errorMessages)}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "450px",
          m: "40px auto",
        }}
      >
        <CancelButton
          label="戻る"
          handleClick={() => {
            navigate("/company/teams")
          }}
        />
        <ReflectButton
          label={getValues("id") === 0 ? "登録" : "更新"}
          disabled={!isValid || forbidden || !isCompanyTeamsExist}
          handleClick={() => {
            handleSubmit(handleSubmitEvent)()
          }}
        />
      </Box>
    </>
  )
}
