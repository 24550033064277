const en = {
  WelcomeBack: "Welcome back! ",
  PleaseLoginAccount: "Please login to your account.",
  EmailAddress: "E-mail address(required)",
  Password: "Password(required)",
  Login: "Login",
  ForgotPassword: "Forgot your password?",
  LoginFailed: "Login failed.",
  YourPassword: "Enter your Password",
  Azure: "This system uses Azure AD authentication.",
  EmailLogin: "Please log in using only your email address.",
  Provider: "Failed to retrieve the identity provider.",
}
export default en
