import React from "react"
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form"

import { Box, Grid, styled } from "@mui/material"

import { useLanguage } from "../../../../contexts/LanguageContext"
import {
  Employee,
  SearchedEmployeeResult,
  SearchEmployeeRequestType,
} from "../../../../models/employee/useEmployeeSearch/type"
import enTranslations from "../../../../translations/employeeStatus/employeeStatusSearch/en"
import jaTranslations from "../../../../translations/employeeStatus/employeeStatusSearch/ja"
import { CancelButton } from "../../../public/CancelButton"
import { ReflectButton } from "../../../public/ReflectButton"
import { EmployeeSearchForm } from "../EmployeeSearchForm"
import { SearchedEmployeeList } from "../SearchedEmployeeList"

interface Props {
  control: Control<SearchEmployeeRequestType>
  errors: FieldErrors<SearchEmployeeRequestType>
  handleSubmit: UseFormHandleSubmit<SearchEmployeeRequestType>
  onSubmit: SubmitHandler<SearchEmployeeRequestType>
  setValue: UseFormSetValue<SearchEmployeeRequestType>
  clearErrors: UseFormClearErrors<SearchEmployeeRequestType>
  setSearchedEmployeeResult: React.Dispatch<
    React.SetStateAction<SearchedEmployeeResult>
  >
  searchedEmployeeResult: Employee[]
  handleEmployeeChecked: (selectedEmployeeId: number) => void
  handleEmployeeFixed: () => void
  setIsSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
  loading: boolean
}

const CustomGridItem = styled(Grid)(() => ({
  minHeight: "70%",
  display: "flex",
  alignItems: "center",
  marginRight: "0.5rem",
}))

const CustomGridSearchItem = styled(Grid)(() => ({
  minHeight: "70%",
  borderLeft: "solid 1px #E5E5E5",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const EmployeeSearchModal = ({
  control,
  errors,
  handleSubmit,
  onSubmit,
  setValue,
  clearErrors,
  setSearchedEmployeeResult,
  searchedEmployeeResult,
  handleEmployeeChecked,
  handleEmployeeFixed,
  setIsSearchModalOpen,
  handleClose,
  loading,
}: Props) => {
  // 従業員を選択後確定ボタンを押した時の挙動
  const handleFixAndClose = () => {
    handleEmployeeFixed()
    setIsSearchModalOpen(false)
    setValue("branch_id", 0)
    setValue("floor_id", 0)
    setValue("team_id", 0)
    setValue("employee_group_id", 0)
    clearErrors(undefined)
  }

  // キャンセルボタンを押した時の挙動
  const handleCancel = () => {
    handleClose()
    setIsSearchModalOpen(false)
    setValue("branch_id", 0)
    setValue("floor_id", 0)
    setValue("team_id", 0)
    setValue("employee_group_id", 0)
    clearErrors(undefined)
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        sx={{
          height: "85%",
        }}
      >
        <CustomGridItem item sm={8.25}>
          <SearchedEmployeeList
            employees={searchedEmployeeResult}
            handleEmployeeChecked={handleEmployeeChecked}
            loading={loading}
          />
        </CustomGridItem>
        <CustomGridSearchItem item sm={3.25}>
          <EmployeeSearchForm
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            setValue={setValue}
            setSearchedEmployeeResult={setSearchedEmployeeResult}
          />
        </CustomGridSearchItem>
      </Grid>
      <Grid item container justifyContent="center" spacing={3}>
        <Grid
          item
          sm={4}
          sx={{
            textAlign: "center",
          }}
        >
          <CancelButton
            label={translations.Cancel}
            handleClick={handleCancel}
          />
        </Grid>
        <Grid
          item
          sm={4}
          sx={{
            textAlign: "center",
          }}
        >
          <ReflectButton
            label={translations.Enter}
            handleClick={handleFixAndClose}
          />
        </Grid>
      </Grid>
    </>
  )
}
