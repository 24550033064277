import React, { useContext } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Box, Typography, Chip } from "@mui/material"

import { EmployeesType } from "../../../../../models/employee/useSchedule/type"
import { MemberSchedulesType } from "../../../../../models/employee/useSchedule/weeks/type"
import { AuthContext } from "../../../../../providers/AuthProvider"

type SchedulesTable = MemberSchedulesType[]

interface Props {
  first_name: string
  last_name: string
  email: string
  index: number
  scheduleMembers: EmployeesType
  weekSchedules: SchedulesTable[]
  changeDisplay: (
    members: EmployeesType,
    schedules: SchedulesTable[],
    index: number
  ) => void
}

export const EmployeeScheduleWeekSideBar: React.FC<Props> = ({
  email,
  first_name,
  last_name,
  index,
  scheduleMembers,
  weekSchedules,
  changeDisplay,
}: Props) => {
  const { currentUser } = useContext(AuthContext)
  const switchVisible = () => {
    changeDisplay(scheduleMembers, weekSchedules, index)
  }

  return (
    <>
      <Box
        sx={{
          position: index == 0 ? "sticky" : "none",
          backgroundColor: "white",
          zIndex: index == 0 ? 1001 : 100,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          borderTop={1}
          borderLeft={1}
          borderBottom={1}
          borderColor="#C1C1C1"
          paddingLeft={1}
          sx={{
            height: "3.5rem",
            lineHeight: "3.5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Noto Sans",
              letterSpacing: "0.5",
            }}
          >
            <span>{last_name}</span>
            <span style={{ paddingLeft: "1rem" }}>{first_name}</span>
          </Typography>
          {email !== currentUser?.attributes.email && (
            <Box mx="0.5rem">
              <Chip
                sx={{
                  "& .MuiChip-icon": {
                    marginRight: "-12px",
                  },
                }}
                label=""
                icon={<CloseIcon />}
                onClick={switchVisible}
                size="small"
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
