const ja = {
  profile: "プロフィール 詳細",
  Profile: "プロフィール",
  Help: "ヘルプ",
  ChangePassword: "パスワード再設定",
  ManagementConsole: "法人管理者画面へ",
  LogOut: "ログアウト",
  PleaseSetIcon: " アイコンを設定する",
  Report: "従業員管理、レポート画面へ",
  employee: "従業員画面へ",
}
export default ja
