import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, styled, Typography, CircularProgress } from "@mui/material"

import { theme } from "../../../../config/theme"
import {
  MeetingRoomDetailScheduleType,
  SignageMeetingRoomType,
} from "../../../../models/signage/useMeetingRoom/type"
import { EARLY_CHECK_IN_INTERVAL } from "../../../../utils/const"
import { dateTimeOnlyFormat } from "../../../../utils/dateTimeFormat"
import { describeTimeRange } from "../../../../utils/schedules"
import { CancelButton } from "../../../public/CancelButton"
import { MeetingRoomScheduleCreator } from "../MeetingRoomScheduleCreator"
import { SignageMeetingRoomCheckIn } from "../SignageMeetingRoomCheckIn"
import { SignageMeetingRoomCheckOut } from "../SignageMeetingRoomCheckOut"

const LoadingBox = styled(Box)(() => ({
  height: "80%",
  width: "90%",
  backgroundColor: "#fff",
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomBox = styled(Box)(() => ({
  height: "80%",
  width: "90%",
  backgroundColor: "#fff",
  borderRadius: theme.spacing(1),
  paddingY: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const TitleTypography = styled(Typography)(() => ({
  fontSize: theme.spacing(3.5),
  fontWeight: "bold",
  color: "#454545",
  letterSpacing: theme.spacing(0.3),
}))

// 画面サイズによって時間表示の大きさを変更
const TimeTypography = styled(Typography)`
  font-size: ${theme.spacing(5)};
  font-weight: bold;
  color: #454545;
  @media screen and (max-width: 800px) and (max-height: 600px) {
    font-size: ${theme.spacing(3.5)};
  }
`

interface Props {
  loading: boolean
  meetingRoomDetailSchedule: MeetingRoomDetailScheduleType
  creatorIconUrl: string
  meetingRoomData: SignageMeetingRoomType
  meetingRoomCheckInUrl: string
  meetingRoomCheckOutUrl: string
}

// チェックイン可能時間かどうかチェック
const earlyCheckInTime = (startTime: Date) => {
  return new Date(
    startTime.getFullYear(),
    startTime.getMonth(),
    startTime.getDate(),
    startTime.getHours(),
    startTime.getMinutes() - EARLY_CHECK_IN_INTERVAL
  )
}

// TODO: 自動キャンセル時間の設定時に時間を変更
// 会議開始15分以内かどうかチェック
const laterCheckInTime = (startTime: Date) => {
  return new Date(
    startTime.getFullYear(),
    startTime.getMonth(),
    startTime.getDate(),
    startTime.getHours(),
    startTime.getMinutes() + 15
  )
}

// NaN表示になる問題解消のため、一度stringに変換
const setStartTime = (startTime: string) => {
  const startTimeString = startTime.toLocaleString()
  return new Date(startTimeString.replace(/-/g, "/"))
}

const setEndTime = (endTime: string) => {
  const endTimeString = endTime.toLocaleString()
  return new Date(endTimeString.replace(/-/g, "/"))
}

export const MeetingRoomScheduleDetail: React.FC<Props> = ({
  loading,
  meetingRoomDetailSchedule,
  creatorIconUrl,
  meetingRoomCheckInUrl,
  meetingRoomCheckOutUrl,
}) => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [isCheckInTime, setIsCheckInTime] = useState<boolean>(false)
  const [isCheckOutTime, setIsCheckOutTime] = useState<boolean>(false)

  const startTime = setStartTime(meetingRoomDetailSchedule.start_time)
  const endTime = setEndTime(meetingRoomDetailSchedule.end_time)
  const timeNow = new Date()

  useEffect(() => {
    // チェックイン可能か判定
    if (earlyCheckInTime(startTime) <= timeNow && endTime >= timeNow) {
      setIsCheckInTime(true)
    } else {
      setIsCheckInTime(false)
    }
    // チェックアウト可能か判定
    if (
      earlyCheckInTime(startTime) <= timeNow &&
      meetingRoomDetailSchedule.checked_in
    ) {
      setIsCheckOutTime(true)
    } else {
      setIsCheckOutTime(false)
    }
  }, [startTime])

  return (
    <>
      {loading ? (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      ) : (
        <>
          <CustomBox>
            <Box mt={4} mx={2}>
              <TitleTypography>
                {meetingRoomDetailSchedule.is_secret
                  ? "非公開"
                  : meetingRoomDetailSchedule.schedule_title}
              </TitleTypography>
            </Box>
            {!isCheckOutTime && (
              <>
                <MeetingRoomScheduleCreator
                  meetingRoomDetailSchedule={meetingRoomDetailSchedule}
                  creatorIconUrl={creatorIconUrl}
                />
                <Box my={2}>
                  <TimeTypography>
                    {describeTimeRange(startTime, endTime, timeNow)}
                  </TimeTypography>
                </Box>
              </>
            )}
            {isCheckInTime && !meetingRoomDetailSchedule.checked_in && (
              <SignageMeetingRoomCheckIn
                meetingRoomCheckInUrl={meetingRoomCheckInUrl}
              />
            )}
            {isCheckOutTime && (
              <SignageMeetingRoomCheckOut
                endTime={endTime}
                meetingRoomCheckOutUrl={meetingRoomCheckOutUrl}
              />
            )}
            <CancelButton
              label="戻る"
              handleClick={() =>
                navigate(`/signage/meeting-rooms/${urlParams.meeting_room_id}`)
              }
            />
          </CustomBox>
        </>
      )}
    </>
  )
}
