// 各コンポーネントでユーザーの情報を扱えるようにするためのprovider
import React, { createContext, useEffect } from "react"

import { useSchedulableMonthes } from "../models/company/useSchedulableMonthes"

type SchedulableMaxDate = {
  schedulableMaxDate: Date
  schedulableMonthes: number
}

interface Props {
  children?: React.ReactNode
}

export const SchedulableMaxDateContext = createContext<SchedulableMaxDate>(
  {} as SchedulableMaxDate
)

export const SchedulableMaxDateProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const { schedulableMonthes, fetchSchedulableMonthes } =
    useSchedulableMonthes()

  const schedulableMaxDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + schedulableMonthes,
    new Date().getDate()
  )

  useEffect(() => {
    ;(async () => {
      fetchSchedulableMonthes()
    })()
  }, [])

  return (
    <>
      <SchedulableMaxDateContext.Provider
        value={{
          schedulableMonthes,
          schedulableMaxDate,
        }}
      >
        {children}
      </SchedulableMaxDateContext.Provider>
    </>
  )
}
