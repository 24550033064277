import React, { memo } from "react"

import { Box } from "@mui/material"

import { AreaType } from "../../../models/company/useDashboard/type"
import { areaIconStyle, areaStyle } from "../../../styles/areaStyle"

interface Props {
  area: AreaType
}

/*
  レイアウト画像に会議室のアイコンが表示される
*/
export const EmployeeHomeLayoutArea: React.FC<Props> = memo(
  ({ area }: Props) => {
    const areaId = area.id
    const areaName = area.area_name
    const areaToMeetingRoom = area.area_to_meeting_room

    return (
      <Box
        id={`A${String(areaId)}`}
        className={"area"}
        sx={areaStyle(
          area.start_x,
          area.start_y,
          area.end_x,
          area.end_y,
          area.color_code
        )}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: area.color_code,
            fontSize: "30px",
          }}
        >
          {areaName}
        </div>
        {areaToMeetingRoom && (
          <>
            <span style={areaIconStyle(area.color_code) as React.CSSProperties}>
              M
            </span>
            <span
              style={{
                top: "60px",
                left: "60px",
                color: area.color_code,
                position: "absolute",
                fontSize: "30px",
              }}
            >
              {areaToMeetingRoom.meeting_room_code}
            </span>
          </>
        )}
      </Box>
    )
  }
)
