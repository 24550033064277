import React, { useContext, useEffect, useState } from "react"

import {
  Button,
  Box,
  Typography,
  styled,
  Modal,
  InputLabel,
  Grid,
} from "@mui/material"

import { CancelButton } from "../../../components/public/CancelButton"
import { ReflectButton } from "../../../components/public/ReflectButton"
import { useLanguage } from "../../../contexts/LanguageContext"
import { usePasswordExpireMonthes } from "../../../models/company/usePasswordExpireMonthes"
import { AuthContext } from "../../../providers/AuthProvider"
import enTranslations from "../../../translations/employeeSettings/employeeSettingsEnvironment/en"
import jaTranslations from "../../../translations/employeeSettings/employeeSettingsEnvironment/ja"
import { useOpen } from "../../../utils/isOpen"

const CustomListBox = styled(Box)(({ theme }) => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  backgroundColor: "#FFFFFF",
}))

const ToDoListBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(6),
}))

const ToDoTitleBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const ToDoListText = styled(Typography)(() => ({
  color: "#707070",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  marginRight: "200px",
}))

const CustomLayoutModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  alignItems: "center",
  width: "600px",
  borderRadius: "10px",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0),
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  width: "216px",
  height: "40px",
  fontSize: "16px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: theme.spacing(1),
  marginRight: theme.spacing(6),
}))

export const EnvironmentSettingsComponent = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const passwordExpireMonthesOpen = useOpen()
  const { fetchPasswordExpireMonthes } = usePasswordExpireMonthes()

  // 言語の切り替え
  const { language, setLanguage } = useLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language)
  const translations = language === "en" ? enTranslations : jaTranslations

  //   従業員ごとに言語を切り替える
  const { currentUser } = useContext(AuthContext)
  const userID = currentUser ? currentUser.attributes.sub : "default"

  //   従業員が切り替わるたびに言語が切り替わる
  useEffect(() => {
    const storedLanguage = localStorage.getItem(`language_${userID}`)
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage)
      setLanguage(storedLanguage)
    }
  }, [userID, currentUser])

  useEffect(() => {
    const planState = async () => {
      await fetchPasswordExpireMonthes()
    }
    planState().then(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>
      {loading ? null : (
        <>
          <CustomListBox>
            <ToDoListBox>
              <ToDoTitleBox>
                <ToDoListText>{translations.Language}</ToDoListText>
                <ToDoListText sx={{ fontSize: "12px" }}>
                  <Box>{language === "en" ? "English (BETA)" : "日本語"}</Box>
                </ToDoListText>
              </ToDoTitleBox>
            </ToDoListBox>
            <Box>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={passwordExpireMonthesOpen.open}
                sx={{ textTransform: "none" }}
              >
                {translations.Change}
              </CustomButton>
            </Box>
          </CustomListBox>
          <Modal
            open={passwordExpireMonthesOpen.isOpen}
            onClose={passwordExpireMonthesOpen.close}
          >
            <CustomLayoutModal>
              <CustomGridContainer container spacing={3}>
                <CustomGridItem item xs={6}>
                  <InputLabel htmlFor="password_expire_monthes">
                    <Typography color="inherit" fontWeight="bold">
                      {translations.Language}
                    </Typography>
                  </InputLabel>
                </CustomGridItem>
                <Box sx={{ marginRight: 2 }}>
                  <Button
                    sx={{ padding: "10px", marginTop: "24px" }}
                    variant={
                      selectedLanguage === "en" ? "contained" : "outlined"
                    }
                    onClick={() => setSelectedLanguage("en")}
                  >
                    English
                  </Button>
                </Box>
                <Button
                  sx={{ padding: "10px", marginTop: "24px" }}
                  variant={selectedLanguage === "ja" ? "contained" : "outlined"}
                  onClick={() => setSelectedLanguage("ja")}
                >
                  日本語
                </Button>
              </CustomGridContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "450px",
                  m: "30px auto",
                }}
              >
                <Grid item xs={4} sm={3}>
                  <ReflectButton
                    // sx={{ textTransform: "none" }}
                    label={translations.Update}
                    handleClick={() => {
                      localStorage.setItem(
                        `language_${userID}`,
                        selectedLanguage
                      )
                      setLanguage(selectedLanguage)
                      passwordExpireMonthesOpen.close()
                    }}
                  />
                </Grid>
                <CancelButton
                  label={translations.Close}
                  handleClick={passwordExpireMonthesOpen.close}
                />
              </Box>
            </CustomLayoutModal>
          </Modal>
        </>
      )}
    </>
  )
}
