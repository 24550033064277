import {
  CompanyRequestType,
  HeaderCompanyType,
  PreparationResponseType,
  SubscriptionNotifyEmailResponceType,
} from "../../models/company/useCompany/type"
import { CompanyRelationsType } from "../../models/public/useCompanyRelations/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchCompanyRequest = async (): Promise<
  ApiResponse<HeaderCompanyType>
> => {
  try {
    const data = await api.get("/company/company_info_registrations")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const createCompanyRequest = async (
  params: CompanyRequestType
): Promise<ApiResponse<CompanyRelationsType>> => {
  try {
    const data = await api.post("/company/company_info_registrations", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchCompanyPrepareRequest = async (): Promise<
  ApiResponse<PreparationResponseType>
> => {
  try {
    const data = await api.get("/company/preparations")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updatePaymentMethodsRequest = async (params: {
  payment_methods: number
}): Promise<ApiResponse<CompanyRelationsType>> => {
  try {
    const data = await api.patch("/company/companies/payment_methods", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateAmStartTimeRequest = async (params: {
  am_start_time: Date
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/companies/am_start_time", params)

    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
export const updateSubscriptionNotifyEmailRequest = async (params: {
  subscription_notify_email: string
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      "/company/companies/subscription_notify_email",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateCompanyNameRequest = async (params: {
  company_name: string
  company_name_kana: string
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      "/company/companies/update_company_info",
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateAmEndTimeRequest = async (params: {
  am_end_time: Date
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/companies/am_end_time", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updatePmStartTimeRequest = async (params: {
  pm_start_time: Date
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/companies/pm_start_time", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updatePmEndTimeRequest = async (params: {
  pm_end_time: Date
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/company/companies/pm_end_time", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchSubscriptionNotifyEmailRequest = async (): Promise<
  ApiResponse<SubscriptionNotifyEmailResponceType>
> => {
  try {
    const data = await api.get("/company/subscription_notify_email")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
