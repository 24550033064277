import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { SignageFloorMap } from "../../../components/signage/floor/Map"
import { useSignageFloor } from "../../../models/signage/useFloor"

export const SignageFloorMapPage = () => {
  const signageObject = useSignageFloor()
  const urlParams = useParams()

  useEffect(() => {
    signageObject.fetchSignageFloorLayout(Number(urlParams.floor_id))
  }, [])

  return (
    <>
      <SignageFloorMap {...signageObject} />
    </>
  )
}
