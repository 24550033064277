import {
  MySchedule,
  ScheduleHomeParDate,
} from "../models/employee/useSchedule/useScheduleHome/type"
import { dateTimeOnlyFormat, formatDateForSafari } from "./dateTimeFormat"
import { hours } from "./hours"

export const roundDownMinutesDate = (date: Date): Date => {
  const roundDownMinute = 15
  const roundDownNumber = 0
  const roundedDownMinutes =
    ((date.getMinutes() / roundDownMinute) | roundDownNumber) * roundDownMinute

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    roundedDownMinutes
  )
}

export const differenceFromStartTimeToEndTime = (
  startTime: number,
  endTime: number
): number => {
  const convertHours = 60 * 60 * 1000
  const diff = startTime - endTime
  return Math.abs(diff) / convertHours
}

export const findHourIndex = (start_time: Date): number => {
  return hours.findIndex((hour) => hour === `${start_time.getHours()}:00`)
}

export const scheduleBarWidth = (
  diffHours: number,
  defaultWidth: number
): number => {
  const intHour = Math.floor(diffHours)
  const decimalHour = diffHours - intHour
  return intHour * defaultWidth + decimalHour * defaultWidth
}

export const scheduleBarLeftPosition = (
  startTimeMinutes: number,
  hourIndex: number,
  startPosition: number,
  defaultWidth: number
): number => {
  const decimalHour = startTimeMinutes / 60 // start_timeの小数点以下を算出
  return startPosition + hourIndex * defaultWidth + decimalHour * defaultWidth
}

export const scheduleBarHeight = (
  diffHours: number,
  defaultHeight: number
): number => {
  const intHour = Math.floor(diffHours)
  const decimalHour = diffHours - intHour
  return intHour * defaultHeight + decimalHour * defaultHeight
}

export const scheduleBarTopPosition = (
  startTimeMinutes: number,
  hourIndex: number,
  startPosition: number,
  defaultHeight: number
): number => {
  const decimalHour = startTimeMinutes / 60 // start_timeの小数点以下を算出
  return startPosition + hourIndex * defaultHeight + decimalHour * defaultHeight
}

// 予定のステータスに応じて背景色を変更
export const bgColorChoice = (schedule_status: string): string => {
  if (schedule_status === "remote") {
    return "#C1C1C1"
  } else if (schedule_status === "going_out") {
    return "#EFC34B"
  } else if (schedule_status === "trip") {
    return "#D972A5"
  } else if (schedule_status === "holiday") {
    return "#4F9BD0"
  } else {
    return "#4EC7B0"
  }
}

// 複数日にまたがる予定を処理し、日ごとの予定リストを返す
export const processMultiDaySchedule = <
  T extends { start_time: string; end_time: string }
>(
  schedule: T
) => {
  const startDate = new Date(formatDateForSafari(schedule.start_time))
  const endDate = new Date(formatDateForSafari(schedule.end_time))
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new Error()
  }

  const scheduleDays = []
  let currentProcessingDay = new Date(startDate)

  // 開始日から終了日までループ
  while (currentProcessingDay <= endDate) {
    const dayStartTime = new Date(currentProcessingDay)
    let dayEndTime

    if (isSameDay(currentProcessingDay, endDate)) {
      dayEndTime = endDate
    } else {
      dayEndTime = new Date(
        currentProcessingDay.getFullYear(),
        currentProcessingDay.getMonth(),
        currentProcessingDay.getDate(),
        23,
        59,
        59
      )
    }

    scheduleDays.push({ startTime: dayStartTime, endTime: dayEndTime })

    currentProcessingDay = new Date(
      currentProcessingDay.getFullYear(),
      currentProcessingDay.getMonth(),
      currentProcessingDay.getDate() + 1
    )
  }
  return scheduleDays
}

// 日付が同じかどうかをチェック
export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

// endTimeが23:59かどうかを確認
export const isEndTimeAtMidnight = (endTime: Date) => {
  return (
    endTime.getHours() === 23 &&
    endTime.getMinutes() === 59 &&
    endTime.getSeconds() === 59
  )
}

// mySchedulesの中のmulti_day_scheduleにreservableの項目がないので加工
// TO DO:できればバックエンドでやりたいが、一旦フロントで対応。
export const addReservableToMultiDayScheduleData = (
  mySchedules: MySchedule
) => {
  return mySchedules.map((scheduleDay) => {
    const updatedEventSchedules = scheduleDay.event_schedules.map(
      (eventSchedule) => {
        if (eventSchedule.multi_day_schedule_data) {
          const updatedMultiDayScheduleData =
            eventSchedule.multi_day_schedule_data.map((multiDayData) => {
              const data = { ...multiDayData }
              if (eventSchedule.reservable_type != null) {
                data.reservable = eventSchedule.reservable
              }
              return data
            })

          return {
            ...eventSchedule,
            multi_day_schedule_data: updatedMultiDayScheduleData,
          }
        }
        return eventSchedule
      }
    )

    const updatedStatusSchedules = scheduleDay.status_schedules.map(
      (statusSchedule) => {
        if (statusSchedule.multi_day_schedule_data) {
          const updatedMultiDayScheduleData =
            statusSchedule.multi_day_schedule_data.map((multiDayData) => {
              const data = { ...multiDayData }
              if (statusSchedule.reservable_type != null) {
                // null または undefined ではない場合
                data.reservable = statusSchedule.reservable
              }
              return data
            })

          return {
            ...statusSchedule,
            multi_day_schedule_data: updatedMultiDayScheduleData,
          }
        }
        return statusSchedule
      }
    )

    return {
      ...scheduleDay,
      event_schedules: updatedEventSchedules,
      status_schedules: updatedStatusSchedules,
    }
  })
}

// 予定が複数日にまたがる場合、status_schedulesの各日の開始時間と終了時間を計算
export const processStatusSchedules = (
  mySchedules: ScheduleHomeParDate[]
): ScheduleHomeParDate[] => {
  return mySchedules.map((day) => {
    const newStatusSchedules = day.status_schedules.map((schedule) => {
      if (
        schedule.multi_day_schedule_data &&
        schedule.multi_day_schedule_data.length === 0
      ) {
        return schedule
      }

      const formattedStartTime = formatDateForSafari(schedule.start_time)
      const formattedEndTime = formatDateForSafari(schedule.end_time)

      const startDate = new Date(formattedStartTime)
      startDate.setHours(0, 0, 0, 0)
      const startDateStr = startDate.toISOString().split("T")[0]

      const endDate = new Date(formattedEndTime)
      endDate.setHours(0, 0, 0, 0)
      const endDateStr = endDate.toISOString().split("T")[0]

      const currentDay = new Date(day.scheduled_date)
      currentDay.setHours(0, 0, 0, 0)
      const currentDayStr = currentDay.toISOString().split("T")[0]

      const timezoneOffset = currentDay.getTimezoneOffset() * 60000 // タイムゾーンのオフセット（ミリ秒）

      let startTimeByDate, endTimeByDate

      if (currentDayStr === startDateStr) {
        startTimeByDate = schedule.start_time
        // タイムゾーンのオフセットを加味して23:59:59を設定
        endTimeByDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          23,
          59,
          59,
          -timezoneOffset
        ).toISOString()
      } else if (currentDayStr === endDateStr) {
        startTimeByDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          0,
          0,
          0,
          -timezoneOffset
        ).toISOString()
        endTimeByDate = schedule.end_time
      } else {
        startTimeByDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          0,
          0,
          0,
          -timezoneOffset
        ).toISOString()
        endTimeByDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          23,
          59,
          59,
          -timezoneOffset
        ).toISOString()
      }

      return {
        ...schedule,
        start_time_by_date: startTimeByDate,
        end_time_by_date: endTimeByDate,
      }
    })

    return {
      ...day,
      status_schedules: newStatusSchedules,
    }
  })
}

// 時間を数字に変換
const extractHour = (dateTimeString: string) => {
  const hourString = dateTimeString.substring(11, 13)
  return parseInt(hourString, 10)
}

// 分を数字に変換
const extractMinute = (dateTimeString: string) => {
  const minuteString = dateTimeString.substring(14, 16)
  return parseInt(minuteString, 10)
}

// 休憩終了時間を時間と分の数字に変換
export const parseTimeString = (timeString: string) => {
  const [hours, minutes] = timeString.split(":").map(Number)
  return { hours, minutes }
}

// schedule_judgeは休憩時間を境にAM・PM・all_dayと判定
export const calculateScheduleJudge = (
  startTimeString: string | undefined,
  endTimeString: string | undefined,
  pmStartTime: number,
  pmStartMinute: number
) => {
  if (!startTimeString || !endTimeString) {
    console.error(
      "Invalid date string in calculateScheduleJudge",
      startTimeString,
      endTimeString
    )
    return "Invalid time"
  }

  // 時間と分の部分の抽出と数値への変換
  const startTimeHour = extractHour(startTimeString)
  const endTimeHour = extractHour(endTimeString)
  const startTimeMinute = extractMinute(startTimeString)
  const endTimeMinute = extractMinute(endTimeString)

  if (
    startTimeHour < pmStartTime ||
    (startTimeHour === pmStartTime && startTimeMinute < pmStartMinute)
  ) {
    if (
      endTimeHour > pmStartTime ||
      (endTimeHour === pmStartTime && endTimeMinute > pmStartMinute)
    ) {
      return "all_day"
    }
    return "AM"
  } else {
    return "PM"
  }
}

// 日付をまたいだ予約の場合に、status_schedulesのschedule_judgeを再計算
export const addNewScheduleJudgeToStatusSchedules = (
  mySchedulesTimeModified: ScheduleHomeParDate[],
  pmStartTime: number,
  pmStartMinute: number
): ScheduleHomeParDate[] => {
  return mySchedulesTimeModified.map((schedule) => {
    const updatedStatusSchedules = schedule.status_schedules.map(
      (statusSchedule) => {
        let scheduleJudge

        if (
          statusSchedule.multi_day_schedule_data &&
          statusSchedule.multi_day_schedule_data.length > 0
        ) {
          scheduleJudge = calculateScheduleJudge(
            statusSchedule.start_time_by_date,
            statusSchedule.end_time_by_date,
            pmStartTime,
            pmStartMinute
          )
        } else {
          // 複数日にまたがらない予約の場合、元の schedule_judge を使用
          scheduleJudge = statusSchedule.schedule_judge
        }
        // 各スケジュールに対して schedule_judge_new を設定
        return {
          ...statusSchedule,
          schedule_judge_redefined: scheduleJudge,
        }
      }
    )

    return {
      ...schedule,
      status_schedules: updatedStatusSchedules,
    }
  })
}

// サイネージに表示する時間の処理
export const describeTimeRange = (
  startTime: Date,
  endTime: Date,
  currentTime: Date
) => {
  const isSameDayStartEnd = isSameDay(startTime, endTime)
  const isSameDayStartCurrent = isSameDay(startTime, currentTime)
  const isSameDayEndCurrent = isSameDay(endTime, currentTime)

  if (isSameDayStartCurrent) {
    return `${dateTimeOnlyFormat(startTime)} - ${
      isSameDayStartEnd ? dateTimeOnlyFormat(endTime) : "翌日以降終了"
    }`
  }

  if (isSameDayEndCurrent) {
    return `前日以前開始 - ${dateTimeOnlyFormat(endTime)}`
  }

  if (currentTime > startTime && currentTime < endTime) {
    return "終日"
  } else {
    // それ以外の場合（例外ケース）
    return `${dateTimeOnlyFormat(startTime)} - ${dateTimeOnlyFormat(endTime)}`
  }
}
