import { Storage } from "aws-amplify"

import React, { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import {
  Button,
  Modal,
  Box,
  Typography,
  Grid,
  CardContent,
  Card,
  CircularProgress,
} from "@mui/material"
import Divider from "@mui/material/Divider"
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid"

import { api } from "../../../../../api/config/axiosConfig"
import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  MeetingRooms,
  MeetingRoom,
  ReservableType,
  ScheduleForm,
} from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import { CompanyRelationsType } from "../../../../../models/public/useCompanyRelations/type"
import enTranslations from "../../../../../translations/employeeSchedule/employeeScheduleReservation/en"
import jaTranslations from "../../../../../translations/employeeSchedule/employeeScheduleReservation/ja"
import { useOpen } from "../../../../../utils/isOpen"
import { Loading } from "../../../../public/Loading"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #707070",
  p: 4,
}

interface Props {
  isResetMeetingRoom: boolean
  branchId: number
  floorId: number
  companyRelations: CompanyRelationsType
  isWholeDay: boolean
}

export const MeetingRoomModal: React.FC<Props> = ({
  isResetMeetingRoom,
  branchId,
  floorId,
  companyRelations,
  isWholeDay,
}) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const [loading, setLoading] = useState<boolean>(false)
  const [blobLoading, setBlobLoading] = useState<boolean>(false)
  const { getValues, setValue } = useFormContext<ScheduleForm>()
  const { isOpen, open, close } = useOpen()
  const [meetingRooms, setMeetingRooms] = useState<MeetingRooms>([])
  const [selectedMeetingRoomIds, setSelectedMeetingRoomIds] =
    useState<GridSelectionModel>([])
  const branch = companyRelations.branches.find(
    (branch) => branch.id === branchId
  )
  const [meetingRoom, setMeetingRoom] = useState<MeetingRoom | undefined>(
    undefined
  )
  const [blob, setBlob] = useState<string | undefined>(undefined)

  const columns: GridColDef[] = [
    {
      field: "meeting_room_code",
      headerName: translations.SpaceNo,
      width: 130,
    },
    {
      field: "meeting_room_name",
      headerName: translations.spaceName,
      width: 350,
    },
    {
      field: "capacity",
      headerName: translations.CapacityNumber,
      type: "number",
      width: 90,
    },
  ]

  const fetchMeetingRoom = async () => {
    setLoading(true)
    const params = {
      branch_id: branchId,
      floor_id: floorId,
      start_date: getValues("schedule.start_date"),
      end_date: getValues("schedule.end_date"),
      start_time: getValues("schedule.start_time"),
      end_time: getValues("schedule.end_time"),
      count: getValues("schedule.share_schedule.who_is_wheres").length,
      recurrence:
        getValues("schedule.schedule_date_type") === 1
          ? getValues("schedule.weekly_or_monthly")
          : null,
      weekly:
        getValues("schedule.schedule_date_type") === 1 &&
        getValues("schedule.weekly_or_monthly") === "weekly"
          ? getValues("schedule.weekly")
          : null,
      monthly:
        getValues("schedule.schedule_date_type") === 1 &&
        getValues("schedule.weekly_or_monthly") === "monthly"
          ? getValues("schedule.monthly")
          : null,
    }

    const result = await api.get<MeetingRooms>(
      "/employee/relations/search_meeting_room",
      {
        params: params,
      }
    )
    let filteredMeetingRooms = result.data
    if (isWholeDay) {
      filteredMeetingRooms = filteredMeetingRooms.filter(
        (room) => room.available_time === 0
      )
    }

    setMeetingRooms(filteredMeetingRooms)
    setLoading(false)
  }

  useEffect(() => {
    if (branchId != 0 && floorId != 0) {
      fetchMeetingRoom()
    }
  }, [floorId])

  useEffect(() => {
    setMeetingRoom(
      meetingRooms.find(
        (meetingRoom) =>
          meetingRoom.id === (selectedMeetingRoomIds[0] as number)
      )
    )
  }, [selectedMeetingRoomIds])
  const downloadAreaOrMeetingRoomFile = async (
    img: string,
    companyId: number,
    branchId: number,
    floorId: number
  ) => {
    if (!img) return

    const result = await Storage.get(img, {
      level: "public",
      customPrefix: {
        public: `company${companyId}/layouts/branch${branchId}/floor${floorId}/images/`,
      },
    })

    return result
  }

  useEffect(() => {
    if (meetingRoom) {
      ;(async () => {
        setBlobLoading(true)
        const resultMeetingRoomImageUrl = await downloadAreaOrMeetingRoomFile(
          meetingRoom.meeting_room_image,
          companyRelations.id,
          branchId,
          floorId
        )
        setBlob(resultMeetingRoomImageUrl)
        setBlobLoading(false)
      })()
    }
  }, [meetingRoom])

  return (
    <>
      <Button
        sx={{
          textTransform: "none",
          fontFamily: "Noto Sans",
        }}
        variant="outlined"
        fullWidth
        disabled={!(floorId != 0 && branchId != 0)}
        onClick={() => {
          if (isResetMeetingRoom) {
            setMeetingRoom(undefined)
            setBlob(undefined)
          }
          fetchMeetingRoom()
          open()
        }}
      >
        {translations.Spaces}
      </Button>
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? (
          <Loading type="content" loading={loading} />
        ) : (
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="#707070"
              marginBottom={2}
            >
              {translations.Desks}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    marginBottom={2}
                  >
                    <Box display="flex" justifyContent="inherit">
                      <Typography marginRight={3}>
                        {translations.Location}:{" "}
                      </Typography>
                      <Typography>
                        {branch ? branch.branch_name : ""}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="inherit">
                      <Typography marginRight={3}>
                        {translations.Floor}:{" "}
                      </Typography>
                      <Typography>
                        {branch
                          ? branch.floors.find((floor) => floor.id === floorId)
                              ?.floor_name
                          : ""}
                      </Typography>
                    </Box>
                  </Box>

                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={meetingRooms}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      onSelectionModelChange={(selectedMeetingRoomIds) => {
                        setSelectedMeetingRoomIds(selectedMeetingRoomIds)
                      }}
                      localeText={{
                        footerRowSelected: (count) =>
                          count !== 1 ? (
                            <div>未選択</div>
                          ) : (
                            <div>{translations.Selected}</div>
                          ),
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      close()
                      const meetingRoom = meetingRooms.find(
                        (meetingRoom) =>
                          meetingRoom.id ===
                          (selectedMeetingRoomIds[0] as number)
                      )
                      const whoIsWheres = getValues(
                        "schedule.share_schedule.who_is_wheres"
                      ).map((whoIsWhere) => {
                        return {
                          userable: {
                            userable_id: whoIsWhere.userable.userable_id,
                            last_name: whoIsWhere.userable.last_name,
                            first_name: whoIsWhere.userable.first_name,
                            email: whoIsWhere.userable.email,
                            userable_type: whoIsWhere.userable.userable_type,
                            company_name: whoIsWhere.userable.company_name,
                            should_send_guest_reception_notification_mail:
                              whoIsWhere.userable
                                .should_send_guest_reception_notification_mail,
                          },
                          reservable: {
                            reservable_id: selectedMeetingRoomIds[0] as number,
                            name: meetingRoom
                              ? meetingRoom.meeting_room_name
                              : "",
                            reservable_type: "MeetingRoom" as ReservableType,
                          },
                        }
                      })
                      setValue(
                        "schedule.share_schedule.who_is_wheres",
                        whoIsWheres
                      )
                    }}
                  >
                    {translations.Enter}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ margin: "4rem 2rem 0 2rem" }}
                />
              </Grid>
              {selectedMeetingRoomIds.length === 1 &&
                meetingRoom &&
                (blobLoading ? (
                  <Grid xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                      <Box
                        sx={{
                          height: "10rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </Card>
                    <Box mt={2}></Box>
                  </Grid>
                ) : (
                  <Grid xs={3}>
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        {/* <span
                        style={{
                          top: "60px",
                          left: "60px",
                          color: meetingRoom.color_code,
                          position: "absolute",
                          fontSize: "30px",
                        }}
                      >
                        {meetingRoom.meeting_room_code}
                      </span> */}
                        <Box display="flex">
                          <Typography
                            sx={{
                              fontSize: "20px",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              border: "solid 5px #fff",
                              boxShadow: "0 0 2px #1d0204",
                              cursor: "pointer",
                              boxSizing: "border-box",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: meetingRoom.color_code,
                            }}
                          >
                            M
                          </Typography>
                          <Typography
                            style={{
                              top: "60px",
                              left: "60px",
                              color: meetingRoom.color_code,
                              fontSize: "20px",
                              lineHeight: "60px",
                            }}
                          >
                            {meetingRoom.meeting_room_code}
                          </Typography>
                          <Typography sx={{ fontSize: "1.5rem" }}>
                            {translations.MeetingSpace}
                          </Typography>
                        </Box>
                        {blob ? (
                          <Box>
                            <img src={blob} />
                          </Box>
                        ) : (
                          <Box></Box>
                        )}
                        <Box>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {translations.SpaceName}:{" "}
                            {meetingRoom.meeting_room_name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {translations.Capacity}: {meetingRoom.capacity}
                          </Typography>
                        </Box>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {translations.Equipment}:
                        </Typography>
                        {meetingRoom.appliances.length === 0 ? (
                          <Box></Box>
                        ) : (
                          meetingRoom.appliances.map((appliance, index) => (
                            <Typography
                              key={appliance.id}
                              component="span"
                              sx={{
                                marginBottom: 1.5,
                                marginRight: 1.5,
                              }}
                              color="text.secondary"
                            >
                              {appliance.appliance_name}
                              {meetingRooms.length !== index ? "," : ""}
                            </Typography>
                          ))
                        )}

                        <Box>
                          <Typography
                            sx={{ mb: 1.5, mt: 1.5 }}
                            color="text.secondary"
                          >
                            {translations.Description}:
                          </Typography>
                          <Typography
                            sx={{
                              mb: 1.5,
                              height: "60px",
                              overflowX: "auto",
                            }}
                            color="text.secondary"
                          >
                            {meetingRoom.meeting_room_description}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                    <Box mt={2}></Box>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
      </Modal>
    </>
  )
}
