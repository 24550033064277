import styled from "@emotion/styled"

import { Box } from "@mui/material"

export const EmployeeIconWrapper = styled(Box)({
  border: "1px solid #E3E3E3",
  borderRadius: "50%",
  height: "3rem",
  width: "3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

export const EmployeeIconSmallWrapper = styled(Box)({
  border: "1px solid #E3E3E3",
  borderRadius: "50%",
  height: "2rem",
  width: "2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

export const CompanyEmployeesIconWrapper = styled(Box)({
  border: "1px solid #E3E3E3",
  borderRadius: "50%",
  height: "1.875rem",
  width: "1.875rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})
