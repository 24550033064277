import React, { ReactElement } from "react"

import { Box } from "@mui/material"

interface Props {
  areaName: string
  title: ReactElement
  content: ReactElement
  leftIcon?: ReactElement
  rightIcon?: ReactElement
}

export const EmployeeHomeContent = ({
  areaName,
  title,
  content,
  leftIcon,
  rightIcon,
}: Props) => {
  return (
    <>
      <Box
        sx={{
          gridArea: areaName,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", padding: "15px 0" }}>
          {leftIcon}
          {title}
          {rightIcon}
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "15px",
            borderRadius: "10px",
            minHeight: "0",
          }}
        >
          {content}
        </Box>
      </Box>
    </>
  )
}
