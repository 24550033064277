import React, { createContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

import { SelectChangeEvent } from "@mui/material"

import { fetchEmployeeLayoutRequest } from "../api/employee/layoutRequest"
import { useLanguage } from "../contexts/LanguageContext"
import {
  EmployeeLayoutRequestType,
  EmployeeLayoutLocationType,
  LayoutType,
  MeetingRoomType,
  SeatObjectType,
  AreaType,
} from "../models/employee/useLayout/type"
import { useSearchSchedules } from "../models/employee/useSchedule/searchPlace"
import { useCompanyRelations } from "../models/public/useCompanyRelations"
import {
  Branch,
  CompanyRelationsType,
} from "../models/public/useCompanyRelations/type"
import enTranslations from "../translations/employeeStatus/employeeStatusHeader/en"
import jaTranslations from "../translations/employeeStatus/employeeStatusHeader/ja"
import { dateStatus } from "../utils/date"
import { downloadIcon } from "../utils/downLoadIcon"
import { downloadLayoutImage } from "../utils/downloadLayoutImage"
import { fifteenMinutesIntervalHours } from "../utils/hours"

type LayoutDataType = {
  locationState: EmployeeLayoutLocationType
  branchId: number
  floorId: number
  changeSelectValue: (e: SelectChangeEvent<number>) => void
  layoutImg: string
  layout: LayoutType
  seats: SeatObjectType[]
  meetingRooms: MeetingRoomType[]
  fetchLayout: (params: EmployeeLayoutRequestType, companyId: number) => void
  handleSeatHighLight: (seatId: number) => void
  filterSeatSchedules: (scheduled_date: string, start_time: string) => void
  handleEmployeePlaceSearch: (targetEmployeeId: number) => void
  filterMeetingRoomSchedules: (
    scheduled_date: string,
    start_time: string
  ) => void
  selectedSeat: SeatObjectType | undefined
  selectedMeetingRoom: MeetingRoomType | undefined
  setSelectedSeat: React.Dispatch<
    React.SetStateAction<SeatObjectType | undefined>
  >
  setSelectedMeetingRoom: React.Dispatch<
    React.SetStateAction<MeetingRoomType | undefined>
  >
  handleFilterWholeDaySchedule: (
    scheduledDate: string,
    wholeDayFlag: boolean,
    startTime?: string
  ) => void
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  previousDate: () => void
  nextDate: () => void
  previousWeekday: () => void
  nextWeekday: () => void
  weekdayNumber: number
  setWeekdayNumber: React.Dispatch<React.SetStateAction<number>>
  dayOfWeek: string[]
  startDate: Date
  endDate: Date
  formatDate: (date: Date) => string
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  timeIndex: number
  setTimeIndex: React.Dispatch<React.SetStateAction<number>>
  userNotExists: boolean
  userNotExistsMessage: string
  setUserNotExists: React.Dispatch<React.SetStateAction<boolean>>
  wholeDayFlag: boolean
  setWholeDayFlag: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  handleFilterSchedule: (wholeDayFlag: boolean) => void
  areas: AreaType[]
  companyRelations: CompanyRelationsType
  selectedBranch: Branch
}
interface Props {
  children?: React.ReactNode
}

export const LayoutContext = createContext<LayoutDataType>({} as LayoutDataType)

const layoutData: LayoutType = {
  x: 0,
  y: 0,
  scale: 0,
}

export const LayoutProvider: React.FC<Props> = ({ children }: Props) => {
  const companyRelationObject = useCompanyRelations()
  const companyRelations = companyRelationObject.companyRelations
  const locationState = useLocation().state as EmployeeLayoutLocationType
  const [branchId, setBranchId] = useState<number>(0)
  const [floorId, setFloorId] = useState<number>(0)

  const [wholeDayFlag, setWholeDayFlag] = useState<boolean>(
    locationState ? locationState?.wholeDayFlag : false
  )

  const [layoutImg, setLayoutImg] = useState<string>("")
  const [layout, setLayout] = useState<LayoutType>(layoutData)
  const [seats, setSeats] = useState<SeatObjectType[]>([])
  const [meetingRooms, setMeetingRooms] = useState<MeetingRoomType[]>([])
  const [areas, setAreas] = useState<AreaType[]>([])
  const [selectedSeat, setSelectedSeat] = useState<SeatObjectType | undefined>(
    undefined
  )
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState<
    MeetingRoomType | undefined
  >(undefined)

  const { searchSchedule } = useSearchSchedules()

  const [userNotExists, setUserNotExists] = useState<boolean>(false)
  const [userNotExistsMessage, setUserNotExistsMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const {
    date,
    setDate,
    previousDate,
    nextDate,
    previousWeekday,
    nextWeekday,
    weekdayNumber,
    setWeekdayNumber,
    dayOfWeek,
    startDate,
    endDate,
    formatDate,
    setStartDate,
    setEndDate,
  } = dateStatus()

  const [timeIndex, setTimeIndex] = useState<number>(
    locationState ? locationState?.timeIndex : 0
  )
  const time = fifteenMinutesIntervalHours[timeIndex]

  const findBranch = (branchId: number) => {
    return companyRelations.branches.filter(
      (branch) => branch.id === branchId
    )[0]
  }

  const handleLayoutChange = async (params: EmployeeLayoutRequestType) => {
    await fetchLayout(params, companyRelations.id)
  }

  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const selectedBranch = findBranch(branchId)

  // モード切り替えで遷移した場合はlocationStateから、それ以外の手段でアクセスした場合は主拠点とする
  useEffect(() => {
    if (companyRelationObject.branchId > 0) {
      setBranchId(
        locationState && locationState.branchId
          ? locationState.branchId
          : companyRelationObject.branchId
      )
    }
  }, [companyRelationObject.branchId])

  useEffect(() => {
    if (companyRelationObject.floorId > 0) {
      // レイアウト予約→席状況と遷移した場合locationStateからfloorIdを引き継ぐ
      // 状況→席状況と遷移した場合、フロアのプルダウンが存在しないためlocationState.floorIdが0(フロアが未選択)になる。
      setFloorId(
        locationState && locationState.floorId
          ? locationState.floorId
          : companyRelationObject.floorId
      )
    }
  }, [companyRelationObject.floorId])

  useEffect(() => {
    setWeekdayNumber(date.getDay())
  }, [date])

  // 終日のチェックボックスを外した時の挙動
  useEffect(() => {
    if (wholeDayFlag) {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag)
    } else {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag, time) // falseの場合は日付と時刻をベースに予定をfilterし直す
    }
  }, [wholeDayFlag])

  // 最新のレイアウト情報の取得
  const fetchLayout = async (
    params: EmployeeLayoutRequestType,
    companyId: number
  ) => {
    setLoading(true)
    try {
      const response = await fetchEmployeeLayoutRequest({
        branch_id: params.branch_id,
        floor_id: params.floor_id,
        start_date: params.start_date,
        end_date: params.end_date,
      })

      if (response.data && response.status === 200) {
        await Promise.all(
          response.data.seats.map(async (oldSeat) => {
            await Promise.all(
              oldSeat.schedule_par_date_list.map(async (schedule_par_date) => {
                await Promise.all(
                  schedule_par_date.schedules.map(async (schedule) => {
                    if (schedule.userable.icon) {
                      const resultImage = await downloadIcon(
                        companyId,
                        schedule.userable.id,
                        schedule.userable.icon
                      )
                      schedule.image_blob = resultImage ? resultImage : ""
                    }
                  })
                )
              })
            )
          })
        )

        await Promise.all(
          response.data.meeting_rooms.map(async (oldMeetingRoom) => {
            await Promise.all(
              oldMeetingRoom.schedule_par_date_list.map(
                async (schedule_par_date) => {
                  await Promise.all(
                    schedule_par_date.schedules.map(async (schedule) => {
                      if (schedule.userable.icon) {
                        const resultImage = await downloadIcon(
                          companyId,
                          schedule.userable.id,
                          schedule.userable.icon
                        )
                        schedule.image_blob = resultImage ? resultImage : ""
                      }
                    })
                  )
                }
              )
            )
          })
        )

        setSeats(response.data.seats)
        setMeetingRooms(response.data.meeting_rooms)
        setAreas(response.data.areas)

        if (response.data.floor.layout_image) {
          const result = await downloadLayoutImage(
            companyId,
            params.branch_id,
            params.floor_id,
            response.data.floor.layout_image
          )
          if (result) {
            setLayoutImg(URL.createObjectURL(result.Body as Blob))
            setLayout({
              x: response.data.x,
              y: response.data.y,
              scale: response.data.scale,
            })
          }
        } else {
          setLayoutImg("")
          setLayout(layoutData)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 座席の予定を指定した日時でfilterする
  const filterSeatSchedules = (scheduled_date: string, start_time: string) => {
    const newSeatArray = seats
      .map((seat) => {
        const scheduleFilteredByDate = seat.schedule_par_date_list.filter(
          (schedule_par_date) => {
            return schedule_par_date.scheduled_date === scheduled_date
          }
        )[0]

        if (!scheduleFilteredByDate?.schedules) return null

        const scheduleFilteredByTime = scheduleFilteredByDate?.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduled_date.replace(/-/g, "/")} ${start_time}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )[0]

        return { ...seat, schedule: scheduleFilteredByTime } // scheduleプロパティに設定した予定がレイアウト上に反映される
      })
      .flatMap((e) => e ?? [])
    setSeats(newSeatArray)
  }

  // 会議室の予定を日時でfilterする
  const filterMeetingRoomSchedules = (
    scheduled_date: string,
    start_time: string
  ) => {
    const newMeetingRoomArray = meetingRooms
      .map((meetingRoom) => {
        const scheduleFilteredByDate =
          meetingRoom.schedule_par_date_list.filter((schedule_par_date) => {
            return schedule_par_date.scheduled_date === scheduled_date
          })[0]

        if (!scheduleFilteredByDate?.schedules) return null

        const schedulesFilteredByTime = scheduleFilteredByDate.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduled_date.replace(/-/g, "/")} ${start_time}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )

        return { ...meetingRoom, schedules: schedulesFilteredByTime } // schedulesプロパティに設定した予定がレイアウト上に反映される
      })
      .flatMap((e) => e ?? [])

    setMeetingRooms(newMeetingRoomArray)
  }

  // 終日の予定だけをfilterする
  const handleFilterWholeDaySchedule = (
    scheduledDate: string,
    wholeDayFlag: boolean,
    startTime?: string
  ) => {
    const newMeetingRoomArray = meetingRooms.map((meetingRoom) => {
      const scheduleFilteredByDate = meetingRoom.schedule_par_date_list.filter(
        (schedule_par_date) => {
          return schedule_par_date.scheduled_date === scheduledDate
        }
      )[0]

      let filteredSchedules = scheduleFilteredByDate?.schedules

      // wholeDayFlagがfalseの場合は日時でfilterし直す
      if (!wholeDayFlag) {
        filteredSchedules = scheduleFilteredByDate?.schedules.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduledDate.replace(/-/g, "/")} ${startTime}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )
      }

      return { ...meetingRoom, schedules: filteredSchedules }
    })

    const newSeatArray = seats.map((seat) => {
      const scheduleFilteredByDate = seat.schedule_par_date_list.filter(
        (schedule_par_date) => {
          return schedule_par_date.scheduled_date === scheduledDate
        }
      )[0]

      let filteredSchedule = scheduleFilteredByDate?.schedules[0]

      if (!wholeDayFlag) {
        filteredSchedule = scheduleFilteredByDate?.schedules?.filter(
          (schedule) => {
            const targetStartTime = new Date(
              `${scheduledDate.replace(/-/g, "/")} ${startTime}`
            ).getTime()
            const startTimeToString = schedule.start_time
              .toLocaleString()
              .replace(/-/g, "/")
            const endTimeToString = schedule.end_time
              .toLocaleString()
              .replace(/-/g, "/")
            const startTimeNumber = new Date(startTimeToString).getTime()
            const endTimeNumber = new Date(endTimeToString).getTime()
            return (
              startTimeNumber <= targetStartTime &&
              endTimeNumber > targetStartTime
            )
          }
        )[0]
      }

      return { ...seat, schedule: filteredSchedule }
    })

    setSeats(newSeatArray)
    setMeetingRooms(newMeetingRoomArray)
  }

  // 日付の切り替えやフロアの切り替えをした時にscheduleを選択済みの日時でfilterする
  const handleFilterSchedule = (wholeDayFlag: boolean) => {
    if (wholeDayFlag) {
      handleFilterWholeDaySchedule(formatDate(date), wholeDayFlag) // 終日のチェックを入れたまま日付を切り替えた場合、終日の予定だけ取得する
    } else {
      // 終日のチェックを外した状態で日付を切り替えた場合、日付と時刻をベースに予定をfilterする
      filterSeatSchedules(formatDate(date), time)
      filterMeetingRoomSchedules(formatDate(date), time)
    }
  }

  // 拠点とフロアの切り替え
  const changeSelectValue = (e: SelectChangeEvent<number>) => {
    switch (e.target.name) {
      case "branch_id":
        setFloorId(0)
        return setBranchId(Number(e.target.value))
      case "floor_id":
        return setFloorId(Number(e.target.value))
    }
  }

  // 座席のハイライト表示
  const handleSeatHighLight = (seatId: number) => {
    // ハイライトされている座席が1件でもある場合は処理を中断
    const highLightedSeats = seats.filter((seat) => {
      return seat.is_highlight
    })

    if (highLightedSeats.length > 0) {
      clearTimeout()
    }

    // 指定された座席をハイライト表示する
    const newSeatArray = seats.map((seat) => {
      if (seat.id === seatId) {
        return { ...seat, is_highlight: true }
      } else {
        return seat
      }
    })

    setSeats(newSeatArray)

    // 3秒後にハイライトをリセットする
    const resetSeatsArray = seats.map((seat) => {
      return { ...seat, is_highlight: false }
    })

    setTimeout(() => setSeats(resetSeatsArray), 1500)
  }

  // 会議室のハイライト表示
  const handleMeetingRoomHighLight = (meetingRoomId: number) => {
    // ハイライトされている座席が1件でもある場合は処理を中断
    const highLightedMeetingRooms = meetingRooms.filter((meetingRoom) => {
      return meetingRoom.is_highlight
    })

    if (highLightedMeetingRooms.length > 0) {
      clearTimeout()
    }

    // 指定された座席をハイライト表示する
    const newMeetingRoomArray = meetingRooms.map((meetingRoom) => {
      if (meetingRoom.id === meetingRoomId) {
        return { ...meetingRoom, is_highlight: true }
      } else {
        return meetingRoom
      }
    })

    setMeetingRooms(newMeetingRoomArray)

    // 3秒後にハイライトをリセットする
    const resetMeetingRoomsArray = meetingRooms.map((meetingRoom) => {
      return { ...meetingRoom, is_highlight: false }
    })

    setTimeout(() => setMeetingRooms(resetMeetingRoomsArray), 1500)
  }

  // ステータスタイトル言語切り替え
  type ScheduleStatuses = {
    [key: string]: string
  }

  type Translations = {
    schedule_statuses: ScheduleStatuses
  }

  const getLocalizedStatusText = (status: string) => {
    const translations: Translations =
      language === "en" ? enTranslations : jaTranslations
    return (
      translations.schedule_statuses[status] ||
      translations.schedule_statuses[5]
    )
  }

  // 検索対象者がどこにいるのか調べる
  const handleEmployeePlaceSearch = async (targetEmployeeId: number) => {
    const targetSeat = seats.filter((seat) => {
      return seat.schedule?.userable.id === targetEmployeeId
    })[0]

    const targetMeetingRoom = meetingRooms.filter((meetingRoom) => {
      return meetingRoom.schedules?.some((schedule) => {
        return schedule.userable.id === targetEmployeeId
      })
    })[0]

    if (targetSeat) {
      handleSeatHighLight(targetSeat.id as number)
    } else if (targetMeetingRoom) {
      handleMeetingRoomHighLight(targetMeetingRoom.id as number)
    } else {
      const targetTime = `${formatDate(date)} ${
        fifteenMinutesIntervalHours[timeIndex]
      }`

      const schedule = await searchSchedule({
        employee_id: targetEmployeeId,
        schedule_time: targetTime,
        whole_day_flag: wholeDayFlag,
      })

      let message = ""
      setUserNotExists(true)
      if (floorId > 0) {
        message += `${translations.UserExist}\n`
        if (schedule) {
          if (schedule?.reservable == null) {
            message += `${translations.thisUser} ${getLocalizedStatusText(
              schedule?.schedule_title
            )} ${translations.is}`
          } else {
            message += `${schedule?.reservable.layout.floor.branch.branch_name} ${schedule?.reservable.layout.floor.floor_name} 座席番号：${schedule?.reservable.seat_name}にいます。`
          }
        } else {
          message += translations.NotDesk
        }
      } else {
        message += "拠点とフロアを選択してください。"
      }
      setUserNotExistsMessage(message)
    }
  }

  return (
    <LayoutContext.Provider
      value={{
        locationState,
        branchId,
        floorId,
        changeSelectValue,
        layoutImg,
        layout,
        seats,
        meetingRooms,
        fetchLayout,
        handleSeatHighLight,
        filterSeatSchedules,
        handleEmployeePlaceSearch,
        filterMeetingRoomSchedules,
        selectedSeat,
        selectedMeetingRoom,
        setSelectedSeat,
        setSelectedMeetingRoom,
        handleFilterWholeDaySchedule,
        date,
        setDate,
        previousDate,
        nextDate,
        previousWeekday,
        nextWeekday,
        weekdayNumber,
        setWeekdayNumber,
        dayOfWeek,
        startDate,
        endDate,
        formatDate,
        setStartDate,
        setEndDate,
        timeIndex,
        setTimeIndex,
        userNotExists,
        userNotExistsMessage,
        setUserNotExists,
        wholeDayFlag,
        setWholeDayFlag,
        loading,
        handleFilterSchedule,
        areas,
        companyRelations,
        selectedBranch,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
