import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  akerunFetchOrganizationRequest,
  updateAkerunOrganizationRequest,
  updateAkerunRequest,
} from "../../../api/company/akerunRequest"
import { useIcon } from "../../public/useIcon"
import { AkerunFomeType, AkerunOrganizationResponseType } from "./type"

const AkerunData: AkerunFomeType = {
  client_id: "",
  client_secret: "",
}

const AkeruResponsenData: AkerunOrganizationResponseType[] = [
  {
    id: "",
    name: "",
    branch_id: 0,
    branch_name: "",
  },
]

export const useAkerun = () => {
  const { fetchFile } = useIcon()
  const [akerun] = useState<AkerunFomeType>(AkerunData)
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const [organizations, setOrganizations] =
    useState<AkerunOrganizationResponseType[]>(AkeruResponsenData)

  const basicSchema = Yup.object().shape({
    client_id: Yup.string().required("必須になります"),
    client_secret: Yup.string().required("必須になります"),
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      client_id: akerun.client_id,
      client_secret: akerun.client_secret,
    },
    resolver: yupResolver(basicSchema),
  })

  // Akerun認証情報登録
  const onUpdateSubmit: SubmitHandler<AkerunFomeType> = useCallback(
    async (data) => {
      try {
        const { error } = await updateAkerunRequest({ ...data })
        if (!error) {
          // DB登録に成功したらAkerunログイン画面に遷移させる
          location.href =
            "https://api.akerun.com/oauth/authorize/?client_id=" +
            data.client_id +
            "&redirect_uri=" +
            process.env.REACT_APP_API_HOST +
            "/auth/akerun/callback&response_type=code"
        } else {
          setErrorMessageOpen(true)
          setErrorMessages(error)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  // 事業所名取得
  const fetchAkerunOrganization = async () => {
    setLoading(true)
    try {
      const response = await akerunFetchOrganizationRequest()
      if (response.status === 200 && response.data) {
        setOrganizations(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // 事業所と拠点の紐付け登録処理
  const onUpdateOrganizationSubmit = useCallback(
    async (data: AkerunOrganizationResponseType[]) => {
      try {
        const { error } = await updateAkerunOrganizationRequest({ ...data })
        if (!error) {
          // DB登録に成功したらAkerunログイン画面に遷移させる
        } else {
          setErrorMessageOpen(true)
          setErrorMessages(error)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    control,
    akerun,
    errors,
    isValid,
    handleSubmit,
    getValues,
    setValue,
    watch,
    errorMessages,
    setErrorMessages,
    fetchFile,
    loading,
    errorMessageOpen,
    setErrorMessageOpen,
    onUpdateSubmit,
    forbidden,
    fetchAkerunOrganization,
    organizations,
    setOrganizations,
    onUpdateOrganizationSubmit,
  }
}
