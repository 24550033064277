import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  fetchContractPlanRequest,
  updateContractAccountCountRequest,
  updateContractPlanRequest,
} from "../../../api/company/contractPlansRequest"
import { Contract } from "../useContract/type"
import { Plan } from "../usePlan/type"
import { ContractPlan } from "./type"

const planDate: Plan = {
  id: 0,
  plan_name: "",
  plan_type: "",
  account_amount: 0,
  available_status: "",
}

const contractData: Contract = {
  id: 0,
  start_date: new Date(),
  end_date: new Date(),
  next_charge_date: new Date(),
  payment_methods: "",
  contract_number: "",
  contract_description: "",
  contract_status: "",
  plan_name: "",
  account_count: 0,
  created_at: new Date(),
}

const contractPlanData: ContractPlan = {
  withdrawal_application_date: null,
  payment_methods: "",
  account_count: 0,
  plan: planDate,
  active_contract: contractData,
}

export const useContractPlan = () => {
  const navigate = useNavigate()
  const [contractPlan, setContractPlan] =
    useState<ContractPlan>(contractPlanData)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const basicSchema = Yup.object().shape({
    plan_id: Yup.number()
      .required("必須になります")
      .min(2, "有料プランのみ変更可能です"),
    account_count: Yup.number()
      .required("必須になります")
      .typeError("数値を入力してください")
      .min(1, "1以上を入力してください"),
  })

  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      plan_id: 0,
      account_count: 0,
    },
  })

  const fetchContractPlan = async () => {
    try {
      const response = await fetchContractPlanRequest()
      if (response.data && response.status === 200) {
        setContractPlan(response.data)
        form.reset({
          plan_id: response.data.plan.id,
          account_count: response.data.account_count,
        })
      }
    } catch (e) {
      console.log("error")
    }
  }

  const updateContractPlan = form.handleSubmit(async (data) => {
    if (contractPlan.plan.id === data.plan_id) {
      return setErrorMessages(["現在のプラン以外を選択してください"])
    }
    try {
      const response = await updateContractPlanRequest({ ...data })
      if (response.status === 204) {
        localStorage.setItem("alertContent", "プラン変更の申し込みを行いました")
        navigate(`/company/settings/payment`)
        window.location.reload()
      } else if (response.error) {
        setErrorMessages(response.error)
      }
    } catch (e) {
      console.log("error")
      setErrorMessages(["入力に誤りがあります。"])
    }
  })

  const updateAccountCount = async (account_count: number) => {
    if (
      contractPlan.plan.plan_type === "year" &&
      contractPlan.active_contract.account_count > account_count
    ) {
      return setErrorMessages(["現在のID数以上を設定してください"])
    }
    try {
      const response = await updateContractAccountCountRequest({
        plan_id: contractPlan.plan.id,
        account_count: account_count,
      })

      if (response.status === 204) {
        localStorage.setItem("alertContent", "登録ID数を更新しました")
        navigate(`/company/settings/payment`)
        window.location.reload()
      } else if (response.error) {
        setErrorMessages(response.error)
      }
    } catch (e) {
      console.log("error")
      setErrorMessages(["入力に誤りがあります。"])
    }
  }

  return {
    form,
    contractPlan,
    fetchContractPlan,
    updateContractPlan,
    errorMessages,
    updateAccountCount,
    setErrorMessages,
  }
}
