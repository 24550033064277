import React, { useMemo, useState } from "react"

import { Box, Grid, Tooltip, Typography } from "@mui/material"

import { useLanguage } from "../../../../../contexts/LanguageContext"
import {
  MeetingRoom,
  Schedules,
} from "../../../../../models/employee/useSchedule/useLayout/type"
import { WhoIsWheres } from "../../../../../models/employee/useSchedule/useScheduleForm/type"
import {
  meetingRoomStyle,
  meetingRoomIconStyle,
} from "../../../../../styles/meetingStyle"
import {
  reservedEmployeesStyle,
  checkInReservedEmployeesStyle,
} from "../../../../../styles/seatStyle"
import enTranslations from "../../../../../translations/errorMessage/en"
import jaTranslations from "../../../../../translations/errorMessage/ja"
import { formatDateForSafari } from "../../../../../utils/dateTimeFormat"
import { formatTime } from "../../../../../utils/time"

interface Props {
  meetingRoom: MeetingRoom
  whoIsWheres: WhoIsWheres
  setWhoIsWheres: React.Dispatch<React.SetStateAction<WhoIsWheres>>
  startTime: Date
  endTime: Date
}

/*
  レイアウト画像にア会議室のアイコンが表示される
*/
export const EmployeeCalendarLayoutMeetingRoom: React.FC<Props> = ({
  meetingRoom,
  whoIsWheres,
  setWhoIsWheres,
  startTime,
  endTime,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  // eslint-disable-next-line
  const [meetingRoomTarget, setMeetingRoomTarget] =
    useState<HTMLElement | null>()

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  useMemo(() => {
    setMeetingRoomTarget(document.getElementById(`M${String(meetingRoom.id)}`))
  }, [meetingRoom.id])

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  const tooltipText = (schedules: Schedules) => {
    const schedule = schedules[0]
    let employeesName = ""
    schedules.map((schedule) => {
      switch (schedule.userable_type) {
        case "Employee":
          employeesName += `${schedule?.userable.last_name} ${schedule?.userable.first_name}, `
          break
        case "Guest":
          employeesName += `${schedule?.userable.last_name} ${schedule?.userable.first_name}(ゲスト), `
      }
    })
    const scheduleTitle = schedule?.schedule_title
    const scheduleTime = schedule?.whole_day_flag
      ? translations.allDay
      : `${formatTime(
          formatDateForSafari(schedule?.start_time.toString())
        )} ~ ${formatTime(formatDateForSafari(schedule?.end_time.toString()))}`

    const seatInfo = meetingRoom.meeting_room_code
    return `会議室番号: ${seatInfo}\n${translations.Name}: ${employeesName}\n${translations.Subject}: ${scheduleTitle}\n${scheduleTime}`
  }

  const canReserveMeetingRoom = (
    targetEmployeeId: number | undefined
  ): boolean => {
    const isSchedulesEmpty =
      !meetingRoom.schedules || meetingRoom.schedules.length === 0
    if (targetEmployeeId && meetingRoom.reservable_employee_ids.length > 0) {
      return (
        isSchedulesEmpty &&
        meetingRoom.reservable_employee_ids.includes(targetEmployeeId)
      )
    } else {
      return isSchedulesEmpty
    }
  }

  const onClickIcon = () => {
    const isSameMeetingRoomSelected = whoIsWheres.some(
      (whoIsWhere) => whoIsWhere.reservable.reservable_id === meetingRoom.id
    )

    if (isSameMeetingRoomSelected) {
      const resetWhoIsWheres = whoIsWheres.map((whoIsWhere) => ({
        ...whoIsWhere,
        reservable: {
          reservable_id: undefined,
          reservable_type: "MeetingRoom",
          name: "",
        },
      }))

      setWhoIsWheres(resetWhoIsWheres as WhoIsWheres)
      return
    }

    const isMeetingRoomValid = whoIsWheres.every((item) =>
      canReserveMeetingRoom(item.userable.userable_id)
    )
    const durationInMinutes = Math.floor(
      (endTime.getTime() - startTime.getTime()) / 60000
    )

    if (!isMeetingRoomValid) {
      alert("選択された会議室は予約できません")
      return
    }
    if (whoIsWheres.length > meetingRoom.capacity) {
      alert("選択された会議室の定員を超えています")
      return
    }
    if (
      !(
        meetingRoom.available_time === 0 ||
        meetingRoom.available_time >= durationInMinutes
      )
    ) {
      alert("選択された会議室の利用可能時間を超えています")
      return
    }

    // 予約処理
    const updatedWhoIsWheres = whoIsWheres.map((whoIsWhere) => ({
      ...whoIsWhere,
      reservable: {
        reservable_id: meetingRoom.id,
        reservable_type: "MeetingRoom",
        name: meetingRoom.meeting_room_name,
      },
    }))

    setWhoIsWheres(updatedWhoIsWheres as WhoIsWheres)
  }

  return (
    <>
      <Box
        id={`M${String(meetingRoom.id)}`}
        className={"meetingRoom"}
        sx={meetingRoomStyle(
          meetingRoom.start_x,
          meetingRoom.start_y,
          meetingRoom.end_x,
          meetingRoom.end_y,
          meetingRoom.color_code
        )}
        onClick={onClickIcon}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <span
          style={
            meetingRoomIconStyle(meetingRoom.color_code) as React.CSSProperties
          }
        >
          M
        </span>
        <span
          style={{
            top: "60px",
            left: "60px",
            color: meetingRoom.color_code,
            position: "absolute",
            fontSize: "30px",
          }}
        >
          {meetingRoom.meeting_room_code}
        </span>
        {meetingRoom.schedules.length != 0 && (
          <Tooltip
            title={
              meetingRoom.schedules.length > 0
                ? tooltipText(meetingRoom.schedules)
                : ""
            }
            placement="bottom"
            disableInteractive
            open={meetingRoom.schedules.length > 0 && tooltipOpen}
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: "pre-wrap",
                  position: "fixed",
                  width: "200px",
                },
              },
            }}
          >
            <Grid
              container
              style={{
                bottom: "10px",
                left: "40px",
                position: "absolute",
                display: "flex",
                alignItems: "center",
              }}
            >
              {meetingRoom.schedules.map((schedule, index) => {
                return (
                  <Grid item key={index} sm={4}>
                    <Box
                      sx={
                        schedule.checked_in
                          ? (checkInReservedEmployeesStyle as React.CSSProperties)
                          : (reservedEmployeesStyle as React.CSSProperties)
                      }
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {schedule.userable.image_blob ? (
                        <img
                          src={schedule.userable.image_blob}
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Typography
                          sx={{ fontSize: "20px" }}
                          color="inherit"
                          display="flex"
                        >
                          <span>{schedule.userable.last_name[0]}</span>
                          <span>{schedule.userable.first_name?.[0]}</span>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Tooltip>
        )}
      </Box>
    </>
  )
}
