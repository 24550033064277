import React, { useContext } from "react"

import { Box, Typography, styled } from "@mui/material"

import { ScheduleType } from "../../../../../models/employee/useSchedule/days/type"
import {
  FetchScheduleShowFunction,
  Loading,
  ScheduleInformations,
} from "../../../../../models/employee/useSchedule/show/type"
import { AuthContext } from "../../../../../providers/AuthProvider"
import { formatDateForSafari } from "../../../../../utils/dateTimeFormat"
import {
  roundDownMinutesDate,
  differenceFromStartTimeToEndTime,
  findHourIndex,
  scheduleBarWidth,
  scheduleBarLeftPosition,
  processMultiDaySchedule,
  isSameDay,
  isEndTimeAtMidnight,
} from "../../../../../utils/schedules"

const defaultWidth = 130
const startPosition = 15

interface ColorChoice {
  bgColor: string
  fontColor: string
  border: string
  borderColor: string
}

const colorChoice = (accept_status: boolean): ColorChoice => {
  let bgColor
  let fontColor
  let border
  let borderColor
  if (accept_status === true) {
    bgColor = "#f0deca"
    fontColor = "#D0944F"
    border = "none"
    borderColor = "none"
    return { bgColor, fontColor, border, borderColor }
  } else {
    bgColor = "#FFFFFF"
    fontColor = "#D0944F"
    border = "solid"
    borderColor = "#f0deca"
    return { bgColor, fontColor, border, borderColor }
  }
}

const CustomScheduleBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  color: "#4EC7B0",
  height: theme.spacing(4.5),
  borderRadius: "5px",
  backgroundColor: "#DBF4EF",
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(0.75),
  zIndex: "1001",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
}))

const DuplicatedFlagBox = styled(Box)({
  display: "inline-block",
  borderRadius: "50%",
  borderColor: "rgb(0 0 0 / 25%)",
  border: "solid 1px rgb(0 0 0 / 25%)",
  textAlign: "center",
  height: "30px",
  width: "30px",
  boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
  color: "rgb(0 0 0 / 25%)",
  margin: "auto",
})

interface Props {
  employee_id: number
  schedule_date: string
  schedule: ScheduleType
  displayReserved: boolean
  displayNonReserved: boolean
  open: () => void
  fetchScheduleShowData: FetchScheduleShowFunction
  setScheduleShowData: React.Dispatch<
    React.SetStateAction<Loading<ScheduleInformations>>
  >
}

export const EmployeeEventScheduleDay: React.FC<Props> = ({
  employee_id,
  schedule_date,
  schedule,
  displayReserved,
  displayNonReserved,
  open,
  fetchScheduleShowData,
}: Props) => {
  const { currentUser } = useContext(AuthContext)

  // 取得した予定がログインユーザーの参加予定かどうか判定
  const isMySchedule = schedule.schedule_information
    ? schedule.schedule_information.share_schedule_userables?.some(
        (u) => u.email === currentUser?.attributes.email
      )
    : schedule.organizer // 外部連携データかどうか判定
    ? schedule.organizer.email === currentUser?.attributes.email ||
      schedule.organizer.emailAddress?.address === currentUser?.attributes.email
    : false

  const { bgColor, fontColor, border, borderColor } = colorChoice(
    schedule.accept_status
  )

  const fetchShowData = async (
    scheduleInformationIds: number[],
    scheduleIds: number[],
    schedules: ScheduleType[]
  ) => {
    await fetchScheduleShowData(
      scheduleInformationIds,
      new Date(schedule_date),
      scheduleIds,
      schedules,
      employee_id
    )
  }
  const handleScheduleShowDataClick = () => {
    open()
    fetchShowData(
      [
        schedule.schedule_information
          ? schedule.schedule_information.id
          : schedule.id,
      ],
      [schedule.id],
      [schedule]
    )
  }

  const handleDuplicatedScheduleShowDataClick = () => {
    open()
    const scheduleInformationIds: number[] = [
      schedule.schedule_information
        ? schedule.schedule_information.id
        : schedule.id,
    ]
    const scheduleIds: number[] = [schedule.id]
    const schedules: ScheduleType[] = [schedule]
    schedule.duplicated_schedules.forEach((schedule) => {
      scheduleInformationIds.push(
        schedule.schedule_information
          ? schedule.schedule_information.id
          : schedule.id
      )
      scheduleIds.push(schedule.id)
      schedules.push(schedule)
    })
    fetchShowData(scheduleInformationIds, scheduleIds, schedules)
  }

  // 複数日にまたがる予定の処理
  const processedSchedules = processMultiDaySchedule(schedule)
  const currentPageDate = new Date(schedule_date)

  return (
    <>
      {processedSchedules.map((processedSchedule) => {
        // Date オブジェクトを ISO 文字列に変換
        const startTimeString = processedSchedule.startTime.toISOString()
        const endTimeString = processedSchedule.endTime.toISOString()

        // formatDateForSafari 関数には文字列を渡す
        const startTime = new Date(formatDateForSafari(startTimeString))
        const endTime = new Date(formatDateForSafari(endTimeString))

        // scheduleDate と同じ日のスケジュールのみを表示
        if (!isSameDay(startTime, currentPageDate)) {
          return null
        }

        // 日を跨ぐ予定の場合、最終日以外は23:59:59をendtimeとしているので、roundDownMinutesDateを適用しない
        const diffHours = differenceFromStartTimeToEndTime(
          roundDownMinutesDate(startTime).getTime(),
          isEndTimeAtMidnight(endTime)
            ? endTime.getTime()
            : roundDownMinutesDate(endTime).getTime()
        )
        const hourIndex = findHourIndex(startTime)
        const width = scheduleBarWidth(diffHours, defaultWidth)
        const left = scheduleBarLeftPosition(
          roundDownMinutesDate(startTime).getMinutes(),
          hourIndex,
          startPosition,
          defaultWidth
        )

        return (
          <>
            {!displayNonReserved &&
              schedule.reservable_type === "MeetingRoom" &&
              schedule.duplicated_flag > 0 && (
                <CustomScheduleBox
                  sx={{
                    width: `${width}px`,
                    left: `${left}px`,
                    backgroundColor: "#DBF4EF",
                    color: "#4EC7B0",
                    paddingLeft: "1rem",
                  }}
                  onClick={handleDuplicatedScheduleShowDataClick}
                >
                  <Box sx={{ overflow: "hidden", whiteSpace: "normal" }}>
                    <Box>
                      <Typography>
                        {!isMySchedule && schedule.is_secret
                          ? "非公開"
                          : schedule.schedule_title}
                      </Typography>
                    </Box>
                  </Box>
                  <DuplicatedFlagBox>
                    <Typography>{schedule.duplicated_flag}</Typography>
                  </DuplicatedFlagBox>
                  <Box ml="auto" mr="0.2rem" display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/meet.svg`}
                    />
                  </Box>
                </CustomScheduleBox>
              )}
            {!displayNonReserved &&
              schedule.reservable_type === "MeetingRoom" &&
              schedule.duplicated_flag == 0 && (
                <CustomScheduleBox
                  sx={{
                    width: `${width}px`,
                    left: `${left}px`,
                    backgroundColor: "#DBF4EF",
                    color: "#4EC7B0",
                  }}
                  onClick={handleScheduleShowDataClick}
                >
                  <Box>
                    <Box
                      sx={{
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "8px",
                        fontWeight: "bold",
                        width: `${width - 12}px`,
                      }}
                    >
                      {!isMySchedule && schedule.is_secret
                        ? "非公開"
                        : schedule.schedule_title}
                    </Box>
                    <Box
                      sx={{
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        fontSize: "0.3rem",
                        width: `${width - 12}px`,
                      }}
                    >
                      {!isMySchedule && schedule.is_secret
                        ? "非公開"
                        : `${schedule.reservable.floor.branch.branch_name} ${schedule.reservable.floor.floor_number}F`}
                    </Box>
                  </Box>

                  <Box ml="auto" mr="0.2rem" display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/meet.svg`}
                      style={{ width: "0.8rem" }}
                    />
                  </Box>
                </CustomScheduleBox>
              )}
            {!displayNonReserved &&
              schedule.reservable_type === "Seat" &&
              schedule.duplicated_flag > 0 && (
                <CustomScheduleBox
                  sx={{
                    width: `${width}px`,
                    left: `${left}px`,
                    border: border,
                    backgroundColor: bgColor,
                    color: fontColor,
                    borderColor: borderColor,
                  }}
                  onClick={handleDuplicatedScheduleShowDataClick}
                >
                  <Box
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "normal",
                      width: "80%",
                    }}
                  >
                    <Box sx={{ width: "80%" }}>
                      <Typography>
                        {!isMySchedule && schedule.is_secret
                          ? "非公開"
                          : schedule.schedule_title}
                      </Typography>
                    </Box>
                  </Box>
                  <DuplicatedFlagBox>
                    <Typography>{schedule.duplicated_flag}</Typography>
                  </DuplicatedFlagBox>
                  <Box ml="auto" mr="0.2rem" display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/seat.svg`}
                    />
                  </Box>
                </CustomScheduleBox>
              )}
            {!displayNonReserved &&
              schedule.reservable_type === "Seat" &&
              schedule.duplicated_flag == 0 && (
                <CustomScheduleBox
                  sx={{
                    width: `${width}px`,
                    left: `${left}px`,
                    border: border,
                    backgroundColor: bgColor,
                    color: fontColor,
                    borderColor: borderColor,
                  }}
                  onClick={handleScheduleShowDataClick}
                >
                  <Box width="80%">
                    <Box
                      sx={{
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "8px",
                        fontWeight: "bold",
                        width: `${width - 12}px`,
                      }}
                    >
                      {!isMySchedule && schedule.is_secret
                        ? "非公開"
                        : schedule.schedule_title}
                    </Box>
                    <Box
                      sx={{
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        fontSize: "0.3rem",
                        width: `${width - 12}px`,
                      }}
                    >
                      {!isMySchedule && schedule.is_secret
                        ? "非公開"
                        : `${schedule.reservable.floor.branch.branch_name} ${schedule.reservable.floor.floor_number}F`}
                    </Box>
                  </Box>
                  <Box ml="auto" mr="0.2rem" display="flex" alignItems="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/icons/seat.svg`}
                    />
                  </Box>
                </CustomScheduleBox>
              )}
            {!displayReserved &&
              !schedule.reservable_type &&
              schedule.duplicated_flag > 0 && (
                <>
                  <CustomScheduleBox
                    sx={{
                      width: `${width}px`,
                      left: `${left}px`,
                      border: border,
                      backgroundColor: bgColor,
                      color: fontColor,
                      borderColor: borderColor,
                    }}
                    onClick={handleDuplicatedScheduleShowDataClick}
                  >
                    <Box>
                      <Typography>
                        {!isMySchedule && schedule.is_secret
                          ? "非公開"
                          : schedule.schedule_title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "inline-block",
                        borderRadius: "50%",
                        borderColor: "rgb(0 0 0 / 25%)",
                        border: "solid 1px rgb(0 0 0 / 25%)",
                        textAlign: "center",
                        height: "30px",
                        width: "30px",
                        boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
                        color: "rgb(0 0 0 / 25%)",
                        mx: "auto",
                      }}
                    >
                      <Typography>{schedule.duplicated_flag}</Typography>
                    </Box>
                  </CustomScheduleBox>
                </>
              )}
            {!displayReserved &&
              !schedule.reservable_type &&
              schedule.duplicated_flag == 0 && (
                <CustomScheduleBox
                  sx={{
                    width: `${width}px`,
                    left: `${left}px`,
                    border: border,
                    backgroundColor: bgColor,
                    color: fontColor,
                    borderColor: borderColor,
                  }}
                  onClick={handleScheduleShowDataClick}
                >
                  <Box>
                    <Box
                      sx={{
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "8px",
                        fontWeight: "bold",
                        fontFamily: "Noto Sans",
                        width: `${width}px`,
                      }}
                    >
                      {!isMySchedule && schedule.is_secret
                        ? "非公開"
                        : schedule.schedule_title}
                    </Box>
                  </Box>
                </CustomScheduleBox>
              )}
          </>
        )
      })}
    </>
  )
}
