import React from "react"
import { useLocation } from "react-router-dom"

import { EmployeeLayoutMainComponent } from "../../../../components/employee/calendar/layouts/EmployeeLayoutMainComponent"
import { ContentEmployeePaper } from "../../../../components/public/ContentPaper"
import { ContentTemplate } from "../../../../components/public/ContentTemplate"
import { ScheduleForm } from "../../../../models/employee/useSchedule/useScheduleForm/type"

type EmployeeCalendarLayoutParams = {
  scheduleForm: ScheduleForm
  branchId: number
  floorId: number
  monthlySetting?: string
  pathname: string
}

export const EmployeeCalendarLayoutPage: React.FC = () => {
  const location = useLocation()
  const employeeCalendarLayoutParams =
    location.state as EmployeeCalendarLayoutParams

  return (
    <ContentTemplate>
      <ContentEmployeePaper>
        <EmployeeLayoutMainComponent
          branchId={employeeCalendarLayoutParams.branchId}
          floorId={employeeCalendarLayoutParams.floorId}
          scheduleForm={employeeCalendarLayoutParams.scheduleForm}
          pathname={employeeCalendarLayoutParams.pathname}
          monthlySetting={employeeCalendarLayoutParams.monthlySetting}
        />
      </ContentEmployeePaper>
    </ContentTemplate>
  )
}
