import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { checkOutRequest } from "../../../../api/employee/scheduleRequest"
import { ReservableType } from "../../../company/useQrCode/type"
import { CompanyRelationsType } from "../../../public/useCompanyRelations/type"

export const useScheduleCheckOut = () => {
  const navigate = useNavigate()
  const locationSearch = useLocation().search
  const queryParams = new URLSearchParams(locationSearch)

  const [branchName, setBranchName] = useState<string>("")
  const [floorId, setFloorId] = useState<number>(0)
  const [floorNumber, setFloorNumber] = useState<string>("")
  const [checkOutType, setCheckOutType] = useState<ReservableType>("Seat")
  const [checkOutTarget, setCheckOutTarget] = useState<string>("")
  const [targetId, setTargetId] = useState<number>(0)
  const [checkOutError, setCheckOutError] = useState<boolean>(false)
  const [checkOutErrorMessage, setCheckOutErrorMessage] = useState<string>("")
  const [dataLoading, setDataLoading] = useState<boolean>(true)

  useEffect(() => {
    if (branchName !== "") setDataLoading(false)
  }, [branchName])

  // クエリパラメータから各種stateを設定する
  const setCheckOutStateFromQueryString = (
    companyRelations: CompanyRelationsType
  ) => {
    companyRelations.branches.map((branch) => {
      branch.floors.map((floor) => {
        if (floor.id === Number(queryParams.get("floor_id"))) {
          setFloorId(Number(queryParams.get("floor_id")))
          setBranchName(branch.branch_name)
          setFloorNumber(floor.floor_number)
        }
      })
    })
    setCheckOutType(queryParams.get("type") as ReservableType)
    setCheckOutTarget(queryParams.get("name") as string)
    setTargetId(Number(queryParams.get("id")))
  }

  // チェックアウトボタンを押した時の挙動
  const checkOut = async () => {
    try {
      const { data, error } = await checkOutRequest({
        reservable_id: targetId,
        reservable_type: checkOutType,
      })
      if (error) {
        setCheckOutError(true)
        setCheckOutErrorMessage(error[0])
      }

      if (data) {
        navigate(
          `/mobile/check-out/completed?floor_id=${floorId}&id=${targetId}&type=${checkOutType}&name=${checkOutTarget}`
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    branchName,
    floorNumber,
    checkOutType,
    checkOutTarget,
    checkOut,
    checkOutError,
    checkOutErrorMessage,
    setCheckOutError,
    setCheckOutStateFromQueryString,
    dataLoading,
    setDataLoading,
  }
}
