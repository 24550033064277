export const formatTime = (time: string) => {
  const timeDate = new Date(time)
  const hour = timeDate.getHours()
  const minutes =
    timeDate.getMinutes() === 0
      ? `0${timeDate.getMinutes()}`
      : timeDate.getMinutes()
  return `${hour}:${minutes}`
}

export const roundDownMinutes = (date: Date, interval: number) => {
  const roundDownMinute = interval
  const roundDownNumber = 0
  const roundedDownMinutes =
    (Math.floor(date.getMinutes() / roundDownMinute) | roundDownNumber) *
    roundDownMinute

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    roundedDownMinutes
  )
}

export const roundUpMinutes = (date: Date, interval: number) => {
  const roundUpMinute = interval
  const roundUpNumber = 0
  const roundedUpMinutes =
    (Math.ceil(date.getMinutes() / roundUpMinute) | roundUpNumber) *
    roundUpMinute

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    roundedUpMinutes
  )
}
