import React from "react"
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"

import {
  Grid,
  Typography,
  styled,
  TextField,
  Box,
  Button,
  Link,
} from "@mui/material"

import { MFAType } from "../../../../models/public/useAuth/MFA/type"
import { pageType } from "../../../../utils/location"
import { ErrorText } from "../../../public/ErrorText"

const CustomGridContainer = styled(Grid)(() => ({
  height: "100vh",
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  maxWidth: theme.spacing(53),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CustomButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: theme.spacing(2),
  padding: theme.spacing(1, 8),
  boxShadow: "0px 3px 6px #00000029",
  fontWeight: "bold",
}))

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(40),
}))

interface Props {
  authData: MFAType
  control: Control<MFAType>
  handleSubmit: UseFormHandleSubmit<MFAType>
  onConfirmSignIn: SubmitHandler<MFAType>
  errors: FieldErrors<MFAType>
  isValid: boolean
  getValues: UseFormGetValues<MFAType>
  setValue: UseFormSetValue<MFAType>
  open: boolean
  errorMessages: string[]
}

// ユーザー認証時、MFAデバイスが設定済みの場合にPINコードを入力させるフォーム
export const SignageMAFForm: React.FC<Props> = ({
  control,
  errors,
  isValid,
  handleSubmit,
  onConfirmSignIn,
  open,
  errorMessages,
}: Props) => {
  return (
    <CustomGridContainer
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <img
            src={`${process.env.PUBLIC_URL}/images/logos/logo.svg`}
            alt="WORK AGILEロゴ"
          />
        </Grid>
      </Grid>
      <CustomGridItem item>
        <Typography
          variant="h6"
          color="inherit"
          align="center"
          fontWeight="bold"
        >
          2要素認証
        </Typography>
      </CustomGridItem>
      <CustomGridItem item>
        <Typography variant="subtitle1" color="inherit" align="center">
          アプリに表示された確認コードを入力してください。
        </Typography>
      </CustomGridItem>
      <CustomGridItem item container spacing={2}>
        <Grid item xs={12} sm={9}>
          <Controller
            control={control}
            name="pinCode"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.pinCode}
                helperText={errors.pinCode?.message}
                id="pin_code"
                name="pin_code"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>
      </CustomGridItem>
      {open && (
        <Grid item>
          <ErrorMessageContainer textAlign="center">
            {ErrorText(errorMessages)}
          </ErrorMessageContainer>
        </Grid>
      )}
      <Grid item>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CustomButton
            onClick={handleSubmit(onConfirmSignIn)}
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            認証
          </CustomButton>
        </Box>
      </Grid>
      <Grid item>
        <Typography align="center">
          <Link
            component={RouterLink}
            to={`/${pageType}/login`}
            variant="caption"
            color="#454545"
          >
            ログイン画面に戻る
          </Link>
        </Typography>
      </Grid>
    </CustomGridContainer>
  )
}
