import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { AxiosInterceptors } from "../AxiosInterceptors"
import { ErrorPage } from "../pages/ErrorPage"
import { AuthProvider } from "../providers/AuthProvider"
import { CalendarLayoutProvider } from "../providers/CalendarLayoutProvider"
import { LayoutProvider } from "../providers/LayoutProvider"
import { LayoutStatusProvider } from "../providers/LayoutStatusProvider"
import { RoleProvider } from "../providers/RoleProvider"
import { CompanyRouter } from "./CompanyRouter"
import { EmployeeRouter } from "./EmployeeRouter"
import { MobileRouter } from "./MobileRouter"
import { SignageRouter } from "./SignageRouter"

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <AxiosInterceptors />
      <Routes>
        <Route
          path="employee/*"
          element={
            <AuthProvider>
              <CalendarLayoutProvider>
                <LayoutProvider>
                  <LayoutStatusProvider>
                    <RoleProvider>
                      <EmployeeRouter />
                    </RoleProvider>
                  </LayoutStatusProvider>
                </LayoutProvider>
              </CalendarLayoutProvider>
            </AuthProvider>
          }
        />
        <Route
          path="company/*"
          element={
            <AuthProvider>
              <RoleProvider>
                <CompanyRouter />
              </RoleProvider>
            </AuthProvider>
          }
        />
        <Route
          path="signage/*"
          element={
            <AuthProvider>
              <RoleProvider>
                <SignageRouter />
              </RoleProvider>
            </AuthProvider>
          }
        />
        <Route
          path="mobile/*"
          element={
            <AuthProvider>
              <RoleProvider>
                <MobileRouter />
              </RoleProvider>
            </AuthProvider>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  )
}
