const en = {
  CheckIn: "To check in,",
  CheckInLogin: "please log in to your account.",
  Azure: "This system uses Azure AD authentication.",
  EmailLogin: "Please log in using only your email address.",
  Provider: "Failed to retrieve the identity provider.",
  YourPassword: "Enter your Password",
  Email: "E-mail address(required)",
  Password: "Password(required)",
  Login: "Login",
  ForgotPassword: "Forgot your password?",
  LoginFailed: "Login failed.",
}
export default en
