import { AxiosError } from "axios"

import { LayoutDashboardType } from "../../models/company/useDashboard/type"
import {
  LayoutRelationType,
  LayoutResavableSeatType,
} from "../../models/company/useLayout/type"
import { MeetingRoomType } from "../../models/employee/useRelations/meetingRoom/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const createLayoutRequest = async (
  params: LayoutRelationType
): Promise<ApiResponse<{ layout_id: number }>> => {
  try {
    const data = await api.post("/company/layouts", params)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchLayoutRequest = async (params: {
  layout_id: number | undefined
}): Promise<ApiResponse<LayoutRelationType>> => {
  try {
    const data = await api.get("/company/layouts", { params: params })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const deleteLayoutRequest = async (params: {
  layout_id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(`/company/layouts/${params.layout_id}/delete`)
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const pausedLayoutRequest = async (params: {
  layout: {
    layout_id: number
    paused_date: Date | string
  }
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/layouts/${params.layout.layout_id}/paused`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const revertToDraftLayoutRequest = async (params: {
  layout_id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/layouts/${params.layout_id}/revert_draft`
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const unsuspendLayoutRequest = async (params: {
  layout_id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/layouts/${params.layout_id}/unsuspend`
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const revertToReleaseLayoutRequest = async (params: {
  layout_id: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/layouts/${params.layout_id}/revert_release`
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchReservedSeatRequest = async (params: {
  floor_id: number
}): Promise<ApiResponse<LayoutResavableSeatType>> => {
  try {
    const data = await api.get("/company/layouts/reserved_seat", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchLayoutDashboardRequest = async (params: {
  floor_id: number
}): Promise<ApiResponse<LayoutDashboardType>> => {
  try {
    const data = await api.get("/company/layouts/dashboard", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const fetchLayoutMeetingRoomRequest = async (
  layout_id: number
): Promise<ApiResponse<MeetingRoomType[]>> => {
  try {
    const data = await api.get(
      `/company/layouts/${layout_id}/search_meeting_room`
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateDefaultLayoutImage = async (params: {
  layout_id: number
  x: number
  y: number
  scale: number
}): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch(
      `/company/layouts/${params.layout_id}/update_default_layout_image`,
      params
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error) {
    const axiosError = error as AxiosError
    return { error: axiosError.response?.data }
  }
}
