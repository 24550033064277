const en = {
  home: "Home",
  locationManagement: "Location Management",
  locationInformation: "Location Information",
  signUp: "Sign Up",
  edit: "Edit",
  show: "Show",
  detail: "Detail",
  layouts: "Layout",
  employeeManagement: "Employee Management",
  employeeInformation: "Employee Information",
  termList: "Term List",
  corporateOrganization: "Corporate Organization",
  report: "Report",
  attendanceRate: "Attendance Rate",
  operationRate: "Operation Rate",
  byLocation: "By Location",
  operationSeatCount: "Operation Seat Count",
  conferenceRoomUsageStatus: "Conference Room Usage Status",
  byAreaUsageStatus: "Usage Status By Area",
  schedule: "Schedule",
  newSchedule: "New Schedule",
  editSchedule: "Edit Schedule",
  workPlace: "Workplace",
  modes: "Mode",
  settings: "Settings",
}
export default en
