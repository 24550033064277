import React, { ReactElement } from "react"

import { Grid, Typography, Box, styled } from "@mui/material"

import { Down } from "../Triangle"

interface Props {
  situation: string
  icon: ReactElement
  title: string
  description: string
  linkText: string
  linkUrl: string
}

const Wrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const Situation = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: 700,
  fontSize: "28px",
  marginBottom: "1.5rem",
  textAlign: "center",
}))

export const ExternalLink = ({
  situation,
  icon,
  title,
  description,
  linkText,
  linkUrl,
}: Props) => {
  return (
    <Wrapper item md={5.5}>
      <Situation>{situation}</Situation>
      <Down color="#FFFFFF" />
      <Box
        textAlign="center"
        sx={{
          backgroundColor: "#FFFFFF",
          width: "70%",
          borderRadius: "30px",
          color: "#22ba9d",
          padding: "1rem",
          marginTop: "1.5rem",
        }}
      >
        {icon}
        <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography
          color="#000000DE"
          variant="caption"
          display="block"
          margin="2rem 0"
          height="40px"
        >
          {description}
        </Typography>
        <Typography>
          <a href={linkUrl}>{linkText}</a>＞
        </Typography>
      </Box>
    </Wrapper>
  )
}
