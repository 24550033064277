import React from "react"

import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { ForgotPasswordAppliedMessage } from "../../../components/public/auth/ForgotPasswordAppliedMessage"

export const EmployeeForgotPasswordApplied = () => {
  return (
    <AuthPageTemplate loading={false}>
      <ForgotPasswordAppliedMessage />
    </AuthPageTemplate>
  )
}
