import axios from "axios"

import { POSTAL_CODE_SEARCH_ENDPOINT } from "../../utils/const"
import { ApiResponse } from "../config/axiosConfig"

interface Address {
  address1: string
  address2: string
  address3: string
  kana1: string
  kana2: string
  kana3: string
  prefcode: string
  zipcode: string
}

interface AddressSearchResult {
  status: number
  results: Address[]
}

export const searchAddressRequest = async (params: {
  postalCode: string
}): Promise<ApiResponse<AddressSearchResult>> => {
  try {
    const data = await axios.get(
      `${POSTAL_CODE_SEARCH_ENDPOINT}?zipcode=${params.postalCode}`
    )
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
