import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Box, Typography, styled } from "@mui/material"

import { SignageMainOutlineButton } from "../../../components/public/SignageMainOutlineButton"
import { SignageMainOutlineErrorButton } from "../../../components/public/SignageMainOutlineErrorButton"
import { theme } from "../../../config/theme"
import { useSignageReception } from "../../../models/signage/useReception"

const CustomBox = styled(Box)(() => ({
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(8),
}))

const CustomTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  color: "#454545",
  marginBottom: theme.spacing(15),
}))

export const SignageReceptionQrErrorPage = () => {
  const navigate = useNavigate()
  const { returnTopPage, resetTimer } = useSignageReception()
  const urlParams = useParams<{ branch_id: string }>()
  const branchId = Number(urlParams.branch_id)

  useEffect(() => {
    returnTopPage()
  }, [])

  return (
    <CustomBox>
      <Box textAlign="center">
        <CustomTypography variant="h4">
          QRコードを読み取ることが出来ませんでした。
          <br></br>
          申し訳ありませんが、再度お試しください。
          <Typography fontSize="22.7px">
            We were unable to scan the QR code. Please try again.
          </Typography>
        </CustomTypography>
      </Box>
      <SignageMainOutlineButton
        label="TOPに戻る"
        subLabel="Back to TOP"
        handleClick={() => {
          resetTimer()
          navigate(`/signage/branches/${branchId}/reception/home`)
        }}
      />
      <SignageMainOutlineErrorButton
        label="再度読み取る"
        subLabel="Scan again"
        handleClick={() => {
          resetTimer()
          navigate(`/signage/branches/${branchId}/reception/qr-reader`)
        }}
      />
    </CustomBox>
  )
}
