import React from "react"
import { useNavigate } from "react-router-dom"

import { InputLabel, Grid, Box, styled, Typography } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import { ProfileResponseType } from "../../../models/employee/useProfile/type"
import enTranslations from "../../../translations/employeeIcon/employeeShowIcon/en"
import jaTranslations from "../../../translations/employeeIcon/employeeShowIcon/ja"
import { CancelButton } from "../../public/CancelButton"

const CustomBox = styled(Box)(() => ({
  justifyContent: "center",
}))

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  justifyContent: "center",
}))

const CustomGridLabel = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  paddingRight: theme.spacing(4),
}))

const CustomGridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  paddingLeft: theme.spacing(4),
}))

interface Props {
  employeeProfile: ProfileResponseType
}

export const EmployeeProfileShowData: React.FC<Props> = ({
  employeeProfile,
}: Props) => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const navigate = useNavigate()
  const isSeatStr = (flag: number) => {
    return flag === null ? translations.No : translations.Yes
  }
  return (
    <>
      <CustomBox>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.EmployeeName}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="last_name_id">
              {employeeProfile.last_name}
            </Typography>
            <Typography id="first_name_id" style={{ paddingLeft: "1rem" }}>
              {employeeProfile.first_name}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.EmployeeNameKana}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="last_name_kana_id">
              {employeeProfile.last_name_kana}
            </Typography>
            <Typography id="first_name_kana_id" style={{ paddingLeft: "1rem" }}>
              {employeeProfile.first_name_kana}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.Email}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="email_id">
              {employeeProfile.account.email}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.EmployeeId}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="employee_id">
              {employeeProfile.employee_code}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.CompanyPhoneNumber}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="phone_id">
              {employeeProfile.phone_number}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.Location}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="branch_name_id">
              {employeeProfile.branch.branch_name}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.Floor}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="floor_number_id">
              {employeeProfile.floor.floor_name}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.DivisionDepartment}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="team_name_id">
              {employeeProfile.team.team_name}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.ManagerName}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="team_leader_name_id">
              {employeeProfile.team.team_leader_name}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.ManagerEmail}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="team_leader_email_id">
              {employeeProfile.team.team_leader_email}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.WorkArrangement}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="remote_flag_id">
              {employeeProfile.remote_flag
                ? translations.FullTime
                : translations.OfficeWork}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.AssignedDesk}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="is_seat">
              {isSeatStr(employeeProfile.seat_id)}
            </Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer
          container
          style={{ display: employeeProfile.seat_id ? "" : "none" }}
        >
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.AssignedDeskId}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="seat_id">{employeeProfile.seat_id}</Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CustomGridLabel item xs={12} sm={5}>
            <InputLabel>{translations.Remarks}</InputLabel>
          </CustomGridLabel>
          <CustomGridItem item xs={12} sm={5}>
            <Typography id="note">{employeeProfile.note}</Typography>
          </CustomGridItem>
        </CustomGridContainer>
        <CustomGridContainer container>
          <CancelButton
            label={translations.Return}
            handleClick={() => navigate("/employee/home")}
          />
        </CustomGridContainer>
      </CustomBox>
    </>
  )
}
