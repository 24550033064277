import React, { memo, ReactNode } from "react"

import Box from "@mui/material/Box"

export const ContentTemplate = memo(({ children }: { children: ReactNode }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F7F7F7",
        }}
      >
        {children}
      </Box>
    </>
  )
})
