// 各コンポーネントでユーザーの情報を扱えるようにするためのprovider
import { Auth } from "aws-amplify"

import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { useLocation } from "react-router-dom"

import { Loading } from "../components/public/Loading"
import { CustomCognitoUser, useAuth } from "../models/public/useAuth"

type AuthContextType = {
  isAuthenticated: boolean
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>
  currentUser: CustomCognitoUser | undefined
  setCurrentUser: Dispatch<SetStateAction<CustomCognitoUser | undefined>>
}

interface Props {
  children?: React.ReactNode
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    currentUser,
    authenticate,
    getCurrentUser,
    setCurrentUser,
  } = useAuth()

  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)

  // // リロード時に最新のログイン状態を検知する
  // useEffect(() => {
  //   const refreshAuthState = async () => {
  //     await Promise.all([authenticate(), getCurrentUser()])
  //   }
  //   refreshAuthState().then(() => {
  //     setLoading(false)
  //   })
  // }, [])

  // ブラウザバックやnavigateなど、ページ遷移時に最新のログイン状態を検知する
  useEffect(() => {
    const refreshAuthState = async () => {
      await Promise.all([authenticate(), getCurrentUser()])
    }
    refreshAuthState().then(() => {
      setLoading(false)
    })
  }, [location.key])

  return (
    <>
      {loading ? (
        <>
          <Loading type="fullScreen" />
        </>
      ) : (
        <AuthContext.Provider
          value={{
            isAuthenticated,
            setIsAuthenticated,
            currentUser,
            setCurrentUser,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </>
  )
}
