import React from "react"

import { Box } from "@mui/material"

import { AreaType } from "../../../../models/company/useReport/type"
import { areaStyle } from "../../../../styles/areaStyle"

interface AreaProps {
  areaId: number | null
  areaName: string
  startX: number
  startY: number
  endX: number
  endY: number
  colorCode: string
}

const AreaObject = (props: AreaProps) => {
  const { areaId, areaName, startX, startY, endX, endY, colorCode } = props
  return (
    <>
      <Box
        id={`A${String(areaId)}`}
        className={"area"}
        sx={areaStyle(startX, startY, endX, endY, colorCode)}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: colorCode,
            fontSize: "30px",
          }}
        >
          {areaName}
        </div>
      </Box>
    </>
  )
}

interface Props {
  area: AreaType
}

export const HeatmapLayoutArea: React.FC<Props> = ({ area }) => {
  return (
    <>
      <AreaObject
        areaId={area.id}
        areaName={area.area_name}
        startX={area.start_x}
        startY={area.start_y}
        endX={area.end_x}
        endY={area.end_y}
        colorCode={area.color_code}
      />
    </>
  )
}
