import { Auth } from "aws-amplify"

import React, { useEffect } from "react"

import { AuthPageTemplate } from "../../../components/public/AuthPageTemplate"
import { LoginForm } from "../../../components/public/auth/LoginForm"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useLogin } from "../../../models/public/useAuth/login"

export const CompanyLogin = () => {
  const authObject = useLogin()
  const { setLanguage } = useLanguage()

  return (
    <AuthPageTemplate loading={authObject.loading}>
      <LoginForm {...authObject} setLanguage={setLanguage} />
    </AuthPageTemplate>
  )
}
