import { useCallback, useState } from "react"

import { fetchScheduleMonthRequest } from "../../../../api/employee/scheduleRequest"
import {
  ScheduleRequestMonthType,
  ScheduleResponseMonthType,
  ScheduleDisplayWeekType,
  ScheduleDisplayMonthType,
} from "./type"

export const useMonthSchedule = () => {
  const [calendars, setCalendars] = useState<ScheduleDisplayMonthType>([])
  const [monthScheduleLoading, setMonthScheduleLoading] =
    useState<boolean>(false)

  // 予定をバックエンドから取得
  const fetchScheduleMonth = useCallback(
    async (data: ScheduleRequestMonthType) => {
      setMonthScheduleLoading(true)
      try {
        const response = await fetchScheduleMonthRequest(data)
        if (response.status === 200 && response.data) {
          const newCalendarData = countSchedule(response.data)
          setCalendars(newCalendarData)
        }
      } catch (error: any) {
        console.log("error")
      } finally {
        setMonthScheduleLoading(false)
      }
    },
    []
  )

  // 日別にスケジュールの種類別表示数を算出
  // 表示可能なカウントはMAX4段
  const countSchedule = (data: ScheduleResponseMonthType) => {
    const newCalendars: ScheduleDisplayMonthType = []
    data.forEach((week) => {
      const weekRow: ScheduleDisplayWeekType = []
      week.forEach((day) => {
        let multipleDisplayCount = 0
        let singleDisplayCount = 0
        let otherDisplayCount = 0

        // event_schedulesの表示枠
        let restDisplayCount = 3

        // 他N件表示数を算出（表示枠内にスケジュール数が収まれば、他N件表示はなし）
        if (day.event_schedules.length - restDisplayCount <= 0) {
          otherDisplayCount = 0
        } else {
          restDisplayCount -= 1
          otherDisplayCount = day.event_schedules.length - restDisplayCount
        }

        // 日またぎ予定の表示数を算出
        if (restDisplayCount >= day.multiple_schedules.length) {
          multipleDisplayCount = day.multiple_schedules.length
        } else {
          multipleDisplayCount = restDisplayCount
        }

        // 単日予定の表示数を算出
        if (restDisplayCount - day.multiple_schedules.length < 0) {
          singleDisplayCount = 0
        } else if (
          restDisplayCount - day.multiple_schedules.length >
          day.event_schedules.length
        ) {
          singleDisplayCount = day.event_schedules.length
        } else {
          singleDisplayCount = restDisplayCount - day.multiple_schedules.length
        }

        weekRow.push({
          ...day,
          multipleDisplayCount,
          singleDisplayCount,
          otherDisplayCount,
        })
      })
      newCalendars.push(weekRow)
    })
    return newCalendars
  }

  // 週またぎ予定の表示幅の計算
  const getEventWidth = (Day: Date, endDay: Date) => {
    const startDate = new Date(Day)
    const endDate = new Date(endDay)
    const diffTme = endDate.getTime() - startDate.getTime()
    const betweenDays = Math.floor(diffTme / 1000 / 60 / 60 / 24)
    return betweenDays * 100 + 100
  }

  return {
    calendars,
    fetchScheduleMonth,
    monthScheduleLoading,
    getEventWidth,
  }
}
