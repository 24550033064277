import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  Box,
  styled,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material"

import { useCreditCard } from "../../../models/company/useCreditCard"
import { useAgreement } from "../../../models/public/useAgreement"
import { AlertError } from "../../public/AlertError"
import { CancelButton } from "../../public/CancelButton"
import { CustomHeadTag } from "../../public/CustomHeadTag"
import { Loading } from "../../public/Loading"
import { ReflectButton } from "../../public/ReflectButton"
import { WarningText } from "../../public/WarningText"

const CustomGridContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 0),
  alignItems: "center",
}))

const CustomGridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}))

export const PaymentCreditCardForm = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const {
    form,
    onCreateCreditCard,
    creditCardErrorMessage,
    defaultCreditCard,
    onFetchCreditCard,
  } = useCreditCard()

  const { privacyChecked, handlePrivacyChange } = useAgreement()

  const errors = form.formState.errors

  useEffect(() => {
    const creditCardRequest = async () => {
      await onFetchCreditCard()
    }
    creditCardRequest().then(() => {
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading type="content" loading={loading} />
  }

  return (
    <>
      <CustomHeadTag />
      <AlertError
        isMessageOpen={creditCardErrorMessage.isOpenMessage}
        closeMessage={creditCardErrorMessage.closeMessage}
        alertMessage={creditCardErrorMessage.alertMessage}
      />
      {defaultCreditCard?.CardNo && (
        <CustomGridContainer container spacing={3}>
          <CustomGridItem item xs={12} sm={4}>
            <Typography fontWeight="boid">登録中のクレジットカード</Typography>
          </CustomGridItem>
          <Grid item xs={12} sm={8}>
            <Typography
              sx={{ marginRight: "10px" }}
            >{`有効期限: ${defaultCreditCard.Expire.substring(0, 2)} /
              ${defaultCreditCard.Expire.substring(2, 4)} カード番号: ${
              defaultCreditCard.CardNo
            }`}</Typography>
          </Grid>
        </CustomGridContainer>
      )}
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={12} sm={2}>
          <InputLabel htmlFor="cardno">カード番号</InputLabel>
        </CustomGridItem>
        <Grid item xs={4} sm={4}>
          <Controller
            control={form.control}
            name="cardno"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.cardno}
                helperText={errors.cardno?.message}
                required
                id="cardno"
                name="cardno"
                placeholder="例: 1234123412341234"
                fullWidth
                variant="outlined"
                inputProps={{ maxLength: 16 }}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={2} sm={2}>
          <InputLabel htmlFor="expireYear">年</InputLabel>
        </CustomGridItem>
        <Grid item xs={2} sm={2}>
          <Controller
            control={form.control}
            name="expireYear"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.expireYear}
                helperText={errors.expireYear?.message}
                required
                id="expireYear"
                name="expireYear"
                fullWidth
                variant="outlined"
                placeholder="YY"
                inputProps={{ maxLength: 2 }}
              />
            )}
          />
        </Grid>
        <CustomGridItem item xs={2} sm={2}>
          <InputLabel htmlFor="expireMonth">月</InputLabel>
        </CustomGridItem>
        <Grid item xs={2} sm={2}>
          <Controller
            control={form.control}
            name="expireMonth"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.expireMonth}
                helperText={errors.expireMonth?.message}
                required
                id="expireMonth"
                name="expireMonth"
                fullWidth
                variant="outlined"
                placeholder="MM"
                inputProps={{ maxLength: 2 }}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={2} sm={2}>
          <InputLabel htmlFor="holdername">名義人</InputLabel>
        </CustomGridItem>
        <Grid item xs={2} sm={2}>
          <Controller
            control={form.control}
            name="holdername"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.holdername}
                helperText={errors.holdername?.message}
                required
                id="holdername"
                name="holdername"
                placeholder="例: TARO YAMADA"
                fullWidth
                variant="outlined"
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <CustomGridItem item xs={2} sm={2}>
          <InputLabel htmlFor="securitycode">セキュリティコード</InputLabel>
        </CustomGridItem>
        <Grid item xs={2} sm={2}>
          <Controller
            control={form.control}
            name="securitycode"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.securitycode}
                helperText={errors.securitycode?.message}
                required
                id="securitycode"
                name="securitycode"
                placeholder="例: 123"
                fullWidth
                variant="outlined"
                inputProps={{ maxLength: 4 }}
              />
            )}
          />
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <Grid item xs={4} sm={4}>
          <Checkbox checked={privacyChecked} onChange={handlePrivacyChange} />
          <a
            href="https://workagile.jp/index.php/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          と
          <a
            href="https://workagile.jp/index.php/terms/"
            target="_blank"
            rel="noreferrer"
          >
            利用規約
          </a>
          に同意します
        </Grid>
      </CustomGridContainer>
      <CustomGridContainer container spacing={3}>
        <Grid item xs={12} sm={12}>
          {WarningText([
            "・登録ボタンを押した後、処理が完了するまでしばらく時間がかかる場合がございます。",
            "・何度もボタンは押さず、画面が切り替わるまでしばらくそのままお待ちください。",
          ])}
        </Grid>
      </CustomGridContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "450px",
          m: "30px auto",
        }}
      >
        <CancelButton
          label="戻る"
          handleClick={() => navigate("/company/settings/payment")}
        />
        <ReflectButton
          label="更新"
          disabled={!form.formState.isValid || !privacyChecked}
          handleClick={onCreateCreditCard}
        />
      </Box>
    </>
  )
}
