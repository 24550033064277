import {
  MobileStatusListRequestType,
  MobileStatusResponseType,
  EmployeeRelationsResponseType,
  TeamType,
} from "../../models/mobile/useTeamSearch/type"
import { api, ApiResponse } from "../config/axiosConfig"

// ユーザーの所属を取得
export const fetchEmployeeTeamRequest = async (): Promise<
  ApiResponse<TeamType>
> => {
  try {
    const data = await api.get("/mobile/team_searches/confirm_team")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

// ユーザーの会社の所属一覧およびユーザーのお気に入りグループ一覧を取得
export const fetchEmployeeRelationsRequest = async (): Promise<
  ApiResponse<EmployeeRelationsResponseType>
> => {
  try {
    const data = await api.get("/mobile/team_searches")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}

// 検索対象者のステータススケジュールを取得
export const fetchMobileScheduleStatusListRequest = async (
  params: MobileStatusListRequestType
): Promise<ApiResponse<MobileStatusResponseType>> => {
  try {
    const data = await api.get("/mobile/team_searches/status", {
      params: params,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data.message }
  }
}
