import { useCallback, useState } from "react"

export const useOpen = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])
  const toggle = useCallback(() => setIsOpen((state) => !state), [])

  return { isOpen, open, close, toggle }
}
