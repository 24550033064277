const en = {
  checkInSeat: "No desks currently require check-in.",
  reservation: "Your desk is Desk",
  PleaseCheckIn: "Please check-in after",
  checkedIn: "You have checked in Desk",
  Today: "today.",
  Until: "until",
  PleaseCheckOut: "Please remember to check-out when you are done.",
  WouldYouCheckIn: "Would you like to check in?",
  WouldYouCheckOut: "Would you like to check out?",
  CurrentDesk: "The current desk is",
  Hour: "hh",
  Minutes: "mm",
  ReservedUntil: "reserved until",
  WouldYouAgain: "Would you like to use it again after checking out?",
  UseAgain: "Use it again",
  beginningOfUse: "at the beginning of use.",
  from: "",
  CANCEL: "CANCEL",
  in: "Check in",
  out: "Check out",
  currentTime: "Update the end time to the current time",
  ReservationEnd: "Reservation End",
  SuccessUpdated: "Success! The end time is updated.",
  Floor: "Floor",
  setTimeReservation: "Set an end time of this reservation",
  ConfirmReservation: "CONFIRM RESERVATION",
}
export default en
